// Generated by ReScript, PLEASE EDIT WITH CARE


var values = [
  /* FR */0,
  /* FR_20R */1,
  /* FR_974 */2,
  /* BE */3,
  /* LU */4
];

function toIso2String(value) {
  switch (value) {
    case /* FR */0 :
        return "FR";
    case /* FR_20R */1 :
        return "FR-20R";
    case /* FR_974 */2 :
        return "FR-974";
    case /* BE */3 :
        return "BE";
    case /* LU */4 :
        return "LU";
    
  }
}

function toIsoString(value) {
  switch (value) {
    case /* FR */0 :
    case /* FR_20R */1 :
    case /* FR_974 */2 :
        return "FR";
    case /* BE */3 :
        return "BE";
    case /* LU */4 :
        return "LU";
    
  }
}

function toLongCountryString(value) {
  switch (value) {
    case /* FR */0 :
        return "France - Mainland";
    case /* FR_20R */1 :
        return "France - Corsica";
    case /* FR_974 */2 :
        return "France - Reunion Island";
    case /* BE */3 :
        return "Belgium";
    case /* LU */4 :
        return "Luxembourg";
    
  }
}

function toMediumCountryString(value) {
  switch (value) {
    case /* FR */0 :
    case /* FR_20R */1 :
    case /* FR_974 */2 :
        return "France";
    case /* BE */3 :
        return "Belgium";
    case /* LU */4 :
        return "Luxembourg";
    
  }
}

function fromString(value) {
  switch (value) {
    case "BE" :
        return {
                TAG: /* Ok */0,
                _0: /* BE */3
              };
    case "FR" :
        return {
                TAG: /* Ok */0,
                _0: /* FR */0
              };
    case "FR_20R" :
        return {
                TAG: /* Ok */0,
                _0: /* FR_20R */1
              };
    case "FR_974" :
        return {
                TAG: /* Ok */0,
                _0: /* FR_974 */2
              };
    case "LU" :
        return {
                TAG: /* Ok */0,
                _0: /* LU */4
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Unknown country code"
            };
  }
}

export {
  values ,
  toIso2String ,
  toIsoString ,
  toLongCountryString ,
  toMediumCountryString ,
  fromString ,
}
/* No side effect */
