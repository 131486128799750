// Generated by ReScript, PLEASE EDIT WITH CARE


function isErrored(value) {
  return value >= 2;
}

function isValid(value) {
  return value === 1;
}

function isPristine(value) {
  return value === 0;
}

export {
  isErrored ,
  isValid ,
  isPristine ,
}
/* No side effect */
