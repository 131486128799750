// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Nav$Pos from "../../resources/navigation/Nav.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as OverlayTriggerView$Pos from "../../resources/overlays/OverlayTriggerView.bs.js";
import * as PageBottomActionsBar$Pos from "../../resources/layout-and-structure/PageBottomActionsBar.bs.js";
import * as PromotionEditDiscountedProductTable$Pos from "./PromotionEditDiscountedProductTable.bs.js";
import * as PromotionEditDiscountedProductReducer$Pos from "./PromotionEditDiscountedProductReducer.bs.js";
import * as PromotionEditDiscountedProductPickerModalTable$Pos from "./PromotionEditDiscountedProductPickerModalTable.bs.js";

function PromotionEditDiscountedProductTableCard$AddProductModalButton(Props) {
  var onRequestModalOpen = Props.onRequestModalOpen;
  var match = Hover$Pos.use(undefined, undefined);
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(OverlayTriggerView$Pos.make, {
                      children: React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.t("Add product"),
                            variation: "normal"
                          }),
                      variation: {
                        NAME: "field",
                        VAL: {
                          required: false
                        }
                      },
                      icon: "search",
                      hovered: match[1]
                    }), undefined, undefined, undefined, undefined, undefined, (function (param) {
                    Curry._1(onRequestModalOpen, undefined);
                  }), undefined, match[0], undefined));
}

var AddProductModalButton = {
  make: PromotionEditDiscountedProductTableCard$AddProductModalButton
};

function PromotionEditDiscountedProductTableCard(Props) {
  var editable = Props.editable;
  var products = Props.products;
  var selectedPriceName = Props.selectedPriceName;
  var renderPageActions = Props.renderPageActions;
  var onRequestProductsUpdate = Props.onRequestProductsUpdate;
  var match = Nav$Pos.Context.use(undefined);
  var match$1 = React.useReducer(PromotionEditDiscountedProductReducer$Pos.make, products);
  var dispatch = match$1[1];
  var rows = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPickerOpened = match$2[1];
  ReactUpdateEffect$Pos.use2((function (param) {
          Curry._1(dispatch, {
                TAG: /* Reset */0,
                _0: products
              });
        }), [
        products,
        selectedPriceName
      ]);
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(onRequestProductsUpdate, rows);
        }), [rows]);
  var onPickProducts = React.useCallback((function (newProducts) {
          Curry._1(dispatch, {
                TAG: /* ProductsAdded */1,
                _0: newProducts
              });
        }), []);
  var onRequestClose = React.useCallback((function (param) {
          Curry._1(setPickerOpened, (function (param) {
                  return false;
                }));
        }), []);
  var renderActionsStart = function (param) {
    if (editable) {
      return React.createElement(PromotionEditDiscountedProductTableCard$AddProductModalButton, {
                  onRequestModalOpen: (function (param) {
                      Curry._1(setPickerOpened, (function (param) {
                              return true;
                            }));
                    })
                });
    } else {
      return null;
    }
  };
  var columns = React.useMemo((function () {
          return PromotionEditDiscountedProductTable$Pos.tableColumns(editable, selectedPriceName, products.length > 1, dispatch);
        }), [
        editable,
        selectedPriceName,
        products.length
      ]);
  var disabledIds = Belt_Array.map(rows, (function (param) {
          return param.cku;
        }));
  var rowsErrors = Belt_Array.keepMap(rows, (function (row) {
          if (row.retailPrices === undefined) {
            return {
                    key: row.cku,
                    message: Intl$Pos.t("This product is not compatible with the selected price list.")
                  };
          } else if (row.discount.amount === 0) {
            return {
                    key: row.cku,
                    message: Intl$Pos.t("The discount of this product must have a positive amount.")
                  };
          } else {
            return ;
          }
        }));
  return React.createElement(React.Fragment, undefined, React.createElement(Card$Pos.make, {
                  children: null,
                  variation: "unset"
                }, editable ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(PromotionEditDiscountedProductTableCard$AddProductModalButton, {
                                    onRequestModalOpen: (function (param) {
                                        Curry._1(setPickerOpened, (function (param) {
                                                return true;
                                              }));
                                      })
                                  })), "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(PromotionEditDiscountedProductTable$Pos.make, PromotionEditDiscountedProductTable$Pos.makeProps({
                          TAG: /* Done */1,
                          _0: {
                            TAG: /* Ok */0,
                            _0: rows
                          }
                        }, columns, PromotionEditDiscountedProductTable$Pos.Row.keyExtractor, undefined, rowsErrors, undefined, undefined, undefined, true, undefined, undefined, undefined, 500, Caml_option.some(React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                              children: React.createElement(TextStyle$Pos.make, {
                                                    children: Intl$Pos.t("Please add products to create your promotional campaign."),
                                                    align: "center",
                                                    variation: "normal",
                                                    size: "small"
                                                  }),
                                              align: "center"
                                            })), "large", "xlarge", undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor05, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(PromotionEditDiscountedProductPickerModalTable$Pos.make, {
                  opened: match$2[0],
                  selectedPriceName: selectedPriceName,
                  disabledIds: disabledIds,
                  onCommit: onPickProducts,
                  onRequestClose: onRequestClose
                }), React.createElement(PageBottomActionsBar$Pos.make, {
                  displayThreshold: 160,
                  renderStart: renderActionsStart,
                  renderEnd: renderPageActions,
                  navOpened: match[0].opened
                }));
}

var make = React.memo(PromotionEditDiscountedProductTableCard, (function (oldProps, newProps) {
        if (oldProps.products === newProps.products && oldProps.selectedPriceName === newProps.selectedPriceName) {
          return oldProps.editable === newProps.editable;
        } else {
          return false;
        }
      }));

var Reducer;

var PromotionTable;

export {
  Reducer ,
  PromotionTable ,
  AddProductModalButton ,
  make ,
}
/* make Not a pure module */
