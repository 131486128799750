// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Card$Pos from "./Card.bs.js";
import * as Column$Pos from "./Column.bs.js";
import * as Columns$Pos from "./Columns.bs.js";
import * as StackFields$Pos from "./StackFields.bs.js";
import * as LayoutPanelHeading$Pos from "./LayoutPanelHeading.bs.js";

function FieldsetLayoutPanel(Props) {
  var title = Props.title;
  var description = Props.description;
  var children = Props.children;
  return React.createElement(Columns$Pos.make, {
              children: null,
              space: "xlarge"
            }, React.createElement(Column$Pos.make, {
                  children: React.createElement(LayoutPanelHeading$Pos.make, {
                        title: title,
                        description: description
                      }),
                  width: "quarter"
                }), React.createElement(Column$Pos.make, {
                  children: React.createElement(Card$Pos.make, {
                        children: React.createElement(StackFields$Pos.make, {
                              children: children
                            })
                      }),
                  width: "three_quarter"
                }));
}

var make = React.memo(FieldsetLayoutPanel);

export {
  make ,
}
/* make Not a pure module */
