// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function gatewayUrl(param) {
  return Belt_Option.getExn(Js_dict.get(import.meta.env, "VITE_GATEWAY_URL"));
}

function analyticsUrl(param) {
  return Belt_Option.getExn(Js_dict.get(import.meta.env, "VITE_ANALYTICS_URL"));
}

function cavavinUrl(param) {
  return Belt_Option.getExn(Js_dict.get(import.meta.env, "VITE_CAVAVIN_URL"));
}

function sheetUrl(param) {
  return Belt_Option.getExn(Js_dict.get(import.meta.env, "VITE_SHEET_URL"));
}

function pdfUrl(param) {
  return Belt_Option.getExn(Js_dict.get(import.meta.env, "VITE_PDF_URL"));
}

function sentryDsn(param) {
  return Js_dict.get(import.meta.env, "VITE_SENTRY_DSN");
}

function intercomAppID(param) {
  return "m4f5bzdq";
}

function logrocketAppID(param) {
  return "u0ukt2/wino";
}

function context(param) {
  if (Belt_Option.isSome(Js_dict.get(import.meta.env, "VITEST"))) {
    return "test";
  }
  var match = Js_dict.get(import.meta.env, "VITE_CONTEXT");
  if (match === undefined) {
    return "dev";
  }
  switch (match) {
    case "production" :
        return "production";
    case "staging" :
        return "staging";
    default:
      return "dev";
  }
}

export {
  gatewayUrl ,
  analyticsUrl ,
  cavavinUrl ,
  sheetUrl ,
  pdfUrl ,
  sentryDsn ,
  intercomAppID ,
  logrocketAppID ,
  context ,
}
/* No side effect */
