// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Price$Pos from "./Price.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";

function PriceRateTableCell(Props) {
  var rateType = Props.rateType;
  var retailPrice = Props.retailPrice;
  var purchasePrice = Props.purchasePrice;
  var computedRate = Price$Pos.makeRate(rateType, retailPrice, purchasePrice);
  var formattedRate = computedRate !== undefined ? computedRate.toFixed(2).replace(".", ",") + " %" : "-";
  return React.createElement(TextStyle$Pos.make, {
              children: formattedRate
            });
}

var make = React.memo(PriceRateTableCell);

export {
  make ,
}
/* make Not a pure module */
