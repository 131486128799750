// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as CatalogDuplicationForm$Pos from "../../modules/Catalog/CatalogDuplicationForm.bs.js";
import * as CatalogDuplicationPage$Pos from "./CatalogDuplicationPage.bs.js";
import * as CatalogDuplication__Config$Pos from "./CatalogDuplication__Config.bs.js";
import * as CatalogDuplication__Queries$Pos from "./CatalogDuplication__Queries.bs.js";

var schema = [
  {
    TAG: /* Custom */7,
    _0: /* Source */0,
    _1: (function (param) {
        if (Belt_Option.isSome(param.source)) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Please select a catalog to duplicate.")
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* Destinations */1,
    _1: (function (param) {
        if (param.destinations.length !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Please select at least one target shop to duplicate to.")
                };
        }
      })
  }
];

function CatalogDuplicationContainer(Props) {
  var cku = Props.cku;
  var duplicationMode = Props.mode;
  var match = Curry.app(CatalogDuplication__Queries$Pos.ProductsQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var executeProductsQuery = match[0];
  var match$1 = Curry.app(CatalogDuplication__Queries$Pos.VariantsQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var executeVariantsQuery = match$1[0];
  var match$2 = Navigation$Pos.useGoBack(undefined);
  var onGoBack = match$2[1];
  var notifier = Notifier$Pos.use(/* KeepOnHistoryChanges */1, undefined);
  var dispatch = Auth$Pos.useDispatch(undefined);
  React.useEffect((function () {
          Curry._1(notifier.clear, undefined);
          if (duplicationMode) {
            Curry._3(executeVariantsQuery, undefined, undefined, CatalogDuplication__Queries$Pos.VariantsQuery.makeVariables(Scalar$Pos.CKU.serialize(cku), undefined));
          } else {
            Curry._3(executeProductsQuery, undefined, undefined, CatalogDuplication__Queries$Pos.ProductsQuery.makeVariables(Scalar$Pos.CKU.serialize(cku), undefined));
          }
        }), []);
  var onSubmitSuccess = React.useCallback((function (message) {
          Curry._1(dispatch, {
                TAG: /* ActiveShopSet */2,
                _0: undefined
              });
          Curry._3(notifier.reset, {
                TAG: /* Success */0,
                _0: Belt_Option.getWithDefault(message, "")
              }, undefined, undefined);
          Curry._1(onGoBack, undefined);
        }), []);
  var onSubmitFailure = React.useCallback((function (message) {
          Curry._3(notifier.reset, {
                TAG: /* Error */1,
                _0: message
              }, undefined, undefined);
        }), []);
  var results = CatalogDuplication__Config$Pos.use(match[1], match$1[1], duplicationMode);
  if (typeof results === "number") {
    if (results !== 0) {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Loading */0
                });
    } else {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Error */2
                });
    }
  }
  var match$3 = results._0;
  return React.createElement(CatalogDuplicationForm$Pos.Core.LegacyProvider.make, {
              initialValues: match$3.initialValues,
              schema: schema,
              children: React.createElement(CatalogDuplicationPage$Pos.make, {
                    cku: cku,
                    references: match$3.references,
                    duplicationMode: duplicationMode,
                    shopsData: match$3.shopsData
                  }),
              id: cku,
              onSubmitFailure: onSubmitFailure,
              onSubmitSuccess: onSubmitSuccess
            });
}

var make = React.memo(CatalogDuplicationContainer);

var Config;

var ProductsQuery;

var VariantsQuery;

export {
  Config ,
  ProductsQuery ,
  VariantsQuery ,
  schema ,
  make ,
}
/* make Not a pure module */
