// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Spinner$Pos from "../../resources/feedback-indicators/Spinner.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

function encodeBodyJson(userId) {
  return Json$Pos.encodeDict(Js_dict.fromArray([[
                    "userId",
                    Json$Pos.encodeString(userId)
                  ]]));
}

function decodeResult(json) {
  var jwt = Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "access_token");
  if (jwt !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: jwt
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

var endpoint = Env$Pos.gatewayUrl(undefined) + "/sign-in/impersonate";

function make(jwt, userId) {
  var headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt + ""
  };
  return Future.map(FuturePromise.fromPromise(fetch(endpoint, {
                          method: "POST",
                          body: Caml_option.some(Json$Pos.stringify(encodeBodyJson(userId))),
                          headers: Caml_option.some(new Headers(headers))
                        }).then(function (prim) {
                        return prim.json();
                      }).then(function (json) {
                      return Promise.resolve(decodeResult(json));
                    })), undefined, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                var accessToken = result._0;
                if (accessToken.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: accessToken._0
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
              }));
}

var UserImpersonationSignInRequest = {
  encodeBodyJson: encodeBodyJson,
  decodeResult: decodeResult,
  endpoint: endpoint,
  make: make
};

var styles = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, "column", undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function AuthImpersonationPage(Props) {
  var userId = Props.userId;
  var authLogoutImpersonationFailureRoute = Props.authLogoutImpersonationFailureRoute;
  var appIndexRoute = Props.appIndexRoute;
  var logUser = Auth$Pos.useLogUser(true, undefined);
  var auth = Auth$Pos.useState(undefined);
  var navigate = Navigation$Pos.useNavigate(undefined);
  var jwt = Auth$Pos.getJwt(undefined);
  var futureJwt = jwt !== undefined ? Future.value({
          TAG: /* Ok */0,
          _0: jwt
        }) : Future.value({
          TAG: /* Error */1,
          _0: undefined
        });
  React.useEffect((function () {
          if (typeof auth === "number") {
            Curry._4(navigate, authLogoutImpersonationFailureRoute, undefined, undefined, undefined);
          } else if (auth.TAG !== /* Logging */0) {
            var match = auth._0.user;
            var id = match.id;
            if (match.impersonating) {
              if (id === userId) {
                Curry._4(navigate, appIndexRoute, undefined, undefined, undefined);
              }
              
            } else if (match.canUseImpersonation && id !== userId) {
              Future.get(Future.flatMapOk(futureJwt, undefined, (function (jwt) {
                          return make(jwt, userId);
                        })), (function (result) {
                      if (result.TAG === /* Ok */0) {
                        return Curry._1(logUser, result._0);
                      } else {
                        return Curry._4(navigate, authLogoutImpersonationFailureRoute, undefined, undefined, undefined);
                      }
                    }));
            }
            
          }
          
        }), [auth]);
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Spinner$Pos.make, {})), Caml_option.some(styles.container), undefined, undefined, undefined));
}

var make$1 = React.memo(AuthImpersonationPage);

export {
  UserImpersonationSignInRequest ,
  styles ,
  make$1 as make,
}
/* endpoint Not a pure module */
