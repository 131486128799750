// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Colors$Pos from "../resources/theme/Colors.bs.js";
import * as FontFaces$Pos from "../resources/theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../resources/theme/FontSizes.bs.js";
import * as ReactAria$Pos from "../externals/ReactAria.bs.js";

var style = Object.assign({}, {
      color: Colors$Pos.neutralColor50,
      fontFamily: FontFaces$Pos.fontFamilyFromFontName("LibreFranklin"),
      fontSize: "" + String(FontSizes$Pos.xsmall) + "px",
      fontWeight: "500",
      marginBottom: "7px"
    }, {
      textWrap: "nowrap"
    });

function Label(Props) {
  var textOpt = Props.text;
  var ariaProps = Props.ariaProps;
  var text = textOpt !== undefined ? textOpt : "";
  return React.createElement(ReactAria$Pos.Spread.make, {
              props: ariaProps,
              children: React.createElement("label", {
                    style: style
                  }, text)
            });
}

var make = React.memo(Label);

export {
  make ,
}
/* style Not a pure module */
