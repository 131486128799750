// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Menu$Pos from "../../resources/navigation/Menu.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../../resources/overlays/Popover.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Scaffold$Pos from "../../bundles/Scaffold/Scaffold.bs.js";
import * as TextLink$Pos from "../../resources/navigation/TextLink.bs.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as SearchBar$Pos from "../../resources/selection-and-input/SearchBar.bs.js";
import * as Separator$Pos from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as ButtonLink$Pos from "../../resources/actions/ButtonLink.bs.js";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as OrderStatus$Pos from "../../modules/Order/OrderStatus.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as TableDateCell$Pos from "../../resources/tables/TableDateCell.bs.js";
import * as SupplierRouter$Pos from "../Supplier/SupplierRouter.bs.js";
import * as AmountTableCell$Pos from "../../modules/AmountTableCell.bs.js";
import * as SelectDateRange$Pos from "../../resources/selection-and-input/SelectDateRange.bs.js";
import * as OrderStatusBadges$Pos from "../../modules/Order/OrderStatusBadges.bs.js";
import * as OrderTableActions$Pos from "../../modules/Order/OrderTableActions.bs.js";
import * as OrderNameTableCell$Pos from "../../modules/Order/OrderNameTableCell.bs.js";
import * as RequestOpenStorageUrlMenuItem$Pos from "../../resources/actions/RequestOpenStorageUrlMenuItem.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query OrderListPageQuery($search: String, $before: String, $after: String, $first: Int, $last: Int, $filterBy: InputOrdersQueryFilter)  {\norders(search: $search, before: $before, after: $after, first: $first, last: $last, filterBy: $filterBy)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedName  \nshopName  \nsupplierCompanyName  \nissueDate  \nreceptionFinishedAt  \nestimatedReceptionDate  \nformattedStatus  \ntotalAmountExcludingTaxes  \ntotalAmountIncludingTaxes  \ntotalProductsQuantity  \ntotalProductsExpectedQuantity  \nsupplier  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.orders;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          orders: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.receptionFinishedAt;
                    var value$3 = value$1.formattedStatus;
                    var value$4 = value$1.supplier;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              formattedName: value$1.formattedName,
                              shopName: value$1.shopName,
                              supplierCompanyName: value$1.supplierCompanyName,
                              issueDate: Scalar$Pos.Datetime.parse(value$1.issueDate),
                              receptionFinishedAt: !(value$2 == null) ? Caml_option.some(Scalar$Pos.Datetime.parse(value$2)) : undefined,
                              estimatedReceptionDate: Scalar$Pos.Datetime.parse(value$1.estimatedReceptionDate),
                              formattedStatus: Js_array.map((function (value) {
                                      switch (value) {
                                        case "ACCEPTED" :
                                            return "ACCEPTED";
                                        case "ARCHIVED" :
                                            return "ARCHIVED";
                                        case "DRAFT" :
                                            return "DRAFT";
                                        case "FINALIZED" :
                                            return "FINALIZED";
                                        case "NOT_RECEIVED" :
                                            return "NOT_RECEIVED";
                                        case "PAID" :
                                            return "PAID";
                                        case "RECEIVED" :
                                            return "RECEIVED";
                                        case "RECEIVING" :
                                            return "RECEIVING";
                                        case "REFUSED" :
                                            return "REFUSED";
                                        case "SENT" :
                                            return "SENT";
                                        case "TO_PAY" :
                                            return "TO_PAY";
                                        default:
                                          throw {
                                                RE_EXN_ID: "Not_found",
                                                Error: new Error()
                                              };
                                      }
                                    }), value$3),
                              totalAmountExcludingTaxes: value$1.totalAmountExcludingTaxes,
                              totalAmountIncludingTaxes: value$1.totalAmountIncludingTaxes,
                              totalProductsQuantity: value$1.totalProductsQuantity,
                              totalProductsExpectedQuantity: value$1.totalProductsExpectedQuantity,
                              supplier: !(value$4 == null) ? ({
                                    __typename: value$4.__typename,
                                    id: value$4.id
                                  }) : undefined
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.orders;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.supplier;
          var supplier;
          if (value$2 !== undefined) {
            var value$3 = value$2.id;
            var value$4 = value$2.__typename;
            supplier = {
              __typename: value$4,
              id: value$3
            };
          } else {
            supplier = null;
          }
          var value$5 = value$1.totalProductsExpectedQuantity;
          var value$6 = value$1.totalProductsQuantity;
          var value$7 = value$1.totalAmountIncludingTaxes;
          var value$8 = value$1.totalAmountExcludingTaxes;
          var value$9 = value$1.formattedStatus;
          var formattedStatus = Js_array.map((function (value) {
                  if (value === "TO_PAY") {
                    return "TO_PAY";
                  } else if (value === "DRAFT") {
                    return "DRAFT";
                  } else if (value === "NOT_RECEIVED") {
                    return "NOT_RECEIVED";
                  } else if (value === "REFUSED") {
                    return "REFUSED";
                  } else if (value === "FINALIZED") {
                    return "FINALIZED";
                  } else if (value === "ACCEPTED") {
                    return "ACCEPTED";
                  } else if (value === "PAID") {
                    return "PAID";
                  } else if (value === "RECEIVING") {
                    return "RECEIVING";
                  } else if (value === "SENT") {
                    return "SENT";
                  } else if (value === "RECEIVED") {
                    return "RECEIVED";
                  } else {
                    return "ARCHIVED";
                  }
                }), value$9);
          var value$10 = value$1.estimatedReceptionDate;
          var value$11 = Scalar$Pos.Datetime.serialize(value$10);
          var value$12 = value$1.receptionFinishedAt;
          var receptionFinishedAt = value$12 !== undefined ? Scalar$Pos.Datetime.serialize(Caml_option.valFromOption(value$12)) : null;
          var value$13 = value$1.issueDate;
          var value$14 = Scalar$Pos.Datetime.serialize(value$13);
          var value$15 = value$1.supplierCompanyName;
          var value$16 = value$1.shopName;
          var value$17 = value$1.formattedName;
          var value$18 = value$1.id;
          var value$19 = value$1.__typename;
          var node = {
            __typename: value$19,
            id: value$18,
            formattedName: value$17,
            shopName: value$16,
            supplierCompanyName: value$15,
            issueDate: value$14,
            receptionFinishedAt: receptionFinishedAt,
            estimatedReceptionDate: value$11,
            formattedStatus: formattedStatus,
            totalAmountExcludingTaxes: value$8,
            totalAmountIncludingTaxes: value$7,
            totalProductsQuantity: value$6,
            totalProductsExpectedQuantity: value$5,
            supplier: supplier
          };
          var value$20 = value.__typename;
          return {
                  __typename: value$20,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var orders = {
    __typename: value$8,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          orders: orders
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputOrdersQueryFilter(inp) {
  var a = inp.status;
  var a$1 = inp.createdAt;
  var a$2 = inp.issueDate;
  var a$3 = inp.receptionFinishedAt;
  var a$4 = inp.shopIds;
  return {
          status: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          createdAt: a$1 !== undefined ? serializeInputObjectDateFilter(a$1) : undefined,
          issueDate: a$2 !== undefined ? serializeInputObjectDateFilter(a$2) : undefined,
          receptionFinishedAt: a$3 !== undefined ? serializeInputObjectDateFilter(a$3) : undefined,
          shopIds: a$4 !== undefined ? serializeInputObjectInFilter(a$4) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.before;
  var a$2 = inp.after;
  var a$3 = inp.first;
  var a$4 = inp.last;
  var a$5 = inp.filterBy;
  return {
          search: a !== undefined ? a : undefined,
          before: a$1 !== undefined ? a$1 : undefined,
          after: a$2 !== undefined ? a$2 : undefined,
          first: a$3 !== undefined ? a$3 : undefined,
          last: a$4 !== undefined ? a$4 : undefined,
          filterBy: a$5 !== undefined ? serializeInputObjectInputOrdersQueryFilter(a$5) : undefined
        };
}

function makeVariables(search, before, after, first, last, filterBy, param) {
  return {
          search: search,
          before: before,
          after: after,
          first: first,
          last: last,
          filterBy: filterBy
        };
}

function makeInputObjectInputOrdersQueryFilter(status, createdAt, issueDate, receptionFinishedAt, shopIds, param) {
  return {
          status: status,
          createdAt: createdAt,
          issueDate: issueDate,
          receptionFinishedAt: receptionFinishedAt,
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined,
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputOrdersQueryFilter: serializeInputObjectInputOrdersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputOrdersQueryFilter: makeInputObjectInputOrdersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputOrdersQueryFilter: serializeInputObjectInputOrdersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputOrdersQueryFilter: makeInputObjectInputOrdersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var make = React.memo(function (Props) {
      var notification = Props.notification;
      var onRequestClose = Props.onRequestClose;
      if (notification === undefined) {
        return null;
      }
      var tmp;
      tmp = notification.TAG === /* Ok */0 ? React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Success */0,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            }) : React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Danger */1,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            });
      return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(tmp), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    });

var NotificationBanner = {
  make: make
};

var endpoint = Env$Pos.sheetUrl(undefined) + "/orders-history";

var match = Intl$Pos.resolvedOptions(Intl$Pos.dateTimeFormatConstructor(undefined, undefined, undefined));

var timeZone = match.timeZone;

function makeRequestBodyJson(shopIds, status, orderIssueStartDate, orderIssueEndDate, orderReceptionFinishedStartDate, orderReceptionFinishedEndDate, param) {
  var body = {};
  body["shopIds"] = Json$Pos.encodeArray(Belt_Array.map(shopIds, Json$Pos.encodeString));
  if (status !== undefined) {
    body["status"] = Json$Pos.encodeString(OrderStatus$Pos.toString(status));
  }
  if (orderIssueStartDate !== undefined) {
    body["orderIssueStartDate"] = Json$Pos.encodeString(Caml_option.valFromOption(orderIssueStartDate).toISOString());
  }
  if (orderIssueEndDate !== undefined) {
    body["orderIssueEndDate"] = Json$Pos.encodeString(Caml_option.valFromOption(orderIssueEndDate).toISOString());
  }
  if (orderReceptionFinishedStartDate !== undefined) {
    body["orderReceptionFinishedStartDate"] = Json$Pos.encodeString(Caml_option.valFromOption(orderReceptionFinishedStartDate).toISOString());
  }
  if (orderReceptionFinishedEndDate !== undefined) {
    body["orderReceptionFinishedEndDate"] = Json$Pos.encodeString(Caml_option.valFromOption(orderReceptionFinishedEndDate).toISOString());
  }
  body["timeZone"] = Json$Pos.encodeString(timeZone);
  return Json$Pos.encodeDict(body);
}

function OrderListPage$OrderExportMenuItem(Props) {
  var text = Props.text;
  var shopIds = Props.shopIds;
  var status = Props.status;
  var issueDateRange = Props.issueDateRange;
  var receptionDateRange = Props.receptionDateRange;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover$Pos.useState(undefined);
  var onRequestClose = match.close;
  var request = function (param) {
    return Request$Pos.makeAndDecode("POST", Caml_option.some(makeRequestBodyJson(shopIds, status, Belt_Option.map(issueDateRange, (function (prim) {
                              return prim[0];
                            })), Belt_Option.map(issueDateRange, (function (prim) {
                              return prim[1];
                            })), Belt_Option.map(receptionDateRange, (function (prim) {
                              return prim[0];
                            })), Belt_Option.map(receptionDateRange, (function (prim) {
                              return prim[1];
                            })), undefined)), undefined, undefined, endpoint);
  };
  var onSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
  };
  var onFailure = function (error) {
    var errorMessage = RequestOpenStorageUrlMenuItem$Pos.failureErrorToString(error, "supplier orders");
    Curry._1(onRequestErrorNotification, errorMessage);
    Curry._1(onRequestClose, undefined);
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request
  };
  return React.createElement(RequestOpenStorageUrlMenuItem$Pos.make, {
              text: text,
              operableRequest: operableRequest,
              onSuccess: onSuccess,
              onFailure: onFailure
            });
}

var OrderExportMenuItem = {
  endpoint: endpoint,
  timeZone: timeZone,
  makeRequestBodyJson: makeRequestBodyJson,
  make: OrderListPage$OrderExportMenuItem
};

var endpoint$1 = Env$Pos.sheetUrl(undefined) + "/orders-products-history";

var match$1 = Intl$Pos.resolvedOptions(Intl$Pos.dateTimeFormatConstructor(undefined, undefined, undefined));

var timeZone$1 = match$1.timeZone;

function makeRequestBodyJson$1(shopIds, status, orderIssueStartDate, orderIssueEndDate, orderReceptionFinishedStartDate, orderReceptionFinishedEndDate, param) {
  var body = {};
  body["shopIds"] = Json$Pos.encodeArray(Belt_Array.map(shopIds, Json$Pos.encodeString));
  if (status !== undefined) {
    body["status"] = Json$Pos.encodeString(OrderStatus$Pos.toString(status));
  }
  if (orderIssueStartDate !== undefined) {
    body["orderIssueStartDate"] = Json$Pos.encodeString(Caml_option.valFromOption(orderIssueStartDate).toISOString());
  }
  if (orderIssueEndDate !== undefined) {
    body["orderIssueEndDate"] = Json$Pos.encodeString(Caml_option.valFromOption(orderIssueEndDate).toISOString());
  }
  if (orderReceptionFinishedStartDate !== undefined) {
    body["orderReceptionFinishedStartDate"] = Json$Pos.encodeString(Caml_option.valFromOption(orderReceptionFinishedStartDate).toISOString());
  }
  if (orderReceptionFinishedEndDate !== undefined) {
    body["orderReceptionFinishedEndDate"] = Json$Pos.encodeString(Caml_option.valFromOption(orderReceptionFinishedEndDate).toISOString());
  }
  body["timeZone"] = Json$Pos.encodeString(timeZone$1);
  return Json$Pos.encodeDict(body);
}

function OrderListPage$OrderProductExportMenuItem(Props) {
  var text = Props.text;
  var shopIds = Props.shopIds;
  var status = Props.status;
  var issueDateRange = Props.issueDateRange;
  var receptionDateRange = Props.receptionDateRange;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover$Pos.useState(undefined);
  var onRequestClose = match.close;
  var request = function (param) {
    return Request$Pos.makeAndDecode("POST", Caml_option.some(makeRequestBodyJson$1(shopIds, status, Belt_Option.map(issueDateRange, (function (prim) {
                              return prim[0];
                            })), Belt_Option.map(issueDateRange, (function (prim) {
                              return prim[1];
                            })), Belt_Option.map(receptionDateRange, (function (prim) {
                              return prim[0];
                            })), Belt_Option.map(receptionDateRange, (function (prim) {
                              return prim[1];
                            })), undefined)), undefined, undefined, endpoint$1);
  };
  var onSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
  };
  var onFailure = function (error) {
    var errorMessage = RequestOpenStorageUrlMenuItem$Pos.failureErrorToString(error, "ordered products");
    Curry._1(onRequestErrorNotification, errorMessage);
    Curry._1(onRequestClose, undefined);
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request
  };
  return React.createElement(RequestOpenStorageUrlMenuItem$Pos.make, {
              text: text,
              operableRequest: operableRequest,
              onSuccess: onSuccess,
              onFailure: onFailure
            });
}

var OrderProductExportMenuItem = {
  endpoint: endpoint$1,
  timeZone: timeZone$1,
  makeRequestBodyJson: makeRequestBodyJson$1,
  make: OrderListPage$OrderProductExportMenuItem
};

var statuses = [
  "DRAFT",
  "FINALIZED",
  "ACCEPTED",
  "RECEIVING",
  "RECEIVED",
  "TO_PAY",
  "PAID",
  "ARCHIVED"
];

function encoder(param) {
  return [
          Belt_Option.map(param.shop, (function (shop) {
                  return shop.id;
                })),
          Belt_Option.map(param.status, OrderStatus$Pos.toString),
          Belt_Option.map(param.issueDateRange, (function (param) {
                  return [
                          param[0].valueOf(),
                          param[1].valueOf()
                        ];
                })),
          Belt_Option.map(param.receptionDateRange, (function (param) {
                  return [
                          param[0].valueOf(),
                          param[1].valueOf()
                        ];
                }))
        ];
}

function decoder(shops, param) {
  var shopId = param[0];
  return {
          TAG: /* Ok */0,
          _0: {
            shop: Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === shopId;
                  })),
            status: Belt_Option.map(param[1], OrderStatus$Pos.fromStringExn),
            issueDateRange: Belt_Option.flatMap(param[2], (function (range) {
                    if (range.length !== 2) {
                      return ;
                    }
                    var start = range[0];
                    var end = range[1];
                    return [
                            new Date(start),
                            new Date(end)
                          ];
                  })),
            receptionDateRange: Belt_Option.flatMap(param[3], (function (range) {
                    if (range.length !== 2) {
                      return ;
                    }
                    var start = range[0];
                    var end = range[1];
                    return [
                            new Date(start),
                            new Date(end)
                          ];
                  }))
          }
        };
}

function useJsonCodec(param) {
  var shops = Auth$Pos.useShops(undefined);
  return JsonCodec$Pos.object4(encoder, (function (param) {
                return decoder(shops, param);
              }), JsonCodec$Pos.optional(JsonCodec$Pos.field("shopId", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("status", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("issueDateRange", JsonCodec$Pos.array(JsonCodec$Pos.$$float))), JsonCodec$Pos.optional(JsonCodec$Pos.field("receptionDateRange", JsonCodec$Pos.array(JsonCodec$Pos.$$float))));
}

var Filters = {
  statuses: statuses,
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function makeQueryVariables(_defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          search: search,
          before: connectionArguments.before,
          after: connectionArguments.after,
          first: connectionArguments.first,
          last: connectionArguments.last,
          filterBy: filterBy
        };
}

function makeQueryVariablesFilterBy(param) {
  var receptionDateRange = param.receptionDateRange;
  var issueDateRange = param.issueDateRange;
  var shop = param.shop;
  var status = Belt_Option.map(param.status, OrderStatus$Pos.toString);
  return {
          status: status !== undefined ? ({
                _in: [status]
              }) : undefined,
          createdAt: undefined,
          issueDate: issueDateRange !== undefined ? ({
                _after: undefined,
                _before: undefined,
                _between: [
                  Scalar$Pos.Datetime.serialize(issueDateRange[0]),
                  Scalar$Pos.Datetime.serialize(issueDateRange[1])
                ]
              }) : undefined,
          receptionFinishedAt: receptionDateRange !== undefined ? ({
                _after: undefined,
                _before: undefined,
                _between: [
                  Scalar$Pos.Datetime.serialize(receptionDateRange[0]),
                  Scalar$Pos.Datetime.serialize(receptionDateRange[1])
                ]
              }) : undefined,
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined
        };
}

function totalCountFromQueryData(param) {
  return param.orders.totalCount;
}

function cursorsFromQueryData(param) {
  var orders = param.orders;
  return [
          orders.pageInfo.startCursor,
          orders.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.map(param.orders.edges, (function (edge) {
                return {
                        id: edge.node.id,
                        formattedName: edge.node.formattedName,
                        shopName: edge.node.shopName,
                        supplierCompanyName: edge.node.supplierCompanyName,
                        supplierId: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                return supplier.id;
                              })),
                        issueDate: edge.node.issueDate,
                        receptionFinishedAt: edge.node.receptionFinishedAt,
                        estimatedReceptionDate: edge.node.estimatedReceptionDate,
                        statuses: edge.node.formattedStatus,
                        totalAmountExcludingTaxes: edge.node.totalAmountExcludingTaxes,
                        totalAmountIncludingTaxes: edge.node.totalAmountIncludingTaxes,
                        totalProductsQuantity: edge.node.totalProductsQuantity,
                        totalProductsExpectedQuantity: edge.node.totalProductsExpectedQuantity
                      };
              }));
}

function keyExtractor(param) {
  return param.id;
}

var Scaffolded = Scaffold$Pos.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

function makeFromState(param) {
  var filters = param.filters;
  var shopId = Belt_Option.map(filters.shop, (function (param) {
          return param.id;
        }));
  return {
          shopId: shopId,
          status: filters.status,
          issueDateRange: filters.issueDateRange,
          receptionDateRange: filters.receptionDateRange
        };
}

var SheetExportInput = {
  makeFromState: makeFromState
};

function OrderListPage(Props) {
  var shops = Auth$Pos.useShops(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var authScope = Auth$Pos.useScope(undefined);
  var match = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = match.TAG === /* Organisation */0 ? true : false;
  var initialFilters = {
    shop: activeShop,
    status: undefined,
    issueDateRange: undefined,
    receptionDateRange: undefined
  };
  var initialState = Curry._1(Scaffolded.makeInitialState, initialFilters);
  var match$1 = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var defaultQueryVariables = {
    search: undefined,
    before: undefined,
    after: undefined,
    first: undefined,
    last: undefined,
    filterBy: undefined
  };
  var match$2 = React.useState(function () {
        
      });
  var setNotification = match$2[1];
  var columns = [
    {
      name: Intl$Pos.t("Number"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 160
        },
        width: {
          NAME: "pct",
          VAL: 20
        },
        margin: "normal",
        sticky: true
      },
      render: (function (param) {
          var shopName;
          shopName = authScope.TAG === /* Organisation */0 && authScope.activeShop === undefined ? param.shopName : undefined;
          var tmp = {
            value: param.formattedName,
            id: param.id
          };
          if (shopName !== undefined) {
            tmp.shopName = Caml_option.valFromOption(shopName);
          }
          return React.createElement(OrderNameTableCell$Pos.make, tmp);
        })
    },
    {
      name: Intl$Pos.t("Supplier"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 160
        },
        width: {
          NAME: "pct",
          VAL: 20
        },
        margin: "small"
      },
      render: (function (param) {
          var supplierId = param.supplierId;
          if (supplierId !== undefined) {
            return React.createElement(TextLink$Pos.make, {
                        text: param.supplierCompanyName,
                        to: {
                          TAG: /* Route */0,
                          _0: SupplierRouter$Pos.showRoute(supplierId)
                        }
                      });
          } else {
            return null;
          }
        })
    },
    {
      name: Intl$Pos.t("Amount VAT excl."),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        },
        sticky: true
      },
      render: (function (param) {
          return React.createElement(AmountTableCell$Pos.make, {
                      value: param.totalAmountExcludingTaxes,
                      secondaryValue: param.totalAmountIncludingTaxes,
                      decimalPrecision: 3
                    });
        })
    },
    {
      name: Intl$Pos.t("Issue date"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          return React.createElement(TableDateCell$Pos.make, {
                      value: Caml_option.some(param.issueDate)
                    });
        })
    },
    {
      name: Intl$Pos.t("Reception date"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        }
      },
      render: (function (param) {
          var receptionFinishedAt = param.receptionFinishedAt;
          if (receptionFinishedAt !== undefined) {
            return React.createElement(TableDateCell$Pos.make, {
                        value: receptionFinishedAt
                      });
          } else {
            return React.createElement(TableDateCell$Pos.make, {
                        value: Caml_option.some(param.estimatedReceptionDate),
                        label: Intl$Pos.t("estimated")
                      });
          }
        })
    },
    {
      name: Intl$Pos.t("Rec. qt"),
      layout: {
        width: {
          NAME: "px",
          VAL: 100
        },
        margin: "xxlarge",
        alignX: "center"
      },
      render: (function (param) {
          var statuses = param.statuses;
          return React.createElement(Inline$Pos.make, {
                      children: null
                    }, React.createElement(TextStyle$Pos.make, {
                          children: OrderStatus$Pos.has(statuses, "RECEIVING") || OrderStatus$Pos.has(statuses, "RECEIVED") || OrderStatus$Pos.has(statuses, "ARCHIVED") ? String(param.totalProductsQuantity) : "-",
                          weight: "semibold"
                        }), React.createElement(TextStyle$Pos.make, {
                          children: " / " + String(param.totalProductsExpectedQuantity)
                        }));
        })
    },
    {
      name: Intl$Pos.t("Status"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        },
        width: {
          NAME: "fr",
          VAL: 1.3
        }
      },
      render: (function (param) {
          return React.createElement(OrderStatusBadges$Pos.make, {
                      statuses: param.statuses
                    });
        })
    },
    {
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 70
        },
        width: {
          NAME: "px",
          VAL: 70
        }
      },
      render: (function (param) {
          return React.createElement(OrderTableActions$Pos.make, {
                      id: param.id,
                      statuses: param.statuses,
                      callbackUrlAfterArchiveAction: LegacyRouter$Pos.routeToPathname(/* Order */0)
                    });
        })
    }
  ];
  var tmp;
  if (organisationAccount) {
    var tmp$1 = {
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: shop,
                            status: prev.status,
                            issueDateRange: prev.issueDateRange,
                            receptionDateRange: prev.receptionDateRange
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp$1.value = Caml_option.valFromOption(state.filters.shop);
    }
    tmp = React.createElement(Auth$Pos.SelectShop.make, tmp$1);
  } else {
    tmp = null;
  }
  var defaultItem_label = Intl$Pos.t("Not archived");
  var defaultItem = {
    key: "default",
    label: defaultItem_label
  };
  var items = Belt_Array.map(statuses, (function (status) {
          return {
                  key: OrderStatus$Pos.toString(status),
                  value: status,
                  label: OrderStatus$Pos.toLabel(status)
                };
        }));
  var tmp$2 = {
    label: Intl$Pos.t("Issue"),
    placeholder: "",
    onChange: (function (issueDateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          shop: prev.shop,
                          status: prev.status,
                          issueDateRange: issueDateRange,
                          receptionDateRange: prev.receptionDateRange
                        };
                })
            });
      })
  };
  if (state.filters.issueDateRange !== undefined) {
    tmp$2.value = Caml_option.valFromOption(state.filters.issueDateRange);
  }
  var tmp$3 = {
    label: Intl$Pos.t("Reception"),
    placeholder: "",
    onChange: (function (receptionDateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          shop: prev.shop,
                          status: prev.status,
                          issueDateRange: prev.issueDateRange,
                          receptionDateRange: receptionDateRange
                        };
                })
            });
      })
  };
  if (state.filters.receptionDateRange !== undefined) {
    tmp$3.value = Caml_option.valFromOption(state.filters.receptionDateRange);
  }
  var match$3 = state.filters;
  var tmp$4;
  var exit = 0;
  if (match$3.status !== undefined || match$3.issueDateRange !== undefined || match$3.receptionDateRange !== undefined) {
    exit = 1;
  } else {
    tmp$4 = null;
  }
  if (exit === 1) {
    tmp$4 = React.createElement(Scaffold$Pos.ResetFiltersButton.make, {
          onPress: (function (param) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (param) {
                        return initialState.filters;
                      })
                  });
            })
        });
  }
  var filters = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, tmp, organisationAccount ? React.createElement(Separator$Pos.make, {}) : null, React.createElement(Select$Pos.make, {
            label: Intl$Pos.t("Status"),
            sections: [{
                items: Belt_Array.concat([defaultItem], items),
                title: Intl$Pos.t("Statuses")
              }],
            value: state.filters.status,
            onChange: (function (status) {
                Curry._1(dispatch, {
                      TAG: /* FiltersUpdated */2,
                      _0: (function (prev) {
                          return {
                                  shop: prev.shop,
                                  status: status,
                                  issueDateRange: prev.issueDateRange,
                                  receptionDateRange: prev.receptionDateRange
                                };
                        })
                    });
              })
          }), React.createElement(SelectDateRange$Pos.make, tmp$2), React.createElement(SelectDateRange$Pos.make, tmp$3), tmp$4);
  var match$4 = makeFromState(state);
  var receptionDateRange = match$4.receptionDateRange;
  var issueDateRange = match$4.issueDateRange;
  var status = match$4.status;
  var shopId = match$4.shopId;
  var shopIds = shopId !== undefined ? [shopId] : Belt_Array.map(shops, (function (shop) {
            return shop.id;
          }));
  var onRequestErrorNotification = function (error) {
    Curry._1(setNotification, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
  };
  var tmp$5 = {
    text: Intl$Pos.t("Orders export"),
    shopIds: shopIds,
    onRequestErrorNotification: onRequestErrorNotification
  };
  if (status !== undefined) {
    tmp$5.status = Caml_option.valFromOption(status);
  }
  if (issueDateRange !== undefined) {
    tmp$5.issueDateRange = Caml_option.valFromOption(issueDateRange);
  }
  if (receptionDateRange !== undefined) {
    tmp$5.receptionDateRange = Caml_option.valFromOption(receptionDateRange);
  }
  var tmp$6 = {
    text: Intl$Pos.t("Ordered products export"),
    shopIds: shopIds,
    onRequestErrorNotification: onRequestErrorNotification
  };
  if (status !== undefined) {
    tmp$6.status = Caml_option.valFromOption(status);
  }
  if (issueDateRange !== undefined) {
    tmp$6.issueDateRange = Caml_option.valFromOption(issueDateRange);
  }
  if (receptionDateRange !== undefined) {
    tmp$6.receptionDateRange = Caml_option.valFromOption(receptionDateRange);
  }
  var actions = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, React.createElement(Menu$Pos.make, {
            children: null,
            disabled: shopIds.length === 0,
            overlayPriority: false
          }, React.createElement(OrderListPage$OrderExportMenuItem, tmp$5), React.createElement(OrderListPage$OrderProductExportMenuItem, tmp$6)), React.createElement(ButtonLink$Pos.make, {
            children: Intl$Pos.t("Create order"),
            variation: "primary",
            to: {
              TAG: /* Route */0,
              _0: LegacyRouter$Pos.routeToPathname(/* Order */0) + "/create"
            }
          }));
  var banner = React.createElement(make, {
        notification: match$2[0],
        onRequestClose: (function (param) {
            Curry._1(setNotification, (function (param) {
                    
                  }));
          })
      });
  var tmp$7 = {
    placeholder: Intl$Pos.t("Search an order"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$7.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar$Pos.make, tmp$7);
  var emptyState;
  var exit$1 = 0;
  if (state.currentPage !== 1 || state.searchQuery !== undefined) {
    exit$1 = 1;
  } else {
    var match$5 = state.filters;
    if (match$5.status !== undefined || match$5.issueDateRange !== undefined || match$5.receptionDateRange !== undefined || match$5.shop !== activeShop) {
      exit$1 = 1;
    } else {
      emptyState = React.createElement(EmptyState$Pos.make, {
            illustration: Illustration$Pos.create,
            title: Intl$Pos.t("Welcome to the supplier orders space.")
          });
    }
  }
  if (exit$1 === 1) {
    emptyState = React.createElement(EmptyState$Pos.make, {
          illustration: Illustration$Pos.notFound,
          title: Intl$Pos.t("No result were found."),
          text: Intl$Pos.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  return React.createElement(Scaffolded.make, {
              title: Intl$Pos.t("Supplier orders"),
              state: state,
              dispatch: dispatch,
              columns: columns,
              filters: filters,
              actions: actions,
              banner: banner,
              searchBar: searchBar,
              emptyState: emptyState,
              defaultQueryVariables: defaultQueryVariables
            });
}

var make$1 = React.memo(OrderListPage);

export {
  Query ,
  NotificationBanner ,
  OrderExportMenuItem ,
  OrderProductExportMenuItem ,
  Filters ,
  Row ,
  Scaffolded ,
  SheetExportInput ,
  make$1 as make,
}
/* query Not a pure module */
