// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Badge$Pos from "../../resources/feedback-indicators/Badge.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../../resources/theme/FontSizes.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as CatalogProduct$Pos from "./CatalogProduct.bs.js";
import * as OverlayTriggerView$Pos from "../../resources/overlays/OverlayTriggerView.bs.js";
import CountriesJson from "./assets/countries.json";
import * as CatalogProductEditForm$Pos from "./CatalogProductEditForm.bs.js";
import * as CatalogProductTaxSelect$Pos from "./CatalogProductTaxSelect.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var InputColorUnexpectedKind = /* @__PURE__ */Caml_exceptions.create("CatalogProductEditFormInputs-Pos.InputColorUnexpectedKind");

var styles = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 3, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(8.0)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(3.0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      text: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, FontSizes$Pos.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            FontFaces$Pos.libreFranklinRegularStyle
          ])
    });

var make = React.memo(function (Props) {
      var children = Props.children;
      var color = Props.color;
      var match = CatalogProduct$Pos.Color.toColorSet(color, "badge");
      var foregroundColor = match.foregroundColor;
      var borderColor = foregroundColor + "1A";
      return React.createElement(Badge$Pos.make, {
                  children: children,
                  borderColor: borderColor,
                  backgroundColor: match.backgroundColor,
                  foregroundColor: foregroundColor
                });
    });

var ColorItem = {
  styles: styles,
  make: make
};

function CatalogProductEditFormInputs$ColorSelect(Props) {
  var match = Curry._1(CatalogProductEditForm$Pos.useFormState, undefined);
  var validation = match.validation;
  var errorMessage;
  if (validation.TAG === /* Ok */0) {
    errorMessage = undefined;
  } else {
    var tmp = match.submission;
    errorMessage = typeof tmp === "number" || tmp.TAG !== /* Failed */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(validation._0, (function (param) {
                  if (param[0]._0 !== 2) {
                    return ;
                  } else {
                    return param[1];
                  }
                })), 0);
  }
  var renderTriggerView = function (param, item, hovered, active, focused) {
    var itemLabel = Belt_Option.mapWithDefault(item, Intl$Pos.t("Select a color"), (function (item) {
            return item.label;
          }));
    var color = CatalogProduct$Pos.Color.fromString(itemLabel);
    var tmp;
    if (color.TAG === /* Ok */0) {
      var color$1 = color._0;
      var colorLabel = CatalogProduct$Pos.Color.toLabel(color$1);
      tmp = React.createElement(make, {
            children: colorLabel,
            color: color$1
          });
    } else {
      tmp = React.createElement(TextStyle$Pos.make, {
            children: Intl$Pos.t("Select a color"),
            variation: "normal"
          });
    }
    return React.createElement(OverlayTriggerView$Pos.make, {
                children: tmp,
                variation: {
                  NAME: "field",
                  VAL: {
                    required: true,
                    errorMessage: errorMessage
                  }
                },
                label: Intl$Pos.t("Color"),
                hovered: hovered,
                active: active,
                focused: focused
              });
  };
  var renderItemContent = React.useCallback((function (color) {
          var color$1 = color.value;
          if (color$1 === undefined) {
            return null;
          }
          var colorLabel = CatalogProduct$Pos.Color.toLabel(color$1);
          return React.createElement(make, {
                      children: colorLabel,
                      color: color$1
                    });
        }), []);
  var match$1 = Curry._1(CatalogProductEditForm$Pos.useFormState, undefined).initialValues.kind;
  var options;
  if (match$1 === "BEER") {
    options = [
      "WHITE",
      "RED",
      "AMBER",
      "BLOND",
      "DARK",
      "BLACK"
    ];
  } else if (match$1 === "WINE") {
    options = [
      "WHITE",
      "RED",
      "ROSE",
      "ORANGE"
    ];
  } else {
    throw {
          RE_EXN_ID: InputColorUnexpectedKind,
          Error: new Error()
        };
  }
  var sections = [{
      items: Belt_Array.map(options, (function (color) {
              return {
                      key: CatalogProduct$Pos.Color.toString(color),
                      value: color,
                      label: CatalogProduct$Pos.Color.toString(color)
                    };
            }))
    }];
  return React.createElement(CatalogProductEditForm$Pos.Select.make, {
              field: /* Color */2,
              label: Intl$Pos.t("Color"),
              searchable: false,
              renderTriggerView: renderTriggerView,
              renderItemContent: renderItemContent,
              sections: sections
            });
}

var ColorSelect = {
  ColorItem: ColorItem,
  make: CatalogProductEditFormInputs$ColorSelect
};

function CatalogProductEditFormInputs$WineTypeSelect(Props) {
  var options = [
    "STILL",
    "EFFERVESCENT"
  ];
  var items = Belt_Array.map(options, (function (wineType) {
          return {
                  key: CatalogProduct$Pos.WineType.toString(wineType),
                  value: wineType,
                  label: CatalogProduct$Pos.WineType.toLabel(wineType)
                };
        }));
  var sections = [{
      items: items
    }];
  return React.createElement(CatalogProductEditForm$Pos.Select.make, {
              field: /* WineType */11,
              label: Intl$Pos.t("Wine type"),
              placeholder: Intl$Pos.t("Select a wine type"),
              sections: sections
            });
}

var WineTypeSelect = {
  make: CatalogProductEditFormInputs$WineTypeSelect
};

function CatalogProductEditFormInputs$WhiteWineTypeSelect(Props) {
  var options = [
    "DRY",
    "SEMIDRY",
    "SOFT",
    "SWEET"
  ];
  var items = Belt_Array.map(options, (function (whiteWineType) {
          return {
                  key: CatalogProduct$Pos.WhiteWineType.toString(whiteWineType),
                  value: whiteWineType,
                  label: CatalogProduct$Pos.WhiteWineType.toLabel(whiteWineType)
                };
        }));
  var sections = [{
      items: items
    }];
  return React.createElement(CatalogProductEditForm$Pos.Select.make, {
              field: /* WhiteWineType */12,
              label: Intl$Pos.t("White wine type"),
              placeholder: Intl$Pos.t("Select a white wine type"),
              sections: sections
            });
}

var WhiteWineTypeSelect = {
  make: CatalogProductEditFormInputs$WhiteWineTypeSelect
};

var Raw = {};

var query = Client.gql(["query productProducers  {\nproductProducers  \n}\n"]);

function parse(value) {
  var value$1 = value.productProducers;
  return {
          productProducers: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize(value) {
  var value$1 = value.productProducers;
  var productProducers = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          productProducers: productProducers
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var ProductProducersQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ProductProducersQuery_refetchQueryDescription = include.refetchQueryDescription;

var ProductProducersQuery_useLazy = include.useLazy;

var ProductProducersQuery_useLazyWithVariables = include.useLazyWithVariables;

var ProductProducersQuery = {
  ProductProducersQuery_inner: ProductProducersQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: ProductProducersQuery_refetchQueryDescription,
  use: use,
  useLazy: ProductProducersQuery_useLazy,
  useLazyWithVariables: ProductProducersQuery_useLazyWithVariables
};

function CatalogProductEditFormInputs$ProductProducerComboBox(Props) {
  var results = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var match = results.data;
  return React.createElement(CatalogProductEditForm$Pos.SuggestionComboBox.make, {
              field: /* Producer */5,
              label: Intl$Pos.t("Producer"),
              placeholder: Intl$Pos.t("Add a producer"),
              hideRequired: true,
              items: match !== undefined ? Belt_Array.map(Belt_Array.keep(match.productProducers, (function (suggestion) {
                            return suggestion !== "";
                          })), (function (productProducer) {
                        return {
                                value: productProducer
                              };
                      })) : []
            });
}

var ProductProducerComboBox = {
  ProductProducersQuery: ProductProducersQuery,
  make: CatalogProductEditFormInputs$ProductProducerComboBox
};

var Raw$1 = {};

var query$1 = Client.gql(["query productWineDesignations  {\nproductWineDesignations  \n}\n"]);

function parse$1(value) {
  var value$1 = value.productWineDesignations;
  return {
          productWineDesignations: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize$1(value) {
  var value$1 = value.productWineDesignations;
  var productWineDesignations = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          productWineDesignations: productWineDesignations
        };
}

function serializeVariables$1(param) {
  
}

function makeVariables$1(param) {
  
}

function makeDefaultVariables$1(param) {
  
}

var ProductWineDesignationsQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var ProductWineDesignationsQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var ProductWineDesignationsQuery_useLazy = include$1.useLazy;

var ProductWineDesignationsQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var ProductWineDesignationsQuery = {
  ProductWineDesignationsQuery_inner: ProductWineDesignationsQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: ProductWineDesignationsQuery_refetchQueryDescription,
  use: use$1,
  useLazy: ProductWineDesignationsQuery_useLazy,
  useLazyWithVariables: ProductWineDesignationsQuery_useLazyWithVariables
};

function CatalogProductEditFormInputs$ProductDesignationComboBox(Props) {
  var results = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var match = results.data;
  return React.createElement(CatalogProductEditForm$Pos.SuggestionComboBox.make, {
              field: /* Designation */7,
              label: Intl$Pos.t("Designation"),
              placeholder: Intl$Pos.t("Add a designation"),
              items: match !== undefined ? Belt_Array.map(Belt_Array.keep(match.productWineDesignations, (function (suggestion) {
                            return suggestion !== "";
                          })), (function (productWineDesignation) {
                        return {
                                value: productWineDesignation
                              };
                      })) : []
            });
}

var ProductDesignationComboBox = {
  ProductWineDesignationsQuery: ProductWineDesignationsQuery,
  make: CatalogProductEditFormInputs$ProductDesignationComboBox
};

var Raw$2 = {};

var query$2 = Client.gql(["query productSpirituousFamilies  {\nproductSpirituousFamilies  \n}\n"]);

function parse$2(value) {
  var value$1 = value.productSpirituousFamilies;
  return {
          productSpirituousFamilies: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize$2(value) {
  var value$1 = value.productSpirituousFamilies;
  var productSpirituousFamilies = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          productSpirituousFamilies: productSpirituousFamilies
        };
}

function serializeVariables$2(param) {
  
}

function makeVariables$2(param) {
  
}

function makeDefaultVariables$2(param) {
  
}

var ProductSpirituousFamiliesQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  makeDefaultVariables: makeDefaultVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var ProductSpirituousFamiliesQuery_refetchQueryDescription = include$2.refetchQueryDescription;

var ProductSpirituousFamiliesQuery_useLazy = include$2.useLazy;

var ProductSpirituousFamiliesQuery_useLazyWithVariables = include$2.useLazyWithVariables;

var ProductSpirituousFamiliesQuery = {
  ProductSpirituousFamiliesQuery_inner: ProductSpirituousFamiliesQuery_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  makeDefaultVariables: makeDefaultVariables$2,
  refetchQueryDescription: ProductSpirituousFamiliesQuery_refetchQueryDescription,
  use: use$2,
  useLazy: ProductSpirituousFamiliesQuery_useLazy,
  useLazyWithVariables: ProductSpirituousFamiliesQuery_useLazyWithVariables
};

function CatalogProductEditFormInputs$ProductFamilyComboBox(Props) {
  var results = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var match = results.data;
  return React.createElement(CatalogProductEditForm$Pos.SuggestionComboBox.make, {
              field: /* Family */6,
              label: Intl$Pos.t("Family"),
              placeholder: Intl$Pos.t("Add a family"),
              items: match !== undefined ? Belt_Array.map(Belt_Array.keep(match.productSpirituousFamilies, (function (suggestion) {
                            return suggestion !== "";
                          })), (function (productSpirituousFamily) {
                        return {
                                value: productSpirituousFamily
                              };
                      })) : []
            });
}

var ProductFamilyComboBox = {
  ProductSpirituousFamiliesQuery: ProductSpirituousFamiliesQuery,
  make: CatalogProductEditFormInputs$ProductFamilyComboBox
};

var Raw$3 = {};

var query$3 = Client.gql(["query productBeerTypes  {\nproductBeerTypes  \n}\n"]);

function parse$3(value) {
  var value$1 = value.productBeerTypes;
  return {
          productBeerTypes: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize$3(value) {
  var value$1 = value.productBeerTypes;
  var productBeerTypes = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          productBeerTypes: productBeerTypes
        };
}

function serializeVariables$3(param) {
  
}

function makeVariables$3(param) {
  
}

function makeDefaultVariables$3(param) {
  
}

var ProductBeerTypesQuery_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  makeDefaultVariables: makeDefaultVariables$3
};

var include$3 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3
    });

var use$3 = include$3.use;

var ProductBeerTypesQuery_refetchQueryDescription = include$3.refetchQueryDescription;

var ProductBeerTypesQuery_useLazy = include$3.useLazy;

var ProductBeerTypesQuery_useLazyWithVariables = include$3.useLazyWithVariables;

var ProductBeerTypesQuery = {
  ProductBeerTypesQuery_inner: ProductBeerTypesQuery_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  makeDefaultVariables: makeDefaultVariables$3,
  refetchQueryDescription: ProductBeerTypesQuery_refetchQueryDescription,
  use: use$3,
  useLazy: ProductBeerTypesQuery_useLazy,
  useLazyWithVariables: ProductBeerTypesQuery_useLazyWithVariables
};

function CatalogProductEditFormInputs$ProductBeerTypeComboBox(Props) {
  var results = Curry.app(use$3, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var match = results.data;
  return React.createElement(CatalogProductEditForm$Pos.SuggestionComboBox.make, {
              field: /* BeerType */10,
              label: Intl$Pos.t("Beer type"),
              placeholder: Intl$Pos.t("Add a beer type"),
              items: match !== undefined ? Belt_Array.map(Belt_Array.keep(match.productBeerTypes, (function (suggestion) {
                            return suggestion !== "";
                          })), (function (productBeerType) {
                        return {
                                value: productBeerType
                              };
                      })) : []
            });
}

var ProductBeerTypeComboBox = {
  ProductBeerTypesQuery: ProductBeerTypesQuery,
  make: CatalogProductEditFormInputs$ProductBeerTypeComboBox
};

var Raw$4 = {};

var query$4 = Client.gql(["query productRegions($country: String)  {\nproductRegions(country: $country)  \n}\n"]);

function parse$4(value) {
  var value$1 = value.productRegions;
  return {
          productRegions: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize$4(value) {
  var value$1 = value.productRegions;
  var productRegions = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          productRegions: productRegions
        };
}

function serializeVariables$4(inp) {
  var a = inp.country;
  return {
          country: a !== undefined ? a : undefined
        };
}

function makeVariables$4(country, param) {
  return {
          country: country
        };
}

function makeDefaultVariables$4(param) {
  return {
          country: undefined
        };
}

var ProductRegionsQuery_inner = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$4,
  makeVariables: makeVariables$4,
  makeDefaultVariables: makeDefaultVariables$4
};

var include$4 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$4,
      Raw: Raw$4,
      parse: parse$4,
      serialize: serialize$4,
      serializeVariables: serializeVariables$4
    });

var use$4 = include$4.use;

var ProductRegionsQuery_refetchQueryDescription = include$4.refetchQueryDescription;

var ProductRegionsQuery_useLazy = include$4.useLazy;

var ProductRegionsQuery_useLazyWithVariables = include$4.useLazyWithVariables;

var ProductRegionsQuery = {
  ProductRegionsQuery_inner: ProductRegionsQuery_inner,
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$4,
  makeVariables: makeVariables$4,
  makeDefaultVariables: makeDefaultVariables$4,
  refetchQueryDescription: ProductRegionsQuery_refetchQueryDescription,
  use: use$4,
  useLazy: ProductRegionsQuery_useLazy,
  useLazyWithVariables: ProductRegionsQuery_useLazyWithVariables
};

function CatalogProductEditFormInputs$ProductRegionComboBox(Props) {
  var match = Curry._1(CatalogProductEditForm$Pos.useFormState, undefined).values;
  var country = match.country;
  var queryVariableCountry;
  switch (country) {
    case "Afrique du Sud" :
        queryVariableCountry = "South Africa";
        break;
    case "Allemagne" :
        queryVariableCountry = "Germany";
        break;
    case "Argentine" :
        queryVariableCountry = "Argentina";
        break;
    case "Australie" :
        queryVariableCountry = "Australia";
        break;
    case "Autriche" :
        queryVariableCountry = "Austria";
        break;
    case "Croatie" :
        queryVariableCountry = "Croatia";
        break;
    case "Espagne" :
        queryVariableCountry = "Spain";
        break;
    case "Etats-Unis" :
        queryVariableCountry = "United States";
        break;
    case "Georgie" :
        queryVariableCountry = "Georgia";
        break;
    case "Gr\xc3\xa8ce" :
        queryVariableCountry = "Greece";
        break;
    case "Hongrie" :
        queryVariableCountry = "Hungary";
        break;
    case "Isra\xc3\xabl" :
        queryVariableCountry = "Israel";
        break;
    case "Italie" :
        queryVariableCountry = "Italy";
        break;
    case "Liban" :
        queryVariableCountry = "Lebanon";
        break;
    case "Nouvelle Z\xc3\xa9lande" :
        queryVariableCountry = "New Zealand";
        break;
    case "Slovaquie" :
        queryVariableCountry = "Slovakia";
        break;
    case "Suisse" :
        queryVariableCountry = "Switzerland";
        break;
    default:
      queryVariableCountry = country;
  }
  var results = Curry.app(use$4, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          country: queryVariableCountry
        }
      ]);
  var match$1 = results.data;
  return React.createElement(CatalogProductEditForm$Pos.SuggestionComboBox.make, {
              field: /* Region */9,
              label: Intl$Pos.t("Region"),
              placeholder: Intl$Pos.t("Add a region"),
              items: match$1 !== undefined ? Belt_Array.map(Belt_Array.keep(match$1.productRegions, (function (suggestion) {
                            return suggestion !== "";
                          })), (function (productRegion) {
                        return {
                                value: productRegion
                              };
                      })) : []
            });
}

var ProductRegionComboBox = {
  ProductRegionsQuery: ProductRegionsQuery,
  make: CatalogProductEditFormInputs$ProductRegionComboBox
};

var countries = CountriesJson;

function CatalogProductEditFormInputs$ProductCountrySelect(Props) {
  var items = Belt_Array.map(countries, (function (country) {
          return {
                  key: country,
                  value: country,
                  label: country
                };
        }));
  var sections = [{
      items: items
    }];
  return React.createElement(CatalogProductEditForm$Pos.Select.make, {
              field: /* Country */8,
              label: Intl$Pos.t("Country"),
              placeholder: Intl$Pos.t("Select a country"),
              sections: sections
            });
}

var ProductCountrySelect = {
  countries: countries,
  make: CatalogProductEditFormInputs$ProductCountrySelect
};

function CatalogProductEditFormInputs$ProductTaxSelect(Props) {
  var productKind = Props.productKind;
  var match = Curry._1(CatalogProductEditForm$Pos.useFormState, undefined);
  var dispatch = Curry._1(CatalogProductEditForm$Pos.useFormDispatch, undefined);
  var defaultTaxValue = productKind === "SIMPLE" ? 5.5 : 20;
  var value = Belt_Option.map(match.values.tax, (function (tax) {
          return {
                  id: tax.id,
                  value: tax.value
                };
        }));
  var onChange = React.useCallback((function (tax) {
          Curry._1(dispatch, {
                TAG: /* FieldValueChanged */2,
                _0: /* Tax */3,
                _1: (function (param) {
                    return {
                            id: tax.id,
                            value: tax.value
                          };
                  })
              });
        }), []);
  return React.createElement(CatalogProductTaxSelect$Pos.make, {
              value: value,
              defaultTaxValue: defaultTaxValue,
              onChange: onChange
            });
}

var ProductTaxSelect = {
  make: CatalogProductEditFormInputs$ProductTaxSelect
};

export {
  InputColorUnexpectedKind ,
  ColorSelect ,
  WineTypeSelect ,
  WhiteWineTypeSelect ,
  ProductProducerComboBox ,
  ProductDesignationComboBox ,
  ProductFamilyComboBox ,
  ProductBeerTypeComboBox ,
  ProductRegionComboBox ,
  ProductCountrySelect ,
  ProductTaxSelect ,
}
/* styles Not a pure module */
