// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Moment from "moment";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDates from "react-dates";
import * as ReactDates$Pos from "../../externals/ReactDates.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

var defaultFocusedInput = ReactDates$Pos.DayPickerRangeController.defaultFocusedInput;

function DayPickerRange(Props) {
  var startDate = Props.startDate;
  var endDate = Props.endDate;
  var maxDate = Props.maxDate;
  var initialVisibleMonth = Props.initialVisibleMonth;
  var isOutsideRange = Props.isOutsideRange;
  var focusedInput = Props.focusedInput;
  var onChange = Props.onChange;
  var onFocusChange = Props.onFocusChange;
  var onPrevMonthClick = Props.onPrevMonthClick;
  var onNextMonthClick = Props.onNextMonthClick;
  var tmp = {
    startDate: Js_null_undefined.fromOption(Belt_Option.map(startDate, (function (prim) {
                return Moment(prim);
              }))),
    endDate: Js_null_undefined.fromOption(Belt_Option.map(endDate, (function (prim) {
                return Moment(prim);
              }))),
    onDatesChange: (function (param) {
        Curry._1(onChange, [
              Belt_Option.map(Caml_option.nullable_to_opt(param.startDate), (function (prim) {
                      return prim.toDate();
                    })),
              Belt_Option.map(Caml_option.nullable_to_opt(param.endDate), (function (prim) {
                      return prim.toDate();
                    }))
            ]);
      }),
    onFocusChange: onFocusChange,
    transitionDuration: 0,
    noBorder: true,
    focusedInput: focusedInput,
    minimumNights: 0
  };
  var tmp$1 = Belt_Option.map(onPrevMonthClick, (function (onPrevMonthClick, moment) {
          Curry._1(onPrevMonthClick, moment.toDate());
        }));
  if (tmp$1 !== undefined) {
    tmp.onPrevMonthClick = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(onNextMonthClick, (function (onNextMonthClick, moment) {
          Curry._1(onNextMonthClick, moment.toDate());
        }));
  if (tmp$2 !== undefined) {
    tmp.onNextMonthClick = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = Belt_Option.map(initialVisibleMonth, (function (initialVisibleMonth, param) {
          return Moment(Curry._1(initialVisibleMonth, undefined));
        }));
  if (tmp$3 !== undefined) {
    tmp.initialVisibleMonth = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = Belt_Option.map(maxDate, (function (maxDate) {
          return Moment(maxDate);
        }));
  if (tmp$4 !== undefined) {
    tmp.maxDate = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = Belt_Option.map(isOutsideRange, (function (isOutsideRange, moment) {
          return Curry._1(isOutsideRange, moment.toDate());
        }));
  if (tmp$5 !== undefined) {
    tmp.isOutsideRange = Caml_option.valFromOption(tmp$5);
  }
  return React.createElement(ReactDates.DayPickerRangeController, tmp);
}

var make = DayPickerRange;

export {
  defaultFocusedInput ,
  make ,
}
/* react Not a pure module */
