// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Style$Pos from "./Style.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var style = Props.style;
      var ref$1 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      var style$1 = Style$Pos.arrayOptionStyle([
            Caml_option.some(Style$Pos.unsafeCss({
                      wordBreak: "breakWord"
                    })),
            style
          ]);
      var tmp = {
        children: children,
        style: style$1
      };
      if (ref$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(ref$1);
      }
      return React.createElement(ReactNative.Text, tmp);
    });

make.displayName = "Text";

function makeProps(prim0, prim1, prim2, prim3, prim4) {
  var tmp = {
    children: prim0
  };
  if (prim1 !== undefined) {
    tmp.style = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.key = prim2;
  }
  if (prim3 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim3);
  }
  return tmp;
}

export {
  makeProps ,
  make ,
}
/* make Not a pure module */
