// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Accordion$Pos from "../../resources/layout-and-structure/Accordion.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as Accounting__Utils from "@wino/accounting/src/Accounting__Utils.bs.js";

var styles = StyleSheet$Pos.create({
      wrapper: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexEnd", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      row: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(1)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(3)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      subrow: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(28)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      label: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(194)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      amount: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(100)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function currencyFormat(value) {
  return Intl$Pos.currencyFormat("EUR", 3, 3, value);
}

function CartAmountsView(Props) {
  var cart = Props.cart;
  var formattedTotalAmountExcludingTaxes = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountExcludingTaxes)));
  var formattedTotalAmountExcludingGlobalDiscounts = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountExcludingGlobalDiscounts)));
  var formattedTotalAmountOfGoods = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountOfGoods)));
  var formattedTotalAmountIncludingTaxes = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(cart.totalAmountIncludingTaxes)));
  var triggerToggleView = React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.row), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("Total amount excl. VAT:"),
                        weight: cart.taxesFree ? "strong" : "regular",
                        size: "large"
                      })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                        children: formattedTotalAmountExcludingTaxes,
                        weight: cart.taxesFree ? "strong" : "regular",
                        size: "large"
                      })), Caml_option.some(styles.amount), undefined, undefined, undefined)));
  var discount = Belt_Array.get(cart.discounts, 0);
  var tmp;
  if (discount !== undefined && discount.value > 0) {
    var formattedDiscountAmount = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(discount.amount)));
    tmp = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.row), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Sub total excl. VAT"),
                              size: "large"
                            })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                              children: formattedTotalAmountExcludingGlobalDiscounts,
                              size: "large"
                            })), Caml_option.some(styles.amount), undefined, undefined, undefined))), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.row), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.template(Intl$Pos.t("Discount {{amount}}"), {
                                    amount: Belt_Option.getExn(discount.formattedValue)
                                  }, undefined),
                              size: "large"
                            })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                              children: formattedDiscountAmount,
                              size: "large"
                            })), Caml_option.some(styles.amount), undefined, undefined, undefined))));
  } else {
    tmp = null;
  }
  var taxes = cart.taxes;
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.wrapper), undefined, undefined, undefined), tmp, React.createElement(Accordion$Pos.make, {
                  children: React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                                    children: null,
                                    space: "xxsmall"
                                  }, React.createElement(Inline$Pos.make, {
                                        children: null
                                      }, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                        children: Intl$Pos.t("Goods total excl. VAT"),
                                                        variation: "normal",
                                                        size: "xsmall"
                                                      })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                        children: formattedTotalAmountOfGoods,
                                                        variation: "normal",
                                                        size: "xsmall"
                                                      })), Caml_option.some(styles.amount), undefined, undefined, undefined))), Belt_Array.mapWithIndex(Belt_Option.getExn(cart.fees), (function (index, fee) {
                                          var formattedFeeAmount = currencyFormat(Accounting__Utils.bigToFloat(fee.amount));
                                          var match = fee.kind;
                                          var tmp;
                                          switch (match) {
                                            case /* Transport */0 :
                                                tmp = Intl$Pos.t("Transport");
                                                break;
                                            case /* Taxes */1 :
                                                tmp = Intl$Pos.t("Taxes");
                                                break;
                                            case /* Other */2 :
                                                tmp = Intl$Pos.t("Other");
                                                break;
                                            
                                          }
                                          return React.createElement(Inline$Pos.make, {
                                                      children: null,
                                                      key: String(index)
                                                    }, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                                      children: Intl$Pos.template(Intl$Pos.t("Total {{feeKind}}"), {
                                                                            feeKind: tmp
                                                                          }, undefined),
                                                                      variation: "normal",
                                                                      size: "xsmall"
                                                                    })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                                      children: formattedFeeAmount,
                                                                      variation: "normal",
                                                                      size: "xsmall"
                                                                    })), Caml_option.some(styles.amount), undefined, undefined, undefined)));
                                        })), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))), Caml_option.some(styles.subrow), undefined, undefined, undefined)),
                  triggerShowView: triggerToggleView,
                  triggerHideView: triggerToggleView
                }), taxes !== undefined ? Belt_Array.map(taxes, (function (tax) {
                      var formattedTaxAmount = currencyFormat(Accounting__Utils.bigToFloat(Belt_Option.getExn(tax.amount)));
                      return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.row), formattedTaxAmount + tax.rate.toString(), undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                  children: Intl$Pos.template(Intl$Pos.t("VAT {{amount}}"), {
                                                        amount: tax.rate.toString()
                                                      }, undefined),
                                                  size: "large"
                                                })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                  children: formattedTaxAmount,
                                                  size: "large"
                                                })), Caml_option.some(styles.amount), undefined, undefined, undefined)));
                    })) : null, cart.taxesFree ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                children: Intl$Pos.template(Intl$Pos.t("{{amount}} of deductible VAT (0.00%)"), {
                                      amount: currencyFormat(0.00)
                                    }, undefined),
                                variation: "normal",
                                size: "large"
                              })), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(28)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined)) : React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.row), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                    children: Intl$Pos.t("Total amount incl. VAT:"),
                                    weight: "strong",
                                    size: "large"
                                  })), Caml_option.some(styles.label), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                    children: formattedTotalAmountIncludingTaxes,
                                    weight: "strong",
                                    size: "large"
                                  })), Caml_option.some(styles.amount), undefined, undefined, undefined))));
}

var make = React.memo(CartAmountsView, (function (oldProps, newProps) {
        return oldProps.cart === newProps.cart;
      }));

var bigToFloat = Accounting__Utils.bigToFloat;

export {
  styles ,
  bigToFloat ,
  currencyFormat ,
  make ,
}
/* styles Not a pure module */
