// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Badge$Pos from "../feedback-indicators/Badge.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Image$Pos from "../../primitives/Image.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Burger$Pos from "./Burger.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../overlays/Popover.bs.js";
import * as Tooltip$Pos from "../overlays/Tooltip.bs.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as IconButton$Pos from "../actions/IconButton.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as Utils from "@react-aria/utils";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as ReactDomElement$Pos from "../../primitives/ReactDomElement.bs.js";
import Nav_default_profilePng from "./Nav_default_profile.png";

var initialState = {
  opened: false
};

var context = React.createContext([
      undefined,
      (function (param) {
          
        })
    ]);

function use(param) {
  var match = React.useContext(context);
  var state = match[0];
  if (state !== undefined) {
    return [
            state,
            match[1]
          ];
  } else {
    return [
            initialState,
            (function (param) {
                
              })
          ];
  }
}

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider = {
  make: make,
  makeProps: makeProps
};

function getReducer(param) {
  return React.useReducer((function (state, action) {
                return {
                        opened: !state.opened
                      };
              }), initialState);
}

var Context = {
  initialState: initialState,
  context: context,
  use: use,
  Provider: Provider,
  getReducer: getReducer
};

var styles = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, "row", undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(42)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      text: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(4)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  whiteSpace: "nowrap"
                })
          ]),
      textActive: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor90, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      textHovered: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor70, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      textImportant: Style$Pos.merge([
            FontFaces$Pos.archivoSemiBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor00, undefined, FontSizes$Pos.large, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      textPrimary: Style$Pos.merge([
            FontFaces$Pos.archivoRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor50, undefined, FontSizes$Pos.normal, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function textStyleFromParams(variation, active, hovered) {
  return Style$Pos.arrayStyle([variation === "important" ? styles.textImportant : (
                  variation === "primary" ? (
                      active ? Style$Pos.arrayStyle([
                              styles.textPrimary,
                              styles.textActive
                            ]) : (
                          hovered ? Style$Pos.arrayStyle([
                                  styles.textPrimary,
                                  styles.textHovered
                                ]) : styles.textPrimary
                        )
                    ) : styles.textPrimary
                )]);
}

function iconColorFromParams(active, hovered, navOpened) {
  if (active) {
    return Colors$Pos.neutralColor90;
  } else if (hovered) {
    if (navOpened) {
      return Colors$Pos.neutralColor70;
    } else {
      return Colors$Pos.neutralColor90;
    }
  } else {
    return Colors$Pos.neutralColor35;
  }
}

function Nav$TextIcon(Props) {
  var children = Props.children;
  var icon = Props.icon;
  var activeOpt = Props.active;
  var variationOpt = Props.variation;
  var hoveredParentOpt = Props.hovered;
  var active = activeOpt !== undefined ? activeOpt : false;
  var variation = variationOpt !== undefined ? variationOpt : "primary";
  var hoveredParent = hoveredParentOpt !== undefined ? hoveredParentOpt : false;
  var match = use(undefined);
  var nav = match[0];
  var match$1 = Hover$Pos.use(undefined, undefined);
  var hovered = match$1[1];
  var tmp;
  if (nav.opened) {
    tmp = React.createElement(Text$Pos.make, Text$Pos.makeProps(children, Caml_option.some(Style$Pos.arrayStyle([
                      styles.text,
                      textStyleFromParams(variation, active, hovered || hoveredParent)
                    ])), undefined, undefined, undefined));
  } else {
    var textContent = children.replace("&#x27;", "'");
    tmp = React.createElement(Tooltip$Pos.make, {
          placement: "start",
          offset: -4,
          crossOffset: 1,
          content: {
            TAG: /* Text */0,
            _0: textContent
          },
          delay: 0,
          opened: hovered
        });
  }
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.view), undefined, match$1[0], undefined), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Icon$Pos.make, {
                              name: icon,
                              fill: variation === "important" ? Colors$Pos.neutralColor00 : iconColorFromParams(active, hovered || hoveredParent, nav.opened)
                            })), "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), tmp);
}

var make$1 = React.memo(Nav$TextIcon);

var styles$1 = StyleSheet$Pos.create({
      header: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(42.0)), "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      label: Style$Pos.merge([
            FontFaces$Pos.archivoRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1.0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(57.75)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      labelHovered: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor90, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      labelActive: Style$Pos.merge([
            FontFaces$Pos.archivoSemiBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor00, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      labelActiveHovered: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor00, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      content: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(999)), undefined, undefined, undefined, "hidden", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      contentClosed: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      list: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(4)), undefined, Caml_option.some(Style$Pos.dp(12)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function contentStyleFromParams(opened) {
  return Style$Pos.arrayOptionStyle([opened ? undefined : Caml_option.some(styles$1.contentClosed)]);
}

function Nav$Section(Props) {
  var children = Props.children;
  var title = Props.title;
  var active = Props.active;
  var icon = Props.icon;
  var nav = use(undefined)[0];
  var url = Navigation$Pos.useUrl(undefined);
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setOpened = match$1[1];
  var opened = match$1[0];
  var match$2 = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match$2.ref;
  var popoverAriaProps = match$2.ariaProps;
  var popover = match$2.state;
  React.useEffect((function () {
          if (!nav.opened) {
            Curry._1(setOpened, (function (param) {
                    return false;
                  }));
          }
          
        }), [nav.opened]);
  React.useEffect((function () {
          Curry._1(popover.close, undefined);
        }), [url.pathname]);
  return React.createElement(React.Fragment, undefined, nav.opened ? React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                        children: null,
                                        align: "spaceBetween"
                                      }, React.createElement(make$1, {
                                            children: title,
                                            icon: icon,
                                            active: active,
                                            hovered: hovered
                                          }), React.createElement(Icon$Pos.make, {
                                            name: opened ? "arrow_up_light" : "arrow_down_light",
                                            fill: hovered || active ? Colors$Pos.neutralColor70 : Colors$Pos.neutralColor30
                                          }))), Caml_option.some(styles$1.header), undefined, match[0], undefined)), undefined, undefined, undefined, undefined, undefined, (function (param) {
                          Curry._1(setOpened, (function (opened) {
                                  return !opened;
                                }));
                        }), undefined, undefined, undefined)) : React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(make$1, {
                                        children: title,
                                        icon: icon,
                                        active: active,
                                        hovered: hovered
                                      })), Caml_option.some(styles$1.header), undefined, popoverTriggerRef, undefined)), popoverAriaProps.triggerProps, undefined, undefined, undefined, undefined, (function (param) {
                          Curry._1(popover.toggle, undefined);
                        }), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(children), Caml_option.some(styles$1.list), undefined, undefined, undefined))), Caml_option.some(Style$Pos.arrayStyle([
                              styles$1.content,
                              contentStyleFromParams(opened)
                            ])), undefined, undefined, undefined)), popover.isOpen ? React.createElement(Popover$Pos.make, {
                    children: React.createElement(Popover$Pos.Dialog.make, {
                          children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(children), "medium", "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                          ariaProps: popoverAriaProps.overlayProps
                        }),
                    triggerRef: popoverTriggerRef,
                    state: popover,
                    variation: "arrowed",
                    placement: "end",
                    offset: 0,
                    crossOffset: 1
                  }) : null);
}

var make$2 = React.memo(Nav$Section);

var styles$2 = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(42)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(10)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(6)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      label: Style$Pos.merge([
            FontFaces$Pos.archivoRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, FontSizes$Pos.normal, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      labelNormal: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      labelNormalHovered: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor70, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      labelImportant: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.dangerColor50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      labelImportantHovered: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.dangerColor60, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function labelStyleFromParams(variation, hovered) {
  return Style$Pos.arrayStyle([
              styles$2.label,
              variation === "normal" ? (
                  hovered ? styles$2.labelNormalHovered : styles$2.labelNormal
                ) : (
                  hovered ? styles$2.labelImportantHovered : styles$2.labelImportant
                )
            ]);
}

function Nav$Button(Props) {
  var label = Props.label;
  var onPress = Props.onPress;
  var icon = Props.icon;
  var variationOpt = Props.variation;
  var variation = variationOpt !== undefined ? variationOpt : "normal";
  var match = Hover$Pos.use(undefined, undefined);
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(label !== undefined && onPress !== undefined ? (
                        icon !== undefined ? React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(make$1, {
                                        children: label,
                                        icon: icon
                                      }), undefined, undefined, undefined, undefined, undefined, (function (param) {
                                      Curry._1(onPress, undefined);
                                    }), undefined, undefined, undefined)) : React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Text$Pos.make, Text$Pos.makeProps(label, Caml_option.some(labelStyleFromParams(variation, match[1])), undefined, undefined, undefined))), undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined, undefined, undefined, (function (param) {
                                      Curry._1(onPress, undefined);
                                    }), undefined, undefined, undefined))
                      ) : null), undefined, undefined, match[0], undefined));
}

var make$3 = React.memo(Nav$Button);

var defaultProfilePictureUri = Nav_default_profilePng;

var containerSize = Style$Pos.dp(32);

var styles$3 = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.backgroundDefaultColortemplate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(64)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.medium)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.medium)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      moreView: Style$Pos.merge([Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.backgroundDefaultColortemplate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)]),
      moreViewOpened: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(300)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  transitionDuration: ".55s",
                  transitionProperty: "max-height"
                })
          ]),
      moreViewClosed: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  transitionDuration: ".15s",
                  transitionProperty: "max-height"
                })
          ]),
      moreList: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(23)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xxsmall)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.medium)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(containerSize), "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerClosed: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(containerSize), undefined, undefined),
      inline: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      profilePicture: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.transparent, undefined, undefined, undefined, undefined, 100, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(containerSize), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(containerSize), undefined, undefined),
      username: Style$Pos.merge([
            FontFaces$Pos.archivoRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor50, undefined, FontSizes$Pos.normal, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.normal)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  overflow: "hidden",
                  width: "100%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                })
          ])
    });

function profilePictureFromParams(hovered, active) {
  return Style$Pos.arrayOptionStyle([
              Caml_option.some(styles$3.profilePicture),
              hovered ? Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor25, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : undefined,
              active ? Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor30, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : undefined
            ]);
}

function containerStyleFromParams(opened) {
  return Style$Pos.arrayOptionStyle([opened ? undefined : Caml_option.some(styles$3.containerClosed)]);
}

function moreViewStyleFromParams(opened) {
  return Style$Pos.arrayOptionStyle([opened ? Caml_option.some(styles$3.moreViewOpened) : Caml_option.some(styles$3.moreViewClosed)]);
}

function Nav$Footer(Props) {
  var children = Props.children;
  var userName = Props.userName;
  var userProfilePictureUri = Props.userProfilePictureUri;
  var match = use(undefined);
  var nav = match[0];
  var url = Navigation$Pos.useUrl(undefined);
  var match$1 = Hover$Pos.use(undefined, undefined);
  var hoveredProfilePicture = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setMoreOpened = match$2[1];
  var moreOpened = match$2[0];
  var match$3 = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match$3.ref;
  var popover = match$3.state;
  React.useEffect((function () {
          Curry._1(popover.close, undefined);
        }), [url.pathname]);
  React.useEffect((function () {
          if (!nav.opened) {
            Curry._1(setMoreOpened, (function (param) {
                    return false;
                  }));
          }
          
        }), [nav.opened]);
  var handleFooterPress = function (param) {
    if (nav.opened) {
      return Curry._1(setMoreOpened, (function (moreOpened) {
                    return !moreOpened;
                  }));
    } else {
      return Curry._1(popover.toggle, undefined);
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(children), Caml_option.some(styles$3.moreList), undefined, undefined, undefined))), Caml_option.some(Style$Pos.arrayStyle([
                              styles$3.moreView,
                              moreViewStyleFromParams(moreOpened)
                            ])), undefined, undefined, undefined)), React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$3.inline), undefined, popoverTriggerRef, undefined), React.createElement(Image$Pos.make, {
                                                      style: profilePictureFromParams(hoveredProfilePicture && !nav.opened, popover.isOpen),
                                                      source: Image$Pos.fromUriSource(Image$Pos.uriSource(userProfilePictureUri !== undefined ? userProfilePictureUri : defaultProfilePictureUri))
                                                    }), nav.opened ? React.createElement(React.Fragment, undefined, React.createElement(Text$Pos.make, Text$Pos.makeProps(userName, Caml_option.some(styles$3.username), undefined, undefined, undefined)), React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps(moreOpened ? "arrow_down_light" : "arrow_up_light", undefined, "xsmall", hoveredProfilePicture ? Colors$Pos.neutralColor70 : Colors$Pos.neutralColor30, Colors$Pos.neutralColor70, undefined, undefined, handleFooterPress, undefined, undefined, undefined))) : (
                                                    popover.isOpen ? React.createElement(Popover$Pos.make, {
                                                            children: React.createElement(Popover$Pos.Dialog.make, {
                                                                  children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(children), "medium", "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                                                                  ariaProps: match$3.ariaProps.overlayProps
                                                                }),
                                                            triggerRef: popoverTriggerRef,
                                                            state: popover,
                                                            variation: "arrowed",
                                                            placement: "end",
                                                            offset: 8
                                                          }) : null
                                                  ))), Caml_option.some(Style$Pos.arrayStyle([
                                                  styles$3.container,
                                                  containerStyleFromParams(nav.opened)
                                                ])), undefined, undefined, undefined))), Caml_option.some(styles$3.view), undefined, undefined, undefined)), undefined, undefined, undefined, undefined, undefined, handleFooterPress, undefined, match$1[0], undefined)));
}

var make$4 = React.memo(Nav$Footer);

var styles$4 = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(42)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      viewImportant: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.secondaryColor50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      viewImportantHovered: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.secondaryColor65, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      viewPrimary: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.transparent, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      label: Style$Pos.merge([
            FontFaces$Pos.archivoRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor50, undefined, FontSizes$Pos.normal, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(8)), undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      labelActiveHovered: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor90, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function viewStyleFromParams(variation, hovered, navOpened) {
  return Style$Pos.arrayStyle([
              styles$4.view,
              variation === "important" ? (
                  hovered ? styles$4.viewImportantHovered : styles$4.viewImportant
                ) : styles$4.viewPrimary
            ]);
}

function labelStyleFromParams$1(active, hovered) {
  return Style$Pos.arrayOptionStyle([
              Caml_option.some(styles$4.label),
              !active && hovered || active && !hovered || active && hovered ? Caml_option.some(styles$4.labelActiveHovered) : undefined
            ]);
}

function Nav$Link(Props) {
  var to = Props.to;
  var label = Props.label;
  var active = Props.active;
  var iconOpt = Props.icon;
  var badgeNewOpt = Props.badgeNew;
  var variationOpt = Props.variation;
  var icon = iconOpt !== undefined ? iconOpt : "ticket_bold";
  var badgeNew = badgeNewOpt !== undefined ? badgeNewOpt : false;
  var variation = variationOpt !== undefined ? variationOpt : "secondary";
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  var match$1 = use(undefined);
  return React.createElement(Navigation$Pos.Link.make, {
              children: React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                children: variation === "primary" || variation === "important" ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(make$1, {
                                                    children: label,
                                                    icon: icon,
                                                    variation: variation
                                                  })), Caml_option.some(viewStyleFromParams(variation, hovered, match$1[0].opened)), undefined, undefined, undefined)) : React.createElement(Inline$Pos.make, {
                                        children: null,
                                        space: "small"
                                      }, React.createElement(Text$Pos.make, Text$Pos.makeProps(label, Caml_option.some(labelStyleFromParams$1(active, hovered)), undefined, undefined, undefined)), badgeNew ? React.createElement(Badge$Pos.make, {
                                              children: Intl$Pos.t("BETA"),
                                              size: "compact",
                                              variation: "information"
                                            }) : null),
                                space: "medium",
                                align: "spaceBetween"
                              })), undefined, undefined, match[0], undefined)),
              to: to
            });
}

var styles$5 = StyleSheet$Pos.create({
      root: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor00, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor50, Caml_option.some(Style$Pos.shadowOffset(0, 3)), 0.1, 10, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "hidden", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), Caml_option.some(Style$Pos.dp(64)), 1, undefined),
            Style$Pos.unsafeCss({
                  transitionDuration: ".25s",
                  transitionProperty: "width",
                  position: "fixed"
                })
          ]),
      rootOpened: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(262.0)), undefined, undefined),
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(262.0)), undefined, undefined),
      header: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor10, undefined, undefined, undefined, undefined, Style$Pos.hairlineWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor50, Caml_option.some(Style$Pos.shadowOffset(1, 0.0)), 0.12, 10, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(70.0)), "spaceBetween", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      burger: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(10)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      userOrganizationName: Style$Pos.merge([
            FontFaces$Pos.archivoBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.brandColor50, undefined, 15, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      userOrganizationNameAndBadge: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexEnd", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(12)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      content: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(8)), Caml_option.some(Style$Pos.dp(17)), undefined, Caml_option.some(Style$Pos.dp(16)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(64.0)), undefined, undefined),
            Style$Pos.unsafeCss({
                  overflowY: "auto",
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                  transitionDuration: ".25s",
                  transitionProperty: "width"
                })
          ]),
      contentOpened: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined)
    });

function rootStyleFromParams(opened) {
  return Style$Pos.arrayStyle([
              styles$5.root,
              opened ? styles$5.rootOpened : Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
            ]);
}

function contentStyleFromParams$1(opened) {
  return Style$Pos.arrayStyle([
              styles$5.content,
              opened ? styles$5.contentOpened : Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
            ]);
}

var contentScrollShadeOverlay = {
  backgroundImage: "radial-gradient(ellipse at center, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 75%)",
  backgroundPosition: "center top",
  borderBottom: "1px solid " + Colors$Pos.neutralColor10,
  height: "16px",
  marginTop: "-16px",
  backgroundSize: "100% 200%",
  boxSizing: "border-box",
  pointerEvents: "none"
};

function Nav(Props) {
  var children = Props.children;
  var userOrganizationName = Props.userOrganizationName;
  var userName = Props.userName;
  var userProfilePictureUri = Props.userProfilePictureUri;
  var badge = Props.badge;
  var userImpersonating = Props.userImpersonating;
  var userSettingsRoute = Props.userSettingsRoute;
  var helpCenterUrl = Props.helpCenterUrl;
  var legacyDashboardUrl = Props.legacyDashboardUrl;
  var legacyDashboardText = Props.legacyDashboardText;
  var onToggleHelpCenter = Props.onToggleHelpCenter;
  var onRequestLogout = Props.onRequestLogout;
  var match = use(undefined);
  var dispatch = match[1];
  var nav = match[0];
  var match$1 = Navigation$Pos.useUrl(undefined);
  var match$2 = React.useState(function () {
        return false;
      });
  var setScrollable = match$2[1];
  var ref = React.useRef(null);
  var handleScrollable = React.useCallback((function (param) {
          var domElement = ref.current;
          if (domElement == null) {
            return ;
          }
          var hasNotReachedBottom = ((domElement.scrollTop | 0) + domElement.clientHeight | 0) < domElement.scrollHeight;
          Curry._1(setScrollable, (function (param) {
                  return hasNotReachedBottom;
                }));
        }), [ref]);
  React.useLayoutEffect((function () {
          var bodyDomElement = ReactDomElement$Pos.fromRef(ref);
          Curry._1(handleScrollable, undefined);
          Belt_Option.forEach(bodyDomElement, (function (domNode) {
                  domNode.addEventListener("scroll", handleScrollable);
                }));
          return (function (param) {
                    Belt_Option.forEach(bodyDomElement, (function (domEmement) {
                            domEmement.removeEventListener("scroll", handleScrollable);
                          }));
                  });
        }), []);
  Utils.useResizeObserver({
        ref: ref,
        onResize: handleScrollable
      });
  var tmp = {
    children: null,
    userName: userName
  };
  var tmp$1 = userImpersonating ? "/impersonating_user.png" : userProfilePictureUri;
  if (tmp$1 !== undefined) {
    tmp.userProfilePictureUri = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$5.container), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$5.header), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Burger$Pos.make, {
                                              onPress: (function (param) {
                                                  Curry._1(dispatch, /* Toggled */0);
                                                })
                                            })), Caml_option.some(styles$5.burger), undefined, undefined, undefined)), nav.opened ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$5.userOrganizationNameAndBadge), undefined, undefined, undefined), React.createElement(Text$Pos.make, Text$Pos.makeProps(userOrganizationName, Caml_option.some(styles$5.userOrganizationName), undefined, undefined, undefined)), badge !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(Caml_option.valFromOption(badge)), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null) : null), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(children), Caml_option.some(contentStyleFromParams$1(nav.opened)), undefined, ref, undefined)), React.createElement(AnimatedRender$Pos.make, {
                              children: React.createElement("div", {
                                    style: contentScrollShadeOverlay
                                  }),
                              displayed: match$2[0],
                              animation: "fade",
                              duration: 250
                            }), userImpersonating ? null : React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "small", "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(make$3, {
                                    label: Intl$Pos.t("Contact us"),
                                    onPress: (function (param) {
                                        Curry._1(onToggleHelpCenter, undefined);
                                      }),
                                    icon: "help_bold"
                                  }), React.createElement(Navigation$Pos.Link.make, {
                                    children: React.createElement(make$1, {
                                          children: Intl$Pos.t("Help center"),
                                          icon: "book_bold"
                                        }),
                                    to: {
                                      TAG: /* Url */2,
                                      _0: helpCenterUrl
                                    },
                                    openNewTab: true
                                  }), React.createElement(Navigation$Pos.Link.make, {
                                    children: React.createElement(make$1, {
                                          children: legacyDashboardText,
                                          icon: "switch_bold"
                                        }),
                                    to: {
                                      TAG: /* Url */2,
                                      _0: legacyDashboardUrl
                                    },
                                    openNewTab: true
                                  })), React.createElement(make$4, tmp, React.createElement(Nav$Link, {
                                  to: {
                                    TAG: /* Route */0,
                                    _0: userSettingsRoute
                                  },
                                  label: Intl$Pos.t("Your account"),
                                  active: match$1.pathname === userSettingsRoute
                                }), React.createElement(make$3, {
                                  label: Intl$Pos.t("Sign out"),
                                  onPress: (function (param) {
                                      Curry._1(onRequestLogout, undefined);
                                    }),
                                  variation: "important"
                                })))), Caml_option.some(rootStyleFromParams(nav.opened)), undefined, undefined, undefined));
}

var Section = {
  make: make$2
};

var Link = {
  make: Nav$Link
};

var openedSize = 262.0;

var closedSize = 64.0;

var make$5 = Nav;

export {
  Context ,
  Section ,
  Link ,
  openedSize ,
  closedSize ,
  make$5 as make,
}
/* context Not a pure module */
