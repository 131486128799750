// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "../layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as FilePicker$Pos from "../../primitives/FilePicker.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as ReactDomElement$Pos from "../../primitives/ReactDomElement.bs.js";

var styles = StyleSheet$Pos.create({
      main: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.brandColor00, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.brandColor50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "dashed", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      textWrapper: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(70)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      textTitle: Style$Pos.merge([
            FontFaces$Pos.archivoBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.brandColor50, undefined, FontSizes$Pos.huge, undefined, undefined, undefined, undefined, 0.125, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(4)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      textSubtitle: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor70, undefined, FontSizes$Pos.normal, undefined, undefined, undefined, undefined, 0.125, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(310)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function DropZone(Props) {
  var placeholderContent = Props.children;
  var customSubtitle = Props.customSubtitle;
  var types = Props.types;
  var maxSizeMbOpt = Props.maxSizeMb;
  var disabledOpt = Props.disabled;
  var onSuccess = Props.onSuccess;
  var onError = Props.onError;
  var maxSizeMb = maxSizeMbOpt !== undefined ? maxSizeMbOpt : 1;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setDragging = match[1];
  var dropZoneRef = React.useRef(null);
  var dropTargetRef = React.useRef(null);
  var disabledRef = React.useRef(disabled);
  var onDrop = React.useCallback((function ($$event) {
          var data = $$event.dataTransfer;
          var file = Belt_Array.get(data.files, 0);
          if (file !== undefined) {
            var file$1 = Caml_option.valFromOption(file);
            var exit = 0;
            if (types !== undefined && FilePicker$Pos.isFileBadType(file$1, types) && FilePicker$Pos.isFileBadExtension(file$1, types)) {
              Curry._1(onError, FilePicker$Pos.messageFromError(/* FileBadExtension */{
                        _0: types
                      }));
            } else {
              exit = 1;
            }
            if (exit === 1) {
              if (file$1.size > maxSizeMb * 1048576) {
                Curry._1(onError, FilePicker$Pos.messageFromError(/* FileTooHeavy */1));
              } else {
                Curry._1(onSuccess, file$1);
              }
            }
            
          } else {
            Curry._1(onError, FilePicker$Pos.messageFromError(/* FileNotFound */0));
          }
          Curry._1(setDragging, (function (param) {
                  return false;
                }));
          $$event.preventDefault();
        }), []);
  var eventListenersHandler = React.useCallback((function (ref) {
          return function (listener) {
            var eventsList = [
              [
                "dragenter",
                (function ($$event) {
                    if (!disabledRef.current) {
                      Curry._1(setDragging, (function (param) {
                              return true;
                            }));
                      dropTargetRef.current = $$event.target;
                      return ;
                    }
                    
                  })
              ],
              [
                "dragleave",
                (function ($$event) {
                    var currentElement = $$event.target;
                    var refElement = Belt_Option.map(Caml_option.nullable_to_opt(dropTargetRef.current), (function (prim) {
                            return prim;
                          }));
                    if (Caml_option.some(currentElement) === refElement) {
                      return Curry._1(setDragging, (function (param) {
                                    return false;
                                  }));
                    }
                    
                  })
              ],
              [
                "dragover",
                (function ($$event) {
                    $$event.preventDefault();
                    $$event.stopPropagation();
                  })
              ],
              [
                "drop",
                (function ($$event) {
                    if (disabledRef.current) {
                      $$event.preventDefault();
                      return ;
                    } else {
                      return Curry._1(onDrop, $$event);
                    }
                  })
              ]
            ];
            return Belt_Option.map(ReactDomElement$Pos.fromRef(ref), (function (domElement) {
                          Belt_Array.forEach(eventsList, (function (param) {
                                  Curry._3(listener, domElement, param[0], param[1]);
                                }));
                        }));
          };
        }), []);
  React.useEffect((function () {
          Curry._2(eventListenersHandler, dropZoneRef, (function (prim0, prim1, prim2) {
                  prim0.addEventListener(prim1, prim2);
                }));
          return (function (param) {
                    Curry._2(eventListenersHandler, dropZoneRef, (function (prim0, prim1, prim2) {
                            prim0.removeEventListener(prim1, prim2);
                          }));
                  });
        }), []);
  React.useEffect((function () {
          disabledRef.current = disabled;
        }), [disabled]);
  var placeholderTypes = React.useMemo((function () {
          if (types !== undefined) {
            if (types.length !== 1) {
              return "(" + (Belt_Array.reduce(types, "", (function (acc, fileType) {
                              return acc + ((
                                        acc.length > 0 ? " " : ""
                                      ) + FilePicker$Pos.extensionFromType(fileType));
                            })) + ")");
            } else {
              return FilePicker$Pos.extensionFromType(types[0]);
            }
          } else {
            return "";
          }
        }), []);
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(match[0] && !disabled ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                                                    children: null
                                                  }, React.createElement(Text$Pos.make, Text$Pos.makeProps(Intl$Pos.t("Drop your file in this area"), Caml_option.some(styles.textTitle), undefined, undefined, undefined)), React.createElement(Text$Pos.make, Text$Pos.makeProps(customSubtitle !== undefined ? customSubtitle : Intl$Pos.template(Intl$Pos.t("Import your {{types}} file by drag and dropping it in here."), {
                                                                  types: placeholderTypes
                                                                }, undefined), Caml_option.some(styles.textSubtitle), undefined, undefined, undefined)))), Caml_option.some(styles.textWrapper), undefined, undefined, undefined))), Caml_option.some(styles.main), undefined, undefined, undefined)) : placeholderContent), undefined, undefined, dropZoneRef, undefined));
}

var make = React.memo(DropZone);

export {
  make ,
}
/* styles Not a pure module */
