// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Group$Pos from "../../resources/layout-and-structure/Group.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CatalogVariant$Pos from "./CatalogVariant.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as CatalogVariantEditForm$Pos from "./CatalogVariantEditForm.bs.js";
import * as CatalogVariantEditFormInputs$Pos from "./CatalogVariantEditFormInputs.bs.js";

function CatalogVariantEditFormInformationFieldsetPanel(Props) {
  var editionMode = Props.editionMode;
  var productKind = Props.productKind;
  var dispatch = Curry._1(CatalogVariantEditForm$Pos.useFormDispatch, undefined);
  var match = Curry._1(CatalogVariantEditForm$Pos.useFormState, undefined).values;
  var bulk = match.bulk;
  var year = match.year;
  var capacityValue = match.capacityValue;
  var capacityUnit = match.capacityUnit;
  var name = match.name;
  var match$1 = React.useState(function () {
        return {
                focused: false,
                modified: false
              };
      });
  var setNameFieldInteracted = match$1[1];
  var nameFieldInteracted = match$1[0];
  ReactUpdateEffect$Pos.use1((function (param) {
          if (nameFieldInteracted.focused && name !== "") {
            Curry._1(setNameFieldInteracted, (function (prev) {
                    return {
                            focused: prev.focused,
                            modified: true
                          };
                  }));
          }
          
        }), [name]);
  ReactUpdateEffect$Pos.use1((function (param) {
          if (bulk) {
            Curry._1(dispatch, {
                  TAG: /* FieldValueChanged */2,
                  _0: /* CapacityValue */2,
                  _1: (function (param) {
                      
                    })
                });
          }
          
        }), [bulk]);
  ReactUpdateEffect$Pos.use4((function (param) {
          if (!nameFieldInteracted.modified) {
            Curry._1(dispatch, {
                  TAG: /* FieldValueChanged */2,
                  _0: /* Name */0,
                  _1: (function (param) {
                      return CatalogVariant$Pos.Name.make(capacityValue, capacityUnit, bulk, year !== undefined && year !== 0 ? year | 0 : undefined);
                    })
                });
          }
          
        }), [
        capacityValue,
        capacityUnit,
        year,
        bulk
      ]);
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("General information"),
              description: Intl$Pos.t("By entering a capacity value and a year, the variant name will be automatically generated."),
              children: null
            }, editionMode ? null : React.createElement(Inline$Pos.make, {
                    children: React.createElement(CatalogVariantEditForm$Pos.InputToggleSwitch.make, {
                          label: Intl$Pos.t("Bulk product management") + "  ",
                          field: /* Bulk */11
                        })
                  }), React.createElement(Group$Pos.make, {
                  children: null
                }, !bulk && Belt_Option.isSome(capacityUnit) ? React.createElement(CatalogVariantEditFormInputs$Pos.InputVariantCapacityValueSelect.make, {
                        productKind: productKind
                      }) : null, React.createElement(CatalogVariantEditFormInputs$Pos.InputVariantCapacityUnitSelect.make, {
                      productKind: productKind,
                      disabled: bulk && editionMode
                    }), productKind === "WINE" || productKind === "SPIRITUOUS" ? React.createElement(CatalogVariantEditFormInputs$Pos.InputVariantYear.make, {
                        productKind: productKind
                      }) : null), React.createElement(CatalogVariantEditForm$Pos.InputText.make, {
                  field: /* Name */0,
                  label: Intl$Pos.t("Variant's name"),
                  placeholder: bulk ? Intl$Pos.t("Bulk by kg") : CatalogVariant$Pos.Name.placeholderFromProductKind(productKind),
                  onFocus: (function (param) {
                      Curry._1(setNameFieldInteracted, (function (prev) {
                              return {
                                      focused: true,
                                      modified: prev.modified
                                    };
                            }));
                    })
                }), React.createElement(Group$Pos.make, {
                  children: null,
                  columns: 2
                }, productKind === "WINE" || productKind === "BEER" || productKind === "SPIRITUOUS" ? React.createElement(CatalogVariantEditFormInputs$Pos.InputVariantAlcoholVolume.make, {
                        productKind: productKind
                      }) : null, React.createElement(CatalogVariantEditFormInputs$Pos.InputVariantPriceLookup.make, {}), React.createElement(CatalogVariantEditForm$Pos.InputText.make, {
                      field: /* StockKeepingUnit */5,
                      label: Intl$Pos.t("SKU code"),
                      placeholder: "B1VAPSOBUOD202153C6"
                    }), React.createElement(CatalogVariantEditForm$Pos.InputText.make, {
                      field: /* Ean13 */4,
                      label: Intl$Pos.t("Barcode"),
                      placeholder: "3670257725480"
                    }), React.createElement(CatalogVariantEditForm$Pos.InputText.make, {
                      field: /* InternalCode */7,
                      label: Intl$Pos.t("Internal code"),
                      placeholder: "CAB04-750ML-2019"
                    }), editionMode ? null : React.createElement(CatalogVariantEditForm$Pos.InputText.make, {
                        field: /* AccountingCode */8,
                        label: Intl$Pos.t("Accounting account"),
                        placeholder: "401000"
                      })));
}

var make = React.memo(CatalogVariantEditFormInformationFieldsetPanel);

export {
  make ,
}
/* make Not a pure module */
