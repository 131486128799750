// Generated by ReScript, PLEASE EDIT WITH CARE


function fromString(slug) {
  switch (slug) {
    case "BOT" :
        return {
                TAG: /* Ok */0,
                _0: "BOT"
              };
    case "ERP" :
        return {
                TAG: /* Ok */0,
                _0: "ERP"
              };
    case "W" :
        return {
                TAG: /* Ok */0,
                _0: "W"
              };
    case "d" :
        return {
                TAG: /* Ok */0,
                _0: "d"
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function toDeviceName(slug) {
  if (slug === "d") {
    return "dashboard.wino.fr";
  } else if (slug === "BOT") {
    return "BOT";
  } else if (slug === "ERP") {
    return "ERP";
  } else {
    return "app.wino.fr";
  }
}

var Slug = {
  fromString: fromString,
  toDeviceName: toDeviceName
};

function formatDeviceName(deviceName, deviceSlug) {
  var slug = fromString(deviceSlug);
  if (slug.TAG === /* Ok */0) {
    return toDeviceName(slug._0);
  } else {
    return "" + deviceName + " - " + deviceSlug + "";
  }
}

export {
  Slug ,
  formatDeviceName ,
}
/* No side effect */
