// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Badge$Pos from "../feedback-indicators/Badge.bs.js";
import * as Title$Pos from "../typography/Title.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Spinner$Pos from "../feedback-indicators/Spinner.bs.js";
import * as InlineText$Pos from "../layout-and-structure/InlineText.bs.js";
import * as TooltipIcon$Pos from "../overlays/TooltipIcon.bs.js";

var style = {
  lineHeight: "12px"
};

var infinityStyle = {
  fontSize: "1.4em"
};

function Metric$BadgeProgressionText(Props) {
  var value = Props.value;
  return React.createElement("span", {
              "aria-label": "progression",
              style: style
            }, Number.isFinite(value) ? (
                value > 0 ? React.createElement(React.Fragment, undefined, "+ " + Intl$Pos.percentFormat(undefined, 0, value)) : React.createElement(React.Fragment, undefined, Intl$Pos.percentFormat(undefined, 0, value).replace("-", "- "))
              ) : React.createElement(React.Fragment, undefined, "+ ", React.createElement("span", {
                        style: infinityStyle
                      }, "∞")));
}

function getVariation(value) {
  var roundedValue = Math.round(value * 100);
  if (roundedValue >= 1) {
    return "success";
  } else if (roundedValue <= -1) {
    return "danger";
  } else {
    return "neutral";
  }
}

function style$1(muted) {
  return {
          backgroundColor: Colors$Pos.neutralColor00,
          display: "flex",
          height: "100%",
          minWidth: "180px",
          padding: "" + String(Spaces$Pos.medium) + "px " + String(Spaces$Pos.large) + "px",
          position: "relative",
          borderRadius: "5px",
          boxShadow: muted ? "rgba(37, 36, 58, 0.01) 0 6px 10px" : "rgba(37, 36, 58, 0.08) 0 0 10px,\n             rgba(37, 36, 58, 0.01) 4px 0 4px,\n             rgba(37, 36, 58, 0.01) -4px 0 4px",
          flexDirection: "column",
          justifyContent: "space-between",
          boxSizing: "border-box",
          transition: "box-shadow .3s ease-in-out"
        };
}

var titleBoxStyle = {
  marginRight: "12px",
  paddingBottom: "8px"
};

var titleBoxBadgeStyle = {
  position: "relative",
  top: "-2px"
};

var titleBoxTooltipStyle = {
  display: "inline-flex",
  marginLeft: "1px",
  position: "absolute",
  top: "1px"
};

var spinnerStyle = {
  position: "absolute",
  right: "8px",
  top: "8px"
};

function Metric(Props) {
  var children = Props.children;
  var title = Props.title;
  var loadingOpt = Props.loading;
  var progression = Props.progression;
  var tooltip = Props.tooltip;
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var ref = React.createRef();
  return React.createElement("div", {
              style: style$1(loading)
            }, React.createElement("div", {
                  ref: ref,
                  style: titleBoxStyle
                }, React.createElement(InlineText$Pos.make, {
                      children: null,
                      linespace: "xxsmall"
                    }, React.createElement(Title$Pos.make, {
                          children: title + "   ",
                          level: 4,
                          weight: "strong"
                        }), progression !== undefined && !Number.isNaN(progression) ? React.createElement("span", {
                            style: titleBoxBadgeStyle
                          }, React.createElement(Badge$Pos.make, {
                                children: React.createElement(Metric$BadgeProgressionText, {
                                      value: progression
                                    }),
                                size: "compact",
                                variation: getVariation(progression)
                              }), tooltip !== undefined ? React.createElement("div", {
                                  style: titleBoxTooltipStyle
                                }, React.createElement(TooltipIcon$Pos.make, {
                                      children: Caml_option.valFromOption(tooltip),
                                      variation: "info",
                                      arrowed: true,
                                      altTriggerRef: ref,
                                      offset: 2,
                                      crossOffset: -1
                                    })) : null) : null, loading ? React.createElement("div", {
                            style: spinnerStyle
                          }, React.createElement(Spinner$Pos.make, {
                                size: 18
                              })) : null)), children);
}

var make = React.memo(Metric);

export {
  getVariation ,
  make ,
}
/* make Not a pure module */
