// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as QueryString$Pos from "../../primitives/QueryString.bs.js";
import * as CatalogProduct$Pos from "./CatalogProduct.bs.js";

function encoder(param) {
  return CatalogProduct$Pos.Kind.toString(param.productKind);
}

function decoder(value) {
  var productKind = CatalogProduct$Pos.Kind.fromString(value);
  if (productKind.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              productKind: productKind._0
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "SyntaxError",
              VAL: productKind._0
            }
          };
  }
}

var value = JsonCodec$Pos.object1(encoder, decoder, JsonCodec$Pos.field("productKind", JsonCodec$Pos.string));

var Codecs = {
  encoder: encoder,
  decoder: decoder,
  value: value
};

function encode(state) {
  return QueryString$Pos.stringify(JsonCodec$Pos.encodeWith(state, value));
}

function decode(query) {
  return JsonCodec$Pos.decodeWith(QueryString$Pos.parse(query), value);
}

var CreateProduct = {
  Codecs: Codecs,
  encode: encode,
  decode: decode
};

export {
  CreateProduct ,
}
/* value Not a pure module */
