// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function isTaxIncluded(price) {
  if (price.TAG === /* PriceExclTax */0) {
    return false;
  } else {
    return true;
  }
}

function toRawValue(price) {
  return price._0;
}

function makeRate(rateType, retailPrice, purchasePrice) {
  var tmp;
  if (retailPrice.TAG === /* PriceExclTax */0) {
    var retailPrice$1 = retailPrice._0;
    tmp = retailPrice$1 <= 0 || retailPrice$1 < purchasePrice ? undefined : (
        rateType ? (retailPrice$1 - purchasePrice) / purchasePrice * 100 : (retailPrice$1 - purchasePrice) / retailPrice$1 * 100
      );
  } else {
    tmp = undefined;
  }
  return Belt_Option.mapWithDefault(tmp, undefined, (function (result) {
                if (Number.isFinite(result)) {
                  return result;
                }
                
              }));
}

function makeWithTax(price, taxRate) {
  var tmp;
  if (price.TAG === /* PriceExclTax */0) {
    if (taxRate >= 0) {
      var price$1 = price._0;
      tmp = price$1 + price$1 * taxRate / 100;
    } else {
      tmp = undefined;
    }
  } else {
    tmp = taxRate >= 0 ? price._0 * 100 / (100 + taxRate) : undefined;
  }
  return Belt_Option.map(Belt_Option.mapWithDefault(tmp, undefined, (function (result) {
                    if (Number.isFinite(result)) {
                      return result;
                    }
                    
                  })), (function (value) {
                var match = Math.round(value * 100) / 100;
                if (price.TAG === /* PriceExclTax */0) {
                  return {
                          TAG: /* PriceInclTax */1,
                          _0: match
                        };
                } else {
                  return {
                          TAG: /* PriceExclTax */0,
                          _0: match
                        };
                }
              }));
}

export {
  isTaxIncluded ,
  toRawValue ,
  makeRate ,
  makeWithTax ,
}
/* No side effect */
