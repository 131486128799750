// Generated by ReScript, PLEASE EDIT WITH CARE


function isRequested(value) {
  if (typeof value === "number") {
    return value !== 0;
  } else {
    return false;
  }
}

function isSucceeded(value) {
  if (typeof value === "number" || value.TAG !== /* Succeeded */1) {
    return false;
  } else {
    return true;
  }
}

export {
  isRequested ,
  isSucceeded ,
}
/* No side effect */
