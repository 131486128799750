// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Label$Pos from "../../primitives/Label.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as OrderEditForm$Pos from "./OrderEditForm.bs.js";

function OrderConditionAndNotesCard(Props) {
  var editable = Props.editable;
  var state = Curry._1(OrderEditForm$Pos.useFormState, undefined);
  var tmp;
  if (editable) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(OrderEditForm$Pos.InputTextArea.make, {
              field: /* Condition */0,
              label: Intl$Pos.t("Conditions")
            }), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(OrderEditForm$Pos.InputTextArea.make, {
              field: /* NoteForSupplier */1,
              label: Intl$Pos.t("Supplier note")
            }));
  } else {
    var condition = state.values.condition;
    var noteForSupplier = state.values.noteForSupplier;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Label$Pos.make, {
              text: Intl$Pos.t("Conditions")
            }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(condition.length > 0 ? React.createElement(TextStyle$Pos.make, {
                            children: condition,
                            size: "large"
                          }) : React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.t("Empty condition"),
                            variation: "subdued"
                          })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Label$Pos.make, {
              text: Intl$Pos.t("Supplier note")
            }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(noteForSupplier.length > 0 ? React.createElement(TextStyle$Pos.make, {
                            children: noteForSupplier,
                            size: "large"
                          }) : React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.t("Empty note"),
                            variation: "subdued"
                          })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  }
  return React.createElement(Card$Pos.make, {
              children: tmp,
              title: "Notes"
            });
}

var make = React.memo(OrderConditionAndNotesCard);

export {
  make ,
}
/* make Not a pure module */
