// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactUpdateEffect$Pos from "./ReactUpdateEffect.bs.js";

function makeTransition(animation, duration, timingFunction) {
  var duration$1 = String(duration / 1000) + "s";
  return {
          start: animation === "fadePopinTranslation" ? "fadePopinTranslation" : (
              animation === "fadeBubbleTranslation" ? "fadeBubbleTranslation" : (
                  animation === "fadeTranslation" ? "fadeTranslation" : "fadeIn"
                )
            ),
          end: "fadeOut",
          duration: duration$1,
          timing: "cubic-bezier(.4, 0, .2, 1)"
        };
}

function AnimatedRender(Props) {
  var children = Props.children;
  var displayed = Props.displayed;
  var animationOpt = Props.animation;
  var durationOpt = Props.duration;
  var timingFunctionOpt = Props.timingFunction;
  var animation = animationOpt !== undefined ? animationOpt : "fadeBubbleTranslation";
  var duration = durationOpt !== undefined ? durationOpt : 85;
  var timingFunction = timingFunctionOpt !== undefined ? timingFunctionOpt : "cubicBezier";
  var match = React.useState(function () {
        return displayed;
      });
  var setShouldRender = match[1];
  ReactUpdateEffect$Pos.use1((function (param) {
          if (displayed) {
            Curry._1(setShouldRender, (function (param) {
                    return true;
                  }));
          }
          
        }), [displayed]);
  var onAnimationEnd = function (param) {
    if (!displayed) {
      return Curry._1(setShouldRender, (function (param) {
                    return false;
                  }));
    }
    
  };
  var match$1 = makeTransition(animation, duration, timingFunction);
  var style = displayed ? ({
        animationDuration: match$1.duration,
        animationName: match$1.start,
        animationTimingFunction: match$1.timing
      }) : ({
        animationDuration: match$1.duration,
        animationName: match$1.end,
        animationTimingFunction: match$1.timing
      });
  if (match[0]) {
    return React.createElement("div", {
                style: style,
                onAnimationEnd: onAnimationEnd
              }, children);
  } else {
    return null;
  }
}

var make = React.memo(AnimatedRender);

export {
  make ,
}
/* make Not a pure module */
