// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "./Box.bs.js";
import * as Card$Pos from "./Card.bs.js";
import * as Column$Pos from "./Column.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "./Columns.bs.js";
import * as LayoutPanelHeading$Pos from "./LayoutPanelHeading.bs.js";

function TableLayoutPanel(Props) {
  var title = Props.title;
  var description = Props.description;
  var children = Props.children;
  return React.createElement(Columns$Pos.make, {
              children: null,
              space: "xlarge"
            }, React.createElement(Column$Pos.make, {
                  children: React.createElement(LayoutPanelHeading$Pos.make, {
                        title: title,
                        description: description
                      }),
                  width: "quarter"
                }), React.createElement(Column$Pos.make, {
                  children: React.createElement(Card$Pos.make, {
                        children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(children), undefined, undefined, "xxsmall", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                        variation: "unset"
                      }),
                  width: "three_quarter"
                }));
}

var make = React.memo(TableLayoutPanel);

export {
  make ,
}
/* make Not a pure module */
