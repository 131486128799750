// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Accordion$Pos from "../../resources/layout-and-structure/Accordion.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as OrderStatus$Pos from "../Order/OrderStatus.bs.js";
import * as CartGlobalDiscountView$Pos from "./CartGlobalDiscountView.bs.js";
import * as CartTaxApplicationView$Pos from "./CartTaxApplicationView.bs.js";

function CartOptionsAccordion(Props) {
  var cart = Props.cart;
  var statuses = Props.statuses;
  var dispatch = Props.onRequestDispatch;
  var triggerShowView = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.t("Show options"),
                    weight: "strong"
                  })), undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  var triggerHideView = React.createElement(TextStyle$Pos.make, {
        children: Intl$Pos.t("Hide options"),
        weight: "strong"
      });
  var match = cart.discounts.length;
  return React.createElement(Accordion$Pos.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Columns$Pos.make, {
                                children: React.createElement(Column$Pos.make, {
                                      children: null,
                                      width: "content"
                                    }, React.createElement(Stack$Pos.make, {
                                          children: null,
                                          space: "none"
                                        }, match !== 0 ? null : React.createElement(CartGlobalDiscountView$Pos.make, {
                                                warningMessage: cart.formattedTotalAmountExcludingGlobalDiscounts,
                                                decimalPrecision: cart.decimalPrecision,
                                                onRequestDispatch: dispatch
                                              }), Belt_Array.map(cart.discounts, (function (discount) {
                                                return React.createElement(CartGlobalDiscountView$Pos.make, {
                                                            discount: discount,
                                                            warningMessage: cart.formattedTotalAmountExcludingGlobalDiscounts,
                                                            decimalPrecision: cart.decimalPrecision,
                                                            onRequestDispatch: dispatch,
                                                            key: discount.id
                                                          });
                                              }))), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(CartTaxApplicationView$Pos.make, {
                                          taxesFree: cart.taxesFree,
                                          orderReceived: OrderStatus$Pos.has(statuses, "RECEIVED"),
                                          onRequestDispatch: dispatch
                                        })),
                                space: "medium"
                              })), undefined, "small", undefined, undefined, "xxlarge", undefined, undefined, undefined, undefined, undefined, undefined)),
              triggerShowView: triggerShowView,
              triggerHideView: triggerHideView
            });
}

var make = React.memo(CartOptionsAccordion, (function (oldProps, newProps) {
        if (oldProps.cart === newProps.cart) {
          return oldProps.statuses === newProps.statuses;
        } else {
          return false;
        }
      }));

var Utils;

export {
  Utils ,
  make ,
}
/* make Not a pure module */
