// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Popover$Pos from "../../resources/overlays/Popover.bs.js";
import * as OpeningButton$Pos from "../../resources/actions/OpeningButton.bs.js";
import * as ProductPriceTableCell$Pos from "../Product/ProductPriceTableCell.bs.js";
import * as CartProductDiscountPopover$Pos from "./CartProductDiscountPopover.bs.js";

function CartProductDiscountsTableCell(Props) {
  var editableOpt = Props.editable;
  var decimalPrecision = Props.decimalPrecision;
  var product = Props.product;
  var onRequestDispatch = Props.onRequestDispatch;
  var editable = editableOpt !== undefined ? editableOpt : false;
  var match = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover$Pos.use(popoverTriggerRef, undefined);
  var triggerHovered = match$1[1];
  var formattedTotalLocalDiscounts;
  formattedTotalLocalDiscounts = product._0.formattedTotalLocalDiscounts;
  var totalAmountElement = React.createElement(ProductPriceTableCell$Pos.make, {
        value: formattedTotalLocalDiscounts
      });
  var popover_isOpen = popover.isOpen;
  var popover_close = function (param) {
    if (!triggerHovered) {
      return Curry._1(popover.close, undefined);
    }
    
  };
  var popover_toggle = popover.toggle;
  var popover$1 = {
    isOpen: popover_isOpen,
    close: popover_close,
    toggle: popover_toggle
  };
  return React.createElement(React.Fragment, undefined, editable ? React.createElement(OpeningButton$Pos.make, OpeningButton$Pos.makeProps(totalAmountElement, popoverAriaProps.triggerProps, popover_isOpen, undefined, (function (param) {
                          Curry._1(popover_toggle, undefined);
                        }), undefined, popoverTriggerRef, undefined)) : totalAmountElement, popover_isOpen ? React.createElement(CartProductDiscountPopover$Pos.make, {
                    product: product,
                    popover: popover$1,
                    popoverAriaProps: popoverAriaProps,
                    popoverTriggerRef: popoverTriggerRef,
                    decimalPrecision: decimalPrecision,
                    onRequestDispatch: onRequestDispatch
                  }) : null);
}

var make = React.memo(CartProductDiscountsTableCell, (function (oldProps, newProps) {
        if (oldProps.product === newProps.product) {
          return oldProps.editable === newProps.editable;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
