// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.transparent, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexStart", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(40)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.normal)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.small)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerInteracted: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor05, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerHovered: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor20, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerFocused: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor25, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      containerErrored: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.dangerColor50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function containerStyleFromParams(disabled, hovered, focused, errored) {
  return Style$Pos.arrayOptionStyle([
              Caml_option.some(styles.container),
              disabled || !(hovered && focused) ? undefined : Caml_option.some(styles.containerInteracted),
              disabled ? undefined : (
                  errored ? Caml_option.some(styles.containerErrored) : (
                      focused ? Caml_option.some(styles.containerFocused) : (
                          hovered ? Caml_option.some(styles.containerHovered) : undefined
                        )
                    )
                )
            ]);
}

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var disabled = Props.disabled;
      var onPress = Props.onPress;
      if (onPress !== undefined) {
        return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(children, undefined, disabled, undefined, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, Caml_option.some(ref), undefined));
      } else {
        return children;
      }
    });

var make$1 = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var variationOpt = Props.variation;
      var hovered = Props.hovered;
      var focusedOpt = Props.focused;
      var erroredOpt = Props.errored;
      var disabledOpt = Props.disabled;
      var onPress = Props.onPress;
      var variation = variationOpt !== undefined ? variationOpt : "normal";
      var focused = focusedOpt !== undefined ? focusedOpt : false;
      var errored = erroredOpt !== undefined ? erroredOpt : false;
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var tmp = {
        children: React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                          children: children,
                          variation: variation === "neutral" ? "normal" : "neutral",
                          weight: variation === "normal" || variation === "neutral" ? "regular" : "medium"
                        })), Caml_option.some(containerStyleFromParams(disabled, hovered, focused, errored)), undefined, Caml_option.some(ref), undefined)),
        disabled: disabled
      };
      if (onPress !== undefined) {
        tmp.onPress = Caml_option.valFromOption(onPress);
      }
      return React.createElement(make, tmp);
    });

var make$2 = React.memo(make$1);

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9) {
  var tmp = {
    children: prim0,
    hovered: prim2
  };
  if (prim1 !== undefined) {
    tmp.variation = Caml_option.valFromOption(prim1);
  }
  if (prim3 !== undefined) {
    tmp.focused = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.errored = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim5);
  }
  if (prim6 !== undefined) {
    tmp.onPress = Caml_option.valFromOption(prim6);
  }
  if (prim7 !== undefined) {
    tmp.key = prim7;
  }
  if (prim8 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim8);
  }
  return tmp;
}

export {
  makeProps ,
  make$2 as make,
}
/* styles Not a pure module */
