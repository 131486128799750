// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as PromotionEditDiscountedProduct$Pos from "./PromotionEditDiscountedProduct.bs.js";

var RootCampaign = {};

function get(values, field) {
  switch (field) {
    case /* DiscountedProducts */0 :
        return values.discountedProducts;
    case /* Name */1 :
        return values.name;
    case /* PriceName */2 :
        return values.priceName;
    case /* Period */3 :
        return values.period;
    case /* RootCampaigns */4 :
        return values.rootCampaigns;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* DiscountedProducts */0 :
        return {
                discountedProducts: value,
                name: values.name,
                priceName: values.priceName,
                period: values.period,
                rootCampaigns: values.rootCampaigns
              };
    case /* Name */1 :
        return {
                discountedProducts: values.discountedProducts,
                name: value,
                priceName: values.priceName,
                period: values.period,
                rootCampaigns: values.rootCampaigns
              };
    case /* PriceName */2 :
        return {
                discountedProducts: values.discountedProducts,
                name: values.name,
                priceName: value,
                period: values.period,
                rootCampaigns: values.rootCampaigns
              };
    case /* Period */3 :
        return {
                discountedProducts: values.discountedProducts,
                name: values.name,
                priceName: values.priceName,
                period: value,
                rootCampaigns: values.rootCampaigns
              };
    case /* RootCampaigns */4 :
        return {
                discountedProducts: values.discountedProducts,
                name: values.name,
                priceName: values.priceName,
                period: values.period,
                rootCampaigns: value
              };
    
  }
}

var Lenses = {
  get: get,
  set: set
};

var include = Form$Pos.Make(Lenses);

var schema = [
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Name */1
  },
  {
    TAG: /* Custom */7,
    _0: /* Period */3,
    _1: (function (param) {
        if (Belt_Option.isSome(param.period)) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Please select a date range.")
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* PriceName */2,
    _1: (function (param) {
        var priceName = param.priceName;
        if (priceName !== undefined && priceName !== "") {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Please select a price name.")
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* RootCampaigns */4,
    _1: (function (param) {
        if (Belt_Array.some(param.rootCampaigns, (function (campaign) {
                  return campaign.selected;
                }))) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Please select at least one shop.")
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* DiscountedProducts */0,
    _1: (function (param) {
        var selectedPriceName = param.priceName;
        var discountedProducts = param.discountedProducts;
        if (discountedProducts.length !== 0) {
          if (Belt_Array.every(discountedProducts, (function (param) {
                    return PromotionEditDiscountedProduct$Pos.isValid(param, selectedPriceName);
                  }))) {
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: "At least one product has an invalid discount."
                  };
          }
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Please add at least one product.")
                };
        }
      })
  }
];

var Core = include.Core;

var Schema = include.Schema;

var FormLegacyProvider = include.FormLegacyProvider;

var FormProvider = include.FormProvider;

var InputText = include.InputText;

var SuggestionComboBox = include.SuggestionComboBox;

var Select = include.Select;

var InputTextArea = include.InputTextArea;

var InputPassword = include.InputPassword;

var InputNumber = include.InputNumber;

var InputOptionalNumber = include.InputOptionalNumber;

var InputSegmentedControls = include.InputSegmentedControls;

var InputRadioGroup = include.InputRadioGroup;

var InputPhone = include.InputPhone;

var InputCheckbox = include.InputCheckbox;

var InputToggleSwitch = include.InputToggleSwitch;

var InputDate = include.InputDate;

var InputDateRange = include.InputDateRange;

var SubmitButton = include.SubmitButton;

var CancelButton = include.CancelButton;

var ResetButton = include.ResetButton;

var ControlEnterKey = include.ControlEnterKey;

var AutoSave = include.AutoSave;

var useFormPropState = include.useFormPropState;

var useField = include.useField;

var useFormState = include.useFormState;

var useFormDispatch = include.useFormDispatch;

var validate = include.validate;

export {
  RootCampaign ,
  Lenses ,
  Core ,
  Schema ,
  FormLegacyProvider ,
  FormProvider ,
  InputText ,
  SuggestionComboBox ,
  Select ,
  InputTextArea ,
  InputPassword ,
  InputNumber ,
  InputOptionalNumber ,
  InputSegmentedControls ,
  InputRadioGroup ,
  InputPhone ,
  InputCheckbox ,
  InputToggleSwitch ,
  InputDate ,
  InputDateRange ,
  SubmitButton ,
  CancelButton ,
  ResetButton ,
  ControlEnterKey ,
  AutoSave ,
  useFormPropState ,
  useField ,
  useFormState ,
  useFormDispatch ,
  validate ,
  schema ,
}
/* include Not a pure module */
