// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Group$Pos from "../../resources/layout-and-structure/Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as ResourceDetailsPage$Pos from "../../core/ResourceDetailsPage.bs.js";

function encodeBody(id, name, address, postalCode, city, cityOfRegistryOffice, country, phoneNumber, email, website, fiscalYearEndClosingMonth, corporateName, legalRepresentative, legalForm, amountOfShareCapital, tvaNumber, siretNumber, rcsNumber, apeNafCode, bankName, bankCode, bankAccountHolder, bankAccountNumber, bicCode, ibanNumber) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "id",
                    Json$Pos.encodeString(id)
                  ],
                  [
                    "name",
                    Json$Pos.encodeString(name)
                  ],
                  [
                    "address",
                    Json$Pos.encodeString(address)
                  ],
                  [
                    "postalCode",
                    Json$Pos.encodeString(postalCode)
                  ],
                  [
                    "city",
                    Json$Pos.encodeString(city)
                  ],
                  [
                    "cityOfRegistryOffice",
                    Json$Pos.encodeString(cityOfRegistryOffice)
                  ],
                  [
                    "country",
                    Json$Pos.encodeString(country)
                  ],
                  [
                    "phoneNumber",
                    Json$Pos.encodeString(phoneNumber)
                  ],
                  [
                    "email",
                    Json$Pos.encodeString(email)
                  ],
                  [
                    "website",
                    Json$Pos.encodeString(website)
                  ],
                  [
                    "fiscalYearEndClosingMonth",
                    Json$Pos.encodeString(fiscalYearEndClosingMonth)
                  ],
                  [
                    "corporateName",
                    Json$Pos.encodeString(corporateName)
                  ],
                  [
                    "legalRepresentative",
                    Json$Pos.encodeString(legalRepresentative)
                  ],
                  [
                    "legalForm",
                    Json$Pos.encodeString(legalForm)
                  ],
                  [
                    "amountOfShareCapital",
                    Json$Pos.encodeString(amountOfShareCapital)
                  ],
                  [
                    "tvaNumber",
                    Json$Pos.encodeString(tvaNumber)
                  ],
                  [
                    "siretNumber",
                    Json$Pos.encodeString(siretNumber)
                  ],
                  [
                    "rcsNumber",
                    Json$Pos.encodeString(rcsNumber)
                  ],
                  [
                    "apeNafCode",
                    Json$Pos.encodeString(apeNafCode)
                  ],
                  [
                    "bankName",
                    Json$Pos.encodeString(bankName)
                  ],
                  [
                    "bankCode",
                    Json$Pos.encodeString(bankCode)
                  ],
                  [
                    "bankAccountHolder",
                    Json$Pos.encodeString(bankAccountHolder)
                  ],
                  [
                    "bankAccountNumber",
                    Json$Pos.encodeString(bankAccountNumber)
                  ],
                  [
                    "bicCode",
                    Json$Pos.encodeString(bicCode)
                  ],
                  [
                    "ibanNumber",
                    Json$Pos.encodeString(ibanNumber)
                  ]
                ]));
}

function decodeServerFailure(serverFailure) {
  switch (serverFailure.kind) {
    case "AuthUserFromHttpRequestFailureCase" :
        return /* AuthUserFromHttpRequestFailureCase */1;
    case "CheckUserPermissionsFailureCase" :
        return /* CheckUserPermissionsFailureCase */2;
    case "NotFoundShopFailure" :
        return /* NotFoundShopFailure */3;
    case "ShopArchivedFailure" :
        return /* ShopArchivedFailure */0;
    default:
      return /* UnknownFailure */4;
  }
}

var endpoint = Env$Pos.gatewayUrl(undefined) + "/shop";

function make(id, name, address, postalCode, city, cityOfRegistryOffice, country, phoneNumber, email, website, fiscalYearEndClosingMonth, corporateName, legalRepresentative, legalForm, amountOfShareCapital, tvaNumber, siretNumber, rcsNumber, apeNafCode, bankName, bankCode, bankAccountHolder, bankAccountNumber, bicCode, ibanNumber) {
  return Future.mapError(Request$Pos.makeAndDecode("PATCH", Caml_option.some(encodeBody(id, name, address, postalCode, city, cityOfRegistryOffice, country, phoneNumber, email, website, fiscalYearEndClosingMonth, corporateName, legalRepresentative, legalForm, amountOfShareCapital, tvaNumber, siretNumber, rcsNumber, apeNafCode, bankName, bankCode, bankAccountHolder, bankAccountNumber, bicCode, ibanNumber)), undefined, undefined, endpoint), undefined, (function (error) {
                if (typeof error === "number") {
                  return ;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeServerFailure);
                }
              }));
}

var UpdateShopRequest = {
  encodeBody: encodeBody,
  decodeServerFailure: decodeServerFailure,
  endpoint: endpoint,
  make: make
};

function get(values, field) {
  switch (field) {
    case /* Name */0 :
        return values.name;
    case /* Email */1 :
        return values.email;
    case /* Address */2 :
        return values.address;
    case /* PostalCode */3 :
        return values.postalCode;
    case /* City */4 :
        return values.city;
    case /* CityOfRegistryOffice */5 :
        return values.cityOfRegistryOffice;
    case /* Country */6 :
        return values.country;
    case /* PhoneNumber */7 :
        return values.phoneNumber;
    case /* Website */8 :
        return values.website;
    case /* FiscalYearEndClosingMonth */9 :
        return values.fiscalYearEndClosingMonth;
    case /* CorporateName */10 :
        return values.corporateName;
    case /* LegalRepresentative */11 :
        return values.legalRepresentative;
    case /* LegalForm */12 :
        return values.legalForm;
    case /* AmountOfShareCapital */13 :
        return values.amountOfShareCapital;
    case /* TvaNumber */14 :
        return values.tvaNumber;
    case /* SiretNumber */15 :
        return values.siretNumber;
    case /* RcsNumber */16 :
        return values.rcsNumber;
    case /* ApeNafCode */17 :
        return values.apeNafCode;
    case /* BankName */18 :
        return values.bankName;
    case /* BankCode */19 :
        return values.bankCode;
    case /* BankAccountHolder */20 :
        return values.bankAccountHolder;
    case /* BankAccountNumber */21 :
        return values.bankAccountNumber;
    case /* BicCode */22 :
        return values.bicCode;
    case /* IbanNumber */23 :
        return values.ibanNumber;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* Name */0 :
        var newrecord = Caml_obj.obj_dup(values);
        newrecord.name = value;
        return newrecord;
    case /* Email */1 :
        var newrecord$1 = Caml_obj.obj_dup(values);
        newrecord$1.email = value;
        return newrecord$1;
    case /* Address */2 :
        var newrecord$2 = Caml_obj.obj_dup(values);
        newrecord$2.address = value;
        return newrecord$2;
    case /* PostalCode */3 :
        var newrecord$3 = Caml_obj.obj_dup(values);
        newrecord$3.postalCode = value;
        return newrecord$3;
    case /* City */4 :
        var newrecord$4 = Caml_obj.obj_dup(values);
        newrecord$4.city = value;
        return newrecord$4;
    case /* CityOfRegistryOffice */5 :
        var newrecord$5 = Caml_obj.obj_dup(values);
        newrecord$5.cityOfRegistryOffice = value;
        return newrecord$5;
    case /* Country */6 :
        var newrecord$6 = Caml_obj.obj_dup(values);
        newrecord$6.country = value;
        return newrecord$6;
    case /* PhoneNumber */7 :
        var newrecord$7 = Caml_obj.obj_dup(values);
        newrecord$7.phoneNumber = value;
        return newrecord$7;
    case /* Website */8 :
        var newrecord$8 = Caml_obj.obj_dup(values);
        newrecord$8.website = value;
        return newrecord$8;
    case /* FiscalYearEndClosingMonth */9 :
        var newrecord$9 = Caml_obj.obj_dup(values);
        newrecord$9.fiscalYearEndClosingMonth = value;
        return newrecord$9;
    case /* CorporateName */10 :
        var newrecord$10 = Caml_obj.obj_dup(values);
        newrecord$10.corporateName = value;
        return newrecord$10;
    case /* LegalRepresentative */11 :
        var newrecord$11 = Caml_obj.obj_dup(values);
        newrecord$11.legalRepresentative = value;
        return newrecord$11;
    case /* LegalForm */12 :
        var newrecord$12 = Caml_obj.obj_dup(values);
        newrecord$12.legalForm = value;
        return newrecord$12;
    case /* AmountOfShareCapital */13 :
        var newrecord$13 = Caml_obj.obj_dup(values);
        newrecord$13.amountOfShareCapital = value;
        return newrecord$13;
    case /* TvaNumber */14 :
        var newrecord$14 = Caml_obj.obj_dup(values);
        newrecord$14.tvaNumber = value;
        return newrecord$14;
    case /* SiretNumber */15 :
        var newrecord$15 = Caml_obj.obj_dup(values);
        newrecord$15.siretNumber = value;
        return newrecord$15;
    case /* RcsNumber */16 :
        var newrecord$16 = Caml_obj.obj_dup(values);
        newrecord$16.rcsNumber = value;
        return newrecord$16;
    case /* ApeNafCode */17 :
        var newrecord$17 = Caml_obj.obj_dup(values);
        newrecord$17.apeNafCode = value;
        return newrecord$17;
    case /* BankName */18 :
        var newrecord$18 = Caml_obj.obj_dup(values);
        newrecord$18.bankName = value;
        return newrecord$18;
    case /* BankCode */19 :
        var newrecord$19 = Caml_obj.obj_dup(values);
        newrecord$19.bankCode = value;
        return newrecord$19;
    case /* BankAccountHolder */20 :
        var newrecord$20 = Caml_obj.obj_dup(values);
        newrecord$20.bankAccountHolder = value;
        return newrecord$20;
    case /* BankAccountNumber */21 :
        var newrecord$21 = Caml_obj.obj_dup(values);
        newrecord$21.bankAccountNumber = value;
        return newrecord$21;
    case /* BicCode */22 :
        var newrecord$22 = Caml_obj.obj_dup(values);
        newrecord$22.bicCode = value;
        return newrecord$22;
    case /* IbanNumber */23 :
        var newrecord$23 = Caml_obj.obj_dup(values);
        newrecord$23.ibanNumber = value;
        return newrecord$23;
    
  }
}

var ShopEditFormLenses = {
  get: get,
  set: set
};

var ShopEditForm = Form$Pos.Make(ShopEditFormLenses);

function SettingsShopsPage$ShopEditFormBankingInformationFieldset(Props) {
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Banking information"),
              description: Intl$Pos.t("Complete your banking information."),
              children: null
            }, React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(ShopEditForm.InputText.make, {
                      field: /* BankName */18,
                      label: Intl$Pos.t("Bank name")
                    }), React.createElement(ShopEditForm.InputText.make, {
                      field: /* BankCode */19,
                      label: Intl$Pos.t("Bank code")
                    })), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(ShopEditForm.InputText.make, {
                      field: /* BankAccountHolder */20,
                      label: Intl$Pos.t("Bank account holder")
                    }), React.createElement(ShopEditForm.InputText.make, {
                      field: /* BankAccountNumber */21,
                      label: Intl$Pos.t("Bank account number")
                    })), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(ShopEditForm.InputText.make, {
                      field: /* BicCode */22,
                      label: Intl$Pos.t("BIC code")
                    }), React.createElement(ShopEditForm.InputText.make, {
                      field: /* IbanNumber */23,
                      label: Intl$Pos.t("IBAN number")
                    })));
}

var make$1 = React.memo(SettingsShopsPage$ShopEditFormBankingInformationFieldset);

var ShopEditFormBankingInformationFieldset = {
  make: make$1
};

function SettingsShopsPage$ShopEditFormLegalInformationFieldset(Props) {
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Legal information"),
              description: Intl$Pos.t("Complete your legal information. It must be displayed on your documents."),
              children: null
            }, React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(ShopEditForm.InputText.make, {
                      field: /* CorporateName */10,
                      label: Intl$Pos.t("Corporate name")
                    }), React.createElement(ShopEditForm.InputText.make, {
                      field: /* LegalForm */12,
                      label: Intl$Pos.t("Legal form")
                    })), React.createElement(ShopEditForm.InputText.make, {
                  field: /* LegalRepresentative */11,
                  label: Intl$Pos.t("Legal representative")
                }), React.createElement(ShopEditForm.InputText.make, {
                  field: /* AmountOfShareCapital */13,
                  label: Intl$Pos.t("Amount of share capital")
                }), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(ShopEditForm.InputText.make, {
                      field: /* CityOfRegistryOffice */5,
                      label: Intl$Pos.t("City of registry office")
                    }), React.createElement(ShopEditForm.InputText.make, {
                      field: /* RcsNumber */16,
                      label: Intl$Pos.t("Company Registration Number")
                    })), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(ShopEditForm.InputText.make, {
                      field: /* SiretNumber */15,
                      label: Intl$Pos.t("SIRET number")
                    }), React.createElement(ShopEditForm.InputText.make, {
                      field: /* ApeNafCode */17,
                      label: Intl$Pos.t("APE/NAF code")
                    })), React.createElement(ShopEditForm.InputText.make, {
                  field: /* TvaNumber */14,
                  label: Intl$Pos.t("VAT number")
                }));
}

var make$2 = React.memo(SettingsShopsPage$ShopEditFormLegalInformationFieldset);

var ShopEditFormLegalInformationFieldset = {
  make: make$2
};

function SettingsShopsPage$ShopEditFormAddressFieldset(Props) {
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Address"),
              description: Intl$Pos.t("Provide your address. It will appear on the documents issued on app.wino.fr."),
              children: null
            }, React.createElement(ShopEditForm.InputText.make, {
                  field: /* Address */2,
                  label: Intl$Pos.t("Address")
                }), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(ShopEditForm.InputText.make, {
                      field: /* PostalCode */3,
                      label: Intl$Pos.t("Postal code")
                    }), React.createElement(ShopEditForm.InputText.make, {
                      field: /* City */4,
                      label: Intl$Pos.t("City")
                    })), React.createElement(ShopEditForm.InputText.make, {
                  field: /* Country */6,
                  label: Intl$Pos.t("Country")
                }));
}

var make$3 = React.memo(SettingsShopsPage$ShopEditFormAddressFieldset);

var ShopEditFormAddressFieldset = {
  make: make$3
};

function SettingsShopsPage$ShopEditFormGeneralInformationFieldset(Props) {
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("General information"),
              description: Intl$Pos.t("Complete your store name and contact information."),
              children: null
            }, React.createElement(ShopEditForm.InputText.make, {
                  field: /* Name */0,
                  label: Intl$Pos.t("Shop name")
                }), React.createElement(ShopEditForm.InputText.make, {
                  field: /* Email */1,
                  label: Intl$Pos.t("Email")
                }), React.createElement(ShopEditForm.InputText.make, {
                  field: /* PhoneNumber */7,
                  label: Intl$Pos.t("Phone")
                }));
}

var make$4 = React.memo(SettingsShopsPage$ShopEditFormGeneralInformationFieldset);

var ShopEditFormGeneralInformationFieldset = {
  make: make$4
};

function shopInputValues(selectedShop) {
  return {
          name: selectedShop.name,
          email: selectedShop.email,
          address: selectedShop.address,
          postalCode: selectedShop.postalCode,
          city: selectedShop.city,
          cityOfRegistryOffice: Belt_Option.getWithDefault(selectedShop.cityOfRegistryOffice, ""),
          country: selectedShop.country,
          phoneNumber: selectedShop.phoneNumber,
          website: Belt_Option.getWithDefault(selectedShop.website, ""),
          fiscalYearEndClosingMonth: Belt_Option.getWithDefault(selectedShop.fiscalYearEndClosingMonth, ""),
          corporateName: selectedShop.corporateName,
          legalRepresentative: Belt_Option.getWithDefault(selectedShop.legalRepresentative, ""),
          legalForm: Belt_Option.getWithDefault(selectedShop.legalForm, ""),
          amountOfShareCapital: Belt_Option.getWithDefault(selectedShop.amountOfShareCapital, ""),
          tvaNumber: Belt_Option.getWithDefault(selectedShop.tvaNumber, ""),
          siretNumber: Belt_Option.getWithDefault(selectedShop.siretNumber, ""),
          rcsNumber: Belt_Option.getWithDefault(selectedShop.rcsNumber, ""),
          apeNafCode: Belt_Option.getWithDefault(selectedShop.apeNafCode, ""),
          bankName: Belt_Option.getWithDefault(selectedShop.bankName, ""),
          bankCode: Belt_Option.getWithDefault(selectedShop.bankCode, ""),
          bankAccountHolder: Belt_Option.getWithDefault(selectedShop.bankAccountHolder, ""),
          bankAccountNumber: Belt_Option.getWithDefault(selectedShop.bankAccountNumber, ""),
          bicCode: Belt_Option.getWithDefault(selectedShop.bicCode, ""),
          ibanNumber: Belt_Option.getWithDefault(selectedShop.ibanNumber, "")
        };
}

var schema = [
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Name */0
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Address */2
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* PostalCode */3
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* City */4
  },
  {
    TAG: /* PhoneNumber */4,
    _0: /* PhoneNumber */7
  },
  {
    TAG: /* Email */5,
    _0: /* Email */1
  }
];

function SettingsShopsPage(Props) {
  var updateShopRequest = Props.updateShopRequest;
  var match = React.useState(function () {
        
      });
  var setNotification = match[1];
  var notification = match[0];
  var shops = Auth$Pos.useShops(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var activeShop$1 = Belt_Option.getWithDefault(activeShop, shops[0]);
  var scope = Auth$Pos.useScope(undefined);
  var logUser = Auth$Pos.useLogUser(undefined, undefined);
  var jwt = Belt_Option.getWithDefault(Auth$Pos.getJwt(undefined), "");
  var onSubmitSuccess = function (param) {
    Curry._1(logUser, jwt);
    Curry._1(setNotification, (function (param) {
            return {
                    TAG: /* Ok */0,
                    _0: Intl$Pos.t("You have made changes to your store information; please now verify your billing information in Subscription and Billing.")
                  };
          }));
  };
  var onSubmitFailure = function (message) {
    Curry._1(setNotification, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: message
                  };
          }));
  };
  var match$1 = Curry._1(ShopEditForm.useFormPropState, {
        id: activeShop$1.id,
        initialValues: shopInputValues(activeShop$1),
        schema: schema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var handleFormSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry.app(updateShopRequest, [
                        activeShop$1.id,
                        param$1.name,
                        param$1.address,
                        param$1.postalCode,
                        param$1.city,
                        param$1.cityOfRegistryOffice,
                        param$1.country,
                        param$1.phoneNumber,
                        param$1.email,
                        param$1.website,
                        param$1.fiscalYearEndClosingMonth,
                        param$1.corporateName,
                        param$1.legalRepresentative,
                        param$1.legalForm,
                        param$1.amountOfShareCapital,
                        param$1.tvaNumber,
                        param$1.siretNumber,
                        param$1.rcsNumber,
                        param$1.apeNafCode,
                        param$1.bankName,
                        param$1.bankCode,
                        param$1.bankAccountHolder,
                        param$1.bankAccountNumber,
                        param$1.bicCode,
                        param$1.ibanNumber
                      ]), undefined, (function (param) {
                      return Intl$Pos.t("Your email address has been updated successfully.");
                    })), undefined, (function (error) {
                  if (error !== undefined) {
                    if (error !== 3) {
                      if (error !== 0) {
                        return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                      } else {
                        return Intl$Pos.t("This shop has been archived.");
                      }
                    } else {
                      return Intl$Pos.t("This shop does not exist.");
                    }
                  } else {
                    return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                  }
                }));
  };
  var actionsBar = React.createElement(ResourceDetailsPage$Pos.ActionsBar.make, {
        items: [
          React.createElement(ShopEditForm.CancelButton.make, {
                text: Intl$Pos.t("Cancel"),
                size: "medium"
              }),
          React.createElement(ShopEditForm.SubmitButton.make, {
                variation: "success",
                size: "medium",
                onSubmit: handleFormSubmit,
                text: Intl$Pos.t("Save")
              })
        ]
      });
  var notificationBanner = notification !== undefined ? React.createElement(ResourceDetailsPage$Pos.NotificationBanner.make, {
          value: notification,
          onRequestClose: (function (param) {
              Curry._1(setNotification, (function (param) {
                      
                    }));
            })
        }) : null;
  var tmp;
  tmp = scope.TAG === /* Organisation */0 ? Intl$Pos.t("Shops") : Intl$Pos.t("Shop");
  var tmp$1;
  tmp$1 = scope.TAG === /* Organisation */0 ? React.createElement(FieldsetLayoutPanel$Pos.make, {
          title: Intl$Pos.t("Selected shop"),
          description: Intl$Pos.t("Select the store for which you want to view or modify information."),
          children: React.createElement(Auth$Pos.SelectSingleShop.make, {
                value: activeShop$1,
                variation: {
                  NAME: "field",
                  VAL: {
                    required: false
                  }
                }
              })
        }) : null;
  return React.createElement(ShopEditForm.Core.Provider.make, {
              children: React.createElement(ResourceDetailsPage$Pos.make, {
                    title: tmp,
                    actionsBar: actionsBar,
                    notificationBanner: notificationBanner,
                    children: null
                  }, tmp$1, React.createElement(make$4, {}), React.createElement(make$3, {}), React.createElement(make$2, {}), React.createElement(make$1, {})),
              propState: [
                match$1[0],
                match$1[1]
              ]
            });
}

var make$5 = SettingsShopsPage;

export {
  UpdateShopRequest ,
  ShopEditFormLenses ,
  ShopEditForm ,
  ShopEditFormBankingInformationFieldset ,
  ShopEditFormLegalInformationFieldset ,
  ShopEditFormAddressFieldset ,
  ShopEditFormGeneralInformationFieldset ,
  shopInputValues ,
  schema ,
  make$5 as make,
}
/* endpoint Not a pure module */
