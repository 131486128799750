// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as OrderListPage$Pos from "./OrderListPage.bs.js";
import * as Notifier__Context$Pos from "../../bundles/Notifier/Notifier__Context.bs.js";
import * as OrderEditContainer$Pos from "./OrderEditContainer.bs.js";
import * as OrderUrlQueryString$Pos from "../../modules/Order/OrderUrlQueryString.bs.js";

var baseRoute = "/orders";

function OrderRouter(Props) {
  var url = Navigation$Pos.useUrl(undefined);
  var match = OrderUrlQueryString$Pos.CreateOrder.decode(url.query);
  var match$1 = Belt_List.fromArray(url.path);
  var tmp;
  var exit = 0;
  if (match$1) {
    var exit$1 = 0;
    if (match$1.hd === "orders") {
      var match$2 = match$1.tl;
      if (match$2) {
        var id = match$2.hd;
        if (id === "create") {
          if (match$2.tl) {
            exit = 1;
          } else {
            var tmp$1 = {};
            var tmp$2 = match[0];
            if (tmp$2 !== undefined) {
              tmp$1.supplierId = Caml_option.valFromOption(tmp$2);
            }
            var tmp$3 = match[1];
            if (tmp$3 !== undefined) {
              tmp$1.shopId = Caml_option.valFromOption(tmp$3);
            }
            tmp = React.createElement(OrderEditContainer$Pos.make, tmp$1);
          }
        } else if (match$2.tl) {
          exit = 1;
        } else {
          tmp = React.createElement(OrderEditContainer$Pos.make, {
                id: id
              });
        }
      } else {
        exit$1 = 2;
      }
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (match$1.tl) {
        exit = 1;
      } else {
        tmp = React.createElement(OrderListPage$Pos.make, {});
      }
    }
    
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Navigation$Pos.Redirect.make, {
          route: baseRoute
        });
  }
  return React.createElement(Notifier__Context$Pos.Provider.make, Notifier__Context$Pos.Provider.makeProps(Notifier$Pos.createContext(undefined), tmp, undefined));
}

var make = OrderRouter;

export {
  baseRoute ,
  make ,
}
/* react Not a pure module */
