// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as AuthLoginPage$Pos from "./AuthLoginPage.bs.js";

function get(values, field) {
  return values.email;
}

function set(values, field, value) {
  return {
          email: value
        };
}

var LoginRecoveryFormLenses = {
  get: get,
  set: set
};

var LoginRecoveryForm = Form$Pos.Make(LoginRecoveryFormLenses);

var styles = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, "column", undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(-22)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(465)), undefined, undefined),
      title: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.large)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined),
      subtitle: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor70, undefined, 14, undefined, undefined, undefined, undefined, undefined, 21, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.backgroundDefaultColortemplate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(15)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.normal)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

var endpoint = Env$Pos.gatewayUrl(undefined) + "/auth/password-forgot";

function encodeBody(username) {
  return Json$Pos.encodeDict(Js_dict.fromArray([[
                    "username",
                    Json$Pos.encodeString(username)
                  ]]));
}

function make(username) {
  return Request$Pos.makeAndDecode("POST", Caml_option.some(encodeBody(username)), false, undefined, endpoint);
}

var PasswordRecoveryRequest = {
  endpoint: endpoint,
  encodeBody: encodeBody,
  make: make
};

var schema = [{
    TAG: /* Email */5,
    _0: /* Email */0
  }];

function AuthLoginRecoveryPage(Props) {
  var loginRecoveryEmailSentRoute = Props.loginRecoveryEmailSentRoute;
  var passwordRecoveryRequest = Props.passwordRecoveryRequest;
  var match = React.useState(function () {
        
      });
  var setNotfication = match[1];
  var notification = match[0];
  var onSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry._1(passwordRecoveryRequest, param$1.email), undefined, (function (param) {
                      return Intl$Pos.t("You should have received the recovery link by the next minutes.");
                    })), undefined, (function (param) {
                  return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                }));
  };
  var navigate = Navigation$Pos.useNavigate(undefined);
  var onSubmitSuccess = function (res) {
    if (res !== undefined) {
      return Curry._4(navigate, loginRecoveryEmailSentRoute, undefined, undefined, undefined);
    } else {
      return Curry._1(setNotfication, (function (param) {
                    return {
                            TAG: /* Danger */1,
                            _0: Intl$Pos.t("An unexpected error occured. Please try again or contact the support.")
                          };
                  }));
    }
  };
  var onSubmitFailure = function (error) {
    Curry._1(setNotfication, (function (param) {
            return {
                    TAG: /* Danger */1,
                    _0: error
                  };
          }));
  };
  var formPropState = Curry._1(LoginRecoveryForm.useFormPropState, {
        initialValues: {
          email: ""
        },
        schema: schema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var onRequestCloseNotificationBanner = function (param) {
    Curry._1(setNotfication, (function (param) {
            
          }));
  };
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.container), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.title), undefined, undefined, undefined), React.createElement(Title$Pos.make, {
                      children: Intl$Pos.t("Recover your password"),
                      level: 2,
                      align: "center"
                    }), notification !== undefined ? React.createElement(AuthLoginPage$Pos.NotificationBanner.make, {
                        notification: notification,
                        onRequestClose: onRequestCloseNotificationBanner
                      }) : null, React.createElement(Text$Pos.make, Text$Pos.makeProps(Intl$Pos.t("Put your email address below and we will send you a recovery link for your password."), Caml_option.some(styles.subtitle), undefined, undefined, undefined))), React.createElement(LoginRecoveryForm.Core.Provider.make, {
                  children: null,
                  propState: formPropState
                }, React.createElement(LoginRecoveryForm.ControlEnterKey.make, {
                      onSubmit: onSubmit
                    }), React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "large"
                    }, React.createElement(LoginRecoveryForm.InputText.make, {
                          field: /* Email */0,
                          label: Intl$Pos.t("Email"),
                          placeholder: Intl$Pos.t("name@yourcompany.com"),
                          hideRequired: true
                        }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(LoginRecoveryForm.SubmitButton.make, {
                                      onSubmit: onSubmit,
                                      text: Intl$Pos.t("Send a recovery link")
                                    })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))));
}

var passwordRecoveryRequest = make;

var make$1 = AuthLoginRecoveryPage;

export {
  LoginRecoveryFormLenses ,
  LoginRecoveryForm ,
  styles ,
  PasswordRecoveryRequest ,
  passwordRecoveryRequest ,
  schema ,
  make$1 as make,
}
/* LoginRecoveryForm Not a pure module */
