// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";

function style(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10, prim11, prim12, prim13, prim14, prim15, prim16, prim17, prim18, prim19, prim20, prim21, prim22, prim23, prim24, prim25, prim26, prim27, prim28, prim29, prim30, prim31, prim32, prim33, prim34, prim35, prim36, prim37, prim38, prim39, prim40, prim41, prim42, prim43, prim44, prim45, prim46, prim47, prim48, prim49, prim50, prim51, prim52, prim53, prim54, prim55, prim56, prim57, prim58, prim59, prim60, prim61, prim62, prim63, prim64, prim65, prim66, prim67, prim68, prim69, prim70, prim71, prim72, prim73, prim74, prim75, prim76, prim77, prim78, prim79, prim80, prim81, prim82, prim83, prim84, prim85, prim86, prim87, prim88, prim89, prim90, prim91, prim92, prim93, prim94, prim95, prim96, prim97, prim98, prim99, prim100, prim101) {
  var tmp = {};
  if (prim0 !== undefined) {
    tmp.resizeMode = Caml_option.valFromOption(prim0);
  }
  if (prim1 !== undefined) {
    tmp.overlayColor = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.tintColor = Caml_option.valFromOption(prim2);
  }
  if (prim3 !== undefined) {
    tmp.color = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.fontFamily = prim4;
  }
  if (prim5 !== undefined) {
    tmp.fontSize = prim5;
  }
  if (prim6 !== undefined) {
    tmp.fontStyle = Caml_option.valFromOption(prim6);
  }
  if (prim7 !== undefined) {
    tmp.fontVariant = Caml_option.valFromOption(prim7);
  }
  if (prim8 !== undefined) {
    tmp.fontWeight = (function () {
          switch (prim8) {
            case "normal" :
                return "normal";
            case "bold" :
                return "bold";
            case "_100" :
                return "100";
            case "_200" :
                return "200";
            case "_300" :
                return "300";
            case "_400" :
                return "400";
            case "_500" :
                return "500";
            case "_600" :
                return "600";
            case "_700" :
                return "700";
            case "_800" :
                return "800";
            case "_900" :
                return "900";
            
          }
        })();
  }
  if (prim9 !== undefined) {
    tmp.includeFontPadding = prim9;
  }
  if (prim10 !== undefined) {
    tmp.letterSpacing = prim10;
  }
  if (prim11 !== undefined) {
    tmp.lineHeight = prim11;
  }
  if (prim12 !== undefined) {
    tmp.textAlign = Caml_option.valFromOption(prim12);
  }
  if (prim13 !== undefined) {
    tmp.textAlignVertical = Caml_option.valFromOption(prim13);
  }
  if (prim14 !== undefined) {
    tmp.textDecorationColor = Caml_option.valFromOption(prim14);
  }
  if (prim15 !== undefined) {
    tmp.textDecorationLine = (function () {
          switch (prim15) {
            case "none" :
                return "none";
            case "underline" :
                return "underline";
            case "lineThrough" :
                return "line-through";
            case "underlineLineThrough" :
                return "underline line-through";
            
          }
        })();
  }
  if (prim16 !== undefined) {
    tmp.textDecorationStyle = Caml_option.valFromOption(prim16);
  }
  if (prim17 !== undefined) {
    tmp.textShadowColor = Caml_option.valFromOption(prim17);
  }
  if (prim18 !== undefined) {
    tmp.textShadowOffset = Caml_option.valFromOption(prim18);
  }
  if (prim19 !== undefined) {
    tmp.textShadowRadius = prim19;
  }
  if (prim20 !== undefined) {
    tmp.textTransform = Caml_option.valFromOption(prim20);
  }
  if (prim21 !== undefined) {
    tmp.writingDirection = Caml_option.valFromOption(prim21);
  }
  if (prim22 !== undefined) {
    tmp.backfaceVisibility = Caml_option.valFromOption(prim22);
  }
  if (prim23 !== undefined) {
    tmp.backgroundColor = Caml_option.valFromOption(prim23);
  }
  if (prim24 !== undefined) {
    tmp.borderBottomColor = Caml_option.valFromOption(prim24);
  }
  if (prim25 !== undefined) {
    tmp.borderBottomEndRadius = prim25;
  }
  if (prim26 !== undefined) {
    tmp.borderBottomLeftRadius = prim26;
  }
  if (prim27 !== undefined) {
    tmp.borderBottomRightRadius = prim27;
  }
  if (prim28 !== undefined) {
    tmp.borderBottomStartRadius = prim28;
  }
  if (prim29 !== undefined) {
    tmp.borderBottomWidth = prim29;
  }
  if (prim30 !== undefined) {
    tmp.borderColor = Caml_option.valFromOption(prim30);
  }
  if (prim31 !== undefined) {
    tmp.borderEndColor = Caml_option.valFromOption(prim31);
  }
  if (prim32 !== undefined) {
    tmp.borderEndWidth = prim32;
  }
  if (prim33 !== undefined) {
    tmp.borderLeftColor = Caml_option.valFromOption(prim33);
  }
  if (prim34 !== undefined) {
    tmp.borderLeftWidth = prim34;
  }
  if (prim35 !== undefined) {
    tmp.borderRadius = prim35;
  }
  if (prim36 !== undefined) {
    tmp.borderRightColor = Caml_option.valFromOption(prim36);
  }
  if (prim37 !== undefined) {
    tmp.borderRightWidth = prim37;
  }
  if (prim38 !== undefined) {
    tmp.borderStartColor = Caml_option.valFromOption(prim38);
  }
  if (prim39 !== undefined) {
    tmp.borderStartWidth = prim39;
  }
  if (prim40 !== undefined) {
    tmp.borderStyle = Caml_option.valFromOption(prim40);
  }
  if (prim41 !== undefined) {
    tmp.borderTopColor = Caml_option.valFromOption(prim41);
  }
  if (prim42 !== undefined) {
    tmp.borderTopEndRadius = prim42;
  }
  if (prim43 !== undefined) {
    tmp.borderTopLeftRadius = prim43;
  }
  if (prim44 !== undefined) {
    tmp.borderTopRightRadius = prim44;
  }
  if (prim45 !== undefined) {
    tmp.borderTopStartRadius = prim45;
  }
  if (prim46 !== undefined) {
    tmp.borderTopWidth = prim46;
  }
  if (prim47 !== undefined) {
    tmp.borderWidth = prim47;
  }
  if (prim48 !== undefined) {
    tmp.elevation = prim48;
  }
  if (prim49 !== undefined) {
    tmp.opacity = prim49;
  }
  if (prim50 !== undefined) {
    tmp.transform = Caml_option.valFromOption(prim50);
  }
  if (prim51 !== undefined) {
    tmp.shadowColor = Caml_option.valFromOption(prim51);
  }
  if (prim52 !== undefined) {
    tmp.shadowOffset = Caml_option.valFromOption(prim52);
  }
  if (prim53 !== undefined) {
    tmp.shadowOpacity = prim53;
  }
  if (prim54 !== undefined) {
    tmp.shadowRadius = prim54;
  }
  if (prim55 !== undefined) {
    tmp.alignContent = (function () {
          switch (prim55) {
            case "flexStart" :
                return "flex-start";
            case "flexEnd" :
                return "flex-end";
            case "center" :
                return "center";
            case "stretch" :
                return "stretch";
            case "spaceAround" :
                return "space-around";
            case "spaceBetween" :
                return "space-between";
            
          }
        })();
  }
  if (prim56 !== undefined) {
    tmp.alignItems = (function () {
          switch (prim56) {
            case "flexStart" :
                return "flex-start";
            case "flexEnd" :
                return "flex-end";
            case "center" :
                return "center";
            case "stretch" :
                return "stretch";
            case "baseline" :
                return "baseline";
            
          }
        })();
  }
  if (prim57 !== undefined) {
    tmp.alignSelf = (function () {
          switch (prim57) {
            case "auto" :
                return "auto";
            case "flexStart" :
                return "flex-start";
            case "flexEnd" :
                return "flex-end";
            case "center" :
                return "center";
            case "stretch" :
                return "stretch";
            case "baseline" :
                return "baseline";
            
          }
        })();
  }
  if (prim58 !== undefined) {
    tmp.aspectRatio = prim58;
  }
  if (prim59 !== undefined) {
    tmp.bottom = Caml_option.valFromOption(prim59);
  }
  if (prim60 !== undefined) {
    tmp.direction = (function () {
          switch (prim60) {
            case "inherit_" :
                return "inherit";
            case "ltr" :
                return "ltr";
            case "rtl" :
                return "rtl";
            
          }
        })();
  }
  if (prim61 !== undefined) {
    tmp.display = Caml_option.valFromOption(prim61);
  }
  if (prim62 !== undefined) {
    tmp.end = Caml_option.valFromOption(prim62);
  }
  if (prim63 !== undefined) {
    tmp.flex = prim63;
  }
  if (prim64 !== undefined) {
    tmp.flexBasis = Caml_option.valFromOption(prim64);
  }
  if (prim65 !== undefined) {
    tmp.flexDirection = (function () {
          switch (prim65) {
            case "row" :
                return "row";
            case "rowReverse" :
                return "row-reverse";
            case "column" :
                return "column";
            case "columnReverse" :
                return "column-reverse";
            
          }
        })();
  }
  if (prim66 !== undefined) {
    tmp.flexGrow = prim66;
  }
  if (prim67 !== undefined) {
    tmp.flexShrink = prim67;
  }
  if (prim68 !== undefined) {
    tmp.flexWrap = Caml_option.valFromOption(prim68);
  }
  if (prim69 !== undefined) {
    tmp.height = Caml_option.valFromOption(prim69);
  }
  if (prim70 !== undefined) {
    tmp.justifyContent = (function () {
          switch (prim70) {
            case "flexStart" :
                return "flex-start";
            case "flexEnd" :
                return "flex-end";
            case "center" :
                return "center";
            case "spaceAround" :
                return "space-around";
            case "spaceBetween" :
                return "space-between";
            case "spaceEvenly" :
                return "space-evenly";
            
          }
        })();
  }
  if (prim71 !== undefined) {
    tmp.left = Caml_option.valFromOption(prim71);
  }
  if (prim72 !== undefined) {
    tmp.margin = Caml_option.valFromOption(prim72);
  }
  if (prim73 !== undefined) {
    tmp.marginBottom = Caml_option.valFromOption(prim73);
  }
  if (prim74 !== undefined) {
    tmp.marginEnd = Caml_option.valFromOption(prim74);
  }
  if (prim75 !== undefined) {
    tmp.marginHorizontal = Caml_option.valFromOption(prim75);
  }
  if (prim76 !== undefined) {
    tmp.marginLeft = Caml_option.valFromOption(prim76);
  }
  if (prim77 !== undefined) {
    tmp.marginRight = Caml_option.valFromOption(prim77);
  }
  if (prim78 !== undefined) {
    tmp.marginStart = Caml_option.valFromOption(prim78);
  }
  if (prim79 !== undefined) {
    tmp.marginTop = Caml_option.valFromOption(prim79);
  }
  if (prim80 !== undefined) {
    tmp.marginVertical = Caml_option.valFromOption(prim80);
  }
  if (prim81 !== undefined) {
    tmp.maxHeight = Caml_option.valFromOption(prim81);
  }
  if (prim82 !== undefined) {
    tmp.maxWidth = Caml_option.valFromOption(prim82);
  }
  if (prim83 !== undefined) {
    tmp.minHeight = Caml_option.valFromOption(prim83);
  }
  if (prim84 !== undefined) {
    tmp.minWidth = Caml_option.valFromOption(prim84);
  }
  if (prim85 !== undefined) {
    tmp.overflow = Caml_option.valFromOption(prim85);
  }
  if (prim86 !== undefined) {
    tmp.padding = Caml_option.valFromOption(prim86);
  }
  if (prim87 !== undefined) {
    tmp.paddingBottom = Caml_option.valFromOption(prim87);
  }
  if (prim88 !== undefined) {
    tmp.paddingEnd = Caml_option.valFromOption(prim88);
  }
  if (prim89 !== undefined) {
    tmp.paddingHorizontal = Caml_option.valFromOption(prim89);
  }
  if (prim90 !== undefined) {
    tmp.paddingLeft = Caml_option.valFromOption(prim90);
  }
  if (prim91 !== undefined) {
    tmp.paddingRight = Caml_option.valFromOption(prim91);
  }
  if (prim92 !== undefined) {
    tmp.paddingStart = Caml_option.valFromOption(prim92);
  }
  if (prim93 !== undefined) {
    tmp.paddingTop = Caml_option.valFromOption(prim93);
  }
  if (prim94 !== undefined) {
    tmp.paddingVertical = Caml_option.valFromOption(prim94);
  }
  if (prim95 !== undefined) {
    tmp.position = Caml_option.valFromOption(prim95);
  }
  if (prim96 !== undefined) {
    tmp.right = Caml_option.valFromOption(prim96);
  }
  if (prim97 !== undefined) {
    tmp.start = Caml_option.valFromOption(prim97);
  }
  if (prim98 !== undefined) {
    tmp.top = Caml_option.valFromOption(prim98);
  }
  if (prim99 !== undefined) {
    tmp.width = Caml_option.valFromOption(prim99);
  }
  if (prim100 !== undefined) {
    tmp.zIndex = prim100;
  }
  return tmp;
}

function dp(prim) {
  return prim;
}

function arrayOptionStyle(prim) {
  return prim;
}

function arrayStyle(prim) {
  return prim;
}

function merge(prim) {
  return ReactNative.StyleSheet.flatten(prim);
}

function shadowOffset(prim0, prim1) {
  return {
          height: prim0,
          width: prim1
        };
}

var hairlineWidth = ReactNative.StyleSheet.hairlineWidth;

function unsafeCss(unsafeStyle) {
  return unsafeStyle;
}

var emptyStyle = style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var noOutline = {
  outline: "none"
};

var Color = {};

var pct = Style$ReactNative.pct;

var auto = "auto";

function toReactDOMStyle(prim) {
  return prim;
}

export {
  Color ,
  style ,
  dp ,
  pct ,
  auto ,
  arrayOptionStyle ,
  arrayStyle ,
  merge ,
  shadowOffset ,
  hairlineWidth ,
  unsafeCss ,
  emptyStyle ,
  noOutline ,
  toReactDOMStyle ,
}
/* hairlineWidth Not a pure module */
