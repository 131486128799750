// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../../resources/overlays/Popover.bs.js";
import * as TextIconButton$Pos from "../../resources/actions/TextIconButton.bs.js";
import * as CartProductFeeFieldset$Pos from "./CartProductFeeFieldset.bs.js";

function CartProductFeesPopover(Props) {
  var product = Props.product;
  var popover = Props.popover;
  var popoverAriaProps = Props.popoverAriaProps;
  var popoverTriggerRef = Props.popoverTriggerRef;
  var decimalPrecision = Props.decimalPrecision;
  var onRequestProductFeeAdd = Props.onRequestProductFeeAdd;
  var onRequestProductFeeUpdate = Props.onRequestProductFeeUpdate;
  var onRequestProductFeeReplicate = Props.onRequestProductFeeReplicate;
  var onRequestProductFeeRemove = Props.onRequestProductFeeRemove;
  var match;
  if (product.TAG === /* Unit */0) {
    var match$1 = product._0;
    match = [
      match$1.fees,
      match$1.availablesFeeKinds
    ];
  } else {
    var match$2 = product._0;
    match = [
      match$2.fees,
      match$2.availablesFeeKinds
    ];
  }
  var fees = match[0];
  var feesRef = React.useRef(fees);
  React.useEffect((function () {
          if (fees.length === 0 && fees.length < feesRef.current.length) {
            Curry._1(popover.close, undefined);
          }
          feesRef.current = fees;
        }), [fees]);
  if (fees.length === 0) {
    return null;
  }
  var match$3 = Belt_Option.map(match[1], (function (prim) {
          return prim.length;
        }));
  return React.createElement(Popover$Pos.make, {
              children: React.createElement(Popover$Pos.Dialog.make, {
                    children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "xlarge", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Stack$Pos.make, {
                              children: Belt_Array.mapWithIndex(fees, (function (index, fee) {
                                      return React.createElement(CartProductFeeFieldset$Pos.make, {
                                                  displayLabel: index === 0,
                                                  fee: fee,
                                                  product: product,
                                                  decimalPrecision: decimalPrecision,
                                                  onRequestProductFeeUpdate: onRequestProductFeeUpdate,
                                                  onRequestProductFeeReplicate: onRequestProductFeeReplicate,
                                                  onRequestProductFeeRemove: onRequestProductFeeRemove,
                                                  key: fee.id
                                                });
                                    })),
                              space: "normal"
                            }), match$3 !== undefined && match$3 !== 0 ? React.createElement(Inline$Pos.make, {
                                children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, {
                                                  children: Intl$Pos.t("Add costs"),
                                                  icon: "plus_light",
                                                  onPress: (function (param) {
                                                      Curry._1(onRequestProductFeeAdd, undefined);
                                                    })
                                                })), undefined, "medium", undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                              }) : null),
                    ariaProps: popoverAriaProps.overlayProps
                  }),
              triggerRef: popoverTriggerRef,
              state: popover,
              placement: "bottom end",
              modal: false
            });
}

var make = React.memo(CartProductFeesPopover);

export {
  make ,
}
/* make Not a pure module */
