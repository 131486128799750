// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Notifier__Context$Pos from "../../bundles/Notifier/Notifier__Context.bs.js";
import * as PromotionEditPage$Pos from "./PromotionEditPage.bs.js";
import * as PromotionListPage$Pos from "./PromotionListPage.bs.js";

var baseRoute = "/promotions";

var route = baseRoute + "/create";

function createRoute(authScope) {
  if (authScope.TAG === /* Organisation */0) {
    if (authScope.shops.length < 6) {
      return {
              TAG: /* Ok */0,
              _0: route
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: undefined
            };
    }
  } else if (authScope._0.kind === "WAREHOUSE") {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: route
          };
  }
}

function PromotionRouter(Props) {
  var url = Navigation$Pos.useUrl(undefined);
  var authScope = Auth$Pos.useScope(undefined);
  var notifierContext = Notifier$Pos.createContext(undefined);
  var authorizedCreateRoute = createRoute(authScope);
  var canShopKindEdit = Belt_Result.isOk(authorizedCreateRoute);
  var match = Belt_List.fromArray(url.path);
  var tmp;
  var exit = 0;
  if (match && match.hd === "promotions") {
    var match$1 = match.tl;
    if (match$1) {
      var cku = match$1.hd;
      var exit$1 = 0;
      if (cku === "create") {
        if (match$1.tl) {
          exit = 1;
        } else if (canShopKindEdit) {
          tmp = React.createElement(PromotionEditPage$Pos.make, {
                canShopKindEdit: canShopKindEdit
              });
        } else {
          exit$1 = 2;
        }
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        if (match$1.tl) {
          exit = 1;
        } else {
          tmp = React.createElement(PromotionEditPage$Pos.make, {
                cku: cku,
                canShopKindEdit: canShopKindEdit
              });
        }
      }
      
    } else {
      tmp = React.createElement(PromotionListPage$Pos.make, {
            authorizedCreateRoute: authorizedCreateRoute
          });
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Navigation$Pos.Redirect.make, {
          route: baseRoute
        });
  }
  return React.createElement(Notifier__Context$Pos.Provider.make, Notifier__Context$Pos.Provider.makeProps(notifierContext, tmp, undefined));
}

var make = PromotionRouter;

export {
  baseRoute ,
  route ,
  createRoute ,
  make ,
}
/* react Not a pure module */
