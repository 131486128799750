// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var PromotionStatus__StatusFromRawValueNotFound = /* @__PURE__ */Caml_exceptions.create("PromotionStatus-Pos.PromotionStatus__StatusFromRawValueNotFound");

function toRawValue(status) {
  if (status === "STOPPED") {
    return "STOPPED";
  } else if (status === "ARCHIVED") {
    return "ARCHIVED";
  } else if (status === "DRAFT") {
    return "DRAFT";
  } else if (status === "NOT_PROGRAMMED") {
    return "NOT_PROGRAMMED";
  } else if (status === "ONGOING") {
    return "ONGOING";
  } else if (status === "EXPIRED") {
    return "EXPIRED";
  } else {
    return "PROGRAMMED";
  }
}

function fromRawValue(status) {
  switch (status) {
    case "ARCHIVED" :
        return "ARCHIVED";
    case "DRAFT" :
        return "DRAFT";
    case "EXPIRED" :
        return "EXPIRED";
    case "NOT_PROGRAMMED" :
        return "NOT_PROGRAMMED";
    case "ONGOING" :
        return "ONGOING";
    case "PROGRAMMED" :
        return "PROGRAMMED";
    case "STOPPED" :
        return "STOPPED";
    default:
      throw {
            RE_EXN_ID: PromotionStatus__StatusFromRawValueNotFound,
            Error: new Error()
          };
  }
}

function toString(status) {
  if (status === "STOPPED") {
    return Intl$Pos.t("Stopped");
  } else if (status === "ARCHIVED") {
    return Intl$Pos.t("Archived");
  } else if (status === "DRAFT") {
    return Intl$Pos.t("Draft");
  } else if (status === "NOT_PROGRAMMED") {
    return Intl$Pos.t("Unscheduled");
  } else if (status === "ONGOING") {
    return Intl$Pos.t("Ongoing");
  } else if (status === "EXPIRED") {
    return Intl$Pos.t("Expired");
  } else {
    return Intl$Pos.t("Scheduled");
  }
}

var statusesWeight = [
  [
    "DRAFT",
    0
  ],
  [
    "ONGOING",
    1
  ],
  [
    "PROGRAMMED",
    2
  ],
  [
    "STOPPED",
    3
  ],
  [
    "NOT_PROGRAMMED",
    4
  ],
  [
    "EXPIRED",
    5
  ],
  [
    "ARCHIVED",
    6
  ]
];

function globalFromStatuses(statuses) {
  return Belt_Array.get(Belt_SortArray.stableSortBy(statuses, (function (current, next) {
                    var match = Belt_Array.getBy(statusesWeight, (function (param) {
                            return param[0] === current;
                          }));
                    var match$1 = Belt_Array.getBy(statusesWeight, (function (param) {
                            return param[0] === next;
                          }));
                    if (match !== undefined && match$1 !== undefined) {
                      return match[1] - match$1[1] | 0;
                    } else {
                      return 0;
                    }
                  })), 0);
}

export {
  PromotionStatus__StatusFromRawValueNotFound ,
  toRawValue ,
  fromRawValue ,
  toString ,
  statusesWeight ,
  globalFromStatuses ,
}
/* Intl-Pos Not a pure module */
