// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Intl$Pos from "../../primitives/Intl.bs.js";

function signFromKind(kind) {
  if (kind === "RESET") {
    return "=";
  } else if (kind === "RECEPTION" || kind === "CREDIT_MEMO" || kind === "REFUND" || kind === "DELIVERY" || kind === "INCOMING_TRANSFER") {
    return "+";
  } else {
    return "-";
  }
}

function toRawValue(capacityPrecision, value) {
  return (
          capacityPrecision !== undefined ? value * Math.pow(10, capacityPrecision) : value
        ) | 0;
}

function format(kind, capacityPrecision, capacityUnit, value) {
  var value$1 = capacityPrecision !== undefined ? value / Math.pow(10, capacityPrecision) : value;
  var unit;
  if (capacityUnit !== undefined) {
    switch (capacityUnit) {
      case "G" :
      case "g" :
          unit = "gram";
          break;
      case "KG" :
      case "kg" :
          unit = "kilogram";
          break;
      case "L" :
      case "l" :
          unit = "liter";
          break;
      default:
        unit = undefined;
    }
  } else {
    unit = undefined;
  }
  return signFromKind(kind) + (
          unit !== undefined ? Intl$Pos.unitFormat(unit, undefined, undefined, value$1) : (
              capacityUnit !== undefined ? Intl$Pos.decimalFormat(undefined, undefined, value$1) + " " + capacityUnit : Intl$Pos.decimalFormat(undefined, undefined, value$1)
            )
        );
}

export {
  signFromKind ,
  toRawValue ,
  format ,
}
/* Intl-Pos Not a pure module */
