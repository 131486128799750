// Generated by ReScript, PLEASE EDIT WITH CARE


function routeToPathname(route) {
  switch (route) {
    case /* Order */0 :
        return "/orders";
    case /* OrderSupply */1 :
        return "/ordersupplies";
    case /* Catalog */2 :
        return "/catalog";
    case /* Promotion */3 :
        return "/promotions";
    
  }
}

export {
  routeToPathname ,
}
/* No side effect */
