// Generated by ReScript, PLEASE EDIT WITH CARE

import JwtDecode from "jwt-decode";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function decode(string) {
  var response;
  try {
    response = JwtDecode(string);
  }
  catch (exn){
    return ;
  }
  return Caml_option.some(response);
}

export {
  decode ,
}
/* jwt-decode Not a pure module */
