// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function use1(effect, deps) {
  var mounted = React.useRef(false);
  React.useEffect((function () {
          if (mounted.current) {
            return Curry._1(effect, undefined);
          } else {
            mounted.current = true;
            return ;
          }
        }), deps);
}

function use2(effect, deps) {
  var mounted = React.useRef(false);
  React.useEffect((function () {
          if (mounted.current) {
            return Curry._1(effect, undefined);
          } else {
            mounted.current = true;
            return ;
          }
        }), deps);
}

function use3(effect, deps) {
  var mounted = React.useRef(false);
  React.useEffect((function () {
          if (mounted.current) {
            return Curry._1(effect, undefined);
          } else {
            mounted.current = true;
            return ;
          }
        }), deps);
}

function use4(effect, deps) {
  var mounted = React.useRef(false);
  React.useEffect((function () {
          if (mounted.current) {
            return Curry._1(effect, undefined);
          } else {
            mounted.current = true;
            return ;
          }
        }), deps);
}

function use5(effect, deps) {
  var mounted = React.useRef(false);
  React.useEffect((function () {
          if (mounted.current) {
            return Curry._1(effect, undefined);
          } else {
            mounted.current = true;
            return ;
          }
        }), deps);
}

function use6(effect, deps) {
  var mounted = React.useRef(false);
  React.useEffect((function () {
          if (mounted.current) {
            return Curry._1(effect, undefined);
          } else {
            mounted.current = true;
            return ;
          }
        }), deps);
}

function use7(effect, deps) {
  var mounted = React.useRef(false);
  React.useEffect((function () {
          if (mounted.current) {
            return Curry._1(effect, undefined);
          } else {
            mounted.current = true;
            return ;
          }
        }), deps);
}

export {
  use1 ,
  use2 ,
  use3 ,
  use4 ,
  use5 ,
  use6 ,
  use7 ,
}
/* react Not a pure module */
