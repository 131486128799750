// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      standardView: Style$Pos.merge([
            FontFaces$Pos.archivoBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor90, undefined, 26, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      compactView: Style$Pos.merge([
            FontFaces$Pos.archivoRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor90, undefined, 16, undefined, undefined, "_600", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function currencyValue(value, currency) {
  return {
          NAME: "currency",
          VAL: [
            value,
            currency
          ]
        };
}

function percentValue(value) {
  return {
          NAME: "percent",
          VAL: value
        };
}

function decimalValue(value) {
  return {
          NAME: "decimal",
          VAL: value
        };
}

function integerValue(value) {
  return {
          NAME: "integer",
          VAL: value
        };
}

function styleFromParams(variant) {
  return Style$Pos.arrayStyle([variant === "compact" ? styles.compactView : styles.standardView]);
}

function ValueIndicator(Props) {
  var value = Props.value;
  var variantOpt = Props.variant;
  var variant = variantOpt !== undefined ? variantOpt : "standard";
  var variant$1 = value.NAME;
  var value$1;
  if (variant$1 === "percent") {
    value$1 = Intl$Pos.percentFormat(2, 2, value.VAL);
  } else if (variant$1 === "integer") {
    value$1 = Intl$Pos.decimalFormat(0, 0, value.VAL);
  } else if (variant$1 === "currency") {
    var match = value.VAL;
    value$1 = Intl$Pos.currencyFormat(match[1], 2, 2, match[0]);
  } else {
    value$1 = Intl$Pos.decimalFormat(2, 2, value.VAL);
  }
  return React.createElement(Text$Pos.make, Text$Pos.makeProps(value$1, Caml_option.some(styleFromParams(variant)), undefined, undefined, undefined));
}

var make = React.memo(ValueIndicator);

make.displayName = "ValueIndicator";

export {
  currencyValue ,
  percentValue ,
  decimalValue ,
  integerValue ,
  make ,
}
/* styles Not a pure module */
