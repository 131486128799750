// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      main: Style$Pos.merge([
            FontFaces$Pos.libreFranklinSemiBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor90, undefined, FontSizes$Pos.normal, undefined, undefined, undefined, undefined, 0.125, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      highlighted: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.brandColor50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      hovered: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.brandColor50, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "underline", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function styleFromParams(highlighted, hovered) {
  return Style$Pos.arrayStyle([
              styles.main,
              highlighted ? (
                  hovered ? styles.hovered : styles.highlighted
                ) : (
                  hovered ? styles.hovered : Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
                )
            ]);
}

function colorFromParams(highlighted, hovered) {
  if (highlighted || hovered) {
    return Colors$Pos.brandColor50;
  } else {
    return Colors$Pos.neutralColor90;
  }
}

function TextLink(Props) {
  var text = Props.text;
  var to = Props.to;
  var openNewTabOpt = Props.openNewTab;
  var highlightedOpt = Props.highlighted;
  var icon = Props.icon;
  var onPress = Props.onPress;
  var openNewTab = openNewTabOpt !== undefined ? openNewTabOpt : false;
  var highlighted = highlightedOpt !== undefined ? highlightedOpt : false;
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  var ref = match[0];
  var tmp = {
    children: icon !== undefined ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                        children: null,
                        space: "small",
                        align: "spaceBetween"
                      }, React.createElement(Text$Pos.make, Text$Pos.makeProps(text, Caml_option.some(styleFromParams(highlighted, hovered)), undefined, ref, undefined)), React.createElement(Icon$Pos.make, {
                            name: icon,
                            fill: colorFromParams(highlighted, hovered),
                            size: 18
                          }))), undefined, undefined, ref, undefined)) : React.createElement(Text$Pos.make, Text$Pos.makeProps(text, Caml_option.some(styleFromParams(highlighted, hovered)), undefined, ref, undefined)),
    to: to,
    openNewTab: openNewTab
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  return React.createElement(Navigation$Pos.Link.make, tmp);
}

var make = TextLink;

export {
  make ,
}
/* styles Not a pure module */
