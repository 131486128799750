// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as ProductReferenceTableCell$Pos from "../Product/ProductReferenceTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query VariantsQuery($search: String, $filterBy: InputVariantsQueryFilter, $before: String, $after: String, $first: Int, $last: Int)  {\nvariants(search: $search, filterBy: $filterBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \nhasNextPage  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncreatedAt  \nbulk  \nstockKeepingUnit  \ninternalCode  \nformattedName  \nformattedDescription  \nvariantPrices(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nvalueIncludingTax  \nfromQuantity  \nprice  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variants;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$2.hasNextPage;
  var value$6 = value$1.edges;
  return {
          variants: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: !(value$5 == null) ? value$5 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.bulk;
                    var value$3 = value$1.stockKeepingUnit;
                    var value$4 = value$1.internalCode;
                    var value$5 = value$1.variantPrices;
                    var value$6 = value$5.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              createdAt: Scalar$Pos.Datetime.parse(value$1.createdAt),
                              bulk: !(value$2 == null) ? value$2 : undefined,
                              stockKeepingUnit: !(value$3 == null) ? value$3 : undefined,
                              internalCode: !(value$4 == null) ? value$4 : undefined,
                              formattedName: value$1.formattedName,
                              formattedDescription: value$1.formattedDescription,
                              variantPrices: {
                                __typename: value$5.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.fromQuantity;
                                        var value$3 = value$1.price;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  valueIncludingTax: value$1.valueIncludingTax,
                                                  fromQuantity: !(value$2 == null) ? value$2 : undefined,
                                                  price: !(value$3 == null) ? ({
                                                        __typename: value$3.__typename,
                                                        id: value$3.id
                                                      }) : undefined
                                                }
                                              };
                                      }), value$6)
                              }
                            }
                          };
                  }), value$6)
          }
        };
}

function serialize(value) {
  var value$1 = value.variants;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variantPrices;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.price;
                  var price;
                  if (value$2 !== undefined) {
                    var value$3 = value$2.id;
                    var value$4 = value$2.__typename;
                    price = {
                      __typename: value$4,
                      id: value$3
                    };
                  } else {
                    price = null;
                  }
                  var value$5 = value$1.fromQuantity;
                  var fromQuantity = value$5 !== undefined ? value$5 : null;
                  var value$6 = value$1.valueIncludingTax;
                  var value$7 = value$1.__typename;
                  var node = {
                    __typename: value$7,
                    valueIncludingTax: value$6,
                    fromQuantity: fromQuantity,
                    price: price
                  };
                  var value$8 = value.__typename;
                  return {
                          __typename: value$8,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variantPrices = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.formattedDescription;
          var value$6 = value$1.formattedName;
          var value$7 = value$1.internalCode;
          var internalCode = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.bulk;
          var bulk = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.createdAt;
          var value$11 = Scalar$Pos.Datetime.serialize(value$10);
          var value$12 = value$1.id;
          var value$13 = value$1.__typename;
          var node = {
            __typename: value$13,
            id: value$12,
            createdAt: value$11,
            bulk: bulk,
            stockKeepingUnit: stockKeepingUnit,
            internalCode: internalCode,
            formattedName: value$6,
            formattedDescription: value$5,
            variantPrices: variantPrices
          };
          var value$14 = value.__typename;
          return {
                  __typename: value$14,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.hasNextPage;
  var hasNextPage = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.startCursor;
  var startCursor = value$7 !== undefined ? value$7 : null;
  var value$8 = value$4.__typename;
  var pageInfo = {
    __typename: value$8,
    startCursor: startCursor,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$9 = value$1.__typename;
  var variants = {
    __typename: value$9,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          variants: variants
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.categoryId;
  var a$6 = inp.createdAt;
  var a$7 = inp.updatedAt;
  var a$8 = inp.supplierId;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          categoryId: a$5 !== undefined ? ({
                _equals: a$5._equals
              }) : undefined,
          createdAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          updatedAt: a$7 !== undefined ? serializeInputObjectDateFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? ({
                _equals: a$8._equals
              }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.before;
  var a$3 = inp.after;
  var a$4 = inp.first;
  var a$5 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsQueryFilter(a$1) : undefined,
          before: a$2 !== undefined ? a$2 : undefined,
          after: a$3 !== undefined ? a$3 : undefined,
          first: a$4 !== undefined ? a$4 : undefined,
          last: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables(search, filterBy, before, after, first, last, param) {
  return {
          search: search,
          filterBy: filterBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, categoryId, createdAt, updatedAt, supplierId, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          categoryId: categoryId,
          createdAt: createdAt,
          updatedAt: updatedAt,
          supplierId: supplierId
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          filterBy: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function rowsFromData(data, priceId) {
  return Belt_Array.map(data.variants.edges, (function (edge) {
                var description = edge.node.formattedDescription;
                var tmp = description === "" ? Intl$Pos.t("Description not filled in") : description;
                return {
                        id: edge.node.id,
                        createdAt: edge.node.createdAt,
                        name: edge.node.formattedName,
                        description: tmp,
                        internalCode: edge.node.internalCode,
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        hasValidVariantPrice: Belt_Option.isSome(Belt_Array.getBy(edge.node.variantPrices.edges, (function (param) {
                                    var node = param.node;
                                    var match = node.fromQuantity;
                                    if (match !== undefined && match !== 1) {
                                      return false;
                                    }
                                    var match$1 = node.price;
                                    if (match$1 !== undefined && match$1.id === priceId) {
                                      return node.valueIncludingTax > 0;
                                    } else {
                                      return false;
                                    }
                                  })))
                      };
              }));
}

var columns = [{
    key: "product",
    name: Intl$Pos.t("Product"),
    render: (function (param) {
        var disabled = param.disabled;
        var match = param.data;
        var badgeNew = DateHelpers$Pos.diffInHours(new Date(), match.createdAt) <= 24 ? ({
              variation: "information",
              text: Intl$Pos.t("New")
            }) : undefined;
        var tmp = {
          variation: "secondary",
          name: match.name,
          description: match.description,
          disabled: disabled,
          openNewTab: true
        };
        if (badgeNew !== undefined) {
          tmp.badge = Caml_option.valFromOption(badgeNew);
        }
        if (match.stockKeepingUnit !== undefined) {
          tmp.stockKeepingUnit = match.stockKeepingUnit;
        }
        if (match.internalCode !== undefined) {
          tmp.internalCode = match.internalCode;
        }
        var tmp$1 = disabled && !match.hasValidVariantPrice ? Intl$Pos.t("No retail prices found with the selected price list") : undefined;
        if (tmp$1 !== undefined) {
          tmp.errorMessage = Caml_option.valFromOption(tmp$1);
        }
        return React.createElement(ProductReferenceTableCell$Pos.make, tmp);
      })
  }];

var make = React.memo(function (Props) {
      var children = Props.children;
      var opened = Props.opened;
      var selectedProducts = Props.selectedProducts;
      var onCommit = Props.onCommit;
      var onRequestClose = Props.onRequestClose;
      var renderStartText = function (param) {
        var length = selectedProducts.length;
        var shouldBePrimaryView = length > 0;
        var formattedText = "" + String(length) + " " + Intl$Pos.t(Intl$Pos.isPlural(length) ? "selected products" : "selected product");
        return React.createElement(Inline$Pos.make, {
                    children: React.createElement(TextStyle$Pos.make, {
                          children: formattedText,
                          variation: shouldBePrimaryView ? "primary" : "neutral",
                          weight: shouldBePrimaryView ? "strong" : "regular"
                        })
                  });
      };
      var onCommit$1 = function (param) {
        Curry._1(onCommit, selectedProducts);
      };
      return React.createElement(Modal$Pos.make, {
                  children: children,
                  opened: opened,
                  title: Intl$Pos.t("Product append"),
                  variation: "secondary",
                  backgroundColor: Colors$Pos.neutralColor00,
                  renderStartText: renderStartText,
                  abortButtonText: Intl$Pos.t("Cancel"),
                  allowCommit: selectedProducts.length !== 0,
                  commitButtonVariation: "primary",
                  commitButtonText: Intl$Pos.t("Add products"),
                  commitButtonCallback: onCommit$1,
                  onRequestClose: onRequestClose
                });
    });

var PickerModal = {
  make: make
};

function makeVariables$1(search, shopId, after, param) {
  return makeVariables(search, makeInputObjectInputVariantsQueryFilter({
                  _in: [shopId]
                }, undefined, undefined, {
                  _equals: true
                }, "EXCLUDED", undefined, undefined, undefined, undefined, undefined), undefined, after, 10, undefined, undefined);
}

function CatalogLabelProductsPickerModalTable(Props) {
  var opened = Props.opened;
  var shopId = Props.shopId;
  var priceId = Props.priceId;
  var disabledIdsOpt = Props.disabledIds;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var match = React.useState(function () {
        return [];
      });
  var setSelectedProducts = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchQuery = match$1[1];
  var searchQuery = match$1[0];
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        /* CacheFirst */1,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        makeVariables$1(searchQuery, shopId, undefined, undefined)
      ]);
  var asyncResult = ApolloHelpers$Pos.useQueryResultToAsyncResultWithVariablesReloading2(query, searchQuery, shopId);
  var data = AsyncResult$Pos.mapOk(asyncResult, (function (param) {
          return rowsFromData(param, priceId);
        }));
  React.useEffect((function () {
          if (!opened && searchQuery !== "") {
            Curry._1(setSearchQuery, (function (param) {
                    return "";
                  }));
          }
          
        }), [opened]);
  var onLoadMore = React.useCallback((function (param) {
          if (typeof asyncResult === "number") {
            return ;
          }
          if (asyncResult.TAG !== /* Done */1) {
            return ;
          }
          var data = asyncResult._0;
          if (data.TAG !== /* Ok */0) {
            return ;
          }
          var data$1 = data._0;
          if (data$1.variants.pageInfo.hasNextPage === true) {
            Curry._5(query.fetchMore, undefined, undefined, makeVariables$1(searchQuery, shopId, data$1.variants.pageInfo.endCursor, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newVariants = fetchMoreResult.variants;
                    return {
                            variants: {
                              __typename: newVariants.__typename,
                              pageInfo: newVariants.pageInfo,
                              totalCount: newVariants.totalCount,
                              edges: Belt_Array.concat(prevResult.variants.edges, newVariants.edges)
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [asyncResult]);
  var onSelectChange = React.useCallback((function (selection) {
          Curry._1(setSelectedProducts, (function (prev) {
                  var rowsData;
                  if (typeof data === "number") {
                    rowsData = data === /* NotAsked */0 ? [] : [];
                  } else if (data.TAG === /* Reloading */0) {
                    var data$1 = data._0;
                    rowsData = data$1.TAG === /* Ok */0 ? data$1._0 : [];
                  } else {
                    var data$2 = data._0;
                    rowsData = data$2.TAG === /* Ok */0 ? data$2._0 : [];
                  }
                  if (selection) {
                    return Belt_Array.keepMap(selection._0, (function (selectedRowId) {
                                  return Belt_Array.getBy(Belt_Array.concat(rowsData, prev), (function (row) {
                                                return row.id === selectedRowId;
                                              }));
                                }));
                  } else {
                    return prev;
                  }
                }));
        }), [
        data,
        disabledIds
      ]);
  var disabledRowsKeys;
  if (typeof data === "number" || data.TAG !== /* Done */1) {
    disabledRowsKeys = disabledIds;
  } else {
    var rows = data._0;
    disabledRowsKeys = rows.TAG === /* Ok */0 ? Belt_Array.concat(disabledIds, Belt_Array.keepMap(rows._0, (function (row) {
                  if (!row.hasValidVariantPrice) {
                    return row.id;
                  }
                  
                }))) : disabledIds;
  }
  return React.createElement(make, {
              children: React.createElement(AnimatedRender$Pos.make, {
                    children: React.createElement(TableView$Pos.make, {
                          data: data,
                          columns: columns,
                          keyExtractor: (function (row) {
                              return row.id;
                            }),
                          disabledRowsKeys: disabledRowsKeys,
                          selectionEnabled: true,
                          selectAllEnabled: false,
                          hideReloadingPlaceholder: true,
                          maxWidth: 580,
                          minHeight: 315,
                          maxHeight: 315,
                          searchPlaceholder: Intl$Pos.t("Search product"),
                          onSearchQueryChange: (function (value) {
                              Curry._1(setSearchQuery, (function (param) {
                                      return value;
                                    }));
                            }),
                          onLoadMore: onLoadMore,
                          onSelectChange: onSelectChange
                        }),
                    displayed: opened,
                    animation: "fadePopinTranslation",
                    duration: 300
                  }),
              opened: opened,
              selectedProducts: match[0],
              onCommit: onCommit,
              onRequestClose: onRequestClose
            });
}

var make$1 = React.memo(CatalogLabelProductsPickerModalTable, (function (oldProps, newProps) {
        return oldProps.opened === newProps.opened;
      }));

export {
  Query ,
  rowsFromData ,
  columns ,
  PickerModal ,
  makeVariables$1 as makeVariables,
  make$1 as make,
}
/* query Not a pure module */
