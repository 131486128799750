// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";

function CatalogPlaceholderShopPicker(Props) {
  var children = Props.children;
  var message = Props.message;
  var disabledIdsOpt = Props.disabledIds;
  var renderItemContent = Props.renderItemContent;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var shops = Auth$Pos.useShops(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var placeholderChildComponent = React.useCallback((function (param) {
          var dispatch = Auth$Pos.useDispatch(undefined);
          var sections = [{
              items: Belt_Array.map(shops, (function (shop) {
                      return {
                              key: shop.id,
                              value: shop,
                              label: shop.name,
                              disabled: Belt_Array.some(disabledIds, (function (id) {
                                      return id === shop.id;
                                    }))
                            };
                    }))
            }];
          var tmp = {
            placeholder: Intl$Pos.t("Select a shop"),
            sections: sections,
            variation: {
              NAME: "field",
              VAL: {
                required: true
              }
            },
            value: activeShop,
            onChange: (function (value) {
                Curry._1(dispatch, {
                      TAG: /* ActiveShopSet */2,
                      _0: value
                    });
              })
          };
          if (renderItemContent !== undefined) {
            tmp.renderItemContent = Caml_option.valFromOption(renderItemContent);
          }
          return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Select$Pos.make, tmp)), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(50)), undefined, undefined)), undefined, undefined, undefined));
        }), [
        shops,
        disabledIds
      ]);
  if (shops.length !== 0) {
    if (activeShop !== undefined) {
      return children;
    } else {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Pending */{
                    title: Intl$Pos.t("Beware !"),
                    message: message,
                    illustration: Caml_option.some(Illustration$Pos.shopMissing)
                  },
                  childComponent: placeholderChildComponent
                });
    }
  } else {
    return React.createElement(Placeholder$Pos.make, {
                status: /* Loading */0
              });
  }
}

var make = React.memo(CatalogPlaceholderShopPicker);

export {
  make ,
}
/* make Not a pure module */
