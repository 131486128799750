// Generated by ReScript, PLEASE EDIT WITH CARE

import * as OrderStatus$Pos from "../../modules/Order/OrderStatus.bs.js";

function isBeforeReception(statuses) {
  return !(OrderStatus$Pos.has(statuses, "RECEIVED") || OrderStatus$Pos.has(statuses, "RECEIVING"));
}

function isInitialFullEdition(statuses, created) {
  if (OrderStatus$Pos.has(statuses, "DRAFT") && !created) {
    return true;
  } else if (OrderStatus$Pos.has(statuses, "RECEIVING")) {
    return created;
  } else {
    return false;
  }
}

function isLimitedEdition(statuses) {
  if (OrderStatus$Pos.has(statuses, "RECEIVED")) {
    return !OrderStatus$Pos.has(statuses, "PAID");
  } else {
    return false;
  }
}

export {
  isBeforeReception ,
  isInitialFullEdition ,
  isLimitedEdition ,
}
/* OrderStatus-Pos Not a pure module */
