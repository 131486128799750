// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Form__Schema$Pos from "./Form__Schema.bs.js";
import * as Form__Status$Pos from "./Form__Status.bs.js";
import * as Form__Submission$Pos from "./Form__Submission.bs.js";
import * as ReactUpdateReducer$Pos from "../../primitives/ReactUpdateReducer.bs.js";

function Make(Lenses) {
  var Schema = Form__Schema$Pos.Make(Lenses);
  var useReducer = function (initialValues, schema, id) {
    return ReactUpdateReducer$Pos.useWithMapState((function (state, action) {
                  if (typeof action === "number") {
                    if (action !== /* ValidationRequested */0) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                id: state.id,
                                initialValues: state.initialValues,
                                values: state.initialValues,
                                status: /* Pristine */0,
                                submission: /* Pending */0,
                                schema: state.schema,
                                validation: state.validation
                              }
                            };
                    }
                    var validation = Curry._2(Schema.validate, schema, state.values);
                    var status;
                    status = validation.TAG === /* Ok */0 ? /* Valid */1 : /* Errored */2;
                    if (Caml_obj.equal(validation, state.validation)) {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                id: state.id,
                                initialValues: state.initialValues,
                                values: state.values,
                                status: status,
                                submission: state.submission,
                                schema: state.schema,
                                validation: state.validation
                              }
                            };
                    } else {
                      return {
                              TAG: /* Update */0,
                              _0: {
                                id: state.id,
                                initialValues: state.initialValues,
                                values: state.values,
                                status: status,
                                submission: state.submission,
                                schema: state.schema,
                                validation: validation
                              }
                            };
                    }
                  } else {
                    switch (action.TAG | 0) {
                      case /* ResetRequested */0 :
                          var values = action._0;
                          return {
                                  TAG: /* Update */0,
                                  _0: {
                                    id: state.id,
                                    initialValues: values,
                                    values: values,
                                    status: /* Pristine */0,
                                    submission: state.submission,
                                    schema: state.schema,
                                    validation: state.validation
                                  }
                                };
                      case /* FieldsValuesChanged */1 :
                          return {
                                  TAG: /* UpdateWithSideEffects */1,
                                  _0: {
                                    id: state.id,
                                    initialValues: state.initialValues,
                                    values: Curry._1(action._0, state.values),
                                    status: state.status,
                                    submission: state.submission,
                                    schema: state.schema,
                                    validation: state.validation
                                  },
                                  _1: (function (self) {
                                      Curry._1(self.dispatch, /* ValidationRequested */0);
                                    })
                                };
                      case /* FieldValueChanged */2 :
                          var field = action._0;
                          return {
                                  TAG: /* UpdateWithSideEffects */1,
                                  _0: {
                                    id: state.id,
                                    initialValues: state.initialValues,
                                    values: Curry._3(Lenses.set, state.values, field, Curry._1(action._1, Curry._2(Lenses.get, state.values, field))),
                                    status: state.status,
                                    submission: state.submission,
                                    schema: state.schema,
                                    validation: state.validation
                                  },
                                  _1: (function (self) {
                                      Curry._1(self.dispatch, /* ValidationRequested */0);
                                    })
                                };
                      case /* SubmitButtonClicked */3 :
                          var handler = action._0;
                          var validation$1 = Curry._2(Schema.validate, schema, state.values);
                          var status$1;
                          status$1 = validation$1.TAG === /* Ok */0 ? /* Valid */1 : /* Errored */2;
                          var submission = Form__Status$Pos.isErrored(status$1) ? /* Pending */0 : /* Requested */1;
                          return {
                                  TAG: /* UpdateWithSideEffects */1,
                                  _0: {
                                    id: state.id,
                                    initialValues: state.initialValues,
                                    values: state.values,
                                    status: status$1,
                                    submission: submission,
                                    schema: state.schema,
                                    validation: validation$1
                                  },
                                  _1: (function (self) {
                                      switch (status$1) {
                                        case /* Pristine */0 :
                                            break;
                                        case /* Valid */1 :
                                            Future.get(Curry._2(handler, state.id, state.values), (function (result) {
                                                    var tmp;
                                                    tmp = result.TAG === /* Ok */0 ? ({
                                                          TAG: /* SubmissionSucceeded */4,
                                                          _0: result._0
                                                        }) : ({
                                                          TAG: /* SubmissionFailed */5,
                                                          _0: result._0
                                                        });
                                                    Curry._1(self.dispatch, tmp);
                                                  }));
                                            break;
                                        case /* Errored */2 :
                                            Curry._1(self.dispatch, {
                                                  TAG: /* SubmissionFailed */5,
                                                  _0: Intl$Pos.t("There are some errors in the form, please correct them before trying to send it again.")
                                                });
                                            break;
                                        
                                      }
                                    })
                                };
                      case /* SubmissionSucceeded */4 :
                          return {
                                  TAG: /* Update */0,
                                  _0: {
                                    id: state.id,
                                    initialValues: state.initialValues,
                                    values: state.values,
                                    status: state.status,
                                    submission: {
                                      TAG: /* Succeeded */1,
                                      _0: action._0
                                    },
                                    schema: state.schema,
                                    validation: state.validation
                                  }
                                };
                      case /* SubmissionFailed */5 :
                          return {
                                  TAG: /* Update */0,
                                  _0: {
                                    id: state.id,
                                    initialValues: state.initialValues,
                                    values: state.values,
                                    status: state.status,
                                    submission: {
                                      TAG: /* Failed */0,
                                      _0: action._0
                                    },
                                    schema: state.schema,
                                    validation: state.validation
                                  }
                                };
                      case /* IdAndInitialValuesUpdated */6 :
                          var values$1 = action._1;
                          return {
                                  TAG: /* Update */0,
                                  _0: {
                                    id: action._0,
                                    initialValues: values$1,
                                    values: values$1,
                                    status: /* Pristine */0,
                                    submission: /* Pending */0,
                                    schema: state.schema,
                                    validation: state.validation
                                  }
                                };
                      
                    }
                  }
                }), (function (param) {
                  return {
                          id: id,
                          initialValues: initialValues,
                          values: initialValues,
                          status: /* Pristine */0,
                          submission: /* Pending */0,
                          schema: schema,
                          validation: Curry._2(Schema.validate, schema, initialValues)
                        };
                }));
  };
  var useFormPropState = function (param) {
    var onSubmitSuccess = param.onSubmitSuccess;
    var onSubmitFailure = param.onSubmitFailure;
    var resetValuesAfterSubmission = param.resetValuesAfterSubmission;
    var initialValues = param.initialValues;
    var id = param.id;
    var match = useReducer(initialValues, param.schema, id);
    var dispatch = match[1];
    var state = match[0];
    React.useEffect((function () {
            Curry._1(dispatch, {
                  TAG: /* IdAndInitialValuesUpdated */6,
                  _0: id,
                  _1: initialValues
                });
          }), [id]);
    React.useEffect((function () {
            Belt_Option.forEach(onSubmitFailure, (function (fn) {
                    var failure = state.submission;
                    if (typeof failure === "number" || failure.TAG !== /* Failed */0) {
                      return ;
                    } else {
                      return Curry._1(fn, failure._0);
                    }
                  }));
            Belt_Option.forEach(onSubmitSuccess, (function (fn) {
                    var ok = state.submission;
                    if (typeof ok === "number" || ok.TAG !== /* Succeeded */1) {
                      return ;
                    } else {
                      return Curry._1(fn, ok._0);
                    }
                  }));
          }), [state.submission]);
    React.useEffect((function () {
            if (Form__Submission$Pos.isSucceeded(state.submission)) {
              var values = resetValuesAfterSubmission !== undefined && resetValuesAfterSubmission ? initialValues : state.values;
              Curry._1(dispatch, {
                    TAG: /* ResetRequested */0,
                    _0: values
                  });
            }
            
          }), [state.submission]);
    return [
            state,
            dispatch
          ];
  };
  var context = React.createContext(undefined);
  var contextProvider = context.Provider;
  var Form__Core$Make$ContextProvider = function (Props) {
    var value = Props.value;
    var children = Props.children;
    return React.createElement(contextProvider, {
                value: value,
                children: children
              });
  };
  var ContextProvider = {
    contextProvider: contextProvider,
    make: Form__Core$Make$ContextProvider
  };
  var useFormContext = function (param) {
    var value = React.useContext(context);
    if (value !== undefined) {
      return value;
    } else {
      return Pervasives.failwith("Could not find form context value");
    }
  };
  var useFormState = function (param) {
    return useFormContext(undefined)[0];
  };
  var useFormDispatch = function (param) {
    return useFormContext(undefined)[1];
  };
  var useFormValues = function (param) {
    return useFormState(undefined).values;
  };
  var useFormStatus = function (param) {
    return useFormState(undefined).status;
  };
  var Form__Core$Make$Provider = function (Props) {
    var children = Props.children;
    var propState = Props.propState;
    return React.createElement(Form__Core$Make$ContextProvider, {
                value: propState,
                children: children
              });
  };
  var Provider = {
    make: Form__Core$Make$Provider
  };
  var Form__Core$Make$LegacyProvider = function (Props) {
    var initialValues = Props.initialValues;
    var schema = Props.schema;
    var children = Props.children;
    var id = Props.id;
    var onSubmitFailure = Props.onSubmitFailure;
    var onSubmitSuccess = Props.onSubmitSuccess;
    var resetValuesAfterSubmission = Props.resetValuesAfterSubmission;
    var propState = useFormPropState({
          id: id,
          initialValues: initialValues,
          schema: schema,
          resetValuesAfterSubmission: resetValuesAfterSubmission,
          onSubmitFailure: onSubmitFailure,
          onSubmitSuccess: onSubmitSuccess
        });
    return React.createElement(Form__Core$Make$Provider, {
                children: children,
                propState: propState
              });
  };
  var LegacyProvider = {
    make: Form__Core$Make$LegacyProvider
  };
  var useField = function (field, hideErrorOpt, hideRequiredOpt, enableTouchAfterValueChange, param) {
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var match = useFormContext(undefined);
    var dispatch = match[1];
    var state = match[0];
    var value = Curry._2(Lenses.get, state.values, field);
    var match$1 = React.useState(function () {
          return false;
        });
    var setFocused = match$1[1];
    var match$2 = React.useState(function () {
          return false;
        });
    var setTouched = match$2[1];
    var touched = match$2[0];
    var errors = state.validation;
    var validation;
    if (errors.TAG === /* Ok */0) {
      validation = {
        TAG: /* Ok */0,
        _0: undefined
      };
    } else {
      var error = Belt_Array.getBy(errors._0, (function (error) {
              return Caml_obj.equal(error[0], /* Field */{
                          _0: field
                        });
            }));
      validation = error !== undefined ? ({
            TAG: /* Error */1,
            _0: error[1]
          }) : ({
            TAG: /* Ok */0,
            _0: undefined
          });
    }
    var match$3 = state.submission;
    var error$1;
    if (validation.TAG === /* Ok */0) {
      error$1 = undefined;
    } else {
      var error$2 = validation._0;
      error$1 = hideError || match$3 === 0 && !touched ? undefined : error$2;
    }
    var required = !hideRequired && Curry._2(Schema.required, state.schema, field);
    var onChange = React.useCallback((function (value) {
            if (touched === false && enableTouchAfterValueChange === true) {
              Curry._1(setTouched, (function (param) {
                      return true;
                    }));
            }
            Curry._1(dispatch, {
                  TAG: /* FieldValueChanged */2,
                  _0: field,
                  _1: (function (_prev) {
                      return value;
                    })
                });
          }), [
          enableTouchAfterValueChange,
          touched
        ]);
    var onFocus = React.useCallback((function (param) {
            Curry._1(setFocused, (function (param) {
                    return true;
                  }));
          }), []);
    var onBlur = React.useCallback((function (param) {
            Curry._1(setTouched, (function (param) {
                    return true;
                  }));
            Curry._1(setFocused, (function (param) {
                    return false;
                  }));
          }), [
          touched,
          value
        ]);
    return {
            value: value,
            focused: match$1[0],
            error: error$1,
            required: required,
            onChange: onChange,
            onFocus: onFocus,
            onBlur: onBlur
          };
  };
  var useSubmit = function (handler) {
    var match = useFormContext(undefined);
    var submission = match[0].submission;
    var dispatch = match[1];
    var disabled = Form__Submission$Pos.isRequested(submission);
    var onSubmit = function (param) {
      if (disabled) {
        return ;
      } else {
        return Curry._1(dispatch, {
                    TAG: /* SubmitButtonClicked */3,
                    _0: handler
                  });
      }
    };
    return {
            disabled: disabled,
            submission: submission,
            onSubmit: onSubmit
          };
  };
  var useCancel = function (param) {
    var match = useFormContext(undefined);
    var dispatch = match[1];
    var state = match[0];
    var match$1 = state.status;
    var match$2 = state.submission;
    var disabled = typeof match$2 === "number" && match$2 !== 0 ? true : match$1 === 0;
    var onCancel = React.useCallback((function (param) {
            Curry._1(dispatch, /* CancelButtonClicked */1);
          }), []);
    return [
            disabled,
            onCancel
          ];
  };
  var useReset = function (param) {
    var match = useFormContext(undefined);
    var dispatch = match[1];
    var state = match[0];
    return React.useCallback((function (param) {
                  Curry._1(dispatch, {
                        TAG: /* ResetRequested */0,
                        _0: state.initialValues
                      });
                }), []);
  };
  return {
          Status: undefined,
          Submission: undefined,
          Schema: Schema,
          useReducer: useReducer,
          useFormPropState: useFormPropState,
          context: context,
          ContextProvider: ContextProvider,
          useFormContext: useFormContext,
          useFormState: useFormState,
          useFormDispatch: useFormDispatch,
          useFormValues: useFormValues,
          useFormStatus: useFormStatus,
          Provider: Provider,
          LegacyProvider: LegacyProvider,
          useField: useField,
          useSubmit: useSubmit,
          useCancel: useCancel,
          useReset: useReset
        };
}

export {
  Make ,
}
/* react Not a pure module */
