// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Pastille$Pos from "../../resources/feedback-indicators/Pastille.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";

function ProductStockTableCell(Props) {
  var value = Props.value;
  var state = Props.state;
  var sizeOpt = Props.size;
  var formattedShopsNames = Props.formattedShopsNames;
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  if (value !== undefined) {
    return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                            children: null,
                            space: Belt_Option.isSome(formattedShopsNames) ? "xxsmall" : "none"
                          }, React.createElement(Inline$Pos.make, {
                                children: null,
                                space: "none"
                              }, state !== undefined ? React.createElement(Pastille$Pos.make, {
                                      variant: state === "OK" ? "success" : (
                                          state === "ALERT" ? "warning" : "danger"
                                        )
                                    }) : null, React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, undefined, undefined, undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined)), React.createElement(TextStyle$Pos.make, {
                                    children: value,
                                    size: size
                                  })), formattedShopsNames !== undefined ? React.createElement(TextStyle$Pos.make, {
                                  children: formattedShopsNames,
                                  variation: "normal",
                                  size: "tiny"
                                }) : null)), undefined, undefined, undefined, undefined));
  } else {
    return React.createElement(TextStyle$Pos.make, {
                children: "-",
                size: size
              });
  }
}

var make = React.memo(ProductStockTableCell);

export {
  make ,
}
/* make Not a pure module */
