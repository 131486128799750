// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../overlays/Popover.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as DayPickerRange$Pos from "./DayPickerRange.bs.js";
import * as DateRangePicker$Pos from "./DateRangePicker.bs.js";
import * as OverlayTriggerView$Pos from "../overlays/OverlayTriggerView.bs.js";

function dateRangetoPartialDateRange(value) {
  if (value !== undefined) {
    return [
            Caml_option.some(value[0]),
            Caml_option.some(value[1])
          ];
  } else {
    return [
            undefined,
            undefined
          ];
  }
}

var presetfromPartialDateRange = DateRangePicker$Pos.Preset.fromPartialDateRange;

var presetToDateRange = DateRangePicker$Pos.Preset.toDateRange;

function labelFromPreset(preset, placeholderOpt, param) {
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : Intl$Pos.t("Pick a date range");
  var divider = " → ";
  if (typeof preset === "number") {
    if (preset >= 8) {
      return placeholder;
    } else {
      return Belt_Option.getWithDefault(DateRangePicker$Pos.Preset.toString(preset), placeholder);
    }
  }
  var match = preset._0;
  var startDate = match[0];
  if (startDate === undefined) {
    return Belt_Option.getWithDefault(DateRangePicker$Pos.Preset.toString(preset), placeholder);
  }
  var endDate = match[1];
  var startDate$1 = Caml_option.valFromOption(startDate);
  if (endDate === undefined) {
    return Intl$Pos.dateTimeFormat(undefined, "medium", startDate$1) + divider + " …";
  }
  var endDate$1 = Caml_option.valFromOption(endDate);
  if (DateHelpers$Pos.isSameDay(startDate$1, endDate$1)) {
    return Intl$Pos.dateTimeFormat(undefined, "medium", startDate$1);
  } else {
    return Intl$Pos.dateTimeFormat(undefined, "medium", startDate$1) + divider + Intl$Pos.dateTimeFormat(undefined, "medium", endDate$1);
  }
}

function SelectDateRange(Props) {
  var value = Props.value;
  var label = Props.label;
  var placeholder = Props.placeholder;
  var disabledResetButton = Props.disabledResetButton;
  var onChange = Props.onChange;
  var mounted = React.useRef(false);
  var match = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover$Pos.use(popoverTriggerRef, undefined);
  var match$2 = React.useState(function () {
        return DayPickerRange$Pos.defaultFocusedInput;
      });
  var setFocusedInput = match$2[1];
  var match$3 = React.useState(function () {
        return dateRangetoPartialDateRange(value);
      });
  var setPartialDateRange = match$3[1];
  var partialDateRange = match$3[0];
  var match$4 = React.useState(function () {
        return presetfromPartialDateRange(partialDateRange);
      });
  var setPreset = match$4[1];
  var preset = match$4[0];
  var match$5 = React.useState(function () {
        var endDate = partialDateRange[1];
        if (endDate !== undefined) {
          return Caml_option.valFromOption(endDate);
        } else {
          return new Date();
        }
      });
  var setVisibleMonth = match$5[1];
  React.useEffect((function () {
          if (mounted.current) {
            var exit = 0;
            if (value !== undefined) {
              var rangeStartDate = partialDateRange[0];
              if (rangeStartDate !== undefined) {
                var rangeEndDate = partialDateRange[1];
                if (!(rangeEndDate !== undefined && value[0].getTime() === Caml_option.valFromOption(rangeStartDate).getTime() && value[1].getTime() === Caml_option.valFromOption(rangeEndDate).getTime())) {
                  exit = 1;
                }
                
              } else {
                exit = 1;
              }
            } else if (partialDateRange[0] !== undefined || partialDateRange[1] !== undefined) {
              exit = 1;
            }
            if (exit === 1) {
              Curry._1(setPartialDateRange, (function (param) {
                      return dateRangetoPartialDateRange(value);
                    }));
            }
            
          }
          
        }), [value]);
  React.useEffect((function () {
          if (mounted.current) {
            if (value !== undefined) {
              var startPartialDateRange = partialDateRange[0];
              if (startPartialDateRange !== undefined) {
                var endPartialDateRange = partialDateRange[1];
                if (endPartialDateRange !== undefined) {
                  var endPartialDateRange$1 = Caml_option.valFromOption(endPartialDateRange);
                  var startPartialDateRange$1 = Caml_option.valFromOption(startPartialDateRange);
                  if (value[0].getTime() !== startPartialDateRange$1.getTime() || value[1].getTime() !== endPartialDateRange$1.getTime()) {
                    Curry._1(onChange, [
                          startPartialDateRange$1,
                          endPartialDateRange$1
                        ]);
                  }
                  
                }
                
              } else if (partialDateRange[1] !== undefined) {
                
              } else {
                Curry._1(onChange, undefined);
              }
            } else {
              var startPartialDateRange$2 = partialDateRange[0];
              if (startPartialDateRange$2 !== undefined) {
                var endPartialDateRange$2 = partialDateRange[1];
                if (endPartialDateRange$2 !== undefined) {
                  Curry._1(onChange, [
                        Caml_option.valFromOption(startPartialDateRange$2),
                        Caml_option.valFromOption(endPartialDateRange$2)
                      ]);
                }
                
              }
              
            }
            var match = presetToDateRange(preset);
            var exit = 0;
            if (match !== undefined) {
              var startB = partialDateRange[0];
              if (startB !== undefined) {
                var endB = partialDateRange[1];
                if (!(endB !== undefined && match[0].valueOf() === Caml_option.valFromOption(startB).valueOf() && match[1].valueOf() === Caml_option.valFromOption(endB).valueOf())) {
                  exit = 1;
                }
                
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
            if (exit === 1) {
              Curry._1(setPreset, (function (param) {
                      return presetfromPartialDateRange(partialDateRange);
                    }));
            }
            
          }
          
        }), [partialDateRange]);
  React.useEffect((function () {
          if (mounted.current) {
            var match = presetToDateRange(preset);
            var startPartialDateRange = partialDateRange[0];
            var exit = 0;
            var exit$1 = 0;
            if (typeof preset === "number") {
              if (match !== undefined) {
                exit$1 = 2;
              }
              
            } else if (match !== undefined) {
              exit$1 = 2;
            }
            if (exit$1 === 2) {
              if (startPartialDateRange !== undefined) {
                var endPartialDateRange = partialDateRange[1];
                if (!(endPartialDateRange !== undefined && Caml_option.valFromOption(startPartialDateRange).getTime() === match[0].getTime() && Caml_option.valFromOption(endPartialDateRange).getTime() === match[1].getTime())) {
                  exit = 1;
                }
                
              } else {
                exit = 1;
              }
            }
            if (exit === 1) {
              Curry._1(setPartialDateRange, (function (param) {
                      return dateRangetoPartialDateRange(match);
                    }));
            }
            if (typeof preset === "number" && preset < 8) {
              Curry._1(popover.close, undefined);
            }
            
          }
          
        }), [preset]);
  React.useEffect((function () {
          mounted.current = true;
          return (function (param) {
                    mounted.current = false;
                  });
        }), []);
  var onDatesChange = function (param) {
    var endDate = param[1];
    var startDate = param[0];
    Curry._1(setPartialDateRange, (function (param) {
            return [
                    Belt_Option.map(startDate, DateHelpers$Pos.startOfDay),
                    Belt_Option.map(endDate, DateHelpers$Pos.endOfDay)
                  ];
          }));
  };
  var onFocusChange = function (focusedInput) {
    Curry._1(setFocusedInput, (function (param) {
            if (focusedInput == null) {
              return DayPickerRange$Pos.defaultFocusedInput;
            } else {
              return focusedInput;
            }
          }));
  };
  var onRequestReset = function (param) {
    Curry._1(setPartialDateRange, (function (param) {
            return [
                    undefined,
                    undefined
                  ];
          }));
  };
  var onPrevMonthClick = function (date) {
    Curry._1(setVisibleMonth, (function (param) {
            return date;
          }));
  };
  var onNextMonthClick = function (date) {
    Curry._1(setVisibleMonth, (function (param) {
            return date;
          }));
  };
  var onChangePreset = function (preset) {
    Curry._1(setPreset, (function (param) {
            return preset;
          }));
    var match = presetToDateRange(preset);
    var endDate = partialDateRange[1];
    if (endDate === undefined) {
      return ;
    }
    if (match === undefined) {
      return ;
    }
    var nextEndDate = match[1];
    if (!DateHelpers$Pos.isSameMonth(Caml_option.valFromOption(endDate), nextEndDate)) {
      return Curry._1(setVisibleMonth, (function (param) {
                    return nextEndDate;
                  }));
    }
    
  };
  var tmp = {
    children: labelFromPreset(preset, placeholder, undefined),
    variation: {
      NAME: "filter",
      VAL: {
        hideLabelColon: Belt_Option.isNone(value)
      }
    },
    icon: "calendar",
    hovered: match$1[1],
    active: popover.isOpen,
    focused: popover.isOpen
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  var tmp$1;
  if (popover.isOpen) {
    var tmp$2 = {
      focusedInput: match$2[0],
      visibleMonth: match$5[0],
      preset: preset,
      range: partialDateRange,
      onChangePreset: onChangePreset,
      onRequestReset: onRequestReset,
      onDatesChange: onDatesChange,
      onFocusChange: onFocusChange,
      onPrevMonthClick: onPrevMonthClick,
      onNextMonthClick: onNextMonthClick
    };
    if (disabledResetButton !== undefined) {
      tmp$2.disabledResetButton = disabledResetButton;
    }
    tmp$1 = React.createElement(Popover$Pos.make, {
          children: React.createElement(Popover$Pos.Dialog.make, {
                children: React.createElement(DateRangePicker$Pos.make, tmp$2),
                ariaProps: popoverAriaProps.overlayProps
              }),
          triggerRef: popoverTriggerRef,
          state: {
            isOpen: popover.isOpen,
            close: popover.close,
            toggle: popover.toggle
          },
          placement: "bottom start",
          dismissable: false
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(OverlayTriggerView$Pos.make, tmp), popoverAriaProps.triggerProps, undefined, undefined, undefined, undefined, (function (param) {
                        Curry._1(popover.toggle, undefined);
                      }), undefined, popoverTriggerRef, undefined)), tmp$1);
}

var make = React.memo(SelectDateRange);

var todayDateRange = DateRangePicker$Pos.todayDateRange;

var defaultFocusedInput = DayPickerRange$Pos.defaultFocusedInput;

export {
  dateRangetoPartialDateRange ,
  presetfromPartialDateRange ,
  presetToDateRange ,
  labelFromPreset ,
  todayDateRange ,
  defaultFocusedInput ,
  make ,
}
/* make Not a pure module */
