// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsonCodec$Pos from "../primitives/JsonCodec.bs.js";
import * as Navigation$Pos from "../primitives/Navigation.bs.js";
import * as QueryString$Pos from "../primitives/QueryString.bs.js";

function totalPages(totalCount, nodesPerPage) {
  if (nodesPerPage <= 0 || totalCount <= 0) {
    return 1;
  } else if (Caml_int32.mod_(totalCount, nodesPerPage) === 0) {
    return Caml_int32.div(totalCount, nodesPerPage);
  } else {
    return Caml_int32.div(totalCount, nodesPerPage) + 1 | 0;
  }
}

function connectionArguments(currentPage, previousPage, totalCount, cursors) {
  if (currentPage === 1) {
    return {
            first: 10
          };
  }
  var startCursor = cursors[0];
  var exit = 0;
  if (startCursor !== undefined) {
    if (currentPage === (previousPage - 1 | 0)) {
      return {
              last: 10,
              before: startCursor
            };
    }
    exit = 2;
  } else {
    exit = 2;
  }
  if (exit === 2) {
    var endCursor = cursors[1];
    if (endCursor !== undefined && currentPage === (previousPage + 1 | 0)) {
      return {
              first: 10,
              after: endCursor
            };
    }
    
  }
  if (currentPage === totalPages(totalCount, 10)) {
    return {
            last: 10
          };
  }
  
}

function initialState(filters) {
  return {
          currentPage: 1,
          previousPage: -1,
          filters: filters,
          connectionArguments: {
            first: 10
          }
        };
}

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* Navigated */0 :
        var nextPage = action.nextPage;
        var previousPage = state.currentPage;
        var maybeConnectionArguments = connectionArguments(nextPage, previousPage, action.totalCount, action.cursors);
        if (maybeConnectionArguments === undefined) {
          return state;
        }
        var newrecord = Caml_obj.obj_dup(state);
        newrecord.connectionArguments = maybeConnectionArguments;
        newrecord.previousPage = previousPage;
        newrecord.currentPage = nextPage;
        return newrecord;
    case /* Searched */1 :
        var searchQuery = action._0;
        var tmp = searchQuery === "" ? undefined : searchQuery;
        return {
                currentPage: 1,
                previousPage: -1,
                searchQuery: tmp,
                filters: state.filters,
                connectionArguments: {
                  first: 10
                }
              };
    case /* FiltersUpdated */2 :
        return {
                currentPage: 1,
                previousPage: -1,
                searchQuery: state.searchQuery,
                filters: Curry._1(action._0, state.filters),
                connectionArguments: {
                  first: 10
                }
              };
    case /* Reset */3 :
        return action._0;
    
  }
}

function nextPage(state, action, totalPages) {
  var match = state.currentPage;
  if ((match === 0 || match === 1) && (totalPages === 0 || totalPages === 1)) {
    return ;
  }
  switch (action) {
    case /* First */0 :
        if (match >= 1) {
          return 1;
        } else {
          return ;
        }
    case /* Prev */1 :
        if (match > totalPages) {
          return totalPages;
        } else if (match > 1) {
          return match - 1 | 0;
        } else {
          return ;
        }
    case /* Next */2 :
        if (match > totalPages) {
          return totalPages;
        } else if (match >= 1 && match < totalPages) {
          return match + 1 | 0;
        } else {
          return ;
        }
    case /* Last */3 :
        if (match >= 1) {
          return totalPages;
        } else {
          return ;
        }
    
  }
}

function encoder(param) {
  var connectionArguments = param.connectionArguments;
  return [
          param.currentPage,
          param.previousPage,
          param.searchQuery,
          Caml_option.some(param.filters),
          connectionArguments.first,
          connectionArguments.last,
          connectionArguments.after,
          connectionArguments.before
        ];
}

function value(filtersJsonCodec, initialFilters) {
  return JsonCodec$Pos.object8(encoder, (function (param) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          currentPage: Belt_Option.getWithDefault(param[0], 1),
                          previousPage: Belt_Option.getWithDefault(param[1], -1),
                          searchQuery: param[2],
                          filters: Belt_Option.getWithDefault(param[3], initialFilters),
                          connectionArguments: {
                            first: param[4],
                            last: param[5],
                            after: param[6],
                            before: param[7]
                          }
                        }
                      };
              }), JsonCodec$Pos.optional(JsonCodec$Pos.field("page", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("previousPage", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("search", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("filters", filtersJsonCodec)), JsonCodec$Pos.optional(JsonCodec$Pos.field("first", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("last", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("after", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("before", JsonCodec$Pos.string)));
}

function use(initialState, filtersJsonCodec) {
  var codec = value(filtersJsonCodec, initialState.filters);
  var navigate = Navigation$Pos.useNavigate(undefined);
  var url = Navigation$Pos.useUrl(undefined);
  var initialState$1 = React.useMemo((function () {
          var urlState = JsonCodec$Pos.decodeWith(QueryString$Pos.parse(url.query), codec);
          var match = url.query;
          if (urlState.TAG === /* Ok */0 && match !== "") {
            return urlState._0;
          } else {
            return initialState;
          }
        }), []);
  var match = React.useReducer(Curry.__2(reducer), initialState$1);
  var state = match[0];
  React.useEffect((function () {
          var query = QueryString$Pos.stringify(JsonCodec$Pos.encodeWith(state, codec));
          var route = url.pathname + "?" + query;
          if (query !== url.query) {
            Curry._4(navigate, route, true, undefined, undefined);
          }
          
        }), [state]);
  return [
          state,
          match[1]
        ];
}

var edgesPerPage = 10;

export {
  edgesPerPage ,
  totalPages ,
  connectionArguments ,
  initialState ,
  reducer ,
  nextPage ,
  use ,
}
/* react Not a pure module */
