// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as AnalyticsShopsPage$Pos from "./AnalyticsShopsPage.bs.js";
import * as AnalyticsCashFlowPage$Pos from "./AnalyticsCashFlowPage.bs.js";
import * as AnalyticsOverviewPage$Pos from "./AnalyticsOverviewPage.bs.js";
import * as AnalyticsTopPerformingProductsPage$Pos from "./AnalyticsTopPerformingProductsPage.bs.js";

var baseRoute = "/analytics";

var overviewRoute = baseRoute + "/overview";

var shopsRoute = baseRoute + "/shops";

var topPerformingProductsRoute = baseRoute + "/top-performing-products";

var cashFlowRoute = baseRoute + "/cash-flow";

function AnalyticsRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (subUrlPath) {
    switch (subUrlPath.hd) {
      case "cash-flow" :
          if (!subUrlPath.tl) {
            return React.createElement(AnalyticsCashFlowPage$Pos.make, {});
          }
          break;
      case "overview" :
          if (!subUrlPath.tl) {
            return React.createElement(AnalyticsOverviewPage$Pos.make, {});
          }
          break;
      case "shops" :
          if (!subUrlPath.tl) {
            return React.createElement(AnalyticsShopsPage$Pos.make, {});
          }
          break;
      case "top-performing-products" :
          if (!subUrlPath.tl) {
            return React.createElement(AnalyticsTopPerformingProductsPage$Pos.make, {});
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation$Pos.Redirect.make, {
              route: overviewRoute
            });
}

var make = AnalyticsRouter;

export {
  baseRoute ,
  overviewRoute ,
  shopsRoute ,
  topPerformingProductsRoute ,
  cashFlowRoute ,
  make ,
}
/* react Not a pure module */
