// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as SupplierNewPage$Pos from "./SupplierNewPage.bs.js";
import * as SupplierEditPage$Pos from "./SupplierEditPage.bs.js";
import * as SupplierListPage$Pos from "./SupplierListPage.bs.js";
import * as SupplierShowPage$Pos from "./SupplierShowPage.bs.js";
import * as SupplierOrderListPage$Pos from "./SupplierOrderListPage.bs.js";
import * as SupplierEditContactPage$Pos from "./SupplierEditContactPage.bs.js";
import * as SupplierEditLocationPage$Pos from "./SupplierEditLocationPage.bs.js";

var baseRoute = "/suppliers";

function showRoute(id) {
  return baseRoute + "/" + id;
}

var newRoute = baseRoute + "/new";

function editRoute(id) {
  return showRoute(id) + "/edit";
}

function editLocationRoute(id) {
  return showRoute(id) + "/edit/location";
}

function editContactRoute(id) {
  return showRoute(id) + "/edit/contact";
}

function SupplierRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (!subUrlPath) {
    return React.createElement(SupplierListPage$Pos.make, {
                supplierBaseRoute: baseRoute,
                supplierNewRoute: newRoute,
                supplierShowRoute: showRoute
              });
  }
  var id = subUrlPath.hd;
  var exit = 0;
  if (id === "new") {
    if (!subUrlPath.tl) {
      return React.createElement(SupplierNewPage$Pos.make, {
                  supplierShowRoute: showRoute
                });
    }
    exit = 2;
  } else {
    exit = 2;
  }
  if (exit === 2) {
    var match = subUrlPath.tl;
    if (!match) {
      return React.createElement(SupplierShowPage$Pos.make, {
                  id: id,
                  supplierEditRoute: editRoute,
                  supplierEditLocationRoute: editLocationRoute,
                  supplierEditContactRoute: editContactRoute,
                  supplierBaseRoute: baseRoute
                });
    }
    switch (match.hd) {
      case "edit" :
          var match$1 = match.tl;
          if (!match$1) {
            return React.createElement(SupplierEditPage$Pos.make, {
                        id: id,
                        supplierShowRoute: showRoute
                      });
          }
          switch (match$1.hd) {
            case "contact" :
                if (!match$1.tl) {
                  return React.createElement(SupplierEditContactPage$Pos.make, {
                              id: id,
                              supplierShowRoute: showRoute
                            });
                }
                break;
            case "location" :
                if (!match$1.tl) {
                  return React.createElement(SupplierEditLocationPage$Pos.make, {
                              id: id,
                              supplierShowRoute: showRoute
                            });
                }
                break;
            default:
              
          }
          break;
      case "orders" :
          if (!match.tl) {
            return React.createElement(SupplierOrderListPage$Pos.make, {
                        id: id
                      });
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation$Pos.Redirect.make, {
              route: baseRoute
            });
}

var make = SupplierRouter;

export {
  baseRoute ,
  showRoute ,
  newRoute ,
  editRoute ,
  editLocationRoute ,
  editContactRoute ,
  make ,
}
/* react Not a pure module */
