// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Icon$Pos from "../../resources/images-and-icons/Icon.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Menu$Pos from "../../resources/navigation/Menu.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Tooltip$Pos from "../../resources/overlays/Tooltip.bs.js";
import * as MenuItem$Pos from "../../resources/navigation/MenuItem.bs.js";
import * as ButtonLink$Pos from "../../resources/actions/ButtonLink.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as CatalogLabel$Pos from "./CatalogLabel.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as CatalogVariantMutations$Pos from "./CatalogVariantMutations.bs.js";
import * as CatalogVariantArchiveModal$Pos from "./CatalogVariantArchiveModal.bs.js";
import * as CatalogLabelQuickPrintButton$Pos from "./CatalogLabelQuickPrintButton.bs.js";

function CatalogVariantPageActions(Props) {
  var cku = Props.cku;
  var variant = Props.variant;
  var fromLabelEditSettingsRedirected = Props.fromLabelEditSettingsRedirected;
  var onRequestNotification = Props.onRequestNotification;
  var match = Navigation$Pos.useUrl(undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setArchiveModalOpened = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setPrinterStatusResult = match$2[1];
  var printerStatusResult = match$2[0];
  var unarchiveVariant = CatalogVariantMutations$Pos.useUnarchive(variant.id);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var scope = Auth$Pos.useScope(undefined);
  React.useEffect((function () {
          if (activeShop === undefined) {
            return ;
          }
          var future = Curry._1(CatalogLabel$Pos.Print.DefaultPrinterRequest.make, activeShop.id);
          Future.map(future, undefined, (function (result) {
                  Curry._1(setPrinterStatusResult, (function (param) {
                          return result;
                        }));
                }));
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [activeShop]);
  var tmp;
  var exit = 0;
  if (printerStatusResult !== undefined) {
    var exit$1 = 0;
    var status;
    if (printerStatusResult.TAG === /* Ok */0 || !printerStatusResult._0) {
      status = printerStatusResult;
      exit$1 = 2;
    } else {
      tmp = null;
    }
    if (exit$1 === 2) {
      if (activeShop !== undefined) {
        tmp = React.createElement(CatalogLabelQuickPrintButton$Pos.make, {
              featureLocked: Belt_Result.isError(status),
              fromEditRedirection: fromLabelEditSettingsRedirected,
              variantId: variant.id,
              shopId: activeShop.id,
              requestBarcodeCompletion: CatalogLabel$Pos.BarcodeCompletionRequest.make,
              requestLabelsPrinting: CatalogLabel$Pos.Print.LabelsRequest.make,
              onRequestNotification: onRequestNotification
            });
      } else {
        exit = 1;
      }
    }
    
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Tooltip$Pos.make, {
          children: React.createElement(Button$Pos.make, Button$Pos.makeProps(React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                    children: null,
                                    space: "xnormal"
                                  }, React.createElement(Icon$Pos.make, {
                                        name: "printer",
                                        fill: Colors$Pos.neutralColor35,
                                        size: 18
                                      }), React.createElement(Text$Pos.make, Text$Pos.makeProps(Intl$Pos.t("Print label"), undefined, undefined, undefined, undefined)))), undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, "neutral", undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
          content: {
            TAG: /* Text */0,
            _0: Intl$Pos.t("Please select a shop beforehand with the filter.")
          },
          disabled: Belt_Option.isSome(activeShop),
          delay: 0
        });
  }
  var match$3 = variant.status;
  var tmp$1;
  var exit$2 = 0;
  if (match$3 === 2) {
    var exit$3 = 0;
    if (scope.TAG === /* Organisation */0 && scope.activeShop === undefined) {
      exit$2 = 1;
    } else {
      exit$3 = 2;
    }
    if (exit$3 === 2) {
      tmp$1 = React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Unarchive"), undefined, "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                  Curry._1(unarchiveVariant, undefined);
                }), undefined, undefined, undefined));
    }
    
  } else {
    exit$2 = 1;
  }
  if (exit$2 === 1) {
    var tmp$2;
    var exit$4 = 0;
    if (scope.TAG === /* Organisation */0 && scope.activeShop === undefined) {
      tmp$2 = null;
    } else {
      exit$4 = 2;
    }
    if (exit$4 === 2) {
      tmp$2 = React.createElement(MenuItem$Pos.make, {
            content: {
              TAG: /* Text */0,
              _0: Intl$Pos.t("Archive")
            },
            action: {
              TAG: /* Callback */0,
              _0: (function (param) {
                  Curry._1(setArchiveModalOpened, (function (param) {
                          return true;
                        }));
                })
            }
          });
    }
    var tmp$3;
    tmp$3 = scope.TAG === /* Organisation */0 ? React.createElement(MenuItem$Pos.make, {
            content: {
              TAG: /* Text */0,
              _0: Intl$Pos.t("Duplicate")
            },
            action: {
              TAG: /* OpenLink */1,
              _0: {
                TAG: /* Route */0,
                _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/duplication/" + cku
              }
            }
          }) : null;
    tmp$1 = React.createElement(Menu$Pos.make, {
          children: null
        }, React.createElement(MenuItem$Pos.make, {
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Edit")
              },
              action: {
                TAG: /* OpenLink */1,
                _0: {
                  TAG: /* Route */0,
                  _0: match.pathname + "/edit"
                }
              }
            }), tmp$2, tmp$3);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Inline$Pos.make, {
                  children: null,
                  space: "small"
                }, tmp, React.createElement(ButtonLink$Pos.make, {
                      children: Intl$Pos.t("See bound product"),
                      variation: "neutral",
                      to: {
                        TAG: /* Route */0,
                        _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/product/redirect/" + cku
                      }
                    }), tmp$1), React.createElement(CatalogVariantArchiveModal$Pos.make, {
                  id: variant.id,
                  opened: match$1[0],
                  onRequestClose: (function (param) {
                      Curry._1(setArchiveModalOpened, (function (param) {
                              return false;
                            }));
                    })
                }));
}

var make = React.memo(CatalogVariantPageActions);

var Config;

export {
  Config ,
  make ,
}
/* make Not a pure module */
