// Generated by ReScript, PLEASE EDIT WITH CARE


var neutralColor25 = "#bdbdca";

var Product = {
  whiteBadgeColor: "#fff4cc",
  whitePastilleColor: "#EED87A",
  whiteTextColor: "#907b1e",
  blondBadgeColor: "#f8e7cc",
  blondTextColor: "#dd8700",
  amberBadgeColor: "#ead5d2",
  amberTextColor: "#982d1f",
  darkBadgeColor: "#ffcfce",
  darkTextColor: "#5f0f0c",
  blackBadgeColor: "#ead5d2",
  blackTextColor: "#300c0a",
  redBadgeColor: "#f5E8f0",
  redTextColor: "#a01e72",
  roseBadgeColor: "#fce5e8",
  roseTextColor: "#e87582"
};

var transparent = "transparent";

var neutralAlpha100 = "#ffffffFF";

var brandColor00 = "#fdf2fb";

var brandColor05 = "#f5e8f0";

var brandColor10 = "#f4e4ee";

var brandColor30 = "#dcadcb";

var brandColor40 = "#c04695";

var brandColor50 = "#a01e72";

var brandColor55 = "#960F6F";

var brandColor60 = "#8a0262";

var secondaryColor05 = "#d4e1ff";

var secondaryColor10 = "#c8d6f9";

var secondaryColor40 = "#90a3e4";

var secondaryColor50 = "#647ccc";

var secondaryColor60 = "#4962b6";

var secondaryColor65 = "#455fb2";

var secondaryColor70 = "#3B54A5";

var neutralColor00 = "#fefefe";

var neutralColor05 = "#f8f8fb";

var neutralColor10 = "#f3f3f7";

var neutralColor15 = "#e7e7ee";

var neutralColor20 = "#d7d7e0";

var neutralColor30 = "#a2a1b0";

var neutralColor35 = "#8c8B9b";

var neutralColor45 = "#797785";

var neutralColor50 = "#797885";

var neutralColor70 = "#535165";

var neutralColor80 = "#35334b";

var neutralColor90 = "#25243a";

var neutralColor100 = "#07061e";

var successColor10 = "#bef1dc";

var successColor20 = "#8fe7c3";

var successColor50 = "#1dbb7a";

var successColor60 = "#0da365";

var successColor70 = "#125135";

var successColor80 = "#0b5135";

var warningColor10 = "#fff7e1";

var warningColor20 = "#fff4cc";

var warningColor25 = "#fff2ba";

var warningColor30 = "#faebaf";

var warningColor50 = "#ffec97";

var warningColor70 = "#ffdb3c";

var warningColor90 = "#867026";

var dangerColor05 = "#ffedef";

var dangerColor10 = "#ffe0e9";

var dangerColor30 = "#e186a2";

var dangerColor50 = "#e61e5a";

var dangerColor55 = "#c81c51";

var dangerColor60 = "#ce0340";

var dangerColor70 = "#b01745";

var extraColorA50 = "#35334b";

var extraColorB50 = "#681e52";

var extraColorC50 = "#345e5d";

var extraColorG50 = "#ec5757";

var extraColorH50 = "#647ccc";

var extraColorI50 = "#dea439";

var extraColorK10 = "#ffdbe0";

var extraColorK30 = "#f9acb4";

var extraColorK50 = "#41c2aa";

var extraColorK60 = "#eb9ea7";

var extraColorK70 = "#ffe1Ca";

var extraColorK80 = "#ab4319";

var titleDefaultColortemplate = "#1a191b";

var theadTextDefaultColortemplate = "#122a4f";

var backgroundDefaultColortemplate = "#f8f8fb";

var borderDefaultColortemplate = "#19355f";

var linkColor = "#0b5135";

var placeholderTextColor = neutralColor25;

export {
  transparent ,
  neutralAlpha100 ,
  brandColor00 ,
  brandColor05 ,
  brandColor10 ,
  brandColor30 ,
  brandColor40 ,
  brandColor50 ,
  brandColor55 ,
  brandColor60 ,
  secondaryColor05 ,
  secondaryColor10 ,
  secondaryColor40 ,
  secondaryColor50 ,
  secondaryColor60 ,
  secondaryColor65 ,
  secondaryColor70 ,
  neutralColor00 ,
  neutralColor05 ,
  neutralColor10 ,
  neutralColor15 ,
  neutralColor20 ,
  neutralColor25 ,
  neutralColor30 ,
  neutralColor35 ,
  neutralColor45 ,
  neutralColor50 ,
  neutralColor70 ,
  neutralColor80 ,
  neutralColor90 ,
  neutralColor100 ,
  successColor10 ,
  successColor20 ,
  successColor50 ,
  successColor60 ,
  successColor70 ,
  successColor80 ,
  warningColor10 ,
  warningColor20 ,
  warningColor25 ,
  warningColor30 ,
  warningColor50 ,
  warningColor70 ,
  warningColor90 ,
  dangerColor05 ,
  dangerColor10 ,
  dangerColor30 ,
  dangerColor50 ,
  dangerColor55 ,
  dangerColor60 ,
  dangerColor70 ,
  extraColorA50 ,
  extraColorB50 ,
  extraColorC50 ,
  extraColorG50 ,
  extraColorH50 ,
  extraColorI50 ,
  extraColorK10 ,
  extraColorK30 ,
  extraColorK50 ,
  extraColorK60 ,
  extraColorK70 ,
  extraColorK80 ,
  Product ,
  titleDefaultColortemplate ,
  theadTextDefaultColortemplate ,
  backgroundDefaultColortemplate ,
  borderDefaultColortemplate ,
  linkColor ,
  placeholderTextColor ,
}
/* No side effect */
