// Generated by ReScript, PLEASE EDIT WITH CARE


function $plus$plus$plus(s1, s2) {
  if (s1 !== undefined) {
    if (s2 !== undefined) {
      return s1 + (" " + s2);
    } else {
      return s1;
    }
  } else if (s2 !== undefined) {
    return s2;
  } else {
    return "";
  }
}

var Utils = {
  $plus$plus$plus: $plus$plus$plus
};

function makeContactName(civility, lastName, firstName) {
  if (firstName !== undefined) {
    return $plus$plus$plus(firstName, lastName);
  } else {
    return $plus$plus$plus(civility, lastName);
  }
}

function makeCity(postalCode, cityName) {
  if (postalCode !== undefined || cityName !== undefined) {
    return $plus$plus$plus(postalCode, cityName);
  }
  
}

export {
  Utils ,
  makeContactName ,
  makeCity ,
}
/* No side effect */
