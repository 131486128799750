// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query variants($filterBy: InputVariantsQueryFilter)  {\nvariants(first: 1, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedName  \nformattedDescription  \nstockKeepingUnit  \npurchasedPrice  \npackaging  \nstock  {\n__typename  \nrawQuantity  \n}\n\nbulk  \ncapacityUnit  \ncapacityPrecision  \nshop  {\n__typename  \nid  \n}\n\nproduct  {\n__typename  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variants;
  var value$2 = value$1.edges;
  return {
          variants: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.stockKeepingUnit;
                    var value$3 = value$1.purchasedPrice;
                    var value$4 = value$1.packaging;
                    var value$5 = value$1.stock;
                    var value$6 = value$5.rawQuantity;
                    var value$7 = value$1.bulk;
                    var value$8 = value$1.capacityUnit;
                    var value$9 = value$1.capacityPrecision;
                    var value$10 = value$1.shop;
                    var value$11 = value$1.product;
                    var value$12 = value$11.tax;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              formattedName: value$1.formattedName,
                              formattedDescription: value$1.formattedDescription,
                              stockKeepingUnit: !(value$2 == null) ? value$2 : undefined,
                              purchasedPrice: !(value$3 == null) ? value$3 : undefined,
                              packaging: !(value$4 == null) ? value$4 : undefined,
                              stock: {
                                __typename: value$5.__typename,
                                rawQuantity: !(value$6 == null) ? value$6 : undefined
                              },
                              bulk: !(value$7 == null) ? value$7 : undefined,
                              capacityUnit: !(value$8 == null) ? value$8 : undefined,
                              capacityPrecision: !(value$9 == null) ? value$9 : undefined,
                              shop: {
                                __typename: value$10.__typename,
                                id: value$10.id
                              },
                              product: {
                                __typename: value$11.__typename,
                                tax: {
                                  __typename: value$12.__typename,
                                  value: value$12.value
                                }
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.variants;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.product;
          var value$3 = value$2.tax;
          var value$4 = value$3.value;
          var value$5 = value$3.__typename;
          var tax = {
            __typename: value$5,
            value: value$4
          };
          var value$6 = value$2.__typename;
          var product = {
            __typename: value$6,
            tax: tax
          };
          var value$7 = value$1.shop;
          var value$8 = value$7.id;
          var value$9 = value$7.__typename;
          var shop = {
            __typename: value$9,
            id: value$8
          };
          var value$10 = value$1.capacityPrecision;
          var capacityPrecision = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.capacityUnit;
          var capacityUnit = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.bulk;
          var bulk = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.stock;
          var value$14 = value$13.rawQuantity;
          var rawQuantity = value$14 !== undefined ? value$14 : null;
          var value$15 = value$13.__typename;
          var stock = {
            __typename: value$15,
            rawQuantity: rawQuantity
          };
          var value$16 = value$1.packaging;
          var packaging = value$16 !== undefined ? value$16 : null;
          var value$17 = value$1.purchasedPrice;
          var purchasedPrice = value$17 !== undefined ? value$17 : null;
          var value$18 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$18 !== undefined ? value$18 : null;
          var value$19 = value$1.formattedDescription;
          var value$20 = value$1.formattedName;
          var value$21 = value$1.id;
          var value$22 = value$1.__typename;
          var node = {
            __typename: value$22,
            id: value$21,
            formattedName: value$20,
            formattedDescription: value$19,
            stockKeepingUnit: stockKeepingUnit,
            purchasedPrice: purchasedPrice,
            packaging: packaging,
            stock: stock,
            bulk: bulk,
            capacityUnit: capacityUnit,
            capacityPrecision: capacityPrecision,
            shop: shop,
            product: product
          };
          var value$23 = value.__typename;
          return {
                  __typename: value$23,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var variants = {
    __typename: value$3,
    edges: edges
  };
  return {
          variants: variants
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.categoryId;
  var a$6 = inp.createdAt;
  var a$7 = inp.updatedAt;
  var a$8 = inp.supplierId;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          categoryId: a$5 !== undefined ? ({
                _equals: a$5._equals
              }) : undefined,
          createdAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          updatedAt: a$7 !== undefined ? serializeInputObjectDateFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? ({
                _equals: a$8._equals
              }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputVariantsQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, categoryId, createdAt, updatedAt, supplierId, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          categoryId: categoryId,
          createdAt: createdAt,
          updatedAt: updatedAt,
          supplierId: supplierId
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function fetchVariantFromSKU(client, shopId, stockKeepingUnit) {
  return Future.map(FuturePromise.fromPromise(Curry._6(client.rescript_query, {
                      query: query,
                      Raw: Raw,
                      parse: parse,
                      serialize: serialize,
                      serializeVariables: serializeVariables
                    }, undefined, undefined, undefined, undefined, {
                      filterBy: makeInputObjectInputVariantsQueryFilter({
                            _in: [shopId]
                          }, {
                            _equals: stockKeepingUnit
                          }, undefined, undefined, "EXCLUDED", undefined, undefined, undefined, undefined, undefined)
                    })), undefined, (function (response) {
                if (response.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: Request$Pos.serverErrorMessage
                        };
                }
                var match = response._0;
                if (match.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: Request$Pos.serverErrorMessage
                        };
                }
                var match$1 = match._0;
                if (match$1.error !== undefined) {
                  return {
                          TAG: /* Error */1,
                          _0: Request$Pos.serverErrorMessage
                        };
                } else {
                  return {
                          TAG: /* Ok */0,
                          _0: Belt_Option.map(Belt_Array.get(match$1.data.variants.edges, 0), (function (edge) {
                                  return edge.node;
                                }))
                        };
                }
              }));
}

var Fetch = {
  fetchVariantFromSKU: fetchVariantFromSKU
};

export {
  Query ,
  Fetch ,
}
/* query Not a pure module */
