// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as CatalogCategoryPicker$Pos from "./CatalogCategoryPicker.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation UpdateProductCategory($id: ID!, $input: InputUpdateProductCategory!)  {\nupdateProductCategory(id: $id, input: $input)  {\n__typename  \nid  \nvariants  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \n}\n\n}\n\n}\n\ncategory  {\n__typename  \nid  \nformattedName  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.updateProductCategory;
  var value$2 = value$1.variants;
  var value$3 = value$2.edges;
  var value$4 = value$1.category;
  return {
          updateProductCategory: {
            __typename: value$1.__typename,
            id: value$1.id,
            variants: {
              __typename: value$2.__typename,
              edges: Js_array.map((function (value) {
                      var value$1 = value.node;
                      return {
                              __typename: value.__typename,
                              node: {
                                __typename: value$1.__typename,
                                id: value$1.id
                              }
                            };
                    }), value$3)
            },
            category: !(value$4 == null) ? ({
                  __typename: value$4.__typename,
                  id: value$4.id,
                  formattedName: value$4.formattedName
                }) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.updateProductCategory;
  var value$2 = value$1.category;
  var category;
  if (value$2 !== undefined) {
    var value$3 = value$2.formattedName;
    var value$4 = value$2.id;
    var value$5 = value$2.__typename;
    category = {
      __typename: value$5,
      id: value$4,
      formattedName: value$3
    };
  } else {
    category = null;
  }
  var value$6 = value$1.variants;
  var value$7 = value$6.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.id;
          var value$3 = value$1.__typename;
          var node = {
            __typename: value$3,
            id: value$2
          };
          var value$4 = value.__typename;
          return {
                  __typename: value$4,
                  node: node
                };
        }), value$7);
  var value$8 = value$6.__typename;
  var variants = {
    __typename: value$8,
    edges: edges
  };
  var value$9 = value$1.id;
  var value$10 = value$1.__typename;
  var updateProductCategory = {
    __typename: value$10,
    id: value$9,
    variants: variants,
    category: category
  };
  return {
          updateProductCategory: updateProductCategory
        };
}

function serializeInputObjectInputUpdateProductCategory(inp) {
  var a = inp.categoryId;
  return {
          categoryId: a !== undefined ? a : undefined
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateProductCategory(inp.input)
        };
}

function makeVariables(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateProductCategory(categoryId, param) {
  return {
          categoryId: categoryId
        };
}

var UpdateProductCategoryMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateProductCategory: serializeInputObjectInputUpdateProductCategory,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateProductCategory: makeInputObjectInputUpdateProductCategory
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var UpdateProductCategoryMutation_useWithVariables = include.useWithVariables;

var UpdateProductCategoryMutation = {
  UpdateProductCategoryMutation_inner: UpdateProductCategoryMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateProductCategory: serializeInputObjectInputUpdateProductCategory,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateProductCategory: makeInputObjectInputUpdateProductCategory,
  use: use,
  useWithVariables: UpdateProductCategoryMutation_useWithVariables
};

function CatalogCategoryEditCell(Props) {
  var variationOpt = Props.variation;
  var value = Props.value;
  var productId = Props.productId;
  var shopId = Props.shopId;
  var variation = variationOpt !== undefined ? variationOpt : "normal";
  var mutate = Curry.app(use, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ])[0];
  var match = React.useState(function () {
        
      });
  var setOptimisticValue = match[1];
  var optimisticValue = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setErrored = match$1[1];
  var onMutateCategoryRequested = React.useCallback((function (category) {
          Future.get(FuturePromise.fromPromise(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                        id: productId,
                        input: {
                          categoryId: category ? category._0.id : null
                        }
                      })), (function (response) {
                  if (response.TAG === /* Ok */0 && response._0.TAG === /* Ok */0) {
                    return Curry._1(setErrored, (function (param) {
                                  return false;
                                }));
                  }
                  Curry._1(setErrored, (function (param) {
                          return true;
                        }));
                  Curry._1(setOptimisticValue, (function (param) {
                          
                        }));
                }));
        }), []);
  var onChange = function (category) {
    Curry._1(setOptimisticValue, (function (param) {
            if (category) {
              return category._0.formattedName;
            } else {
              return Intl$Pos.t("Not classified");
            }
          }));
    Curry._1(onMutateCategoryRequested, category);
  };
  var value$1 = optimisticValue !== undefined ? optimisticValue : value;
  var tmp = {
    variation: variation,
    value: value$1,
    errored: match$1[0],
    onChange: onChange
  };
  if (shopId !== undefined) {
    tmp.shopId = shopId;
  }
  return React.createElement(CatalogCategoryPicker$Pos.make, tmp);
}

var make = React.memo(CatalogCategoryEditCell);

export {
  UpdateProductCategoryMutation ,
  make ,
}
/* query Not a pure module */
