// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function use(query) {
  var match = React.useState(function () {
        return window.matchMedia(query).matches;
      });
  var setMatched = match[1];
  React.useEffect((function () {
          var mediaQueryList = window.matchMedia(query);
          Curry._1(setMatched, (function (param) {
                  return mediaQueryList.matches;
                }));
          var handler = function (param) {
            Curry._1(setMatched, (function (param) {
                    return mediaQueryList.matches;
                  }));
          };
          mediaQueryList.addListener(handler);
          return (function (param) {
                    mediaQueryList.removeListener(handler);
                  });
        }), [query]);
  return match[0];
}

export {
  use ,
}
/* react Not a pure module */
