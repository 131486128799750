// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function mockUser(param) {
  return {
          id: "mock-user-id",
          name: "mock-name-id",
          organizationName: "mock-organization-name",
          profilePictureUri: undefined,
          username: "mock-username",
          canUseImpersonation: false,
          impersonating: false
        };
}

function mockShop(idOpt, nameOpt, corporateNameOpt, emailOpt, kindOpt, param) {
  var id = idOpt !== undefined ? idOpt : "mock-shop-id";
  var name = nameOpt !== undefined ? nameOpt : "mock-shop-name";
  var corporateName = corporateNameOpt !== undefined ? corporateNameOpt : "mock-shop-corporateName";
  var email = emailOpt !== undefined ? emailOpt : "mock-shop-email";
  var kind = kindOpt !== undefined ? kindOpt : "INTEGRATED";
  return {
          id: id,
          kind: kind,
          name: name,
          corporateName: corporateName,
          address: "mock-shop-address",
          postalCode: "mock-shop-postal-code",
          city: "mock-shop-city",
          country: "mock-shop-country",
          phoneNumber: "0629831744",
          email: email,
          legalRepresentative: "mock-shop-legal-representative",
          logoUri: undefined,
          activeWebDeviceId: "mock-shop-active-web-device-id",
          bankName: "mock-shop-bankName",
          cityOfRegistryOffice: "mock-shop-cityOfRegistryOffice",
          website: "mock-shop-website",
          fiscalYearEndClosingMonth: "mock-shop-fiscalYearEndClosingMonth",
          legalForm: "mock-shop-legalForm",
          amountOfShareCapital: "mock-shop-amountOfShareCapital",
          tvaNumber: "mock-shop-tvaNumber",
          siretNumber: "mock-shop-siretNumber",
          rcsNumber: "mock-shop-rcsNumber",
          apeNafCode: "mock-shop-apeNafCode",
          bankCode: "mock-shop-bankCode",
          bankAccountHolder: "mock-shop-bankAccountHolder",
          bankAccountNumber: "mock-shop-bankAccountNumber",
          bicCode: "mock-shop-bicCode",
          ibanNumber: "mock-shop-ibanNumber"
        };
}

function mockAuthState(activeShopOpt, shopsOpt, param) {
  var activeShop = activeShopOpt !== undefined ? Caml_option.valFromOption(activeShopOpt) : mockShop(undefined, undefined, undefined, undefined, undefined, undefined);
  var shops = shopsOpt !== undefined ? shopsOpt : [mockShop(undefined, undefined, undefined, undefined, undefined, undefined)];
  return {
          user: {
            id: "mock-user-id",
            name: "mock-name-id",
            organizationName: "mock-organization-name",
            profilePictureUri: undefined,
            username: "mock-username",
            canUseImpersonation: false,
            impersonating: false
          },
          shops: shops,
          activeShop: activeShop
        };
}

function mockAuthOrganisationScope(activeShopOpt, shopsOpt, param) {
  var activeShop = activeShopOpt !== undefined ? Caml_option.valFromOption(activeShopOpt) : undefined;
  var shops = shopsOpt !== undefined ? shopsOpt : [
      mockShop(undefined, undefined, undefined, undefined, undefined, undefined),
      mockShop(undefined, undefined, undefined, undefined, undefined, undefined)
    ];
  return {
          TAG: /* Organisation */0,
          activeShop: activeShop,
          shops: shops
        };
}

function mockAuthSingleScope(shopOpt, param) {
  var shop = shopOpt !== undefined ? shopOpt : mockShop(undefined, undefined, undefined, undefined, undefined, undefined);
  return {
          TAG: /* Single */1,
          _0: shop
        };
}

export {
  mockUser ,
  mockShop ,
  mockAuthState ,
  mockAuthOrganisationScope ,
  mockAuthSingleScope ,
}
/* No side effect */
