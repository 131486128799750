// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InputNumberField$Pos from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as ProductPriceTableCell$Pos from "../Product/ProductPriceTableCell.bs.js";

function CartProductUnitPriceTableCell(Props) {
  var product = Props.product;
  var decimalPrecision = Props.decimalPrecision;
  var editableOpt = Props.editable;
  var dispatch = Props.onRequestDispatch;
  var editable = editableOpt !== undefined ? editableOpt : false;
  var match;
  if (product.TAG === /* Unit */0) {
    var match$1 = product._0;
    match = [
      match$1.id,
      match$1.unitPrice,
      match$1.formattedUnitPrice
    ];
  } else {
    var match$2 = product._0;
    match = [
      match$2.id,
      match$2.unitPrice,
      match$2.formattedUnitPrice
    ];
  }
  var productId = match[0];
  var inputAppender;
  if (product.TAG === /* Unit */0) {
    inputAppender = {
      TAG: /* Currency */0,
      _0: "EUR"
    };
  } else {
    var unit = product._0.capacityUnit;
    inputAppender = unit !== undefined ? ({
          TAG: /* Custom */1,
          _0: Intl$Pos.toCurrencySymbol("EUR") + ("/" + unit + "")
        }) : ({
          TAG: /* Currency */0,
          _0: "EUR"
        });
  }
  if (editable) {
    return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(InputNumberField$Pos.make, {
                            value: match[1],
                            minValue: 0,
                            precision: decimalPrecision,
                            appender: inputAppender,
                            shrinkInput: true,
                            hideStepper: true,
                            onChange: (function (newPrice) {
                                Curry._1(dispatch, {
                                      TAG: /* ProductUnitPriceUpdated */6,
                                      _0: productId,
                                      _1: newPrice
                                    });
                              })
                          })), undefined, undefined, undefined, undefined));
  } else {
    return React.createElement(ProductPriceTableCell$Pos.make, {
                value: match[2]
              });
  }
}

var make = React.memo(CartProductUnitPriceTableCell, (function (oldProps, newProps) {
        if (oldProps.product === newProps.product && oldProps.editable === newProps.editable) {
          return oldProps.decimalPrecision === newProps.decimalPrecision;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
