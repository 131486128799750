// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Svg$Pos from "../../primitives/Svg.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Media$Pos from "../../primitives/Media.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Checkbox$Pos from "../selection-and-input/Checkbox.bs.js";
import * as ReactAria$Pos from "../../externals/ReactAria.bs.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as ReactStately from "react-stately";
import * as Breakpoints$Pos from "../theme/Breakpoints.bs.js";
import * as ReactStately$Pos from "../../externals/ReactStately.bs.js";
import * as Utils from "@react-aria/utils";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as ReactDomElement$Pos from "../../primitives/ReactDomElement.bs.js";

function fromStaticToReactStatelyTableColumnSize(value) {
  return Curry._1(ReactStately$Pos.Table.Column.Size.make, value.NAME === "pct" ? ({
                  TAG: /* Static */0,
                  _0: {
                    NAME: "pct",
                    VAL: value.VAL
                  }
                }) : ({
                  TAG: /* Static */0,
                  _0: {
                    NAME: "px",
                    VAL: value.VAL
                  }
                }));
}

function toReactStatelyTableColumnSize(value) {
  var variant = value.NAME;
  return Curry._1(ReactStately$Pos.Table.Column.Size.make, variant === "px" ? ({
                  TAG: /* Static */0,
                  _0: {
                    NAME: "px",
                    VAL: value.VAL
                  }
                }) : (
                variant === "pct" ? ({
                      TAG: /* Static */0,
                      _0: {
                        NAME: "pct",
                        VAL: value.VAL
                      }
                    }) : ({
                      TAG: /* Dynamic */1,
                      _0: {
                        NAME: "fr",
                        VAL: value.VAL
                      }
                    })
              ));
}

var selectCellWidth = Curry._1(ReactStately$Pos.Table.Column.Size.make, {
      TAG: /* Static */0,
      _0: {
        NAME: "px",
        VAL: 17
      }
    });

var defaultCellWidth = Curry._1(ReactStately$Pos.Table.Column.Size.make, {
      TAG: /* Static */0,
      _0: {
        NAME: "px",
        VAL: 90
      }
    });

function getBoundingRectFromNodeRef(ref) {
  return Belt_Option.map(ReactDomElement$Pos.fromRef(ref), (function (prim) {
                return prim.getBoundingClientRect();
              }));
}

function getScrollableXYFromNodeRef(ref) {
  return Belt_Option.map(ReactDomElement$Pos.fromRef(ref), (function (domElement) {
                return [
                        ((domElement.scrollLeft | 0) + domElement.clientWidth | 0) < (domElement.scrollWidth - 17 | 0),
                        ((domElement.scrollTop | 0) + domElement.clientHeight | 0) < (domElement.scrollHeight - 17 | 0)
                      ];
              }));
}

function getScrollBarWidthFromNodeRef(ref) {
  return Belt_Option.map(ReactDomElement$Pos.fromRef(ref), (function (domElement) {
                return (domElement.offsetWidth | 0) - domElement.clientWidth | 0;
              }));
}

function computeColumnStickyPositionState(columnIndex, layoutState, scrolled) {
  var columnSticky = Belt_Option.mapWithDefault(Belt_Array.getBy(layoutState, (function (col) {
              return col.index === columnIndex;
            })), false, (function (col) {
          return col.sticky;
        }));
  var stickyColumns = columnSticky ? Belt_Array.keepWithIndex(layoutState, (function (col, idx) {
            if (col.sticky) {
              return idx <= columnIndex;
            } else {
              return false;
            }
          })) : [];
  var match = Belt_Array.reduce(stickyColumns, [
        0,
        0,
        false
      ], (function (acc, curr) {
          var cumulatedStickyWidth = acc[0];
          var left = Belt_Option.mapWithDefault(curr.relativeRect, 0, (function (prim) {
                  return prim.left;
                }));
          var width = Belt_Option.mapWithDefault(curr.relativeRect, 0, (function (prim) {
                  return prim.width;
                }));
          var nextCumulatedStickyWidth = cumulatedStickyWidth + width;
          var sticking = left - cumulatedStickyWidth <= 0 && scrolled;
          return [
                  nextCumulatedStickyWidth,
                  cumulatedStickyWidth,
                  sticking
                ];
        }));
  return [
          match[1],
          match[2]
        ];
}

function getRelativeBoundingRect(src, dest) {
  return new DOMRect(dest.left - src.left, dest.top - src.top, dest.width, dest.height);
}

function layoutState(tableRect, columns) {
  return Belt_Array.map(columns, (function (column) {
                if (column.props.isSelectionCell) {
                  return {
                          key: column.key,
                          index: column.index,
                          relativeRect: undefined,
                          sticky: false
                        };
                }
                var match = Belt_Option.flatMap(column.props.extraColumnProps.ref, getBoundingRectFromNodeRef);
                return {
                        key: column.key,
                        index: column.index,
                        relativeRect: tableRect !== undefined && match !== undefined ? Caml_option.some(getRelativeBoundingRect(Caml_option.valFromOption(tableRect), Caml_option.valFromOption(match))) : undefined,
                        sticky: Belt_Option.getWithDefault(column.props.extraColumnProps.sticky, false)
                      };
              }));
}

function use(tableRef, headerRef, bodyRef, tableState, onLoadMore) {
  var match = React.useState(function () {
        
      });
  var setTableRect = match[1];
  var tableRect = match[0];
  var match$1 = React.useState(function () {
        return [
                0,
                0
              ];
      });
  var setScrollPositions = match$1[1];
  var scrollPositions = match$1[0];
  var match$2 = React.useState(function () {
        return 0;
      });
  var setScrollBarWidth = match$2[1];
  var scrollBarWidth = match$2[0];
  React.useLayoutEffect((function () {
          var headerDomElement = ReactDomElement$Pos.fromRef(headerRef);
          var bodyDomElement = ReactDomElement$Pos.fromRef(bodyRef);
          var handler = function (param) {
            var match = Belt_Option.map(bodyDomElement, (function (bodyDomElement) {
                    return bodyDomElement.scrollLeft;
                  }));
            var match$1 = Belt_Option.map(bodyDomElement, (function (bodyDomElement) {
                    return bodyDomElement.scrollTop;
                  }));
            var x = scrollPositions[0];
            if (headerDomElement !== undefined && match !== undefined && match$1 !== undefined && (x !== match || x === 0)) {
              Caml_option.valFromOption(headerDomElement).scrollLeft = match;
              return Curry._1(setScrollPositions, (function (param) {
                            return [
                                    match,
                                    match$1
                                  ];
                          }));
            }
            
          };
          Curry._1(setTableRect, (function (param) {
                  return getBoundingRectFromNodeRef(tableRef);
                }));
          Belt_Option.forEach(bodyDomElement, (function (domNode) {
                  domNode.addEventListener("scroll", handler);
                }));
          return (function (param) {
                    Belt_Option.forEach(bodyDomElement, (function (domEmement) {
                            domEmement.removeEventListener("scroll", handler);
                          }));
                  });
        }), []);
  React.useLayoutEffect((function () {
          var domElement = ReactDomElement$Pos.fromRef(bodyRef);
          var handler = function (param) {
            if (domElement === undefined) {
              return ;
            }
            if (onLoadMore === undefined) {
              return ;
            }
            var domElement$1 = Caml_option.valFromOption(domElement);
            var scrolledY = domElement$1.scrollTop + domElement$1.offsetHeight;
            var scrolledY$1 = scrolledY | 0;
            if ((domElement$1.scrollHeight - scrolledY$1 | 0) < 100) {
              return Curry._1(onLoadMore, undefined);
            }
            
          };
          Belt_Option.forEach(domElement, (function (domElement) {
                  domElement.removeEventListener("scroll", handler);
                }));
          Belt_Option.forEach(domElement, (function (domElement) {
                  domElement.addEventListener("scroll", handler);
                }));
          return (function (param) {
                    Belt_Option.forEach(domElement, (function (domElement) {
                            domElement.removeEventListener("scroll", handler);
                          }));
                  });
        }), [onLoadMore]);
  Utils.useResizeObserver({
        ref: tableRef,
        onResize: (function (param) {
            var match = getBoundingRectFromNodeRef(tableRef);
            if (tableRect !== undefined && match !== undefined && Caml_option.valFromOption(tableRect).width !== Caml_option.valFromOption(match).width) {
              setTimeout((function (param) {
                      Curry._1(setTableRect, (function (param) {
                              return getBoundingRectFromNodeRef(tableRef);
                            }));
                    }), 0);
              return ;
            }
            
          })
      });
  Utils.useResizeObserver({
        ref: bodyRef,
        onResize: (function (param) {
            var width = getScrollBarWidthFromNodeRef(bodyRef);
            if (width !== undefined && width !== scrollBarWidth) {
              setTimeout((function (param) {
                      Curry._1(setScrollBarWidth, (function (param) {
                              return width;
                            }));
                    }), 0);
              return ;
            }
            
          })
      });
  var getDefaultWidth = React.useCallback((function (node) {
          if (node.props.isSelectionCell) {
            return Caml_option.some(selectCellWidth);
          }
          
        }), []);
  var getDefaultMinWidth = React.useCallback((function (node) {
          if (node.props.isSelectionCell) {
            return Caml_option.some(selectCellWidth);
          } else {
            return Caml_option.some(defaultCellWidth);
          }
        }), []);
  var match$3 = tableState.selectionManager.selectionMode;
  var tableWidth;
  var exit = 0;
  if (tableRect !== undefined && match$3 === "multiple") {
    tableWidth = Caml_option.valFromOption(tableRect).width - 17;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tableWidth = Belt_Option.map(Belt_Option.map(tableRect, (function (prim) {
                return prim.width;
              })), (function (x) {
            return x - scrollBarWidth;
          }));
  }
  var scrollBarX_position = scrollPositions[0];
  var scrollBarX_scrollable = Belt_Option.mapWithDefault(getScrollableXYFromNodeRef(bodyRef), false, (function (prim) {
          return prim[0];
        }));
  var scrollBarX = {
    position: scrollBarX_position,
    scrollable: scrollBarX_scrollable
  };
  var scrollBarY_position = scrollPositions[1];
  var scrollBarY_scrollable = Belt_Option.mapWithDefault(getScrollableXYFromNodeRef(bodyRef), false, (function (prim) {
          return prim[1];
        }));
  var scrollBarY_size = scrollBarWidth;
  var scrollBarY = {
    position: scrollBarY_position,
    scrollable: scrollBarY_scrollable,
    size: scrollBarY_size
  };
  var columnResizeState = ReactStately$Pos.Table.useColumnResizeState({
        tableWidth: tableWidth,
        getDefaultWidth: getDefaultWidth,
        getDefaultMinWidth: getDefaultMinWidth
      }, tableState);
  return {
          state: layoutState(tableRect, tableState.collection.columns),
          ariaState: columnResizeState,
          headerRef: headerRef,
          bodyRef: bodyRef,
          tableWidth: tableWidth,
          scrollBarX: scrollBarX,
          scrollBarY: scrollBarY
        };
}

var style = {
  display: "flex"
};

function makeScrollBarOffsetStyle(scrollBarWidth) {
  return {
          minWidth: "" + String(scrollBarWidth) + "px"
        };
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var item = Props.item;
      var state = Props.state;
      var layout = Props.layout;
      var ref = React.useRef(null);
      var match = ReactAria.useTableHeaderRow({
            node: item
          }, state, ref);
      var scrollBarWidth = Belt_Option.getWithDefault(layout.scrollBarY.size, 0);
      return React.createElement(ReactAria$Pos.Spread.make, {
                  props: match.rowProps,
                  children: React.createElement("tr", {
                        ref: ref,
                        style: style
                      }, children, React.createElement("div", {
                            style: makeScrollBarOffsetStyle(scrollBarWidth)
                          }))
                });
    });

function style$1(width) {
  return {
          backgroundColor: Colors$Pos.neutralColor00,
          display: "flex",
          paddingRight: "5px",
          paddingBottom: "10px",
          paddingLeft: "20px",
          width: "" + String(width) + "px",
          alignSelf: "flex-end"
        };
}

var make$1 = React.memo(function (Props) {
      var column = Props.column;
      var state = Props.state;
      var layout = Props.layout;
      var disabled = Props.disabled;
      var ref = React.useRef(null);
      var match = ReactAria.useTableColumnHeader({
            node: column
          }, state, ref);
      var match$1 = ReactAria.useTableSelectAllCheckbox(state);
      var checkboxProps = match$1.checkboxProps;
      var width = Curry._1(layout.ariaState.getColumnWidth, column.key);
      var match$2 = state.selectionManager.selectionMode;
      var tmp;
      var exit = 0;
      if (match$2 === "multiple" && !disabled) {
        tmp = React.createElement(Checkbox$Pos.make, {
              indeterminate: checkboxProps.isIndeterminate,
              accessibilityLabel: "select all",
              ariaProps: checkboxProps,
              value: checkboxProps.isSelected
            });
      } else {
        exit = 1;
      }
      if (exit === 1) {
        tmp = React.createElement(ReactAria.VisuallyHidden, {
              children: checkboxProps["aria-label"]
            });
      }
      return React.createElement(ReactAria$Pos.Spread.make, {
                  props: match.columnHeaderProps,
                  children: React.createElement("th", {
                        ref: ref,
                        style: style$1(width)
                      }, tmp)
                });
    });

function style$2(sortable, columnIsFirst, columnIsLast, minWidth, width, left, sticky, sticking, marginOpt, alignXOpt, param) {
  var margin = marginOpt !== undefined ? marginOpt : "xsmall";
  var alignX = alignXOpt !== undefined ? alignXOpt : "flexStart";
  return {
          backgroundColor: Colors$Pos.neutralColor00,
          color: Colors$Pos.neutralColor30,
          cursor: sortable ? "pointer" : "default",
          display: "flex",
          left: "" + String(left) + "px",
          lineHeight: "14px",
          minWidth: "" + String(minWidth) + "px",
          paddingRight: columnIsLast ? "25px" : "" + String(Spaces$Pos.toFloat(margin)) + "px",
          paddingBottom: "10px",
          paddingLeft: columnIsFirst ? "20px" : "10px",
          position: sticky ? "sticky" : "initial",
          textAlign: "start",
          width: "" + String(width) + "px",
          zIndex: sticking ? "1" : "0",
          boxShadow: "" + (
            sticking ? Colors$Pos.neutralColor00 : Colors$Pos.transparent
          ) + " 4px 0px 16px 4px",
          alignSelf: "flex-end",
          justifyContent: alignX === "flexStart" ? "start" : (
              alignX === "flexEnd" ? "end" : "center"
            ),
          boxSizing: "border-box"
        };
}

var make$2 = React.memo(function (Props) {
      var column = Props.column;
      var state = Props.state;
      var layout = Props.layout;
      var match = Hover$Pos.use(undefined, undefined);
      var hovered = match[1];
      var ref = match[0];
      var match$1 = ReactAria.useTableColumnHeader({
            node: column
          }, state, ref);
      var match$2 = ReactAria$Pos.Focus.useRing(undefined, undefined);
      column.props.extraColumnProps.ref = ref;
      var breakpoint = column.props.extraColumnProps.breakpoint;
      var breakpointMatched = Media$Pos.use(Breakpoints$Pos.toMediaQuery(breakpoint));
      var minWidth = Curry._1(layout.ariaState.getColumnMinWidth, column.key);
      var width = Curry._1(layout.ariaState.getColumnWidth, column.key);
      var columnIsFirst = column.index === 0;
      var columnIsLast = column.index === (state.collection.columnCount - 1 | 0);
      var match$3 = column.props.extraColumnProps;
      var sticky = Belt_Option.getWithDefault(column.props.extraColumnProps.sticky, false);
      var match$4 = React.useMemo((function () {
              return computeColumnStickyPositionState(column.index, layout.state, layout.scrollBarX.position > 0);
            }), [layout.scrollBarX.position]);
      var sortDirection = Belt_Option.flatMap(state.sortDescriptor, (function (descriptor) {
              if (descriptor.column === column.key) {
                return descriptor.direction;
              }
              
            }));
      var sortable = column.props.allowsSorting;
      var sortableOrSorted = Belt_Option.isSome(sortDirection) || hovered && sortable;
      if (breakpointMatched) {
        return React.createElement(ReactAria$Pos.Spread.make, {
                    props: ReactAria.mergeProps(match$1.columnHeaderProps, match$2.focusProps),
                    children: React.createElement("th", {
                          ref: ref,
                          style: style$2(sortable, columnIsFirst, columnIsLast, minWidth, width, match$4[0], sticky, match$4[1], match$3.margin, match$3.alignX, undefined),
                          colSpan: column.colspan
                        }, React.createElement(TextStyle$Pos.make, {
                              children: column.rendered,
                              variation: sortableOrSorted ? "neutral" : "normal",
                              weight: Belt_Option.isSome(sortDirection) ? "semibold" : "medium",
                              size: "tiny",
                              maxLines: 1
                            }), sortDirection !== undefined ? (
                            sortable ? React.createElement(Svg$Pos.make, {
                                    children: React.createElement(Svg$Pos.Path.make, {
                                          d: sortDirection === "ascending" ? "M 4.707 9.707 C 4.317 10.098 3.683 10.098 3.293 9.707 C 2.902 9.317 2.902 8.683 3.293 8.293 L 10 1.586 L 16.707 8.293 C 17.098 8.683 17.098 9.317 16.707 9.707 C 16.317 10.098 15.683 10.098 15.293 9.707 L 11 5.414 L 11 19 C 11 19.552 10.552 20 10 20 C 9.448 20 9 19.552 9 19 L 9 5.414 L 4.707 9.707 Z" : "M4.70711 11.2929C4.31658 10.9024 3.68342 10.9024 3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L10 19.4142L16.7071 12.7071C17.0976 12.3166 17.0976 11.6834 16.7071 11.2929C16.3166 10.9024 15.6834 10.9024 15.2929 11.2929L11 15.5858V2C11 1.44772 10.5523 1 10 1C9.44772 1 9 1.44772 9 2V15.5858L4.70711 11.2929Z",
                                          fill: Colors$Pos.neutralColor90,
                                          transform: "translate(2 1)"
                                        }),
                                    width: "14",
                                    height: "13",
                                    viewBox: "0 0 20 20"
                                  }) : null
                          ) : (
                            sortable ? React.createElement(Svg$Pos.make, {
                                    children: React.createElement(Svg$Pos.Path.make, {
                                          d: "M16.2929 5.70714C16.6834 6.09766 17.3166 6.09766 17.7071 5.70714C18.0976 5.31661 18.0976 4.68345 17.7071 4.29292L14 0.585815L10.2929 4.29292C9.90237 4.68345 9.90237 5.31661 10.2929 5.70714C10.6834 6.09766 11.3166 6.09766 11.7071 5.70714L13 4.41424V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4.41424L16.2929 5.70714ZM3.70711 14.2929C3.31658 13.9024 2.68342 13.9024 2.29289 14.2929C1.90237 14.6834 1.90237 15.3166 2.29289 15.7071L6 19.4142L9.70711 15.7071C10.0976 15.3166 10.0976 14.6834 9.70711 14.2929C9.31658 13.9024 8.68342 13.9024 8.29289 14.2929L7 15.5858L7 6.00006C7 5.44778 6.55229 5.00006 6 5.00006C5.44771 5.00006 5 5.44778 5 6.00006L5 15.5858L3.70711 14.2929Z",
                                          fill: hovered ? Colors$Pos.neutralColor90 : Colors$Pos.neutralColor35,
                                          transform: "translate(2 1)"
                                        }),
                                    width: "14",
                                    height: "13",
                                    viewBox: "0 0 20 20"
                                  }) : null
                          ))
                  });
      } else {
        return null;
      }
    });

var theadStyle = {
  borderBottom: "1px solid",
  borderBottomColor: Colors$Pos.neutralColor20,
  display: "block",
  overflow: "auto"
};

function tbodyStyle(minHeight, maxHeight) {
  var minHeight$1 = Belt_Option.mapWithDefault(minHeight, "auto", (function (height) {
          return String(height) + "px";
        }));
  var maxHeight$1 = Belt_Option.mapWithDefault(maxHeight, "auto", (function (height) {
          return String(height) + "px";
        }));
  return {
          display: "flex",
          maxHeight: maxHeight$1,
          minHeight: minHeight$1,
          overflow: "auto",
          flexDirection: "column",
          flexGrow: "1"
        };
}

var make$3 = React.memo(function (Props) {
      var children = Props.children;
      var type_ = Props.type_;
      var layout = Props.layout;
      var minHeight = Props.minHeight;
      var maxHeight = Props.maxHeight;
      var match = ReactAria.useTableRowGroup();
      var headerRef = layout.headerRef;
      var bodyRef = layout.bodyRef;
      return React.createElement(ReactAria$Pos.Spread.make, {
                  props: match.rowGroupProps,
                  children: type_ === "thead" ? React.createElement("thead", {
                          ref: headerRef,
                          style: theadStyle
                        }, children) : React.createElement("tbody", {
                          ref: bodyRef,
                          style: tbodyStyle(minHeight, maxHeight)
                        }, children)
                });
    });

var theadRowStyle = {
  display: "flex"
};

var tbodyRowErrorMarkerStyle = {
  backgroundColor: Colors$Pos.dangerColor50,
  height: "100%",
  position: "absolute",
  width: "2px",
  zIndex: "1"
};

function tbodyRowStyle(even, selectable, selected, hovered, errored, minWidth) {
  var rowColor = errored ? Colors$Pos.dangerColor05 : (
      selected ? Colors$Pos.brandColor00 : (
          hovered ? Colors$Pos.neutralColor10 : (
              even ? Colors$Pos.neutralColor05 : Colors$Pos.neutralColor00
            )
        )
    );
  return {
          backgroundColor: rowColor,
          cursor: selectable ? "default" : "auto",
          display: "flex",
          minWidth: "" + String(minWidth) + "px",
          position: "relative",
          boxShadow: "" + rowColor + " -1px 0 0 0"
        };
}

var make$4 = React.memo(function (Props) {
      var children = Props.children;
      var item = Props.item;
      var state = Props.state;
      var layout = Props.layout;
      var errored = Props.errored;
      var match = Hover$Pos.use(undefined, undefined);
      var ref = match[0];
      var match$1 = ReactAria.useTableRow({
            node: item
          }, state, ref);
      var match$2 = ReactAria$Pos.Focus.useRing(undefined, undefined);
      var selectable = state.selectionManager.selectionMode !== "none";
      var selected = state.selectionManager.isSelected(item.key);
      var disabled = state.selectionManager.isDisabled(item.key);
      var match$3 = item.type;
      var tmp;
      if (match$3 === "thead") {
        tmp = React.createElement("tr", {
              ref: ref,
              style: theadRowStyle
            }, children);
      } else {
        var even = item.index % 2 === 0;
        var hovered = match[1] && !disabled;
        var minWidth = Belt_Option.getWithDefault(layout.tableWidth, 0);
        tmp = React.createElement("tr", {
              ref: ref,
              style: tbodyRowStyle(even, selectable, selected, hovered, errored, minWidth)
            }, errored ? React.createElement("div", {
                    style: tbodyRowErrorMarkerStyle
                  }) : null, children);
      }
      return React.createElement(ReactAria$Pos.Spread.make, {
                  props: ReactAria.mergeProps(match$1.rowProps, match$2.focusProps),
                  children: tmp
                });
    });

function style$3(width) {
  return {
          backgroundColor: "inherit",
          borderBottom: "1px solid",
          borderBottomColor: Colors$Pos.neutralColor15,
          display: "flex",
          paddingTop: "1px",
          paddingRight: "5px",
          paddingLeft: "20px",
          width: "" + String(width) + "px"
        };
}

var make$5 = React.memo(function (Props) {
      var cell = Props.cell;
      var state = Props.state;
      var layout = Props.layout;
      var ref = React.useRef(null);
      var match = ReactAria.useTableCell({
            node: cell
          }, state, ref);
      var match$1 = ReactAria.useTableSelectionCheckbox({
            key: cell.parentKey
          }, state);
      var checkboxProps = match$1.checkboxProps;
      var width = Curry._1(layout.ariaState.getColumnWidth, cell.column.key);
      return React.createElement(ReactAria$Pos.Spread.make, {
                  props: match.gridCellProps,
                  children: React.createElement("td", {
                        ref: ref,
                        style: style$3(width)
                      }, React.createElement(Checkbox$Pos.make, {
                            disabled: checkboxProps.isDisabled,
                            accessibilityLabel: "select",
                            ariaProps: checkboxProps,
                            value: checkboxProps.isSelected
                          }))
                });
    });

function style$4(columnIsFirst, columnIsLast, minWidth, width, left, stickyOpt, stickingOpt, marginOpt, alignXOpt, alignYOpt, param) {
  var sticky = stickyOpt !== undefined ? stickyOpt : false;
  var sticking = stickingOpt !== undefined ? stickingOpt : false;
  var margin = marginOpt !== undefined ? marginOpt : "xsmall";
  var alignX = alignXOpt !== undefined ? alignXOpt : "flexStart";
  var alignY = alignYOpt !== undefined ? alignYOpt : "center";
  return {
          backgroundColor: "inherit",
          borderRight: "1px solid " + (
            sticking ? Colors$Pos.neutralColor20 : Colors$Pos.transparent
          ) + "",
          borderBottom: "1px solid",
          borderBottomColor: Colors$Pos.neutralColor15,
          display: "flex",
          left: "" + String(left) + "px",
          minWidth: "" + String(minWidth) + "px",
          paddingTop: "10px",
          paddingRight: columnIsLast ? "25px" : "" + String(Spaces$Pos.toFloat(margin)) + "px",
          paddingBottom: "10px",
          paddingLeft: columnIsFirst ? "20px" : "10px",
          position: sticky ? "sticky" : "initial",
          width: "" + String(width) + "px",
          zIndex: sticking ? "1" : "0",
          boxShadow: sticking ? "inherit" : "unset",
          alignItems: alignX === "center" ? "center" : (
              alignX === "flexStart" ? "flex-start" : (
                  alignX === "flexEnd" ? "flex-end" : "normal"
                )
            ),
          flexDirection: "column",
          justifyContent: alignY === "center" ? "center" : (
              alignY === "flexStart" ? "flex-start" : (
                  alignY === "flexEnd" ? "flex-end" : "normal"
                )
            ),
          boxSizing: "border-box"
        };
}

var make$6 = React.memo(function (Props) {
      var cell = Props.cell;
      var state = Props.state;
      var layout = Props.layout;
      var ref = React.useRef(null);
      var match = ReactAria.useTableCell({
            node: cell
          }, state, ref);
      var match$1 = ReactAria$Pos.Focus.useRing(undefined, undefined);
      var match$2 = cell.column.props.extraColumnProps;
      var breakpoint = match$2.breakpoint;
      var breakpointMatched = Media$Pos.use(Breakpoints$Pos.toMediaQuery(breakpoint));
      var width = Curry._1(layout.ariaState.getColumnWidth, cell.column.key);
      var minWidth = Curry._1(layout.ariaState.getColumnMinWidth, cell.column.key);
      var initialWidthRef = React.useRef(width);
      React.useLayoutEffect((function () {
              if (breakpointMatched) {
                if (breakpoint !== "xsmall") {
                  layout.ariaState.updateResizedColumns(cell.column.key, initialWidthRef.current);
                }
                
              } else {
                layout.ariaState.updateResizedColumns(cell.column.key, 0);
              }
            }), [breakpointMatched]);
      var columnIsFirst = cell.column.index === 0;
      var columnIsLast = cell.column.index === (state.collection.columnCount - 1 | 0);
      var match$3 = cell.column.props.extraColumnProps;
      var sticky = Belt_Option.getWithDefault(cell.column.props.extraColumnProps.sticky, false);
      var match$4 = React.useMemo((function () {
              return computeColumnStickyPositionState(cell.column.index, layout.state, layout.scrollBarX.position > 0);
            }), [layout.scrollBarX.position]);
      if (breakpointMatched) {
        return React.createElement(ReactAria$Pos.Spread.make, {
                    props: ReactAria.mergeProps(match.gridCellProps, match$1.focusProps),
                    children: React.createElement("td", {
                          ref: ref,
                          style: style$4(columnIsFirst, columnIsLast, minWidth, width, match$4[0], sticky, match$4[1], match$3.margin, match$3.alignX, match$3.alignY, undefined)
                        }, cell.rendered)
                  });
      } else {
        return null;
      }
    });

function verticalGradientOverflowStyle(rightOpt, bottomOpt, param) {
  var right = rightOpt !== undefined ? rightOpt : 0;
  var bottom = bottomOpt !== undefined ? bottomOpt : 0;
  return {
          backgroundImage: "radial-gradient(at 100% 50%, rgba(0, 0, 0, 0.12) 0%, transparent 70%)",
          bottom: "" + String(bottom) + "px",
          height: "calc(100% - 25px)",
          position: "absolute",
          right: "" + String(right) + "px",
          width: "10px",
          zIndex: "1",
          pointerEvents: "none"
        };
}

var horizontalGradientOverflowStyle = {
  backgroundImage: "radial-gradient(ellipse at center, rgba(0, 0, 0, 0.08) 0%, transparent 70%)",
  bottom: "0",
  height: "13px",
  left: "0",
  position: "absolute",
  right: "0",
  zIndex: "1",
  backgroundSize: "100% 200%",
  pointerEvents: "none"
};

function style$5(maxWidth, bottomBorder) {
  var maxWidth$1 = Belt_Option.mapWithDefault(maxWidth, "auto", (function (width) {
          return String(width) + "px";
        }));
  return {
          backgroundColor: Colors$Pos.neutralColor00,
          borderBottom: "1px solid",
          borderBottomColor: bottomBorder ? Colors$Pos.neutralColor15 : Colors$Pos.transparent,
          display: "flex",
          height: "100%",
          maxWidth: maxWidth$1,
          overflow: "hidden",
          position: "relative",
          flexDirection: "column"
        };
}

var defaultDisabledRowsKeys = [];

var defaultErroredRowsMap = [];

function Table(Props) {
  var columns = Props.columns;
  var rows = Props.rows;
  var keyExtractor = Props.keyExtractor;
  var disabledRowsKeysOpt = Props.disabledRowsKeys;
  var erroredRowsMapOpt = Props.erroredRowsMap;
  var selectionEnabledOpt = Props.selectionEnabled;
  var selectAllEnabledOpt = Props.selectAllEnabled;
  var initialAllSelectedOpt = Props.initialAllSelected;
  var sortDescriptor = Props.sortDescriptor;
  var maxWidth = Props.maxWidth;
  var minHeight = Props.minHeight;
  var maxHeight = Props.maxHeight;
  var footerComponent = Props.footerComponent;
  var onLoadMore = Props.onLoadMore;
  var onSelectChange = Props.onSelectChange;
  var onSortChange = Props.onSortChange;
  var disabledRowsKeys = disabledRowsKeysOpt !== undefined ? disabledRowsKeysOpt : defaultDisabledRowsKeys;
  var erroredRowsMap = erroredRowsMapOpt !== undefined ? erroredRowsMapOpt : defaultErroredRowsMap;
  var selectionEnabled = selectionEnabledOpt !== undefined ? selectionEnabledOpt : false;
  var selectAllEnabled = selectAllEnabledOpt !== undefined ? selectAllEnabledOpt : true;
  var initialAllSelected = initialAllSelectedOpt !== undefined ? initialAllSelectedOpt : false;
  var ref = React.useRef(null);
  var headerRef = React.useRef(null);
  var bodyRef = React.useRef(null);
  var match = React.useState(function () {
        if (initialAllSelected && selectAllEnabled) {
          return /* All */0;
        } else {
          return /* Selected */{
                  _0: new Set([])
                };
        }
      });
  var setSelectedKeys = match[1];
  var selectedKeys = match[0];
  React.useEffect((function () {
          Belt_Option.forEach(onSelectChange, (function (fn) {
                  var selectedKeys$1 = selectedKeys ? /* Selected */({
                        _0: Array.from(selectedKeys._0)
                      }) : /* All */0;
                  if (selectionEnabled) {
                    return Curry._1(fn, selectedKeys$1);
                  }
                  
                }));
        }), [
        selectedKeys,
        rows.length !== 0
      ]);
  var columns$1 = React.useMemo((function () {
          return Belt_Array.keep(columns, (function (column) {
                        var hidden = Belt_Option.getWithDefault(Belt_Option.flatMap(column.layout, (function (display) {
                                    return display.hidden;
                                  })), false);
                        return hidden === false;
                      }));
        }), [columns]);
  var tableChildren = React.useMemo((function () {
          return [
                  React.createElement(ReactStately.TableHeader, {
                        children: (function (column) {
                            var breakpoint = Belt_Option.flatMap(column.layout, (function (display) {
                                    return display.breakpoint;
                                  }));
                            var minWidth = Belt_Option.map(Belt_Option.flatMap(column.layout, (function (display) {
                                        return display.minWidth;
                                      })), fromStaticToReactStatelyTableColumnSize);
                            var width = Belt_Option.map(Belt_Option.flatMap(column.layout, (function (display) {
                                        return display.width;
                                      })), toReactStatelyTableColumnSize);
                            var defaultWidth = Belt_Option.map(Belt_Option.flatMap(column.layout, (function (display) {
                                        return display.defaultWidth;
                                      })), toReactStatelyTableColumnSize);
                            var extraColumnProps_breakpoint = Belt_Option.getWithDefault(breakpoint, "xsmall");
                            var extraColumnProps_margin = Belt_Option.flatMap(column.layout, (function (display) {
                                    return display.margin;
                                  }));
                            var extraColumnProps_alignX = Belt_Option.flatMap(column.layout, (function (display) {
                                    return display.alignX;
                                  }));
                            var extraColumnProps_alignY = Belt_Option.flatMap(column.layout, (function (display) {
                                    return display.alignY;
                                  }));
                            var extraColumnProps_sticky = Belt_Option.flatMap(column.layout, (function (display) {
                                    return Belt_Option.map(display.sticky, (function (sticky) {
                                                  if (sticky) {
                                                    return rows.length <= 30;
                                                  } else {
                                                    return false;
                                                  }
                                                }));
                                  }));
                            var extraColumnProps = {
                              breakpoint: extraColumnProps_breakpoint,
                              margin: extraColumnProps_margin,
                              alignX: extraColumnProps_alignX,
                              alignY: extraColumnProps_alignY,
                              sticky: extraColumnProps_sticky
                            };
                            var match = width !== undefined && breakpoint !== undefined ? [
                                undefined,
                                width
                              ] : [
                                width,
                                defaultWidth
                              ];
                            var minWidth$1 = Belt_Option.mapWithDefault(breakpoint, minWidth, (function (param) {
                                    return Caml_option.some(fromStaticToReactStatelyTableColumnSize({
                                                    NAME: "px",
                                                    VAL: 0
                                                  }));
                                  }));
                            var allowsSorting = Belt_Option.getWithDefault(column.allowsSorting, false);
                            var tmp = {
                              children: Belt_Option.getWithDefault(column.name, ""),
                              allowsSorting: allowsSorting,
                              extraColumnProps: extraColumnProps,
                              key: column.key
                            };
                            if (minWidth$1 !== undefined) {
                              tmp.minWidth = Caml_option.valFromOption(minWidth$1);
                            }
                            var tmp$1 = match[0];
                            if (tmp$1 !== undefined) {
                              tmp.width = Caml_option.valFromOption(tmp$1);
                            }
                            var tmp$2 = match[1];
                            if (tmp$2 !== undefined) {
                              tmp.defaultWidth = Caml_option.valFromOption(tmp$2);
                            }
                            return React.createElement(ReactStately.Column, tmp);
                          }),
                        columns: columns$1
                      }),
                  React.createElement(ReactStately.TableBody, {
                        children: (function (row) {
                            var rowKey = Curry._1(keyExtractor, row);
                            var disabled = Belt_Array.some(disabledRowsKeys, (function (current) {
                                    return current === rowKey;
                                  }));
                            var index = Belt_Option.getWithDefault(Belt_Array.getIndexBy(rows, (function (current) {
                                        return Curry._1(keyExtractor, current) === Curry._1(keyExtractor, row);
                                      })), -1);
                            return React.createElement(ReactStately.Row, {
                                        children: (function (columnKey) {
                                            var errorMessage = Belt_Option.map(Belt_Array.getBy(erroredRowsMap, (function (error) {
                                                        return error.key === rowKey;
                                                      })), (function (error) {
                                                    return error.message;
                                                  }));
                                            var column = Belt_Array.getBy(columns$1, (function (column) {
                                                    return column.key === columnKey;
                                                  }));
                                            return React.createElement(ReactStately.Cell, {
                                                        children: Curry._1(column.render, {
                                                              data: row,
                                                              index: index,
                                                              disabled: disabled,
                                                              errorMessage: errorMessage
                                                            }),
                                                        key: rowKey + column.key
                                                      });
                                          }),
                                        key: rowKey
                                      });
                          }),
                        items: rows
                      })
                ];
        }), [
        columns$1,
        disabledRowsKeys,
        keyExtractor,
        rows,
        erroredRowsMap
      ]);
  var props_selectionMode = selectionEnabled ? "multiple" : "none";
  var props_allowDuplicateSelectionEvents = false;
  var props_selectedKeys = selectedKeys ? selectedKeys._0 : "all";
  var props_onSelectionChange = function (keys) {
    var match = typeof keys;
    var value = match === "string" ? /* All */0 : /* Selected */({
          _0: keys
        });
    Curry._1(setSelectedKeys, (function (prev) {
            if (value || selectAllEnabled) {
              return value;
            } else {
              return prev;
            }
          }));
  };
  var props = {
    children: tableChildren,
    selectionMode: props_selectionMode,
    showSelectionCheckboxes: selectionEnabled,
    allowDuplicateSelectionEvents: props_allowDuplicateSelectionEvents,
    disabledBehavior: "all",
    disabledKeys: disabledRowsKeys,
    selectedKeys: props_selectedKeys,
    sortDescriptor: sortDescriptor,
    onSortChange: onSortChange,
    onSelectionChange: props_onSelectionChange
  };
  var state = ReactStately.useTableState(props);
  var layout = use(ref, headerRef, bodyRef, state, onLoadMore);
  var props$1 = {
    "aria-label": "table",
    scrollRef: bodyRef
  };
  var match$1 = ReactAria.useTable(props$1, state, ref);
  var gridProps = match$1.gridProps;
  return React.useMemo((function () {
                var tmp = {
                  children: null,
                  type_: "tbody",
                  layout: layout
                };
                if (minHeight !== undefined) {
                  tmp.minHeight = Caml_option.valFromOption(minHeight);
                }
                if (maxHeight !== undefined) {
                  tmp.maxHeight = Caml_option.valFromOption(maxHeight);
                }
                return React.createElement(ReactAria$Pos.Spread.make, {
                            props: gridProps,
                            children: React.createElement("table", {
                                  ref: ref,
                                  style: style$5(maxWidth, layout.scrollBarY.scrollable)
                                }, React.createElement(make$3, {
                                      children: Belt_Array.map(state.collection.headerRows, (function (headerRow) {
                                              return React.createElement(make, {
                                                          children: Belt_Array.map(ReactStately$Pos.Table.collectionToArray(headerRow.childNodes), (function (column) {
                                                                  if (column.props.isSelectionCell) {
                                                                    return React.createElement(make$1, {
                                                                                column: column,
                                                                                state: state,
                                                                                layout: layout,
                                                                                disabled: !selectAllEnabled,
                                                                                key: column.key
                                                                              });
                                                                  } else {
                                                                    return React.createElement(make$2, {
                                                                                column: column,
                                                                                state: state,
                                                                                layout: layout,
                                                                                key: column.key
                                                                              });
                                                                  }
                                                                })),
                                                          item: headerRow,
                                                          state: state,
                                                          layout: layout,
                                                          key: headerRow.key
                                                        });
                                            })),
                                      type_: "thead",
                                      layout: layout
                                    }), React.createElement(make$3, tmp, Belt_Array.map(ReactStately$Pos.Table.collectionToArray(state.collection.body.childNodes), (function (row) {
                                            var errored = Belt_Array.some(erroredRowsMap, (function (error) {
                                                    return error.key === row.key;
                                                  }));
                                            return React.createElement(make$4, {
                                                        children: Belt_Array.map(ReactStately$Pos.Table.collectionToArray(row.childNodes), (function (cell) {
                                                                if (cell.props.isSelectionCell) {
                                                                  return React.createElement(make$5, {
                                                                              cell: cell,
                                                                              state: state,
                                                                              layout: layout,
                                                                              key: cell.key
                                                                            });
                                                                } else {
                                                                  return React.createElement(make$6, {
                                                                              cell: cell,
                                                                              state: state,
                                                                              layout: layout,
                                                                              key: cell.key
                                                                            });
                                                                }
                                                              })),
                                                        item: row,
                                                        state: state,
                                                        layout: layout,
                                                        errored: errored,
                                                        key: row.key
                                                      });
                                          })), Belt_Option.getWithDefault(footerComponent, null)), React.createElement(AnimatedRender$Pos.make, {
                                      children: React.createElement("div", {
                                            style: verticalGradientOverflowStyle(layout.scrollBarY.size, undefined, undefined)
                                          }),
                                      displayed: layout.scrollBarX.scrollable,
                                      animation: "fade",
                                      duration: 450
                                    }), React.createElement(AnimatedRender$Pos.make, {
                                      children: React.createElement("div", {
                                            style: horizontalGradientOverflowStyle
                                          }),
                                      displayed: layout.scrollBarY.scrollable,
                                      animation: "fade",
                                      duration: 450
                                    }))
                          });
              }), [
              selectAllEnabled && selectionEnabled,
              selectedKeys,
              tableChildren,
              Belt_Array.some(columns$1, (function (c) {
                      return Belt_Option.getWithDefault(Belt_Option.flatMap(c.layout, (function (l) {
                                        return l.sticky;
                                      })), false);
                    })) && rows.length <= 30 ? layout.scrollBarX.position : 0,
              layout.ariaState.updateResizedColumns,
              Json$Pos.stringifyAny([
                    layout.tableWidth,
                    layout.scrollBarY.scrollable,
                    layout.scrollBarY.size
                  ]),
              Json$Pos.stringifyAny([
                    state.selectionManager.focusedKey,
                    state.sortDescriptor
                  ])
            ]);
}

var TableLayout = {
  computeColumnStickyPositionState: computeColumnStickyPositionState,
  getRelativeBoundingRect: getRelativeBoundingRect
};

var ColumnSize = {};

var make$7 = Table;

export {
  TableLayout ,
  ColumnSize ,
  make$7 as make,
}
/* selectCellWidth Not a pure module */
