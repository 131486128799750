// Generated by ReScript, PLEASE EDIT WITH CARE


var xsmall = "(min-width: 24rem)";

var small = "(min-width: 34rem)";

var medium = "(min-width: 48rem)";

var large = "(min-width: 64rem)";

var huge = "(min-width: 87rem)";

var massive = "(min-width: 100rem)";

function toMediaQuery(breakpoint) {
  if (breakpoint === "large") {
    return large;
  } else if (breakpoint === "xsmall") {
    return xsmall;
  } else if (breakpoint === "medium") {
    return medium;
  } else if (breakpoint === "small") {
    return small;
  } else if (breakpoint === "massive") {
    return massive;
  } else {
    return huge;
  }
}

export {
  xsmall ,
  small ,
  medium ,
  large ,
  huge ,
  massive ,
  toMediaQuery ,
}
/* No side effect */
