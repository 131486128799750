// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var Discount = {};

function makeDiscounted(value, amount, kind) {
  if (kind === "PERCENT") {
    return value - value * amount / 100;
  } else {
    return value - amount;
  }
}

var ProductPrice = {
  makeDiscounted: makeDiscounted
};

function make(id, cku, name, description, stockKeepingUnit, purchasePrice, bulkUnit, retailPricesData, selectedPriceName) {
  var prices = Belt_Array.map(retailPricesData, (function (param) {
          return {
                  name: param[0],
                  value: param[1]
                };
        }));
  return {
          id: id,
          cku: cku,
          name: name,
          description: description,
          stockKeepingUnit: stockKeepingUnit,
          purchasePrice: purchasePrice,
          bulkUnit: bulkUnit,
          retailPrices: Belt_Array.some(prices, (function (param) {
                  return param.name === selectedPriceName;
                })) ? prices : undefined,
          discount: {
            id: Uuid.v4(),
            amount: 0,
            kind: "CURRENCY"
          }
        };
}

function isValid(discountedProduct, selectedPriceName) {
  var match = discountedProduct.discount;
  var match$1 = discountedProduct.retailPrices;
  var retailPriceValue;
  if (match$1 !== undefined && selectedPriceName !== undefined) {
    var match$2 = Belt_Array.getBy(match$1, (function (price) {
            return price.name === selectedPriceName;
          }));
    retailPriceValue = match$2 !== undefined ? match$2.value : undefined;
  } else {
    retailPriceValue = undefined;
  }
  if (retailPriceValue === undefined) {
    return false;
  }
  var discountedValue = makeDiscounted(retailPriceValue, match.amount, match.kind);
  if (discountedValue < retailPriceValue) {
    return discountedValue >= 0;
  } else {
    return false;
  }
}

export {
  Discount ,
  ProductPrice ,
  make ,
  isValid ,
}
/* uuid Not a pure module */
