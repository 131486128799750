// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Uuid$Pos from "../../externals/Uuid.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as CatalogCategoryPicker$Pos from "./CatalogCategoryPicker.bs.js";
import * as CatalogProductEditForm$Pos from "./CatalogProductEditForm.bs.js";

var OrganisationProduct_ShopIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogProductEditFormOrganisationFieldsetPanel-Pos.OrganisationProduct_ShopIdNotFound");

function CatalogProductEditFormOrganisationFieldsetPanel(Props) {
  var dispatch = Curry._1(CatalogProductEditForm$Pos.useFormDispatch, undefined);
  var match = React.useState(function () {
        return "";
      });
  var setFormattedValue = match[1];
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var shopId;
  if (activeShop !== undefined) {
    shopId = activeShop.id;
  } else {
    throw {
          RE_EXN_ID: OrganisationProduct_ShopIdNotFound,
          Error: new Error()
        };
  }
  var onChange = function (category) {
    if (category) {
      var match = category._0;
      var formattedName = match.formattedName;
      var id = match.id;
      var uuid = id === "" ? undefined : Uuid$Pos.fromString(id);
      Curry._1(dispatch, {
            TAG: /* FieldValueChanged */2,
            _0: /* CategoryId */4,
            _1: (function (param) {
                return uuid;
              })
          });
      return Curry._1(setFormattedValue, (function (param) {
                    return formattedName;
                  }));
    }
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* CategoryId */4,
          _1: (function (param) {
              
            })
        });
    Curry._1(setFormattedValue, (function (param) {
            return Intl$Pos.t("Not classified");
          }));
  };
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Organisation"),
              description: Intl$Pos.t("Enter in which categorie this product should belong to."),
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(CatalogCategoryPicker$Pos.make, {
                                triggerType: "input",
                                placeholder: Intl$Pos.t("Add a category"),
                                value: match[0],
                                shopId: shopId,
                                onChange: onChange
                              })), undefined, undefined, undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined))
            });
}

var make = React.memo(CatalogProductEditFormOrganisationFieldsetPanel);

export {
  OrganisationProduct_ShopIdNotFound ,
  make ,
}
/* make Not a pure module */
