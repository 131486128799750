// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function fromString(string) {
  if (Uuid.validate(string)) {
    return Caml_option.some(string);
  }
  
}

export {
  fromString ,
}
/* uuid Not a pure module */
