// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Tooltip$Pos from "../overlays/Tooltip.bs.js";
import * as MenuItem$Pos from "../navigation/MenuItem.bs.js";
import * as AsyncData$Pos from "../../primitives/AsyncData.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";

function RequestMenuItem(Props) {
  var text = Props.text;
  var textError = Props.textError;
  var operableRequest = Props.operableRequest;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return /* NotAsked */0;
      });
  var setRequestResult = match[1];
  var requestResult = match[0];
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(onChange, requestResult);
        }), [requestResult]);
  var onPress = React.useCallback((function (param) {
          if (operableRequest.TAG !== /* Ok */0) {
            return ;
          }
          if (typeof requestResult === "number") {
            if (requestResult !== /* NotAsked */0) {
              return ;
            }
            
          } else if (requestResult.TAG === /* Reloading */0) {
            return ;
          }
          Curry._1(setRequestResult, (function (param) {
                  return /* Loading */1;
                }));
          Future.get(Curry._1(operableRequest._0, undefined), (function (result) {
                  Curry._1(setRequestResult, (function (param) {
                          return {
                                  TAG: /* Done */1,
                                  _0: result
                                };
                        }));
                }));
        }), [operableRequest]);
  var text$1;
  text$1 = typeof requestResult === "number" || !(requestResult.TAG === /* Done */1 && !(requestResult._0.TAG === /* Ok */0 || textError === undefined)) ? text : textError;
  var tmp;
  tmp = typeof requestResult === "number" || !(requestResult.TAG === /* Done */1 && requestResult._0.TAG !== /* Ok */0) ? "normal" : "danger";
  var tmp$1;
  tmp$1 = operableRequest.TAG === /* Ok */0 ? Intl$Pos.t("Cannot run request") : operableRequest._0;
  return React.createElement(Tooltip$Pos.make, {
              children: React.createElement(MenuItem$Pos.make, {
                    content: {
                      TAG: /* Text */0,
                      _0: text$1
                    },
                    textVariation: tmp,
                    size: "normal",
                    disabled: AsyncData$Pos.isBusy(requestResult) || Belt_Result.isError(operableRequest),
                    shouldCloseOnPress: false,
                    action: {
                      TAG: /* Callback */0,
                      _0: onPress
                    }
                  }),
              placement: "start",
              content: {
                TAG: /* Text */0,
                _0: tmp$1
              },
              disabled: Belt_Result.isOk(operableRequest),
              delay: 75
            });
}

var make = RequestMenuItem;

export {
  make ,
}
/* react Not a pure module */
