// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Future from "rescript-future/src/Future.bs.js";
import * as Auth$Pos from "../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../primitives/Intl.bs.js";
import * as Json$Pos from "../primitives/Json.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as AuthRoutes$Pos from "../app/Auth/AuthRoutes.bs.js";
import * as Navigation$Pos from "../primitives/Navigation.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var UnauthorizedRequestError = /* @__PURE__ */Caml_exceptions.create("Request-Pos.UnauthorizedRequestError");

var UnknownRequestError = /* @__PURE__ */Caml_exceptions.create("Request-Pos.UnknownRequestError");

var serverErrorMessage = Intl$Pos.t("Wino did not respond, please reiterate your request.");

function make(methodOpt, bodyJson, authTokenRequiredOpt, abortSignal, endpoint) {
  var method = methodOpt !== undefined ? methodOpt : "GET";
  var authTokenRequired = authTokenRequiredOpt !== undefined ? authTokenRequiredOpt : true;
  var jwt = Auth$Pos.getJwt(undefined);
  if (Belt_Option.isNone(jwt) && authTokenRequired) {
    Navigation$Pos.openRoute(AuthRoutes$Pos.logoutSessionExpiredRoute);
    return Future.value({
                TAG: /* Error */1,
                _0: /* RedirectingAuthenticationJwtError */2
              });
  }
  var headers = jwt !== undefined ? (
      authTokenRequired ? new Headers({
              "Content-Type": "application/json",
              Authorization: "Bearer " + jwt + ""
            }) : new Headers({
              "Content-Type": "application/json"
            })
    ) : new Headers({
          "Content-Type": "application/json"
        });
  var init_method = method;
  var init_body = Belt_Option.map(bodyJson, Json$Pos.stringify);
  var init_headers = Caml_option.some(headers);
  var init = {
    method: init_method,
    body: init_body,
    headers: init_headers,
    signal: abortSignal
  };
  return Future.map(Future.mapError(FuturePromise.fromPromise(fetch(endpoint, init).then(function (response) {
                          var match = response.ok;
                          var match$1 = response.status;
                          if (match) {
                            if (match$1 !== 200) {
                              return Promise.reject({
                                          RE_EXN_ID: UnknownRequestError
                                        });
                            } else {
                              return response.json();
                            }
                          }
                          if (match$1 >= 405) {
                            return Promise.reject({
                                        RE_EXN_ID: UnknownRequestError
                                      });
                          }
                          if (match$1 < 400) {
                            return Promise.reject({
                                        RE_EXN_ID: UnknownRequestError
                                      });
                          }
                          switch (match$1) {
                            case 401 :
                                return Promise.reject({
                                            RE_EXN_ID: UnauthorizedRequestError
                                          });
                            case 402 :
                            case 403 :
                                return Promise.reject({
                                            RE_EXN_ID: UnknownRequestError
                                          });
                            case 400 :
                            case 404 :
                                return response.json();
                            
                          }
                        })), undefined, Caml_js_exceptions.internalToOCamlException), undefined, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: result._0
                        };
                }
                var error = result._0;
                if (error.RE_EXN_ID === UnauthorizedRequestError) {
                  return {
                          TAG: /* Error */1,
                          _0: /* RedirectingAuthenticationJwtError */2
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: /* FatalServerError */0
                        };
                }
              }));
}

function decodeReponseJsonFailures(json) {
  var failures = Belt_Array.keepMap(Belt_Array.map(json, Json$Pos.decodeDict), (function (json) {
          var message = Json$Pos.flatDecodeDictFieldString(json, "message");
          var kind = Json$Pos.flatDecodeDictFieldString(json, "kind");
          var data = Belt_Option.map(Json$Pos.flatDecodeDictFieldDict(json, "data"), (function (prim) {
                  return prim;
                }));
          if (message !== undefined && kind !== undefined) {
            return {
                    message: message,
                    kind: kind,
                    data: data
                  };
          }
          
        }));
  if (failures.length === json.length) {
    return failures;
  }
  
}

function decodeResponseJson(json) {
  var data = Belt_Option.orElse(Belt_Option.orElse(Belt_Option.map(Json$Pos.flatDecodeDictFieldDict(Json$Pos.decodeDict(json), "data"), (function (prim) {
                  return prim;
                })), Belt_Option.map(Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "data"), (function (prim) {
                  return prim;
                }))), Belt_Option.map(Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "data", Json$Pos.decodeNull), (function (param) {
              return Json$Pos.encodedNull;
            })));
  var errors = Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "errors");
  if (data !== undefined) {
    if (errors !== undefined) {
      return {
              TAG: /* Error */1,
              _0: undefined
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: Caml_option.some(Caml_option.valFromOption(data))
            };
    }
  } else if (errors !== undefined) {
    return {
            TAG: /* Error */1,
            _0: decodeReponseJsonFailures(errors)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

function makeAndDecode(method, bodyJson, authTokenRequired, abortSignal, endpoint) {
  return Future.flatMapOk(Future.mapOk(make(method, bodyJson, authTokenRequired, abortSignal, endpoint), undefined, decodeResponseJson), undefined, (function (result) {
                var tmp;
                if (result.TAG === /* Ok */0) {
                  var data = result._0;
                  tmp = data !== undefined ? ({
                        TAG: /* Ok */0,
                        _0: Caml_option.valFromOption(data)
                      }) : ({
                        TAG: /* Error */1,
                        _0: /* CannotDecodeResult */1
                      });
                } else {
                  var failures = result._0;
                  tmp = failures !== undefined ? ({
                        TAG: /* Error */1,
                        _0: /* ServerFailures */{
                          _0: failures
                        }
                      }) : ({
                        TAG: /* Error */1,
                        _0: /* CannotDecodeResult */1
                      });
                }
                return Future.value(tmp);
              }));
}

export {
  serverErrorMessage ,
  make ,
  decodeResponseJson ,
  decodeReponseJsonFailures ,
  makeAndDecode ,
}
/* serverErrorMessage Not a pure module */
