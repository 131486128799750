// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as DayPickerRange$Pos from "./DayPickerRange.bs.js";
import * as TextIconButton$Pos from "../actions/TextIconButton.bs.js";
import * as InputRadioGroupField$Pos from "./InputRadioGroupField.bs.js";

var values = [
  /* Today */0,
  /* Yesterday */1,
  /* CurrentWeek */2,
  /* LastWeek */3,
  /* CurrentMonth */4,
  /* LastMonth */5,
  /* CurrentYear */6,
  /* LastYear */7
];

function today(param) {
  return /* Today */0;
}

function yesterday(param) {
  return /* Yesterday */1;
}

function currentWeek(param) {
  return /* CurrentWeek */2;
}

function lastWeek(param) {
  return /* LastWeek */3;
}

function currentMonth(param) {
  return /* CurrentMonth */4;
}

function lastMonth(param) {
  return /* LastMonth */5;
}

function currentYear(param) {
  return /* CurrentYear */6;
}

function lastYear(param) {
  return /* LastYear */7;
}

function custom(custom$1) {
  return /* Custom */{
          _0: custom$1
        };
}

function notDefined(param) {
  return /* NotDefined */8;
}

function toString(preset) {
  if (typeof preset !== "number") {
    return Intl$Pos.t("Custom");
  }
  switch (preset) {
    case /* Today */0 :
        return Intl$Pos.t("Today");
    case /* Yesterday */1 :
        return Intl$Pos.t("Yesterday");
    case /* CurrentWeek */2 :
        return Intl$Pos.t("Current week");
    case /* LastWeek */3 :
        return Intl$Pos.t("Last week");
    case /* CurrentMonth */4 :
        return Intl$Pos.t("Current month");
    case /* LastMonth */5 :
        return Intl$Pos.t("Last month");
    case /* CurrentYear */6 :
        return Intl$Pos.t("Current year");
    case /* LastYear */7 :
        return Intl$Pos.t("Last year");
    case /* NotDefined */8 :
        return ;
    
  }
}

function toDateRange(preset) {
  var now = new Date(Date.now());
  if (typeof preset === "number") {
    switch (preset) {
      case /* Today */0 :
          return [
                  DateHelpers$Pos.startOfDay(now),
                  DateHelpers$Pos.endOfDay(now)
                ];
      case /* Yesterday */1 :
          return [
                  DateHelpers$Pos.subDays(DateHelpers$Pos.startOfDay(now), 1),
                  DateHelpers$Pos.endOfDay(DateHelpers$Pos.subDays(now, 1))
                ];
      case /* CurrentWeek */2 :
          return [
                  DateHelpers$Pos.startOfWeek(now),
                  DateHelpers$Pos.endOfDay(now)
                ];
      case /* LastWeek */3 :
          return [
                  DateHelpers$Pos.startOfWeek(DateHelpers$Pos.subWeeks(now, 1)),
                  DateHelpers$Pos.endOfWeek(DateHelpers$Pos.subWeeks(now, 1))
                ];
      case /* CurrentMonth */4 :
          return [
                  DateHelpers$Pos.startOfMonth(now),
                  DateHelpers$Pos.endOfDay(now)
                ];
      case /* LastMonth */5 :
          return [
                  DateHelpers$Pos.startOfMonth(DateHelpers$Pos.subMonths(now, 1)),
                  DateHelpers$Pos.endOfMonth(DateHelpers$Pos.subMonths(now, 1))
                ];
      case /* CurrentYear */6 :
          return [
                  DateHelpers$Pos.startOfYear(now),
                  DateHelpers$Pos.endOfDay(now)
                ];
      case /* LastYear */7 :
          return [
                  DateHelpers$Pos.startOfYear(DateHelpers$Pos.subYears(now, 1)),
                  DateHelpers$Pos.endOfYear(DateHelpers$Pos.subYears(now, 1))
                ];
      case /* NotDefined */8 :
          return ;
      
    }
  } else {
    var match = preset._0;
    var startDate = match[0];
    if (startDate === undefined) {
      return ;
    }
    var endDate = match[1];
    if (endDate !== undefined) {
      return [
              Caml_option.valFromOption(startDate),
              Caml_option.valFromOption(endDate)
            ];
    } else {
      return ;
    }
  }
}

function fromPartialDateRange(range) {
  var startDateA = range[0];
  if (startDateA === undefined) {
    return /* NotDefined */8;
  }
  var endDateA = range[1];
  if (endDateA === undefined) {
    return /* Custom */{
            _0: range
          };
  }
  var endDateA$1 = Caml_option.valFromOption(endDateA);
  var startDateA$1 = Caml_option.valFromOption(startDateA);
  var preset = Belt_Array.getBy(values, (function (preset) {
          var match = toDateRange(preset);
          if (match !== undefined && DateHelpers$Pos.isSameDay(startDateA$1, match[0])) {
            return DateHelpers$Pos.isSameDay(endDateA$1, match[1]);
          } else {
            return false;
          }
        }));
  return Belt_Option.getWithDefault(preset, /* Custom */{
              _0: range
            });
}

var todayDateRange = Belt_Option.getExn(toDateRange(/* Today */0));

var maxDate = DateHelpers$Pos.endOfDay(new Date());

function isOutsideRange(date) {
  return date.getTime() > maxDate.getTime();
}

var styles = StyleSheet$Pos.create({
      presets: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor15, Style$Pos.hairlineWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(342)), undefined, undefined, Caml_option.some(Style$Pos.dp(20)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function DateRangePicker(Props) {
  var focusedInputOpt = Props.focusedInput;
  var visibleMonth = Props.visibleMonth;
  var preset = Props.preset;
  var range = Props.range;
  var disabledResetButtonOpt = Props.disabledResetButton;
  var onChangePreset = Props.onChangePreset;
  var onRequestReset = Props.onRequestReset;
  var onDatesChange = Props.onDatesChange;
  var onFocusChange = Props.onFocusChange;
  var onPrevMonthClick = Props.onPrevMonthClick;
  var onNextMonthClick = Props.onNextMonthClick;
  var focusedInput = focusedInputOpt !== undefined ? focusedInputOpt : DayPickerRange$Pos.defaultFocusedInput;
  var disabledResetButton = disabledResetButtonOpt !== undefined ? disabledResetButtonOpt : false;
  var onPrevMonthClick$1 = Curry.__1(onPrevMonthClick);
  var onNextMonthClick$1 = Curry.__1(onNextMonthClick);
  var initialVisibleMonth = function (param) {
    return visibleMonth;
  };
  return React.createElement(Inline$Pos.make, {
              children: null
            }, React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.presets), undefined, undefined, undefined), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                  children: Intl$Pos.t("Period"),
                                  variation: "normal",
                                  weight: "semibold",
                                  size: "tiny"
                                })), undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputRadioGroupField$Pos.make, {
                      value: preset,
                      required: false,
                      onChange: onChangePreset,
                      options: [
                        /* Today */0,
                        /* Yesterday */1,
                        /* CurrentWeek */2,
                        /* LastWeek */3,
                        /* CurrentMonth */4,
                        /* LastMonth */5,
                        /* CurrentYear */6,
                        /* LastYear */7
                      ],
                      optionToText: (function (preset) {
                          return Belt_Option.getWithDefault(toString(preset), "");
                        })
                    }), disabledResetButton ? null : React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, {
                                    children: Intl$Pos.t("Reset period"),
                                    size: "small",
                                    disabled: (Js_null_undefined.fromOption(range[1]) == null),
                                    icon: "delete_light",
                                    onPress: onRequestReset
                                  })), undefined, "xnormal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(DayPickerRange$Pos.make, {
                  startDate: range[0],
                  endDate: range[1],
                  maxDate: maxDate,
                  initialVisibleMonth: initialVisibleMonth,
                  isOutsideRange: isOutsideRange,
                  focusedInput: focusedInput,
                  onChange: onDatesChange,
                  onFocusChange: onFocusChange,
                  onPrevMonthClick: onPrevMonthClick$1,
                  onNextMonthClick: onNextMonthClick$1,
                  key: visibleMonth.toDateString()
                }));
}

var make = React.memo(DateRangePicker);

var Preset = {
  values: values,
  toString: toString,
  toDateRange: toDateRange,
  fromPartialDateRange: fromPartialDateRange,
  today: today,
  yesterday: yesterday,
  currentWeek: currentWeek,
  lastWeek: lastWeek,
  currentMonth: currentMonth,
  lastMonth: lastMonth,
  currentYear: currentYear,
  lastYear: lastYear,
  custom: custom,
  notDefined: notDefined
};

export {
  Preset ,
  todayDateRange ,
  maxDate ,
  isOutsideRange ,
  make ,
}
/* todayDateRange Not a pure module */
