// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid$Pos from "../../externals/Uuid.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as CatalogProduct$Pos from "./CatalogProduct.bs.js";

function encoder(param) {
  return [
          param.id,
          param.value
        ];
}

function decoder(param) {
  var uuid = Uuid$Pos.fromString(param[0]);
  if (uuid !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              id: Caml_option.valFromOption(uuid),
              value: param[1]
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "SyntaxError",
              VAL: "Invalid tax uuid"
            }
          };
  }
}

var value = JsonCodec$Pos.object2(encoder, decoder, JsonCodec$Pos.field("id", JsonCodec$Pos.string), JsonCodec$Pos.field("value", JsonCodec$Pos.$$float));

var Tax = {
  encoder: encoder,
  decoder: decoder,
  value: value
};

function encoder$1(state) {
  return [
          state.name,
          state.tax,
          Belt_Option.map(state.categoryId, (function (prim) {
                  return prim;
                })),
          CatalogProduct$Pos.Kind.toString(state.kind),
          Belt_Option.map(state.color, CatalogProduct$Pos.Color.toString),
          state.producer,
          state.family,
          state.designation,
          state.country,
          state.region,
          state.beerType,
          Belt_Option.map(state.wineType, CatalogProduct$Pos.WineType.toString),
          Belt_Option.map(state.whiteWineType, CatalogProduct$Pos.WhiteWineType.toString)
        ];
}

function decoder$1(param) {
  var getValueAndError = function (value) {
    if (value !== undefined) {
      if (value.TAG === /* Ok */0) {
        return [
                Caml_option.some(value._0),
                undefined
              ];
      } else {
        return [
                undefined,
                Caml_option.some(value._0)
              ];
      }
    } else {
      return [
              undefined,
              undefined
            ];
    }
  };
  var categoryId = Belt_Option.map(param[2], (function (id) {
          var uuid = Uuid$Pos.fromString(id);
          if (uuid !== undefined) {
            return {
                    TAG: /* Ok */0,
                    _0: Caml_option.valFromOption(uuid)
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: "Invalid category uuid"
                  };
          }
        }));
  var match = CatalogProduct$Pos.Kind.fromString(param[3]);
  var match$1 = getValueAndError(categoryId);
  var match$2 = getValueAndError(Belt_Option.map(param[4], CatalogProduct$Pos.Color.fromString));
  var match$3 = getValueAndError(Belt_Option.map(param[11], CatalogProduct$Pos.WineType.fromString));
  var match$4 = getValueAndError(Belt_Option.map(param[12], CatalogProduct$Pos.WhiteWineType.fromString));
  if (match.TAG !== /* Ok */0) {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "SyntaxError",
              VAL: match._0
            }
          };
  }
  if (match$1[1] === undefined && match$2[1] === undefined && match$3[1] === undefined && match$4[1] === undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              name: param[0],
              kind: match._0,
              color: match$2[0],
              tax: param[1],
              categoryId: match$1[0],
              producer: param[5],
              family: param[6],
              designation: param[7],
              country: param[8],
              region: param[9],
              beerType: param[10],
              wineType: match$3[0],
              whiteWineType: match$4[0]
            }
          };
  }
  var error = match$1[1];
  if (error !== undefined) {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "SyntaxError",
              VAL: error
            }
          };
  }
  var error$1 = match$2[1];
  if (error$1 !== undefined) {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "SyntaxError",
              VAL: error$1
            }
          };
  }
  var error$2 = match$3[1];
  if (error$2 !== undefined) {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "SyntaxError",
              VAL: error$2
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "SyntaxError",
              VAL: match$4[1]
            }
          };
  }
}

var value$1 = JsonCodec$Pos.object13(encoder$1, decoder$1, JsonCodec$Pos.field("name", JsonCodec$Pos.string), JsonCodec$Pos.optional(JsonCodec$Pos.field("tax", value)), JsonCodec$Pos.optional(JsonCodec$Pos.field("categoryId", JsonCodec$Pos.string)), JsonCodec$Pos.field("kind", JsonCodec$Pos.string), JsonCodec$Pos.optional(JsonCodec$Pos.field("color", JsonCodec$Pos.string)), JsonCodec$Pos.field("producer", JsonCodec$Pos.string), JsonCodec$Pos.field("family", JsonCodec$Pos.string), JsonCodec$Pos.field("designation", JsonCodec$Pos.string), JsonCodec$Pos.field("country", JsonCodec$Pos.string), JsonCodec$Pos.field("region", JsonCodec$Pos.string), JsonCodec$Pos.field("beerType", JsonCodec$Pos.string), JsonCodec$Pos.optional(JsonCodec$Pos.field("wineType", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("whiteWineType", JsonCodec$Pos.string)));

var Codecs = {
  Tax: Tax,
  encoder: encoder$1,
  decoder: decoder$1,
  value: value$1
};

function encode(state) {
  return JsonCodec$Pos.encodeWith(state, value$1);
}

function decode(historyState) {
  return Belt_Option.map(historyState, (function (state) {
                return JsonCodec$Pos.decodeWith(state, value$1);
              }));
}

var CreateVariantFromProduct = {
  Codecs: Codecs,
  encode: encode,
  decode: decode
};

export {
  CreateVariantFromProduct ,
}
/* value Not a pure module */
