// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactAria from "react-aria";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var Button = {};

var Label = {};

var TextField = {};

var Checkbox = {};

var Select = {};

var $$Selection = {};

var HeaderRow = {};

var ColumnHeader = {};

var RowGroup = {};

var Row = {};

var Cell = {};

var Layout = {};

var Table = {
  $$Selection: $$Selection,
  HeaderRow: HeaderRow,
  ColumnHeader: ColumnHeader,
  RowGroup: RowGroup,
  Row: Row,
  Cell: Cell,
  Layout: Layout
};

var Position = {};

var Trigger = {};

var Overlay = {
  Position: Position,
  Trigger: Trigger
};

var Popover = {};

var Dialog = {};

var Trigger$1 = {};

var Tooltip = {
  Trigger: Trigger$1
};

var DateSegment = {};

var TimeField = {};

var NumberField = {};

var Section = {};

var ListBox = {
  Section: Section
};

var $$Option = {};

var ComboBox = {};

var Filter = {};

var Resize = {};

var DismissButton = {};

var VisuallyHidden = {};

var Within = {};

var Scope = {};

var Ring = {};

function useRing(prim0, prim1) {
  return ReactAria.useFocusRing(prim0 !== undefined ? Caml_option.valFromOption(prim0) : undefined);
}

function useWithin(prim) {
  return ReactAria.useFocusWithin(prim);
}

var Focus = {
  Within: Within,
  Scope: Scope,
  Ring: Ring,
  useRing: useRing,
  useWithin: useWithin
};

function ReactAria$Spread(Props) {
  return React.cloneElement(Props.children, Props.props);
}

var Spread = {
  make: ReactAria$Spread
};

export {
  Button ,
  Label ,
  TextField ,
  Checkbox ,
  Select ,
  Table ,
  Overlay ,
  Popover ,
  Dialog ,
  Tooltip ,
  DateSegment ,
  TimeField ,
  NumberField ,
  ListBox ,
  $$Option ,
  ComboBox ,
  Filter ,
  Resize ,
  DismissButton ,
  VisuallyHidden ,
  Focus ,
  Spread ,
}
/* react Not a pure module */
