// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var kvisteEndpoint = Env$Pos.cavavinUrl(undefined) + "/cart/update";

function encodeProduct(product) {
  var match;
  if (product.TAG === /* Unit */0) {
    var match$1 = product._0;
    match = [
      match$1.quantity,
      match$1.stockKeepingUnit
    ];
  } else {
    var match$2 = product._0;
    match = [
      match$2.quantity.toNumber(),
      match$2.stockKeepingUnit
    ];
  }
  var stockKeepingUnit = match[1];
  if (stockKeepingUnit !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: Json$Pos.encodeDict(Js_dict.fromArray([
                      [
                        "quantity",
                        Json$Pos.encodeNumber(match[0])
                      ],
                      [
                        "reference",
                        Json$Pos.encodeString(stockKeepingUnit)
                      ]
                    ]))
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

var ResponseError = /* @__PURE__ */Caml_exceptions.create("ResponseError");

function sendToKviste(cartProducts, shopId) {
  var payload = {};
  payload["cart"] = Json$Pos.encodeArray(Belt_Array.keepMap(cartProducts, (function (product) {
              var product$1 = encodeProduct(product);
              if (product$1.TAG === /* Ok */0) {
                return Caml_option.some(product$1._0);
              }
              
            })));
  return Future.mapError(FuturePromise.fromPromise(fetch(kvisteEndpoint, {
                          method: "POST",
                          body: Caml_option.some(Json$Pos.stringify(Json$Pos.encodeDict(payload))),
                          headers: Caml_option.some(new Headers({
                                    "Content-Type": "application/json",
                                    "x-shop-id": shopId
                                  }))
                        }).then(function (prim) {
                        return prim.json();
                      }).then(function (json) {
                      var dict = Json$Pos.decodeDict(json);
                      if (dict === undefined) {
                        return Promise.reject({
                                    RE_EXN_ID: ResponseError,
                                    _1: "Cannot decode json response"
                                  });
                      }
                      var dict$1 = Caml_option.valFromOption(dict);
                      var match = Belt_Option.flatMap(Js_dict.get(dict$1, "success"), Json$Pos.decodeBoolean);
                      var match$1 = Belt_Option.flatMap(Js_dict.get(dict$1, "sucess"), Json$Pos.decodeBoolean);
                      var success = match !== undefined ? match : (
                          match$1 !== undefined ? match$1 : Pervasives.failwith("Cannot decode success property")
                        );
                      var redirect = Belt_Option.flatMap(Js_dict.get(dict$1, "redirect"), Json$Pos.decodeString);
                      var decodeError = function (response) {
                        var dict = Json$Pos.decodeDict(response);
                        if (dict === undefined) {
                          return ;
                        }
                        var dict$1 = Caml_option.valFromOption(dict);
                        var reference = Belt_Option.flatMap(Js_dict.get(dict$1, "reference"), Json$Pos.decodeString);
                        var message = Belt_Option.flatMap(Js_dict.get(dict$1, "message"), Json$Pos.decodeString);
                        if (reference !== undefined && message !== undefined) {
                          return {
                                  message: message,
                                  reference: reference
                                };
                        }
                        
                      };
                      var errors = Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict$1, "errors"), Json$Pos.decodeArray), (function (errors) {
                              return Belt_Array.keepMap(errors, decodeError);
                            }));
                      return Promise.resolve({
                                  success: success,
                                  redirect: redirect,
                                  errors: errors
                                });
                    })), undefined, (function (param) {
                return Intl$Pos.t("Kviste service is temporarily unavailable.");
              }));
}

function checkProductDataKvisteAPI(cartProducts, shopId, notifier, onRequestCartRowsError, onRequestCartLoading) {
  Curry._1(onRequestCartLoading, {
        message: undefined
      });
  Future.tapError(Future.mapOk(Future.tap(sendToKviste(cartProducts, shopId), (function (param) {
                  Curry._1(onRequestCartLoading, undefined);
                })), undefined, (function (result) {
              var match = result.errors;
              var match$1 = result.redirect;
              if (match !== undefined) {
                return Curry._1(onRequestCartRowsError, Belt_Array.keepMap(match, (function (error) {
                                  var match = Belt_Array.keepMap(cartProducts, (function (product) {
                                          var match;
                                          if (product.TAG === /* Unit */0) {
                                            var match$1 = product._0;
                                            match = [
                                              match$1.id,
                                              match$1.stockKeepingUnit
                                            ];
                                          } else {
                                            var match$2 = product._0;
                                            match = [
                                              match$2.id,
                                              match$2.stockKeepingUnit
                                            ];
                                          }
                                          var sku = match[1];
                                          if (sku !== undefined && sku === error.reference) {
                                            return match[0];
                                          }
                                          
                                        }));
                                  if (match.length !== 1) {
                                    return ;
                                  }
                                  var productId = match[0];
                                  return {
                                          key: productId,
                                          message: Intl$Pos.t(error.message)
                                        };
                                })));
              } else if (match$1 !== undefined && result.success) {
                return Curry._3(notifier.reset, {
                            TAG: /* Success */0,
                            _0: Intl$Pos.template(Intl$Pos.t("The order has successfully been sent to Kviste. [Get to cart]({{link}})"), {
                                  link: match$1
                                }, undefined)
                          }, undefined, undefined);
              } else {
                return ;
              }
            })), (function (message) {
          Curry._3(notifier.add, {
                TAG: /* Error */1,
                _0: message
              }, undefined, undefined);
        }));
}

export {
  kvisteEndpoint ,
  checkProductDataKvisteAPI ,
}
/* kvisteEndpoint Not a pure module */
