// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Spinner$Pos from "../../resources/feedback-indicators/Spinner.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      root: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-100)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function LoadingPage(Props) {
  var textOpt = Props.text;
  var text = textOpt !== undefined ? textOpt : Intl$Pos.t("Loading...");
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.root), undefined, undefined, undefined), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Spinner$Pos.make, {
                              size: 40
                            })), undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Stack$Pos.make, {
                  children: null
                }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Title$Pos.make, {
                                  children: text,
                                  level: 3,
                                  align: "center"
                                })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                  children: Intl$Pos.t("Please wait."),
                                  align: "center",
                                  variation: "normal"
                                })), "xhuge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
}

var make = LoadingPage;

export {
  styles ,
  make ,
}
/* styles Not a pure module */
