// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var style = Props.style;
      var ref$1 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      if (children !== undefined) {
        var tmp = {
          children: Caml_option.valFromOption(children)
        };
        if (ref$1 !== undefined) {
          tmp.ref = Caml_option.valFromOption(ref$1);
        }
        if (style !== undefined) {
          tmp.style = Caml_option.valFromOption(style);
        }
        return React.createElement(ReactNative.View, tmp);
      }
      var tmp$1 = {};
      if (ref$1 !== undefined) {
        tmp$1.ref = Caml_option.valFromOption(ref$1);
      }
      if (style !== undefined) {
        tmp$1.style = Caml_option.valFromOption(style);
      }
      return React.createElement(ReactNative.View, tmp$1);
    });

var make$1 = React.memo(make);

make$1.displayName = "View";

function makeProps(prim0, prim1, prim2, prim3, prim4) {
  var tmp = {};
  if (prim0 !== undefined) {
    tmp.children = Caml_option.valFromOption(prim0);
  }
  if (prim1 !== undefined) {
    tmp.style = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.key = prim2;
  }
  if (prim3 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim3);
  }
  return tmp;
}

export {
  makeProps ,
  make$1 as make,
}
/* make Not a pure module */
