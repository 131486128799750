// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env$Pos from "../../core/Env.bs.js";
import * as Jwt$Pos from "../../externals/Jwt.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var storageItemKey = "jwt";

var isTesting = Env$Pos.context(undefined) === "test";

var testingStorageItemValue = {
  contents: isTesting ? "dummy-token" : undefined
};

function isImpersonated(jwt) {
  return Belt_Option.isSome(Json$Pos.flatDecodeDictFieldString(Json$Pos.flatDecodeDictField(Json$Pos.flatDecodeDictFieldDict(Belt_Option.flatMap(Jwt$Pos.decode(jwt), Json$Pos.decodeDict), "scope"), "impersonationUser", Json$Pos.decodeDict), "id"));
}

function get(param) {
  if (isTesting) {
    return testingStorageItemValue.contents;
  } else {
    return Belt_Option.orElse(Caml_option.null_to_opt(sessionStorage.getItem(storageItemKey)), Caml_option.null_to_opt(localStorage.getItem(storageItemKey)));
  }
}

function set(token, impersonating, param) {
  if (isTesting) {
    testingStorageItemValue.contents = token;
  } else if (impersonating === true) {
    sessionStorage.setItem(storageItemKey, token);
  } else {
    localStorage.setItem(storageItemKey, token);
  }
}

function remove(param) {
  if (isTesting) {
    testingStorageItemValue.contents = undefined;
  } else {
    sessionStorage.removeItem(storageItemKey);
    localStorage.removeItem(storageItemKey);
  }
}

export {
  storageItemKey ,
  isTesting ,
  testingStorageItemValue ,
  isImpersonated ,
  get ,
  set ,
  remove ,
}
/* isTesting Not a pure module */
