// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Label$Pos from "../../primitives/Label.bs.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as TextLink$Pos from "../../resources/navigation/TextLink.bs.js";
import * as AsyncData$Pos from "../../primitives/AsyncData.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as IconButton$Pos from "../../resources/actions/IconButton.bs.js";
import * as TextAction$Pos from "../../resources/actions/TextAction.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as InputTextField$Pos from "../../resources/selection-and-input/InputTextField.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";

var printersHubName = "StarPrintersHub";

function errorMessageFromError(error) {
  if (error !== 0) {
    return Request$Pos.serverErrorMessage;
  } else {
    return Intl$Pos.t("The API key is not recognized. You can try to generate a new one or contact our support.");
  }
}

function makeEndpoint(shopId) {
  return Env$Pos.gatewayUrl(undefined) + "/printers-hub/" + printersHubName + "/" + shopId + "/printers";
}

function decodeResultItem(json) {
  var dict = Json$Pos.decodeDict(json);
  var decodeConfig = function (dict) {
    var match = Json$Pos.flatDecodeDictFieldString(dict, "id");
    var match$1 = Json$Pos.flatDecodeDictFieldString(dict, "groupId");
    if (match !== undefined && match$1 !== undefined) {
      return [
              match,
              match$1
            ];
    }
    
  };
  var match = Json$Pos.flatDecodeDictFieldString(dict, "name");
  var match$1 = Json$Pos.flatDecodeDictFieldString(dict, "hubName");
  var match$2 = Json$Pos.flatDecodeDictFieldString(dict, "macAddress");
  var match$3 = decodeConfig(Json$Pos.flatDecodeDictFieldDict(dict, "config"));
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined) {
    return {
            id: match$3[0],
            groupId: match$3[1],
            name: match,
            hubName: match$1,
            macAddress: match$2
          };
  }
  
}

function decodeResult(json) {
  var resultItems = Json$Pos.decodeArray(json);
  if (resultItems !== undefined) {
    return Belt_Array.keepMap(resultItems, decodeResultItem);
  } else {
    return [];
  }
}

function decodeServerFailure(error) {
  if (error.kind === "NotFoundShopPrintersHub") {
    return /* NoPrinterFound */1;
  } else {
    return /* UnexpectedFailure */2;
  }
}

function mapFutureResult(futureResult) {
  return Future.mapError(Future.mapOk(futureResult, undefined, decodeResult), undefined, (function (error) {
                if (typeof error === "number") {
                  return /* UnexpectedFailure */2;
                } else {
                  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(error._0, 0), decodeServerFailure), /* UnexpectedFailure */2);
                }
              }));
}

function make(shopId) {
  return mapFutureResult(Request$Pos.makeAndDecode("GET", undefined, undefined, undefined, makeEndpoint(shopId)));
}

var SettingsLabelPrinterListRequest = {
  makeEndpoint: makeEndpoint,
  decodeResultItem: decodeResultItem,
  decodeResult: decodeResult,
  decodeServerFailure: decodeServerFailure,
  mapFutureResult: mapFutureResult,
  make: make
};

function makeEndpoint$1(shopId) {
  return Env$Pos.gatewayUrl(undefined) + "/printers-hub/" + printersHubName + "/" + shopId + "/default-printer";
}

function decodeResult$1(json) {
  var dict = Json$Pos.decodeDict(json);
  return Json$Pos.flatDecodeDictFieldString(Json$Pos.flatDecodeDictFieldDict(dict, "config"), "id");
}

function decodeServerFailure$1(error) {
  if (error.kind === "NotFoundShopDefaultPrinterConfig") {
    return /* NoPrinterFound */1;
  } else {
    return /* UnexpectedFailure */2;
  }
}

function mapFutureResult$1(futureResult) {
  return Future.mapError(Future.mapOk(futureResult, undefined, decodeResult$1), undefined, (function (error) {
                if (typeof error === "number") {
                  return /* UnexpectedFailure */2;
                } else {
                  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(error._0, 0), decodeServerFailure$1), /* UnexpectedFailure */2);
                }
              }));
}

function make$1(shopId) {
  return mapFutureResult$1(Request$Pos.makeAndDecode("GET", undefined, undefined, undefined, makeEndpoint$1(shopId)));
}

var SettingsLabelDefaultPrinterIdRequest = {
  makeEndpoint: makeEndpoint$1,
  decodeResult: decodeResult$1,
  decodeServerFailure: decodeServerFailure$1,
  mapFutureResult: mapFutureResult$1,
  make: make$1
};

function makeEndpoint$2(shopId) {
  return Env$Pos.gatewayUrl(undefined) + "/printers-hub/" + printersHubName + "/" + shopId + "/setup";
}

function encodeBodyJson(apiKey) {
  return Json$Pos.encodeDict(Js_dict.fromArray([[
                    "printersHubConfig",
                    Json$Pos.encodeDict(Js_dict.fromArray([[
                                "apiKey",
                                Json$Pos.encodeString(apiKey)
                              ]]))
                  ]]));
}

function decodeResultItem$1(json) {
  var dict = Json$Pos.decodeDict(json);
  var decodeConfig = function (dict) {
    var match = Json$Pos.flatDecodeDictFieldString(dict, "id");
    var match$1 = Json$Pos.flatDecodeDictFieldString(dict, "groupId");
    if (match !== undefined && match$1 !== undefined) {
      return [
              match,
              match$1
            ];
    }
    
  };
  var match = Json$Pos.flatDecodeDictFieldString(dict, "name");
  var match$1 = Json$Pos.flatDecodeDictFieldString(dict, "hubName");
  var match$2 = Json$Pos.flatDecodeDictFieldString(dict, "macAddress");
  var match$3 = decodeConfig(Json$Pos.flatDecodeDictFieldDict(dict, "config"));
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined) {
    return {
            id: match$3[0],
            groupId: match$3[1],
            name: match,
            hubName: match$1,
            macAddress: match$2
          };
  }
  
}

function decodeResult$2(json) {
  var resultItems = Json$Pos.decodeArray(json);
  if (resultItems !== undefined) {
    return Belt_Array.keepMap(resultItems, decodeResultItem$1);
  } else {
    return [];
  }
}

function decodeServerFailure$2(error) {
  switch (error.kind) {
    case "InvalidPrinterHubConfig" :
    case "PrintersHubPermissionsCheck" :
        return /* InvalidAPIKey */0;
    default:
      return /* UnexpectedFailure */2;
  }
}

function mapFutureResult$2(futureResult) {
  return Future.mapError(Future.mapOk(futureResult, undefined, decodeResult$2), undefined, (function (error) {
                if (typeof error === "number") {
                  return /* UnexpectedFailure */2;
                } else {
                  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(error._0, 0), decodeServerFailure$2), /* UnexpectedFailure */2);
                }
              }));
}

function make$2(shopId, apiKey) {
  return mapFutureResult$2(Request$Pos.makeAndDecode("POST", Caml_option.some(encodeBodyJson(apiKey)), undefined, undefined, makeEndpoint$2(shopId)));
}

var SettingsLabelPrinterHubSetupRequest = {
  makeEndpoint: makeEndpoint$2,
  encodeBodyJson: encodeBodyJson,
  decodeResultItem: decodeResultItem$1,
  decodeResult: decodeResult$2,
  decodeServerFailure: decodeServerFailure$2,
  mapFutureResult: mapFutureResult$2,
  make: make$2
};

function makeEndpoint$3(shopId) {
  return Env$Pos.gatewayUrl(undefined) + "/printers-hub/" + printersHubName + "/" + shopId + "/default-printer";
}

function encodeBodyJson$1(id, groupId) {
  return Json$Pos.encodeDict(Js_dict.fromArray([[
                    "defaultPrinterDeviceConfig",
                    Json$Pos.encodeDict(Js_dict.fromArray([
                              [
                                "id",
                                Json$Pos.encodeString(id)
                              ],
                              [
                                "groupId",
                                Json$Pos.encodeString(groupId)
                              ]
                            ]))
                  ]]));
}

function mapFutureResult$3(futureResult) {
  return Future.map(futureResult, undefined, (function (result) {
                if (result.TAG === /* Ok */0 && result._0 === "OK") {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
              }));
}

function make$3(shopId, id, groupId) {
  var jwt = Auth$Pos.getJwt(undefined);
  return mapFutureResult$3(jwt !== undefined ? Future.mapError(FuturePromise.fromPromise(fetch(makeEndpoint$3(shopId), {
                                method: "POST",
                                body: Caml_option.some(Json$Pos.stringify(encodeBodyJson$1(id, groupId))),
                                headers: Caml_option.some(new Headers({
                                          "Content-Type": "application/json",
                                          Authorization: "Bearer " + jwt + ""
                                        }))
                              }).then(function (prim) {
                              return prim.text();
                            }).then(function (text) {
                            return Promise.resolve(text);
                          })), undefined, (function (param) {
                      return /* UnexpectedFailure */2;
                    })) : Future.value({
                    TAG: /* Error */1,
                    _0: /* UnexpectedFailure */2
                  }));
}

var SettingsLabelPrinterDefaultSelectionRequest = {
  makeEndpoint: makeEndpoint$3,
  encodeBodyJson: encodeBodyJson$1,
  mapFutureResult: mapFutureResult$3,
  make: make$3
};

var starPrinterUrl = new URL("https://portal.starprinter.online/Dashboard");

var secretTextPlaceholder = "• • • • • • • • • • • • • • • • • • • • • • • • • • •";

function SettingsLabelPrinterPage$SettingsLabelPrinterApiKeyEdit(Props) {
  var editionStageOpt = Props.editionStage;
  var loadingOpt = Props.loading;
  var onSubmit = Props.onSubmit;
  var editionStage = editionStageOpt !== undefined ? editionStageOpt : false;
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var match = React.useState(function () {
        return "";
      });
  var setApiKey = match[1];
  var apiKey = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setEditionModalOpened = match$1[1];
  var editionModalOpened = match$1[0];
  if (editionStage) {
    return React.createElement(Stack$Pos.make, {
                children: null,
                space: "none"
              }, React.createElement(Label$Pos.make, {
                    text: Intl$Pos.t("StarPrinter Online API key")
                  }), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                children: null,
                                alignY: "center"
                              }, React.createElement(TextStyle$Pos.make, {
                                    children: secretTextPlaceholder,
                                    weight: "semibold",
                                    size: "xlarge"
                                  }), React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps("edit_light", 18, "small", Colors$Pos.neutralColor100, Colors$Pos.brandColor60, undefined, undefined, (function (param) {
                                          Curry._1(setEditionModalOpened, (function (param) {
                                                  return true;
                                                }));
                                        }), undefined, undefined, undefined)), React.createElement(Modal$Pos.make, {
                                    children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(InputTextField$Pos.make, {
                                                      label: {
                                                        text: Intl$Pos.t("StarPrinter Online API key")
                                                      },
                                                      placeholder: Intl$Pos.t("Enter API key"),
                                                      autoFocus: editionModalOpened,
                                                      value: apiKey,
                                                      onChange: (function (value) {
                                                          Curry._1(setApiKey, (function (param) {
                                                                  return value;
                                                                }));
                                                        })
                                                    })), "xlarge", undefined, "xlarge", "xmedium", undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                                    opened: editionModalOpened,
                                    title: Intl$Pos.t("Edit StarPrinter Online API key"),
                                    abortButtonText: Intl$Pos.t("Cancel"),
                                    allowCommit: apiKey !== "",
                                    commitButtonText: Intl$Pos.t("Save"),
                                    commitButtonCallback: (function (param) {
                                        Curry._1(onSubmit, apiKey);
                                      }),
                                    onRequestClose: (function (param) {
                                        Curry._1(setEditionModalOpened, (function (param) {
                                                return false;
                                              }));
                                        Curry._1(setApiKey, (function (param) {
                                                return "";
                                              }));
                                      })
                                  }))), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-8)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined)));
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(InputTextField$Pos.make, {
                    label: {
                      text: Intl$Pos.t("StarPrinter Online API key")
                    },
                    placeholder: Intl$Pos.t("Enter API key"),
                    value: apiKey,
                    onChange: (function (value) {
                        Curry._1(setApiKey, (function (param) {
                                return value;
                              }));
                      })
                  }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                children: null,
                                align: "spaceBetween",
                                alignY: "top"
                              }, React.createElement(TextLink$Pos.make, {
                                    text: "StarPrinter Online Dashboard",
                                    to: {
                                      TAG: /* Url */2,
                                      _0: starPrinterUrl
                                    },
                                    openNewTab: true,
                                    highlighted: true,
                                    icon: "external_link"
                                  }), React.createElement(Button$Pos.make, Button$Pos.makeProps("   " + Intl$Pos.t("Save") + "   ", "xsmall", "success", undefined, undefined, loading, apiKey === "", undefined, undefined, undefined, (function (param) {
                                          Curry._1(onSubmit, apiKey);
                                          Curry._1(setApiKey, (function (param) {
                                                  return "";
                                                }));
                                        }), undefined, undefined, undefined)))), undefined, undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  }
}

var SettingsLabelPrinterApiKeyEdit = {
  starPrinterUrl: starPrinterUrl,
  secretTextPlaceholder: secretTextPlaceholder,
  make: SettingsLabelPrinterPage$SettingsLabelPrinterApiKeyEdit
};

var starPrinterUrl$1 = new URL("https://portal.starprinter.online/Dashboard");

function formatPrinterLabel(name, macAddress) {
  if (name === "") {
    return "" + Intl$Pos.t("Anonymous") + " (" + macAddress + ")";
  } else {
    return "" + name + " (" + macAddress + ")";
  }
}

function SettingsLabelPrinterPage$SettingsLabelPrinterDefaultSelection(Props) {
  var loadingOpt = Props.loading;
  var printers = Props.printers;
  var defaultPrinterId = Props.defaultPrinterId;
  var onSubmit = Props.onSubmit;
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var defaultPrinter = Belt_Array.getBy(printers, (function (printer) {
          return printer.id === defaultPrinterId;
        }));
  var match = React.useState(function () {
        return defaultPrinter;
      });
  var setPickedPrinter = match[1];
  var pickedPrinter = match[0];
  if (printers.length !== 1) {
    var items = Belt_Array.map(printers, (function (printer) {
            return {
                    key: printer.id,
                    value: printer,
                    label: formatPrinterLabel(printer.name, printer.macAddress)
                  };
          }));
    return React.createElement(Stack$Pos.make, {
                children: null
              }, React.createElement(Select$Pos.make, {
                    label: Intl$Pos.t("Label printer"),
                    placeholder: Intl$Pos.t("Select a printer"),
                    sections: [{
                        items: items,
                        title: Intl$Pos.t("Printers")
                      }],
                    variation: {
                      NAME: "field",
                      VAL: {
                        required: false
                      }
                    },
                    value: pickedPrinter,
                    onChange: (function (printer) {
                        Curry._1(setPickedPrinter, (function (param) {
                                return printer;
                              }));
                      })
                  }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                children: null,
                                align: "spaceBetween",
                                alignY: "top"
                              }, React.createElement(TextLink$Pos.make, {
                                    text: "StarPrinter Online Dashboard",
                                    to: {
                                      TAG: /* Url */2,
                                      _0: starPrinterUrl$1
                                    },
                                    openNewTab: true,
                                    highlighted: true,
                                    icon: "external_link"
                                  }), React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Save"), "xsmall", "success", undefined, undefined, loading, Belt_Option.isNone(pickedPrinter), undefined, undefined, undefined, (function (param) {
                                          if (pickedPrinter !== undefined) {
                                            return Curry._2(onSubmit, pickedPrinter, printers);
                                          }
                                          
                                        }), undefined, undefined, undefined)))), undefined, undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  }
  var printer = printers[0];
  return React.createElement(Inline$Pos.make, {
              children: null,
              space: "medium"
            }, React.createElement(Title$Pos.make, {
                  children: formatPrinterLabel(printer.name, printer.macAddress),
                  level: 4,
                  weight: "strong"
                }), React.createElement(TextLink$Pos.make, {
                  text: "StarPrinter Online Dashboard",
                  to: {
                    TAG: /* Url */2,
                    _0: starPrinterUrl$1
                  },
                  openNewTab: true,
                  highlighted: true,
                  icon: "external_link"
                }));
}

var SettingsLabelPrinterDefaultSelection = {
  starPrinterUrl: starPrinterUrl$1,
  formatPrinterLabel: formatPrinterLabel,
  make: SettingsLabelPrinterPage$SettingsLabelPrinterDefaultSelection
};

function SettingsLabelPrinterPage$SettingsLabelPrinterDocumentationCard(Props) {
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "medium"
                  }, React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("This is a unique code that you provide to us so that we can access your Star printer's online functions securely.")
                      }), React.createElement(Stack$Pos.make, {
                        children: React.createElement(TextAction$Pos.make, {
                              text: Intl$Pos.t("Consult the tutorial dedicated to Star printer configuration and StarPrinter Online"),
                              highlighted: true,
                              onPress: (function (param) {
                                  HelpCenter$Pos.showArticle(HelpCenter$Pos.setupLabelPrinterAndStarPrinterOnline);
                                })
                            }),
                        space: "small"
                      })),
              title: Intl$Pos.t("What is an API key?")
            });
}

var SettingsLabelPrinterDocumentationCard = {
  make: SettingsLabelPrinterPage$SettingsLabelPrinterDocumentationCard
};

function SettingsLabelPrinterPage(Props) {
  var match = React.useState(function () {
        return /* NotAsked */0;
      });
  var setPrintersResult = match[1];
  var printersResult = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setDefaultPrinterId = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setNotification = match$2[1];
  var notification = match$2[0];
  var shops = Auth$Pos.useShops(undefined);
  var activeShop = Belt_Option.getWithDefault(Auth$Pos.useActiveShop(undefined), shops[0]);
  var match$3 = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = match$3.TAG === /* Organisation */0 ? true : false;
  var requestPrintersList = function (param) {
    return Future.tap(make(activeShop.id), (function (result) {
                  if (result.TAG === /* Ok */0) {
                    var printers = result._0;
                    return Curry._1(setPrintersResult, (function (param) {
                                  return {
                                          TAG: /* Done */1,
                                          _0: {
                                            TAG: /* Ok */0,
                                            _0: printers
                                          }
                                        };
                                }));
                  }
                  var error = result._0;
                  if (error !== 1) {
                    return Curry._1(setNotification, (function (param) {
                                  return {
                                          TAG: /* Error */1,
                                          _0: errorMessageFromError(error)
                                        };
                                }));
                  } else {
                    return Curry._1(setPrintersResult, (function (param) {
                                  return {
                                          TAG: /* Done */1,
                                          _0: {
                                            TAG: /* Ok */0,
                                            _0: undefined
                                          }
                                        };
                                }));
                  }
                }));
  };
  React.useEffect((function () {
          if (printersResult !== 0) {
            return ;
          }
          var executedPrintersListFuture = requestPrintersList(undefined);
          var defaultPrinterFuture = make$1(activeShop.id);
          Future.tapOk(defaultPrinterFuture, (function (id) {
                  Curry._1(setDefaultPrinterId, (function (param) {
                          return id;
                        }));
                }));
          return (function (param) {
                    Future.cancel(executedPrintersListFuture);
                    Future.cancel(defaultPrinterFuture);
                  });
        }), [printersResult]);
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(setNotification, (function (param) {
                  
                }));
          Curry._1(setPrintersResult, (function (param) {
                  return /* Loading */1;
                }));
          var executedFuture = requestPrintersList(undefined);
          return (function (param) {
                    Future.cancel(executedFuture);
                  });
        }), [activeShop]);
  var handleApiKeyChange = function (apiKey) {
    var printers = AsyncData$Pos.getWithDefault(printersResult, {
          TAG: /* Ok */0,
          _0: undefined
        });
    Curry._1(setPrintersResult, (function (param) {
            return {
                    TAG: /* Reloading */0,
                    _0: printers
                  };
          }));
    Future.get(Future.flatMapOk(make$2(activeShop.id, apiKey), undefined, (function (printers) {
                if (printers.length !== 1) {
                  return Future.value({
                              TAG: /* Ok */0,
                              _0: printers
                            });
                }
                var printer = printers[0];
                return Future.mapError(Future.mapOk(make$3(activeShop.id, printer.id, printer.groupId), undefined, (function (param) {
                                  return printers;
                                })), undefined, (function (param) {
                              return /* UnexpectedFailure */2;
                            }));
              })), (function (result) {
            if (result.TAG === /* Ok */0) {
              var latestPrinters = result._0;
              Curry._1(setPrintersResult, (function (param) {
                      return {
                              TAG: /* Done */1,
                              _0: {
                                TAG: /* Ok */0,
                                _0: latestPrinters
                              }
                            };
                    }));
              return Curry._1(setNotification, (function (param) {
                            return {
                                    TAG: /* Ok */0,
                                    _0: Intl$Pos.t("The printer has been successfully registered.")
                                  };
                          }));
            }
            var error = result._0;
            Curry._1(setPrintersResult, (function (param) {
                    return {
                            TAG: /* Done */1,
                            _0: printers
                          };
                  }));
            Curry._1(setNotification, (function (param) {
                    return {
                            TAG: /* Error */1,
                            _0: errorMessageFromError(error)
                          };
                  }));
          }));
  };
  var handleDefaultPrinterSelection = function (printer, printers) {
    Curry._1(setPrintersResult, (function (param) {
            return {
                    TAG: /* Reloading */0,
                    _0: {
                      TAG: /* Ok */0,
                      _0: printers
                    }
                  };
          }));
    Future.get(Future.flatMap(make$3(activeShop.id, printer.id, printer.groupId), undefined, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return make$1(activeShop.id);
                } else {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: /* UnexpectedFailure */2
                            });
                }
              })), (function (result) {
            if (result.TAG === /* Ok */0) {
              var defaultPrinterId = result._0;
              Curry._1(setDefaultPrinterId, (function (param) {
                      return defaultPrinterId;
                    }));
              Curry._1(setNotification, (function (param) {
                      return {
                              TAG: /* Ok */0,
                              _0: Intl$Pos.t("The default printer has been successfully registered.")
                            };
                    }));
            } else {
              var error = result._0;
              Curry._1(setNotification, (function (param) {
                      return {
                              TAG: /* Error */1,
                              _0: errorMessageFromError(error)
                            };
                    }));
            }
            Curry._1(setPrintersResult, (function (param) {
                    return {
                            TAG: /* Done */1,
                            _0: {
                              TAG: /* Ok */0,
                              _0: printers
                            }
                          };
                  }));
          }));
  };
  var renderHeaderActions = function (param) {
    if (organisationAccount) {
      return React.createElement(Inline$Pos.make, {
                  children: React.createElement(Auth$Pos.SelectSingleShop.make, {
                        value: activeShop,
                        variation: {
                          NAME: "filter",
                          VAL: {
                            hideLabelColon: false
                          }
                        }
                      })
                });
    } else {
      return null;
    }
  };
  var notificationBanner;
  if (notification !== undefined) {
    var tmp;
    tmp = notification.TAG === /* Ok */0 ? ({
          TAG: /* Success */0,
          _0: notification._0
        }) : ({
          TAG: /* Danger */1,
          _0: notification._0
        });
    notificationBanner = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                      textStatus: tmp,
                      onRequestClose: (function (param) {
                          Curry._1(setNotification, (function (param) {
                                  
                                }));
                        })
                    })), undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  } else {
    notificationBanner = null;
  }
  var tmp$1;
  var exit = 0;
  var printers;
  if (typeof printersResult === "number") {
    exit = 3;
  } else if (printersResult.TAG === /* Reloading */0) {
    var match$4 = printersResult._0;
    if (match$4.TAG === /* Ok */0) {
      var printers$1 = match$4._0;
      if (printers$1 !== undefined) {
        printers = printers$1;
        exit = 2;
      } else {
        exit = 1;
      }
    } else {
      exit = 3;
    }
  } else {
    var match$5 = printersResult._0;
    if (match$5.TAG === /* Ok */0) {
      var printers$2 = match$5._0;
      if (printers$2 !== undefined) {
        printers = printers$2;
        exit = 2;
      } else {
        exit = 1;
      }
    } else {
      tmp$1 = React.createElement(Placeholder$Pos.make, {
            status: /* Error */2
          });
    }
  }
  switch (exit) {
    case 1 :
        var loading = AsyncData$Pos.isBusy(printersResult);
        tmp$1 = React.createElement(SettingsLabelPrinterPage$SettingsLabelPrinterApiKeyEdit, {
              loading: loading,
              onSubmit: handleApiKeyChange
            });
        break;
    case 2 :
        var loading$1 = AsyncData$Pos.isBusy(printersResult);
        tmp$1 = React.createElement(Stack$Pos.make, {
              children: null,
              space: "xnormal"
            }, React.createElement(SettingsLabelPrinterPage$SettingsLabelPrinterApiKeyEdit, {
                  editionStage: true,
                  loading: loading$1,
                  onSubmit: handleApiKeyChange
                }), React.createElement(SettingsLabelPrinterPage$SettingsLabelPrinterDefaultSelection, {
                  loading: loading$1,
                  printers: printers,
                  defaultPrinterId: match$1[0],
                  onSubmit: handleDefaultPrinterSelection
                }));
        break;
    case 3 :
        tmp$1 = React.createElement(Placeholder$Pos.make, {
              status: /* Loading */0
            });
        break;
    
  }
  return React.createElement(Page$Pos.make, {
              children: null,
              variation: "compact",
              title: Intl$Pos.t("Label printer"),
              renderHeaderActions: renderHeaderActions
            }, notificationBanner, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                              children: null,
                              space: "xlarge"
                            }, React.createElement(FieldsetLayoutPanel$Pos.make, {
                                  title: Intl$Pos.t("Configuration"),
                                  description: Intl$Pos.t("To connect your printer, enter the StarPrinter Online API key."),
                                  children: tmp$1
                                }), React.createElement(Columns$Pos.make, {
                                  children: null,
                                  space: "xlarge"
                                }, React.createElement(Column$Pos.make, {
                                      children: null,
                                      width: "quarter"
                                    }), React.createElement(Column$Pos.make, {
                                      children: React.createElement(SettingsLabelPrinterPage$SettingsLabelPrinterDocumentationCard, {}),
                                      width: "three_quarter"
                                    })))), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var make$4 = React.memo(SettingsLabelPrinterPage);

export {
  printersHubName ,
  errorMessageFromError ,
  SettingsLabelPrinterListRequest ,
  SettingsLabelDefaultPrinterIdRequest ,
  SettingsLabelPrinterHubSetupRequest ,
  SettingsLabelPrinterDefaultSelectionRequest ,
  SettingsLabelPrinterApiKeyEdit ,
  SettingsLabelPrinterDefaultSelection ,
  SettingsLabelPrinterDocumentationCard ,
  make$4 as make,
}
/* starPrinterUrl Not a pure module */
