// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";

function StockActivitySourceTableCell(Props) {
  var deviceName = Props.deviceName;
  var shopName = Props.shopName;
  return React.createElement(Stack$Pos.make, {
              children: null,
              space: Belt_Option.isSome(shopName) ? "xxsmall" : "none"
            }, React.createElement(TextStyle$Pos.make, {
                  children: deviceName
                }), shopName !== undefined ? React.createElement(TextStyle$Pos.make, {
                    children: shopName,
                    variation: "normal",
                    size: "tiny"
                  }) : null);
}

var make = React.memo(StockActivitySourceTableCell);

export {
  make ,
}
/* make Not a pure module */
