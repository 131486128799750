// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";

function AuthLogoutPage(Props) {
  var auth = Auth$Pos.useState(undefined);
  var unlogUser = Auth$Pos.useUnlogUser(undefined);
  React.useEffect((function () {
          if (typeof auth !== "number" && auth.TAG !== /* Logging */0) {
            Curry._1(unlogUser, undefined);
          }
          
        }), [auth]);
  return null;
}

var make = React.memo(AuthLogoutPage);

export {
  make ,
}
/* make Not a pure module */
