// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as AdminShopListPage$Pos from "./AdminShopListPage.bs.js";
import * as AdminImpersonationPage$Pos from "./AdminImpersonationPage.bs.js";

var baseRoute = "/admin";

var impersonationRoute = baseRoute + "/impersonation";

var shopsRoute = baseRoute + "/shops";

function AdminRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var appIndexRoute = Props.appIndexRoute;
  if (subUrlPath) {
    switch (subUrlPath.hd) {
      case "impersonation" :
          if (!subUrlPath.tl) {
            return React.createElement(AdminImpersonationPage$Pos.make, {});
          }
          break;
      case "shops" :
          if (!subUrlPath.tl) {
            return React.createElement(AdminShopListPage$Pos.make, {});
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation$Pos.Redirect.make, {
              route: appIndexRoute
            });
}

var make = React.memo(AdminRouter);

export {
  baseRoute ,
  impersonationRoute ,
  shopsRoute ,
  make ,
}
/* make Not a pure module */
