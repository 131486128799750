// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as PromotionEditActions$Pos from "./PromotionEditActions.bs.js";
import * as PromotionStatusBadge$Pos from "./PromotionStatusBadge.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

function keyExtractor(row) {
  return row.id;
}

var Row = {
  keyExtractor: keyExtractor
};

function tableColumns(editing, promotionCreated) {
  return [
          {
            key: "shopName",
            name: Intl$Pos.t("Shop"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 150
              }
            },
            render: (function (param) {
                return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                        children: param.data.shopName
                                      })), undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
              })
          },
          {
            key: "status",
            name: Intl$Pos.t("Status"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var status = param.data.status;
                if (status !== undefined) {
                  return React.createElement(PromotionStatusBadge$Pos.make, {
                              status: status
                            });
                } else {
                  return null;
                }
              })
          },
          {
            key: "action",
            layout: {
              hidden: !promotionCreated || editing,
              width: {
                NAME: "fr",
                VAL: 0
              },
              alignX: "flexEnd"
            },
            render: (function (param) {
                var match = param.data;
                var status = match.status;
                if (status !== undefined) {
                  return React.createElement(PromotionEditActions$Pos.make, {
                              variation: "menuItem",
                              id: match.id,
                              status: status
                            });
                } else {
                  return null;
                }
              })
          }
        ];
}

var Raw = {};

var query = Client.gql(["query PricesQuery($after: String)  {\nprices(first: 50, after: $after)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.prices;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          prices: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.name;
          var value$6 = value$1.id;
          var value$7 = value$1.__typename;
          var node = {
            __typename: value$7,
            id: value$6,
            name: value$5,
            shop: shop
          };
          var value$8 = value.__typename;
          return {
                  __typename: value$8,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var prices = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          prices: prices
        };
}

function serializeVariables(inp) {
  var a = inp.after;
  return {
          after: a !== undefined ? a : undefined
        };
}

function makeVariables(after, param) {
  return {
          after: after
        };
}

function makeDefaultVariables(param) {
  return {
          after: undefined
        };
}

var PricesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var PricesQuery_refetchQueryDescription = include.refetchQueryDescription;

var PricesQuery_use = include.use;

var PricesQuery_useLazy = include.useLazy;

var PricesQuery_useLazyWithVariables = include.useLazyWithVariables;

var PricesQuery = {
  PricesQuery_inner: PricesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: PricesQuery_refetchQueryDescription,
  use: PricesQuery_use,
  useLazy: PricesQuery_useLazy,
  useLazyWithVariables: PricesQuery_useLazyWithVariables
};

function runScanPrices(pricesFetch, cursor, dataOpt, onDone, param) {
  var data = dataOpt !== undefined ? dataOpt : [];
  Future.mapOk(FuturePromise.fromPromise(Curry._1(pricesFetch, cursor)), undefined, (function (response) {
          if (response.TAG !== /* Ok */0) {
            return Curry._1(onDone, /* Error */1);
          }
          var match = response._0;
          var match$1 = match.data.prices;
          var match$2 = match$1.pageInfo;
          var match$3 = match$2.hasNextPage;
          if (match$3 !== undefined && match$3) {
            if (match.error !== undefined) {
              return Curry._1(onDone, /* Error */1);
            } else {
              return runScanPrices(pricesFetch, match$2.endCursor, Belt_Array.concat(data, match$1.edges), onDone, undefined);
            }
          }
          if (match.error !== undefined) {
            return Curry._1(onDone, /* Error */1);
          } else {
            return Curry._1(onDone, /* Success */{
                        _0: Belt_Array.concat(data, match$1.edges)
                      });
          }
        }));
}

function makeEligibleShopsFromPrices(prices, shops, selectedPriceName) {
  return Belt_Array.keepMap(shops, (function (shop) {
                var match = Belt_Array.getBy(prices, (function (param) {
                        var price = param.node;
                        if (price.shop.id === shop.id && price.name === selectedPriceName) {
                          return shop.kind !== "WAREHOUSE";
                        } else {
                          return false;
                        }
                      }));
                if (match !== undefined) {
                  return {
                          id: Uuid.v4(),
                          creatorIdentifier: "",
                          shopId: shop.id,
                          shopName: shop.name,
                          priceId: match.node.id,
                          status: undefined,
                          selected: false
                        };
                }
                
              }));
}

function PromotionEditFormManagementTableCard(Props) {
  var editing = Props.editing;
  var promotionCreated = Props.promotionCreated;
  var campaigns = Props.campaigns;
  var selectedPriceName = Props.selectedPriceName;
  var onRequestShopsUpdate = Props.onRequestShopsUpdate;
  var shops = Auth$Pos.useShops(undefined);
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setStatus = match[1];
  var status = match[0];
  React.useEffect((function () {
          var pricesFetch = function (after) {
            return Curry._6(apolloClient.rescript_query, {
                        query: query,
                        Raw: Raw,
                        parse: parse,
                        serialize: serialize,
                        serializeVariables: serializeVariables
                      }, undefined, undefined, /* NetworkOnly */2, undefined, {
                        after: after
                      });
          };
          runScanPrices(pricesFetch, undefined, undefined, (function (result) {
                  Curry._1(setStatus, (function (param) {
                          return result;
                        }));
                }), undefined);
          return (function (param) {
                    Curry._1(onRequestShopsUpdate, []);
                  });
        }), []);
  var columns = React.useMemo((function () {
          return tableColumns(editing, promotionCreated);
        }), [
        promotionCreated,
        editing
      ]);
  var rows = React.useMemo((function () {
          if (typeof status === "number" || promotionCreated || campaigns.length !== 0) {
            return campaigns;
          } else {
            return makeEligibleShopsFromPrices(status._0, shops, selectedPriceName);
          }
        }), [
        status,
        campaigns
      ]);
  var onSelectChange = React.useCallback((function (selectedRowsKeys) {
          var tmp;
          if (selectedRowsKeys) {
            var keys = selectedRowsKeys._0;
            tmp = Belt_Array.map(rows, (function (campaign) {
                    if (Belt_Array.some(keys, (function (key) {
                              return key === campaign.id;
                            }))) {
                      return {
                              id: campaign.id,
                              creatorIdentifier: campaign.creatorIdentifier,
                              shopId: campaign.shopId,
                              shopName: campaign.shopName,
                              priceId: campaign.priceId,
                              status: campaign.status,
                              selected: true
                            };
                    } else {
                      return {
                              id: campaign.id,
                              creatorIdentifier: campaign.creatorIdentifier,
                              shopId: campaign.shopId,
                              shopName: campaign.shopName,
                              priceId: campaign.priceId,
                              status: campaign.status,
                              selected: false
                            };
                    }
                  }));
          } else {
            tmp = Belt_Array.map(rows, (function (campaign) {
                    return {
                            id: campaign.id,
                            creatorIdentifier: campaign.creatorIdentifier,
                            shopId: campaign.shopId,
                            shopName: campaign.shopName,
                            priceId: campaign.priceId,
                            status: campaign.status,
                            selected: true
                          };
                  }));
          }
          Curry._1(onRequestShopsUpdate, tmp);
        }), [rows]);
  var match$1 = Auth$Pos.useScope(undefined);
  var tmp;
  tmp = match$1.TAG === /* Organisation */0 ? undefined : Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined));
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Card$Pos.make, {
                          children: null,
                          title: Intl$Pos.t("Campaign management by shop"),
                          variation: "table"
                        }, promotionCreated ? null : React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                            children: Intl$Pos.t("Once the draft is saved, you will no longer be able to edit the selection."),
                                            variation: "normal"
                                          })), "large", undefined, "small", "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), rows.length === 0 ? React.createElement(Placeholder$Pos.make, {
                                status: /* Loading */0
                              }) : React.createElement(TableView$Pos.make, {
                                data: {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Ok */0,
                                    _0: rows
                                  }
                                },
                                columns: columns,
                                keyExtractor: keyExtractor,
                                selectionEnabled: !promotionCreated,
                                initialAllSelected: !promotionCreated,
                                maxHeight: 292,
                                onSelectChange: onSelectChange
                              }))), tmp, undefined, undefined, undefined));
}

var make = React.memo(PromotionEditFormManagementTableCard, (function (oldProps, newProps) {
        if (Caml_obj.equal(oldProps.campaigns, newProps.campaigns) && oldProps.editing === newProps.editing && oldProps.promotionCreated === newProps.promotionCreated) {
          return oldProps.selectedPriceName === newProps.selectedPriceName;
        } else {
          return false;
        }
      }));

export {
  Row ,
  tableColumns ,
  PricesQuery ,
  runScanPrices ,
  makeEligibleShopsFromPrices ,
  make ,
}
/* query Not a pure module */
