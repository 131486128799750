// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ListBox$Pos from "./ListBox.bs.js";
import * as Popover$Pos from "../overlays/Popover.bs.js";
import * as ReactStately from "react-stately";
import * as InputTextField$Pos from "./InputTextField.bs.js";

function SearchComboBoxField(Props) {
  var label = Props.label;
  var disabledOpt = Props.disabled;
  var loadingOpt = Props.loading;
  var placeholder = Props.placeholder;
  var errorMessage = Props.errorMessage;
  var sectionTitleOpt = Props.sectionTitle;
  var noResultLabelOpt = Props.noResultLabel;
  var showResultsOpt = Props.showResults;
  var items = Props.items;
  var inputValue = Props.value;
  var onInputChange = Props.onInputChange;
  var onSelectionChange = Props.onSelectionChange;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var sectionTitle = sectionTitleOpt !== undefined ? sectionTitleOpt : Intl$Pos.t("Search results");
  var noResultLabel = noResultLabelOpt !== undefined ? noResultLabelOpt : Intl$Pos.t("No result found");
  var showResults = showResultsOpt !== undefined ? showResultsOpt : true;
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  var focused = match[0];
  var triggerRef = React.useRef(null);
  var inputRef = React.useRef(null);
  var popoverRef = React.useRef(null);
  var listBoxRef = React.useRef(null);
  var showResults$1 = showResults && !loading;
  var children = React.useMemo((function () {
          if (showResults$1) {
            return [React.createElement(ReactStately.Section, {
                          children: Belt_Array.map(items, (function (item) {
                                  return React.createElement(ReactStately.Item, {
                                              children: React.createElement("span", undefined, item.label),
                                              textValue: item.label,
                                              key: item.key
                                            });
                                })),
                          title: sectionTitle
                        })];
          } else {
            return [];
          }
        }), [
        items,
        sectionTitle,
        showResults$1
      ]);
  var onFocusChange = React.useCallback((function (focused) {
          Curry._1(setFocused, (function (param) {
                  return focused;
                }));
        }), []);
  var onSelectionChange$1 = React.useCallback((function (key) {
          Belt_Option.forEach(Belt_Option.flatMap((key == null) ? undefined : Caml_option.some(key), (function (key) {
                      return Belt_Array.getBy(items, (function (item) {
                                    return item.key === key;
                                  }));
                    })), onSelectionChange);
        }), [items]);
  var props_aria$label = label.text;
  var props_onInputChange = onInputChange;
  var props_onSelectionChange = onSelectionChange$1;
  var props_onFocusChange = onFocusChange;
  var props = {
    children: children,
    "aria-label": props_aria$label,
    isDisabled: disabled,
    placeholder: placeholder,
    allowsCustomValue: true,
    menuTrigger: "input",
    inputValue: inputValue,
    onInputChange: props_onInputChange,
    allowsEmptyCollection: false,
    onSelectionChange: props_onSelectionChange,
    onFocusChange: props_onFocusChange
  };
  var state = ReactStately.useComboBoxState(props);
  var newrecord = Caml_obj.obj_dup(props);
  var match$1 = ReactAria.useComboBox((newrecord.popoverRef = Caml_option.some(popoverRef), newrecord.listBoxRef = Caml_option.some(listBoxRef), newrecord.inputRef = Caml_option.some(inputRef), newrecord), state);
  React.useEffect((function () {
          if (focused && state.inputValue !== "") {
            setTimeout((function (param) {
                    Curry._1(state.close, undefined);
                  }), 5);
          }
          
        }), [state.selectedKey]);
  var onRequestClearSearch = React.useCallback((function (param) {
          if (state.inputValue !== "") {
            return Curry._1(state.setInputValue, "");
          }
          
        }), [state.inputValue]);
  var errored = Belt_Option.isSome(errorMessage);
  var openedList = state.isOpen && state.inputValue !== "";
  var tmp = {
    variation: {
      NAME: "search",
      VAL: {
        loading: loading,
        onRequestClear: onRequestClearSearch
      }
    },
    label: label,
    disabled: disabled,
    focused: focused,
    containerRef: triggerRef,
    inputRef: inputRef,
    ariaProps: match$1.inputProps
  };
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (errorMessage !== undefined) {
    tmp.errorMessage = errorMessage;
  }
  var tmp$1;
  if (openedList) {
    var tmp$2 = {
      children: React.createElement(ListBox$Pos.make, {
            domRef: listBoxRef,
            state: state,
            props: match$1.listBoxProps,
            noResultLabel: noResultLabel
          }),
      popoverRef: popoverRef,
      triggerRef: triggerRef,
      state: {
        isOpen: state.isOpen,
        close: state.close,
        toggle: state.toggle
      },
      layout: "triggerStrictWidth",
      modal: false
    };
    var tmp$3 = errored ? -16 : undefined;
    if (tmp$3 !== undefined) {
      tmp$2.offset = tmp$3;
    }
    tmp$1 = React.createElement(Popover$Pos.make, tmp$2);
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(InputTextField$Pos.make, tmp), tmp$1);
}

var make = SearchComboBoxField;

export {
  make ,
}
/* react Not a pure module */
