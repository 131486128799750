// Generated by ReScript, PLEASE EDIT WITH CARE


function Column(Props) {
  var children = Props.children;
  return children;
}

var make = Column;

export {
  make ,
}
/* No side effect */
