// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as CatalogProductNoteCard$Pos from "../../modules/Catalog/CatalogProductNoteCard.bs.js";
import * as CatalogProductPageActions$Pos from "../../modules/Catalog/CatalogProductPageActions.bs.js";
import * as CatalogProductStatusBadge$Pos from "../../modules/Catalog/CatalogProductStatusBadge.bs.js";
import * as CatalogProductInformationCard$Pos from "../../modules/Catalog/CatalogProductInformationCard.bs.js";
import * as CatalogProductStatusTableCard$Pos from "../../modules/Catalog/CatalogProductStatusTableCard.bs.js";
import * as CatalogProductVariantTableCard$Pos from "../../modules/Catalog/CatalogProductVariantTableCard.bs.js";
import * as CatalogProductOrganisationTableCard$Pos from "../../modules/Catalog/CatalogProductOrganisationTableCard.bs.js";

function CatalogProductPage$AuthSelectShop(Props) {
  var productShopIds = Props.productShopIds;
  var shops = Auth$Pos.useShops(undefined);
  var disabledIds = Belt_Array.keepMap(shops, (function (param) {
          var id = param.id;
          var match = Belt_Array.getBy(productShopIds, (function (current) {
                  return current === id;
                }));
          if (match !== undefined) {
            return ;
          } else {
            return id;
          }
        }));
  return React.createElement(Auth$Pos.SelectShop.make, {
              disabledIds: disabledIds
            });
}

var AuthSelectShop = {
  make: CatalogProductPage$AuthSelectShop
};

function CatalogProductPage(Props) {
  var cku = Props.cku;
  var productShopIds = Props.productShopIds;
  var shopsProduct = Props.shopsProduct;
  var shopsProductVariants = Props.shopsProductVariants;
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var notifier = Notifier$Pos.use(undefined, undefined);
  var scope = Auth$Pos.useScope(undefined);
  var someProduct = shopsProduct.length !== 0;
  var renderActions = function (param) {
    var match = Belt_Array.get(shopsProduct, 0);
    var match$1 = Belt_Array.get(shopsProductVariants, 0);
    if (scope.TAG === /* Organisation */0) {
      return null;
    }
    if (match === undefined) {
      return null;
    }
    if (match$1 === undefined) {
      return null;
    }
    var allVariantsArchived = Belt_Array.every(match$1, (function (variant) {
            return variant.status === /* Archived */2;
          }));
    return React.createElement(CatalogProductPageActions$Pos.make, {
                cku: cku,
                id: match.id,
                status: match.status,
                allVariantsArchived: allVariantsArchived
              });
  };
  var renderHeaderActions;
  if (scope.TAG === /* Organisation */0) {
    var shops = scope.shops;
    renderHeaderActions = (function (param) {
        var match = Belt_Array.get(shopsProduct, 0);
        var match$1 = Belt_Array.get(shopsProductVariants, 0);
        var tmp;
        if (match !== undefined && match$1 !== undefined) {
          var allVariantsArchived = Belt_Array.every(match$1, (function (variant) {
                  return variant.status === /* Archived */2;
                }));
          tmp = React.createElement(CatalogProductPageActions$Pos.make, {
                cku: cku,
                id: match.id,
                status: match.status,
                allVariantsArchived: allVariantsArchived
              });
        } else {
          tmp = null;
        }
        return React.createElement(Inline$Pos.make, {
                    children: null,
                    align: "spaceBetween",
                    grow: true
                  }, React.createElement(Inline$Pos.make, {
                        children: null,
                        space: "normal"
                      }, shops.length < 6 ? React.createElement(CatalogProductPage$AuthSelectShop, {
                              productShopIds: productShopIds
                            }) : null, someProduct ? null : React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("No product association to this shop has been recorded."),
                              variation: "negative"
                            })), tmp);
      });
  } else {
    renderHeaderActions = undefined;
  }
  var pageTitle = Belt_Option.mapWithDefault(Belt_Array.get(shopsProduct, 0), "", (function (param) {
          return param.name;
        }));
  var renderTitleEnd = function (param) {
    var match = Belt_Array.get(shopsProduct, 0);
    var product;
    if (match === undefined) {
      return null;
    }
    if (scope.TAG === /* Organisation */0) {
      if (scope.activeShop === undefined) {
        return null;
      }
      product = match;
    } else {
      product = match;
    }
    return React.createElement(CatalogProductStatusBadge$Pos.make, {
                value: product.status
              });
  };
  var tmp;
  if (someProduct) {
    var tmp$1;
    tmp$1 = scope.TAG === /* Organisation */0 && scope.activeShop === undefined ? React.createElement(CatalogProductStatusTableCard$Pos.make, {
            shopsProduct: shopsProduct
          }) : null;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Notifier$Pos.Banner.make, {
              notifier: notifier
            }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                          children: React.createElement(Columns$Pos.make, {
                                children: null,
                                space: "large"
                              }, React.createElement(Column$Pos.make, {
                                    children: React.createElement(Stack$Pos.make, {
                                          children: null,
                                          space: "large"
                                        }, React.createElement(CatalogProductInformationCard$Pos.make, {
                                              product: Belt_Array.getExn(shopsProduct, 0)
                                            }), React.createElement(CatalogProductOrganisationTableCard$Pos.make, {
                                              shopsProduct: shopsProduct
                                            }), tmp$1, React.createElement(CatalogProductNoteCard$Pos.make, {
                                              productId: Belt_Option.map(Belt_Array.getBy(shopsProduct, (function (product) {
                                                          return product.shopId === Belt_Option.map(activeShop, (function (param) {
                                                                        return param.id;
                                                                      }));
                                                        })), (function (product) {
                                                      return product.id;
                                                    })),
                                              internalNote: Belt_Option.mapWithDefault(Belt_Array.getBy(shopsProduct, (function (product) {
                                                          return product.shopId === Belt_Option.map(activeShop, (function (param) {
                                                                        return param.id;
                                                                      }));
                                                        })), "", (function (product) {
                                                      return product.internalNote;
                                                    }))
                                            })),
                                    width: "one_third"
                                  }), React.createElement(Column$Pos.make, {
                                    children: React.createElement(CatalogProductVariantTableCard$Pos.make, {
                                          shopsProductVariants: shopsProductVariants
                                        }),
                                    width: "three_quarter"
                                  })),
                          space: "medium"
                        })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  } else {
    tmp = null;
  }
  var tmp$2 = {
    children: tmp,
    title: pageTitle,
    renderTitleEnd: renderTitleEnd,
    renderActions: renderActions
  };
  if (renderHeaderActions !== undefined) {
    tmp$2.renderHeaderActions = Caml_option.valFromOption(renderHeaderActions);
  }
  return React.createElement(Page$Pos.make, tmp$2);
}

var make = React.memo(CatalogProductPage);

var Config;

export {
  Config ,
  AuthSelectShop ,
  make ,
}
/* make Not a pure module */
