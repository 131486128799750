// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function kindToHeaderText(kind) {
  switch (kind) {
    case /* Transport */0 :
        return Intl$Pos.t("Transport U. fees");
    case /* Taxes */1 :
        return Intl$Pos.t("Taxes U. fees");
    case /* Other */2 :
        return Intl$Pos.t("Other U. fees");
    
  }
}

function getFeesRowData(productFees, cartFees) {
  if (cartFees !== undefined) {
    return Belt_Array.map(cartFees, (function (cartFee) {
                  var match_ = Belt_Array.getBy(productFees, (function (fee) {
                          return fee.kind === cartFee.kind;
                        }));
                  if (match_ !== undefined) {
                    return Belt_Option.getWithDefault(match_.formattedAmount, "");
                  } else {
                    return "";
                  }
                }));
  } else {
    return [""];
  }
}

function formattedTaxRates(productTaxes) {
  return Belt_Array.reduce(productTaxes, "", (function (acc, tax) {
                return acc + ((
                          acc !== "" ? ", " : ""
                        ) + (tax.rate.toFixed(2) + "%"));
              }));
}

export {
  kindToHeaderText ,
  getFeesRowData ,
  formattedTaxRates ,
}
/* Intl-Pos Not a pure module */
