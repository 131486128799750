// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as IconButton$Pos from "../actions/IconButton.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

function isButtonDisabled(action, currentPage, totalPages) {
  if (action >= 2) {
    return currentPage >= totalPages;
  } else {
    return currentPage <= 1;
  }
}

var styles = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-7)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function Pagination$ControlButton(Props) {
  var currentPage = Props.currentPage;
  var totalPages = Props.totalPages;
  var action = Props.action;
  var onPress = Props.onPress;
  var disabled = isButtonDisabled(action, currentPage, totalPages);
  var tmp;
  switch (action) {
    case /* First */0 :
        tmp = "double_arrow_left_light";
        break;
    case /* Prev */1 :
        tmp = "arrow_left_light";
        break;
    case /* Next */2 :
        tmp = "arrow_right_light";
        break;
    case /* Last */3 :
        tmp = "double_arrow_right_light";
        break;
    
  }
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps(tmp, undefined, "small", disabled ? Colors$Pos.neutralColor20 : Colors$Pos.neutralColor90, undefined, undefined, disabled, (function (param) {
                                Curry._1(onPress, action);
                              }), undefined, undefined, undefined))), Caml_option.some(styles.view), undefined, undefined, undefined));
}

var make = React.memo(Pagination$ControlButton);

var styles$1 = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(28)), "center", undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(1)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(28)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      disabledView: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor10, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      text: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor30, undefined, FontSizes$Pos.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      disabledText: Style$Pos.merge([
            FontFaces$Pos.libreFranklinBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor90, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function viewStyleFromParams(disabled) {
  return Style$Pos.arrayOptionStyle([
              Caml_option.some(styles$1.view),
              disabled ? Caml_option.some(styles$1.disabledView) : undefined
            ]);
}

function textStyleFromParams(disabled) {
  return Style$Pos.arrayOptionStyle([
              Caml_option.some(styles$1.text),
              disabled ? Caml_option.some(styles$1.disabledText) : undefined
            ]);
}

function Pagination$PageButton(Props) {
  var currentPage = Props.currentPage;
  var totalPages = Props.totalPages;
  var action = Props.action;
  var children = Props.children;
  var onPress = Props.onPress;
  var disabled = action !== undefined ? isButtonDisabled(action, currentPage, totalPages) : true;
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Text$Pos.make, Text$Pos.makeProps(children, Caml_option.some(textStyleFromParams(disabled)), undefined, undefined, undefined))), Caml_option.some(viewStyleFromParams(disabled)), undefined, undefined, undefined)), undefined, disabled, undefined, undefined, undefined, (function (param) {
                    if (action !== undefined) {
                      return Curry._1(onPress, action);
                    }
                    
                  }), undefined, undefined, undefined));
}

var make$1 = React.memo(Pagination$PageButton);

var styles$2 = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor00, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "solid", Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(64)), "spaceBetween", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.large)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      label: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor30, undefined, FontSizes$Pos.small, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function Pagination(Props) {
  var currentPage = Props.currentPage;
  var totalPages = Props.totalPages;
  var onPress = Props.onRequestPaginate;
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$2.container), undefined, undefined, undefined), React.createElement(Text$Pos.make, Text$Pos.makeProps("Page " + (String(currentPage) + (Intl$Pos.t(" of ") + String(totalPages))), Caml_option.some(styles$2.label), undefined, undefined, undefined)), React.createElement(Inline$Pos.make, {
                  children: null
                }, React.createElement(make, {
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* First */0,
                      onPress: onPress
                    }), React.createElement(make, {
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* Prev */1,
                      onPress: onPress
                    }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                  children: null
                                }, currentPage > 1 ? React.createElement(make$1, {
                                        currentPage: currentPage,
                                        totalPages: totalPages,
                                        action: /* Prev */1,
                                        children: currentPage - 1 | 0,
                                        onPress: onPress
                                      }) : null, React.createElement(make$1, {
                                      currentPage: currentPage,
                                      totalPages: totalPages,
                                      children: currentPage,
                                      onPress: onPress
                                    }), currentPage < totalPages ? React.createElement(make$1, {
                                        currentPage: currentPage,
                                        totalPages: totalPages,
                                        action: /* Next */2,
                                        children: currentPage + 1 | 0,
                                        onPress: onPress
                                      }) : null)), "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(make, {
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* Next */2,
                      onPress: onPress
                    }), React.createElement(make, {
                      currentPage: currentPage,
                      totalPages: totalPages,
                      action: /* Last */3,
                      onPress: onPress
                    })));
}

var make$2 = React.memo(Pagination);

export {
  isButtonDisabled ,
  make$2 as make,
}
/* styles Not a pure module */
