// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Accounting from "@wino/accounting/src/Accounting.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as OrderEdit$Pos from "./OrderEdit.bs.js";
import * as OrderStatus$Pos from "../../modules/Order/OrderStatus.bs.js";
import * as Accounting__Serializer from "@wino/accounting/src/Accounting__Serializer.bs.js";

function updateCartStocks(cart, stocks, statuses) {
  if (stocks === undefined) {
    return cart;
  }
  if (!OrderEdit$Pos.isBeforeReception(statuses)) {
    return cart;
  }
  var newrecord = Caml_obj.obj_dup(cart);
  newrecord.products = Belt_Array.map(cart.products, (function (product) {
          if (product.TAG === /* Unit */0) {
            var product$1 = product._0;
            var newrecord = Caml_obj.obj_dup(product$1);
            var updatedStock = Belt_Array.getBy(stocks, (function (updatedStock) {
                    return updatedStock.productId === product$1.id;
                  }));
            newrecord.stock = updatedStock !== undefined ? updatedStock.rawQuantity : product$1.stock;
            return {
                    TAG: /* Unit */0,
                    _0: newrecord
                  };
          }
          var precision = product._1;
          var product$2 = product._0;
          var newrecord$1 = Caml_obj.obj_dup(product$2);
          var updatedStock$1 = Belt_Array.getBy(stocks, (function (updatedStock) {
                  return updatedStock.productId === product$2.id;
                }));
          newrecord$1.stock = updatedStock$1 !== undefined ? Accounting.fromRawProductQuantity(precision, updatedStock$1.rawQuantity) : product$2.stock;
          return {
                  TAG: /* Bulk */1,
                  _0: newrecord$1,
                  _1: precision
                };
        }));
  return newrecord;
}

function serializeForm(param, state) {
  var serializedCart = Accounting__Serializer.serialize(state.cart);
  return Json$Pos.stringifyAny({
              condition: state.condition,
              noteForSupplier: state.noteForSupplier,
              supplierId: state.supplierId,
              issueDate: state.issueDate,
              estimatedReceptionDate: state.estimatedReceptionDate,
              note: state.note,
              cart: serializedCart
            });
}

function unserializeForm(extraData, serializedState) {
  var state = Json$Pos.parseExn(serializedState);
  var unserializedCart = Accounting__Serializer.deserialize(state.cart);
  var issueDate = new Date(state.issueDate);
  var estimatedReceptionDate = new Date(state.estimatedReceptionDate);
  var statuses = extraData.statuses;
  return {
          condition: state.condition,
          noteForSupplier: state.noteForSupplier,
          supplierId: state.supplierId,
          issueDate: issueDate,
          estimatedReceptionDate: estimatedReceptionDate,
          note: state.note,
          cart: OrderStatus$Pos.has(statuses, "DRAFT") ? unserializedCart : updateCartStocks(unserializedCart, extraData.stocks, statuses),
          shopId: extraData.shopId,
          deviceId: extraData.deviceId,
          supplierName: state.supplierName,
          supplierAddress: state.supplierAddress,
          supplierPostalCode: state.supplierPostalCode,
          supplierCity: state.supplierCity,
          supplierCountry: state.supplierCountry
        };
}

export {
  updateCartStocks ,
  serializeForm ,
  unserializeForm ,
}
/* Json-Pos Not a pure module */
