// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactNative from "react-native";

function create(prim) {
  return ReactNative.StyleSheet.create(prim);
}

function flatten(prim) {
  return ReactNative.StyleSheet.flatten(prim);
}

export {
  create ,
  flatten ,
}
/* react-native Not a pure module */
