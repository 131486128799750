// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as OrderEditForm$Pos from "./OrderEditForm.bs.js";

function OrderMemoCard(Props) {
  return React.createElement(Card$Pos.make, {
              children: React.createElement(OrderEditForm$Pos.InputTextArea.make, {
                    field: /* Note */5,
                    label: Intl$Pos.t("Internal memo")
                  }),
              title: "Note"
            });
}

var make = React.memo(OrderMemoCard);

export {
  make ,
}
/* make Not a pure module */
