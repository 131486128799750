// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Group$Pos from "../../resources/layout-and-structure/Group.bs.js";
import * as Image$Pos from "../../primitives/Image.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as AuthRoutes$Pos from "./AuthRoutes.bs.js";
import * as BugTracker$Pos from "../../core/BugTracker.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import Wino_logoPng from "./wino_logo.png";
import * as CountryCode$Pos from "../../primitives/CountryCode.bs.js";
import * as SpinnerModal$Pos from "../../resources/feedback-indicators/SpinnerModal.bs.js";
import * as CorporateEntity$Pos from "../../core/CorporateEntity.bs.js";
import * as Form__Submission$Pos from "../../bundles/Form/Form__Submission.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as PageBottomActionsBar$Pos from "../../resources/layout-and-structure/PageBottomActionsBar.bs.js";
import * as CustomerBillingAccount$Pos from "../../core/CustomerBillingAccount.bs.js";
import * as SupplierAddressComboBoxField$Pos from "../../modules/Supplier/SupplierAddressComboBoxField.bs.js";

function get(values, field) {
  switch (field) {
    case /* Username */0 :
        return values.username;
    case /* Password */1 :
        return values.password;
    case /* PasswordConfirmation */2 :
        return values.passwordConfirmation;
    case /* Name */3 :
        return values.name;
    case /* Email */4 :
        return values.email;
    case /* PhoneNumber */5 :
        return values.phoneNumber;
    case /* CorporateName */6 :
        return values.corporateName;
    case /* LegalRepresentative */7 :
        return values.legalRepresentative;
    case /* LegalForm */8 :
        return values.legalForm;
    case /* SiretNumber */9 :
        return values.siretNumber;
    case /* ApeNafCode */10 :
        return values.apeNafCode;
    case /* VatNumber */11 :
        return values.vatNumber;
    case /* Address */12 :
        return values.address;
    case /* City */13 :
        return values.city;
    case /* PostalCode */14 :
        return values.postalCode;
    case /* Country */15 :
        return values.country;
    case /* IbanNumber */16 :
        return values.ibanNumber;
    case /* ShippingAddress */17 :
        return values.shippingAddress;
    case /* ShippingPostalCode */18 :
        return values.shippingPostalCode;
    case /* ShippingCity */19 :
        return values.shippingCity;
    case /* ShippingCountry */20 :
        return values.shippingCountry;
    case /* SameShippingAddressThanBilling */21 :
        return values.sameShippingAddressThanBilling;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* Username */0 :
        var newrecord = Caml_obj.obj_dup(values);
        newrecord.username = value;
        return newrecord;
    case /* Password */1 :
        var newrecord$1 = Caml_obj.obj_dup(values);
        newrecord$1.password = value;
        return newrecord$1;
    case /* PasswordConfirmation */2 :
        var newrecord$2 = Caml_obj.obj_dup(values);
        newrecord$2.passwordConfirmation = value;
        return newrecord$2;
    case /* Name */3 :
        var newrecord$3 = Caml_obj.obj_dup(values);
        newrecord$3.name = value;
        return newrecord$3;
    case /* Email */4 :
        var newrecord$4 = Caml_obj.obj_dup(values);
        newrecord$4.email = value;
        return newrecord$4;
    case /* PhoneNumber */5 :
        var newrecord$5 = Caml_obj.obj_dup(values);
        newrecord$5.phoneNumber = value;
        return newrecord$5;
    case /* CorporateName */6 :
        var newrecord$6 = Caml_obj.obj_dup(values);
        newrecord$6.corporateName = value;
        return newrecord$6;
    case /* LegalRepresentative */7 :
        var newrecord$7 = Caml_obj.obj_dup(values);
        newrecord$7.legalRepresentative = value;
        return newrecord$7;
    case /* LegalForm */8 :
        var newrecord$8 = Caml_obj.obj_dup(values);
        newrecord$8.legalForm = value;
        return newrecord$8;
    case /* SiretNumber */9 :
        var newrecord$9 = Caml_obj.obj_dup(values);
        newrecord$9.siretNumber = value;
        return newrecord$9;
    case /* ApeNafCode */10 :
        var newrecord$10 = Caml_obj.obj_dup(values);
        newrecord$10.apeNafCode = value;
        return newrecord$10;
    case /* VatNumber */11 :
        var newrecord$11 = Caml_obj.obj_dup(values);
        newrecord$11.vatNumber = value;
        return newrecord$11;
    case /* Address */12 :
        var newrecord$12 = Caml_obj.obj_dup(values);
        newrecord$12.address = value;
        return newrecord$12;
    case /* City */13 :
        var newrecord$13 = Caml_obj.obj_dup(values);
        newrecord$13.city = value;
        return newrecord$13;
    case /* PostalCode */14 :
        var newrecord$14 = Caml_obj.obj_dup(values);
        newrecord$14.postalCode = value;
        return newrecord$14;
    case /* Country */15 :
        var newrecord$15 = Caml_obj.obj_dup(values);
        newrecord$15.country = value;
        return newrecord$15;
    case /* IbanNumber */16 :
        var newrecord$16 = Caml_obj.obj_dup(values);
        newrecord$16.ibanNumber = value;
        return newrecord$16;
    case /* ShippingAddress */17 :
        var newrecord$17 = Caml_obj.obj_dup(values);
        newrecord$17.shippingAddress = value;
        return newrecord$17;
    case /* ShippingPostalCode */18 :
        var newrecord$18 = Caml_obj.obj_dup(values);
        newrecord$18.shippingPostalCode = value;
        return newrecord$18;
    case /* ShippingCity */19 :
        var newrecord$19 = Caml_obj.obj_dup(values);
        newrecord$19.shippingCity = value;
        return newrecord$19;
    case /* ShippingCountry */20 :
        var newrecord$20 = Caml_obj.obj_dup(values);
        newrecord$20.shippingCountry = value;
        return newrecord$20;
    case /* SameShippingAddressThanBilling */21 :
        var newrecord$21 = Caml_obj.obj_dup(values);
        newrecord$21.sameShippingAddressThanBilling = value;
        return newrecord$21;
    
  }
}

var SignupFormLenses = {
  get: get,
  set: set
};

var SignupForm = Form$Pos.Make(SignupFormLenses);

function stripEmptyString(string) {
  if (string === "") {
    return ;
  } else {
    return string;
  }
}

function createUserRegistrationPayloadDict(username, password, name, email, phoneNumber, corporateName, legalRepresentative, legalForm, siretNumber, apeNafCode, vatNumber, address, postalCode, city, country, ibanNumber, shippingAddress, shippingPostalCode, shippingCity, shippingCountry, sameShippingAddressThanBilling, ipAddress, userAgent, acceptedAt) {
  var shop = Js_dict.fromArray([
        [
          "name",
          Json$Pos.encodeString(name)
        ],
        [
          "email",
          Json$Pos.encodeString(email)
        ],
        [
          "phoneNumber",
          Json$Pos.encodeString(phoneNumber)
        ],
        [
          "corporateName",
          Json$Pos.encodeString(corporateName)
        ],
        [
          "legalRepresentative",
          Json$Pos.encodeString(legalRepresentative)
        ],
        [
          "legalForm",
          Json$Pos.encodeString(legalForm)
        ],
        [
          "taxCountry",
          Json$Pos.encodeString(CountryCode$Pos.toIso2String(country))
        ],
        [
          "address",
          Json$Pos.encodeString(address)
        ],
        [
          "city",
          Json$Pos.encodeString(city)
        ],
        [
          "postalCode",
          Json$Pos.encodeString(postalCode)
        ],
        [
          "country",
          Json$Pos.encodeString(CountryCode$Pos.toMediumCountryString(country))
        ],
        [
          "customerBillingAccount",
          Json$Pos.encodeDict(Js_dict.fromArray([
                    [
                      "corporateName",
                      Json$Pos.encodeString(corporateName)
                    ],
                    [
                      "shopName",
                      Json$Pos.encodeString(name)
                    ],
                    [
                      "phone",
                      Json$Pos.encodeString(phoneNumber)
                    ],
                    [
                      "email",
                      Json$Pos.encodeString(email)
                    ],
                    [
                      "vatNumber",
                      Json$Pos.encodeString(Belt_Option.getWithDefault(vatNumber, ""))
                    ],
                    [
                      "ibanNumber",
                      Json$Pos.encodeString(ibanNumber)
                    ],
                    [
                      "billingAddress",
                      Json$Pos.encodeDict(Js_dict.fromArray([
                                [
                                  "address",
                                  Json$Pos.encodeString(address)
                                ],
                                [
                                  "city",
                                  Json$Pos.encodeString(city)
                                ],
                                [
                                  "postalCode",
                                  Json$Pos.encodeString(postalCode)
                                ],
                                [
                                  "country",
                                  Json$Pos.encodeString(CountryCode$Pos.toIsoString(country))
                                ]
                              ]))
                    ],
                    [
                      "shippingAddress",
                      sameShippingAddressThanBilling ? Json$Pos.encodeDict(Js_dict.fromArray([
                                  [
                                    "address",
                                    Json$Pos.encodeString(address)
                                  ],
                                  [
                                    "city",
                                    Json$Pos.encodeString(city)
                                  ],
                                  [
                                    "postalCode",
                                    Json$Pos.encodeString(postalCode)
                                  ],
                                  [
                                    "country",
                                    Json$Pos.encodeString(CountryCode$Pos.toIsoString(country))
                                  ]
                                ])) : Json$Pos.encodeDict(Js_dict.fromArray([
                                  [
                                    "address",
                                    Json$Pos.encodeString(shippingAddress)
                                  ],
                                  [
                                    "city",
                                    Json$Pos.encodeString(shippingCity)
                                  ],
                                  [
                                    "postalCode",
                                    Json$Pos.encodeString(shippingPostalCode)
                                  ],
                                  [
                                    "country",
                                    Json$Pos.encodeString(CountryCode$Pos.toIsoString(shippingCountry))
                                  ]
                                ]))
                    ],
                    [
                      "sepaMandateAcceptanceDetails",
                      Json$Pos.encodeDict(Js_dict.fromArray([
                                [
                                  "ipAddress",
                                  Json$Pos.encodeString(ipAddress)
                                ],
                                [
                                  "userAgent",
                                  Json$Pos.encodeString(userAgent)
                                ],
                                [
                                  "acceptedAt",
                                  Json$Pos.encodeNumber(acceptedAt)
                                ]
                              ]))
                    ]
                  ]))
        ]
      ]);
  if (siretNumber !== undefined) {
    shop["siretNumber"] = Json$Pos.encodeString(siretNumber);
  }
  if (apeNafCode !== undefined) {
    shop["apeNafCode"] = Json$Pos.encodeString(apeNafCode);
  }
  if (vatNumber !== undefined) {
    shop["vatNumber"] = Json$Pos.encodeString(vatNumber);
  }
  return Js_dict.fromArray([
              [
                "username",
                Json$Pos.encodeString(username)
              ],
              [
                "password",
                Json$Pos.encodeString(password)
              ],
              [
                "shop",
                Json$Pos.encodeDict(shop)
              ]
            ]);
}

function decodeServerFailure(serverFailure) {
  switch (serverFailure.kind) {
    case "DuplicateUserUsername" :
        return /* DuplicateUserUsername */0;
    case "InvalidCorporateName" :
        return /* InvalidCorporateName */4;
    case "InvalidIban" :
        return /* InvalidIban */3;
    case "InvalidVatNumber" :
        return /* InvalidVatNumber */1;
    case "WrongSepaMandateAcceptanceDetails" :
        return /* WrongSepaMandateAcceptanceDetails */2;
    default:
      return /* Unknown */6;
  }
}

var endpoint = Env$Pos.gatewayUrl(undefined) + "/sign-up";

function make(username, password, name, email, phoneNumber, corporateName, legalRepresentative, legalForm, siretNumber, apeNafCode, vatNumber, address, postalCode, city, country, ibanNumber, shippingAddress, shippingPostalCode, shippingCity, shippingCountry, sameShippingAddressThanBilling, ipAddress, userAgent, acceptedAt) {
  return Future.mapError(Future.mapOk(Request$Pos.makeAndDecode("POST", Caml_option.some(Json$Pos.encodeDict(createUserRegistrationPayloadDict(username, password, name, email, phoneNumber, corporateName, legalRepresentative, legalForm, stripEmptyString(siretNumber), stripEmptyString(apeNafCode), stripEmptyString(vatNumber), address, postalCode, city, country, ibanNumber, shippingAddress, shippingPostalCode, shippingCity, shippingCountry, sameShippingAddressThanBilling, ipAddress, userAgent, acceptedAt))), false, undefined, endpoint), undefined, (function (param) {
                    
                  })), undefined, (function (error) {
                if (typeof error === "number") {
                  return ;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeServerFailure);
                }
              }));
}

var SignupRequest = {
  createUserRegistrationPayloadDict: createUserRegistrationPayloadDict,
  decodeServerFailure: decodeServerFailure,
  endpoint: endpoint,
  make: make
};

function AuthSignupPage$NotificationBanner(Props) {
  var notification = Props.notification;
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                          textStatus: notification
                        })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(100)), undefined, undefined)), undefined, undefined, undefined));
}

var make$1 = React.memo(AuthSignupPage$NotificationBanner);

var NotificationBanner = {
  make: make$1
};

function validateAddressValue(value, values) {
  if (!values.sameShippingAddressThanBilling && value === "") {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("Please fulfill this field.")
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

var schema = [
  {
    TAG: /* Email */5,
    _0: /* Username */0
  },
  {
    TAG: /* Password */6,
    _0: /* Password */1
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* PasswordConfirmation */2
  },
  {
    TAG: /* CustomString */8,
    _0: /* PasswordConfirmation */2,
    _1: (function (value, values) {
        if (values.password === value) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("Password and its confirmation must be identical")
                };
        }
      })
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Name */3
  },
  {
    TAG: /* Email */5,
    _0: /* Email */4
  },
  {
    TAG: /* PhoneNumber */4,
    _0: /* PhoneNumber */5
  },
  {
    TAG: /* CustomString */8,
    _0: /* CorporateName */6,
    _1: (function (value, param) {
        if (CorporateEntity$Pos.CorporateName.validate(value)) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("The corporate name is invalid. Please provide the full name of the company.")
                };
        }
      })
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Address */12
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* PostalCode */14
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* City */13
  },
  {
    TAG: /* CustomString */8,
    _0: /* ShippingAddress */17,
    _1: validateAddressValue
  },
  {
    TAG: /* CustomString */8,
    _0: /* ShippingPostalCode */18,
    _1: validateAddressValue
  },
  {
    TAG: /* CustomString */8,
    _0: /* ShippingCity */19,
    _1: validateAddressValue
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* IbanNumber */16
  },
  {
    TAG: /* CustomString */8,
    _0: /* IbanNumber */16,
    _1: (function (value, param) {
        if (CorporateEntity$Pos.Iban.validate(value)) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("The IBAN number is not valid.")
                };
        }
      })
  },
  {
    TAG: /* CustomString */8,
    _0: /* LegalRepresentative */7,
    _1: (function (value, param) {
        if (value.length < 3) {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("The legal representative is not valid, full name must be given.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  }
];

var imageUri = Wino_logoPng;

var imageSize = Style$Pos.dp(40);

var styles = StyleSheet$Pos.create({
      image: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(imageSize), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(imageSize), undefined, undefined)
    });

function AuthSignupPage$SignUpHeader(Props) {
  var onSubmit = Props.onSubmit;
  return React.createElement(Inline$Pos.make, {
              children: null,
              align: "spaceBetween"
            }, React.createElement(Inline$Pos.make, {
                  children: null,
                  space: "medium",
                  alignY: "center"
                }, React.createElement(Image$Pos.make, {
                      style: styles.image,
                      source: Image$Pos.fromUriSource(Image$Pos.uriSource(imageUri))
                    }), React.createElement(Title$Pos.make, {
                      children: Intl$Pos.t("Set Up Your Wino Account"),
                      level: 1
                    })), React.createElement(SignupForm.SubmitButton.make, {
                  variation: "success",
                  size: "large",
                  onSubmit: onSubmit,
                  text: Intl$Pos.t("Create your account")
                }));
}

var SignUpHeader = {
  imageUri: imageUri,
  imageSize: imageSize,
  styles: styles,
  make: AuthSignupPage$SignUpHeader
};

function make$2(param) {
  return window.navigator.userAgent;
}

var GetNavigatorUserAgent = {
  make: make$2
};

var styles$1 = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(1480)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xxhuge)), undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xxlarge)), undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xlarge)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function AuthSignupPage(Props) {
  var signupRequest = Props.signupRequest;
  var ipAddressRequest = Props.ipAddressRequest;
  var initialNotification = Props.initialNotification;
  var initialUsernameOpt = Props.initialUsername;
  var initialUsername = initialUsernameOpt !== undefined ? initialUsernameOpt : "";
  var navigate = Navigation$Pos.useNavigate(undefined);
  var match = React.useState(function () {
        return initialNotification;
      });
  var setNotfication = match[1];
  var notification = match[0];
  var onSubmitSuccess = function (param) {
    Curry._4(navigate, AuthRoutes$Pos.signupSuccess, undefined, undefined, undefined);
  };
  var onSubmitFailure = function (error) {
    window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
    Curry._1(setNotfication, (function (param) {
            return {
                    TAG: /* Danger */1,
                    _0: error
                  };
          }));
  };
  var formPropState = Curry._1(SignupForm.useFormPropState, {
        initialValues: {
          username: initialUsername,
          password: "",
          passwordConfirmation: "",
          name: "",
          email: "",
          phoneNumber: "",
          corporateName: "",
          legalRepresentative: "",
          legalForm: "",
          siretNumber: "",
          apeNafCode: "",
          vatNumber: "",
          address: "",
          city: "",
          postalCode: "",
          country: /* FR */0,
          ibanNumber: "",
          shippingAddress: "",
          shippingPostalCode: "",
          shippingCity: "",
          shippingCountry: /* FR */0,
          sameShippingAddressThanBilling: true
        },
        schema: schema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var formState = formPropState[0];
  var validation = formState.validation;
  var submission = formState.submission;
  var values = formState.values;
  var formDispatch = formPropState[1];
  var submitting = Form__Submission$Pos.isRequested(submission);
  React.useEffect((function () {
          if (submitting) {
            Curry._1(setNotfication, (function (param) {
                    
                  }));
          }
          
        }), [submitting]);
  var onSubmit = function (param, param$1) {
    var sameShippingAddressThanBilling = param$1.sameShippingAddressThanBilling;
    var shippingCountry = param$1.shippingCountry;
    var shippingCity = param$1.shippingCity;
    var shippingPostalCode = param$1.shippingPostalCode;
    var shippingAddress = param$1.shippingAddress;
    var ibanNumber = param$1.ibanNumber;
    var country = param$1.country;
    var postalCode = param$1.postalCode;
    var city = param$1.city;
    var address = param$1.address;
    var vatNumber = param$1.vatNumber;
    var apeNafCode = param$1.apeNafCode;
    var siretNumber = param$1.siretNumber;
    var legalForm = param$1.legalForm;
    var legalRepresentative = param$1.legalRepresentative;
    var corporateName = param$1.corporateName;
    var phoneNumber = param$1.phoneNumber;
    var email = param$1.email;
    var name = param$1.name;
    var password = param$1.password;
    var username = param$1.username;
    return Future.mapError(Future.mapOk(Future.flatMap(Future.tapError(Curry._1(ipAddressRequest, undefined), (function (error) {
                              BugTracker$Pos.reportErrorMessage("Fetching IP on signup failed with error: " + Belt_Option.getWithDefault(Json$Pos.stringifyAny(error), "unknown"));
                            })), undefined, (function (ipRequestResult) {
                          if (ipRequestResult.TAG === /* Ok */0) {
                            return Curry.app(signupRequest, [
                                        username,
                                        password,
                                        name,
                                        email,
                                        phoneNumber,
                                        corporateName,
                                        legalRepresentative,
                                        legalForm,
                                        siretNumber,
                                        apeNafCode,
                                        vatNumber,
                                        address,
                                        postalCode,
                                        city,
                                        country,
                                        CorporateEntity$Pos.Iban.sanitize(ibanNumber),
                                        shippingAddress,
                                        shippingPostalCode,
                                        shippingCity,
                                        shippingCountry,
                                        sameShippingAddressThanBilling,
                                        ipRequestResult._0,
                                        window.navigator.userAgent,
                                        Date.now()
                                      ]);
                          } else {
                            return Future.value({
                                        TAG: /* Error */1,
                                        _0: /* IpAddressFailure */5
                                      });
                          }
                        })), undefined, (function (param) {
                      return Intl$Pos.t("Account successfully set up.");
                    })), undefined, (function (failure) {
                  if (failure === undefined) {
                    return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                  }
                  switch (failure) {
                    case /* DuplicateUserUsername */0 :
                        return Intl$Pos.t("This email address is already used.");
                    case /* InvalidVatNumber */1 :
                        return Intl$Pos.t("The VAT number is not valid.");
                    case /* WrongSepaMandateAcceptanceDetails */2 :
                        return Intl$Pos.t("Your internet connection has been interrupted, please reload the page before submitting the form again.");
                    case /* InvalidIban */3 :
                        return Intl$Pos.t("The IBAN number is not valid.");
                    case /* InvalidCorporateName */4 :
                        return Intl$Pos.t("The corporate name is not valid, you must enter the name of your company.");
                    case /* IpAddressFailure */5 :
                    case /* Unknown */6 :
                        return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                    
                  }
                }));
  };
  var errorMessageAddress;
  errorMessageAddress = validation.TAG === /* Ok */0 || typeof submission === "number" || submission.TAG !== /* Failed */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(validation._0, (function (param) {
                if (param[0]._0 !== 12) {
                  return ;
                } else {
                  return param[1];
                }
              })), 0);
  var onChangeAddress = function (value) {
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* Address */12,
          _1: (function (param) {
              return value;
            })
        });
  };
  var onRequestAddressAutoComplete = function (address) {
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* Address */12,
          _1: (function (param) {
              return address.name;
            })
        });
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* PostalCode */14,
          _1: (function (param) {
              return address.postcode;
            })
        });
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* City */13,
          _1: (function (param) {
              return address.city;
            })
        });
  };
  var errorMessageShippingAddress;
  errorMessageShippingAddress = validation.TAG === /* Ok */0 || typeof submission === "number" || submission.TAG !== /* Failed */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(validation._0, (function (param) {
                if (param[0]._0 !== 17) {
                  return ;
                } else {
                  return param[1];
                }
              })), 0);
  var onChangeShippingAddress = function (value) {
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* ShippingAddress */17,
          _1: (function (param) {
              return value;
            })
        });
  };
  var onRequestShippingAddressAutoComplete = function (address) {
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* ShippingAddress */17,
          _1: (function (param) {
              return address.name;
            })
        });
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* ShippingPostalCode */18,
          _1: (function (param) {
              return address.postcode;
            })
        });
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* ShippingCity */19,
          _1: (function (param) {
              return address.city;
            })
        });
  };
  var tmp = {
    required: true,
    addressName: values.address,
    onInputChange: onChangeAddress,
    onRequestAutoComplete: onRequestAddressAutoComplete
  };
  if (errorMessageAddress !== undefined) {
    tmp.errorMessage = Caml_option.valFromOption(errorMessageAddress);
  }
  var tmp$1;
  if (values.sameShippingAddressThanBilling) {
    tmp$1 = null;
  } else {
    var tmp$2 = {
      required: true,
      addressName: values.shippingAddress,
      onInputChange: onChangeShippingAddress,
      onRequestAutoComplete: onRequestShippingAddressAutoComplete
    };
    if (errorMessageShippingAddress !== undefined) {
      tmp$2.errorMessage = Caml_option.valFromOption(errorMessageShippingAddress);
    }
    tmp$1 = React.createElement(Stack$Pos.make, {
          children: null,
          space: "large"
        }, React.createElement(SupplierAddressComboBoxField$Pos.make, tmp$2), React.createElement(Group$Pos.make, {
              children: null
            }, React.createElement(SignupForm.InputText.make, {
                  field: /* ShippingPostalCode */18,
                  label: Intl$Pos.t("Postal code"),
                  placeholder: Intl$Pos.t("Postal code")
                }), React.createElement(SignupForm.InputText.make, {
                  field: /* ShippingCity */19,
                  label: Intl$Pos.t("City"),
                  placeholder: Intl$Pos.t("City")
                })), React.createElement(SignupForm.Select.make, {
              field: /* ShippingCountry */20,
              label: Intl$Pos.t("Country"),
              sections: [{
                  items: Belt_Array.map(CustomerBillingAccount$Pos.acceptedCountryCodes, (function (value) {
                          return {
                                  key: CountryCode$Pos.toIso2String(value),
                                  value: value,
                                  label: Intl$Pos.t(CountryCode$Pos.toMediumCountryString(value))
                                };
                        }))
                }]
            }));
  }
  return React.createElement(SignupForm.Core.Provider.make, {
              children: React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles$1.container), undefined, undefined, undefined), React.createElement(SpinnerModal$Pos.make, {
                        title: Intl$Pos.t("Account creation"),
                        opened: submitting,
                        loopMessages: [
                          Intl$Pos.t("User account creation"),
                          Intl$Pos.t("Subscription setup"),
                          Intl$Pos.t("Tax creation"),
                          Intl$Pos.t("Configuring devices"),
                          Intl$Pos.t("Validating your payment method")
                        ]
                      }), React.createElement(AuthSignupPage$SignUpHeader, {
                        onSubmit: onSubmit
                      }), notification !== undefined ? React.createElement(make$1, {
                          notification: notification
                        }) : null, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, undefined, "huge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(SignupForm.ControlEnterKey.make, {
                            onSubmit: onSubmit
                          }), React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "large"
                          }, React.createElement(FieldsetLayoutPanel$Pos.make, {
                                title: Intl$Pos.t("Login details"),
                                description: Intl$Pos.t("Please make a note of your password out of sight. This will solve a potential slight memory lapse when first logging into Wino!"),
                                children: null
                              }, React.createElement(SignupForm.InputText.make, {
                                    field: /* Username */0,
                                    label: Intl$Pos.t("Account email"),
                                    placeholder: Intl$Pos.t("anna.rossi@gmail.com")
                                  }), React.createElement(SignupForm.InputPassword.make, {
                                    field: /* Password */1,
                                    label: Intl$Pos.t("Password"),
                                    placeholder: Intl$Pos.t("Password"),
                                    showTypingValidation: true
                                  }), React.createElement(SignupForm.InputPassword.make, {
                                    field: /* PasswordConfirmation */2,
                                    label: Intl$Pos.t("Password confirmation"),
                                    placeholder: Intl$Pos.t("Password confirmation"),
                                    hideRequired: true
                                  })), React.createElement(FieldsetLayoutPanel$Pos.make, {
                                title: Intl$Pos.t("General store information."),
                                description: Intl$Pos.t("Complete the store name and your contact information that will be displayed on your sales documents."),
                                children: null
                              }, React.createElement(SignupForm.InputText.make, {
                                    field: /* Name */3,
                                    label: Intl$Pos.t("Shop name"),
                                    placeholder: Intl$Pos.t("Shop name")
                                  }), React.createElement(SignupForm.InputText.make, {
                                    field: /* Email */4,
                                    label: Intl$Pos.t("Shop email"),
                                    placeholder: Intl$Pos.t("contact@shop.com")
                                  }), React.createElement(SignupForm.InputText.make, {
                                    field: /* PhoneNumber */5,
                                    label: Intl$Pos.t("Shop phone"),
                                    placeholder: Intl$Pos.t("01 23 45 67 89")
                                  })), React.createElement(FieldsetLayoutPanel$Pos.make, {
                                title: Intl$Pos.t("Company information"),
                                description: Intl$Pos.t("These details will be used for billing our services. They will also appear on your sales documents issued by the Wino software."),
                                children: null
                              }, React.createElement(Group$Pos.make, {
                                    children: null
                                  }, React.createElement(SignupForm.InputText.make, {
                                        field: /* CorporateName */6,
                                        label: Intl$Pos.t("Corporate name"),
                                        placeholder: Intl$Pos.t("Company name as displayed on your official business registration document.")
                                      }), React.createElement(SignupForm.SuggestionComboBox.make, {
                                        field: /* LegalForm */8,
                                        label: Intl$Pos.t("Legal form"),
                                        placeholder: Intl$Pos.t("Legal form"),
                                        items: Belt_Array.map(CorporateEntity$Pos.CompanyLegalForm.values, (function (value) {
                                                return {
                                                        value: Intl$Pos.t(CorporateEntity$Pos.CompanyLegalForm.toString(value))
                                                      };
                                              }))
                                      })), React.createElement(SignupForm.InputText.make, {
                                    field: /* LegalRepresentative */7,
                                    label: Intl$Pos.t("Legal representative"),
                                    placeholder: Intl$Pos.t("Anna Rossi")
                                  }), React.createElement(SignupForm.InputText.make, {
                                    field: /* VatNumber */11,
                                    label: Intl$Pos.t("VAT number"),
                                    placeholder: Intl$Pos.t("FR 11 123123123")
                                  }), React.createElement(Group$Pos.make, {
                                    children: null
                                  }, React.createElement(SignupForm.InputText.make, {
                                        field: /* SiretNumber */9,
                                        label: Intl$Pos.t("SIRET number"),
                                        placeholder: Intl$Pos.t("Enter 14 digits")
                                      }), React.createElement(SignupForm.InputText.make, {
                                        field: /* ApeNafCode */10,
                                        label: Intl$Pos.t("APE/NAF code"),
                                        placeholder: Intl$Pos.t("Enter a code of 4 digits and one letter")
                                      }))), React.createElement(FieldsetLayoutPanel$Pos.make, {
                                title: Intl$Pos.t("Billing address"),
                                description: Intl$Pos.t("This address will be used for billing our services. It will also appear on your sales documents issued by the Wino software. If needed, it can be changed later within the software."),
                                children: null
                              }, React.createElement(SupplierAddressComboBoxField$Pos.make, tmp), React.createElement(Group$Pos.make, {
                                    children: null
                                  }, React.createElement(SignupForm.InputText.make, {
                                        field: /* PostalCode */14,
                                        label: Intl$Pos.t("Postal code"),
                                        placeholder: Intl$Pos.t("Postal code")
                                      }), React.createElement(SignupForm.InputText.make, {
                                        field: /* City */13,
                                        label: Intl$Pos.t("City"),
                                        placeholder: Intl$Pos.t("City")
                                      })), React.createElement(SignupForm.Select.make, {
                                    field: /* Country */15,
                                    label: Intl$Pos.t("Country"),
                                    infotip: Intl$Pos.t("For your country, carefully select the proposal corresponding to your situation. Your choice will determine the VAT settings on your account."),
                                    sections: [{
                                        items: Belt_Array.map(CountryCode$Pos.values, (function (value) {
                                                return {
                                                        key: CountryCode$Pos.toIso2String(value),
                                                        value: value,
                                                        label: Intl$Pos.t(CountryCode$Pos.toLongCountryString(value))
                                                      };
                                              }))
                                      }]
                                  })), React.createElement(FieldsetLayoutPanel$Pos.make, {
                                title: Intl$Pos.t("Shipping address"),
                                description: Intl$Pos.t("Provide the address where you would like to receive your cash register equipment."),
                                children: null
                              }, React.createElement(SignupForm.InputToggleSwitch.make, {
                                    label: Intl$Pos.t("Shipping address same as billing address."),
                                    field: /* SameShippingAddressThanBilling */21
                                  }), tmp$1), React.createElement(FieldsetLayoutPanel$Pos.make, {
                                title: Intl$Pos.t("Banking details for SEPA direct debit"),
                                description: Intl$Pos.t("Entering your bank details and then validating the form automatically triggers the approval of a SEPA direct debit mandate."),
                                children: null
                              }, React.createElement(SignupForm.InputText.make, {
                                    field: /* IbanNumber */16,
                                    label: Intl$Pos.t("IBAN number"),
                                    placeholder: Intl$Pos.t("FR12 1234 5678 1000 0000 0000 123")
                                  }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                                textStatus: {
                                                  TAG: /* Info */3,
                                                  _0: Intl$Pos.t("By providing your IBAN and clicking on 'Create account' from this URL, you authorize Wino Technologies and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with these instructions. You are entitled to a refund from your bank under the terms and conditions of the agreement you have entered into with them. The refund must be requested within 8 weeks from the date your account was debited.")
                                                }
                                              })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))))), React.createElement(PageBottomActionsBar$Pos.make, {
                        displayThreshold: 180,
                        renderEnd: (function (param) {
                            return React.createElement(SignupForm.SubmitButton.make, {
                                        variation: "success",
                                        size: "large",
                                        onSubmit: onSubmit,
                                        text: Intl$Pos.t("Create your account")
                                      });
                          })
                      })),
              propState: formPropState
            });
}

var signupRequest = make;

var make$3 = AuthSignupPage;

export {
  SignupFormLenses ,
  SignupForm ,
  stripEmptyString ,
  SignupRequest ,
  signupRequest ,
  NotificationBanner ,
  validateAddressValue ,
  schema ,
  SignUpHeader ,
  GetNavigatorUserAgent ,
  styles$1 as styles,
  make$3 as make,
}
/* SignupForm Not a pure module */
