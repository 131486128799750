// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";

function notAsked(param) {
  return /* NotAsked */0;
}

function loading(param) {
  return /* Loading */1;
}

function reloading(data) {
  return {
          TAG: /* Reloading */0,
          _0: data
        };
}

function done(data) {
  return {
          TAG: /* Done */1,
          _0: data
        };
}

function isReloading(asyncData) {
  if (typeof asyncData === "number" || asyncData.TAG !== /* Reloading */0) {
    return false;
  } else {
    return true;
  }
}

function isBusy(asyncData) {
  if (typeof asyncData === "number") {
    if (asyncData === /* NotAsked */0) {
      return false;
    } else {
      return true;
    }
  } else if (asyncData.TAG === /* Reloading */0) {
    return true;
  } else {
    return false;
  }
}

function isIdle(asyncData) {
  if (typeof asyncData === "number") {
    if (asyncData === /* NotAsked */0) {
      return true;
    } else {
      return false;
    }
  } else if (asyncData.TAG === /* Reloading */0) {
    return false;
  } else {
    return true;
  }
}

function toBusy(asyncData) {
  if (typeof asyncData === "number") {
    if (asyncData === /* NotAsked */0) {
      return /* Loading */1;
    } else {
      return asyncData;
    }
  } else if (asyncData.TAG === /* Reloading */0) {
    return asyncData;
  } else {
    return {
            TAG: /* Reloading */0,
            _0: asyncData._0
          };
  }
}

function map(asyncResult, transform) {
  if (typeof asyncResult === "number") {
    if (asyncResult === /* NotAsked */0) {
      return /* NotAsked */0;
    } else {
      return /* Loading */1;
    }
  } else if (asyncResult.TAG === /* Reloading */0) {
    return {
            TAG: /* Reloading */0,
            _0: Curry._1(transform, asyncResult._0)
          };
  } else {
    return {
            TAG: /* Done */1,
            _0: Curry._1(transform, asyncResult._0)
          };
  }
}

function flatMap(asyncResult, transform) {
  if (typeof asyncResult === "number") {
    if (asyncResult !== 0) {
      return /* Loading */1;
    } else {
      return /* NotAsked */0;
    }
  } else {
    return Curry._1(transform, asyncResult._0);
  }
}

function mapWithDefault(asyncResult, defaultValue, transform) {
  if (typeof asyncResult === "number") {
    return defaultValue;
  } else {
    return Curry._1(transform, asyncResult._0);
  }
}

function getWithDefault(asyncResult, defaultValue) {
  if (typeof asyncResult === "number") {
    return defaultValue;
  } else {
    return asyncResult._0;
  }
}

export {
  notAsked ,
  loading ,
  reloading ,
  done ,
  isReloading ,
  isBusy ,
  isIdle ,
  toBusy ,
  map ,
  flatMap ,
  mapWithDefault ,
  getWithDefault ,
}
/* No side effect */
