// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as QueryString$Pos from "../../primitives/QueryString.bs.js";

function encoder(param) {
  return [
          param.supplierId,
          param.shopId
        ];
}

function decoder(param) {
  return {
          TAG: /* Ok */0,
          _0: {
            supplierId: param[0],
            shopId: param[1]
          }
        };
}

var value = JsonCodec$Pos.object2(encoder, decoder, JsonCodec$Pos.field("supplierId", JsonCodec$Pos.string), JsonCodec$Pos.field("shopId", JsonCodec$Pos.string));

var Codecs = {
  encoder: encoder,
  decoder: decoder,
  value: value
};

function encode(state) {
  return QueryString$Pos.stringify(JsonCodec$Pos.encodeWith(state, value));
}

function decode(query) {
  var match = JsonCodec$Pos.decodeWith(QueryString$Pos.parse(query), value);
  if (match.TAG !== /* Ok */0) {
    return [
            undefined,
            undefined
          ];
  }
  var match$1 = match._0;
  return [
          match$1.supplierId,
          match$1.shopId
        ];
}

var CreateOrder = {
  Codecs: Codecs,
  encode: encode,
  decode: decode
};

export {
  CreateOrder ,
}
/* value Not a pure module */
