// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDomElement$Pos from "./ReactDomElement.bs.js";

function use(forwardedRef, param) {
  var match = React.useState(function () {
        return {
                width: 0,
                height: 0
              };
      });
  var setDimensions = match[1];
  var ref = forwardedRef !== undefined ? forwardedRef : React.useRef(null);
  var resizeTimeoutIdRef = React.useRef(null);
  var onResizeChanged = React.useCallback((function (maybeDomElement) {
          return function (param) {
            if (maybeDomElement !== undefined) {
              var domElement = Caml_option.valFromOption(maybeDomElement);
              var dimensions_width = Math.floor(domElement.offsetWidth);
              var dimensions_height = Math.floor(domElement.offsetHeight);
              var dimensions = {
                width: dimensions_width,
                height: dimensions_height
              };
              var timeoutId = setTimeout((function (param) {
                      Curry._1(setDimensions, (function (param) {
                              return dimensions;
                            }));
                    }), 75);
              resizeTimeoutIdRef.current = timeoutId;
            }
            var id = resizeTimeoutIdRef.current;
            if (!(id == null)) {
              clearTimeout(id);
              return ;
            }
            
          };
        }), [ref.current]);
  React.useEffect((function () {
          var domElement = ReactDomElement$Pos.fromRef(ref);
          if (domElement !== undefined) {
            var domElement$1 = Caml_option.valFromOption(domElement);
            window.addEventListener("resize", Curry._1(onResizeChanged, Caml_option.some(domElement$1)));
            Curry._1(setDimensions, (function (param) {
                    return {
                            width: Math.floor(domElement$1.offsetWidth),
                            height: Math.floor(domElement$1.offsetHeight)
                          };
                  }));
          }
          return (function (param) {
                    window.removeEventListener("resize", Curry._1(onResizeChanged, undefined));
                  });
        }), [ref.current]);
  return [
          ref,
          match[0]
        ];
}

export {
  use ,
}
/* react Not a pure module */
