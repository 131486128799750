// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactNative from "react-native";
import * as Animated$ReactNative from "rescript-react-native/src/apis/Animated.bs.js";

function loop(prim) {
  return ReactNative.Animated.loop(prim);
}

var Value;

var Animation;

var Interpolation;

var StyleProp;

var Easing;

var $$Image;

var timing = Animated$ReactNative.timing;

export {
  Value ,
  Animation ,
  Interpolation ,
  StyleProp ,
  Easing ,
  $$Image ,
  loop ,
  timing ,
}
/* react-native Not a pure module */
