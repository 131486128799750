// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Moment from "moment";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactDates from "react-dates";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

var make = React.memo(function (Props) {
      var value = Props.value;
      var onChange = Props.onChange;
      var isDayBlocked = Props.isDayBlocked;
      var date = Js_null_undefined.fromOption(Belt_Option.map(value, (function (prim) {
                  return Moment(prim);
                })));
      var onDateChange = React.useCallback((function (date) {
              Curry._1(onChange, date.toDate());
            }), [value]);
      var isDayBlocked$1 = React.useCallback((function (day) {
              return Belt_Option.mapWithDefault(isDayBlocked, false, (function (fn) {
                            return Curry._1(fn, day.toDate());
                          }));
            }), [value]);
      return React.createElement(ReactDates.DayPickerSingleDateController, {
                  date: date,
                  onDateChange: onDateChange,
                  isDayBlocked: isDayBlocked$1,
                  transitionDuration: 0,
                  noBorder: true,
                  focused: true
                });
    });

export {
  make ,
}
/* make Not a pure module */
