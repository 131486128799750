// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Price$Pos from "./Price.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as InputNumberField$Pos from "../../resources/selection-and-input/InputNumberField.bs.js";

function PriceRetailTableEditCell(Props) {
  var value = Props.value;
  var secondaryValue = Props.secondaryValue;
  var purchasePrice = Props.purchasePrice;
  var bulkUnit = Props.bulkUnit;
  var autoFocusedOpt = Props.autoFocused;
  var shrinkInputOpt = Props.shrinkInput;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var onError = Props.onError;
  var autoFocused = autoFocusedOpt !== undefined ? autoFocusedOpt : false;
  var shrinkInput = shrinkInputOpt !== undefined ? shrinkInputOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setErrored = match[1];
  var errored = match[0];
  var taxIncluded = Price$Pos.isTaxIncluded(value);
  var value$1 = Price$Pos.toRawValue(value);
  var secondaryValue$1 = Belt_Option.map(secondaryValue, Price$Pos.toRawValue);
  var onChange$1 = React.useCallback((function (value) {
          Curry._1(onChange, taxIncluded ? ({
                    TAG: /* PriceInclTax */1,
                    _0: value
                  }) : ({
                    TAG: /* PriceExclTax */0,
                    _0: value
                  }));
        }), []);
  React.useEffect((function () {
          Curry._1(setErrored, (function (param) {
                  if (value$1 !== 0) {
                    if (value$1 < purchasePrice) {
                      return true;
                    } else if (secondaryValue$1 !== undefined) {
                      return secondaryValue$1 < purchasePrice;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }));
        }), [value$1]);
  React.useEffect((function () {
          if (errored && onError !== undefined) {
            Curry._1(onError, undefined);
          }
          
        }), [errored]);
  var tmp = {
    value: value$1,
    minValue: 0,
    appender: {
      TAG: /* Custom */1,
      _0: Intl$Pos.toCurrencySymbol("EUR") + Belt_Option.mapWithDefault(bulkUnit, "", (function (unit) {
              return " / " + unit + "";
            }))
    },
    shrinkInput: shrinkInput,
    autoFocused: autoFocused,
    onChange: onChange$1
  };
  var tmp$1 = errored ? "" : undefined;
  if (tmp$1 !== undefined) {
    tmp.errorMessage = tmp$1;
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  var tmp$2;
  if (secondaryValue$1 !== undefined) {
    var formattedSecondaryRetailPrice = Intl$Pos.currencyFormat("EUR", undefined, undefined, secondaryValue$1) + " " + Intl$Pos.t("HT");
    tmp$2 = React.createElement(TextStyle$Pos.make, {
          children: formattedSecondaryRetailPrice,
          variation: "normal",
          size: "tiny"
        });
  } else {
    tmp$2 = null;
  }
  return React.createElement(Stack$Pos.make, {
              children: null,
              space: Belt_Option.isSome(secondaryValue$1) ? "xsmall" : "none"
            }, React.createElement(InputNumberField$Pos.make, tmp), tmp$2);
}

var make = React.memo(PriceRetailTableEditCell, (function (oldProps, newProps) {
        if (oldProps.value === newProps.value && oldProps.secondaryValue === newProps.secondaryValue && oldProps.purchasePrice === newProps.purchasePrice && oldProps.bulkUnit === newProps.bulkUnit && oldProps.autoFocused === newProps.autoFocused) {
          return oldProps.shrinkInput === newProps.shrinkInput;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
