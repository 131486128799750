// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as OrderPdf$Pos from "../../modules/Order/OrderPdf.bs.js";
import * as OrderCart$Pos from "../../modules/Order/OrderCart.bs.js";
import * as OrderEdit$Pos from "./OrderEdit.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as OrderStatus$Pos from "../../modules/Order/OrderStatus.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as OrderEditForm$Pos from "../../modules/Order/OrderEditForm.bs.js";
import * as OrderMemoCard$Pos from "../../modules/Order/OrderMemoCard.bs.js";
import * as Accounting__Reducer from "@wino/accounting/src/Accounting__Reducer.bs.js";
import * as TriggerDownload$Pos from "../../primitives/TriggerDownload.bs.js";
import * as OrderPageActions$Pos from "../../modules/Order/OrderPageActions.bs.js";
import * as OrderStatusBadges$Pos from "../../modules/Order/OrderStatusBadges.bs.js";
import * as OrderEditActionsList$Pos from "../../modules/Order/OrderEditActionsList.bs.js";
import * as OrderInformationCard$Pos from "../../modules/Order/OrderInformationCard.bs.js";
import * as OrderCartCsvExporting$Pos from "../../modules/Order/OrderCartCsvExporting.bs.js";
import * as OrderConditionAndNotesCard$Pos from "../../modules/Order/OrderConditionAndNotesCard.bs.js";
import * as OrderSupplierInformationCard$Pos from "../../modules/Order/OrderSupplierInformationCard.bs.js";

function OrderEditPage(Props) {
  var id = Props.id;
  var name = Props.name;
  var updatedAt = Props.updatedAt;
  var statuses = Props.statuses;
  var receptionFinishedAt = Props.receptionFinishedAt;
  var cartRowErrors = Props.cartRowErrors;
  var onRequestCartRowsError = Props.onRequestCartRowsError;
  var match = Navigation$Pos.useUrl(undefined);
  var pathname = match.pathname;
  var notifier = Notifier$Pos.use(undefined, undefined);
  var state = Curry._1(OrderEditForm$Pos.useFormState, undefined);
  var dispatch = Curry._1(OrderEditForm$Pos.useFormDispatch, undefined);
  var match$1 = React.useState(function () {
        return OrderEdit$Pos.isInitialFullEdition(statuses, Belt_Option.isSome(updatedAt));
      });
  var setEdition = match$1[1];
  var edition = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setEditedMemo = match$2[1];
  var editedMemo = match$2[0];
  React.useEffect((function () {
          if (!edition) {
            Curry._1(notifier.clear, undefined);
            Curry._1(onRequestCartRowsError, []);
          }
          
        }), [
        updatedAt,
        edition
      ]);
  React.useEffect((function () {
          if (OrderStatus$Pos.has(statuses, "RECEIVING")) {
            Curry._1(setEdition, (function (param) {
                    return true;
                  }));
          }
          
        }), [OrderStatus$Pos.has(statuses, "RECEIVING")]);
  React.useEffect((function () {
          var match = state.submission;
          if (typeof match !== "number" && match.TAG === /* Succeeded */1) {
            Curry._1(setEdition, (function (param) {
                    return false;
                  }));
          }
          
        }), [state.submission]);
  React.useEffect((function () {
          if (state.status !== /* Pristine */0 && state.submission !== /* Requested */1) {
            Curry._1(setEdition, (function (param) {
                    return true;
                  }));
          } else {
            Curry._1(setEditedMemo, (function (param) {
                    return false;
                  }));
          }
        }), [state.status]);
  React.useEffect((function () {
          if (!edition && state.values.note !== state.initialValues.note) {
            Curry._1(setEditedMemo, (function (param) {
                    return true;
                  }));
          }
          
        }), [state.values.note]);
  var onRequestDispatch = function (action) {
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* Cart */6,
          _1: (function (prevCart) {
              return Accounting__Reducer.reducer(prevCart, action);
            })
        });
  };
  var renderPageTitleEnd = function (param) {
    var match = edition && !editedMemo;
    if (match) {
      return null;
    } else {
      return React.createElement(OrderStatusBadges$Pos.make, {
                  statuses: statuses
                });
    }
  };
  var handlesActionList = React.useCallback((function (action) {
          switch (action) {
            case /* Edition */0 :
                return Curry._1(setEdition, (function (param) {
                              return true;
                            }));
            case /* DownloadPDF */1 :
                var orderId = state.id;
                if (orderId !== undefined) {
                  Future.tapError(OrderPdf$Pos.requestAndOpen(orderId, name), (function (param) {
                          Curry._3(notifier.add, {
                                TAG: /* Error */1,
                                _0: OrderPdf$Pos.errorMessage
                              }, undefined, undefined);
                        }));
                  return Curry._1(notifier.clear, undefined);
                } else {
                  return ;
                }
            case /* DownloadCSV */2 :
                var csvBlob = OrderCartCsvExporting$Pos.makeBlob(state.values.cart, name, statuses, state.values.supplierName, state.values.supplierAddress, state.values.supplierPostalCode, state.values.supplierCity, state.values.supplierCountry);
                if (csvBlob.TAG !== /* Ok */0) {
                  return Curry._3(notifier.add, {
                              TAG: /* Error */1,
                              _0: "Something went wrong"
                            }, undefined, undefined);
                }
                Future.tapError(TriggerDownload$Pos.fromBlob(csvBlob._0, OrderCartCsvExporting$Pos.makeFilename(statuses, name)), (function (param) {
                        Curry._3(notifier.add, {
                              TAG: /* Error */1,
                              _0: "Something went wrong"
                            }, undefined, undefined);
                      }));
                return ;
            
          }
        }), [
        state.id,
        state.values.cart,
        name,
        statuses
      ]);
  var renderPageActions = function (param) {
    return React.createElement(Inline$Pos.make, {
                children: null,
                space: "small"
              }, edition ? null : React.createElement(OrderEditActionsList$Pos.make, {
                      id: id,
                      statuses: statuses,
                      onRequestAction: handlesActionList
                    }), React.createElement(OrderPageActions$Pos.make, {
                    id: id,
                    statuses: statuses,
                    edition: edition,
                    onRequestCancelEdition: (function (param) {
                        Curry._1(setEdition, (function (param) {
                                return false;
                              }));
                      })
                  }));
  };
  var shouldBlockOnRouteChange = React.useCallback((function (nextRoute) {
          var orderBaseRoute = LegacyRouter$Pos.routeToPathname(/* Order */0);
          var allowed = pathname === orderBaseRoute + "/create" && nextRoute.startsWith(orderBaseRoute + "/");
          if (allowed) {
            return false;
          } else {
            return state.status !== /* Pristine */0;
          }
        }), [state.status]);
  var editable = OrderStatus$Pos.has(statuses, "DRAFT") || edition && !editedMemo;
  var match$3 = Belt_Array.getExn(statuses, 0);
  var tmp;
  var exit = 0;
  if (match$3 === "RECEIVING" && editable) {
    tmp = Intl$Pos.t("Reception");
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = edition && !editedMemo ? Intl$Pos.t("Edition") : " ";
  }
  var formattedTitle = Intl$Pos.template(Intl$Pos.t("Order{{entilted}}{{name}}"), {
        entilted: tmp,
        name: Intl$Pos.t(name)
      }, undefined);
  return React.createElement(React.Fragment, undefined, React.createElement(Navigation$Pos.Prompt.make, {
                  message: Intl$Pos.t("Some changes in the order have not been saved."),
                  shouldBlockOnRouteChange: shouldBlockOnRouteChange
                }), React.createElement(Page$Pos.make, {
                  children: React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "medium"
                      }, React.createElement(Notifier$Pos.Banner.make, {
                            notifier: notifier
                          }), React.createElement(OrderCart$Pos.make, {
                            orderId: id,
                            cart: state.values.cart,
                            statuses: statuses,
                            editable: editable,
                            rowErrors: cartRowErrors,
                            edition: edition,
                            onRequestCancelEdition: (function (param) {
                                Curry._1(setEdition, (function (param) {
                                        return false;
                                      }));
                              }),
                            onRequestCartRowsError: onRequestCartRowsError,
                            onRequestDispatch: onRequestDispatch
                          }), React.createElement(Columns$Pos.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Column$Pos.make, {
                                children: React.createElement(Stack$Pos.make, {
                                      children: null,
                                      space: "medium"
                                    }, React.createElement(OrderSupplierInformationCard$Pos.make, {
                                          editable: OrderStatus$Pos.has(statuses, "DRAFT")
                                        }), React.createElement(OrderInformationCard$Pos.make, {
                                          editable: editable && !OrderEdit$Pos.isLimitedEdition(statuses),
                                          receptionFinishedAt: receptionFinishedAt,
                                          updatedAt: updatedAt
                                        }), React.createElement(OrderMemoCard$Pos.make, {})),
                                width: "one_third"
                              }), React.createElement(Column$Pos.make, {
                                children: React.createElement(Stack$Pos.make, {
                                      children: React.createElement(OrderConditionAndNotesCard$Pos.make, {
                                            editable: editable && !OrderEdit$Pos.isLimitedEdition(statuses)
                                          }),
                                      space: "medium"
                                    }),
                                width: "three_quarter"
                              }))),
                  title: formattedTitle,
                  renderTitleEnd: renderPageTitleEnd,
                  renderActions: renderPageActions
                }));
}

var make = React.memo(OrderEditPage, (function (oldProps, newProps) {
        if (oldProps.id === newProps.id && oldProps.name === newProps.name && oldProps.updatedAt === newProps.updatedAt && oldProps.statuses === newProps.statuses) {
          return oldProps.cartRowErrors === newProps.cartRowErrors;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
