// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../primitives/Intl.bs.js";
import * as Page$Pos from "../resources/layout-and-structure/Page.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../resources/tables/TableView.bs.js";
import * as BarControl$Pos from "../resources/layout-and-structure/BarControl.bs.js";
import * as Pagination$Pos from "../resources/navigation/Pagination.bs.js";
import * as TextIconButton$Pos from "../resources/actions/TextIconButton.bs.js";

var make = React.memo(function (Props) {
      var onPress = Props.onPress;
      return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, {
                              children: Intl$Pos.t("Reset"),
                              size: "small",
                              icon: "close_light",
                              onPress: (function (param) {
                                  Curry._1(onPress, undefined);
                                })
                            })), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined));
    });

var ResetFiltersButton = {
  make: make
};

function ResourceListPage(Props) {
  var title = Props.title;
  var subtitle = Props.subtitle;
  var filtersOpt = Props.filters;
  var actionsOpt = Props.actions;
  var bannerOpt = Props.banner;
  var searchBarOpt = Props.searchBar;
  var tableData = Props.tableData;
  var tableColumns = Props.tableColumns;
  var tableKeyExtractor = Props.tableKeyExtractor;
  var tableEmptyState = Props.tableEmptyState;
  var paginationCurrentPage = Props.paginationCurrentPage;
  var paginationTotalPages = Props.paginationTotalPages;
  var onRequestPaginate = Props.onRequestPaginate;
  var filters = filtersOpt !== undefined ? Caml_option.valFromOption(filtersOpt) : null;
  var actions = actionsOpt !== undefined ? Caml_option.valFromOption(actionsOpt) : null;
  var banner = bannerOpt !== undefined ? Caml_option.valFromOption(bannerOpt) : null;
  var searchBar = searchBarOpt !== undefined ? Caml_option.valFromOption(searchBarOpt) : null;
  var pageVariation = subtitle !== undefined ? "standard" : "compact";
  var tmp = {
    children: null,
    variation: pageVariation,
    title: title
  };
  if (subtitle !== undefined) {
    tmp.subtitle = subtitle;
  }
  return React.createElement(Page$Pos.make, tmp, React.createElement(BarControl$Pos.make, {
                  filters: filters,
                  actions: actions,
                  banner: banner
                }), React.createElement(TableView$Pos.make, {
                  data: tableData,
                  columns: tableColumns,
                  keyExtractor: tableKeyExtractor,
                  placeholderEmptyState: tableEmptyState,
                  searchBar: searchBar
                }), React.createElement(Pagination$Pos.make, {
                  currentPage: paginationCurrentPage,
                  totalPages: paginationTotalPages,
                  onRequestPaginate: onRequestPaginate
                }));
}

var make$1 = ResourceListPage;

export {
  ResetFiltersButton ,
  make$1 as make,
}
/* make Not a pure module */
