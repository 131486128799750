// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../../resources/theme/FontSizes.bs.js";
import * as StockActivityKind$Pos from "./StockActivityKind.bs.js";
import * as StockActivityQuantity$Pos from "./StockActivityQuantity.bs.js";

function StockActivityQuantityTableCell(Props) {
  var value = Props.value;
  var capacityPrecision = Props.capacityPrecision;
  var capacityUnit = Props.capacityUnit;
  var kind = Props.kind;
  return React.createElement(Text$Pos.make, Text$Pos.makeProps(StockActivityQuantity$Pos.format(kind, capacityPrecision, capacityUnit, value), Caml_option.some(Style$Pos.arrayStyle([
                          FontFaces$Pos.libreFranklinSemiBoldStyle,
                          FontSizes$Pos.styleFromSize("normal"),
                          Style$Pos.style(undefined, undefined, undefined, StockActivityKind$Pos.toColor(kind), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
                        ])), undefined, undefined, undefined));
}

var make = React.memo(StockActivityQuantityTableCell);

export {
  make ,
}
/* make Not a pure module */
