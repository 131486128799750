// Generated by ReScript, PLEASE EDIT WITH CARE


function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* ModalCalled */0 :
        return {
                modalOpened: action._0,
                file: state.file,
                loadingState: state.loadingState
              };
    case /* FileImported */1 :
        return {
                modalOpened: state.modalOpened,
                file: {
                  src: action._0,
                  shopId: action._1
                },
                loadingState: state.loadingState
              };
    case /* CartLoadingStateRequested */2 :
        return {
                modalOpened: state.modalOpened,
                file: state.file,
                loadingState: action._0
              };
    
  }
}

var initialState = {
  modalOpened: false,
  file: undefined,
  loadingState: undefined
};

export {
  initialState ,
  reducer ,
}
/* No side effect */
