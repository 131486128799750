// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Accounting from "@wino/accounting/src/Accounting.bs.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function makeQuantity(maxStockThreshold, stockRawQuantity, packaging, bulkPrecision, param) {
  var exponent = Belt_Option.mapWithDefault(bulkPrecision, 0, (function (prim) {
          return prim;
        }));
  var defaultQuantity = 1 * Math.pow(10, exponent);
  var defaultQuantity$1 = defaultQuantity | 0;
  var quantity;
  if (maxStockThreshold !== undefined) {
    if (stockRawQuantity !== undefined) {
      if (packaging !== undefined) {
        var quantity$1 = maxStockThreshold - stockRawQuantity | 0;
        var diff = Caml_int32.mod_(quantity$1, packaging);
        quantity = diff !== 0 ? quantity$1 - diff | 0 : quantity$1;
      } else {
        quantity = maxStockThreshold - stockRawQuantity | 0;
      }
    } else {
      quantity = defaultQuantity$1;
    }
  } else {
    quantity = stockRawQuantity !== undefined || packaging === undefined ? defaultQuantity$1 : packaging;
  }
  var match = quantity <= 0;
  if (match) {
    return defaultQuantity$1;
  } else {
    return quantity;
  }
}

function makeProductInput(id, name, description, sku, stockRawQuantityOpt, packagingRawQuantity, taxValue, unitPriceOpt, rawQuantityOpt, bulkPrecision, capacityUnit, param) {
  var stockRawQuantity = stockRawQuantityOpt !== undefined ? stockRawQuantityOpt : 0;
  var unitPrice = unitPriceOpt !== undefined ? unitPriceOpt : 0;
  var rawQuantity = rawQuantityOpt !== undefined ? rawQuantityOpt : 0;
  if (bulkPrecision === undefined) {
    return {
            TAG: /* Unit */0,
            product: {
              id: undefined,
              identifier: id,
              stockKeepingUnit: sku,
              name: name,
              description: description,
              capacityUnit: undefined,
              stock: stockRawQuantity,
              packaging: packagingRawQuantity,
              quantity: rawQuantity,
              expectedQuantity: undefined,
              unitPrice: unitPrice,
              fees: [],
              discounts: [],
              tax: taxValue
            }
          };
  }
  var quantity = Accounting.fromRawProductQuantity(bulkPrecision, rawQuantity).toNumber();
  var stock = Accounting.fromRawProductQuantity(bulkPrecision, stockRawQuantity).toNumber();
  var packaging = Belt_Option.map(packagingRawQuantity, (function (value) {
          return Accounting.fromRawProductQuantity(bulkPrecision, value).toNumber();
        }));
  return {
          TAG: /* Bulk */1,
          product: {
            id: undefined,
            identifier: id,
            stockKeepingUnit: sku,
            name: name,
            description: description,
            capacityUnit: capacityUnit,
            stock: stock,
            packaging: packaging,
            quantity: quantity,
            expectedQuantity: undefined,
            unitPrice: unitPrice,
            fees: [],
            discounts: [],
            tax: taxValue
          },
          precision: bulkPrecision
        };
}

export {
  makeQuantity ,
  makeProductInput ,
}
/* Accounting Not a pure module */
