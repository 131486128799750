// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Future from "rescript-future/src/Future.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as LoadingRouter$Pos from "../../app/Loading/LoadingRouter.bs.js";

var errorMessage = Intl$Pos.t("Wino could not generate the PDF, please reiterate your request.");

var endpoint = Env$Pos.pdfUrl(undefined) + "/order-document";

function makeRequestBodyJson(orderId) {
  var body = {};
  body["orderId"] = Json$Pos.encodeString(orderId);
  return Json$Pos.encodeDict(body);
}

function decodeAndMakeUrlExn(json) {
  return new URL(Belt_Option.getExn(Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "url")));
}

function requestAndOpen(orderId, orderName) {
  var windowName = Intl$Pos.template(Intl$Pos.t("Download {{orderName}} PDF"), {
        orderName: orderName
      }, undefined);
  var windowUrl = LoadingRouter$Pos.baseRoute(Intl$Pos.template(Intl$Pos.t("Generating PDF of order {{orderName}}"), {
            orderName: orderName
          }, undefined), undefined);
  var loadingWindow = window.open(windowUrl, windowName);
  if (loadingWindow == null) {
    return Future.value({
                TAG: /* Error */1,
                _0: /* OpenWindowError */0
              });
  } else {
    return Future.mapError(Future.tap(Request$Pos.makeAndDecode("POST", Caml_option.some(makeRequestBodyJson(orderId)), undefined, undefined, endpoint), (function (result) {
                      if (result.TAG === /* Ok */0) {
                        var generatedPdfUrl = decodeAndMakeUrlExn(result._0);
                        loadingWindow.location = generatedPdfUrl.href;
                        return ;
                      }
                      loadingWindow.close();
                    })), undefined, (function (error) {
                  return /* RequestError */{
                          _0: error
                        };
                }));
  }
}

export {
  errorMessage ,
  endpoint ,
  makeRequestBodyJson ,
  decodeAndMakeUrlExn ,
  requestAndOpen ,
}
/* errorMessage Not a pure module */
