// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AsyncData$Pos from "./AsyncData.bs.js";

function doneOk(ok) {
  return {
          TAG: /* Done */1,
          _0: {
            TAG: /* Ok */0,
            _0: ok
          }
        };
}

function reloadingOk(ok) {
  return {
          TAG: /* Reloading */0,
          _0: {
            TAG: /* Ok */0,
            _0: ok
          }
        };
}

var map = AsyncData$Pos.map;

function mapOk(asyncResult, transform) {
  return AsyncData$Pos.map(asyncResult, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: Curry._1(transform, result._0)
                        };
                } else {
                  return result;
                }
              }));
}

function mapError(asyncResult, transform) {
  return AsyncData$Pos.map(asyncResult, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return result;
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Curry._1(transform, result._0)
                        };
                }
              }));
}

var notAsked = AsyncData$Pos.notAsked;

var loading = AsyncData$Pos.loading;

var reloading = AsyncData$Pos.reloading;

var done = AsyncData$Pos.done;

var isReloading = AsyncData$Pos.isReloading;

var isBusy = AsyncData$Pos.isBusy;

var isIdle = AsyncData$Pos.isIdle;

var toBusy = AsyncData$Pos.toBusy;

export {
  notAsked ,
  loading ,
  reloading ,
  done ,
  doneOk ,
  reloadingOk ,
  isReloading ,
  isBusy ,
  isIdle ,
  toBusy ,
  map ,
  mapOk ,
  mapError ,
}
/* No side effect */
