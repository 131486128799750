// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Modal$Pos from "../overlays/Modal.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Placeholder$Pos from "../layout-and-structure/Placeholder.bs.js";

function wait(ms) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        resolve(undefined);
                      }), ms);
              }));
}

function SpinnerModal(Props) {
  var title = Props.title;
  var opened = Props.opened;
  var loopMessagesOpt = Props.loopMessages;
  var loopMessages = loopMessagesOpt !== undefined ? loopMessagesOpt : [];
  var url = Navigation$Pos.useUrl(undefined);
  var match = React.useState(function () {
        return Intl$Pos.t("Please wait until the operation is complete");
      });
  var setMessage = match[1];
  React.useEffect((function () {
          if (opened) {
            var index = {
              contents: 0
            };
            var loop = async function (param) {
              while(index.contents <= loopMessages.length) {
                var currentIndex = index.contents;
                var randomTime = Math.random() * 3500.0 + 1000.0;
                await wait(randomTime | 0);
                Curry._1(setMessage, (function(currentIndex){
                    return function (param) {
                      return Belt_Option.getWithDefault(Belt_Array.get(loopMessages, currentIndex), Intl$Pos.t("Please wait until the operation is complete"));
                    }
                    }(currentIndex)));
                index.contents = currentIndex + 1 | 0;
              };
            };
            loop(undefined);
          }
          
        }), [opened]);
  var shouldBlockOnRouteChange = React.useCallback((function (nextRoute) {
          if (opened) {
            return url.pathname !== nextRoute;
          } else {
            return false;
          }
        }), [opened]);
  return React.createElement(React.Fragment, undefined, React.createElement(Navigation$Pos.Prompt.make, {
                  message: Intl$Pos.t("Warning: an operation is still in progress."),
                  shouldBlockOnRouteChange: shouldBlockOnRouteChange
                }), React.createElement(Modal$Pos.make, {
                  children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Placeholder$Pos.make, {
                                    status: /* Loading */0,
                                    customText: match[0]
                                  })), undefined, undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                  opened: opened,
                  title: title,
                  hideFooter: true
                }));
}

var make = React.memo(SpinnerModal);

export {
  make ,
}
/* make Not a pure module */
