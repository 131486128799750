// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Json$Pos from "../primitives/Json.bs.js";
import * as Uuid$Pos from "../externals/Uuid.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var CannotParseDatetime = /* @__PURE__ */Caml_exceptions.create("Scalar-Pos.Datetime.CannotParseDatetime");

var CannotSerializeDatetime = /* @__PURE__ */Caml_exceptions.create("Scalar-Pos.Datetime.CannotSerializeDatetime");

function parse(json) {
  var datetime = Belt_Option.map(Json$Pos.decodeString(json), (function (prim) {
          return new Date(prim);
        }));
  if (datetime !== undefined) {
    var datetime$1 = Caml_option.valFromOption(datetime);
    if (Number.isNaN(datetime$1.getTime())) {
      throw {
            RE_EXN_ID: CannotParseDatetime,
            Error: new Error()
          };
    }
    return datetime$1;
  }
  throw {
        RE_EXN_ID: CannotParseDatetime,
        Error: new Error()
      };
}

var isDate = (date => date instanceof Date);

function serialize(date) {
  if (isDate(date)) {
    return Json$Pos.encodeString(date.toISOString());
  }
  throw {
        RE_EXN_ID: CannotSerializeDatetime,
        Error: new Error()
      };
}

var CannotParseText = /* @__PURE__ */Caml_exceptions.create("Scalar-Pos.Text.CannotParseText");

var CannotSerializeText = /* @__PURE__ */Caml_exceptions.create("Scalar-Pos.Text.CannotSerializeText");

function parse$1(json) {
  var string = Json$Pos.decodeString(json);
  if (string !== undefined) {
    return string;
  }
  throw {
        RE_EXN_ID: CannotParseText,
        Error: new Error()
      };
}

function serialize$1(text) {
  var match = typeof text;
  if (match === "string") {
    return Json$Pos.encodeString(text);
  }
  throw {
        RE_EXN_ID: CannotSerializeText,
        Error: new Error()
      };
}

var $$Text = {
  CannotParseText: CannotParseText,
  CannotSerializeText: CannotSerializeText,
  parse: parse$1,
  serialize: serialize$1
};

var CannotParseCku = /* @__PURE__ */Caml_exceptions.create("Scalar-Pos.CKU.CannotParseCku");

var CannotSerializeCku = /* @__PURE__ */Caml_exceptions.create("Scalar-Pos.CKU.CannotSerializeCku");

function parse$2(json) {
  var val;
  try {
    val = Belt_Option.map(Belt_Option.flatMap(Json$Pos.decodeString(json), Uuid$Pos.fromString), (function (uuid) {
            return [
                    Uuid.version(uuid),
                    uuid
                  ];
          }));
  }
  catch (exn){
    throw {
          RE_EXN_ID: CannotParseCku,
          Error: new Error()
        };
  }
  if (val !== undefined) {
    if (val[0] !== 4) {
      throw {
            RE_EXN_ID: CannotParseCku,
            Error: new Error()
          };
    }
    return val[1];
  }
  throw {
        RE_EXN_ID: CannotParseCku,
        Error: new Error()
      };
}

function serialize$2(cku) {
  var match = Belt_Option.map(Uuid$Pos.fromString(cku), (function (prim) {
          return Uuid.version(prim);
        }));
  if (match !== undefined) {
    if (match !== 4) {
      throw {
            RE_EXN_ID: CannotSerializeCku,
            Error: new Error()
          };
    }
    return Json$Pos.encodeString(cku);
  }
  throw {
        RE_EXN_ID: CannotSerializeCku,
        Error: new Error()
      };
}

var CKU = {
  CannotParseCku: CannotParseCku,
  CannotSerializeCku: CannotSerializeCku,
  parse: parse$2,
  serialize: serialize$2
};

var Datetime = {
  CannotParseDatetime: CannotParseDatetime,
  CannotSerializeDatetime: CannotSerializeDatetime,
  parse: parse,
  serialize: serialize
};

export {
  Datetime ,
  $$Text ,
  CKU ,
}
/* uuid Not a pure module */
