// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as CatalogDuplicationForm$Pos from "./CatalogDuplicationForm.bs.js";
import * as CatalogDuplicationFormMutations$Pos from "./CatalogDuplicationFormMutations.bs.js";

function CatalogDuplicationFormPageActions(Props) {
  var duplicationMode = Props.duplicationMode;
  var duplicateProduct = CatalogDuplicationFormMutations$Pos.useDuplicateProduct(undefined);
  var duplicateVariant = CatalogDuplicationFormMutations$Pos.useDuplicateVariant(undefined);
  return React.createElement(Inline$Pos.make, {
              children: React.createElement(CatalogDuplicationForm$Pos.SubmitButton.make, {
                    variation: "success",
                    size: "medium",
                    onSubmit: duplicationMode ? duplicateVariant : duplicateProduct,
                    text: Intl$Pos.t("Duplicate")
                  }),
              space: "small"
            });
}

var make = React.memo(CatalogDuplicationFormPageActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
