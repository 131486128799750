// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query SupplierQuery($id: ID!)  {\nsupplier(id: $id)  {\n__typename  \nid  \ncompanyName  \nemail  \nphoneNumber  \nmobileNumber  \ncontacts  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nisDefault  \ncivility  \nlastName  \nfirstName  \nemail  \nphoneNumber  \nmobileNumber  \n}\n\n}\n\n}\n\nlocations  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nrecipient  \naddress  \npostalCode  \ncity  \ncountry  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.supplier;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.email;
    var value$3 = value$1.phoneNumber;
    var value$4 = value$1.mobileNumber;
    var value$5 = value$1.contacts;
    var value$6 = value$5.edges;
    var value$7 = value$1.locations;
    var value$8 = value$7.edges;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      companyName: value$1.companyName,
      email: !(value$2 == null) ? value$2 : undefined,
      phoneNumber: !(value$3 == null) ? value$3 : undefined,
      mobileNumber: !(value$4 == null) ? value$4 : undefined,
      contacts: {
        __typename: value$5.__typename,
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.civility;
                var tmp;
                if (value$2 == null) {
                  tmp = undefined;
                } else {
                  var tmp$1;
                  switch (value$2) {
                    case "MR" :
                        tmp$1 = "MR";
                        break;
                    case "MRS" :
                        tmp$1 = "MRS";
                        break;
                    case "NEUTRAL" :
                        tmp$1 = "NEUTRAL";
                        break;
                    default:
                      throw {
                            RE_EXN_ID: "Not_found",
                            Error: new Error()
                          };
                  }
                  tmp = tmp$1;
                }
                var value$3 = value$1.firstName;
                var value$4 = value$1.email;
                var value$5 = value$1.phoneNumber;
                var value$6 = value$1.mobileNumber;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          isDefault: value$1.isDefault,
                          civility: tmp,
                          lastName: value$1.lastName,
                          firstName: !(value$3 == null) ? value$3 : undefined,
                          email: !(value$4 == null) ? value$4 : undefined,
                          phoneNumber: !(value$5 == null) ? value$5 : undefined,
                          mobileNumber: !(value$6 == null) ? value$6 : undefined
                        }
                      };
              }), value$6)
      },
      locations: {
        __typename: value$7.__typename,
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.recipient;
                var value$3 = value$1.address;
                var value$4 = value$1.postalCode;
                var value$5 = value$1.city;
                var value$6 = value$1.country;
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          recipient: !(value$2 == null) ? value$2 : undefined,
                          address: !(value$3 == null) ? value$3 : undefined,
                          postalCode: !(value$4 == null) ? value$4 : undefined,
                          city: !(value$5 == null) ? value$5 : undefined,
                          country: !(value$6 == null) ? value$6 : undefined
                        }
                      };
              }), value$8)
      }
    };
  }
  return {
          supplier: tmp
        };
}

function serialize(value) {
  var value$1 = value.supplier;
  var supplier;
  if (value$1 !== undefined) {
    var value$2 = value$1.locations;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.country;
            var country = value$2 !== undefined ? value$2 : null;
            var value$3 = value$1.city;
            var city = value$3 !== undefined ? value$3 : null;
            var value$4 = value$1.postalCode;
            var postalCode = value$4 !== undefined ? value$4 : null;
            var value$5 = value$1.address;
            var address = value$5 !== undefined ? value$5 : null;
            var value$6 = value$1.recipient;
            var recipient = value$6 !== undefined ? value$6 : null;
            var value$7 = value$1.__typename;
            var node = {
              __typename: value$7,
              recipient: recipient,
              address: address,
              postalCode: postalCode,
              city: city,
              country: country
            };
            var value$8 = value.__typename;
            return {
                    __typename: value$8,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.__typename;
    var locations = {
      __typename: value$4,
      edges: edges
    };
    var value$5 = value$1.contacts;
    var value$6 = value$5.edges;
    var edges$1 = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.mobileNumber;
            var mobileNumber = value$2 !== undefined ? value$2 : null;
            var value$3 = value$1.phoneNumber;
            var phoneNumber = value$3 !== undefined ? value$3 : null;
            var value$4 = value$1.email;
            var email = value$4 !== undefined ? value$4 : null;
            var value$5 = value$1.firstName;
            var firstName = value$5 !== undefined ? value$5 : null;
            var value$6 = value$1.lastName;
            var value$7 = value$1.civility;
            var civility = value$7 !== undefined ? (
                value$7 === "MR" ? "MR" : (
                    value$7 === "MRS" ? "MRS" : "NEUTRAL"
                  )
              ) : null;
            var value$8 = value$1.isDefault;
            var value$9 = value$1.__typename;
            var node = {
              __typename: value$9,
              isDefault: value$8,
              civility: civility,
              lastName: value$6,
              firstName: firstName,
              email: email,
              phoneNumber: phoneNumber,
              mobileNumber: mobileNumber
            };
            var value$10 = value.__typename;
            return {
                    __typename: value$10,
                    node: node
                  };
          }), value$6);
    var value$7 = value$5.__typename;
    var contacts = {
      __typename: value$7,
      edges: edges$1
    };
    var value$8 = value$1.mobileNumber;
    var mobileNumber = value$8 !== undefined ? value$8 : null;
    var value$9 = value$1.phoneNumber;
    var phoneNumber = value$9 !== undefined ? value$9 : null;
    var value$10 = value$1.email;
    var email = value$10 !== undefined ? value$10 : null;
    var value$11 = value$1.companyName;
    var value$12 = value$1.id;
    var value$13 = value$1.__typename;
    supplier = {
      __typename: value$13,
      id: value$12,
      companyName: value$11,
      email: email,
      phoneNumber: phoneNumber,
      mobileNumber: mobileNumber,
      contacts: contacts,
      locations: locations
    };
  } else {
    supplier = null;
  }
  return {
          supplier: supplier
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var SupplierQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var SupplierQuery_refetchQueryDescription = include.refetchQueryDescription;

var SupplierQuery_use = include.use;

var SupplierQuery_useLazy = include.useLazy;

var SupplierQuery_useLazyWithVariables = include.useLazyWithVariables;

var SupplierQuery = {
  SupplierQuery_inner: SupplierQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: SupplierQuery_refetchQueryDescription,
  use: SupplierQuery_use,
  useLazy: SupplierQuery_useLazy,
  useLazyWithVariables: SupplierQuery_useLazyWithVariables
};

export {
  SupplierQuery ,
}
/* query Not a pure module */
