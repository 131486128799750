// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Button$Pos from "./Button.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";

function ButtonLink(Props) {
  var children = Props.children;
  var variation = Props.variation;
  var size = Props.size;
  var disabledOpt = Props.disabled;
  var focused = Props.focused;
  var icon = Props.icon;
  var to = Props.to;
  var openNewTabOpt = Props.openNewTab;
  var onPress = Props.onPress;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var openNewTab = openNewTabOpt !== undefined ? openNewTabOpt : false;
  var tmp = {
    children: React.createElement(Button$Pos.make, Button$Pos.makeProps(children, size, variation, undefined, undefined, undefined, disabled, focused, icon, undefined, undefined, undefined, undefined, undefined)),
    to: to,
    openNewTab: openNewTab,
    disabled: disabled
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  return React.createElement(Navigation$Pos.Link.make, tmp);
}

var make = React.memo(ButtonLink);

export {
  make ,
}
/* make Not a pure module */
