// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      h1: {
        color: "#25243a",
        font: "normal bold 26px \"Archivo\""
      },
      h2: {
        color: "#25243a",
        font: "normal bold 22px \"Archivo\""
      },
      h3: {
        color: "#25243a",
        font: "normal normal 18px \"Archivo\""
      },
      h4: {
        color: "#25243a",
        font: "normal 500 16px \"Archivo\""
      },
      h5: {
        color: "#a2a1b0",
        font: "normal 500 14px \"Archivo\""
      },
      h6: {
        color: "#25243a",
        font: "normal 400 12px \"Archivo\""
      },
      alignLeft: {
        textAlign: "left"
      },
      alignCenter: {
        textAlign: "center"
      },
      alignRight: {
        textAlign: "right"
      }
    });

function styleFromLevel(level) {
  if (level === 2) {
    return styles.h2;
  } else if (level === 3) {
    return styles.h3;
  } else if (level === 4) {
    return styles.h4;
  } else if (level === 5) {
    return styles.h5;
  } else if (level === 6) {
    return styles.h6;
  } else {
    return styles.h1;
  }
}

function styleFromAlign(align) {
  if (align === "start") {
    return styles.alignLeft;
  } else if (align === "center") {
    return styles.alignCenter;
  } else {
    return styles.alignRight;
  }
}

function styleFromWeight(weight) {
  return [weight !== undefined ? (
              weight === "regular" ? ({
                    fontWeight: 400
                  }) : (
                  weight === "strong" ? ({
                        fontWeight: 600
                      }) : ({
                        fontWeight: 500
                      })
                )
            ) : ({})];
}

function styleProps(level, align, weight, color) {
  var tmp = {};
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  return Stylex.props(styleFromLevel(level), styleFromAlign(align), styleFromWeight(weight), tmp);
}

function Title(Props) {
  var children = Props.children;
  var levelOpt = Props.level;
  var alignOpt = Props.align;
  var weight = Props.weight;
  var color = Props.color;
  var level = levelOpt !== undefined ? levelOpt : 1;
  var align = alignOpt !== undefined ? alignOpt : "start";
  var match = styleProps(level, align, weight, color);
  var className = match.className;
  var style = match.style;
  if (level === 2) {
    var tmp = {};
    if (className !== undefined) {
      tmp.className = Caml_option.valFromOption(className);
    }
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    return React.createElement("h2", tmp, children);
  }
  if (level === 3) {
    var tmp$1 = {};
    if (className !== undefined) {
      tmp$1.className = Caml_option.valFromOption(className);
    }
    if (style !== undefined) {
      tmp$1.style = Caml_option.valFromOption(style);
    }
    return React.createElement("h3", tmp$1, children);
  }
  if (level === 4) {
    var tmp$2 = {};
    if (className !== undefined) {
      tmp$2.className = Caml_option.valFromOption(className);
    }
    if (style !== undefined) {
      tmp$2.style = Caml_option.valFromOption(style);
    }
    return React.createElement("h4", tmp$2, children);
  }
  if (level === 5) {
    var tmp$3 = {};
    if (className !== undefined) {
      tmp$3.className = Caml_option.valFromOption(className);
    }
    if (style !== undefined) {
      tmp$3.style = Caml_option.valFromOption(style);
    }
    return React.createElement("h5", tmp$3, children);
  }
  if (level === 6) {
    var tmp$4 = {};
    if (className !== undefined) {
      tmp$4.className = Caml_option.valFromOption(className);
    }
    if (style !== undefined) {
      tmp$4.style = Caml_option.valFromOption(style);
    }
    return React.createElement("h6", tmp$4, children);
  }
  var tmp$5 = {};
  if (className !== undefined) {
    tmp$5.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp$5.style = Caml_option.valFromOption(style);
  }
  return React.createElement("h1", tmp$5, children);
}

var make = React.memo(Title);

export {
  make ,
}
/* styles Not a pure module */
