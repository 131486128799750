// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Belt_List from "rescript/lib/es6/belt_List.js";

function reducer(state, action) {
  if (typeof action === "number") {
    return /* [] */0;
  }
  if (action.TAG === /* AddRequested */0) {
    var match = action._0;
    var details = match.details;
    var message = match.message;
    var kind = match.kind;
    if (match.reset) {
      return {
              hd: {
                id: Uuid.v4(),
                content: {
                  message: message,
                  details: details,
                  kind: kind
                }
              },
              tl: /* [] */0
            };
    } else {
      return Belt_List.add(state, {
                  id: Uuid.v4(),
                  content: {
                    message: message,
                    details: details,
                    kind: kind
                  }
                });
    }
  }
  var id = action._0;
  return Belt_List.keep(state, (function (n) {
                return n.id !== id;
              }));
}

var initialState = /* [] */0;

export {
  initialState ,
  reducer ,
}
/* uuid Not a pure module */
