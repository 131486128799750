// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as StockTransferForm$Pos from "../../modules/StockTransfer/StockTransferForm.bs.js";
import * as StockTransferFormActions$Pos from "../../modules/StockTransfer/StockTransferFormActions.bs.js";
import * as StockTransferFormProductsCard$Pos from "../../modules/StockTransfer/StockTransferFormProductsCard.bs.js";
import * as StockTransferFormSenderInformationCard$Pos from "../../modules/StockTransfer/StockTransferFormSenderInformationCard.bs.js";
import * as StockTransferFormRecipientInformationCard$Pos from "../../modules/StockTransfer/StockTransferFormRecipientInformationCard.bs.js";

function StockTransferCreatePage(Props) {
  var notifier = Notifier$Pos.use(undefined, undefined);
  var shops = Auth$Pos.useShops(undefined);
  var onSubmitFailure = React.useCallback(function (message) {
        Curry._3(notifier.reset, {
              TAG: /* Error */1,
              _0: message
            }, undefined, undefined);
      });
  var onSubmitSuccess = React.useCallback(function (submissionResponse) {
        if (submissionResponse !== undefined) {
          return Curry._1(notifier.clear, undefined);
        } else {
          return Curry._3(notifier.add, {
                      TAG: /* Error */1,
                      _0: Request$Pos.serverErrorMessage
                    }, undefined, undefined);
        }
      });
  var match = Curry._1(StockTransferForm$Pos.useFormPropState, {
        initialValues: StockTransferForm$Pos.initialValues,
        schema: StockTransferForm$Pos.schema,
        resetValuesAfterSubmission: true,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var formState = match[0];
  var productsRowErrors = StockTransferForm$Pos.productsError(formState.values.products);
  var oldFormStatus = React.useRef(formState.status);
  React.useEffect((function () {
          var match = formState.submission;
          if (typeof match !== "number" && match.TAG === /* Succeeded */1) {
            var match$1 = formState.values;
            var recipientShopId = match$1.recipientShopId;
            var senderShopId = match$1.senderShopId;
            var senderShop = Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === senderShopId;
                  }));
            var recipientShop = Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === recipientShopId;
                  }));
            var strLiteral = Intl$Pos.isPlural(formState.values.products.length) ? "All seized stocks were successfully transferred from {{sender}} to {{recipient}}!" : "The seized stock was successfully transferred from {{sender}} to {{recipient}}!";
            var notification = Intl$Pos.template(Intl$Pos.t(strLiteral), {
                  sender: senderShop.name,
                  recipient: recipientShop.name
                }, undefined);
            Curry._3(notifier.reset, {
                  TAG: /* Success */0,
                  _0: notification
                }, undefined, undefined);
          }
          
        }), [formState.submission]);
  React.useEffect((function () {
          if (oldFormStatus.current === /* Pristine */0 && formState.status !== /* Pristine */0) {
            Curry._1(notifier.clear, undefined);
          }
          oldFormStatus.current = formState.status;
        }), [formState.status]);
  return React.createElement(StockTransferForm$Pos.Core.Provider.make, {
              children: React.createElement(Page$Pos.make, {
                    children: null,
                    title: Intl$Pos.t("Stock transfer"),
                    renderActions: (function (param) {
                        return React.createElement(StockTransferFormActions$Pos.make, {});
                      })
                  }, React.createElement(Notifier$Pos.Banner.make, {
                        notifier: notifier
                      }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                                    children: null,
                                    space: "medium"
                                  }, React.createElement(Columns$Pos.make, {
                                        children: null,
                                        space: "large"
                                      }, React.createElement(Column$Pos.make, {
                                            children: React.createElement(StockTransferFormSenderInformationCard$Pos.make, {}),
                                            width: "half"
                                          }), React.createElement(Column$Pos.make, {
                                            children: React.createElement(StockTransferFormRecipientInformationCard$Pos.make, {}),
                                            width: "half"
                                          })), React.createElement(StockTransferFormProductsCard$Pos.make, {
                                        productsRowErrors: productsRowErrors
                                      }))), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))),
              propState: [
                formState,
                match[1]
              ]
            });
}

var make = React.memo(StockTransferCreatePage);

export {
  make ,
}
/* make Not a pure module */
