/* TypeScript file generated from HelpCenter.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as HelpCenterBS__Es6Import from './HelpCenter.bs';
const HelpCenterBS: any = HelpCenterBS__Es6Import;

export const install: (_1:{ readonly appId: string }) => void = function (Arg1: any) {
  const result = HelpCenterBS.install(Arg1.appId);
  return result
};
