// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as TooltipIcon$Pos from "../../resources/overlays/TooltipIcon.bs.js";
import * as CatalogTableActions$Pos from "./CatalogTableActions.bs.js";
import * as CatalogProductStatusBadge$Pos from "./CatalogProductStatusBadge.bs.js";
import * as CatalogProductNameTableCell$Pos from "./CatalogProductNameTableCell.bs.js";
import * as CatalogVariantStockThresholdCell$Pos from "./CatalogVariantStockThresholdCell.bs.js";
import * as PricePurchaseTableEditCellWrapper$Pos from "../Price/PricePurchaseTableEditCellWrapper.bs.js";

function keyExtractor(row) {
  return row.id + "-variant";
}

var Row = {
  keyExtractor: keyExtractor
};

function makeTextItemStyle(priceIsDefault) {
  return Style$Pos.merge([
              priceIsDefault ? FontFaces$Pos.libreFranklinSemiBoldStyle : FontFaces$Pos.libreFranklinRegularStyle,
              Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Spaces$Pos.large, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
            ]);
}

var make = React.memo(function (Props) {
      var formattedRetailPrice = Props.formattedRetailPrice;
      var retailPrices = Props.retailPrices;
      var ref = React.useRef(null);
      var formattedNewlinedPrices = Belt_Array.map(retailPrices, (function (param) {
              var taxType = Intl$Pos.t(param.taxIncluded ? "VAT incl." : "VAT excl.");
              return React.createElement(Text$Pos.make, Text$Pos.makeProps("" + param.name + " " + taxType + " : " + param.formattedValue + "\n", Caml_option.some(makeTextItemStyle(param.default)), undefined, undefined, undefined));
            }));
      return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                              children: null,
                              space: "small"
                            }, React.createElement(TextStyle$Pos.make, {
                                  children: formattedRetailPrice
                                }), retailPrices.length !== 0 ? React.createElement(TooltipIcon$Pos.make, {
                                    children: formattedNewlinedPrices,
                                    variation: "info",
                                    altTriggerRef: ref
                                  }) : null)), undefined, undefined, ref, undefined));
    });

var RetailPriceDetailsCell = {
  makeTextItemStyle: makeTextItemStyle,
  make: make
};

function tableColumns(allShopsFiltered) {
  return [
          {
            key: "variant-name",
            name: Intl$Pos.t("Name"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 170
              },
              width: {
                NAME: "fr",
                VAL: 1.5
              }
            },
            render: (function (param) {
                var match = param.data;
                var shopName = allShopsFiltered ? match.shopName : undefined;
                var tmp = {
                  cku: match.cku,
                  name: match.name
                };
                if (shopName !== undefined) {
                  tmp.shopName = Caml_option.valFromOption(shopName);
                }
                return React.createElement(CatalogProductNameTableCell$Pos.make, tmp);
              })
          },
          {
            key: "variant-purchaseprice",
            name: Intl$Pos.t("Purchase price"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                var formattedPurchasePrice = match.formattedPurchasePrice;
                if (formattedPurchasePrice === "VARIABLE") {
                  return React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Differing"),
                              variation: "normal",
                              size: "small"
                            });
                }
                return React.createElement(PricePurchaseTableEditCellWrapper$Pos.make, {
                            value: match.purchasePrice,
                            formattedPurchasePrice: formattedPurchasePrice,
                            bulkUnit: match.bulkUnit,
                            variantId: match.id
                          });
              })
          },
          {
            key: "variant-retailprice",
            name: Intl$Pos.t("Retail price"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(make, {
                            formattedRetailPrice: match.formattedRetailPrice,
                            retailPrices: match.retailPrices
                          });
              })
          },
          {
            key: "variant-stock",
            name: Intl$Pos.t("Stock"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                var stock = match.stock;
                return React.createElement(CatalogVariantStockThresholdCell$Pos.make, {
                            stockQuantity: stock.quantity,
                            stockState: stock.state,
                            formattedShopsNames: match.shopName,
                            id: match.id,
                            maxStockThreshold: match.maxStockThreshold,
                            minStockThreshold: match.minStockThreshold,
                            stockOrderTriggerThreshold: match.stockOrderTriggerThreshold,
                            bulk: match.bulk
                          });
              })
          },
          {
            key: "variant-status",
            name: Intl$Pos.t("Status"),
            render: (function (param) {
                var status = param.data.status;
                if (status !== undefined) {
                  return React.createElement(CatalogProductStatusBadge$Pos.make, {
                              value: status
                            });
                } else {
                  return React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Differing"),
                              variation: "normal"
                            });
                }
              })
          },
          {
            key: "variant-actions",
            layout: {
              width: {
                NAME: "fr",
                VAL: 0
              },
              alignX: "flexEnd"
            },
            render: (function (param) {
                var match = param.data;
                var tmp = {
                  cku: match.cku,
                  id: match.id
                };
                if (match.status !== undefined) {
                  tmp.status = Caml_option.valFromOption(match.status);
                }
                return React.createElement(CatalogTableActions$Pos.make, tmp);
              })
          }
        ];
}

function CatalogProductVariantTableCard(Props) {
  var shopsProductVariants = Props.shopsProductVariants;
  var match = Auth$Pos.useScope(undefined);
  var allShopsFiltered;
  allShopsFiltered = match.TAG === /* Organisation */0 ? match.activeShop === undefined : false;
  var columns = React.useMemo((function () {
          return tableColumns(allShopsFiltered);
        }), [allShopsFiltered]);
  var rows = Belt_Array.concatMany(shopsProductVariants);
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TableView$Pos.make, {
                                data: {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Ok */0,
                                    _0: rows
                                  }
                                },
                                columns: columns,
                                keyExtractor: keyExtractor,
                                hideCard: true,
                                maxHeight: 500,
                                placeholderEmptyState: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                                  children: React.createElement(TextStyle$Pos.make, {
                                                        children: Intl$Pos.t("No product variant has been yet recorded"),
                                                        align: "center",
                                                        variation: "normal",
                                                        size: "small"
                                                      }),
                                                  align: "center"
                                                })), "large", "xlarge", undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor05, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined))
                              })), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              title: Intl$Pos.t("Product's variants"),
              variation: "table"
            });
}

var make$1 = React.memo(CatalogProductVariantTableCard);

var Config;

export {
  Config ,
  Row ,
  RetailPriceDetailsCell ,
  tableColumns ,
  make$1 as make,
}
/* make Not a pure module */
