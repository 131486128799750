// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as CustomerListPage$Pos from "./CustomerListPage.bs.js";

var baseRoute = "/customers";

function CustomerRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (subUrlPath) {
    return React.createElement(Navigation$Pos.Redirect.make, {
                route: baseRoute
              });
  } else {
    return React.createElement(CustomerListPage$Pos.make, {
                request: CustomerListPage$Pos.CustomersExportRequest.make
              });
  }
}

var make = CustomerRouter;

export {
  baseRoute ,
  make ,
}
/* react Not a pure module */
