// Generated by ReScript, PLEASE EDIT WITH CARE


var Style = {};

function toPx(value) {
  return String(value) + "px";
}

export {
  Style ,
  toPx ,
}
/* No side effect */
