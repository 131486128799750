// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as BigJs from "big.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Strong$Pos from "../../resources/typography/Strong.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as TextIconButton$Pos from "../../resources/actions/TextIconButton.bs.js";
import * as InputNumberField$Pos from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as CartDiscount__Utils$Pos from "./CartDiscount__Utils.bs.js";
import * as InputSegmentedControlsField$Pos from "../../resources/selection-and-input/InputSegmentedControlsField.bs.js";

function makeDefaultDiscount(product) {
  var quantity;
  quantity = product.TAG === /* Unit */0 ? product._0.quantity : product._0.quantity.toNumber();
  return {
          name: "",
          kind: /* Percent */1,
          value: 0,
          quantity: BigJs.Big(quantity),
          id: "",
          formattedValue: undefined,
          amount: undefined,
          formattedAmount: undefined,
          warnings: []
        };
}

function CartProductDiscountFieldset(Props) {
  var product = Props.product;
  var discountOpt = Props.discount;
  var decimalPrecision = Props.decimalPrecision;
  var onPressRemoveDiscount = Props.onPressRemoveDiscount;
  var dispatch = Props.onRequestDispatch;
  var discount = discountOpt !== undefined ? discountOpt : makeDefaultDiscount(product);
  var match = React.useState(function () {
        return discount.value;
      });
  var setValue = match[1];
  var value = match[0];
  var match$1;
  if (product.TAG === /* Unit */0) {
    var match$2 = product._0;
    match$1 = [
      match$2.id,
      match$2.formattedTotalPrice,
      match$2.formattedTotalLocalDiscounts
    ];
  } else {
    var match$3 = product._0;
    match$1 = [
      match$3.id,
      match$3.formattedTotalPrice,
      match$3.formattedTotalLocalDiscounts
    ];
  }
  var productId = match$1[0];
  var match$4;
  match$4 = product.TAG === /* Unit */0 ? [
      product._0.quantity,
      0
    ] : [
      product._0.quantity.toNumber(),
      product._1
    ];
  var productQuantity = match$4[0];
  var totalAmount;
  if (product.TAG === /* Unit */0) {
    var totalAmount$1 = product._0.totalAmountExcludingGlobalDiscounts;
    totalAmount = totalAmount$1 !== undefined ? Caml_option.valFromOption(totalAmount$1).toNumber() : 0;
  } else {
    var totalAmount$2 = product._0.totalAmountExcludingGlobalDiscounts;
    totalAmount = totalAmount$2 !== undefined ? Caml_option.valFromOption(totalAmount$2).toNumber() : 0;
  }
  ReactUpdateEffect$Pos.use1((function (param) {
          var match = discount.id;
          var exit = 0;
          if (match === "" && value > 0) {
            Curry._1(dispatch, {
                  TAG: /* ProductDiscountAdded */13,
                  _0: productId,
                  _1: {
                    id: undefined,
                    name: "",
                    kind: /* Percent */1,
                    value: value,
                    quantity: 0
                  }
                });
          } else {
            exit = 1;
          }
          if (exit === 1) {
            var match$1 = discount.kind;
            var tmp;
            switch (match$1) {
              case /* Currency */0 :
                  var match$2 = value > totalAmount;
                  tmp = {
                    name: discount.name,
                    kind: discount.kind,
                    value: match$2 ? totalAmount : value,
                    quantity: discount.quantity,
                    id: discount.id,
                    formattedValue: discount.formattedValue,
                    amount: discount.amount,
                    formattedAmount: discount.formattedAmount,
                    warnings: discount.warnings
                  };
                  break;
              case /* Percent */1 :
                  var match$3 = value > 100;
                  tmp = {
                    name: discount.name,
                    kind: discount.kind,
                    value: match$3 ? 100 : value,
                    quantity: discount.quantity,
                    id: discount.id,
                    formattedValue: discount.formattedValue,
                    amount: discount.amount,
                    formattedAmount: discount.formattedAmount,
                    warnings: discount.warnings
                  };
                  break;
              case /* Free */2 :
                  tmp = {
                    name: discount.name,
                    kind: discount.kind,
                    value: discount.value,
                    quantity: value > productQuantity ? BigJs.Big(productQuantity) : BigJs.Big(value),
                    id: discount.id,
                    formattedValue: discount.formattedValue,
                    amount: discount.amount,
                    formattedAmount: discount.formattedAmount,
                    warnings: discount.warnings
                  };
                  break;
              
            }
            Curry._1(dispatch, {
                  TAG: /* ProductDiscountUpdated */15,
                  _0: productId,
                  _1: discount.id,
                  _2: tmp
                });
          }
          
        }), [value]);
  var onDiscountKindChange = React.useCallback((function (kind) {
          var match = discount.id;
          if (match === "") {
            return Curry._1(dispatch, {
                        TAG: /* ProductDiscountAdded */13,
                        _0: productId,
                        _1: {
                          id: undefined,
                          name: "",
                          kind: kind,
                          value: 0,
                          quantity: 0
                        }
                      });
          } else {
            return Curry._1(dispatch, {
                        TAG: /* ProductDiscountUpdated */15,
                        _0: productId,
                        _1: discount.id,
                        _2: {
                          name: discount.name,
                          kind: kind,
                          value: 0,
                          quantity: discount.quantity,
                          id: discount.id,
                          formattedValue: discount.formattedValue,
                          amount: discount.amount,
                          formattedAmount: discount.formattedAmount,
                          warnings: discount.warnings
                        }
                      });
          }
        }), [discount]);
  var onPressRemoveDiscount$1 = React.useCallback((function (param) {
          Curry._1(dispatch, {
                TAG: /* ProductDiscountRemoved */14,
                _0: productId,
                _1: discount.id
              });
          Curry._1(onPressRemoveDiscount, undefined);
        }), [discount.id]);
  var match$5 = discount.kind;
  var tmp;
  switch (match$5) {
    case /* Currency */0 :
        var match$6 = totalAmount - 1 <= 0;
        var maxValue = match$6 ? discount.value : undefined;
        var tmp$1 = {
          value: discount.value,
          minValue: 0,
          precision: decimalPrecision,
          label: Intl$Pos.t("Discount amount"),
          appender: {
            TAG: /* Currency */0,
            _0: "EUR"
          },
          onChange: (function (value) {
              Curry._1(setValue, (function (param) {
                      return value;
                    }));
            })
        };
        if (maxValue !== undefined) {
          tmp$1.maxValue = maxValue;
        }
        var match$7 = discount.warnings;
        var tmp$2;
        if (match$7.length !== 1) {
          tmp$2 = null;
        } else {
          var warning = match$7[0];
          tmp$2 = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.template(CartDiscount__Utils$Pos.discountWarningToText(warning), {
                                  max: match$1[1]
                                }, undefined),
                            size: "xsmall"
                          })), "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        }
        tmp = React.createElement(Stack$Pos.make, {
              children: null,
              space: "xsmall"
            }, React.createElement(InputNumberField$Pos.make, tmp$1), tmp$2);
        break;
    case /* Percent */1 :
        tmp = React.createElement(InputNumberField$Pos.make, {
              value: discount.value,
              minValue: 0,
              maxValue: 100,
              precision: 2,
              label: Intl$Pos.t("Discount percentage"),
              appender: /* Percent */0,
              onChange: (function (value) {
                  Curry._1(setValue, (function (param) {
                          return value;
                        }));
                })
            });
        break;
    case /* Free */2 :
        var inputAppender;
        var exit = 0;
        if (product.TAG === /* Unit */0) {
          exit = 1;
        } else {
          var unit = product._0.capacityUnit;
          if (unit !== undefined) {
            inputAppender = {
              TAG: /* Custom */1,
              _0: "/ " + String(productQuantity).replace(".", ",") + " " + unit + ""
            };
          } else {
            exit = 1;
          }
        }
        if (exit === 1) {
          inputAppender = {
            TAG: /* Custom */1,
            _0: "/ " + String(productQuantity) + ""
          };
        }
        tmp = React.createElement(InputNumberField$Pos.make, {
              value: discount.quantity.toNumber(),
              minValue: 0,
              maxValue: productQuantity,
              precision: match$4[1],
              label: Intl$Pos.t("Quantity offered"),
              appender: inputAppender,
              onChange: (function (value) {
                  Curry._1(setValue, (function (param) {
                          return value;
                        }));
                })
            });
        break;
    
  }
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                          children: null,
                          space: "medium"
                        }, React.createElement(Columns$Pos.make, {
                              children: null,
                              space: "medium"
                            }, React.createElement(Column$Pos.make, {
                                  children: React.createElement(InputSegmentedControlsField$Pos.make, {
                                        label: Intl$Pos.t("Discount type"),
                                        value: discount.kind,
                                        required: false,
                                        onChange: onDiscountKindChange,
                                        options: {
                                          hd: /* Percent */1,
                                          tl: {
                                            hd: /* Currency */0,
                                            tl: {
                                              hd: /* Free */2,
                                              tl: /* [] */0
                                            }
                                          }
                                        },
                                        optionToText: CartDiscount__Utils$Pos.kindToText
                                      }),
                                  width: "content"
                                }), React.createElement(Column$Pos.make, {
                                  children: tmp,
                                  width: "fluid"
                                })), React.createElement(Stack$Pos.make, {
                              children: null,
                              space: "xxsmall"
                            }, React.createElement(Strong$Pos.make, {
                                  children: Intl$Pos.template(Intl$Pos.t("Total discount : {{amount}}"), {
                                        amount: Belt_Option.getExn(match$1[2])
                                      }, undefined)
                                }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, {
                                              children: Intl$Pos.t("Remove discount"),
                                              icon: "delete_light",
                                              onPress: onPressRemoveDiscount$1
                                            })), undefined, "xmedium", undefined, "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined))))), undefined, undefined, undefined, undefined));
}

var make = React.memo(CartProductDiscountFieldset, (function (oldProps, newProps) {
        if (Caml_obj.equal(oldProps.product, newProps.product) && Caml_obj.equal(oldProps.discount, newProps.discount)) {
          return oldProps.decimalPrecision === newProps.decimalPrecision;
        } else {
          return false;
        }
      }));

var Utils;

export {
  Utils ,
  makeDefaultDiscount ,
  make ,
}
/* make Not a pure module */
