// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as SettingsRoutes$Pos from "./SettingsRoutes.bs.js";
import * as SettingsUserPage$Pos from "./SettingsUserPage.bs.js";
import * as SettingsShopsPage$Pos from "./SettingsShopsPage.bs.js";
import * as SettingsLabelPrinterPage$Pos from "./SettingsLabelPrinterPage.bs.js";
import * as CustomerBillingAccountInvoices$Pos from "../../modules/CustomerBillingAccount/CustomerBillingAccountInvoices.bs.js";
import * as SettingsCustomerBillingAccountEditPage$Pos from "./SettingsCustomerBillingAccountEditPage.bs.js";
import * as SettingsCustomerBillingAccountShowPage$Pos from "./SettingsCustomerBillingAccountShowPage.bs.js";
import * as SettingsCustomerBillingAccountListInvoicesPage$Pos from "./SettingsCustomerBillingAccountListInvoicesPage.bs.js";

function SettingsRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var appIndexRoute = Props.appIndexRoute;
  var url = Navigation$Pos.useUrl(undefined);
  if (subUrlPath) {
    switch (subUrlPath.hd) {
      case "customer-billing-account" :
          var match = subUrlPath.tl;
          if (!match) {
            return React.createElement(SettingsCustomerBillingAccountShowPage$Pos.make, {
                        customerBillingAccountRequest: SettingsCustomerBillingAccountShowPage$Pos.customerBillingAccountRequest,
                        customerBillingAccountPaymentMethodRequest: SettingsCustomerBillingAccountShowPage$Pos.customerBillingAccountPaymentMethodRequest,
                        customerBillingAccountPaymentMethodUpdateRequest: SettingsCustomerBillingAccountShowPage$Pos.customerBillingAccountPaymentMethodUpdateRequest,
                        customerBillingAccountInvoicesRequest: CustomerBillingAccountInvoices$Pos.CustomerBillingAccountInvoicesRequest.make,
                        customerBillingAccountSubscriptionsRequest: SettingsCustomerBillingAccountShowPage$Pos.customerBillingAccountSubscriptionsRequest
                      });
          }
          switch (match.hd) {
            case "edit" :
                if (!match.tl) {
                  var decodeUrlResult = SettingsRoutes$Pos.decodeEditCustomerBillingAccountQueryString(url.query);
                  if (decodeUrlResult.TAG !== /* Ok */0) {
                    return React.createElement(Navigation$Pos.Redirect.make, {
                                route: appIndexRoute
                              });
                  }
                  var match$1 = decodeUrlResult._0;
                  return React.createElement(SettingsCustomerBillingAccountEditPage$Pos.make, {
                              activeShopId: match$1.activeShopId,
                              corporateName: match$1.corporateName,
                              shopName: match$1.shopName,
                              email: match$1.email,
                              phone: match$1.phone,
                              vatNumber: match$1.vatNumber,
                              billingAddress: match$1.billingAddress,
                              shippingAddress: match$1.shippingAddress,
                              requestCustomerBillingAccountUpdate: SettingsCustomerBillingAccountEditPage$Pos.requestCustomerBillingAccountUpdate
                            });
                }
                break;
            case "invoices" :
                if (!match.tl) {
                  return React.createElement(SettingsCustomerBillingAccountListInvoicesPage$Pos.make, {
                              customerBillingAccountInvoicesRequest: CustomerBillingAccountInvoices$Pos.customerBillingAccountInvoicesRequest
                            });
                }
                break;
            default:
              
          }
          break;
      case "label-printer" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsLabelPrinterPage$Pos.make, {});
          }
          break;
      case "shops" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsShopsPage$Pos.make, {
                        updateShopRequest: SettingsShopsPage$Pos.UpdateShopRequest.make
                      });
          }
          break;
      case "user" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsUserPage$Pos.make, {
                        updateEmailRequest: SettingsUserPage$Pos.updateEmailRequest,
                        updatePasswordRequest: SettingsUserPage$Pos.updatePasswordRequest,
                        updateUserNamesRequest: SettingsUserPage$Pos.updateUserNamesRequest
                      });
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation$Pos.Redirect.make, {
              route: appIndexRoute
            });
}

var make = React.memo(SettingsRouter);

export {
  make ,
}
/* make Not a pure module */
