// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as CatalogVariantStockThresholdForm$Pos from "./CatalogVariantStockThresholdForm.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation updateVariant_stockThresholds($id: ID!, $input: InputUpdateVariant!)  {\nupdateVariant(id: $id, input: $input)  {\n__typename  \nid  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nstock  {\n__typename  \nformattedQuantity  \nstate  \nformattedShopsNames  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.maxStockThreshold;
  var value$3 = value$1.minStockThreshold;
  var value$4 = value$1.stockOrderTriggerThreshold;
  var value$5 = value$1.stock;
  var value$6 = value$5.formattedQuantity;
  var value$7 = value$5.state;
  var tmp;
  if (value$7 == null) {
    tmp = undefined;
  } else {
    var tmp$1;
    switch (value$7) {
      case "ALERT" :
          tmp$1 = "ALERT";
          break;
      case "DANGER" :
          tmp$1 = "DANGER";
          break;
      case "OK" :
          tmp$1 = "OK";
          break;
      default:
        throw {
              RE_EXN_ID: "Not_found",
              Error: new Error()
            };
    }
    tmp = tmp$1;
  }
  var value$8 = value$5.formattedShopsNames;
  return {
          updateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            maxStockThreshold: !(value$2 == null) ? value$2 : undefined,
            minStockThreshold: !(value$3 == null) ? value$3 : undefined,
            stockOrderTriggerThreshold: !(value$4 == null) ? value$4 : undefined,
            stock: {
              __typename: value$5.__typename,
              formattedQuantity: !(value$6 == null) ? value$6 : undefined,
              state: tmp,
              formattedShopsNames: !(value$8 == null) ? value$8 : undefined
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.stock;
  var value$3 = value$2.formattedShopsNames;
  var formattedShopsNames = value$3 !== undefined ? value$3 : null;
  var value$4 = value$2.state;
  var state = value$4 !== undefined ? (
      value$4 === "OK" ? "OK" : (
          value$4 === "ALERT" ? "ALERT" : "DANGER"
        )
    ) : null;
  var value$5 = value$2.formattedQuantity;
  var formattedQuantity = value$5 !== undefined ? value$5 : null;
  var value$6 = value$2.__typename;
  var stock = {
    __typename: value$6,
    formattedQuantity: formattedQuantity,
    state: state,
    formattedShopsNames: formattedShopsNames
  };
  var value$7 = value$1.stockOrderTriggerThreshold;
  var stockOrderTriggerThreshold = value$7 !== undefined ? value$7 : null;
  var value$8 = value$1.minStockThreshold;
  var minStockThreshold = value$8 !== undefined ? value$8 : null;
  var value$9 = value$1.maxStockThreshold;
  var maxStockThreshold = value$9 !== undefined ? value$9 : null;
  var value$10 = value$1.id;
  var value$11 = value$1.__typename;
  var updateVariant = {
    __typename: value$11,
    id: value$10,
    maxStockThreshold: maxStockThreshold,
    minStockThreshold: minStockThreshold,
    stockOrderTriggerThreshold: stockOrderTriggerThreshold,
    stock: stock
  };
  return {
          updateVariant: updateVariant
        };
}

function serializeInputObjectInputUpdateVariantMetaData(inp) {
  var a = inp.shopifyVariantId;
  return {
          shopifyVariantId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateVariant(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.name;
  var a$2 = inp.purchasedPrice;
  var a$3 = inp.year;
  var a$4 = inp.capacityValue;
  var a$5 = inp.capacityUnit;
  var a$6 = inp.capacityPrecision;
  var a$7 = inp.alcoholVolume;
  var a$8 = inp.ean13;
  var a$9 = inp.internalNote;
  var a$10 = inp.metadata;
  var a$11 = inp.tastingNote;
  var a$12 = inp.packaging;
  var a$13 = inp.internalCode;
  var a$14 = inp.priceLookUpCode;
  var a$15 = inp.accountingCode;
  var a$16 = inp.supplierId;
  var a$17 = inp.minStockThreshold;
  var a$18 = inp.maxStockThreshold;
  var a$19 = inp.stockOrderTriggerThreshold;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          purchasedPrice: a$2 !== undefined ? a$2 : undefined,
          year: a$3 !== undefined ? a$3 : undefined,
          capacityValue: a$4 !== undefined ? a$4 : undefined,
          capacityUnit: a$5 !== undefined ? a$5 : undefined,
          capacityPrecision: a$6 !== undefined ? a$6 : undefined,
          alcoholVolume: a$7 !== undefined ? a$7 : undefined,
          ean13: a$8 !== undefined ? a$8 : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          metadata: a$10 !== undefined ? serializeInputObjectInputUpdateVariantMetaData(a$10) : undefined,
          tastingNote: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          packaging: a$12 !== undefined ? a$12 : undefined,
          internalCode: a$13 !== undefined ? a$13 : undefined,
          priceLookUpCode: a$14 !== undefined ? a$14 : undefined,
          accountingCode: a$15 !== undefined ? a$15 : undefined,
          supplierId: a$16 !== undefined ? a$16 : undefined,
          minStockThreshold: a$17 !== undefined ? a$17 : undefined,
          maxStockThreshold: a$18 !== undefined ? a$18 : undefined,
          stockOrderTriggerThreshold: a$19 !== undefined ? a$19 : undefined
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateVariant(inp.input)
        };
}

function makeVariables(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateVariant(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, internalNote, metadata, tastingNote, packaging, internalCode, priceLookUpCode, accountingCode, supplierId, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          internalNote: internalNote,
          metadata: metadata,
          tastingNote: tastingNote,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          accountingCode: accountingCode,
          supplierId: supplierId,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        };
}

function makeInputObjectInputUpdateVariantMetaData(shopifyVariantId, param) {
  return {
          shopifyVariantId: shopifyVariantId
        };
}

var UpdateVariantStockThresholdsMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var UpdateVariantStockThresholdsMutation_useWithVariables = include.useWithVariables;

var UpdateVariantStockThresholdsMutation = {
  UpdateVariantStockThresholdsMutation_inner: UpdateVariantStockThresholdsMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData,
  use: use,
  useWithVariables: UpdateVariantStockThresholdsMutation_useWithVariables
};

function validateMinStockThreshold(param) {
  var minStockThreshold = param.minStockThreshold;
  if (minStockThreshold > param.stockOrderTriggerThreshold) {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("The minimum stock should be less or equal to the value triggering a new order.")
          };
  } else if (minStockThreshold >= param.maxStockThreshold) {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("The minimum stock should be less than the maximum stock.")
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

function validateStockOrderTriggerThreshold(param) {
  var maxStockThreshold = param.maxStockThreshold;
  if (maxStockThreshold !== 0) {
    if (param.stockOrderTriggerThreshold >= maxStockThreshold) {
      return {
              TAG: /* Error */1,
              _0: Intl$Pos.t("This value should be less than maximum stock value.")
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: undefined
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: Intl$Pos.t("You have to define the maximum stock beforehand.")
          };
  }
}

var schema = [
  {
    TAG: /* Custom */7,
    _0: /* MinStockThreshold */1,
    _1: validateMinStockThreshold
  },
  {
    TAG: /* Custom */7,
    _0: /* StockOrderTriggerThreshold */2,
    _1: validateStockOrderTriggerThreshold
  }
];

function CatalogVariantStockThresholdFormContainer(Props) {
  var children = Props.children;
  var variantId = Props.variantId;
  var maxStockThreshold = Props.maxStockThreshold;
  var minStockThreshold = Props.minStockThreshold;
  var stockOrderTriggerThreshold = Props.stockOrderTriggerThreshold;
  var formPropState = Curry._1(CatalogVariantStockThresholdForm$Pos.useFormPropState, {
        id: variantId,
        initialValues: {
          maxStockThreshold: maxStockThreshold,
          minStockThreshold: minStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        },
        schema: schema
      });
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  var commit = React.useCallback((function (param) {
          var input = makeInputObjectInputUpdateVariant(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, param.minStockThreshold | 0, param.maxStockThreshold | 0, param.stockOrderTriggerThreshold | 0, undefined);
          var id = variantId === "" ? Pervasives.failwith("There is no variant id found here.") : variantId;
          return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                              id: id,
                              input: input
                            })), undefined, (function (param) {
                        return param.updateVariant.id;
                      }));
        }), [variantId]);
  return React.createElement(CatalogVariantStockThresholdForm$Pos.Core.Provider.make, {
              children: null,
              propState: formPropState
            }, React.createElement(CatalogVariantStockThresholdForm$Pos.AutoSave.make, {
                  onSubmit: (function (param, values) {
                      return Curry._1(commit, values);
                    }),
                  disabled: match[1].loading
                }), children);
}

var make = React.memo(CatalogVariantStockThresholdFormContainer);

export {
  UpdateVariantStockThresholdsMutation ,
  validateMinStockThreshold ,
  validateStockOrderTriggerThreshold ,
  schema ,
  make ,
}
/* query Not a pure module */
