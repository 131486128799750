// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Badge$Pos from "../../resources/feedback-indicators/Badge.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as OrderStatus$Pos from "./OrderStatus.bs.js";

function OrderStatusBadges(Props) {
  var statuses = Props.statuses;
  return React.createElement(Inline$Pos.make, {
              children: Belt_Array.map(statuses, (function (x) {
                      if (x === "ARCHIVED") {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Archived"),
                                    variation: "neutral"
                                  });
                      } else if (x === "DRAFT") {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Draft"),
                                    variation: "neutral"
                                  });
                      } else if (x === "NOT_RECEIVED") {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Not received"),
                                    variation: "normal"
                                  });
                      } else if (x === "REFUSED") {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Declined"),
                                    variation: "danger"
                                  });
                      } else if (x === "FINALIZED") {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Finalized"),
                                    variation: "success"
                                  });
                      } else if (x === "ACCEPTED") {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Accepted"),
                                    variation: "success"
                                  });
                      } else if (x === "RECEIVING") {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Reception in progress"),
                                    variation: "information"
                                  });
                      } else if (x === "RECEIVED") {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Reception finished"),
                                    variation: "success"
                                  });
                      } else if (OrderStatus$Pos.has(statuses, "TO_PAY")) {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("To pay"),
                                    variation: "warning"
                                  });
                      } else if (OrderStatus$Pos.has(statuses, "PAID")) {
                        return React.createElement(Badge$Pos.make, {
                                    children: Intl$Pos.t("Paid"),
                                    variation: "success"
                                  });
                      } else {
                        return null;
                      }
                    })),
              space: "small",
              wrap: true
            });
}

var make = React.memo(OrderStatusBadges);

export {
  make ,
}
/* make Not a pure module */
