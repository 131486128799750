// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App$Pos from "./App.bs.js";
import * as Providers$Pos from "./core/Providers.bs.js";
import * as BugTracker$Pos from "./core/BugTracker.bs.js";

function Root(Props) {
  return React.createElement(Providers$Pos.make, {
              children: React.createElement(BugTracker$Pos.Boundary.make, {
                    children: React.createElement(App$Pos.make, {})
                  })
            });
}

var make = Root;

export {
  make ,
}
/* react Not a pure module */
