// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as BugTracker$Pos from "../../core/BugTracker.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as Accounting__Serializer from "@wino/accounting/src/Accounting__Serializer.bs.js";
import * as OrderEdit__Queries$Pos from "../../app/Order/OrderEdit__Queries.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var UpdateOrderMutation_IdNotFound = /* @__PURE__ */Caml_exceptions.create("OrderMutations-Pos.UpdateOrderMutation_IdNotFound");

var Raw = {};

var query = Client.gql(["mutation CreateOrderMutation($input: InputCreateOrder!)  {\ncreateOrder(input: $input)  {\n__typename  \nid  \nformattedName  \nshopName  \nsupplierCompanyName  \nissueDate  \nreceptionFinishedAt  \nestimatedReceptionDate  \nformattedStatus  \nformattedTotalAmountIncludingTaxes  \ntotalProductsQuantity  \ntotalProductsExpectedQuantity  \nsupplier  {\n__typename  \nid  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.createOrder;
  var value$2 = value$1.receptionFinishedAt;
  var value$3 = value$1.formattedStatus;
  var value$4 = value$1.supplier;
  return {
          createOrder: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedName: value$1.formattedName,
            shopName: value$1.shopName,
            supplierCompanyName: value$1.supplierCompanyName,
            issueDate: Scalar$Pos.Datetime.parse(value$1.issueDate),
            receptionFinishedAt: !(value$2 == null) ? Caml_option.some(Scalar$Pos.Datetime.parse(value$2)) : undefined,
            estimatedReceptionDate: Scalar$Pos.Datetime.parse(value$1.estimatedReceptionDate),
            formattedStatus: Js_array.map((function (value) {
                    switch (value) {
                      case "ACCEPTED" :
                          return "ACCEPTED";
                      case "ARCHIVED" :
                          return "ARCHIVED";
                      case "DRAFT" :
                          return "DRAFT";
                      case "FINALIZED" :
                          return "FINALIZED";
                      case "NOT_RECEIVED" :
                          return "NOT_RECEIVED";
                      case "PAID" :
                          return "PAID";
                      case "RECEIVED" :
                          return "RECEIVED";
                      case "RECEIVING" :
                          return "RECEIVING";
                      case "REFUSED" :
                          return "REFUSED";
                      case "SENT" :
                          return "SENT";
                      case "TO_PAY" :
                          return "TO_PAY";
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                  }), value$3),
            formattedTotalAmountIncludingTaxes: value$1.formattedTotalAmountIncludingTaxes,
            totalProductsQuantity: value$1.totalProductsQuantity,
            totalProductsExpectedQuantity: value$1.totalProductsExpectedQuantity,
            supplier: !(value$4 == null) ? ({
                  __typename: value$4.__typename,
                  id: value$4.id
                }) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.createOrder;
  var value$2 = value$1.supplier;
  var supplier;
  if (value$2 !== undefined) {
    var value$3 = value$2.id;
    var value$4 = value$2.__typename;
    supplier = {
      __typename: value$4,
      id: value$3
    };
  } else {
    supplier = null;
  }
  var value$5 = value$1.totalProductsExpectedQuantity;
  var value$6 = value$1.totalProductsQuantity;
  var value$7 = value$1.formattedTotalAmountIncludingTaxes;
  var value$8 = value$1.formattedStatus;
  var formattedStatus = Js_array.map((function (value) {
          if (value === "TO_PAY") {
            return "TO_PAY";
          } else if (value === "DRAFT") {
            return "DRAFT";
          } else if (value === "NOT_RECEIVED") {
            return "NOT_RECEIVED";
          } else if (value === "REFUSED") {
            return "REFUSED";
          } else if (value === "FINALIZED") {
            return "FINALIZED";
          } else if (value === "ACCEPTED") {
            return "ACCEPTED";
          } else if (value === "PAID") {
            return "PAID";
          } else if (value === "RECEIVING") {
            return "RECEIVING";
          } else if (value === "SENT") {
            return "SENT";
          } else if (value === "RECEIVED") {
            return "RECEIVED";
          } else {
            return "ARCHIVED";
          }
        }), value$8);
  var value$9 = value$1.estimatedReceptionDate;
  var value$10 = Scalar$Pos.Datetime.serialize(value$9);
  var value$11 = value$1.receptionFinishedAt;
  var receptionFinishedAt = value$11 !== undefined ? Scalar$Pos.Datetime.serialize(Caml_option.valFromOption(value$11)) : null;
  var value$12 = value$1.issueDate;
  var value$13 = Scalar$Pos.Datetime.serialize(value$12);
  var value$14 = value$1.supplierCompanyName;
  var value$15 = value$1.shopName;
  var value$16 = value$1.formattedName;
  var value$17 = value$1.id;
  var value$18 = value$1.__typename;
  var createOrder = {
    __typename: value$18,
    id: value$17,
    formattedName: value$16,
    shopName: value$15,
    supplierCompanyName: value$14,
    issueDate: value$13,
    receptionFinishedAt: receptionFinishedAt,
    estimatedReceptionDate: value$10,
    formattedStatus: formattedStatus,
    formattedTotalAmountIncludingTaxes: value$7,
    totalProductsQuantity: value$6,
    totalProductsExpectedQuantity: value$5,
    supplier: supplier
  };
  return {
          createOrder: createOrder
        };
}

function serializeInputObjectInputCreateOrder(inp) {
  var a = inp.condition;
  var a$1 = inp.noteForSupplier;
  var a$2 = inp.note;
  return {
          issueDate: inp.issueDate,
          estimatedReceptionDate: inp.estimatedReceptionDate,
          condition: a !== undefined ? a : undefined,
          noteForSupplier: a$1 !== undefined ? a$1 : undefined,
          note: a$2 !== undefined ? a$2 : undefined,
          cart: inp.cart,
          supplierId: inp.supplierId,
          deviceId: inp.deviceId,
          shopId: inp.shopId
        };
}

function serializeVariables(inp) {
  return {
          input: serializeInputObjectInputCreateOrder(inp.input)
        };
}

function makeVariables(input, param) {
  return {
          input: input
        };
}

function makeInputObjectInputCreateOrder(issueDate, estimatedReceptionDate, condition, noteForSupplier, note, cart, supplierId, deviceId, shopId, param) {
  return {
          issueDate: issueDate,
          estimatedReceptionDate: estimatedReceptionDate,
          condition: condition,
          noteForSupplier: noteForSupplier,
          note: note,
          cart: cart,
          supplierId: supplierId,
          deviceId: deviceId,
          shopId: shopId
        };
}

var CreateOrderMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateOrder: serializeInputObjectInputCreateOrder,
  makeVariables: makeVariables,
  makeInputObjectInputCreateOrder: makeInputObjectInputCreateOrder
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CreateOrderMutation_useWithVariables = include.useWithVariables;

var CreateOrderMutation = {
  CreateOrderMutation_inner: CreateOrderMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateOrder: serializeInputObjectInputCreateOrder,
  makeVariables: makeVariables,
  makeInputObjectInputCreateOrder: makeInputObjectInputCreateOrder,
  use: use,
  useWithVariables: CreateOrderMutation_useWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation UpdateOrderMutation($id: ID!, $input: InputUpdateOrder!)  {\nupdateOrder(id: $id, input: $input)  {\n__typename  \nid  \nformattedName  \ncondition  \nnoteForSupplier  \nsupplierNote  \nsupplier  {\n__typename  \nid  \n}\n\nissueDate  \nestimatedReceptionDate  \nnote  \ncart  \nupdatedAt  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.updateOrder;
  var value$2 = value$1.condition;
  var value$3 = value$1.noteForSupplier;
  var value$4 = value$1.supplierNote;
  var value$5 = value$1.supplier;
  var value$6 = value$1.note;
  return {
          updateOrder: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedName: value$1.formattedName,
            condition: !(value$2 == null) ? Scalar$Pos.$$Text.parse(value$2) : undefined,
            noteForSupplier: !(value$3 == null) ? Scalar$Pos.$$Text.parse(value$3) : undefined,
            supplierNote: !(value$4 == null) ? value$4 : undefined,
            supplier: !(value$5 == null) ? ({
                  __typename: value$5.__typename,
                  id: value$5.id
                }) : undefined,
            issueDate: Scalar$Pos.Datetime.parse(value$1.issueDate),
            estimatedReceptionDate: Scalar$Pos.Datetime.parse(value$1.estimatedReceptionDate),
            note: !(value$6 == null) ? Scalar$Pos.$$Text.parse(value$6) : undefined,
            cart: Scalar$Pos.$$Text.parse(value$1.cart),
            updatedAt: Scalar$Pos.Datetime.parse(value$1.updatedAt)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.updateOrder;
  var value$2 = value$1.updatedAt;
  var value$3 = Scalar$Pos.Datetime.serialize(value$2);
  var value$4 = value$1.cart;
  var value$5 = Scalar$Pos.$$Text.serialize(value$4);
  var value$6 = value$1.note;
  var note = value$6 !== undefined ? Scalar$Pos.$$Text.serialize(value$6) : null;
  var value$7 = value$1.estimatedReceptionDate;
  var value$8 = Scalar$Pos.Datetime.serialize(value$7);
  var value$9 = value$1.issueDate;
  var value$10 = Scalar$Pos.Datetime.serialize(value$9);
  var value$11 = value$1.supplier;
  var supplier;
  if (value$11 !== undefined) {
    var value$12 = value$11.id;
    var value$13 = value$11.__typename;
    supplier = {
      __typename: value$13,
      id: value$12
    };
  } else {
    supplier = null;
  }
  var value$14 = value$1.supplierNote;
  var supplierNote = value$14 !== undefined ? value$14 : null;
  var value$15 = value$1.noteForSupplier;
  var noteForSupplier = value$15 !== undefined ? Scalar$Pos.$$Text.serialize(value$15) : null;
  var value$16 = value$1.condition;
  var condition = value$16 !== undefined ? Scalar$Pos.$$Text.serialize(value$16) : null;
  var value$17 = value$1.formattedName;
  var value$18 = value$1.id;
  var value$19 = value$1.__typename;
  var updateOrder = {
    __typename: value$19,
    id: value$18,
    formattedName: value$17,
    condition: condition,
    noteForSupplier: noteForSupplier,
    supplierNote: supplierNote,
    supplier: supplier,
    issueDate: value$10,
    estimatedReceptionDate: value$8,
    note: note,
    cart: value$5,
    updatedAt: value$3
  };
  return {
          updateOrder: updateOrder
        };
}

function serializeInputObjectInputUpdateOrder(inp) {
  var a = inp.issueDate;
  var a$1 = inp.estimatedReceptionDate;
  var a$2 = inp.condition;
  var a$3 = inp.noteForSupplier;
  var a$4 = inp.note;
  var a$5 = inp.cart;
  var a$6 = inp.supplierId;
  return {
          issueDate: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          estimatedReceptionDate: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          condition: a$2 !== undefined ? a$2 : undefined,
          noteForSupplier: a$3 !== undefined ? a$3 : undefined,
          note: a$4 !== undefined ? a$4 : undefined,
          cart: a$5 !== undefined ? Caml_option.valFromOption(a$5) : undefined,
          supplierId: a$6 !== undefined ? a$6 : undefined
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateOrder(inp.input)
        };
}

function makeVariables$1(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateOrder(issueDate, estimatedReceptionDate, condition, noteForSupplier, note, cart, supplierId, param) {
  return {
          issueDate: issueDate,
          estimatedReceptionDate: estimatedReceptionDate,
          condition: condition,
          noteForSupplier: noteForSupplier,
          note: note,
          cart: cart,
          supplierId: supplierId
        };
}

var UpdateOrderMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateOrder: serializeInputObjectInputUpdateOrder,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateOrder: makeInputObjectInputUpdateOrder
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var UpdateOrderMutation_useWithVariables = include$1.useWithVariables;

var UpdateOrderMutation = {
  UpdateOrderMutation_inner: UpdateOrderMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateOrder: serializeInputObjectInputUpdateOrder,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateOrder: makeInputObjectInputUpdateOrder,
  use: use$1,
  useWithVariables: UpdateOrderMutation_useWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["mutation MoveOrderMutation($id: ID!, $action: OrderAction)  {\nmoveOrder(id: $id, action: $action)  {\n__typename  \nid  \nformattedStatus  \n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.moveOrder;
  var value$2 = value$1.formattedStatus;
  return {
          moveOrder: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedStatus: Js_array.map((function (value) {
                    switch (value) {
                      case "ACCEPTED" :
                          return "ACCEPTED";
                      case "ARCHIVED" :
                          return "ARCHIVED";
                      case "DRAFT" :
                          return "DRAFT";
                      case "FINALIZED" :
                          return "FINALIZED";
                      case "NOT_RECEIVED" :
                          return "NOT_RECEIVED";
                      case "PAID" :
                          return "PAID";
                      case "RECEIVED" :
                          return "RECEIVED";
                      case "RECEIVING" :
                          return "RECEIVING";
                      case "REFUSED" :
                          return "REFUSED";
                      case "SENT" :
                          return "SENT";
                      case "TO_PAY" :
                          return "TO_PAY";
                      default:
                        return {
                                NAME: "FutureAddedValue",
                                VAL: value
                              };
                    }
                  }), value$2)
          }
        };
}

function serialize$2(value) {
  var value$1 = value.moveOrder;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = Js_array.map((function (value) {
          if (typeof value === "object") {
            return value.VAL;
          } else if (value === "TO_PAY") {
            return "TO_PAY";
          } else if (value === "DRAFT") {
            return "DRAFT";
          } else if (value === "NOT_RECEIVED") {
            return "NOT_RECEIVED";
          } else if (value === "REFUSED") {
            return "REFUSED";
          } else if (value === "FINALIZED") {
            return "FINALIZED";
          } else if (value === "ACCEPTED") {
            return "ACCEPTED";
          } else if (value === "PAID") {
            return "PAID";
          } else if (value === "RECEIVING") {
            return "RECEIVING";
          } else if (value === "SENT") {
            return "SENT";
          } else if (value === "RECEIVED") {
            return "RECEIVED";
          } else {
            return "ARCHIVED";
          }
        }), value$2);
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var moveOrder = {
    __typename: value$4,
    id: value$3,
    formattedStatus: formattedStatus
  };
  return {
          moveOrder: moveOrder
        };
}

function serializeVariables$2(inp) {
  var a = inp.action;
  return {
          id: inp.id,
          action: a !== undefined ? (
              a === "FINISH_RECEPTION" ? "FINISH_RECEPTION" : (
                  a === "START_RECEPTION" ? "START_RECEPTION" : (
                      a === "CANCEL" ? "CANCEL" : (
                          a === "PAY" ? "PAY" : (
                              a === "REFUSE" ? "REFUSE" : (
                                  a === "SEND" ? "SEND" : (
                                      a === "ACCEPT" ? "ACCEPT" : "FINALIZE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined
        };
}

function makeVariables$2(id, action, param) {
  return {
          id: id,
          action: action
        };
}

var MoveOrderMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var MoveOrderMutation_useWithVariables = include$2.useWithVariables;

var MoveOrderMutation = {
  MoveOrderMutation_inner: MoveOrderMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  use: use$2,
  useWithVariables: MoveOrderMutation_useWithVariables
};

var Raw$3 = {};

var query$3 = Client.gql(["mutation ArchiveOrderMutation($id: ID!)  {\narchiveOrder(id: $id)  {\n__typename  \nid  \nformattedStatus  \n}\n\n}\n"]);

function parse$3(value) {
  var value$1 = value.archiveOrder;
  var value$2 = value$1.formattedStatus;
  return {
          archiveOrder: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedStatus: Js_array.map((function (value) {
                    switch (value) {
                      case "ACCEPTED" :
                          return "ACCEPTED";
                      case "ARCHIVED" :
                          return "ARCHIVED";
                      case "DRAFT" :
                          return "DRAFT";
                      case "FINALIZED" :
                          return "FINALIZED";
                      case "NOT_RECEIVED" :
                          return "NOT_RECEIVED";
                      case "PAID" :
                          return "PAID";
                      case "RECEIVED" :
                          return "RECEIVED";
                      case "RECEIVING" :
                          return "RECEIVING";
                      case "REFUSED" :
                          return "REFUSED";
                      case "SENT" :
                          return "SENT";
                      case "TO_PAY" :
                          return "TO_PAY";
                      default:
                        return {
                                NAME: "FutureAddedValue",
                                VAL: value
                              };
                    }
                  }), value$2)
          }
        };
}

function serialize$3(value) {
  var value$1 = value.archiveOrder;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = Js_array.map((function (value) {
          if (typeof value === "object") {
            return value.VAL;
          } else if (value === "TO_PAY") {
            return "TO_PAY";
          } else if (value === "DRAFT") {
            return "DRAFT";
          } else if (value === "NOT_RECEIVED") {
            return "NOT_RECEIVED";
          } else if (value === "REFUSED") {
            return "REFUSED";
          } else if (value === "FINALIZED") {
            return "FINALIZED";
          } else if (value === "ACCEPTED") {
            return "ACCEPTED";
          } else if (value === "PAID") {
            return "PAID";
          } else if (value === "RECEIVING") {
            return "RECEIVING";
          } else if (value === "SENT") {
            return "SENT";
          } else if (value === "RECEIVED") {
            return "RECEIVED";
          } else {
            return "ARCHIVED";
          }
        }), value$2);
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var archiveOrder = {
    __typename: value$4,
    id: value$3,
    formattedStatus: formattedStatus
  };
  return {
          archiveOrder: archiveOrder
        };
}

function serializeVariables$3(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$3(id, param) {
  return {
          id: id
        };
}

var ArchiveOrderMutation_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3
};

var include$3 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3
    });

var use$3 = include$3.use;

var ArchiveOrderMutation_useWithVariables = include$3.useWithVariables;

var ArchiveOrderMutation = {
  ArchiveOrderMutation_inner: ArchiveOrderMutation_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  use: use$3,
  useWithVariables: ArchiveOrderMutation_useWithVariables
};

var Raw$4 = {};

var query$4 = Client.gql(["mutation UnarchiveOrderMutation($id: ID!)  {\nunarchiveOrder(id: $id)  {\n__typename  \nid  \nformattedStatus  \n}\n\n}\n"]);

function parse$4(value) {
  var value$1 = value.unarchiveOrder;
  var value$2 = value$1.formattedStatus;
  return {
          unarchiveOrder: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedStatus: Js_array.map((function (value) {
                    switch (value) {
                      case "ACCEPTED" :
                          return "ACCEPTED";
                      case "ARCHIVED" :
                          return "ARCHIVED";
                      case "DRAFT" :
                          return "DRAFT";
                      case "FINALIZED" :
                          return "FINALIZED";
                      case "NOT_RECEIVED" :
                          return "NOT_RECEIVED";
                      case "PAID" :
                          return "PAID";
                      case "RECEIVED" :
                          return "RECEIVED";
                      case "RECEIVING" :
                          return "RECEIVING";
                      case "REFUSED" :
                          return "REFUSED";
                      case "SENT" :
                          return "SENT";
                      case "TO_PAY" :
                          return "TO_PAY";
                      default:
                        return {
                                NAME: "FutureAddedValue",
                                VAL: value
                              };
                    }
                  }), value$2)
          }
        };
}

function serialize$4(value) {
  var value$1 = value.unarchiveOrder;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = Js_array.map((function (value) {
          if (typeof value === "object") {
            return value.VAL;
          } else if (value === "TO_PAY") {
            return "TO_PAY";
          } else if (value === "DRAFT") {
            return "DRAFT";
          } else if (value === "NOT_RECEIVED") {
            return "NOT_RECEIVED";
          } else if (value === "REFUSED") {
            return "REFUSED";
          } else if (value === "FINALIZED") {
            return "FINALIZED";
          } else if (value === "ACCEPTED") {
            return "ACCEPTED";
          } else if (value === "PAID") {
            return "PAID";
          } else if (value === "RECEIVING") {
            return "RECEIVING";
          } else if (value === "SENT") {
            return "SENT";
          } else if (value === "RECEIVED") {
            return "RECEIVED";
          } else {
            return "ARCHIVED";
          }
        }), value$2);
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var unarchiveOrder = {
    __typename: value$4,
    id: value$3,
    formattedStatus: formattedStatus
  };
  return {
          unarchiveOrder: unarchiveOrder
        };
}

function serializeVariables$4(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$4(id, param) {
  return {
          id: id
        };
}

var UnarchiveOrderMutation_inner = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$4,
  makeVariables: makeVariables$4
};

var include$4 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$4,
      Raw: Raw$4,
      parse: parse$4,
      serialize: serialize$4,
      serializeVariables: serializeVariables$4
    });

var use$4 = include$4.use;

var UnarchiveOrderMutation_useWithVariables = include$4.useWithVariables;

var UnarchiveOrderMutation = {
  UnarchiveOrderMutation_inner: UnarchiveOrderMutation_inner,
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$4,
  makeVariables: makeVariables$4,
  use: use$4,
  useWithVariables: UnarchiveOrderMutation_useWithVariables
};

function useCreate(param) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var result = match[1];
  var mutate = match[0];
  var filterGlobalDiscount = function (cart) {
    var newrecord = Caml_obj.obj_dup(cart);
    newrecord.discounts = Belt_Array.keep(cart.discounts, (function (discount) {
            var amount = discount.amount;
            if (amount !== undefined) {
              return Caml_option.valFromOption(amount).toNumber() > 0;
            } else {
              return false;
            }
          }));
    return newrecord;
  };
  return React.useCallback(function (param) {
              return function (state) {
                var input = makeInputObjectInputCreateOrder(Scalar$Pos.Datetime.serialize(state.issueDate), Scalar$Pos.Datetime.serialize(state.estimatedReceptionDate), state.condition, state.noteForSupplier, state.note, Scalar$Pos.$$Text.serialize(Accounting__Serializer.serialize(filterGlobalDiscount(state.cart))), state.supplierId, state.deviceId, state.shopId, undefined);
                if (state.deviceId === "") {
                  return Future.make(function (resolve) {
                              BugTracker$Pos.reportErrorMessage("Empty deviceId error with saved order state: " + Belt_Option.getWithDefault(Json$Pos.stringifyAny(input), ""));
                              Curry._1(resolve, {
                                    TAG: /* Error */1,
                                    _0: Intl$Pos.t("Something wrong happened, please try again or contact the support through the bottom left side corner chat button.")
                                  });
                            });
                } else {
                  return Future.tapOk(Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                          input: input
                                        })), undefined, (function (param) {
                                    return param.createOrder.id;
                                  })), (function (param) {
                                var match = result.client;
                                if (match !== undefined) {
                                  Curry._1(match.rescript_resetStore, undefined);
                                  return ;
                                }
                                
                              }));
                }
              };
            });
}

function useUpdate(id) {
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  var filterGlobalDiscount = function (cart) {
    var newrecord = Caml_obj.obj_dup(cart);
    newrecord.discounts = Belt_Array.keep(cart.discounts, (function (discount) {
            var amount = discount.amount;
            if (amount !== undefined) {
              return Caml_option.valFromOption(amount).toNumber() > 0;
            } else {
              return false;
            }
          }));
    return newrecord;
  };
  return React.useCallback((function (param) {
                return function (state) {
                  var input = makeInputObjectInputUpdateOrder(Caml_option.some(Scalar$Pos.Datetime.serialize(state.issueDate)), Caml_option.some(Scalar$Pos.Datetime.serialize(state.estimatedReceptionDate)), state.condition, state.noteForSupplier, state.note, Caml_option.some(Scalar$Pos.$$Text.serialize(Accounting__Serializer.serialize(filterGlobalDiscount(state.cart)))), state.supplierId, undefined);
                  var tmp;
                  if (id !== undefined) {
                    tmp = id;
                  } else {
                    throw {
                          RE_EXN_ID: UpdateOrderMutation_IdNotFound,
                          Error: new Error()
                        };
                  }
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      id: tmp,
                                      input: input
                                    })), undefined, (function (param) {
                                return param.updateOrder.id;
                              }));
                };
              }), []);
}

function useMove(id, action) {
  var match = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return function (param) {
                  var id$1 = id !== undefined ? id : Pervasives.failwith("The order should be saved before continuing.");
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, [Curry._3(OrderEdit__Queries$Pos.OrderQuery.refetchQueryDescription, undefined, undefined, OrderEdit__Queries$Pos.OrderQuery.makeVariables(id$1, undefined))], undefined, {
                                      id: id$1,
                                      action: action
                                    })), undefined, (function (param) {
                                return param.moveOrder.id;
                              }));
                };
              }), [action]);
}

function useArchive(id) {
  var match = Curry.app(use$3, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback(function (param) {
              var id$1 = id !== undefined ? id : Pervasives.failwith("The order should be saved before archiving.");
              return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, [Curry._3(OrderEdit__Queries$Pos.OrderQuery.refetchQueryDescription, undefined, undefined, OrderEdit__Queries$Pos.OrderQuery.makeVariables(id$1, undefined))], undefined, {
                                  id: id$1
                                })), undefined, (function (param) {
                            return id$1;
                          }));
            });
}

function useUnarchive(id) {
  var match = Curry.app(use$4, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback(function (param) {
              var id$1 = id !== undefined ? id : Pervasives.failwith("The order should be saved before unarchiving.");
              return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, [Curry._3(OrderEdit__Queries$Pos.OrderQuery.refetchQueryDescription, undefined, undefined, OrderEdit__Queries$Pos.OrderQuery.makeVariables(id$1, undefined))], undefined, {
                                  id: id$1
                                })), undefined, (function (param) {
                            return id$1;
                          }));
            });
}

var OrderQuery;

export {
  OrderQuery ,
  UpdateOrderMutation_IdNotFound ,
  CreateOrderMutation ,
  UpdateOrderMutation ,
  MoveOrderMutation ,
  ArchiveOrderMutation ,
  UnarchiveOrderMutation ,
  useCreate ,
  useUpdate ,
  useMove ,
  useArchive ,
  useUnarchive ,
}
/* query Not a pure module */
