// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as Scaffold$Pos from "../../bundles/Scaffold/Scaffold.bs.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as SearchBar$Pos from "../../resources/selection-and-input/SearchBar.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as ButtonLink$Pos from "../../resources/actions/ButtonLink.bs.js";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as SelectDateRange$Pos from "../../resources/selection-and-input/SelectDateRange.bs.js";
import * as TableDatetimeCell$Pos from "../../resources/tables/TableDatetimeCell.bs.js";
import * as StockTransferTableRowActions$Pos from "../../modules/StockTransfer/StockTransferTableRowActions.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query StockTransferListPageQuery($search: String, $filterBy: InputStockTransfersQueryFilter, $before: String, $after: String, $first: Int, $last: Int)  {\nstockTransfers(search: $search, filterBy: $filterBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncode  \ncreatedAt  \nsenderShop  {\n__typename  \nname  \n}\n\nrecipientShop  {\n__typename  \nname  \n}\n\nproducts  {\n__typename  \ntotalCount  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.stockTransfers;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          stockTransfers: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.senderShop;
                    var value$3 = value$1.recipientShop;
                    var value$4 = value$1.products;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              code: value$1.code,
                              createdAt: Scalar$Pos.Datetime.parse(value$1.createdAt),
                              senderShop: {
                                __typename: value$2.__typename,
                                name: value$2.name
                              },
                              recipientShop: {
                                __typename: value$3.__typename,
                                name: value$3.name
                              },
                              products: {
                                __typename: value$4.__typename,
                                totalCount: value$4.totalCount
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.stockTransfers;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.products;
          var value$3 = value$2.totalCount;
          var value$4 = value$2.__typename;
          var products = {
            __typename: value$4,
            totalCount: value$3
          };
          var value$5 = value$1.recipientShop;
          var value$6 = value$5.name;
          var value$7 = value$5.__typename;
          var recipientShop = {
            __typename: value$7,
            name: value$6
          };
          var value$8 = value$1.senderShop;
          var value$9 = value$8.name;
          var value$10 = value$8.__typename;
          var senderShop = {
            __typename: value$10,
            name: value$9
          };
          var value$11 = value$1.createdAt;
          var value$12 = Scalar$Pos.Datetime.serialize(value$11);
          var value$13 = value$1.code;
          var value$14 = value$1.id;
          var value$15 = value$1.__typename;
          var node = {
            __typename: value$15,
            id: value$14,
            code: value$13,
            createdAt: value$12,
            senderShop: senderShop,
            recipientShop: recipientShop,
            products: products
          };
          var value$16 = value.__typename;
          return {
                  __typename: value$16,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var stockTransfers = {
    __typename: value$8,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          stockTransfers: stockTransfers
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInputStockTransfersQueryFilter(inp) {
  var a = inp.createdAt;
  return {
          createdAt: a !== undefined ? serializeInputObjectDateFilter(a) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.before;
  var a$3 = inp.after;
  var a$4 = inp.first;
  var a$5 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputStockTransfersQueryFilter(a$1) : undefined,
          before: a$2 !== undefined ? a$2 : undefined,
          after: a$3 !== undefined ? a$3 : undefined,
          first: a$4 !== undefined ? a$4 : undefined,
          last: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables(search, filterBy, before, after, first, last, param) {
  return {
          search: search,
          filterBy: filterBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputStockTransfersQueryFilter(createdAt, param) {
  return {
          createdAt: createdAt
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          filterBy: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputStockTransfersQueryFilter: serializeInputObjectInputStockTransfersQueryFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputStockTransfersQueryFilter: makeInputObjectInputStockTransfersQueryFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputStockTransfersQueryFilter: serializeInputObjectInputStockTransfersQueryFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputStockTransfersQueryFilter: makeInputObjectInputStockTransfersQueryFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function encoder(param) {
  return Belt_Option.map(param.createdAtDateRange, (function (param) {
                return [
                        param[0].valueOf(),
                        param[1].valueOf()
                      ];
              }));
}

function decoder(createdAtDateRange) {
  return {
          TAG: /* Ok */0,
          _0: {
            createdAtDateRange: Belt_Option.flatMap(createdAtDateRange, (function (range) {
                    if (range.length !== 2) {
                      return ;
                    }
                    var start = range[0];
                    var end = range[1];
                    return [
                            new Date(start),
                            new Date(end)
                          ];
                  }))
          }
        };
}

function useJsonCodec(param) {
  return JsonCodec$Pos.object1(encoder, decoder, JsonCodec$Pos.optional(JsonCodec$Pos.field("createdAtDateRange", JsonCodec$Pos.array(JsonCodec$Pos.$$float))));
}

var Filters = {
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function makeQueryVariables(_defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          search: search,
          filterBy: filterBy,
          before: connectionArguments.before,
          after: connectionArguments.after,
          first: connectionArguments.first,
          last: connectionArguments.last
        };
}

function makeQueryVariablesFilterBy(param) {
  var createdAtDateRange = param.createdAtDateRange;
  return {
          createdAt: createdAtDateRange !== undefined ? ({
                _after: undefined,
                _before: undefined,
                _between: [
                  Scalar$Pos.Datetime.serialize(createdAtDateRange[0]),
                  Scalar$Pos.Datetime.serialize(createdAtDateRange[1])
                ]
              }) : undefined
        };
}

function totalCountFromQueryData(param) {
  return param.stockTransfers.totalCount;
}

function cursorsFromQueryData(param) {
  var stockTransfers = param.stockTransfers;
  return [
          stockTransfers.pageInfo.startCursor,
          stockTransfers.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.map(param.stockTransfers.edges, (function (edge) {
                return {
                        id: edge.node.id,
                        code: edge.node.code,
                        date: edge.node.createdAt,
                        senderShop: edge.node.senderShop.name,
                        recipientShop: edge.node.recipientShop.name,
                        productsCount: edge.node.products.totalCount
                      };
              }));
}

function keyExtractor(param) {
  return param.id;
}

var Scaffolded = Scaffold$Pos.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

var scaffoldedColumns = [
  {
    name: Intl$Pos.t("Reference"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 120
      },
      sticky: true
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.code
                  });
      })
  },
  {
    name: Intl$Pos.t("Datetime"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 120
      }
    },
    render: (function (param) {
        return React.createElement(TableDatetimeCell$Pos.make, {
                    value: param.date
                  });
      })
  },
  {
    name: Intl$Pos.t("Issuing shop"),
    layout: {
      width: {
        NAME: "pct",
        VAL: 25
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.senderShop
                  });
      })
  },
  {
    name: Intl$Pos.t("Recipient shop"),
    layout: {
      width: {
        NAME: "pct",
        VAL: 25
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.recipientShop
                  });
      })
  },
  {
    name: Intl$Pos.t("Number of unique references"),
    layout: {
      width: {
        NAME: "px",
        VAL: 200
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.productsCount
                  });
      })
  },
  {
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "px",
        VAL: 70
      }
    },
    render: (function (param) {
        var notifier = Notifier$Pos.use(undefined, undefined);
        return React.createElement(StockTransferTableRowActions$Pos.make, {
                    id: param.id,
                    code: param.code,
                    onError: (function (message) {
                        Curry._3(notifier.add, {
                              TAG: /* Error */1,
                              _0: message
                            }, undefined, undefined);
                      })
                  });
      })
  }
];

function StockTransferListPage(Props) {
  var stockTransferCreateRoute = Props.stockTransferCreateRoute;
  var notifier = Notifier$Pos.use(undefined, undefined);
  var initialState = Curry._1(Scaffolded.makeInitialState, {
        createdAtDateRange: undefined
      });
  var match = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match[1];
  var state = match[0];
  var defaultQueryVariables = {
    search: undefined,
    filterBy: undefined,
    before: undefined,
    after: undefined,
    first: undefined,
    last: undefined
  };
  var tmp = {
    placeholder: Intl$Pos.t("Since the beginning"),
    onChange: (function (createdAtDateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (param) {
                  return {
                          createdAtDateRange: createdAtDateRange
                        };
                })
            });
      })
  };
  if (state.filters.createdAtDateRange !== undefined) {
    tmp.value = Caml_option.valFromOption(state.filters.createdAtDateRange);
  }
  var match$1 = state.filters;
  var filters = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, React.createElement(SelectDateRange$Pos.make, tmp), match$1.createdAtDateRange !== undefined ? React.createElement(Scaffold$Pos.ResetFiltersButton.make, {
              onPress: (function (param) {
                  Curry._1(dispatch, {
                        TAG: /* FiltersUpdated */2,
                        _0: (function (param) {
                            return initialState.filters;
                          })
                      });
                })
            }) : null);
  var actions = React.createElement(ButtonLink$Pos.make, {
        children: Intl$Pos.t("Create stock transfer"),
        variation: "primary",
        to: {
          TAG: /* Route */0,
          _0: stockTransferCreateRoute
        }
      });
  var banner = React.createElement(Notifier$Pos.Banner.make, {
        notifier: notifier
      });
  var tmp$1 = {
    placeholder: Intl$Pos.t("Search a stock transfer"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$1.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar$Pos.make, tmp$1);
  var emptyState;
  var exit = 0;
  if (state.currentPage !== 1 || state.searchQuery !== undefined || state.filters.createdAtDateRange !== undefined) {
    exit = 1;
  } else {
    emptyState = React.createElement(EmptyState$Pos.make, {
          illustration: Illustration$Pos.create,
          title: Intl$Pos.t("Welcome to the stock transfers space.")
        });
  }
  if (exit === 1) {
    emptyState = React.createElement(EmptyState$Pos.make, {
          illustration: Illustration$Pos.notFound,
          title: Intl$Pos.t("No result were found."),
          text: Intl$Pos.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  return React.createElement(Scaffolded.make, {
              title: Intl$Pos.t("Stock transfers"),
              state: state,
              dispatch: dispatch,
              columns: scaffoldedColumns,
              filters: filters,
              actions: actions,
              banner: banner,
              searchBar: searchBar,
              emptyState: emptyState,
              defaultQueryVariables: defaultQueryVariables
            });
}

var make = React.memo(StockTransferListPage);

export {
  Query ,
  Filters ,
  Row ,
  Scaffolded ,
  scaffoldedColumns ,
  make ,
}
/* query Not a pure module */
