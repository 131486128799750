// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as CatalogVariantEditForm$Pos from "./CatalogVariantEditForm.bs.js";
import * as CatalogVariantEditFormMutations$Pos from "./CatalogVariantEditFormMutations.bs.js";

function CatalogVariantEditFormPageActions(Props) {
  var editionMode = Props.editionMode;
  var productValues = Props.productValues;
  var updateVariant = CatalogVariantEditFormMutations$Pos.useUpdateVariant(undefined);
  var createVariant = CatalogVariantEditFormMutations$Pos.useCreateVariant(undefined);
  var createProductAndVariant = CatalogVariantEditFormMutations$Pos.useCreateProductAndVariant(productValues);
  var onSubmit = editionMode ? updateVariant : (
      productValues !== undefined ? createProductAndVariant : createVariant
    );
  return React.createElement(Inline$Pos.make, {
              children: React.createElement(CatalogVariantEditForm$Pos.SubmitButton.make, {
                    variation: "success",
                    size: "medium",
                    onSubmit: onSubmit,
                    text: Intl$Pos.t("Save")
                  }),
              space: "small"
            });
}

var make = React.memo(CatalogVariantEditFormPageActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
