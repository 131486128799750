// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      view: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor20, undefined, undefined, undefined, undefined, Style$Pos.hairlineWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(2)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      viewHovered: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor30, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function viewStyleFromParams(opened, hovered) {
  return Style$Pos.arrayOptionStyle([opened || hovered ? Caml_option.some(styles.viewHovered) : undefined]);
}

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var ariaProps = Props.ariaProps;
      var opened = Props.opened;
      var disabledOpt = Props.disabled;
      var onPress = Props.onPress;
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var ref$1 = (ref == null) ? undefined : Caml_option.some(ref);
      var match = Hover$Pos.use(ref$1, undefined);
      return React.createElement(Inline$Pos.make, {
                  children: React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(disabled ? React.createElement(Inline$Pos.make, {
                                      children: null,
                                      space: "small"
                                    }, children, React.createElement(Icon$Pos.make, {
                                          name: "arrow_down_light"
                                        })) : React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(Inline$Pos.make, {
                                              children: null,
                                              space: "small"
                                            }, children, React.createElement(Icon$Pos.make, {
                                                  name: opened ? "arrow_up_light" : "arrow_down_light"
                                                })), ariaProps, undefined, undefined, undefined, undefined, onPress, undefined, match[0], undefined))), Caml_option.some(Style$Pos.arrayStyle([
                                    styles.view,
                                    viewStyleFromParams(opened, match[1] && !disabled)
                                  ])), undefined, undefined, undefined))
                });
    });

var make$1 = React.memo(make);

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7) {
  var tmp = {
    children: prim0,
    opened: prim2,
    onPress: prim4
  };
  if (prim1 !== undefined) {
    tmp.ariaProps = Caml_option.valFromOption(prim1);
  }
  if (prim3 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim3);
  }
  if (prim5 !== undefined) {
    tmp.key = prim5;
  }
  if (prim6 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim6);
  }
  return tmp;
}

export {
  makeProps ,
  make$1 as make,
}
/* styles Not a pure module */
