// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Env$Pos from "../core/Env.bs.js";
import * as $$History from "history";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRouter from "react-router";
import * as ReactRouterDom from "react-router-dom";

function urlToRoute(url) {
  var queryString = url.query;
  var tmp = queryString === "" ? "" : "?" + queryString;
  return url.pathname + tmp;
}

function pathFromPathname(pathname) {
  switch (pathname) {
    case "" :
    case "/" :
        return [];
    default:
      var raw = Js_string.sliceToEnd(1, pathname);
      var match = raw[raw.length - 1 | 0];
      var raw$1 = match === "/" ? Js_string.slice(0, -1, raw) : raw;
      return raw$1.split("/");
  }
}

var match = Env$Pos.context(undefined);

var history = match === "test" ? $$History.createMemoryHistory(undefined) : $$History.createBrowserHistory();

function useUrl(param) {
  var match = React.useContext(ReactRouter.__RouterContext);
  var $$location = match.location;
  return {
          pathname: $$location.pathname,
          path: pathFromPathname($$location.pathname),
          query: $$location.search.substr(1),
          state: $$location.state
        };
}

function useNavigate(param) {
  var match = React.useContext(ReactRouter.__RouterContext);
  var history = match.history;
  return function (pathname, replaceOpt, state, param) {
    var replace = replaceOpt !== undefined ? replaceOpt : false;
    var pushAction = replace ? history.replace : history.push;
    Curry._2(pushAction, pathname, state);
  };
}

function useGoBack(param) {
  var match = React.useContext(ReactRouter.__RouterContext);
  var history = match.history;
  var historyIndex = history.index;
  var canGoBack = historyIndex !== undefined ? historyIndex > 0 : history.length > 1;
  var onGoBack = function (param) {
    Curry._1(history.goBack, undefined);
  };
  return [
          canGoBack,
          onGoBack
        ];
}

function openRoute(route) {
  Curry._2(history.replace, route, undefined);
}

function openNewTabRoute(route) {
  window.open(route);
}

function closeBrowserTab(param) {
  window.close();
}

function toRoute(route) {
  return {
          TAG: /* Route */0,
          _0: route
        };
}

function toUrl(href) {
  return {
          TAG: /* Url */2,
          _0: href
        };
}

function Navigation$Link(Props) {
  var children = Props.children;
  var to = Props.to;
  var openNewTabOpt = Props.openNewTab;
  var disabledOpt = Props.disabled;
  var onPress = Props.onPress;
  var openNewTab = openNewTabOpt !== undefined ? openNewTabOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var target = openNewTab ? "_blank" : "_self";
  var onClick = onPress !== undefined && !disabled ? (function (param) {
        Curry._1(onPress, undefined);
      }) : undefined;
  switch (to.TAG | 0) {
    case /* Route */0 :
        if (disabled) {
          return children;
        }
        var tmp = {
          children: children,
          to: {
            pathname: to._0
          },
          target: target
        };
        if (onClick !== undefined) {
          tmp.onClick = Caml_option.valFromOption(onClick);
        }
        return React.createElement(ReactRouterDom.Link, tmp);
    case /* RouteWithQueryString */1 :
        if (disabled) {
          return children;
        }
        var tmp$1 = {
          children: children,
          to: {
            pathname: to._0,
            search: to._1
          },
          target: target
        };
        if (onClick !== undefined) {
          tmp$1.onClick = Caml_option.valFromOption(onClick);
        }
        return React.createElement(ReactRouterDom.Link, tmp$1);
    case /* Url */2 :
        if (disabled) {
          return children;
        }
        var href = to._0.href;
        var tmp$2 = {
          href: href,
          target: target
        };
        if (onClick !== undefined) {
          tmp$2.onClick = Caml_option.valFromOption(onClick);
        }
        return React.createElement("a", tmp$2, children);
    
  }
}

var Link = {
  toRoute: toRoute,
  toUrl: toUrl,
  make: Navigation$Link
};

function Navigation$Provider(Props) {
  var children = Props.children;
  var customHistory = Props.history;
  return React.createElement(ReactRouter.Router, {
              children: children,
              history: Belt_Option.getWithDefault(customHistory, history)
            });
}

var Provider = {
  make: Navigation$Provider
};

var make = React.memo(function (Props) {
      var message = Props.message;
      var shouldBlockOnRouteChange = Props.shouldBlockOnRouteChange;
      var match = React.useContext(ReactRouter.__RouterContext);
      var $$location = match.location;
      var history = match.history;
      var unblock = React.useRef(undefined);
      var handleUnload = React.useCallback((function (domEvent) {
              if (Curry._1(shouldBlockOnRouteChange, $$location.pathname)) {
                domEvent.returnValue = message;
                return ;
              }
              
            }), [
            $$location.pathname,
            shouldBlockOnRouteChange
          ]);
      React.useEffect((function () {
              unblock.current = Curry._1(history.block, (function ($$location, _action) {
                      if (Curry._1(shouldBlockOnRouteChange, $$location.pathname)) {
                        return message;
                      }
                      
                    }));
              return (function (param) {
                        var unblock$1 = unblock.current;
                        if (unblock$1 !== undefined) {
                          return Curry._1(unblock$1, undefined);
                        }
                        
                      });
            }), [shouldBlockOnRouteChange]);
      React.useEffect((function () {
              window.addEventListener("beforeunload", handleUnload);
              return (function (param) {
                        window.removeEventListener("beforeunload", handleUnload);
                      });
            }), [handleUnload]);
      return null;
    });

var Prompt = {
  make: make
};

function Navigation$Redirect(Props) {
  var pathname = Props.route;
  return React.createElement(ReactRouterDom.Redirect, {
              to: {
                pathname: pathname
              }
            });
}

var Redirect = {
  make: Navigation$Redirect
};

export {
  urlToRoute ,
  useUrl ,
  useNavigate ,
  useGoBack ,
  openRoute ,
  openNewTabRoute ,
  closeBrowserTab ,
  Link ,
  Provider ,
  Prompt ,
  Redirect ,
}
/* match Not a pure module */
