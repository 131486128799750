// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Stack$Pos from "./Stack.bs.js";

function StackFields(Props) {
  var children = Props.children;
  return React.createElement(Stack$Pos.make, {
              children: children,
              space: "large"
            });
}

var make = StackFields;

export {
  make ,
}
/* react Not a pure module */
