// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Menu$Pos from "../../resources/navigation/Menu.bs.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../../resources/overlays/Popover.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as MenuItem$Pos from "../../resources/navigation/MenuItem.bs.js";
import * as Scaffold$Pos from "../../bundles/Scaffold/Scaffold.bs.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as SearchBar$Pos from "../../resources/selection-and-input/SearchBar.bs.js";
import * as Separator$Pos from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as TextAction$Pos from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as CatalogLabel$Pos from "../../modules/Catalog/CatalogLabel.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as SpinnerModal$Pos from "../../resources/feedback-indicators/SpinnerModal.bs.js";
import * as CatalogProduct$Pos from "../../modules/Catalog/CatalogProduct.bs.js";
import * as RequestMenuItem$Pos from "../../resources/actions/RequestMenuItem.bs.js";
import * as TriggerDownload$Pos from "../../primitives/TriggerDownload.bs.js";
import * as CatalogTableActions$Pos from "../../modules/Catalog/CatalogTableActions.bs.js";
import * as CatalogCategoryEditCell$Pos from "../../modules/Catalog/CatalogCategoryEditCell.bs.js";
import * as CatalogProductStatusBadge$Pos from "../../modules/Catalog/CatalogProductStatusBadge.bs.js";
import * as ProductReferenceTableCell$Pos from "../../modules/Product/ProductReferenceTableCell.bs.js";
import * as CatalogLabelQuickPrintButton$Pos from "../../modules/Catalog/CatalogLabelQuickPrintButton.bs.js";
import * as RequestOpenStorageUrlMenuItem$Pos from "../../resources/actions/RequestOpenStorageUrlMenuItem.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as CatalogProductEditUrlQueryString$Pos from "../../modules/Catalog/CatalogProductEditUrlQueryString.bs.js";
import * as CatalogVariantStockThresholdCell$Pos from "../../modules/Catalog/CatalogVariantStockThresholdCell.bs.js";
import * as PricePurchaseTableEditCellWrapper$Pos from "../../modules/Price/PricePurchaseTableEditCellWrapper.bs.js";

var Raw = {};

var query = Client.gql(["query CatalogPageQuery($search: String, $filterBy: InputVariantsQueryFilter, $before: String, $after: String, $first: Int, $last: Int)  {\nvariantsDistinctOnCku(search: $search, filterBy: $filterBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \ncku  \nid  \nformattedName  \nformattedDescription  \nformattedPurchasedPrice  \npurchasedPrice  \nstockKeepingUnit  \nformattedCategory  \nbulk  \ncapacityUnit  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nstock  {\n__typename  \nformattedQuantity  \nstate  \nformattedShopsNames  \n}\n\nproduct  {\n__typename  \nid  \ncolor  \nproducer  \n}\n\nformattedStatus  \n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          variantsDistinctOnCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.formattedPurchasedPrice;
                    var value$3 = value$1.purchasedPrice;
                    var value$4 = value$1.stockKeepingUnit;
                    var value$5 = value$1.formattedCategory;
                    var value$6 = value$1.bulk;
                    var value$7 = value$1.capacityUnit;
                    var value$8 = value$1.maxStockThreshold;
                    var value$9 = value$1.minStockThreshold;
                    var value$10 = value$1.stockOrderTriggerThreshold;
                    var value$11 = value$1.stock;
                    var value$12 = value$11.formattedQuantity;
                    var value$13 = value$11.state;
                    var tmp;
                    if (value$13 == null) {
                      tmp = undefined;
                    } else {
                      var tmp$1;
                      switch (value$13) {
                        case "ALERT" :
                            tmp$1 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$1 = "DANGER";
                            break;
                        case "OK" :
                            tmp$1 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp = tmp$1;
                    }
                    var value$14 = value$11.formattedShopsNames;
                    var value$15 = value$1.product;
                    var value$16 = value$15.color;
                    var tmp$2;
                    if (value$16 == null) {
                      tmp$2 = undefined;
                    } else {
                      var tmp$3;
                      switch (value$16) {
                        case "AMBER" :
                            tmp$3 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$3 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$3 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$3 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$3 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$3 = "RED";
                            break;
                        case "ROSE" :
                            tmp$3 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$3 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$2 = tmp$3;
                    }
                    var value$17 = value$15.producer;
                    var value$18 = value$1.formattedStatus;
                    var tmp$4;
                    switch (value$18) {
                      case "ACTIVE" :
                          tmp$4 = "ACTIVE";
                          break;
                      case "ARCHIVED" :
                          tmp$4 = "ARCHIVED";
                          break;
                      case "INACTIVE" :
                          tmp$4 = "INACTIVE";
                          break;
                      case "VARIABLE" :
                          tmp$4 = "VARIABLE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              cku: Scalar$Pos.CKU.parse(value$1.cku),
                              id: value$1.id,
                              formattedName: value$1.formattedName,
                              formattedDescription: value$1.formattedDescription,
                              formattedPurchasedPrice: !(value$2 == null) ? value$2 : undefined,
                              purchasedPrice: !(value$3 == null) ? value$3 : undefined,
                              stockKeepingUnit: !(value$4 == null) ? value$4 : undefined,
                              formattedCategory: !(value$5 == null) ? value$5 : undefined,
                              bulk: !(value$6 == null) ? value$6 : undefined,
                              capacityUnit: !(value$7 == null) ? value$7 : undefined,
                              maxStockThreshold: !(value$8 == null) ? value$8 : undefined,
                              minStockThreshold: !(value$9 == null) ? value$9 : undefined,
                              stockOrderTriggerThreshold: !(value$10 == null) ? value$10 : undefined,
                              stock: {
                                __typename: value$11.__typename,
                                formattedQuantity: !(value$12 == null) ? value$12 : undefined,
                                state: tmp,
                                formattedShopsNames: !(value$14 == null) ? value$14 : undefined
                              },
                              product: {
                                __typename: value$15.__typename,
                                id: value$15.id,
                                color: tmp$2,
                                producer: !(value$17 == null) ? value$17 : undefined
                              },
                              formattedStatus: tmp$4
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedStatus;
          var formattedStatus = value$2 === "INACTIVE" ? "INACTIVE" : (
              value$2 === "ARCHIVED" ? "ARCHIVED" : (
                  value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
                )
            );
          var value$3 = value$1.product;
          var value$4 = value$3.producer;
          var producer = value$4 !== undefined ? value$4 : null;
          var value$5 = value$3.color;
          var color = value$5 !== undefined ? (
              value$5 === "ORANGE" ? "ORANGE" : (
                  value$5 === "RED" ? "RED" : (
                      value$5 === "AMBER" ? "AMBER" : (
                          value$5 === "DARK" ? "DARK" : (
                              value$5 === "BLACK" ? "BLACK" : (
                                  value$5 === "BLOND" ? "BLOND" : (
                                      value$5 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$6 = value$3.id;
          var value$7 = value$3.__typename;
          var product = {
            __typename: value$7,
            id: value$6,
            color: color,
            producer: producer
          };
          var value$8 = value$1.stock;
          var value$9 = value$8.formattedShopsNames;
          var formattedShopsNames = value$9 !== undefined ? value$9 : null;
          var value$10 = value$8.state;
          var state = value$10 !== undefined ? (
              value$10 === "OK" ? "OK" : (
                  value$10 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$11 = value$8.formattedQuantity;
          var formattedQuantity = value$11 !== undefined ? value$11 : null;
          var value$12 = value$8.__typename;
          var stock = {
            __typename: value$12,
            formattedQuantity: formattedQuantity,
            state: state,
            formattedShopsNames: formattedShopsNames
          };
          var value$13 = value$1.stockOrderTriggerThreshold;
          var stockOrderTriggerThreshold = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.minStockThreshold;
          var minStockThreshold = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.maxStockThreshold;
          var maxStockThreshold = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.capacityUnit;
          var capacityUnit = value$16 !== undefined ? value$16 : null;
          var value$17 = value$1.bulk;
          var bulk = value$17 !== undefined ? value$17 : null;
          var value$18 = value$1.formattedCategory;
          var formattedCategory = value$18 !== undefined ? value$18 : null;
          var value$19 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$19 !== undefined ? value$19 : null;
          var value$20 = value$1.purchasedPrice;
          var purchasedPrice = value$20 !== undefined ? value$20 : null;
          var value$21 = value$1.formattedPurchasedPrice;
          var formattedPurchasedPrice = value$21 !== undefined ? value$21 : null;
          var value$22 = value$1.formattedDescription;
          var value$23 = value$1.formattedName;
          var value$24 = value$1.id;
          var value$25 = value$1.cku;
          var value$26 = Scalar$Pos.CKU.serialize(value$25);
          var value$27 = value$1.__typename;
          var node = {
            __typename: value$27,
            cku: value$26,
            id: value$24,
            formattedName: value$23,
            formattedDescription: value$22,
            formattedPurchasedPrice: formattedPurchasedPrice,
            purchasedPrice: purchasedPrice,
            stockKeepingUnit: stockKeepingUnit,
            formattedCategory: formattedCategory,
            bulk: bulk,
            capacityUnit: capacityUnit,
            maxStockThreshold: maxStockThreshold,
            minStockThreshold: minStockThreshold,
            stockOrderTriggerThreshold: stockOrderTriggerThreshold,
            stock: stock,
            product: product,
            formattedStatus: formattedStatus
          };
          var value$28 = value.__typename;
          return {
                  __typename: value$28,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var variantsDistinctOnCku = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          variantsDistinctOnCku: variantsDistinctOnCku
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.categoryId;
  var a$6 = inp.createdAt;
  var a$7 = inp.updatedAt;
  var a$8 = inp.supplierId;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          categoryId: a$5 !== undefined ? ({
                _equals: a$5._equals
              }) : undefined,
          createdAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          updatedAt: a$7 !== undefined ? serializeInputObjectDateFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? ({
                _equals: a$8._equals
              }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.before;
  var a$3 = inp.after;
  var a$4 = inp.first;
  var a$5 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsQueryFilter(a$1) : undefined,
          before: a$2 !== undefined ? a$2 : undefined,
          after: a$3 !== undefined ? a$3 : undefined,
          first: a$4 !== undefined ? a$4 : undefined,
          last: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables(search, filterBy, before, after, first, last, param) {
  return {
          search: search,
          filterBy: filterBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, categoryId, createdAt, updatedAt, supplierId, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          categoryId: categoryId,
          createdAt: createdAt,
          updatedAt: updatedAt,
          supplierId: supplierId
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          filterBy: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var make = React.memo(function (Props) {
      var notification = Props.notification;
      var onRequestClose = Props.onRequestClose;
      if (notification === undefined) {
        return null;
      }
      var tmp;
      tmp = notification.TAG === /* Ok */0 ? React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Success */0,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            }) : React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Danger */1,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            });
      return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(tmp), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    });

var NotificationBanner = {
  make: make
};

var endpoint = Env$Pos.sheetUrl(undefined) + "/inventory";

function encodeRequestBodyJson(shopIds, variantsActive, variantsArchived, param) {
  var body = {};
  var match = Intl$Pos.resolvedOptions(Intl$Pos.dateTimeFormatConstructor(undefined, undefined, undefined));
  body["shopIds"] = Json$Pos.encodeArray(Belt_Array.map(shopIds, Json$Pos.encodeString));
  if (variantsActive !== undefined) {
    body["variantsActive"] = Json$Pos.encodeBoolean(variantsActive);
  }
  if (variantsArchived !== undefined) {
    body["variantsArchived"] = Json$Pos.encodeBoolean(variantsArchived);
  }
  body["timeZone"] = Json$Pos.encodeString(match.timeZone);
  return Json$Pos.encodeDict(body);
}

function CatalogPage$InventoryExportMenuItem(Props) {
  var text = Props.text;
  var shopIds = Props.shopIds;
  var variantsActive = Props.variantsActive;
  var variantsArchived = Props.variantsArchived;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover$Pos.useState(undefined);
  var onRequestClose = match.close;
  var request = function (param) {
    return Request$Pos.makeAndDecode("POST", Caml_option.some(encodeRequestBodyJson(shopIds, variantsActive, variantsArchived, undefined)), undefined, undefined, endpoint);
  };
  var onSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
  };
  var onFailure = function (error) {
    var errorMessage = RequestOpenStorageUrlMenuItem$Pos.failureErrorToString(error, "inventory");
    Curry._1(onRequestErrorNotification, errorMessage);
    Curry._1(onRequestClose, undefined);
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request
  };
  return React.createElement(RequestOpenStorageUrlMenuItem$Pos.make, {
              text: text,
              operableRequest: operableRequest,
              onSuccess: onSuccess,
              onFailure: onFailure
            });
}

var InventoryExportMenuItem = {
  endpoint: endpoint,
  encodeRequestBodyJson: encodeRequestBodyJson,
  make: CatalogPage$InventoryExportMenuItem
};

var endpoint$1 = Env$Pos.sheetUrl(undefined) + "/variants-price-look-up-codes";

function encodeRequestBodyJson$1(shopId, variantsActive, variantsArchived, param) {
  var body = {};
  body["shopId"] = Json$Pos.encodeString(shopId);
  if (variantsActive !== undefined) {
    body["variantsActive"] = Json$Pos.encodeBoolean(variantsActive);
  }
  if (variantsArchived !== undefined) {
    body["variantsArchived"] = Json$Pos.encodeBoolean(variantsArchived);
  }
  return Json$Pos.encodeDict(body);
}

function CatalogPage$PriceLookUpExportMenuItem(Props) {
  var text = Props.text;
  var shopId = Props.shopId;
  var variantsActive = Props.variantsActive;
  var variantsArchived = Props.variantsArchived;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover$Pos.useState(undefined);
  var onRequestClose = match.close;
  var request = function (param) {
    return Request$Pos.makeAndDecode("POST", Caml_option.some(encodeRequestBodyJson$1(Belt_Option.getWithDefault(shopId, ""), variantsActive, variantsArchived, undefined)), undefined, undefined, endpoint$1);
  };
  var onSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
  };
  var onFailure = function (error) {
    var errorMessage = RequestOpenStorageUrlMenuItem$Pos.failureErrorToString(error, "PLU codes");
    Curry._1(onRequestErrorNotification, errorMessage);
    Curry._1(onRequestClose, undefined);
  };
  var operableRequest = shopId !== undefined ? ({
        TAG: /* Ok */0,
        _0: request
      }) : ({
        TAG: /* Error */1,
        _0: Intl$Pos.t("Please select a shop beforehand with the filter.")
      });
  return React.createElement(RequestOpenStorageUrlMenuItem$Pos.make, {
              text: text,
              operableRequest: operableRequest,
              onSuccess: onSuccess,
              onFailure: onFailure
            });
}

var PriceLookUpExportMenuItem = {
  endpoint: endpoint$1,
  encodeRequestBodyJson: encodeRequestBodyJson$1,
  make: CatalogPage$PriceLookUpExportMenuItem
};

var endpoint$2 = Env$Pos.gatewayUrl(undefined) + "/variants-centralization";

function encodeRequestBodyJson$2(param) {
  return Json$Pos.encodeDict({});
}

function decodeRequestResponseJson(json) {
  var decodedIssuesCount = Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "issuesCount", Json$Pos.decodeNumber);
  var decodedPatchesCount = Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "patchesCount", Json$Pos.decodeNumber);
  var decodedFileUrl = Json$Pos.flatDecodeDictFieldString(Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "file", Json$Pos.decodeDict), "url");
  if (decodedIssuesCount !== undefined && decodedPatchesCount !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              issuesCount: decodedIssuesCount | 0,
              patchesCount: decodedPatchesCount | 0,
              fileUrl: Belt_Option.map(decodedFileUrl, (function (prim) {
                      return new URL(prim);
                    }))
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* CannotDecodeResult */1
          };
  }
}

function renderPatchesText(patchesCount) {
  return React.createElement(TextStyle$Pos.make, {
              children: Intl$Pos.template("▪ " + Intl$Pos.t(Intl$Pos.isPlural(patchesCount) ? "{{productCount}} new products have been successfully centralized." : "1 new product has been successfully centralized."), {
                    productCount: String(patchesCount)
                  }, undefined),
              variation: "success",
              weight: "medium"
            });
}

function renderIssuesText(issuesCount) {
  return React.createElement(TextStyle$Pos.make, {
              children: Intl$Pos.template("▪ " + Intl$Pos.t(Intl$Pos.isPlural(issuesCount) ? "{{productCount}} errors detected, some products could not be centralized." : "1 error detected, a product could not be centralized."), {
                    productCount: String(issuesCount)
                  }, undefined),
              variation: "negative",
              weight: "medium"
            });
}

var make$1 = React.memo(function (Props) {
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestClose = Props.onRequestClose;
      return React.createElement(Stack$Pos.make, {
                  children: null,
                  space: "xxlarge"
                }, React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(Stack$Pos.make, {
                          children: null
                        }, React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                            }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                          children: "▪ " + Intl$Pos.t("no new product has been centralized."),
                                          weight: "medium"
                                        })), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(Inline$Pos.make, {
                          children: null,
                          space: "none"
                        }, React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("To know more about the catalog centralization") + ", "
                            }), React.createElement(TextAction$Pos.make, {
                              text: Intl$Pos.t("please visit the help center."),
                              highlighted: true,
                              onPress: onPressHelpCenterText
                            }))), React.createElement(Inline$Pos.make, {
                      children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Close"), "large", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                  Curry._1(onRequestClose, undefined);
                                }), undefined, undefined, undefined)),
                      align: "end",
                      grow: true
                    }));
    });

var NoIssueNoPatchView = {
  make: make$1
};

var make$2 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestDownloadFile = Props.onRequestDownloadFile;
      return React.createElement(Stack$Pos.make, {
                  children: null,
                  space: "xxlarge"
                }, React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(Stack$Pos.make, {
                          children: null
                        }, React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                            }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(TextStyle$Pos.make, {
                                  children: "▪ " + Intl$Pos.t("no new product has been centralized."),
                                  weight: "medium"
                                }), renderIssuesText(issuesCount))), React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("The products could not be centralized because identical SKUs were detected within the same store. Click on the \"Download errors reporting\" button to retrieve the list of products to correct.")
                        }), React.createElement(Inline$Pos.make, {
                          children: null,
                          space: "none"
                        }, React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("To find out what corrections need to be made") + ", "
                            }), React.createElement(TextAction$Pos.make, {
                              text: Intl$Pos.t("please visit the help center."),
                              highlighted: true,
                              onPress: onPressHelpCenterText
                            }))), React.createElement(Inline$Pos.make, {
                      children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Download errors reporting"), "large", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                  Curry._1(onRequestDownloadFile, undefined);
                                }), undefined, undefined, undefined)),
                      align: "end",
                      grow: true
                    }));
    });

var IssueNoPatchView = {
  make: make$2
};

var make$3 = React.memo(function (Props) {
      var patchesCount = Props.patchesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestClose = Props.onRequestClose;
      return React.createElement(Stack$Pos.make, {
                  children: React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "xxlarge"
                      }, React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Stack$Pos.make, {
                                children: null
                              }, React.createElement(TextStyle$Pos.make, {
                                    children: Intl$Pos.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                                  }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(renderPatchesText(patchesCount)), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(Inline$Pos.make, {
                                children: null,
                                space: "none"
                              }, React.createElement(TextStyle$Pos.make, {
                                    children: Intl$Pos.t("To know more about the catalog centralization") + ", "
                                  }), React.createElement(TextAction$Pos.make, {
                                    text: Intl$Pos.t("please visit the help center."),
                                    highlighted: true,
                                    onPress: onPressHelpCenterText
                                  }))), React.createElement(Inline$Pos.make, {
                            children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Close"), "large", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        Curry._1(onRequestClose, undefined);
                                      }), undefined, undefined, undefined)),
                            align: "end",
                            grow: true
                          })),
                  space: "xxlarge"
                });
    });

var NoIssuePatchView = {
  make: make$3
};

var make$4 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var patchesCount = Props.patchesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestDownloadFile = Props.onRequestDownloadFile;
      return React.createElement(Stack$Pos.make, {
                  children: React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "xxlarge"
                      }, React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Stack$Pos.make, {
                                children: null
                              }, React.createElement(TextStyle$Pos.make, {
                                    children: Intl$Pos.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                                  }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined), renderPatchesText(patchesCount), renderIssuesText(issuesCount))), React.createElement(TextStyle$Pos.make, {
                                children: Intl$Pos.t("The products could not be centralized because identical SKUs were detected within the same store. Click on the \"Download errors reporting\" button to retrieve the list of products to correct.")
                              }), React.createElement(Inline$Pos.make, {
                                children: null,
                                space: "none"
                              }, React.createElement(TextStyle$Pos.make, {
                                    children: Intl$Pos.t("To find out what corrections need to be made") + ", "
                                  }), React.createElement(TextAction$Pos.make, {
                                    text: Intl$Pos.t("please visit the help center."),
                                    highlighted: true,
                                    onPress: onPressHelpCenterText
                                  }))), React.createElement(Inline$Pos.make, {
                            children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Download errors reporting"), "large", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        Curry._1(onRequestDownloadFile, undefined);
                                      }), undefined, undefined, undefined)),
                            align: "end",
                            grow: true
                          })),
                  space: "xxlarge"
                });
    });

var IssuePatchView = {
  make: make$4
};

var make$5 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var patchesCount = Props.patchesCount;
      var fileUrl = Props.fileUrl;
      var onRequestErrorNotification = Props.onRequestErrorNotification;
      var onRequestClose = Props.onRequestClose;
      var match = React.useState(function () {
            return true;
          });
      var setOpened = match[1];
      var onPressHelpCenterText = function (param) {
        HelpCenter$Pos.showArticle(HelpCenter$Pos.howCentralizeCatalog);
      };
      var onRequestClose$1 = function (param) {
        Curry._1(setOpened, (function (param) {
                return false;
              }));
        Curry._1(onRequestClose, undefined);
      };
      var onRequestDownloadFile = function (param) {
        Belt_Option.forEach(fileUrl, (function (url) {
                Future.get(Future.mapError(TriggerDownload$Pos.fromUrl(undefined, url), undefined, (function (param) {
                            return Curry._1(onRequestErrorNotification, Intl$Pos.t("An issue when attempting downloading the file occurred."));
                          })), (function (param) {
                        Curry._1(setOpened, (function (param) {
                                return false;
                              }));
                        Curry._1(onRequestClose, undefined);
                      }));
              }));
      };
      var match$1 = issuesCount > 0;
      var match$2 = patchesCount > 0;
      return React.createElement(Modal$Pos.make, {
                  children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(match$1 ? (
                                  match$2 ? React.createElement(make$4, {
                                          issuesCount: issuesCount,
                                          patchesCount: patchesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestDownloadFile: onRequestDownloadFile
                                        }) : React.createElement(make$2, {
                                          issuesCount: issuesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestDownloadFile: onRequestDownloadFile
                                        })
                                ) : (
                                  match$2 ? React.createElement(make$3, {
                                          patchesCount: patchesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestClose: onRequestClose$1
                                        }) : React.createElement(make$1, {
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestClose: onRequestClose$1
                                        })
                                )), "xlarge", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                  opened: match[0],
                  title: Intl$Pos.t("Centralize catalogs"),
                  variation: "secondary",
                  hideFooter: true,
                  onRequestClose: onRequestClose$1
                });
    });

var ReportModal = {
  renderPatchesText: renderPatchesText,
  renderIssuesText: renderIssuesText,
  NoIssueNoPatchView: NoIssueNoPatchView,
  IssueNoPatchView: IssueNoPatchView,
  NoIssuePatchView: NoIssuePatchView,
  IssuePatchView: IssuePatchView,
  make: make$5
};

function CatalogPage$CentralizeRequestMenuItem(Props) {
  var text = Props.text;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover$Pos.useState(undefined);
  var match$1 = React.useState(function () {
        return /* NotAsked */0;
      });
  var setAsyncResult = match$1[1];
  var asyncResult = match$1[0];
  React.useEffect((function () {
          if (typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1 && asyncResult._0.TAG !== /* Ok */0) {
            Curry._1(onRequestErrorNotification, Request$Pos.serverErrorMessage);
          }
          
        }), [asyncResult]);
  var request = function (param) {
    return Future.map(Request$Pos.makeAndDecode("POST", Caml_option.some(Json$Pos.encodeDict({})), undefined, undefined, endpoint$2), undefined, (function (json) {
                  return Belt_Result.flatMap(json, decodeRequestResponseJson);
                }));
  };
  var onChange = function (asyncResult) {
    if (typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1) {
      var match = asyncResult._0;
      if (match.TAG !== /* Ok */0) {
        return Curry._1(setAsyncResult, (function (param) {
                      return asyncResult;
                    }));
      }
      var heuristicTimeout = 1000 + Math.imul(match._0.patchesCount, 175) | 0;
      setTimeout((function (param) {
              Curry._1(setAsyncResult, (function (param) {
                      return asyncResult;
                    }));
            }), heuristicTimeout);
      return ;
    }
    Curry._1(setAsyncResult, (function (param) {
            return asyncResult;
          }));
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request
  };
  var tmp;
  if (typeof asyncResult === "number" || asyncResult.TAG !== /* Done */1) {
    tmp = null;
  } else {
    var match$2 = asyncResult._0;
    if (match$2.TAG === /* Ok */0) {
      var match$3 = match$2._0;
      tmp = React.createElement(make$5, {
            issuesCount: match$3.issuesCount,
            patchesCount: match$3.patchesCount,
            fileUrl: match$3.fileUrl,
            onRequestErrorNotification: onRequestErrorNotification,
            onRequestClose: match.close
          });
    } else {
      tmp = null;
    }
  }
  return React.createElement(React.Fragment, undefined, React.createElement(SpinnerModal$Pos.make, {
                  title: text,
                  opened: AsyncResult$Pos.isBusy(asyncResult)
                }), React.createElement(RequestMenuItem$Pos.make, {
                  text: text,
                  operableRequest: operableRequest,
                  onChange: onChange
                }), tmp);
}

var CentralizeRequestMenuItem = {
  endpoint: endpoint$2,
  encodeRequestBodyJson: encodeRequestBodyJson$2,
  decodeRequestResponseJson: decodeRequestResponseJson,
  ReportModal: ReportModal,
  make: CatalogPage$CentralizeRequestMenuItem
};

function getStatuses(cavavinUserPermitted) {
  if (cavavinUserPermitted) {
    return [
            /* Active */0,
            /* Inactive */1,
            /* Archived */2
          ];
  } else {
    return [
            /* Unarchived */3,
            /* Archived */2
          ];
  }
}

function encoder(param) {
  return [
          Belt_Option.map(param.shop, (function (shop) {
                  return shop.id;
                })),
          Belt_Option.map(param.status, CatalogProduct$Pos.Status.toString)
        ];
}

function decoder(shops, param) {
  var shopId = param[0];
  var match = Belt_Option.map(param[1], CatalogProduct$Pos.Status.fromString);
  var tmp;
  tmp = match !== undefined && match.TAG === /* Ok */0 ? match._0 : undefined;
  return {
          TAG: /* Ok */0,
          _0: {
            shop: Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === shopId;
                  })),
            status: tmp
          }
        };
}

function useJsonCodec(param) {
  var shops = Auth$Pos.useShops(undefined);
  return JsonCodec$Pos.object2(encoder, (function (param) {
                return decoder(shops, param);
              }), JsonCodec$Pos.optional(JsonCodec$Pos.field("shopId", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("status", JsonCodec$Pos.string)));
}

var Filters = {
  getStatuses: getStatuses,
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function makeQueryVariables(_defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          search: search,
          filterBy: filterBy,
          before: connectionArguments.before,
          after: connectionArguments.after,
          first: connectionArguments.first,
          last: connectionArguments.last
        };
}

function makeQueryVariablesFilterBy(param) {
  var status = param.status;
  var shop = param.shop;
  return {
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined,
          stockKeepingUnit: undefined,
          ean13: undefined,
          active: status !== undefined ? (
              status !== 1 ? (
                  status !== 0 ? undefined : ({
                        _equals: true
                      })
                ) : ({
                    _equals: false
                  })
            ) : undefined,
          archived: status !== undefined ? (
              status !== 2 ? "EXCLUDED" : "ONLY"
            ) : "INCLUDED",
          categoryId: undefined,
          createdAt: undefined,
          updatedAt: undefined,
          supplierId: undefined
        };
}

function totalCountFromQueryData(param) {
  return param.variantsDistinctOnCku.totalCount;
}

function cursorsFromQueryData(param) {
  var variantsDistinctOnCku = param.variantsDistinctOnCku;
  return [
          variantsDistinctOnCku.pageInfo.startCursor,
          variantsDistinctOnCku.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.map(param.variantsDistinctOnCku.edges, (function (edge) {
                var match = edge.node.formattedStatus;
                var match$1 = edge.node.capacityUnit;
                var match$2 = Belt_Option.getWithDefault(edge.node.bulk, false);
                return {
                        cku: edge.node.cku,
                        id: edge.node.id,
                        productId: edge.node.product.id,
                        color: edge.node.product.color,
                        producer: Belt_Option.getWithDefault(edge.node.product.producer, "-"),
                        name: edge.node.formattedName,
                        description: edge.node.formattedDescription,
                        categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, ""),
                        purchasePrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        formattedPurchasePrice: edge.node.formattedPurchasedPrice,
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        stockQuantity: edge.node.stock.formattedQuantity,
                        stockState: edge.node.stock.state,
                        formattedShopsNames: edge.node.stock.formattedShopsNames,
                        status: match === "INACTIVE" ? /* Inactive */1 : (
                            match === "ARCHIVED" ? /* Archived */2 : (
                                match === "ACTIVE" ? /* Active */0 : undefined
                              )
                          ),
                        bulkUnit: match$1 !== undefined && match$2 ? match$1 : undefined,
                        maxStockThreshold: Belt_Option.getWithDefault(edge.node.maxStockThreshold, 0),
                        minStockThreshold: Belt_Option.getWithDefault(edge.node.minStockThreshold, 0),
                        stockOrderTriggerThreshold: Belt_Option.getWithDefault(edge.node.stockOrderTriggerThreshold, 0),
                        bulk: Belt_Option.getWithDefault(edge.node.bulk, false)
                      };
              }));
}

function keyExtractor(param) {
  return param.id;
}

var Scaffolded = Scaffold$Pos.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

function makeFromState(param) {
  var filters = param.filters;
  var status = filters.status;
  var shopId = Belt_Option.map(filters.shop, (function (param) {
          return param.id;
        }));
  var variantsActive = status !== undefined ? (
      status !== 1 ? (
          status !== 0 ? undefined : true
        ) : false
    ) : undefined;
  var variantsArchived = status !== undefined ? (
      status !== 2 ? false : true
    ) : undefined;
  return {
          shopId: shopId,
          variantsActive: variantsActive,
          variantsArchived: variantsArchived
        };
}

var SheetExportInput = {
  makeFromState: makeFromState
};

function CatalogPage(Props) {
  var cavavinUserPermitted = Props.cavavinUserPermitted;
  var variantIdfromLabelEditSettings = Props.variantIdfromLabelEditSettings;
  var authDispatch = Auth$Pos.useDispatch(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var match = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = match.TAG === /* Organisation */0 ? true : false;
  var initialFilterStatus = cavavinUserPermitted ? /* Active */0 : /* Unarchived */3;
  var initialFilters = {
    shop: activeShop,
    status: initialFilterStatus
  };
  var initialState = Curry._1(Scaffolded.makeInitialState, initialFilters);
  var match$1 = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var defaultQueryVariables = {
    search: undefined,
    filterBy: undefined,
    before: undefined,
    after: undefined,
    first: undefined,
    last: undefined
  };
  var match$2 = React.useState(function () {
        
      });
  var setPrinterStatusResult = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setNotification = match$3[1];
  React.useEffect((function () {
          if (state.filters.shop !== activeShop) {
            Curry._1(authDispatch, {
                  TAG: /* ActiveShopSet */2,
                  _0: state.filters.shop
                });
          }
          
        }), [state.filters.shop]);
  React.useEffect((function () {
          var match = state.filters.shop;
          if (match === undefined) {
            return ;
          }
          var future = Curry._1(CatalogLabel$Pos.Print.DefaultPrinterRequest.make, match.id);
          Future.map(future, undefined, (function (result) {
                  Curry._1(setPrinterStatusResult, (function (param) {
                          return result;
                        }));
                }));
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [state.filters.shop]);
  var columns = [
    {
      name: Intl$Pos.t("Name and description"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 250
        },
        width: {
          NAME: "fr",
          VAL: 5
        },
        margin: "medium",
        sticky: true
      },
      render: (function (param) {
          var pastilleColor = Belt_Option.map(param.color, (function (color) {
                  return CatalogProduct$Pos.Color.toColorSet(color, "pastille").foregroundColor;
                }));
          var tmp = {
            cku: param.cku,
            name: param.name,
            description: param.description
          };
          if (pastilleColor !== undefined) {
            tmp.pastilleColor = Caml_option.valFromOption(pastilleColor);
          }
          if (param.stockKeepingUnit !== undefined) {
            tmp.stockKeepingUnit = param.stockKeepingUnit;
          }
          return React.createElement(ProductReferenceTableCell$Pos.make, tmp);
        })
    },
    {
      name: Intl$Pos.t("Producer"),
      layout: {
        breakpoint: "huge",
        defaultWidth: {
          NAME: "px",
          VAL: 200
        },
        margin: "xlarge"
      },
      render: (function (param) {
          return React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.t(param.producer)
                    });
        })
    },
    {
      name: Intl$Pos.t("Category"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 120
        },
        width: {
          NAME: "pct",
          VAL: 17
        },
        margin: "small"
      },
      render: (function (param) {
          var categoryName = param.categoryName;
          var match = state.filters.shop;
          var tmp = categoryName === "VARIABLE" ? React.createElement(TextStyle$Pos.make, {
                  children: Intl$Pos.t("Differing"),
                  variation: "normal"
                }) : (
              match !== undefined ? React.createElement(CatalogCategoryEditCell$Pos.make, {
                      value: categoryName,
                      productId: param.productId
                    }) : React.createElement(TextStyle$Pos.make, {
                      children: categoryName
                    })
            );
          return React.createElement(React.Fragment, undefined, tmp);
        })
    },
    {
      name: Intl$Pos.t("Stock"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        },
        width: {
          NAME: "px",
          VAL: 125
        }
      },
      render: (function (param) {
          return React.createElement(CatalogVariantStockThresholdCell$Pos.make, {
                      stockQuantity: param.stockQuantity,
                      stockState: param.stockState,
                      formattedShopsNames: param.formattedShopsNames,
                      id: param.id,
                      maxStockThreshold: param.maxStockThreshold,
                      minStockThreshold: param.minStockThreshold,
                      stockOrderTriggerThreshold: param.stockOrderTriggerThreshold,
                      bulk: param.bulk
                    });
        })
    },
    {
      name: Intl$Pos.t("Purchase price"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 120
        },
        width: {
          NAME: "fr",
          VAL: 2
        }
      },
      render: (function (param) {
          var formattedPurchasePrice = param.formattedPurchasePrice;
          var disabled = Belt_Option.isNone(state.filters.shop);
          if (formattedPurchasePrice === "VARIABLE") {
            return React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("Differing"),
                        variation: "normal"
                      });
          }
          return React.createElement(PricePurchaseTableEditCellWrapper$Pos.make, {
                      value: param.purchasePrice,
                      formattedPurchasePrice: formattedPurchasePrice,
                      bulkUnit: param.bulkUnit,
                      disabled: disabled,
                      variantId: param.id
                    });
        })
    },
    {
      name: Intl$Pos.t("Status"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        },
        width: {
          NAME: "fr",
          VAL: 1.7
        }
      },
      render: (function (param) {
          var status = param.status;
          if (status !== undefined) {
            return React.createElement(CatalogProductStatusBadge$Pos.make, {
                        value: status
                      });
          } else {
            return React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("Differing"),
                        variation: "normal"
                      });
          }
        })
    },
    {
      layout: {
        hidden: Belt_Option.isNone(state.filters.shop) || Belt_Option.mapWithDefault(match$2[0], true, Belt_Result.isError),
        minWidth: {
          NAME: "px",
          VAL: 50
        },
        width: {
          NAME: "px",
          VAL: 50
        }
      },
      render: (function (param) {
          var id = param.id;
          var match = state.filters.shop;
          if (match !== undefined) {
            return React.createElement(CatalogLabelQuickPrintButton$Pos.make, {
                        variation: "rounded",
                        featureLocked: false,
                        fromEditRedirection: variantIdfromLabelEditSettings === id,
                        variantId: id,
                        shopId: match.id,
                        requestBarcodeCompletion: CatalogLabel$Pos.BarcodeCompletionRequest.make,
                        requestLabelsPrinting: CatalogLabel$Pos.Print.LabelsRequest.make,
                        onRequestNotification: (function (value) {
                            Curry._1(setNotification, (function (param) {
                                    return value;
                                  }));
                          })
                      });
          } else {
            return null;
          }
        })
    },
    {
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 70
        },
        width: {
          NAME: "px",
          VAL: 70
        }
      },
      render: (function (param) {
          var tmp = {
            cku: param.cku,
            id: param.id
          };
          if (param.status !== undefined) {
            tmp.status = Caml_option.valFromOption(param.status);
          }
          return React.createElement(CatalogTableActions$Pos.make, tmp);
        })
    }
  ];
  var tmp;
  if (organisationAccount) {
    var tmp$1 = {
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: shop,
                            status: prev.status
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp$1.value = Caml_option.valFromOption(state.filters.shop);
    }
    tmp = React.createElement(Auth$Pos.SelectShop.make, tmp$1);
  } else {
    tmp = null;
  }
  var statuses = getStatuses(cavavinUserPermitted);
  var defaultItem_label = Intl$Pos.t("All");
  var defaultItem = {
    key: "default",
    label: defaultItem_label
  };
  var items = Belt_Array.map(statuses, (function (value) {
          return {
                  key: CatalogProduct$Pos.Status.toString(value),
                  value: value,
                  label: CatalogProduct$Pos.Status.toLabel(value)
                };
        }));
  var match$4 = state.filters;
  var match$5 = match$4.status;
  var tmp$2;
  var exit = 0;
  if (match$5 !== undefined) {
    if (match$5 !== 0) {
      if (match$5 >= 3 && !cavavinUserPermitted) {
        tmp$2 = null;
      } else {
        exit = 1;
      }
    } else if (cavavinUserPermitted) {
      tmp$2 = null;
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$2 = React.createElement(Scaffold$Pos.ResetFiltersButton.make, {
          onPress: (function (param) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (param) {
                        return initialState.filters;
                      })
                  });
            })
        });
  }
  var filters = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, tmp, organisationAccount ? React.createElement(Separator$Pos.make, {}) : null, React.createElement(Select$Pos.make, {
            label: Intl$Pos.t("Status"),
            sections: [{
                items: Belt_Array.concat(items, [defaultItem]),
                title: Intl$Pos.t("Statuses")
              }],
            value: state.filters.status,
            onChange: (function (status) {
                Curry._1(dispatch, {
                      TAG: /* FiltersUpdated */2,
                      _0: (function (prev) {
                          return {
                                  shop: prev.shop,
                                  status: status
                                };
                        })
                    });
              })
          }), tmp$2);
  var match$6 = makeFromState(state);
  var variantsArchived = match$6.variantsArchived;
  var variantsActive = match$6.variantsActive;
  var shopId = match$6.shopId;
  var match$7 = Auth$Pos.useShops(undefined);
  var shopIds = match$7.length !== 0 ? (
      shopId !== undefined ? [shopId] : Belt_Array.map(match$7, (function (shop) {
                return shop.id;
              }))
    ) : undefined;
  var makeMenuItemAction = function (productKind) {
    return {
            TAG: /* OpenLink */1,
            _0: {
              TAG: /* RouteWithQueryString */1,
              _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/product/create",
              _1: CatalogProductEditUrlQueryString$Pos.CreateProduct.encode({
                    productKind: productKind
                  })
            }
          };
  };
  var onRequestErrorNotification = function (error) {
    Curry._1(setNotification, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
  };
  var tmp$3;
  if (shopIds !== undefined) {
    var tmp$4 = {
      text: Intl$Pos.t(Intl$Pos.t("Export inventory")),
      shopIds: shopIds,
      onRequestErrorNotification: onRequestErrorNotification
    };
    if (variantsActive !== undefined) {
      tmp$4.variantsActive = Caml_option.valFromOption(variantsActive);
    }
    if (variantsArchived !== undefined) {
      tmp$4.variantsArchived = Caml_option.valFromOption(variantsArchived);
    }
    var tmp$5 = {
      text: Intl$Pos.t("Export PLU codes"),
      shopId: shopId,
      onRequestErrorNotification: onRequestErrorNotification
    };
    if (variantsActive !== undefined) {
      tmp$5.variantsActive = Caml_option.valFromOption(variantsActive);
    }
    if (variantsArchived !== undefined) {
      tmp$5.variantsArchived = Caml_option.valFromOption(variantsArchived);
    }
    tmp$3 = React.createElement(React.Fragment, undefined, React.createElement(MenuItem$Pos.make, {
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Import inventory")
              },
              size: "normal",
              action: {
                TAG: /* OpenLink */1,
                _0: {
                  TAG: /* Route */0,
                  _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/inventory/import"
                }
              }
            }), React.createElement(CatalogPage$InventoryExportMenuItem, tmp$4), React.createElement(CatalogPage$PriceLookUpExportMenuItem, tmp$5), React.createElement(MenuItem$Pos.make, {
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Print labels")
              },
              size: "normal",
              action: {
                TAG: /* OpenLink */1,
                _0: {
                  TAG: /* Route */0,
                  _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + "/labels/create"
                }
              }
            }), organisationAccount ? React.createElement(CatalogPage$CentralizeRequestMenuItem, {
                text: Intl$Pos.t("Centralize catalogs"),
                onRequestErrorNotification: onRequestErrorNotification
              }) : null);
  } else {
    tmp$3 = null;
  }
  var actions = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, React.createElement(Menu$Pos.make, {
            children: tmp$3,
            disabled: Belt_Option.isNone(shopIds),
            overlayPriority: false
          }), React.createElement(Menu$Pos.make, {
            children: null,
            buttonText: Intl$Pos.t("Create new reference"),
            buttonVariation: "primary"
          }, React.createElement(MenuItem$Pos.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct$Pos.Kind.toLabel(true, undefined, "WINE")
                },
                action: makeMenuItemAction("WINE")
              }), React.createElement(MenuItem$Pos.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct$Pos.Kind.toLabel(true, undefined, "SPIRITUOUS")
                },
                action: makeMenuItemAction("SPIRITUOUS")
              }), React.createElement(MenuItem$Pos.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct$Pos.Kind.toLabel(true, undefined, "BEER")
                },
                action: makeMenuItemAction("BEER")
              }), React.createElement(MenuItem$Pos.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct$Pos.Kind.toLabel(true, undefined, "SIMPLE")
                },
                action: makeMenuItemAction("SIMPLE")
              })));
  var banner = React.createElement(make, {
        notification: match$3[0],
        onRequestClose: (function (param) {
            Curry._1(setNotification, (function (param) {
                    
                  }));
          })
      });
  var tmp$6 = {
    placeholder: Intl$Pos.t("Search a product"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$6.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar$Pos.make, tmp$6);
  var emptyState;
  var exit$1 = 0;
  if (state.currentPage !== 1 || state.searchQuery !== undefined) {
    exit$1 = 1;
  } else {
    var match$8 = state.filters;
    if (match$8.status !== undefined || match$8.shop !== activeShop) {
      exit$1 = 1;
    } else {
      emptyState = React.createElement(EmptyState$Pos.make, {
            illustration: Illustration$Pos.create,
            title: Intl$Pos.t("Welcome to the catalog space.")
          });
    }
  }
  if (exit$1 === 1) {
    emptyState = React.createElement(EmptyState$Pos.make, {
          illustration: Illustration$Pos.notFound,
          title: Intl$Pos.t("No result were found."),
          text: Intl$Pos.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  return React.createElement(Scaffolded.make, {
              title: Intl$Pos.t("Catalog"),
              state: state,
              dispatch: dispatch,
              columns: columns,
              filters: filters,
              actions: actions,
              banner: banner,
              searchBar: searchBar,
              emptyState: emptyState,
              defaultQueryVariables: defaultQueryVariables
            });
}

var make$6 = React.memo(CatalogPage);

export {
  Query ,
  NotificationBanner ,
  InventoryExportMenuItem ,
  PriceLookUpExportMenuItem ,
  CentralizeRequestMenuItem ,
  Filters ,
  Row ,
  Scaffolded ,
  SheetExportInput ,
  make$6 as make,
}
/* query Not a pure module */
