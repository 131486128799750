// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as CatalogProductStatusBadge$Pos from "./CatalogProductStatusBadge.bs.js";

function keyExtractor(row) {
  return row.id + "-status";
}

var Row = {
  keyExtractor: keyExtractor
};

var columns = [
  {
    key: "shopname",
    name: Intl$Pos.t("Shop/Warehouse"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 150
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.data.shopName
                  });
      })
  },
  {
    key: "status",
    name: Intl$Pos.t("Status"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      width: {
        NAME: "fr",
        VAL: 0
      }
    },
    render: (function (param) {
        return React.createElement(CatalogProductStatusBadge$Pos.make, {
                    value: param.data.status
                  });
      })
  }
];

function CatalogVariantStatusTableCard(Props) {
  var shopsVariant = Props.shopsVariant;
  var rows = Belt_Array.map(shopsVariant, (function (product) {
          return {
                  id: product.id,
                  shopName: product.shopName,
                  status: product.status
                };
        }));
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TableView$Pos.make, {
                                data: {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Ok */0,
                                    _0: rows
                                  }
                                },
                                columns: columns,
                                keyExtractor: keyExtractor,
                                hideCard: true
                              })), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              title: Intl$Pos.t("Statuses"),
              variation: "table"
            });
}

var make = React.memo(CatalogVariantStatusTableCard);

var Config;

export {
  Config ,
  Row ,
  columns ,
  make ,
}
/* columns Not a pure module */
