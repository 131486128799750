// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as StockActivityReason$Pos from "./StockActivityReason.bs.js";

function StockActivityTypeTableCell(Props) {
  var kind = Props.value;
  var reason = Props.reason;
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                          children: reason !== undefined && reason !== "LOSS" ? kind + " > " + StockActivityReason$Pos.toLabel(reason) : kind
                        })), undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = React.memo(StockActivityTypeTableCell);

export {
  make ,
}
/* make Not a pure module */
