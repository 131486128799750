// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Scaffold$Pos from "../../bundles/Scaffold/Scaffold.bs.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as Separator$Pos from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as AmountTableCell$Pos from "../../modules/AmountTableCell.bs.js";
import * as SelectDateRange$Pos from "../../resources/selection-and-input/SelectDateRange.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query AnalyticsShopsPageQuery($filterBy: ShopKeyPerformanceIndicatorsQueryFilter)  {\nshopsKeyPerformanceIndicators(filterBy: $filterBy)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nshop  {\n__typename  \nid  \nname  \n}\n\nticketsRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\ninvoicesRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\nglobalRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\nsalesCount  \nsalesIndex  \nshoppingCartAverage  \ntotalAmountOfTaxes  \nproductsSoldCount  \ntotalPurchaseCost  \nmargin  \nmarginRate  \nmarkupRate  \n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.shopsKeyPerformanceIndicators;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          shopsKeyPerformanceIndicators: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    var value$3 = value$1.ticketsRevenue;
                    var value$4 = value$1.invoicesRevenue;
                    var value$5 = value$1.globalRevenue;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name
                              },
                              ticketsRevenue: {
                                __typename: value$3.__typename,
                                includingTaxes: value$3.includingTaxes,
                                excludingTaxes: value$3.excludingTaxes
                              },
                              invoicesRevenue: {
                                __typename: value$4.__typename,
                                includingTaxes: value$4.includingTaxes,
                                excludingTaxes: value$4.excludingTaxes
                              },
                              globalRevenue: {
                                __typename: value$5.__typename,
                                includingTaxes: value$5.includingTaxes,
                                excludingTaxes: value$5.excludingTaxes
                              },
                              salesCount: value$1.salesCount,
                              salesIndex: value$1.salesIndex,
                              shoppingCartAverage: value$1.shoppingCartAverage,
                              totalAmountOfTaxes: value$1.totalAmountOfTaxes,
                              productsSoldCount: value$1.productsSoldCount,
                              totalPurchaseCost: value$1.totalPurchaseCost,
                              margin: value$1.margin,
                              marginRate: value$1.marginRate,
                              markupRate: value$1.markupRate
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.shopsKeyPerformanceIndicators;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.markupRate;
          var value$3 = value$1.marginRate;
          var value$4 = value$1.margin;
          var value$5 = value$1.totalPurchaseCost;
          var value$6 = value$1.productsSoldCount;
          var value$7 = value$1.totalAmountOfTaxes;
          var value$8 = value$1.shoppingCartAverage;
          var value$9 = value$1.salesIndex;
          var value$10 = value$1.salesCount;
          var value$11 = value$1.globalRevenue;
          var value$12 = value$11.excludingTaxes;
          var value$13 = value$11.includingTaxes;
          var value$14 = value$11.__typename;
          var globalRevenue = {
            __typename: value$14,
            includingTaxes: value$13,
            excludingTaxes: value$12
          };
          var value$15 = value$1.invoicesRevenue;
          var value$16 = value$15.excludingTaxes;
          var value$17 = value$15.includingTaxes;
          var value$18 = value$15.__typename;
          var invoicesRevenue = {
            __typename: value$18,
            includingTaxes: value$17,
            excludingTaxes: value$16
          };
          var value$19 = value$1.ticketsRevenue;
          var value$20 = value$19.excludingTaxes;
          var value$21 = value$19.includingTaxes;
          var value$22 = value$19.__typename;
          var ticketsRevenue = {
            __typename: value$22,
            includingTaxes: value$21,
            excludingTaxes: value$20
          };
          var value$23 = value$1.shop;
          var value$24 = value$23.name;
          var value$25 = value$23.id;
          var value$26 = value$23.__typename;
          var shop = {
            __typename: value$26,
            id: value$25,
            name: value$24
          };
          var value$27 = value$1.__typename;
          var node = {
            __typename: value$27,
            shop: shop,
            ticketsRevenue: ticketsRevenue,
            invoicesRevenue: invoicesRevenue,
            globalRevenue: globalRevenue,
            salesCount: value$10,
            salesIndex: value$9,
            shoppingCartAverage: value$8,
            totalAmountOfTaxes: value$7,
            productsSoldCount: value$6,
            totalPurchaseCost: value$5,
            margin: value$4,
            marginRate: value$3,
            markupRate: value$2
          };
          var value$28 = value.__typename;
          return {
                  __typename: value$28,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var shopsKeyPerformanceIndicators = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          shopsKeyPerformanceIndicators: shopsKeyPerformanceIndicators
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.startDate;
  var a$2 = inp.endDate;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          startDate: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          endDate: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectShopKeyPerformanceIndicatorsQueryFilter(shopIds, startDate, endDate, param) {
  return {
          shopIds: shopIds,
          startDate: startDate,
          endDate: endDate
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter: serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectShopKeyPerformanceIndicatorsQueryFilter: makeInputObjectShopKeyPerformanceIndicatorsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter: serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectShopKeyPerformanceIndicatorsQueryFilter: makeInputObjectShopKeyPerformanceIndicatorsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function encoder(param) {
  return [
          Belt_Option.map(param.shop, (function (shop) {
                  return shop.id;
                })),
          Belt_Option.map(param.dateRange, (function (param) {
                  return [
                          param[0].valueOf(),
                          param[1].valueOf()
                        ];
                }))
        ];
}

function decoder(shops, param) {
  var shopId = param[0];
  return {
          TAG: /* Ok */0,
          _0: {
            shop: Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === shopId;
                  })),
            dateRange: Belt_Option.flatMap(param[1], (function (range) {
                    if (range.length !== 2) {
                      return ;
                    }
                    var start = range[0];
                    var end = range[1];
                    return [
                            new Date(start),
                            new Date(end)
                          ];
                  }))
          }
        };
}

function useJsonCodec(param) {
  var shops = Auth$Pos.useShops(undefined);
  return JsonCodec$Pos.object2(encoder, (function (param) {
                return decoder(shops, param);
              }), JsonCodec$Pos.optional(JsonCodec$Pos.field("shopId", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("dateRange", JsonCodec$Pos.array(JsonCodec$Pos.$$float))));
}

var Filters = {
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function makeQueryVariables(defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeQueryVariablesFilterBy(param) {
  var dateRange = param.dateRange;
  var shop = param.shop;
  return {
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined,
          startDate: Belt_Option.map(dateRange, (function (param) {
                  return Scalar$Pos.Datetime.serialize(param[0]);
                })),
          endDate: Belt_Option.map(dateRange, (function (param) {
                  return Scalar$Pos.Datetime.serialize(param[1]);
                }))
        };
}

function totalCountFromQueryData(param) {
  return param.shopsKeyPerformanceIndicators.totalCount;
}

function cursorsFromQueryData(param) {
  var shopsKeyPerformanceIndicators = param.shopsKeyPerformanceIndicators;
  return [
          shopsKeyPerformanceIndicators.pageInfo.startCursor,
          shopsKeyPerformanceIndicators.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.map(param.shopsKeyPerformanceIndicators.edges, (function (param) {
                var node = param.node;
                return {
                        shopId: node.shop.id,
                        shopName: node.shop.name,
                        ticketsRevenueIncludingTaxes: node.ticketsRevenue.includingTaxes,
                        ticketsRevenueExcludingTaxes: node.ticketsRevenue.excludingTaxes,
                        invoicesRevenueIncludingTaxes: node.invoicesRevenue.includingTaxes,
                        invoicesRevenueExcludingTaxes: node.invoicesRevenue.excludingTaxes,
                        globalRevenueIncludingTaxes: node.globalRevenue.includingTaxes,
                        globalRevenueExcludingTaxes: node.globalRevenue.excludingTaxes,
                        salesCount: node.salesCount,
                        margin: node.margin,
                        marginRate: node.marginRate,
                        markupRate: node.markupRate,
                        salesIndex: node.salesIndex,
                        shoppingCartAverage: node.shoppingCartAverage,
                        productsSoldCount: node.productsSoldCount,
                        totalAmountOfTaxes: node.totalAmountOfTaxes,
                        totalPurchaseCost: node.totalPurchaseCost,
                        currency: "EUR"
                      };
              }));
}

function keyExtractor(param) {
  return param.shopId;
}

var Scaffolded = Scaffold$Pos.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

var columns = [
  {
    name: Intl$Pos.t("Shop"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 160
      },
      width: {
        NAME: "pct",
        VAL: 20
      },
      margin: "small",
      sticky: true
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.shopName
                  });
      })
  },
  {
    name: Intl$Pos.t("Revenue excl. VAT"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      width: {
        NAME: "fr",
        VAL: 2
      },
      sticky: true
    },
    render: (function (param) {
        return React.createElement(AmountTableCell$Pos.make, {
                    value: param.globalRevenueExcludingTaxes,
                    secondaryValue: param.globalRevenueIncludingTaxes
                  });
      })
  },
  {
    name: Intl$Pos.t("Margin"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      width: {
        NAME: "fr",
        VAL: 1.5
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.currencyFormat(param.currency, undefined, undefined, param.margin),
                    weight: "semibold"
                  });
      })
  },
  {
    name: Intl$Pos.t("Margin/Markup rt"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 100
      },
      width: {
        NAME: "fr",
        VAL: 2.5
      }
    },
    render: (function (param) {
        var formattedMarginRate = Intl$Pos.percentFormat(2, 2, param.marginRate / 100);
        var formattedMarkupRate = Intl$Pos.percentFormat(2, 2, param.markupRate / 100);
        return React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "xxsmall"
                  }, React.createElement(TextStyle$Pos.make, {
                        children: formattedMarginRate
                      }), React.createElement(TextStyle$Pos.make, {
                        children: formattedMarkupRate,
                        variation: "normal",
                        size: "tiny"
                      }));
      })
  },
  {
    name: Intl$Pos.t("Cost of sales"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      width: {
        NAME: "fr",
        VAL: 2
      },
      margin: "small",
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.currencyFormat(param.currency, undefined, undefined, param.totalPurchaseCost)
                  });
      })
  },
  {
    name: Intl$Pos.t("Avg. basket"),
    layout: {
      margin: "small",
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.currencyFormat(param.currency, undefined, undefined, param.shoppingCartAverage)
                  });
      })
  },
  {
    name: Intl$Pos.t("Sales"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "px",
        VAL: 120
      },
      alignX: "center"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.salesCount
                  });
      })
  },
  {
    name: Intl$Pos.t("Sales index"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "px",
        VAL: 130
      },
      alignX: "center"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.decimalFormat(undefined, undefined, param.salesIndex)
                  });
      })
  },
  {
    name: Intl$Pos.t("Products sold"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "px",
        VAL: 130
      },
      alignX: "center"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.productsSoldCount
                  });
      })
  }
];

function AnalyticsShopsPage(Props) {
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var defaultQueryVariables = {
    filterBy: undefined
  };
  var initialState = Curry._1(Scaffolded.makeInitialState, {
        shop: activeShop,
        dateRange: SelectDateRange$Pos.todayDateRange
      });
  var match = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match[1];
  var state = match[0];
  var tmp = {
    onChange: (function (shop) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          shop: shop,
                          dateRange: prev.dateRange
                        };
                })
            });
      })
  };
  if (state.filters.shop !== undefined) {
    tmp.value = Caml_option.valFromOption(state.filters.shop);
  }
  var tmp$1 = {
    placeholder: Intl$Pos.t("Select a period"),
    disabledResetButton: true,
    onChange: (function (dateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          shop: prev.shop,
                          dateRange: dateRange
                        };
                })
            });
      })
  };
  if (state.filters.dateRange !== undefined) {
    tmp$1.value = Caml_option.valFromOption(state.filters.dateRange);
  }
  var filters = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, React.createElement(Auth$Pos.SelectShop.make, tmp), React.createElement(Separator$Pos.make, {}), React.createElement(SelectDateRange$Pos.make, tmp$1));
  return React.createElement(Scaffolded.make, {
              title: Intl$Pos.t("Performances per shop"),
              state: state,
              dispatch: dispatch,
              columns: columns,
              filters: filters,
              emptyState: EmptyState$Pos.error,
              defaultQueryVariables: defaultQueryVariables
            });
}

var make = React.memo(AnalyticsShopsPage);

export {
  Query ,
  Filters ,
  Row ,
  Scaffolded ,
  columns ,
  make ,
}
/* query Not a pure module */
