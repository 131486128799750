// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Accordion$Pos from "../../resources/layout-and-structure/Accordion.bs.js";
import * as InfoBlock$Pos from "../../resources/layout-and-structure/InfoBlock.bs.js";
import * as IpAddress$Pos from "../../primitives/IpAddress.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as TextAction$Pos from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as CountryCode$Pos from "../../primitives/CountryCode.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as TooltipIcon$Pos from "../../resources/overlays/TooltipIcon.bs.js";
import * as AuthSignupPage$Pos from "../Auth/AuthSignupPage.bs.js";
import * as SettingsRoutes$Pos from "./SettingsRoutes.bs.js";
import * as CorporateEntity$Pos from "../../core/CorporateEntity.bs.js";
import * as CustomerBillingAccount$Pos from "../../core/CustomerBillingAccount.bs.js";
import * as CustomerBillingAccountInvoices$Pos from "../../modules/CustomerBillingAccount/CustomerBillingAccountInvoices.bs.js";

function decodeResult(json) {
  var billingAccountDict = Json$Pos.decodeDict(json);
  var shippingAddressDict = Json$Pos.flatDecodeDictFieldDict(Json$Pos.flatDecodeDictFieldDict(billingAccountDict, "shipping"), "address");
  var billingAddressDict = Json$Pos.flatDecodeDictFieldDict(billingAccountDict, "address");
  var paymentMethodDict = Json$Pos.flatDecodeDictFieldDict(billingAccountDict, "defaultPaymentMethod");
  var ibanLast4 = Json$Pos.flatDecodeDictFieldString(Json$Pos.flatDecodeDictFieldDict(paymentMethodDict, "iban"), "last4");
  var decodeAddress = function (json) {
    var match = Json$Pos.flatDecodeDictFieldString(json, "address");
    var match$1 = Json$Pos.flatDecodeDictFieldString(json, "postalCode");
    var match$2 = Json$Pos.flatDecodeDictFieldString(json, "city");
    var match$3 = Json$Pos.flatDecodeDictFieldString(json, "country");
    if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined) {
      return {
              address: match,
              postalCode: match$1,
              city: match$2,
              country: match$3
            };
    }
    
  };
  var corporateName = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "corporateName");
  var shopName = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "shopName");
  var email = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "email");
  var phone = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "phone");
  var billingAddress = decodeAddress(billingAddressDict);
  var shippingAddress = decodeAddress(shippingAddressDict);
  var vatNumber = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "vatNumber");
  return {
          corporateName: corporateName,
          shopName: shopName,
          email: email,
          phone: phone,
          billingAddress: billingAddress,
          shippingAddress: shippingAddress,
          vatNumber: vatNumber,
          iban: ibanLast4 !== undefined ? ({
                last4: ibanLast4
              }) : undefined
        };
}

var endpoint = Env$Pos.gatewayUrl(undefined) + "/customer-billing-account/";

function make(shopId) {
  return Future.mapOk(Request$Pos.makeAndDecode("GET", undefined, undefined, undefined, endpoint + shopId), undefined, decodeResult);
}

var CustomerBillingAccountRequest = {
  decodeResult: decodeResult,
  endpoint: endpoint,
  make: make
};

function decodeResult$1(json) {
  var match = Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "status");
  var match$1 = Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "acceptedAt", Json$Pos.decodeNumber);
  if (match === undefined) {
    return ;
  }
  if (match$1 === undefined) {
    return ;
  }
  switch (match) {
    case "active" :
        return {
                status: /* Active */0,
                acceptedAt: match$1
              };
    case "inactive" :
        return {
                status: /* Inactive */1,
                acceptedAt: match$1
              };
    case "pending" :
        return {
                status: /* Pending */2,
                acceptedAt: match$1
              };
    default:
      return ;
  }
}

var endpoint$1 = Env$Pos.gatewayUrl(undefined) + "/sepa-mandate/";

function make$1(shopId) {
  return Future.mapOk(Request$Pos.makeAndDecode("GET", undefined, undefined, undefined, endpoint$1 + shopId), undefined, decodeResult$1);
}

var CustomerBillingAccountPaymentMethodRequest = {
  decodeResult: decodeResult$1,
  endpoint: endpoint$1,
  make: make$1
};

function decodeCustomerPlans(json) {
  var dict = Json$Pos.decodeDict(json);
  var match = Json$Pos.flatDecodeDictFieldString(dict, "kind");
  var match$1 = Json$Pos.flatDecodeDictField(dict, "upcomingInvoiceDate", Json$Pos.decodeNumber);
  var match$2 = Json$Pos.flatDecodeDictField(dict, "quantity", Json$Pos.decodeNumber);
  if (match === undefined) {
    return ;
  }
  if (match$2 === undefined) {
    return ;
  }
  var upcomingInvoiceDate = match$1 !== undefined ? Caml_option.some(new Date(match$1)) : undefined;
  var match$3 = CustomerBillingAccount$Pos.CustomerBillingPlanKind.fromString(match);
  if (match$3.TAG === /* Ok */0) {
    return {
            kind: match$3._0,
            upcomingInvoiceDate: upcomingInvoiceDate,
            quantity: match$2 | 0
          };
  }
  
}

function decodeCustomerOptions(json) {
  var dict = Json$Pos.decodeDict(json);
  var match = Json$Pos.flatDecodeDictFieldString(dict, "name");
  var match$1 = Json$Pos.flatDecodeDictField(dict, "upcomingInvoiceDate", Json$Pos.decodeNumber);
  var match$2 = Json$Pos.flatDecodeDictField(dict, "quantity", Json$Pos.decodeNumber);
  if (match === undefined) {
    return ;
  }
  if (match$2 === undefined) {
    return ;
  }
  var upcomingInvoiceDate = match$1 !== undefined ? Caml_option.some(new Date(match$1)) : undefined;
  return {
          name: match,
          upcomingInvoiceDate: upcomingInvoiceDate,
          quantity: match$2 | 0
        };
}

function decodeResult$2(json) {
  var match = Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "plans");
  var match$1 = Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "options");
  if (match !== undefined && match$1 !== undefined) {
    return {
            plans: Belt_Array.keepMap(match, decodeCustomerPlans),
            options: Belt_Array.keepMap(match$1, decodeCustomerOptions)
          };
  }
  
}

var endpoint$2 = Env$Pos.gatewayUrl(undefined) + "/customer-subscriptions/";

function make$2(shopId) {
  return Future.mapOk(Request$Pos.makeAndDecode("GET", undefined, undefined, undefined, endpoint$2 + shopId), undefined, decodeResult$2);
}

var CustomerBillingAccountSubscriptionsRequest = {
  decodeCustomerPlans: decodeCustomerPlans,
  decodeCustomerOptions: decodeCustomerOptions,
  decodeResult: decodeResult$2,
  endpoint: endpoint$2,
  make: make$2
};

function SettingsCustomerBillingAccountShowPage$CustomerBillingAccountSubscriptionsCard(Props) {
  var subscriptions = Props.subscriptions;
  var tmp;
  var exit = 0;
  if (subscriptions !== undefined && subscriptions.plans.length !== 0) {
    var tmp$1;
    if (subscriptions.options.length !== 0) {
      var triggerToggleView = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("Options"),
                        weight: "strong"
                      })), undefined, "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
      tmp$1 = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Accordion$Pos.make, {
                        children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Columns$Pos.make, {
                                          children: React.createElement(Column$Pos.make, {
                                                children: Belt_Array.map(subscriptions.options, (function (option) {
                                                        return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                                                children: null,
                                                                                opacity: 0.6
                                                                              }, Intl$Pos.t(option.name), option.quantity > 1 ? " (x" + String(option.quantity) + ")" : null)), undefined, undefined, "none", undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined));
                                                      })),
                                                width: "content"
                                              }),
                                          space: "medium"
                                        })), undefined, undefined, undefined, "small", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined)),
                        triggerShowView: triggerToggleView,
                        triggerHideView: triggerToggleView
                      })), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, undefined, "xsmall", "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined), Belt_Array.map(subscriptions.plans, (function (plan) {
                var upcomingInvoiceDate = plan.upcomingInvoiceDate;
                return React.createElement(Inline$Pos.make, {
                            children: null
                          }, React.createElement(TextStyle$Pos.make, {
                                children: Intl$Pos.t("Subscription") + " ",
                                variation: "primary",
                                weight: "medium"
                              }), React.createElement(TextStyle$Pos.make, {
                                children: null,
                                variation: "primary",
                                weight: "strong"
                              }, Intl$Pos.t(CustomerBillingAccount$Pos.CustomerBillingPlanKind.toString(plan.kind)), plan.quantity > 1 ? " (x" + String(plan.quantity) + ")" : null), upcomingInvoiceDate !== undefined ? React.createElement(TooltipIcon$Pos.make, {
                                  children: Intl$Pos.t("Next charge on") + " " + Intl$Pos.dateTimeFormat(undefined, undefined, Caml_option.valFromOption(upcomingInvoiceDate)) + ".",
                                  variation: "info",
                                  placement: "top start",
                                  crossOffset: 0
                                }) : null);
              })), tmp$1);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Banner$Pos.make, {
          textStatus: {
            TAG: /* Warning */2,
            _0: Intl$Pos.t("Unable to display information about your subscription.")
          }
        });
  }
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "medium"
                  }, tmp, React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("Would you like to change your subscription?") + " "
                      }), React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Contact us"), "medium", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              HelpCenter$Pos.showMessages(undefined);
                            }), undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextAction$Pos.make, {
                                    text: Intl$Pos.t("Review the terms and conditions of sale."),
                                    highlighted: true,
                                    onPress: (function (param) {
                                        HelpCenter$Pos.showArticle(HelpCenter$Pos.termsAndConditions);
                                      })
                                  })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))),
              title: Intl$Pos.t("Subscription")
            });
}

var CustomerBillingAccountSubscriptionsCard = {
  make: SettingsCustomerBillingAccountShowPage$CustomerBillingAccountSubscriptionsCard
};

function encodeBody(shopId, ibanNumber, ipAddress, userAgent, acceptedAt) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "shopId",
                    Json$Pos.encodeString(shopId)
                  ],
                  [
                    "iban",
                    Json$Pos.encodeString(ibanNumber)
                  ],
                  [
                    "ipAddress",
                    Json$Pos.encodeString(ipAddress)
                  ],
                  [
                    "userAgent",
                    Json$Pos.encodeString(userAgent)
                  ],
                  [
                    "acceptedAt",
                    Json$Pos.encodeNumber(acceptedAt)
                  ]
                ]));
}

var endpoint$3 = Env$Pos.gatewayUrl(undefined) + "/default-payment-method";

function decodeServerFailure(error) {
  switch (error.kind) {
    case "InvalidIban" :
        return /* InvalidIban */1;
    case "WrongSepaMandateAcceptanceDetails" :
        return /* WrongSepaMandateAcceptanceDetails */0;
    default:
      return /* Unknown */2;
  }
}

function make$3(shopId, ibanNumber, ipAddress, userAgent, acceptedAt) {
  return Future.mapError(Future.mapOk(Request$Pos.makeAndDecode("PATCH", Caml_option.some(encodeBody(shopId, ibanNumber, ipAddress, userAgent, acceptedAt)), undefined, undefined, endpoint$3), undefined, (function (param) {
                    
                  })), undefined, (function (error) {
                if (typeof error === "number") {
                  return /* Unknown */2;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeServerFailure);
                }
              }));
}

var CustomerBillingAccountUpdatePaymentMethodRequest = {
  encodeBody: encodeBody,
  endpoint: endpoint$3,
  decodeServerFailure: decodeServerFailure,
  make: make$3
};

function get(values, field) {
  return values.ibanNumber;
}

function set(values, field, value) {
  return {
          ibanNumber: value
        };
}

var CustomerBillingAccountEditPaymentMethodFormLenses = {
  get: get,
  set: set
};

var CustomerBillingAccountEditPaymentMethodForm = Form$Pos.Make(CustomerBillingAccountEditPaymentMethodFormLenses);

var schema = [{
    TAG: /* CustomString */8,
    _0: /* IbanNumber */0,
    _1: (function (value, param) {
        if (CorporateEntity$Pos.Iban.validate(value)) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("The IBAN number is not valid.")
                };
        }
      })
  }];

function SettingsCustomerBillingAccountShowPage$CustomerBillingAccountEditPaymentMethodFormModal(Props) {
  var opened = Props.opened;
  var activeShopId = Props.activeShopId;
  var updatePaymentMethodRequest = Props.updatePaymentMethodRequest;
  var onRequestClose = Props.onRequestClose;
  var onSuccessNotification = Props.onSuccessNotification;
  var match = React.useState(function () {
        
      });
  var setIpAddress = match[1];
  var ipAddress = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var error = match$1[0];
  React.useEffect((function () {
          Future.get(IpAddress$Pos.request(undefined), (function (value) {
                  if (value.TAG !== /* Ok */0) {
                    return ;
                  }
                  var value$1 = value._0;
                  Curry._1(setIpAddress, (function (param) {
                          return value$1;
                        }));
                }));
        }), []);
  var onSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry._5(updatePaymentMethodRequest, activeShopId, param$1.ibanNumber, Belt_Option.getWithDefault(ipAddress, ""), AuthSignupPage$Pos.GetNavigatorUserAgent.make(undefined), Date.now()), undefined, (function (param) {
                      return Intl$Pos.t("Your email address has been updated successfully.");
                    })), undefined, (function (failure) {
                  if (failure === undefined) {
                    return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                  }
                  switch (failure) {
                    case /* WrongSepaMandateAcceptanceDetails */0 :
                        return Intl$Pos.t("Your internet connection has been interrupted, please reload the page before submitting the form again.");
                    case /* InvalidIban */1 :
                        return Intl$Pos.t("The IBAN number is not valid.");
                    case /* Unknown */2 :
                        return Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                    
                  }
                }));
  };
  var onSubmitSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
    Curry._1(onSuccessNotification, {
          TAG: /* Success */0,
          _0: Intl$Pos.t("Changes made successfully.")
        });
  };
  var onSubmitFailure = function (message) {
    Curry._1(setError, (function (param) {
            return message;
          }));
  };
  var formPropState = Curry._1(CustomerBillingAccountEditPaymentMethodForm.useFormPropState, {
        initialValues: {
          ibanNumber: ""
        },
        schema: schema,
        resetValuesAfterSubmission: true,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  return React.createElement(Modal$Pos.make, {
              children: React.createElement(CustomerBillingAccountEditPaymentMethodForm.Core.Provider.make, {
                    children: null,
                    propState: formPropState
                  }, error !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                      textStatus: {
                                        TAG: /* Danger */1,
                                        _0: error
                                      },
                                      onRequestClose: (function (param) {
                                          Curry._1(setError, (function (param) {
                                                  
                                                }));
                                        })
                                    })), "xlarge", undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "xlarge", undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                        children: Intl$Pos.t("When confirming a new payment method, your current payment method will be removed."),
                                        opacity: 0.5
                                      })), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "small"
                          }, React.createElement(CustomerBillingAccountEditPaymentMethodForm.InputText.make, {
                                field: /* IbanNumber */0,
                                label: Intl$Pos.t("IBAN number"),
                                placeholder: Intl$Pos.t("FR12 1234 5678 1000 0000 0000 123")
                              }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                            textStatus: {
                                              TAG: /* Info */3,
                                              _0: Intl$Pos.t("By providing your IBAN and clicking 'Confirm' from this window, you authorize Wino Technologies and Stripe, our payment service provider, to send instructions to your bank to debit your account, and your bank to debit your account in accordance with these instructions. You are entitled to a refund from your bank according to the terms and conditions of the agreement you have with them. The refund must be requested within 8 weeks from the date your account was debited.")
                                            }
                                          })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                        children: null,
                                        space: "xmedium",
                                        align: "end"
                                      }, React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Cancel"), "large", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                  Curry._1(onRequestClose, undefined);
                                                }), undefined, undefined, undefined)), React.createElement(CustomerBillingAccountEditPaymentMethodForm.SubmitButton.make, {
                                            variation: "success",
                                            onSubmit: onSubmit,
                                            text: Intl$Pos.t("Confirm")
                                          }))), undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))),
              opened: opened,
              title: Intl$Pos.t("Change in the Payment Method"),
              hideFooter: true,
              onRequestClose: onRequestClose
            });
}

var CustomerBillingAccountEditPaymentMethodFormModal = {
  schema: schema,
  make: SettingsCustomerBillingAccountShowPage$CustomerBillingAccountEditPaymentMethodFormModal
};

function SettingsCustomerBillingAccountShowPage$CustomerBillingAccountPaymentMethodCard(Props) {
  var customerBillingAccount = Props.customerBillingAccount;
  var activeShopId = Props.activeShopId;
  var mandate = Props.mandate;
  var customerBillingAccountPaymentMethodUpdateRequest = Props.customerBillingAccountPaymentMethodUpdateRequest;
  var match = React.useState(function () {
        return false;
      });
  var setEditIbanModalOpened = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setNotification = match$1[1];
  var notification = match$1[0];
  var action_title = Intl$Pos.t("Change payment method");
  var action_handler = {
    TAG: /* Callback */0,
    _0: (function (param) {
        Curry._1(setEditIbanModalOpened, (function (param) {
                return true;
              }));
      })
  };
  var action = {
    icon: "edit_light",
    title: action_title,
    handler: action_handler
  };
  var tmp;
  if (customerBillingAccount !== undefined) {
    var match$2 = customerBillingAccount.iban;
    var tmp$1;
    if (mandate !== undefined && mandate.status === 0) {
      var acceptedAt = mandate.acceptedAt;
      tmp$1 = acceptedAt !== undefined ? React.createElement(TextStyle$Pos.make, {
              children: Intl$Pos.t("Accepted mandate on") + " " + Intl$Pos.dateTimeFormat(undefined, "short", new Date(acceptedAt))
            }) : null;
    } else {
      tmp$1 = null;
    }
    var tmp$2;
    var exit = 0;
    if (mandate !== undefined) {
      switch (mandate.status) {
        case /* Active */0 :
            tmp$2 = null;
            break;
        case /* Inactive */1 :
            exit = 1;
            break;
        case /* Pending */2 :
            tmp$2 = React.createElement(Banner$Pos.make, {
                  textStatus: {
                    TAG: /* Warning */2,
                    _0: Intl$Pos.t("Your payment mandate is currently being validated.")
                  }
                });
            break;
        
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$2 = React.createElement(Banner$Pos.make, {
            textStatus: {
              TAG: /* Warning */2,
              _0: Intl$Pos.t("Attention, no valid payment method is registered.")
            }
          });
    }
    tmp = React.createElement(Stack$Pos.make, {
          children: null
        }, match$2 !== undefined ? React.createElement(TextStyle$Pos.make, {
                children: Intl$Pos.t("SEPA direct debit") + " • • • • " + match$2.last4
              }) : null, tmp$1, tmp$2, notification !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                            textStatus: notification,
                            onRequestClose: (function (param) {
                                Curry._1(setNotification, (function (param) {
                                        
                                      }));
                              })
                          })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null);
  } else {
    tmp = React.createElement(Banner$Pos.make, {
          textStatus: {
            TAG: /* Warning */2,
            _0: Intl$Pos.t("Unable to display information regarding your payment method.")
          }
        });
  }
  var tmp$3 = {
    children: tmp,
    title: Intl$Pos.t("Payment method")
  };
  var tmp$4 = Belt_Option.isSome(customerBillingAccount) ? action : undefined;
  if (tmp$4 !== undefined) {
    tmp$3.action = Caml_option.valFromOption(tmp$4);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Card$Pos.make, tmp$3), React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountEditPaymentMethodFormModal, {
                  opened: match[0],
                  activeShopId: activeShopId,
                  updatePaymentMethodRequest: customerBillingAccountPaymentMethodUpdateRequest,
                  onRequestClose: (function (param) {
                      Curry._1(setEditIbanModalOpened, (function (param) {
                              return false;
                            }));
                    }),
                  onSuccessNotification: (function (value) {
                      Curry._1(setNotification, (function (param) {
                              return value;
                            }));
                    })
                }));
}

var CustomerBillingAccountPaymentMethodCard = {
  make: SettingsCustomerBillingAccountShowPage$CustomerBillingAccountPaymentMethodCard
};

function SettingsCustomerBillingAccountShowPage$CustomerBillingAccountAddressBox(Props) {
  var value = Props.value;
  var title = Props.title;
  var tmp;
  if (value !== undefined) {
    var countrycode = CountryCode$Pos.fromString(value.country);
    var country;
    country = countrycode.TAG === /* Ok */0 ? React.createElement(TextStyle$Pos.make, {
            children: Intl$Pos.t(CountryCode$Pos.toMediumCountryString(countrycode._0))
          }) : React.createElement(TextStyle$Pos.make, {
            children: Intl$Pos.t("Country not specified"),
            variation: "subdued"
          });
    tmp = React.createElement(React.Fragment, undefined, React.createElement(TextStyle$Pos.make, {
              children: value.address
            }), React.createElement(TextStyle$Pos.make, {
              children: value.postalCode + " " + value.city
            }), country);
  } else {
    tmp = React.createElement(TextStyle$Pos.make, {
          children: Intl$Pos.t("Address not provided"),
          variation: "subdued"
        });
  }
  return React.createElement(InfoBlock$Pos.make, {
              children: tmp,
              title: title
            });
}

var CustomerBillingAccountAddressBox = {
  make: SettingsCustomerBillingAccountShowPage$CustomerBillingAccountAddressBox
};

function SettingsCustomerBillingAccountShowPage$CustomerBillingAccountBillingInformationCard(Props) {
  var activeShopId = Props.activeShopId;
  var customerBillingAccount = Props.customerBillingAccount;
  if (customerBillingAccount === undefined) {
    return React.createElement(Card$Pos.make, {
                children: React.createElement(Banner$Pos.make, {
                      textStatus: {
                        TAG: /* Warning */2,
                        _0: Intl$Pos.t("Unable to display information regarding your billing.")
                      }
                    }),
                title: Intl$Pos.t("Billing information")
              });
  }
  var vatNumber = customerBillingAccount.vatNumber;
  var shippingAddress = customerBillingAccount.shippingAddress;
  var billingAddress = customerBillingAccount.billingAddress;
  var phone = customerBillingAccount.phone;
  var email = customerBillingAccount.email;
  var shopName = customerBillingAccount.shopName;
  var corporateName = customerBillingAccount.corporateName;
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "large"
                  }, React.createElement(Stack$Pos.make, {
                        children: null
                      }, corporateName !== undefined ? React.createElement(TextStyle$Pos.make, {
                              children: corporateName
                            }) : React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Corporate name not provided"),
                              variation: "subdued"
                            }), shopName !== undefined ? React.createElement(TextStyle$Pos.make, {
                              children: shopName
                            }) : null, email !== undefined ? React.createElement(TextStyle$Pos.make, {
                              children: email
                            }) : React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Email not provided"),
                              variation: "subdued"
                            }), phone !== undefined ? React.createElement(TextStyle$Pos.make, {
                              children: phone
                            }) : React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Phone number not provided"),
                              variation: "subdued"
                            })), vatNumber !== undefined ? React.createElement(TextStyle$Pos.make, {
                          children: vatNumber
                        }) : React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("VAT number not provided"),
                          variation: "subdued"
                        }), React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountAddressBox, {
                        value: billingAddress,
                        title: Intl$Pos.t("Billing address")
                      }), React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountAddressBox, {
                        value: shippingAddress,
                        title: Intl$Pos.t("Shipping address")
                      }), Belt_Array.some([
                        corporateName,
                        email,
                        vatNumber,
                        phone
                      ], Belt_Option.isNone) || Belt_Array.some([
                        billingAddress,
                        shippingAddress
                      ], Belt_Option.isNone) ? React.createElement(Banner$Pos.make, {
                          textStatus: {
                            TAG: /* Warning */2,
                            _0: Intl$Pos.t("Attention, important information needs to be completed.")
                          }
                        }) : null),
              title: Intl$Pos.t("Billing information"),
              action: {
                icon: "edit_light",
                title: Intl$Pos.t("Edit billing information"),
                handler: {
                  TAG: /* OpenLink */1,
                  _0: {
                    TAG: /* RouteWithQueryString */1,
                    _0: SettingsRoutes$Pos.customerBillingAccountEditRoute,
                    _1: SettingsRoutes$Pos.encodeEditCustomerBillingAccountQueryString(activeShopId, Belt_Option.getWithDefault(corporateName, ""), Belt_Option.getWithDefault(shopName, ""), Belt_Option.getWithDefault(email, ""), Belt_Option.getWithDefault(phone, ""), billingAddress, shippingAddress, vatNumber)
                  }
                }
              }
            });
}

var CustomerBillingAccountBillingInformationCard = {
  make: SettingsCustomerBillingAccountShowPage$CustomerBillingAccountBillingInformationCard
};

function SettingsCustomerBillingAccountShowPage$CustomerBillingAccountWarningNotificationBanner(Props) {
  var state = Props.state;
  var match = state.customerBillingAccount;
  var exit = 0;
  if (!(match !== undefined && !(match.iban !== undefined && match.vatNumber !== undefined && match.shippingAddress !== undefined && match.billingAddress !== undefined && match.phone !== undefined && match.email !== undefined && match.corporateName !== undefined))) {
    exit = 2;
  }
  if (exit === 2) {
    var match$1 = state.mandate;
    if (match$1 !== undefined && match$1.status !== 1) {
      return null;
    }
    
  }
  return React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Warning */2,
                _0: Intl$Pos.t("Billing information is missing or incorrect, please rectify the situation as soon as possible to continue using the solution.")
              }
            });
}

var CustomerBillingAccountWarningNotificationBanner = {
  make: SettingsCustomerBillingAccountShowPage$CustomerBillingAccountWarningNotificationBanner
};

function SettingsCustomerBillingAccountShowPage$CustomerBillingAccountErrorBanner(Props) {
  return React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Warning */2,
                _0: Intl$Pos.t(Intl$Pos.t("Some subscription and billing settings cannot be displayed at the moment."))
              }
            });
}

var CustomerBillingAccountErrorBanner = {
  make: SettingsCustomerBillingAccountShowPage$CustomerBillingAccountErrorBanner
};

function SettingsCustomerBillingAccountShowPage$CustomerBillingAccountBillingInvoicesCard(Props) {
  var customerBillingAccountInvoicesRequest = Props.customerBillingAccountInvoicesRequest;
  return React.createElement(Card$Pos.make, {
              children: React.createElement(CustomerBillingAccountInvoices$Pos.CustomerBillingAccountInvoicesTable.make, {
                    customerBillingAccountInvoicesRequest: customerBillingAccountInvoicesRequest,
                    preview: true
                  }),
              title: Intl$Pos.t("Invoice history"),
              variation: "table"
            });
}

var CustomerBillingAccountBillingInvoicesCard = {
  make: SettingsCustomerBillingAccountShowPage$CustomerBillingAccountBillingInvoicesCard
};

function SettingsCustomerBillingAccountShowPage(Props) {
  var customerBillingAccountRequest = Props.customerBillingAccountRequest;
  var customerBillingAccountPaymentMethodRequest = Props.customerBillingAccountPaymentMethodRequest;
  var customerBillingAccountPaymentMethodUpdateRequest = Props.customerBillingAccountPaymentMethodUpdateRequest;
  var customerBillingAccountInvoicesRequest = Props.customerBillingAccountInvoicesRequest;
  var customerBillingAccountSubscriptionsRequest = Props.customerBillingAccountSubscriptionsRequest;
  var scope = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = scope.TAG === /* Organisation */0 ? true : false;
  var activeShop;
  if (scope.TAG === /* Organisation */0) {
    var shop = scope.activeShop;
    activeShop = shop !== undefined ? shop : scope.shops[0];
  } else {
    activeShop = scope._0;
  }
  var match = React.useState(function () {
        return AsyncResult$Pos.notAsked(undefined);
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(setState, (function (param) {
                  return /* Loading */1;
                }));
          var future = Future.all3([
                Curry._1(customerBillingAccountRequest, activeShop.id),
                Curry._1(customerBillingAccountPaymentMethodRequest, activeShop.id),
                Curry._1(customerBillingAccountSubscriptionsRequest, activeShop.id)
              ]);
          Future.get(future, (function (results) {
                  var customerBillingAccount = results[0];
                  if (customerBillingAccount.TAG === /* Ok */0) {
                    var mandate = results[1];
                    var customerBillingAccount$1 = customerBillingAccount._0;
                    if (mandate.TAG === /* Ok */0) {
                      var subscriptions = results[2];
                      var mandate$1 = mandate._0;
                      if (subscriptions.TAG === /* Ok */0) {
                        var subscriptions$1 = subscriptions._0;
                        return Curry._1(setState, (function (param) {
                                      return {
                                              TAG: /* Done */1,
                                              _0: {
                                                TAG: /* Ok */0,
                                                _0: {
                                                  customerBillingAccount: customerBillingAccount$1,
                                                  mandate: mandate$1,
                                                  subscriptions: subscriptions$1
                                                }
                                              }
                                            };
                                    }));
                      }
                      
                    }
                    
                  }
                  Curry._1(setState, (function (param) {
                          return {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Error */1,
                                    _0: undefined
                                  }
                                };
                        }));
                }));
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [activeShop]);
  var renderHeaderActions = function (param) {
    if (organisationAccount) {
      return React.createElement(Inline$Pos.make, {
                  children: React.createElement(Auth$Pos.SelectSingleShop.make, {
                        value: activeShop,
                        variation: {
                          NAME: "filter",
                          VAL: {
                            hideLabelColon: false
                          }
                        }
                      })
                });
    } else {
      return null;
    }
  };
  var tmp;
  if (typeof state === "number" || state.TAG !== /* Done */1) {
    tmp = null;
  } else {
    var response = state._0;
    tmp = response.TAG === /* Ok */0 ? React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountWarningNotificationBanner, {
            state: response._0
          }) : React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountErrorBanner, {});
  }
  var tmp$1;
  var exit = 0;
  if (typeof state === "number" || state.TAG !== /* Done */1) {
    exit = 1;
  } else {
    var match$1 = state._0;
    if (match$1.TAG === /* Ok */0) {
      var match$2 = match$1._0;
      var customerBillingAccount = match$2.customerBillingAccount;
      tmp$1 = React.createElement(Columns$Pos.make, {
            children: null,
            space: "large"
          }, React.createElement(Column$Pos.make, {
                children: React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountSubscriptionsCard, {
                          subscriptions: match$2.subscriptions
                        }), React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountPaymentMethodCard, {
                          customerBillingAccount: customerBillingAccount,
                          activeShopId: activeShop.id,
                          mandate: match$2.mandate,
                          customerBillingAccountPaymentMethodUpdateRequest: customerBillingAccountPaymentMethodUpdateRequest
                        }), React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountBillingInformationCard, {
                          activeShopId: activeShop.id,
                          customerBillingAccount: customerBillingAccount
                        })),
                width: "one_third"
              }), React.createElement(Column$Pos.make, {
                children: React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountBillingInvoicesCard, {
                      customerBillingAccountInvoicesRequest: customerBillingAccountInvoicesRequest
                    }),
                width: "three_quarter"
              }));
    } else {
      tmp$1 = React.createElement(Columns$Pos.make, {
            children: null,
            space: "large"
          }, React.createElement(Column$Pos.make, {
                children: React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountSubscriptionsCard, {
                          subscriptions: undefined
                        }), React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountPaymentMethodCard, {
                          customerBillingAccount: undefined,
                          activeShopId: activeShop.id,
                          mandate: undefined,
                          customerBillingAccountPaymentMethodUpdateRequest: customerBillingAccountPaymentMethodUpdateRequest
                        }), React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountBillingInformationCard, {
                          activeShopId: activeShop.id,
                          customerBillingAccount: undefined
                        })),
                width: "one_third"
              }), React.createElement(Column$Pos.make, {
                children: React.createElement(SettingsCustomerBillingAccountShowPage$CustomerBillingAccountBillingInvoicesCard, {
                      customerBillingAccountInvoicesRequest: customerBillingAccountInvoicesRequest
                    }),
                width: "three_quarter"
              }));
    }
  }
  if (exit === 1) {
    tmp$1 = React.createElement(Placeholder$Pos.make, {
          status: /* Loading */0
        });
  }
  return React.createElement(Page$Pos.make, {
              children: null,
              variation: "compact",
              title: Intl$Pos.t("Subscription and billing"),
              renderHeaderActions: renderHeaderActions
            }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(tmp), undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), tmp$1);
}

var customerBillingAccountRequest = make;

var customerBillingAccountPaymentMethodRequest = make$1;

var customerBillingAccountPaymentMethodUpdateRequest = make$3;

var customerBillingAccountSubscriptionsRequest = make$2;

var make$4 = SettingsCustomerBillingAccountShowPage;

export {
  CustomerBillingAccountRequest ,
  CustomerBillingAccountPaymentMethodRequest ,
  CustomerBillingAccountSubscriptionsRequest ,
  CustomerBillingAccountSubscriptionsCard ,
  CustomerBillingAccountUpdatePaymentMethodRequest ,
  CustomerBillingAccountEditPaymentMethodFormLenses ,
  CustomerBillingAccountEditPaymentMethodForm ,
  CustomerBillingAccountEditPaymentMethodFormModal ,
  CustomerBillingAccountPaymentMethodCard ,
  CustomerBillingAccountAddressBox ,
  CustomerBillingAccountBillingInformationCard ,
  CustomerBillingAccountWarningNotificationBanner ,
  CustomerBillingAccountErrorBanner ,
  CustomerBillingAccountBillingInvoicesCard ,
  customerBillingAccountRequest ,
  customerBillingAccountPaymentMethodRequest ,
  customerBillingAccountPaymentMethodUpdateRequest ,
  customerBillingAccountSubscriptionsRequest ,
  make$4 as make,
}
/* endpoint Not a pure module */
