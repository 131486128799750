// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function fromUrl(filename, url) {
  return Future.make(function (resolve) {
              var body = document.querySelector("body");
              var tempLink = document.createElement("a");
              tempLink.setAttribute("style", "display: none;");
              tempLink.setAttribute("href", url.href);
              tempLink.setAttribute("download", Belt_Option.getWithDefault(filename, url.href));
              var match = tempLink.getAttribute("download");
              if (match == null) {
                tempLink.setAttribute("target", "_blank");
              }
              if (body == null) {
                Curry._1(resolve, {
                      TAG: /* Error */1,
                      _0: undefined
                    });
                return ;
              }
              if (tempLink !== undefined) {
                body.appendChild(tempLink);
                Caml_option.valFromOption(tempLink).click();
                var timeoutId = setTimeout((function (param) {
                        body.removeChild(tempLink);
                        try {
                          URL.revokeObjectURL(url.href);
                          return Curry._1(resolve, {
                                      TAG: /* Ok */0,
                                      _0: undefined
                                    });
                        }
                        catch (exn){
                          return Curry._1(resolve, {
                                      TAG: /* Error */1,
                                      _0: undefined
                                    });
                        }
                      }), 200);
                return (function (param) {
                          clearTimeout(timeoutId);
                        });
              }
              Curry._1(resolve, {
                    TAG: /* Error */1,
                    _0: undefined
                  });
            });
}

function fromBlob(blob, filename) {
  return fromUrl(filename, new URL(URL.createObjectURL(new File([blob], filename))));
}

export {
  fromUrl ,
  fromBlob ,
}
/* No side effect */
