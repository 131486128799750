// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as TextStyle$Pos from "./TextStyle.bs.js";

function Strong(Props) {
  var children = Props.children;
  var sizeOpt = Props.size;
  var variationOpt = Props.variation;
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  var variation = variationOpt !== undefined ? variationOpt : "neutral";
  return React.createElement(TextStyle$Pos.make, {
              children: children,
              variation: variation,
              weight: "strong",
              size: size
            });
}

var make = React.memo(Strong);

export {
  make ,
}
/* make Not a pure module */
