// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../primitives/Intl.bs.js";
import * as Stack$Pos from "../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as TextStyle$Pos from "../resources/typography/TextStyle.bs.js";

function style(hasSecondaryValue) {
  var offset = hasSecondaryValue ? 25 : 0;
  return {
          textAlign: "right",
          width: "calc(75px + " + String(offset) + "px)"
        };
}

function AmountTableCell(Props) {
  var value = Props.value;
  var secondaryValue = Props.secondaryValue;
  var decimalPrecisionOpt = Props.decimalPrecision;
  var decimalPrecision = decimalPrecisionOpt !== undefined ? decimalPrecisionOpt : 2;
  var formate = function (value) {
    return Intl$Pos.currencyFormat("EUR", decimalPrecision, decimalPrecision, value);
  };
  var hasSecondaryValue = Belt_Option.isSome(secondaryValue);
  return React.createElement("div", {
              style: style(hasSecondaryValue)
            }, secondaryValue !== undefined ? React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "xxsmall"
                  }, React.createElement(TextStyle$Pos.make, {
                        children: formate(value),
                        maxLines: 1
                      }), React.createElement(TextStyle$Pos.make, {
                        children: formate(secondaryValue) + " " + Intl$Pos.t("VAT incl."),
                        variation: "normal",
                        size: "tiny",
                        maxLines: 1
                      })) : React.createElement(TextStyle$Pos.make, {
                    children: formate(value),
                    weight: "semibold",
                    maxLines: 1
                  }));
}

var make = React.memo(AmountTableCell);

export {
  style ,
  make ,
}
/* make Not a pure module */
