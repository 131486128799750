// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as CatalogProductMutations$Pos from "../../app/Catalog/CatalogProductMutations.bs.js";

function CatalogProductArchiveModal(Props) {
  var id = Props.id;
  var opened = Props.opened;
  var onRequestClose = Props.onRequestClose;
  var scope = Auth$Pos.useScope(undefined);
  var navigate = Navigation$Pos.useNavigate(undefined);
  var archiveProduct = CatalogProductMutations$Pos.useArchive(id);
  return React.createElement(Modal$Pos.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                children: Intl$Pos.t("Once the product is archived, all its variants will be archived too.\nIts variants will only be visible from the catalog by applying the \"Archive\" filter."),
                                variation: "normal"
                              })), "xlarge", "xxlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              opened: opened,
              title: Intl$Pos.t("Confirm the archiving of this product and its variants"),
              abortButtonText: Intl$Pos.t("Cancel"),
              commitButtonText: Intl$Pos.t("Confirm"),
              commitButtonCallback: (function (param) {
                  Future.get(Curry._1(archiveProduct, undefined), (function (result) {
                          if (result.TAG === /* Ok */0 && scope.TAG !== /* Organisation */0) {
                            return Curry._4(navigate, LegacyRouter$Pos.routeToPathname(/* Catalog */2), undefined, undefined, undefined);
                          }
                          
                        }));
                }),
              onRequestClose: onRequestClose
            });
}

var make = React.memo(CatalogProductArchiveModal, (function (oldProps, newProps) {
        if (oldProps.id === newProps.id) {
          return oldProps.opened === newProps.opened;
        } else {
          return false;
        }
      }));

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
