// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Pervasives from "rescript/lib/es6/pervasives.js";

var context = React.createContext([
      undefined,
      (function (param) {
          
        })
    ]);

function use(param) {
  var match = React.useContext(context);
  var state = match[0];
  if (state !== undefined) {
    return [
            state,
            match[1]
          ];
  } else {
    return Pervasives.failwith("Could not find Notifier context value");
  }
}

var Context = {
  context: context,
  use: use
};

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider = {
  make: make,
  makeProps: makeProps
};

export {
  Context ,
  Provider ,
}
/* context Not a pure module */
