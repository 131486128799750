// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAria$Pos from "../externals/ReactAria.bs.js";

var style = {
  backgroundColor: "inherit",
  border: "none",
  cursor: "inherit",
  fontFamily: "inherit",
  minHeight: "56px",
  minWidth: "112px",
  resize: "none"
};

function TextArea(Props) {
  var areaRef = Props.areaRef;
  var ariaProps = Props.ariaProps;
  var placeholder = Props.placeholder;
  var readOnlyOpt = Props.readOnly;
  var derivedStyleOpt = Props.style;
  var value = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var readOnly = readOnlyOpt !== undefined ? readOnlyOpt : false;
  var derivedStyle = derivedStyleOpt !== undefined ? Caml_option.valFromOption(derivedStyleOpt) : ({});
  var domRef = Belt_Option.getWithDefault(areaRef, React.useRef(null));
  var onHeightChange = React.useCallback((function (element) {
          var elementStyleDict = Js_dict.get(element, "style");
          Belt_Option.map(elementStyleDict, (function (dict) {
                  dict["overflow"] = "hidden";
                }));
          Belt_Option.map(elementStyleDict, (function (dict) {
                  dict["height"] = "auto";
                }));
          var offsetHeight = element.offsetHeight | 0;
          var scrollHeight = element.scrollHeight;
          var clientHeight = element.clientHeight;
          var unsafeHeight = "" + String(scrollHeight + (offsetHeight - clientHeight | 0) | 0) + "px";
          Belt_Option.map(elementStyleDict, (function (dict) {
                  dict["height"] = unsafeHeight;
                }));
          Belt_Option.map(elementStyleDict, (function (dict) {
                  dict["overflow"] = "auto";
                }));
        }), []);
  React.useLayoutEffect((function () {
          var area = domRef.current;
          if (!(area == null)) {
            Curry._1(onHeightChange, area);
          }
          
        }), [
        onHeightChange,
        areaRef,
        value
      ]);
  var match = Belt_Option.isSome(value) ? [
      undefined,
      value
    ] : [
      value,
      undefined
    ];
  var props_aria$label = "TextArea";
  var props_inputElementType = "textarea";
  var props_value = match[1];
  var props_defaultValue = match[0];
  var props = {
    "aria-label": props_aria$label,
    placeholder: placeholder,
    inputElementType: props_inputElementType,
    isReadOnly: readOnly,
    value: props_value,
    defaultValue: props_defaultValue,
    onChange: onChange,
    onFocus: onFocus,
    onBlur: onBlur
  };
  var match$1 = ReactAria.useTextField(props, domRef);
  var props$1 = ReactAria.mergeProps(match$1.inputProps, ariaProps);
  var style$1 = Object.assign({}, style, derivedStyle);
  return React.createElement(ReactAria$Pos.Spread.make, {
              props: props$1,
              children: React.createElement("textarea", {
                    ref: domRef,
                    style: style$1
                  })
            });
}

var make = React.memo(TextArea);

export {
  make ,
}
/* make Not a pure module */
