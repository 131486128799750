// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Colors$Pos from "../theme/Colors.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";

function borderColor(focused, hovered, errored, disabled) {
  if (disabled) {
    return Colors$Pos.neutralColor15;
  } else if (errored && focused) {
    return Colors$Pos.dangerColor55;
  } else if (errored) {
    return Colors$Pos.dangerColor30;
  } else if (focused) {
    return Colors$Pos.neutralColor30;
  } else if (hovered) {
    return Colors$Pos.neutralColor25;
  } else {
    return Colors$Pos.neutralColor20;
  }
}

function backgroundColor(disabled) {
  if (disabled) {
    return Colors$Pos.neutralColor05;
  } else {
    return Colors$Pos.neutralColor00;
  }
}

function color(disabled) {
  if (disabled) {
    return Colors$Pos.neutralColor35;
  } else {
    return Colors$Pos.neutralColor90;
  }
}

var fontSizePx = String(FontSizes$Pos.normal) + "px";

var borderRadiusPx = "5px";

var fontSize = FontSizes$Pos.normal;

export {
  borderColor ,
  borderRadiusPx ,
  backgroundColor ,
  color ,
  fontSize ,
  fontSizePx ,
}
/* fontSizePx Not a pure module */
