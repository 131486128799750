// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "./Box.bs.js";
import * as Inline$Pos from "./Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function BarControl(Props) {
  var filters = Props.filters;
  var actions = Props.actions;
  var banner = Props.banner;
  if (filters === undefined && actions === undefined && banner === undefined) {
    return React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  }
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Inline$Pos.make, {
                  children: null,
                  align: filters !== undefined && Caml_option.valFromOption(filters) !== null ? "spaceBetween" : "end"
                }, filters !== undefined ? Caml_option.valFromOption(filters) : null, actions !== undefined ? Caml_option.valFromOption(actions) : null), banner !== undefined ? Caml_option.valFromOption(banner) : null);
}

var make = BarControl;

export {
  make ,
}
/* react Not a pure module */
