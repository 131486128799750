// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as LoadingPage$Pos from "./LoadingPage.bs.js";

function baseRoute(text, param) {
  if (text !== undefined) {
    return "/loading/" + text;
  } else {
    return "/loading";
  }
}

function LoadingRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (subUrlPath) {
    if (subUrlPath.tl) {
      return React.createElement(Navigation$Pos.Redirect.make, {
                  route: "/loading"
                });
    } else {
      return React.createElement(LoadingPage$Pos.make, {
                  text: subUrlPath.hd
                });
    }
  } else {
    return React.createElement(LoadingPage$Pos.make, {});
  }
}

var make = LoadingRouter;

export {
  baseRoute ,
  make ,
}
/* react Not a pure module */
