// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "./Box.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var rootStyle = Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor20, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(30)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Style$Pos.hairlineWidth)), undefined, undefined);

function Separator(Props) {
  var spaceOpt = Props.space;
  var space = spaceOpt !== undefined ? spaceOpt : "xsmall";
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(View$Pos.make, View$Pos.makeProps(undefined, Caml_option.some(rootStyle), undefined, undefined, undefined))), space, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var make = React.memo(Separator);

export {
  make ,
}
/* rootStyle Not a pure module */
