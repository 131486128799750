// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query products($filterBy: InputProductsQueryFilter)  {\nproducts(filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.products;
  var value$2 = value$1.edges;
  return {
          products: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.products;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.__typename;
          var node = {
            __typename: value$5,
            shop: shop
          };
          var value$6 = value.__typename;
          return {
                  __typename: value$6,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var products = {
    __typename: value$3,
    edges: edges
  };
  return {
          products: products
        };
}

function serializeInputObjectProductKindEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a === "SPIRITUOUS" ? "SPIRITUOUS" : (
              a === "BEER" ? "BEER" : (
                  a === "WINE" ? "WINE" : "SIMPLE"
                )
            )
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectProductColorEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a === "ORANGE" ? "ORANGE" : (
              a === "RED" ? "RED" : (
                  a === "AMBER" ? "AMBER" : (
                      a === "DARK" ? "DARK" : (
                          a === "BLACK" ? "BLACK" : (
                              a === "BLOND" ? "BLOND" : (
                                  a === "ROSE" ? "ROSE" : "WHITE"
                                )
                            )
                        )
                    )
                )
            )
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputProductsQueryFilter(inp) {
  var a = inp.name;
  var a$1 = inp.kind;
  var a$2 = inp.producer;
  var a$3 = inp.color;
  var a$4 = inp.variantCkus;
  var a$5 = inp.shopIds;
  var a$6 = inp.archived;
  return {
          name: a !== undefined ? ({
                _equals: a._equals
              }) : undefined,
          kind: a$1 !== undefined ? serializeInputObjectProductKindEqualsFilter(a$1) : undefined,
          producer: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          color: a$3 !== undefined ? serializeInputObjectProductColorEqualsFilter(a$3) : undefined,
          variantCkus: a$4 !== undefined ? serializeInputObjectInFilter(a$4) : undefined,
          shopIds: a$5 !== undefined ? serializeInputObjectInFilter(a$5) : undefined,
          archived: a$6 !== undefined ? (
              a$6 === "ONLY" ? "ONLY" : (
                  a$6 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputProductsQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputProductsQueryFilter(name, kind, producer, color, variantCkus, shopIds, archived, param) {
  return {
          name: name,
          kind: kind,
          producer: producer,
          color: color,
          variantCkus: variantCkus,
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectProductKindEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectProductColorEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var ProductsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductsQueryFilter: serializeInputObjectInputProductsQueryFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectProductKindEqualsFilter: serializeInputObjectProductKindEqualsFilter,
  serializeInputObjectProductColorEqualsFilter: serializeInputObjectProductColorEqualsFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductsQueryFilter: makeInputObjectInputProductsQueryFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectProductKindEqualsFilter: makeInputObjectProductKindEqualsFilter,
  makeInputObjectProductColorEqualsFilter: makeInputObjectProductColorEqualsFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var ProductsQuery_refetchQueryDescription = include.refetchQueryDescription;

var ProductsQuery_use = include.use;

var ProductsQuery_useLazy = include.useLazy;

var ProductsQuery_useLazyWithVariables = include.useLazyWithVariables;

var ProductsQuery = {
  ProductsQuery_inner: ProductsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductsQueryFilter: serializeInputObjectInputProductsQueryFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectProductKindEqualsFilter: serializeInputObjectProductKindEqualsFilter,
  serializeInputObjectProductColorEqualsFilter: serializeInputObjectProductColorEqualsFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductsQueryFilter: makeInputObjectInputProductsQueryFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectProductKindEqualsFilter: makeInputObjectProductKindEqualsFilter,
  makeInputObjectProductColorEqualsFilter: makeInputObjectProductColorEqualsFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: ProductsQuery_refetchQueryDescription,
  use: ProductsQuery_use,
  useLazy: ProductsQuery_useLazy,
  useLazyWithVariables: ProductsQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query variantsByCku($cku: CKU!, $filterBy: InputVariantsQueryFilter)  {\nvariantsByCku(cku: $cku, first: 1, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedName  \nname  \ncapacityUnit  \ncapacityValue  \ncapacityPrecision  \nean13  \nstockKeepingUnit  \npriceLookUpCode  \ninternalCode  \nalcoholVolume  \naccountingCode  \nyear  \nbulk  \npurchasedPrice  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nproduct  {\n__typename  \nkind  \n}\n\nvariantPrices(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nvalueIncludingTax  \nvalueExcludingTax  \nprice  {\n__typename  \nname  \nenableByDefault  \ntaxIncluded  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  return {
          variantsByCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.capacityUnit;
                    var value$3 = value$1.capacityValue;
                    var value$4 = value$1.capacityPrecision;
                    var value$5 = value$1.ean13;
                    var value$6 = value$1.stockKeepingUnit;
                    var value$7 = value$1.priceLookUpCode;
                    var value$8 = value$1.internalCode;
                    var value$9 = value$1.alcoholVolume;
                    var value$10 = value$1.accountingCode;
                    var value$11 = value$1.year;
                    var value$12 = value$1.bulk;
                    var value$13 = value$1.purchasedPrice;
                    var value$14 = value$1.maxStockThreshold;
                    var value$15 = value$1.minStockThreshold;
                    var value$16 = value$1.stockOrderTriggerThreshold;
                    var value$17 = value$1.product;
                    var value$18 = value$17.kind;
                    var tmp;
                    switch (value$18) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$19 = value$1.variantPrices;
                    var value$20 = value$19.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              formattedName: value$1.formattedName,
                              name: value$1.name,
                              capacityUnit: !(value$2 == null) ? value$2 : undefined,
                              capacityValue: !(value$3 == null) ? value$3 : undefined,
                              capacityPrecision: !(value$4 == null) ? value$4 : undefined,
                              ean13: !(value$5 == null) ? value$5 : undefined,
                              stockKeepingUnit: !(value$6 == null) ? value$6 : undefined,
                              priceLookUpCode: !(value$7 == null) ? value$7 : undefined,
                              internalCode: !(value$8 == null) ? value$8 : undefined,
                              alcoholVolume: !(value$9 == null) ? value$9 : undefined,
                              accountingCode: !(value$10 == null) ? value$10 : undefined,
                              year: !(value$11 == null) ? value$11 : undefined,
                              bulk: !(value$12 == null) ? value$12 : undefined,
                              purchasedPrice: !(value$13 == null) ? value$13 : undefined,
                              maxStockThreshold: !(value$14 == null) ? value$14 : undefined,
                              minStockThreshold: !(value$15 == null) ? value$15 : undefined,
                              stockOrderTriggerThreshold: !(value$16 == null) ? value$16 : undefined,
                              product: {
                                __typename: value$17.__typename,
                                kind: tmp
                              },
                              variantPrices: {
                                __typename: value$19.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.price;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  id: value$1.id,
                                                  valueIncludingTax: value$1.valueIncludingTax,
                                                  valueExcludingTax: value$1.valueExcludingTax,
                                                  price: !(value$2 == null) ? ({
                                                        __typename: value$2.__typename,
                                                        name: value$2.name,
                                                        enableByDefault: value$2.enableByDefault,
                                                        taxIncluded: value$2.taxIncluded
                                                      }) : undefined
                                                }
                                              };
                                      }), value$20)
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variantPrices;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.price;
                  var price;
                  if (value$2 !== undefined) {
                    var value$3 = value$2.taxIncluded;
                    var value$4 = value$2.enableByDefault;
                    var value$5 = value$2.name;
                    var value$6 = value$2.__typename;
                    price = {
                      __typename: value$6,
                      name: value$5,
                      enableByDefault: value$4,
                      taxIncluded: value$3
                    };
                  } else {
                    price = null;
                  }
                  var value$7 = value$1.valueExcludingTax;
                  var value$8 = value$1.valueIncludingTax;
                  var value$9 = value$1.id;
                  var value$10 = value$1.__typename;
                  var node = {
                    __typename: value$10,
                    id: value$9,
                    valueIncludingTax: value$8,
                    valueExcludingTax: value$7,
                    price: price
                  };
                  var value$11 = value.__typename;
                  return {
                          __typename: value$11,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variantPrices = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.product;
          var value$6 = value$5.kind;
          var kind = value$6 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$6 === "BEER" ? "BEER" : (
                  value$6 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$7 = value$5.__typename;
          var product = {
            __typename: value$7,
            kind: kind
          };
          var value$8 = value$1.stockOrderTriggerThreshold;
          var stockOrderTriggerThreshold = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.minStockThreshold;
          var minStockThreshold = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.maxStockThreshold;
          var maxStockThreshold = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.purchasedPrice;
          var purchasedPrice = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.bulk;
          var bulk = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.year;
          var year = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.accountingCode;
          var accountingCode = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.alcoholVolume;
          var alcoholVolume = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.internalCode;
          var internalCode = value$16 !== undefined ? value$16 : null;
          var value$17 = value$1.priceLookUpCode;
          var priceLookUpCode = value$17 !== undefined ? value$17 : null;
          var value$18 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$18 !== undefined ? value$18 : null;
          var value$19 = value$1.ean13;
          var ean13 = value$19 !== undefined ? value$19 : null;
          var value$20 = value$1.capacityPrecision;
          var capacityPrecision = value$20 !== undefined ? value$20 : null;
          var value$21 = value$1.capacityValue;
          var capacityValue = value$21 !== undefined ? value$21 : null;
          var value$22 = value$1.capacityUnit;
          var capacityUnit = value$22 !== undefined ? value$22 : null;
          var value$23 = value$1.name;
          var value$24 = value$1.formattedName;
          var value$25 = value$1.id;
          var value$26 = value$1.__typename;
          var node = {
            __typename: value$26,
            id: value$25,
            formattedName: value$24,
            name: value$23,
            capacityUnit: capacityUnit,
            capacityValue: capacityValue,
            capacityPrecision: capacityPrecision,
            ean13: ean13,
            stockKeepingUnit: stockKeepingUnit,
            priceLookUpCode: priceLookUpCode,
            internalCode: internalCode,
            alcoholVolume: alcoholVolume,
            accountingCode: accountingCode,
            year: year,
            bulk: bulk,
            purchasedPrice: purchasedPrice,
            maxStockThreshold: maxStockThreshold,
            minStockThreshold: minStockThreshold,
            stockOrderTriggerThreshold: stockOrderTriggerThreshold,
            product: product,
            variantPrices: variantPrices
          };
          var value$27 = value.__typename;
          return {
                  __typename: value$27,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var variantsByCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          variantsByCku: variantsByCku
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter$1(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectStringEqualsFilter$1(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.categoryId;
  var a$6 = inp.createdAt;
  var a$7 = inp.updatedAt;
  var a$8 = inp.supplierId;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter$1(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          categoryId: a$5 !== undefined ? ({
                _equals: a$5._equals
              }) : undefined,
          createdAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          updatedAt: a$7 !== undefined ? serializeInputObjectDateFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? ({
                _equals: a$8._equals
              }) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.filterBy;
  return {
          cku: inp.cku,
          filterBy: a !== undefined ? serializeInputObjectInputVariantsQueryFilter(a) : undefined
        };
}

function makeVariables$1(cku, filterBy, param) {
  return {
          cku: cku,
          filterBy: filterBy
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, categoryId, createdAt, updatedAt, supplierId, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          categoryId: categoryId,
          createdAt: createdAt,
          updatedAt: updatedAt,
          supplierId: supplierId
        };
}

function makeInputObjectInFilter$1(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter$1(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

var VariantQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter$1,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter$1,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var VariantQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var VariantQuery_use = include$1.use;

var VariantQuery_useLazy = include$1.useLazy;

var VariantQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var VariantQuery = {
  VariantQuery_inner: VariantQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter$1,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter$1,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  refetchQueryDescription: VariantQuery_refetchQueryDescription,
  use: VariantQuery_use,
  useLazy: VariantQuery_useLazy,
  useLazyWithVariables: VariantQuery_useLazyWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["query productsByVariantCku($cku: CKU!, $filterBy: InputProductsByVariantCkuQueryFilter)  {\nproductsByVariantCku(cku: $cku, first: 1, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \nkind  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.edges;
  return {
          productsByVariantCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.kind;
                    var tmp;
                    switch (value$2) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$3 = value$1.tax;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              kind: tmp,
                              tax: {
                                __typename: value$3.__typename,
                                value: value$3.value
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize$2(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.tax;
          var value$3 = value$2.value;
          var value$4 = value$2.__typename;
          var tax = {
            __typename: value$4,
            value: value$3
          };
          var value$5 = value$1.kind;
          var kind = value$5 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$5 === "BEER" ? "BEER" : (
                  value$5 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$6 = value$1.name;
          var value$7 = value$1.id;
          var value$8 = value$1.__typename;
          var node = {
            __typename: value$8,
            id: value$7,
            name: value$6,
            kind: kind,
            tax: tax
          };
          var value$9 = value.__typename;
          return {
                  __typename: value$9,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var productsByVariantCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          productsByVariantCku: productsByVariantCku
        };
}

function serializeInputObjectInFilter$2(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputProductsByVariantCkuQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter$2(a) : undefined,
          archived: a$1 !== undefined ? (
              a$1 === "ONLY" ? "ONLY" : (
                  a$1 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables$2(inp) {
  var a = inp.filterBy;
  return {
          cku: inp.cku,
          filterBy: a !== undefined ? serializeInputObjectInputProductsByVariantCkuQueryFilter(a) : undefined
        };
}

function makeVariables$2(cku, filterBy, param) {
  return {
          cku: cku,
          filterBy: filterBy
        };
}

function makeInputObjectInputProductsByVariantCkuQueryFilter(shopIds, archived, param) {
  return {
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectInFilter$2(_in, param) {
  return {
          _in: _in
        };
}

var ProductQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectInputProductsByVariantCkuQueryFilter: serializeInputObjectInputProductsByVariantCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$2,
  makeVariables: makeVariables$2,
  makeInputObjectInputProductsByVariantCkuQueryFilter: makeInputObjectInputProductsByVariantCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$2
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var ProductQuery_refetchQueryDescription = include$2.refetchQueryDescription;

var ProductQuery_use = include$2.use;

var ProductQuery_useLazy = include$2.useLazy;

var ProductQuery_useLazyWithVariables = include$2.useLazyWithVariables;

var ProductQuery = {
  ProductQuery_inner: ProductQuery_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectInputProductsByVariantCkuQueryFilter: serializeInputObjectInputProductsByVariantCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$2,
  makeVariables: makeVariables$2,
  makeInputObjectInputProductsByVariantCkuQueryFilter: makeInputObjectInputProductsByVariantCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$2,
  refetchQueryDescription: ProductQuery_refetchQueryDescription,
  use: ProductQuery_use,
  useLazy: ProductQuery_useLazy,
  useLazyWithVariables: ProductQuery_useLazyWithVariables
};

export {
  ProductsQuery ,
  VariantQuery ,
  ProductQuery ,
}
/* query Not a pure module */
