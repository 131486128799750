// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../../resources/theme/FontFaces.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as CatalogVariant$Pos from "./CatalogVariant.bs.js";
import * as ProductStockTableCell$Pos from "../Product/ProductStockTableCell.bs.js";
import * as CatalogVariantLossReportModal$Pos from "./CatalogVariantLossReportModal.bs.js";
import * as CatalogVariantDeliveryReportModal$Pos from "./CatalogVariantDeliveryReportModal.bs.js";

function keyExtractor(row) {
  return row.shopId;
}

var Row = {
  keyExtractor: keyExtractor
};

var columns = [
  {
    key: "shopname",
    name: Intl$Pos.t("Shop/Warehouse"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 150
      },
      margin: "normal"
    },
    render: (function (param) {
        return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                children: param.data.shopName
                              })), undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
      })
  },
  {
    key: "stock-quantity",
    name: "Stock",
    layout: {
      width: {
        NAME: "fr",
        VAL: 0
      }
    },
    render: (function (param) {
        var match = param.data;
        var tmp = {
          value: match.formattedQuantity
        };
        if (match.state !== undefined) {
          tmp.state = Caml_option.valFromOption(match.state);
        }
        return React.createElement(ProductStockTableCell$Pos.make, tmp);
      })
  }
];

var styles = StyleSheet$Pos.create({
      multishopStockText: Style$Pos.merge([
            FontFaces$Pos.archivoBoldStyle,
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, 30, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

var initialModalsState = {
  loss: false,
  delivery: false
};

function CatalogVariantStockTableCard(Props) {
  var cku = Props.cku;
  var shopsVariantStock = Props.shopsVariantStock;
  var match = React.useState(function () {
        return initialModalsState;
      });
  var setReportingModalsOpened = match[1];
  var reportingModalsOpened = match[0];
  var match$1 = Auth$Pos.useScope(undefined);
  var multishop;
  multishop = match$1.TAG === /* Organisation */0 ? match$1.activeShop === undefined : false;
  var shopsStockDiffering = multishop && CatalogVariant$Pos.MultiShops.arrayKeepUniqueBy(shopsVariantStock, (function (a, b) {
          if (a.capacityUnit === b.capacityUnit) {
            return a.capacityPrecision === b.capacityPrecision;
          } else {
            return false;
          }
        })).length > 1;
  var makeMultishopStock = React.useCallback((function (capacityUnit) {
          return function (capacityPrecision) {
            var totalStock = Belt_Array.reduce(shopsVariantStock, 0, (function (acc, stock) {
                    return acc + stock.rawQuantity | 0;
                  }));
            return CatalogVariant$Pos.StockQuantity.format(totalStock, capacityPrecision, capacityUnit);
          };
        }), [shopsVariantStock]);
  var onRequestClose = React.useCallback((function (param) {
          Curry._1(setReportingModalsOpened, (function (param) {
                  return initialModalsState;
                }));
        }), []);
  var match$2 = Belt_Array.get(shopsVariantStock, 0);
  return React.createElement(React.Fragment, undefined, React.createElement(Card$Pos.make, {
                  children: React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "small"
                      }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), multishop && !(shopsStockDiffering || match$2 === undefined) ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Text$Pos.make, Text$Pos.makeProps(Curry._2(makeMultishopStock, match$2.capacityUnit, match$2.capacityPrecision), Caml_option.some(styles.multishopStockText), undefined, undefined, undefined))), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Inline$Pos.make, {
                                children: null,
                                space: "small"
                              }, React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Loss"), "xsmall", "danger", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                          Curry._1(setReportingModalsOpened, (function (param) {
                                                  return {
                                                          loss: true,
                                                          delivery: false
                                                        };
                                                }));
                                        }), undefined, undefined, undefined)), React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Delivery"), "xsmall", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                          Curry._1(setReportingModalsOpened, (function (param) {
                                                  return {
                                                          loss: false,
                                                          delivery: true
                                                        };
                                                }));
                                        }), undefined, undefined, undefined)))), React.createElement(TableView$Pos.make, {
                            data: {
                              TAG: /* Done */1,
                              _0: {
                                TAG: /* Ok */0,
                                _0: shopsVariantStock
                              }
                            },
                            columns: columns,
                            keyExtractor: keyExtractor
                          })),
                  title: multishop ? "Stocks" : "Stock",
                  variation: "table"
                }), React.createElement(CatalogVariantLossReportModal$Pos.make, {
                  cku: cku,
                  opened: reportingModalsOpened.loss,
                  shopsVariantStock: shopsVariantStock,
                  onRequestClose: onRequestClose
                }), React.createElement(CatalogVariantDeliveryReportModal$Pos.make, {
                  cku: cku,
                  opened: reportingModalsOpened.delivery,
                  shopsVariantStock: shopsVariantStock,
                  onRequestClose: onRequestClose
                }));
}

var make = React.memo(CatalogVariantStockTableCard);

var Config;

export {
  Config ,
  Row ,
  columns ,
  styles ,
  initialModalsState ,
  make ,
}
/* columns Not a pure module */
