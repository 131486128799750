// Generated by ReScript, PLEASE EDIT WITH CARE


var basePathname = "auth";

var baseRoute = "/" + basePathname;

var loginRoute = baseRoute + "/login";

var loginSessionFromWebsiteRoute = loginRoute + "/from-website";

var loginSessionExpiredRoute = loginRoute + "/session-expired";

var loginImpersonationFailureRoute = loginRoute + "/impersonation-failure";

var loginRecoverySuccessRoute = loginRoute + "/password-reset";

var loginUsernameUpdateSuccessRoute = loginRoute + "/username-updated";

var logoutRoute = baseRoute + "/logout";

var logoutSessionExpiredRoute = logoutRoute + "/session-expired";

var logoutImpersonationFailureRoute = logoutRoute + "/impersonation-failure";

var recoveryRoute = baseRoute + "/recovery";

function resetRoute(token) {
  return baseRoute + "/reset/" + token;
}

function impersonationRoute(userId) {
  return baseRoute + "/impersonation/" + userId;
}

var loginRecoveryEmailSentRoute = loginRoute + "/recovery-email-sent";

var signupSuccess = baseRoute + "/signup/success";

export {
  basePathname ,
  baseRoute ,
  loginRoute ,
  loginSessionFromWebsiteRoute ,
  loginSessionExpiredRoute ,
  loginImpersonationFailureRoute ,
  loginRecoverySuccessRoute ,
  loginUsernameUpdateSuccessRoute ,
  logoutRoute ,
  logoutSessionExpiredRoute ,
  logoutImpersonationFailureRoute ,
  recoveryRoute ,
  resetRoute ,
  impersonationRoute ,
  loginRecoveryEmailSentRoute ,
  signupSuccess ,
}
/* No side effect */
