// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query productsByVariantCku($cku: CKU!)  {\nproductsByVariantCku(cku: $cku, first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \nproducer  \nkind  \ncolor  \nshop  {\n__typename  \nid  \n}\n\nvariants(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \ncku  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.edges;
  return {
          productsByVariantCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.producer;
                    var value$3 = value$1.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$1.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$1.shop;
                    var value$6 = value$1.variants;
                    var value$7 = value$6.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              producer: !(value$2 == null) ? value$2 : undefined,
                              kind: tmp,
                              color: tmp$1,
                              shop: {
                                __typename: value$5.__typename,
                                id: value$5.id
                              },
                              variants: {
                                __typename: value$6.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  cku: Scalar$Pos.CKU.parse(value$1.cku)
                                                }
                                              };
                                      }), value$7)
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variants;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.cku;
                  var value$3 = Scalar$Pos.CKU.serialize(value$2);
                  var value$4 = value$1.__typename;
                  var node = {
                    __typename: value$4,
                    cku: value$3
                  };
                  var value$5 = value.__typename;
                  return {
                          __typename: value$5,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variants = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.shop;
          var value$6 = value$5.id;
          var value$7 = value$5.__typename;
          var shop = {
            __typename: value$7,
            id: value$6
          };
          var value$8 = value$1.color;
          var color = value$8 !== undefined ? (
              value$8 === "ORANGE" ? "ORANGE" : (
                  value$8 === "RED" ? "RED" : (
                      value$8 === "AMBER" ? "AMBER" : (
                          value$8 === "DARK" ? "DARK" : (
                              value$8 === "BLACK" ? "BLACK" : (
                                  value$8 === "BLOND" ? "BLOND" : (
                                      value$8 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$9 = value$1.kind;
          var kind = value$9 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$9 === "BEER" ? "BEER" : (
                  value$9 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$10 = value$1.producer;
          var producer = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.name;
          var value$12 = value$1.id;
          var value$13 = value$1.__typename;
          var node = {
            __typename: value$13,
            id: value$12,
            name: value$11,
            producer: producer,
            kind: kind,
            color: color,
            shop: shop,
            variants: variants
          };
          var value$14 = value.__typename;
          return {
                  __typename: value$14,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var productsByVariantCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          productsByVariantCku: productsByVariantCku
        };
}

function serializeVariables(inp) {
  return {
          cku: inp.cku
        };
}

function makeVariables(cku, param) {
  return {
          cku: cku
        };
}

var ProductsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var ProductsQuery_refetchQueryDescription = include.refetchQueryDescription;

var ProductsQuery_use = include.use;

var ProductsQuery_useLazy = include.useLazy;

var ProductsQuery_useLazyWithVariables = include.useLazyWithVariables;

var ProductsQuery = {
  ProductsQuery_inner: ProductsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: ProductsQuery_refetchQueryDescription,
  use: ProductsQuery_use,
  useLazy: ProductsQuery_useLazy,
  useLazyWithVariables: ProductsQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query variantsByCku($cku: CKU!)  {\nvariantsByCku(cku: $cku, first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedStatus  \nformattedName  \nshop  {\n__typename  \nid  \n}\n\nproduct  {\n__typename  \nname  \nproducer  \nkind  \ncolor  \nvariants(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \ncku  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  return {
          variantsByCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.formattedStatus;
                    var tmp;
                    switch (value$2) {
                      case "ACTIVE" :
                          tmp = "ACTIVE";
                          break;
                      case "ARCHIVED" :
                          tmp = "ARCHIVED";
                          break;
                      case "INACTIVE" :
                          tmp = "INACTIVE";
                          break;
                      case "VARIABLE" :
                          tmp = "VARIABLE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$3 = value$1.shop;
                    var value$4 = value$1.product;
                    var value$5 = value$4.producer;
                    var value$6 = value$4.kind;
                    var tmp$1;
                    switch (value$6) {
                      case "BEER" :
                          tmp$1 = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp$1 = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp$1 = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp$1 = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$7 = value$4.color;
                    var tmp$2;
                    if (value$7 == null) {
                      tmp$2 = undefined;
                    } else {
                      var tmp$3;
                      switch (value$7) {
                        case "AMBER" :
                            tmp$3 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$3 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$3 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$3 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$3 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$3 = "RED";
                            break;
                        case "ROSE" :
                            tmp$3 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$3 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$2 = tmp$3;
                    }
                    var value$8 = value$4.variants;
                    var value$9 = value$8.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              formattedStatus: tmp,
                              formattedName: value$1.formattedName,
                              shop: {
                                __typename: value$3.__typename,
                                id: value$3.id
                              },
                              product: {
                                __typename: value$4.__typename,
                                name: value$4.name,
                                producer: !(value$5 == null) ? value$5 : undefined,
                                kind: tmp$1,
                                color: tmp$2,
                                variants: {
                                  __typename: value$8.__typename,
                                  edges: Js_array.map((function (value) {
                                          var value$1 = value.node;
                                          return {
                                                  __typename: value.__typename,
                                                  node: {
                                                    __typename: value$1.__typename,
                                                    cku: Scalar$Pos.CKU.parse(value$1.cku)
                                                  }
                                                };
                                        }), value$9)
                                }
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.variantsByCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.product;
          var value$3 = value$2.variants;
          var value$4 = value$3.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.cku;
                  var value$3 = Scalar$Pos.CKU.serialize(value$2);
                  var value$4 = value$1.__typename;
                  var node = {
                    __typename: value$4,
                    cku: value$3
                  };
                  var value$5 = value.__typename;
                  return {
                          __typename: value$5,
                          node: node
                        };
                }), value$4);
          var value$5 = value$3.__typename;
          var variants = {
            __typename: value$5,
            edges: edges
          };
          var value$6 = value$2.color;
          var color = value$6 !== undefined ? (
              value$6 === "ORANGE" ? "ORANGE" : (
                  value$6 === "RED" ? "RED" : (
                      value$6 === "AMBER" ? "AMBER" : (
                          value$6 === "DARK" ? "DARK" : (
                              value$6 === "BLACK" ? "BLACK" : (
                                  value$6 === "BLOND" ? "BLOND" : (
                                      value$6 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$7 = value$2.kind;
          var kind = value$7 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$7 === "BEER" ? "BEER" : (
                  value$7 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$8 = value$2.producer;
          var producer = value$8 !== undefined ? value$8 : null;
          var value$9 = value$2.name;
          var value$10 = value$2.__typename;
          var product = {
            __typename: value$10,
            name: value$9,
            producer: producer,
            kind: kind,
            color: color,
            variants: variants
          };
          var value$11 = value$1.shop;
          var value$12 = value$11.id;
          var value$13 = value$11.__typename;
          var shop = {
            __typename: value$13,
            id: value$12
          };
          var value$14 = value$1.formattedName;
          var value$15 = value$1.formattedStatus;
          var formattedStatus = value$15 === "INACTIVE" ? "INACTIVE" : (
              value$15 === "ARCHIVED" ? "ARCHIVED" : (
                  value$15 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
                )
            );
          var value$16 = value$1.id;
          var value$17 = value$1.__typename;
          var node = {
            __typename: value$17,
            id: value$16,
            formattedStatus: formattedStatus,
            formattedName: value$14,
            shop: shop,
            product: product
          };
          var value$18 = value.__typename;
          return {
                  __typename: value$18,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var variantsByCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          variantsByCku: variantsByCku
        };
}

function serializeVariables$1(inp) {
  return {
          cku: inp.cku
        };
}

function makeVariables$1(cku, param) {
  return {
          cku: cku
        };
}

var VariantsQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var VariantsQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var VariantsQuery_use = include$1.use;

var VariantsQuery_useLazy = include$1.useLazy;

var VariantsQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var VariantsQuery = {
  VariantsQuery_inner: VariantsQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  refetchQueryDescription: VariantsQuery_refetchQueryDescription,
  use: VariantsQuery_use,
  useLazy: VariantsQuery_useLazy,
  useLazyWithVariables: VariantsQuery_useLazyWithVariables
};

export {
  ProductsQuery ,
  VariantsQuery ,
}
/* query Not a pure module */
