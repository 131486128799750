// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Intl$Pos from "../../primitives/Intl.bs.js";

function kindToText(kind) {
  switch (kind) {
    case /* Currency */0 :
        return Intl$Pos.t("EURO");
    case /* Percent */1 :
        return Intl$Pos.t("%");
    case /* Free */2 :
        return Intl$Pos.t("Free");
    
  }
}

function discountWarningToText(warning) {
  return Intl$Pos.t("Cannot be greater than {{max}}");
}

function productExpectedQuantityWarningToText(warning) {
  return Intl$Pos.t("Quantity by {{packaging}}");
}

export {
  kindToText ,
  discountWarningToText ,
  productExpectedQuantityWarningToText ,
}
/* Intl-Pos Not a pure module */
