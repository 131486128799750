// Generated by ReScript, PLEASE EDIT WITH CARE


function startOfDay(date) {
  return new Date(new Date(date.getTime()).setHours(0, 0, 0, 0));
}

function endOfDay(date) {
  return new Date(new Date(date.getTime()).setHours(23, 59, 59, 999));
}

function isSameDay(dateA, dateB) {
  return startOfDay(new Date(dateA.getTime())).getTime() === startOfDay(new Date(dateB.getTime())).getTime();
}

function addDays(date, days) {
  return new Date(new Date(date.getTime()).setDate(date.getDate() + days));
}

function subDays(date, days) {
  return addDays(date, - days);
}

function startOfWeek(date) {
  var day = date.getDay();
  var diff = (
    day < 1 ? 7 : 0
  ) + day - 1;
  return startOfDay(new Date(new Date(date.getTime()).setDate(date.getDate() - diff)));
}

function endOfWeek(date) {
  var day = date.getDay();
  var diff = (
    day < 1 ? -7 : 0
  ) + 6 - (day - 1);
  return endOfDay(new Date(new Date(date.getTime()).setDate(date.getDate() + diff)));
}

function addWeeks(date, weeks) {
  return addDays(date, weeks * 7);
}

function subWeeks(date, weeks) {
  return addWeeks(date, - weeks);
}

function startOfMonth(date) {
  return startOfDay(new Date(new Date(date.getTime()).setDate(1)));
}

function lastDayOfMonthDate(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

function getDaysInMonth(date) {
  return lastDayOfMonthDate(date).getDate();
}

function addMonths(date, months) {
  var year = date.getFullYear();
  var month = date.getMonth() + months;
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var date$1 = new Date(year, month, 1);
  var daysInMonth = lastDayOfMonthDate(date$1).getDate();
  var date$2 = Math.min(daysInMonth, date.getDate());
  return new Date(year, month, date$2, hours, minutes, seconds);
}

function subMonths(date, months) {
  return addMonths(date, - months);
}

function isSameMonth(dateA, dateB) {
  return startOfMonth(dateA).getTime() === startOfMonth(dateB).getTime();
}

function endOfMonth(date) {
  return endOfDay(lastDayOfMonthDate(date));
}

function startOfYear(date) {
  return new Date(date.getFullYear(), 0, 1, 0, 0, 0);
}

function addYears(date, years) {
  return addMonths(date, 12 * years);
}

function subYears(date, years) {
  return addYears(date, - years);
}

function endOfYear(date) {
  return endOfDay(new Date(date.getFullYear(), 11, 31));
}

function diffInDays(firstDate, lastDate) {
  var dayMs = 24 * 60 * 60 * 1000;
  var diff = lastDate.getTime() - firstDate.getTime();
  return Math.round(Math.abs(diff / dayMs)) | 0;
}

function diffInHours(firstDate, lastDate) {
  var hourMs = 60 * 60 * 1000;
  var diff = firstDate.getTime() - lastDate.getTime();
  return Math.round(Math.abs(diff / hourMs)) | 0;
}

function getPreviousPeriod(startDate, endDate) {
  var days = diffInDays(startDate, endDate);
  var startDatePreviousPeriod = addDays(startDate, - days);
  var days$1 = diffInDays(startDate, endDate);
  var endDatePreviousPeriod = addDays(endDate, - days$1);
  return [
          startDatePreviousPeriod,
          endDatePreviousPeriod
        ];
}

function getPreviousYearPeriod(startDate, endDate) {
  var startDatePreviousPeriod = addYears(startDate, - 1);
  var endDatePreviousPeriod = addYears(endDate, - 1);
  return [
          startDatePreviousPeriod,
          endDatePreviousPeriod
        ];
}

export {
  startOfDay ,
  endOfDay ,
  isSameDay ,
  addDays ,
  subDays ,
  startOfWeek ,
  endOfWeek ,
  addWeeks ,
  subWeeks ,
  startOfMonth ,
  endOfMonth ,
  isSameMonth ,
  lastDayOfMonthDate ,
  getDaysInMonth ,
  addMonths ,
  subMonths ,
  startOfYear ,
  endOfYear ,
  addYears ,
  subYears ,
  diffInDays ,
  diffInHours ,
  getPreviousPeriod ,
  getPreviousYearPeriod ,
}
/* No side effect */
