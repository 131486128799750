// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../layout-and-structure/Stack.bs.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";

function TableDatetimeCell(Props) {
  var value = Props.value;
  var formattedDate = Intl$Pos.dateTimeFormat(undefined, "short", value);
  var match = Intl$Pos.dateTimeFormat("short", undefined, value);
  var formattedTime = Intl$Pos.locale === "fr-FR" ? match.replace(":", "h") : match;
  return React.createElement(Stack$Pos.make, {
              children: null
            }, React.createElement(TextStyle$Pos.make, {
                  children: formattedDate
                }), React.createElement(TextStyle$Pos.make, {
                  children: Intl$Pos.t("at") + (" " + formattedTime),
                  variation: "normal",
                  size: "tiny"
                }));
}

var make = React.memo(TableDatetimeCell);

export {
  make ,
}
/* make Not a pure module */
