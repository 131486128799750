// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Notifier__Context$Pos from "../../bundles/Notifier/Notifier__Context.bs.js";
import * as StockTransferListPage$Pos from "./StockTransferListPage.bs.js";
import * as StockTransferCreatePage$Pos from "./StockTransferCreatePage.bs.js";

var baseRoute = "/stocktransfer";

var createRoute = baseRoute + "/create";

function StockTransferRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var tmp;
  var exit = 0;
  if (subUrlPath) {
    if (subUrlPath.hd === "create" && !subUrlPath.tl) {
      tmp = React.createElement(StockTransferCreatePage$Pos.make, {});
    } else {
      exit = 1;
    }
  } else {
    tmp = React.createElement(StockTransferListPage$Pos.make, {
          stockTransferCreateRoute: createRoute
        });
  }
  if (exit === 1) {
    tmp = React.createElement(Navigation$Pos.Redirect.make, {
          route: baseRoute
        });
  }
  return React.createElement(Notifier__Context$Pos.Provider.make, Notifier__Context$Pos.Provider.makeProps(Notifier$Pos.createContext(undefined), tmp, undefined));
}

var make = StockTransferRouter;

export {
  baseRoute ,
  createRoute ,
  make ,
}
/* react Not a pure module */
