// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as CartProduct$Pos from "../Cart/CartProduct.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var TaxValueNotFound = /* @__PURE__ */Caml_exceptions.create("OrderProductFormAutoPicker-Pos.TaxValueNotFound");

var Raw = {};

var query = Client.gql(["query MissingStockVariantsQuery($after: String, $supplierId: String!)  {\nmissingStockVariants(first: 50, after: $after, filterBy: {supplierId: {_equals: $supplierId}})  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedName  \nformattedDescription  \nstockKeepingUnit  \npurchasedPrice  \npackaging  \nbulk  \ncapacityPrecision  \nmaxStockThreshold  \nstock  {\n__typename  \nrawQuantity  \n}\n\nproduct  {\n__typename  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.missingStockVariants;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          missingStockVariants: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.stockKeepingUnit;
                    var value$3 = value$1.purchasedPrice;
                    var value$4 = value$1.packaging;
                    var value$5 = value$1.bulk;
                    var value$6 = value$1.capacityPrecision;
                    var value$7 = value$1.maxStockThreshold;
                    var value$8 = value$1.stock;
                    var value$9 = value$8.rawQuantity;
                    var value$10 = value$1.product;
                    var value$11 = value$10.tax;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              formattedName: value$1.formattedName,
                              formattedDescription: value$1.formattedDescription,
                              stockKeepingUnit: !(value$2 == null) ? value$2 : undefined,
                              purchasedPrice: !(value$3 == null) ? value$3 : undefined,
                              packaging: !(value$4 == null) ? value$4 : undefined,
                              bulk: !(value$5 == null) ? value$5 : undefined,
                              capacityPrecision: !(value$6 == null) ? value$6 : undefined,
                              maxStockThreshold: !(value$7 == null) ? value$7 : undefined,
                              stock: {
                                __typename: value$8.__typename,
                                rawQuantity: !(value$9 == null) ? value$9 : undefined
                              },
                              product: {
                                __typename: value$10.__typename,
                                tax: {
                                  __typename: value$11.__typename,
                                  value: value$11.value
                                }
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.missingStockVariants;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.product;
          var value$3 = value$2.tax;
          var value$4 = value$3.value;
          var value$5 = value$3.__typename;
          var tax = {
            __typename: value$5,
            value: value$4
          };
          var value$6 = value$2.__typename;
          var product = {
            __typename: value$6,
            tax: tax
          };
          var value$7 = value$1.stock;
          var value$8 = value$7.rawQuantity;
          var rawQuantity = value$8 !== undefined ? value$8 : null;
          var value$9 = value$7.__typename;
          var stock = {
            __typename: value$9,
            rawQuantity: rawQuantity
          };
          var value$10 = value$1.maxStockThreshold;
          var maxStockThreshold = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.capacityPrecision;
          var capacityPrecision = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.bulk;
          var bulk = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.packaging;
          var packaging = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.purchasedPrice;
          var purchasedPrice = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.formattedDescription;
          var value$17 = value$1.formattedName;
          var value$18 = value$1.id;
          var value$19 = value$1.__typename;
          var node = {
            __typename: value$19,
            id: value$18,
            formattedName: value$17,
            formattedDescription: value$16,
            stockKeepingUnit: stockKeepingUnit,
            purchasedPrice: purchasedPrice,
            packaging: packaging,
            bulk: bulk,
            capacityPrecision: capacityPrecision,
            maxStockThreshold: maxStockThreshold,
            stock: stock,
            product: product
          };
          var value$20 = value.__typename;
          return {
                  __typename: value$20,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var missingStockVariants = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          missingStockVariants: missingStockVariants
        };
}

function serializeVariables(inp) {
  var a = inp.after;
  return {
          after: a !== undefined ? a : undefined,
          supplierId: inp.supplierId
        };
}

function makeVariables(after, supplierId, param) {
  return {
          after: after,
          supplierId: supplierId
        };
}

var MissingStockVariantsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var MissingStockVariantsQuery_refetchQueryDescription = include.refetchQueryDescription;

var MissingStockVariantsQuery_use = include.use;

var MissingStockVariantsQuery_useLazy = include.useLazy;

var MissingStockVariantsQuery_useLazyWithVariables = include.useLazyWithVariables;

var MissingStockVariantsQuery = {
  MissingStockVariantsQuery_inner: MissingStockVariantsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: MissingStockVariantsQuery_refetchQueryDescription,
  use: MissingStockVariantsQuery_use,
  useLazy: MissingStockVariantsQuery_useLazy,
  useLazyWithVariables: MissingStockVariantsQuery_useLazyWithVariables
};

function runScanMissingStockVariants(apolloClient, supplierId, cursor, dataOpt, onDone, param) {
  var data = dataOpt !== undefined ? dataOpt : [];
  Future.get(FuturePromise.fromPromise(Curry._6(apolloClient.rescript_query, {
                query: query,
                Raw: Raw,
                parse: parse,
                serialize: serialize,
                serializeVariables: serializeVariables
              }, undefined, undefined, /* NetworkOnly */2, undefined, {
                after: cursor,
                supplierId: supplierId
              })), (function (response) {
          if (response.TAG !== /* Ok */0) {
            return Curry._2(onDone, /* Error */2, []);
          }
          var match = response._0;
          if (match.TAG !== /* Ok */0) {
            return Curry._2(onDone, /* Error */2, []);
          }
          var match$1 = match._0;
          var match$2 = match$1.data.missingStockVariants;
          var match$3 = match$2.pageInfo;
          var match$4 = match$3.hasNextPage;
          if (match$4 !== undefined && match$4) {
            if (match$1.error !== undefined) {
              return Curry._2(onDone, /* Error */2, []);
            } else {
              return runScanMissingStockVariants(apolloClient, supplierId, match$3.endCursor, Belt_Array.concat(data, match$2.edges), onDone, undefined);
            }
          }
          if (match$1.error !== undefined) {
            return Curry._2(onDone, /* Error */2, []);
          }
          var allVariants = Belt_Array.keep(Belt_Array.concat(data, match$2.edges), (function (param) {
                  return Belt_Option.getWithDefault(param.node.bulk, false) === false;
                }));
          Curry._2(onDone, /* Success */{
                _0: allVariants.length
              }, allVariants);
        }));
}

function OrderProductFormAutoPicker(Props) {
  var status = Props.status;
  var orderId = Props.orderId;
  var supplierId = Props.supplierId;
  var supplierName = Props.supplierName;
  var onQueryStatusChange = Props.onQueryStatusChange;
  var onPickProducts = Props.onPickProducts;
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setOpened = match[1];
  var opened = match[0];
  React.useEffect((function () {
          if (opened || orderId !== undefined || !(supplierName !== undefined && supplierId !== "")) {
            
          } else {
            Curry._1(setOpened, (function (param) {
                    return true;
                  }));
          }
        }), [
        supplierId,
        supplierName
      ]);
  React.useEffect((function () {
          if (status === /* Loading */1) {
            runScanMissingStockVariants(apolloClient, supplierId, undefined, undefined, (function (status, data) {
                    Curry._1(onQueryStatusChange, status);
                    Curry._1(onPickProducts, Belt_Array.map(data, (function (param) {
                                var variant = param.node;
                                var rawQuantity = CartProduct$Pos.makeQuantity(variant.maxStockThreshold, variant.stock.rawQuantity, variant.packaging, variant.capacityPrecision, undefined);
                                var description = variant.formattedDescription;
                                var tmp = description === "" ? Intl$Pos.t("Description not filled in") : description;
                                return CartProduct$Pos.makeProductInput(variant.id, variant.formattedName, tmp, variant.stockKeepingUnit, variant.stock.rawQuantity, variant.packaging, variant.product.tax.value, variant.purchasedPrice, rawQuantity, undefined, undefined, undefined);
                              })));
                  }), undefined);
          }
          
        }), [status]);
  return null;
}

var make = React.memo(OrderProductFormAutoPicker, (function (oldProps, newProps) {
        if (oldProps.status === newProps.status && oldProps.supplierId === newProps.supplierId) {
          return oldProps.supplierName === newProps.supplierName;
        } else {
          return false;
        }
      }));

export {
  TaxValueNotFound ,
  MissingStockVariantsQuery ,
  runScanMissingStockVariants ,
  make ,
}
/* query Not a pure module */
