// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Style$Pos from "./Style.bs.js";
import * as ReactNative from "react-native";

function uriSource(uri) {
  return {
          uri: uri
        };
}

function fromUriSource(uriSource) {
  return uriSource;
}

function $$Image(Props) {
  var style = Props.style;
  var source = Props.source;
  return React.createElement(ReactNative.Image, {
              source: source,
              style: Style$Pos.arrayOptionStyle([style])
            });
}

var make = React.memo($$Image);

make.displayName = "Image";

export {
  uriSource ,
  fromUriSource ,
  make ,
}
/* make Not a pure module */
