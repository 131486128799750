// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function parseExn(prim) {
  return JSON.parse(prim);
}

function stringify(prim) {
  return JSON.stringify(prim);
}

function stringifyAny(prim) {
  return JSON.stringify(prim);
}

function decodeDictOptionalField(dict, name, decode) {
  return Belt_Option.flatMap(Js_dict.get(dict, name), decode);
}

function decodeDictFieldExn(dict, name, decode) {
  return Belt_Option.getExn(decodeDictOptionalField(dict, name, decode));
}

function flatDecodeDictField(dict, name, decode) {
  return Belt_Option.flatMap(Belt_Option.flatMap(dict, (function (dict) {
                    return Js_dict.get(dict, name);
                  })), decode);
}

function flatDecodeDictFieldBoolean(dict, name) {
  return flatDecodeDictField(dict, name, Js_json.decodeBoolean);
}

function flatDecodeDictFieldString(dict, name) {
  return flatDecodeDictField(dict, name, Js_json.decodeString);
}

function flatDecodeDictFieldDict(dict, name) {
  return flatDecodeDictField(dict, name, Js_json.decodeObject);
}

function flatDecodeDictFieldArray(dict, name) {
  return flatDecodeDictField(dict, name, Js_json.decodeArray);
}

function flatDecodeDictFieldFloat(dict, name) {
  return flatDecodeDictField(dict, name, Js_json.decodeNumber);
}

function encodeArray(prim) {
  return prim;
}

function encodeString(prim) {
  return prim;
}

function encodeDict(prim) {
  return prim;
}

function encodeBoolean(prim) {
  return prim;
}

var encodedNull = null;

function encodeNumber(prim) {
  return prim;
}

function fromObjExn(obj) {
  return JSON.parse(Belt_Option.getExn(JSON.stringify(obj)));
}

var decodeDict = Js_json.decodeObject;

var decodeString = Js_json.decodeString;

var decodeArray = Js_json.decodeArray;

var decodeBoolean = Js_json.decodeBoolean;

var decodeNumber = Js_json.decodeNumber;

var decodeNull = Js_json.decodeNull;

export {
  decodeDict ,
  decodeString ,
  decodeArray ,
  decodeBoolean ,
  decodeNumber ,
  decodeNull ,
  decodeDictOptionalField ,
  decodeDictFieldExn ,
  flatDecodeDictField ,
  flatDecodeDictFieldBoolean ,
  flatDecodeDictFieldString ,
  flatDecodeDictFieldDict ,
  flatDecodeDictFieldArray ,
  flatDecodeDictFieldFloat ,
  encodeArray ,
  encodeString ,
  encodeDict ,
  encodeBoolean ,
  encodedNull ,
  encodeNumber ,
  parseExn ,
  stringify ,
  stringifyAny ,
  fromObjExn ,
}
/* encodedNull Not a pure module */
