// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Menu$Pos from "../../resources/navigation/Menu.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as MenuItem$Pos from "../../resources/navigation/MenuItem.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as CatalogVariantMutations$Pos from "./CatalogVariantMutations.bs.js";
import * as CatalogVariantArchiveModal$Pos from "./CatalogVariantArchiveModal.bs.js";

function CatalogTableActions(Props) {
  var cku = Props.cku;
  var id = Props.id;
  var status = Props.status;
  var scope = Auth$Pos.useScope(undefined);
  var match = Navigation$Pos.useUrl(undefined);
  var pathname = match.pathname;
  var match$1 = React.useState(function () {
        return false;
      });
  var setArchiveModalOpened = match$1[1];
  var unarchiveVariant = CatalogVariantMutations$Pos.useUnarchive(id);
  var fromCatalogPage = pathname === LegacyRouter$Pos.routeToPathname(/* Catalog */2);
  var tmp;
  tmp = scope.TAG === /* Organisation */0 ? React.createElement(MenuItem$Pos.make, {
          content: {
            TAG: /* Text */0,
            _0: Intl$Pos.t("Duplicate")
          },
          action: {
            TAG: /* OpenLink */1,
            _0: {
              TAG: /* Route */0,
              _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + ("/duplication/" + cku + "")
            }
          }
        }) : null;
  var tmp$1;
  var exit = 0;
  var exit$1 = 0;
  if (fromCatalogPage && scope.TAG === /* Organisation */0 && scope.activeShop === undefined) {
    tmp$1 = null;
  } else {
    exit$1 = 2;
  }
  if (exit$1 === 2) {
    if (status === 2) {
      tmp$1 = React.createElement(MenuItem$Pos.make, {
            content: {
              TAG: /* Text */0,
              _0: Intl$Pos.t("Unarchive")
            },
            action: {
              TAG: /* Callback */0,
              _0: (function (param) {
                  Curry._1(unarchiveVariant, undefined);
                })
            }
          });
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp$1 = React.createElement(MenuItem$Pos.make, {
          content: {
            TAG: /* Text */0,
            _0: Intl$Pos.t("Archive")
          },
          action: {
            TAG: /* Callback */0,
            _0: (function (param) {
                Curry._1(setArchiveModalOpened, (function (param) {
                        return true;
                      }));
              })
          }
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Inline$Pos.make, {
                  children: React.createElement(Menu$Pos.make, {
                        children: null,
                        variation: "more"
                      }, React.createElement(MenuItem$Pos.make, {
                            content: {
                              TAG: /* Text */0,
                              _0: Intl$Pos.t("See")
                            },
                            action: {
                              TAG: /* OpenLink */1,
                              _0: {
                                TAG: /* Route */0,
                                _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + ("/" + cku + "")
                              }
                            }
                          }), React.createElement(MenuItem$Pos.make, {
                            content: {
                              TAG: /* Text */0,
                              _0: Intl$Pos.t("Edit")
                            },
                            action: {
                              TAG: /* OpenLink */1,
                              _0: {
                                TAG: /* Route */0,
                                _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + ("/" + cku + "/edit")
                              }
                            }
                          }), pathname.includes("/product") ? null : React.createElement(MenuItem$Pos.make, {
                              content: {
                                TAG: /* Text */0,
                                _0: Intl$Pos.t("See bound product")
                              },
                              action: {
                                TAG: /* OpenLink */1,
                                _0: {
                                  TAG: /* Route */0,
                                  _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + ("/product/redirect/" + cku + "")
                                }
                              }
                            }), pathname.endsWith("/catalog") ? React.createElement(MenuItem$Pos.make, {
                              content: {
                                TAG: /* Text */0,
                                _0: Intl$Pos.t("Create new variant")
                              },
                              action: {
                                TAG: /* OpenLink */1,
                                _0: {
                                  TAG: /* Route */0,
                                  _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2) + ("/create/redirect/" + cku + "")
                                }
                              }
                            }) : null, tmp, tmp$1),
                  align: "end"
                }), React.createElement(CatalogVariantArchiveModal$Pos.make, {
                  id: id,
                  opened: match$1[0],
                  onRequestClose: (function (param) {
                      Curry._1(setArchiveModalOpened, (function (param) {
                              return false;
                            }));
                    })
                }));
}

var make = React.memo(CatalogTableActions);

export {
  make ,
}
/* make Not a pure module */
