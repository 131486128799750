// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as SpinnerModal$Pos from "../feedback-indicators/SpinnerModal.bs.js";
import * as RequestMenuItem$Pos from "./RequestMenuItem.bs.js";
import * as TriggerDownload$Pos from "../../primitives/TriggerDownload.bs.js";

function failureErrorToString(error, exportName) {
  if (error) {
    return Intl$Pos.template(Intl$Pos.t("An issue when attempting downloading {{export}} occurred."), {
                export: Intl$Pos.t(exportName)
              }, undefined) + " " + Request$Pos.serverErrorMessage;
  } else {
    return Intl$Pos.t("An issue when downloading the file occurred with this navigator.");
  }
}

function decodeAndMakeUrl(json) {
  return Belt_Option.map(Belt_Option.orElse(Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "url"), Json$Pos.flatDecodeDictFieldString(Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "file", Json$Pos.decodeDict), "url")), (function (prim) {
                return new URL(prim);
              }));
}

function RequestOpenStorageUrlMenuItem(Props) {
  var text = Props.text;
  var textErrorOpt = Props.textError;
  var operableRequest = Props.operableRequest;
  var onSuccess = Props.onSuccess;
  var onFailure = Props.onFailure;
  var textError = textErrorOpt !== undefined ? textErrorOpt : Intl$Pos.t("Download failed");
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var onChange = React.useCallback((function (asyncResult) {
          Curry._1(setLoading, (function (param) {
                  return AsyncResult$Pos.isBusy(asyncResult);
                }));
          if (typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1) {
            var json = asyncResult._0;
            if (json.TAG === /* Ok */0) {
              var fileUrl = decodeAndMakeUrl(json._0);
              if (fileUrl !== undefined) {
                var fileUrl$1 = Caml_option.valFromOption(fileUrl);
                Future.tapError(Future.tapOk(TriggerDownload$Pos.fromUrl(undefined, fileUrl$1), (function (param) {
                            Curry._1(onSuccess, Caml_option.some(fileUrl$1));
                          })), (function (param) {
                        Curry._1(onFailure, /* LinkingOpenUrlError */0);
                      }));
              } else {
                Curry._1(onSuccess, undefined);
              }
            } else {
              Curry._1(onFailure, /* RequestError */{
                    _0: json._0
                  });
            }
          }
          
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(SpinnerModal$Pos.make, {
                  title: text,
                  opened: match[0]
                }), React.createElement(RequestMenuItem$Pos.make, {
                  text: text,
                  textError: textError,
                  operableRequest: operableRequest,
                  onChange: onChange
                }));
}

var make = React.memo(RequestOpenStorageUrlMenuItem);

export {
  failureErrorToString ,
  decodeAndMakeUrl ,
  make ,
}
/* make Not a pure module */
