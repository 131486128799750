// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Menu$Pos from "../../resources/navigation/Menu.bs.js";
import * as MenuItem$Pos from "../../resources/navigation/MenuItem.bs.js";
import * as OrderArchiveModal$Pos from "./OrderArchiveModal.bs.js";

function OrderEditActionsList(Props) {
  var id = Props.id;
  var statuses = Props.statuses;
  var onRequestAction = Props.onRequestAction;
  var match = React.useState(function () {
        return false;
      });
  var setArchivingModalOpened = match[1];
  var tmp;
  var exit = 0;
  var len = statuses.length;
  if (len >= 3) {
    tmp = null;
  } else {
    switch (len) {
      case 0 :
          tmp = null;
          break;
      case 1 :
          var match$1 = statuses[0];
          if (match$1 === "ARCHIVED") {
            tmp = React.createElement(Menu$Pos.make, {
                  children: null
                }, React.createElement(MenuItem$Pos.make, {
                      content: {
                        TAG: /* Text */0,
                        _0: Intl$Pos.t("Download PDF")
                      },
                      action: {
                        TAG: /* Callback */0,
                        _0: (function (param) {
                            Curry._1(onRequestAction, /* DownloadPDF */1);
                          })
                      }
                    }), React.createElement(MenuItem$Pos.make, {
                      content: {
                        TAG: /* Text */0,
                        _0: Intl$Pos.t("Download CSV")
                      },
                      action: {
                        TAG: /* Callback */0,
                        _0: (function (param) {
                            Curry._1(onRequestAction, /* DownloadCSV */2);
                          })
                      }
                    }));
          } else if (match$1 === "DRAFT") {
            tmp = React.createElement(Menu$Pos.make, {
                  children: null
                }, React.createElement(MenuItem$Pos.make, {
                      content: {
                        TAG: /* Text */0,
                        _0: Intl$Pos.t("Download PDF")
                      },
                      action: {
                        TAG: /* Callback */0,
                        _0: (function (param) {
                            Curry._1(onRequestAction, /* DownloadPDF */1);
                          })
                      }
                    }), React.createElement(MenuItem$Pos.make, {
                      content: {
                        TAG: /* Text */0,
                        _0: Intl$Pos.t("Download CSV")
                      },
                      action: {
                        TAG: /* Callback */0,
                        _0: (function (param) {
                            Curry._1(onRequestAction, /* DownloadCSV */2);
                          })
                      }
                    }), React.createElement(MenuItem$Pos.make, {
                      content: {
                        TAG: /* Text */0,
                        _0: Intl$Pos.t("Archive")
                      },
                      action: {
                        TAG: /* Callback */0,
                        _0: (function (param) {
                            Curry._1(setArchivingModalOpened, (function (param) {
                                    return true;
                                  }));
                          })
                      }
                    }));
          } else if (match$1 === "FINALIZED") {
            exit = 1;
          } else {
            tmp = match$1 === "RECEIVING" ? React.createElement(Menu$Pos.make, {
                    children: null
                  }, React.createElement(MenuItem$Pos.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("Continue reception")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(onRequestAction, /* Edition */0);
                            })
                        }
                      }), React.createElement(MenuItem$Pos.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("Download PDF")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(onRequestAction, /* DownloadPDF */1);
                            })
                        }
                      }), React.createElement(MenuItem$Pos.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("Download CSV")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(onRequestAction, /* DownloadCSV */2);
                            })
                        }
                      }), React.createElement(MenuItem$Pos.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("Archive")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(setArchivingModalOpened, (function (param) {
                                      return true;
                                    }));
                            })
                        }
                      })) : null;
          }
          break;
      case 2 :
          var match$2 = statuses[0];
          if (match$2 === "ACCEPTED") {
            var match$3 = statuses[1];
            if (match$3 === "NOT_RECEIVED") {
              exit = 1;
            } else {
              tmp = null;
            }
          } else if (match$2 === "RECEIVED") {
            var match$4 = statuses[1];
            tmp = match$4 === "TO_PAY" ? React.createElement(Menu$Pos.make, {
                    children: null
                  }, React.createElement(MenuItem$Pos.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("Edit order prices")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(onRequestAction, /* Edition */0);
                            })
                        }
                      }), React.createElement(MenuItem$Pos.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("Download PDF")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(onRequestAction, /* DownloadPDF */1);
                            })
                        }
                      }), React.createElement(MenuItem$Pos.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("Download CSV")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(onRequestAction, /* DownloadCSV */2);
                            })
                        }
                      }), React.createElement(MenuItem$Pos.make, {
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t("Archive")
                        },
                        action: {
                          TAG: /* Callback */0,
                          _0: (function (param) {
                              Curry._1(setArchivingModalOpened, (function (param) {
                                      return true;
                                    }));
                            })
                        }
                      })) : (
                match$4 === "PAID" ? React.createElement(Menu$Pos.make, {
                        children: null
                      }, React.createElement(MenuItem$Pos.make, {
                            content: {
                              TAG: /* Text */0,
                              _0: Intl$Pos.t("Download PDF")
                            },
                            action: {
                              TAG: /* Callback */0,
                              _0: (function (param) {
                                  Curry._1(onRequestAction, /* DownloadPDF */1);
                                })
                            }
                          }), React.createElement(MenuItem$Pos.make, {
                            content: {
                              TAG: /* Text */0,
                              _0: Intl$Pos.t("Download CSV")
                            },
                            action: {
                              TAG: /* Callback */0,
                              _0: (function (param) {
                                  Curry._1(onRequestAction, /* DownloadCSV */2);
                                })
                            }
                          }), React.createElement(MenuItem$Pos.make, {
                            content: {
                              TAG: /* Text */0,
                              _0: Intl$Pos.t("Archive")
                            },
                            action: {
                              TAG: /* Callback */0,
                              _0: (function (param) {
                                  Curry._1(setArchivingModalOpened, (function (param) {
                                          return true;
                                        }));
                                })
                            }
                          })) : null
              );
          } else {
            tmp = null;
          }
          break;
      
    }
  }
  if (exit === 1) {
    tmp = React.createElement(Menu$Pos.make, {
          children: null
        }, React.createElement(MenuItem$Pos.make, {
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Edit order")
              },
              action: {
                TAG: /* Callback */0,
                _0: (function (param) {
                    Curry._1(onRequestAction, /* Edition */0);
                  })
              }
            }), React.createElement(MenuItem$Pos.make, {
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Download PDF")
              },
              action: {
                TAG: /* Callback */0,
                _0: (function (param) {
                    Curry._1(onRequestAction, /* DownloadPDF */1);
                  })
              }
            }), React.createElement(MenuItem$Pos.make, {
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Download CSV")
              },
              action: {
                TAG: /* Callback */0,
                _0: (function (param) {
                    Curry._1(onRequestAction, /* DownloadCSV */2);
                  })
              }
            }), React.createElement(MenuItem$Pos.make, {
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Archive")
              },
              action: {
                TAG: /* Callback */0,
                _0: (function (param) {
                    Curry._1(setArchivingModalOpened, (function (param) {
                            return true;
                          }));
                  })
              }
            }));
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement(OrderArchiveModal$Pos.make, {
                  orderId: id,
                  opened: match[0],
                  onRequestClose: (function (param) {
                      Curry._1(setArchivingModalOpened, (function (param) {
                              return false;
                            }));
                    })
                }));
}

var make = React.memo(OrderEditActionsList, (function (oldProps, newProps) {
        if (oldProps.id === newProps.id) {
          return oldProps.statuses === newProps.statuses;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
