// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as CatalogProduct__Queries$Pos from "./CatalogProduct__Queries.bs.js";

function makeFilterBy(activeShopId) {
  return CatalogProduct__Queries$Pos.ProductQuery.makeInputObjectInputProductsByVariantCkuQueryFilter(activeShopId !== undefined ? CatalogProduct__Queries$Pos.ProductQuery.makeInputObjectInFilter([activeShopId], undefined) : undefined, "INCLUDED", undefined);
}

function formatValueFromVariantPrice(param) {
  var variantPrice = param.node;
  var match = Belt_Option.map(variantPrice.price, (function (price) {
          return price.taxIncluded;
        }));
  var price = match !== undefined ? (
      match ? variantPrice.valueIncludingTax : variantPrice.valueExcludingTax
    ) : undefined;
  return Belt_Option.mapWithDefault(price, "-", (function (price) {
                return Intl$Pos.currencyFormat("EUR", undefined, undefined, price);
              }));
}

function retailPriceFromVariantPrices(prices) {
  return Belt_Option.mapWithDefault(Belt_Array.getBy(prices, (function (param) {
                    return Belt_Option.mapWithDefault(param.node.price, false, (function (price) {
                                  return price.enableByDefault;
                                }));
                  })), "-", formatValueFromVariantPrice);
}

function productsInformationFromData(data) {
  return Belt_Array.map(data.productsByVariantCku.edges, (function (param) {
                var data = param.node;
                return {
                        id: data.id,
                        status: Belt_Option.map(data.formattedStatus, (function (status) {
                                if (status === "ARCHIVED") {
                                  return /* Archived */2;
                                } else if (status === "ACTIVE") {
                                  return /* Active */0;
                                } else {
                                  return /* Inactive */1;
                                }
                              })),
                        name: data.name,
                        description: data.formattedDescription,
                        formattedOrigin: data.formattedOrigin,
                        taxValue: data.tax.value,
                        categoryId: Belt_Option.map(data.category, (function (param) {
                                return param.id;
                              })),
                        formattedCategoryName: Belt_Option.mapWithDefault(data.category, Intl$Pos.t("Not classified"), (function (param) {
                                return param.formattedName;
                              })),
                        shopId: data.shop.id,
                        shopName: data.shop.name,
                        internalNote: Belt_Option.getWithDefault(data.internalNote, "")
                      };
              }));
}

function productsVariantsFromData(data) {
  return Belt_Array.map(data.productsByVariantCku.edges, (function (param) {
                return Belt_Array.map(Belt_SortArray.stableSortBy(param.node.variants.edges, (function (param, param$1) {
                                  return Js_string.localeCompare(param$1.node.name, param.node.name) | 0;
                                })), (function (param) {
                              var variant = param.node;
                              var match = variant.formattedStatus;
                              var match$1 = variant.capacityUnit;
                              var match$2 = Belt_Option.getWithDefault(variant.bulk, false);
                              return {
                                      cku: variant.cku,
                                      id: variant.id,
                                      shopName: variant.shop.name,
                                      status: match === "INACTIVE" ? /* Inactive */1 : (
                                          match === "ARCHIVED" ? /* Archived */2 : (
                                              match === "ACTIVE" ? /* Active */0 : undefined
                                            )
                                        ),
                                      name: variant.name,
                                      formattedPurchasePrice: variant.formattedPurchasedPrice,
                                      purchasePrice: Belt_Option.getWithDefault(variant.purchasedPrice, 0),
                                      formattedRetailPrice: retailPriceFromVariantPrices(variant.variantPrices.edges),
                                      retailPrices: Belt_Array.keepMap(variant.variantPrices.edges, (function (variantPrice) {
                                              var price = variantPrice.node.price;
                                              if (price !== undefined) {
                                                return {
                                                        name: price.name,
                                                        formattedValue: formatValueFromVariantPrice(variantPrice),
                                                        taxIncluded: price.taxIncluded,
                                                        default: price.enableByDefault
                                                      };
                                              }
                                              
                                            })),
                                      bulkUnit: match$1 !== undefined && match$2 ? match$1 : undefined,
                                      stock: {
                                        quantity: variant.stock.formattedQuantity,
                                        state: variant.stock.state
                                      },
                                      maxStockThreshold: Belt_Option.getWithDefault(variant.maxStockThreshold, 0),
                                      minStockThreshold: Belt_Option.getWithDefault(variant.minStockThreshold, 0),
                                      stockOrderTriggerThreshold: Belt_Option.getWithDefault(variant.stockOrderTriggerThreshold, 0),
                                      bulk: Belt_Option.getWithDefault(variant.bulk, false)
                                    };
                            }));
              }));
}

var Query;

export {
  Query ,
  makeFilterBy ,
  formatValueFromVariantPrice ,
  retailPriceFromVariantPrices ,
  productsInformationFromData ,
  productsVariantsFromData ,
}
/* Intl-Pos Not a pure module */
