// Generated by ReScript, PLEASE EDIT WITH CARE


var DayPickerSingleDateController = {};

var DayPickerRangeController = {
  defaultFocusedInput: "startDate"
};

export {
  DayPickerSingleDateController ,
  DayPickerRangeController ,
}
/* No side effect */
