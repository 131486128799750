// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Group$Pos from "../../resources/layout-and-structure/Group.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as CatalogProductEditForm$Pos from "./CatalogProductEditForm.bs.js";
import * as CatalogProductEditFormInputs$Pos from "./CatalogProductEditFormInputs.bs.js";

function CatalogProductEditFormInformationFieldsetPanel(Props) {
  var state = Curry._1(CatalogProductEditForm$Pos.useFormState, undefined);
  var productKind = state.initialValues.kind;
  var match = state.values.color;
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("General information"),
              description: Intl$Pos.t("Complete the product information.\nThe information of this reference will be later enriched by the information of the variant."),
              children: null
            }, React.createElement(CatalogProductEditForm$Pos.InputText.make, {
                  field: /* Name */0,
                  label: Intl$Pos.t("Product's name"),
                  placeholder: Intl$Pos.t("Product's name")
                }), productKind === "WINE" || productKind === "BEER" || productKind === "SPIRITUOUS" ? React.createElement(Group$Pos.make, {
                    children: null
                  }, productKind === "SPIRITUOUS" ? React.createElement(CatalogProductEditFormInputs$Pos.ProductFamilyComboBox.make, {}) : (
                      productKind === "WINE" || productKind === "BEER" ? React.createElement(CatalogProductEditFormInputs$Pos.ColorSelect.make, {}) : null
                    ), productKind === "BEER" ? React.createElement(CatalogProductEditFormInputs$Pos.ProductBeerTypeComboBox.make, {}) : (
                      productKind === "WINE" ? React.createElement(CatalogProductEditFormInputs$Pos.WineTypeSelect.make, {}) : null
                    )) : null, productKind === "WINE" && match === "WHITE" ? React.createElement(CatalogProductEditFormInputs$Pos.WhiteWineTypeSelect.make, {}) : null, productKind === "WINE" ? React.createElement(Group$Pos.make, {
                    children: null
                  }, React.createElement(CatalogProductEditFormInputs$Pos.ProductProducerComboBox.make, {}), React.createElement(CatalogProductEditFormInputs$Pos.ProductDesignationComboBox.make, {})) : null, React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(CatalogProductEditFormInputs$Pos.ProductCountrySelect.make, {}), productKind === "WINE" ? React.createElement(CatalogProductEditFormInputs$Pos.ProductRegionComboBox.make, {}) : React.createElement(CatalogProductEditFormInputs$Pos.ProductProducerComboBox.make, {})), React.createElement(CatalogProductEditFormInputs$Pos.ProductTaxSelect.make, {
                  productKind: productKind
                }));
}

var make = React.memo(CatalogProductEditFormInformationFieldsetPanel);

export {
  make ,
}
/* make Not a pure module */
