// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Group$Pos from "../../resources/layout-and-structure/Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Client from "@apollo/client";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as SupplierCivility$Pos from "../../modules/Supplier/SupplierCivility.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as ResourceDetailsPage$Pos from "../../core/ResourceDetailsPage.bs.js";
import * as SupplierAddressComboBoxField$Pos from "../../modules/Supplier/SupplierAddressComboBoxField.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation createSupplier($supplierInput: InputCreateSupplier!, $contactsInput: [InputCreateSupplierContact!], $locationsInput: [InputCreateSupplierLocation!])  {\ncreateSupplier(supplierInput: $supplierInput, contactsInput: $contactsInput, locationsInput: $locationsInput)  {\n__typename  \nid  \ncompanyName  \nintraCommunityVat  \nsiretNumber  \nphoneNumber  \nmobileNumber  \nemail  \nnote  \ninternalCode  \ncontacts  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nlastName  \nfirstName  \nposition  \nemail  \nphoneNumber  \nmobileNumber  \nisDefault  \ncivility  \n}\n\n}\n\n}\n\nlocations  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nlabel  \npostalCode  \ncity  \ncountry  \naddress  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.createSupplier;
  var value$2 = value$1.intraCommunityVat;
  var value$3 = value$1.siretNumber;
  var value$4 = value$1.phoneNumber;
  var value$5 = value$1.mobileNumber;
  var value$6 = value$1.email;
  var value$7 = value$1.note;
  var value$8 = value$1.internalCode;
  var value$9 = value$1.contacts;
  var value$10 = value$9.edges;
  var value$11 = value$1.locations;
  var value$12 = value$11.edges;
  return {
          createSupplier: {
            __typename: value$1.__typename,
            id: value$1.id,
            companyName: value$1.companyName,
            intraCommunityVat: !(value$2 == null) ? value$2 : undefined,
            siretNumber: !(value$3 == null) ? value$3 : undefined,
            phoneNumber: !(value$4 == null) ? value$4 : undefined,
            mobileNumber: !(value$5 == null) ? value$5 : undefined,
            email: !(value$6 == null) ? value$6 : undefined,
            note: !(value$7 == null) ? Scalar$Pos.$$Text.parse(value$7) : undefined,
            internalCode: !(value$8 == null) ? value$8 : undefined,
            contacts: {
              __typename: value$9.__typename,
              edges: Js_array.map((function (value) {
                      var value$1 = value.node;
                      var value$2 = value$1.firstName;
                      var value$3 = value$1.position;
                      var value$4 = value$1.email;
                      var value$5 = value$1.phoneNumber;
                      var value$6 = value$1.mobileNumber;
                      var value$7 = value$1.civility;
                      var tmp;
                      if (value$7 == null) {
                        tmp = undefined;
                      } else {
                        var tmp$1;
                        switch (value$7) {
                          case "MR" :
                              tmp$1 = "MR";
                              break;
                          case "MRS" :
                              tmp$1 = "MRS";
                              break;
                          case "NEUTRAL" :
                              tmp$1 = "NEUTRAL";
                              break;
                          default:
                            tmp$1 = {
                              NAME: "FutureAddedValue",
                              VAL: value$7
                            };
                        }
                        tmp = tmp$1;
                      }
                      return {
                              __typename: value.__typename,
                              node: {
                                __typename: value$1.__typename,
                                lastName: value$1.lastName,
                                firstName: !(value$2 == null) ? value$2 : undefined,
                                position: !(value$3 == null) ? value$3 : undefined,
                                email: !(value$4 == null) ? value$4 : undefined,
                                phoneNumber: !(value$5 == null) ? value$5 : undefined,
                                mobileNumber: !(value$6 == null) ? value$6 : undefined,
                                isDefault: value$1.isDefault,
                                civility: tmp
                              }
                            };
                    }), value$10)
            },
            locations: {
              __typename: value$11.__typename,
              edges: Js_array.map((function (value) {
                      var value$1 = value.node;
                      var value$2 = value$1.postalCode;
                      var value$3 = value$1.city;
                      var value$4 = value$1.country;
                      var value$5 = value$1.address;
                      return {
                              __typename: value.__typename,
                              node: {
                                __typename: value$1.__typename,
                                label: value$1.label,
                                postalCode: !(value$2 == null) ? value$2 : undefined,
                                city: !(value$3 == null) ? value$3 : undefined,
                                country: !(value$4 == null) ? value$4 : undefined,
                                address: !(value$5 == null) ? value$5 : undefined
                              }
                            };
                    }), value$12)
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.createSupplier;
  var value$2 = value$1.locations;
  var value$3 = value$2.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.address;
          var address = value$2 !== undefined ? value$2 : null;
          var value$3 = value$1.country;
          var country = value$3 !== undefined ? value$3 : null;
          var value$4 = value$1.city;
          var city = value$4 !== undefined ? value$4 : null;
          var value$5 = value$1.postalCode;
          var postalCode = value$5 !== undefined ? value$5 : null;
          var value$6 = value$1.label;
          var value$7 = value$1.__typename;
          var node = {
            __typename: value$7,
            label: value$6,
            postalCode: postalCode,
            city: city,
            country: country,
            address: address
          };
          var value$8 = value.__typename;
          return {
                  __typename: value$8,
                  node: node
                };
        }), value$3);
  var value$4 = value$2.__typename;
  var locations = {
    __typename: value$4,
    edges: edges
  };
  var value$5 = value$1.contacts;
  var value$6 = value$5.edges;
  var edges$1 = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.civility;
          var civility = value$2 !== undefined ? (
              typeof value$2 === "object" ? value$2.VAL : (
                  value$2 === "MR" ? "MR" : (
                      value$2 === "MRS" ? "MRS" : "NEUTRAL"
                    )
                )
            ) : null;
          var value$3 = value$1.isDefault;
          var value$4 = value$1.mobileNumber;
          var mobileNumber = value$4 !== undefined ? value$4 : null;
          var value$5 = value$1.phoneNumber;
          var phoneNumber = value$5 !== undefined ? value$5 : null;
          var value$6 = value$1.email;
          var email = value$6 !== undefined ? value$6 : null;
          var value$7 = value$1.position;
          var position = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.firstName;
          var firstName = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.lastName;
          var value$10 = value$1.__typename;
          var node = {
            __typename: value$10,
            lastName: value$9,
            firstName: firstName,
            position: position,
            email: email,
            phoneNumber: phoneNumber,
            mobileNumber: mobileNumber,
            isDefault: value$3,
            civility: civility
          };
          var value$11 = value.__typename;
          return {
                  __typename: value$11,
                  node: node
                };
        }), value$6);
  var value$7 = value$5.__typename;
  var contacts = {
    __typename: value$7,
    edges: edges$1
  };
  var value$8 = value$1.internalCode;
  var internalCode = value$8 !== undefined ? value$8 : null;
  var value$9 = value$1.note;
  var note = value$9 !== undefined ? Scalar$Pos.$$Text.serialize(value$9) : null;
  var value$10 = value$1.email;
  var email = value$10 !== undefined ? value$10 : null;
  var value$11 = value$1.mobileNumber;
  var mobileNumber = value$11 !== undefined ? value$11 : null;
  var value$12 = value$1.phoneNumber;
  var phoneNumber = value$12 !== undefined ? value$12 : null;
  var value$13 = value$1.siretNumber;
  var siretNumber = value$13 !== undefined ? value$13 : null;
  var value$14 = value$1.intraCommunityVat;
  var intraCommunityVat = value$14 !== undefined ? value$14 : null;
  var value$15 = value$1.companyName;
  var value$16 = value$1.id;
  var value$17 = value$1.__typename;
  var createSupplier = {
    __typename: value$17,
    id: value$16,
    companyName: value$15,
    intraCommunityVat: intraCommunityVat,
    siretNumber: siretNumber,
    phoneNumber: phoneNumber,
    mobileNumber: mobileNumber,
    email: email,
    note: note,
    internalCode: internalCode,
    contacts: contacts,
    locations: locations
  };
  return {
          createSupplier: createSupplier
        };
}

function serializeInputObjectInputCreateSupplierLocation(inp) {
  var a = inp.recipient;
  var a$1 = inp.address;
  var a$2 = inp.postalCode;
  var a$3 = inp.city;
  var a$4 = inp.country;
  var a$5 = inp.defaults;
  return {
          label: inp.label,
          recipient: a !== undefined ? a : undefined,
          address: a$1 !== undefined ? a$1 : undefined,
          postalCode: a$2 !== undefined ? a$2 : undefined,
          city: a$3 !== undefined ? a$3 : undefined,
          country: a$4 !== undefined ? a$4 : undefined,
          defaults: Js_array.map((function (b) {
                  if (b === "DELIVERY") {
                    return "DELIVERY";
                  } else {
                    return "BILLING";
                  }
                }), a$5)
        };
}

function serializeInputObjectInputCreateSupplierContact(inp) {
  var a = inp.firstName;
  var a$1 = inp.position;
  var a$2 = inp.email;
  var a$3 = inp.phoneNumber;
  var a$4 = inp.mobileNumber;
  var a$5 = inp.civility;
  return {
          lastName: inp.lastName,
          firstName: a !== undefined ? a : undefined,
          position: a$1 !== undefined ? a$1 : undefined,
          email: a$2 !== undefined ? a$2 : undefined,
          phoneNumber: a$3 !== undefined ? a$3 : undefined,
          mobileNumber: a$4 !== undefined ? a$4 : undefined,
          isDefault: inp.isDefault,
          civility: a$5 !== undefined ? (
              a$5 === "MR" ? "MR" : (
                  a$5 === "MRS" ? "MRS" : "NEUTRAL"
                )
            ) : undefined
        };
}

function serializeInputObjectInputCreateSupplier(inp) {
  var a = inp.intraCommunityVat;
  var a$1 = inp.siretNumber;
  var a$2 = inp.phoneNumber;
  var a$3 = inp.mobileNumber;
  var a$4 = inp.email;
  var a$5 = inp.note;
  var a$6 = inp.internalCode;
  return {
          companyName: inp.companyName,
          intraCommunityVat: a !== undefined ? a : undefined,
          siretNumber: a$1 !== undefined ? a$1 : undefined,
          phoneNumber: a$2 !== undefined ? a$2 : undefined,
          mobileNumber: a$3 !== undefined ? a$3 : undefined,
          email: a$4 !== undefined ? a$4 : undefined,
          note: a$5 !== undefined ? a$5 : undefined,
          internalCode: a$6 !== undefined ? a$6 : undefined,
          shopId: inp.shopId
        };
}

function serializeVariables(inp) {
  var a = inp.contactsInput;
  var a$1 = inp.locationsInput;
  return {
          supplierInput: serializeInputObjectInputCreateSupplier(inp.supplierInput),
          contactsInput: a !== undefined ? Js_array.map(serializeInputObjectInputCreateSupplierContact, a) : undefined,
          locationsInput: a$1 !== undefined ? Js_array.map(serializeInputObjectInputCreateSupplierLocation, a$1) : undefined
        };
}

function makeVariables(supplierInput, contactsInput, locationsInput, param) {
  return {
          supplierInput: supplierInput,
          contactsInput: contactsInput,
          locationsInput: locationsInput
        };
}

function makeInputObjectInputCreateSupplier(companyName, intraCommunityVat, siretNumber, phoneNumber, mobileNumber, email, note, internalCode, shopId, param) {
  return {
          companyName: companyName,
          intraCommunityVat: intraCommunityVat,
          siretNumber: siretNumber,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          email: email,
          note: note,
          internalCode: internalCode,
          shopId: shopId
        };
}

function makeInputObjectInputCreateSupplierContact(lastName, firstName, position, email, phoneNumber, mobileNumber, isDefault, civility, param) {
  return {
          lastName: lastName,
          firstName: firstName,
          position: position,
          email: email,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          isDefault: isDefault,
          civility: civility
        };
}

function makeInputObjectInputCreateSupplierLocation(label, recipient, address, postalCode, city, country, defaults, param) {
  return {
          label: label,
          recipient: recipient,
          address: address,
          postalCode: postalCode,
          city: city,
          country: country,
          defaults: defaults
        };
}

var CreateSupplierMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateSupplier: serializeInputObjectInputCreateSupplier,
  serializeInputObjectInputCreateSupplierContact: serializeInputObjectInputCreateSupplierContact,
  serializeInputObjectInputCreateSupplierLocation: serializeInputObjectInputCreateSupplierLocation,
  makeVariables: makeVariables,
  makeInputObjectInputCreateSupplier: makeInputObjectInputCreateSupplier,
  makeInputObjectInputCreateSupplierContact: makeInputObjectInputCreateSupplierContact,
  makeInputObjectInputCreateSupplierLocation: makeInputObjectInputCreateSupplierLocation
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CreateSupplierMutation_useWithVariables = include.useWithVariables;

var CreateSupplierMutation = {
  CreateSupplierMutation_inner: CreateSupplierMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateSupplier: serializeInputObjectInputCreateSupplier,
  serializeInputObjectInputCreateSupplierContact: serializeInputObjectInputCreateSupplierContact,
  serializeInputObjectInputCreateSupplierLocation: serializeInputObjectInputCreateSupplierLocation,
  makeVariables: makeVariables,
  makeInputObjectInputCreateSupplier: makeInputObjectInputCreateSupplier,
  makeInputObjectInputCreateSupplierContact: makeInputObjectInputCreateSupplierContact,
  makeInputObjectInputCreateSupplierLocation: makeInputObjectInputCreateSupplierLocation,
  use: use,
  useWithVariables: CreateSupplierMutation_useWithVariables
};

function get(values, field) {
  switch (field) {
    case /* ShopId */0 :
        return values.shopId;
    case /* CompanyName */1 :
        return values.companyName;
    case /* Email */2 :
        return values.email;
    case /* PhoneNumber */3 :
        return values.phoneNumber;
    case /* MobileNumber */4 :
        return values.mobileNumber;
    case /* IntraCommunityVat */5 :
        return values.intraCommunityVat;
    case /* SiretNumber */6 :
        return values.siretNumber;
    case /* InternalCode */7 :
        return values.internalCode;
    case /* Note */8 :
        return values.note;
    case /* ContactFirstName */9 :
        return values.contactFirstName;
    case /* ContactLastName */10 :
        return values.contactLastName;
    case /* ContactCivility */11 :
        return values.contactCivility;
    case /* ContactPosition */12 :
        return values.contactPosition;
    case /* ContactEmail */13 :
        return values.contactEmail;
    case /* ContactPhoneNumber */14 :
        return values.contactPhoneNumber;
    case /* ContactMobileNumber */15 :
        return values.contactMobileNumber;
    case /* LocationLabel */16 :
        return values.locationLabel;
    case /* LocationRecipient */17 :
        return values.locationRecipient;
    case /* LocationAddress */18 :
        return values.locationAddress;
    case /* LocationPostalCode */19 :
        return values.locationPostalCode;
    case /* LocationCity */20 :
        return values.locationCity;
    case /* LocationCountry */21 :
        return values.locationCountry;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* ShopId */0 :
        var newrecord = Caml_obj.obj_dup(values);
        newrecord.shopId = value;
        return newrecord;
    case /* CompanyName */1 :
        var newrecord$1 = Caml_obj.obj_dup(values);
        newrecord$1.companyName = value;
        return newrecord$1;
    case /* Email */2 :
        var newrecord$2 = Caml_obj.obj_dup(values);
        newrecord$2.email = value;
        return newrecord$2;
    case /* PhoneNumber */3 :
        var newrecord$3 = Caml_obj.obj_dup(values);
        newrecord$3.phoneNumber = value;
        return newrecord$3;
    case /* MobileNumber */4 :
        var newrecord$4 = Caml_obj.obj_dup(values);
        newrecord$4.mobileNumber = value;
        return newrecord$4;
    case /* IntraCommunityVat */5 :
        var newrecord$5 = Caml_obj.obj_dup(values);
        newrecord$5.intraCommunityVat = value;
        return newrecord$5;
    case /* SiretNumber */6 :
        var newrecord$6 = Caml_obj.obj_dup(values);
        newrecord$6.siretNumber = value;
        return newrecord$6;
    case /* InternalCode */7 :
        var newrecord$7 = Caml_obj.obj_dup(values);
        newrecord$7.internalCode = value;
        return newrecord$7;
    case /* Note */8 :
        var newrecord$8 = Caml_obj.obj_dup(values);
        newrecord$8.note = value;
        return newrecord$8;
    case /* ContactFirstName */9 :
        var newrecord$9 = Caml_obj.obj_dup(values);
        newrecord$9.contactFirstName = value;
        return newrecord$9;
    case /* ContactLastName */10 :
        var newrecord$10 = Caml_obj.obj_dup(values);
        newrecord$10.contactLastName = value;
        return newrecord$10;
    case /* ContactCivility */11 :
        var newrecord$11 = Caml_obj.obj_dup(values);
        newrecord$11.contactCivility = value;
        return newrecord$11;
    case /* ContactPosition */12 :
        var newrecord$12 = Caml_obj.obj_dup(values);
        newrecord$12.contactPosition = value;
        return newrecord$12;
    case /* ContactEmail */13 :
        var newrecord$13 = Caml_obj.obj_dup(values);
        newrecord$13.contactEmail = value;
        return newrecord$13;
    case /* ContactPhoneNumber */14 :
        var newrecord$14 = Caml_obj.obj_dup(values);
        newrecord$14.contactPhoneNumber = value;
        return newrecord$14;
    case /* ContactMobileNumber */15 :
        var newrecord$15 = Caml_obj.obj_dup(values);
        newrecord$15.contactMobileNumber = value;
        return newrecord$15;
    case /* LocationLabel */16 :
        var newrecord$16 = Caml_obj.obj_dup(values);
        newrecord$16.locationLabel = value;
        return newrecord$16;
    case /* LocationRecipient */17 :
        var newrecord$17 = Caml_obj.obj_dup(values);
        newrecord$17.locationRecipient = value;
        return newrecord$17;
    case /* LocationAddress */18 :
        var newrecord$18 = Caml_obj.obj_dup(values);
        newrecord$18.locationAddress = value;
        return newrecord$18;
    case /* LocationPostalCode */19 :
        var newrecord$19 = Caml_obj.obj_dup(values);
        newrecord$19.locationPostalCode = value;
        return newrecord$19;
    case /* LocationCity */20 :
        var newrecord$20 = Caml_obj.obj_dup(values);
        newrecord$20.locationCity = value;
        return newrecord$20;
    case /* LocationCountry */21 :
        var newrecord$21 = Caml_obj.obj_dup(values);
        newrecord$21.locationCountry = value;
        return newrecord$21;
    
  }
}

var SupplierNewFormLenses = {
  get: get,
  set: set
};

function supplierNewFormInitialValues(shopId) {
  return {
          shopId: shopId,
          companyName: "",
          email: "",
          phoneNumber: "",
          mobileNumber: "",
          intraCommunityVat: "",
          siretNumber: "",
          internalCode: "",
          note: "",
          contactFirstName: "",
          contactLastName: "",
          contactCivility: "NEUTRAL",
          contactPosition: "",
          contactEmail: "",
          contactPhoneNumber: "",
          contactMobileNumber: "",
          locationLabel: "",
          locationRecipient: "",
          locationAddress: "",
          locationPostalCode: "",
          locationCity: "",
          locationCountry: ""
        };
}

var SupplierNewForm = Form$Pos.Make(SupplierNewFormLenses);

var supplierNewFormSchema = [
  {
    TAG: /* StringNotEmpty */1,
    _0: /* CompanyName */1
  },
  {
    TAG: /* Custom */7,
    _0: /* ContactLastName */10,
    _1: (function (values) {
        var contactFieldsFulfilled = values.contactEmail !== "" || values.contactFirstName !== "" || values.contactMobileNumber !== "" || values.contactPhoneNumber !== "" || values.contactPosition !== "";
        if (values.contactLastName === "" && contactFieldsFulfilled) {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("This value must not be empty.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  },
  {
    TAG: /* Custom */7,
    _0: /* LocationLabel */16,
    _1: (function (values) {
        var locationFieldsFulfilled = values.locationRecipient !== "" || values.locationAddress !== "" || values.locationPostalCode !== "" || values.locationCity !== "" || values.locationCountry !== "";
        if (values.locationLabel === "" && locationFieldsFulfilled) {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("This value must not be empty.")
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        }
      })
  }
];

function stripEmptyString(string) {
  switch (string) {
    case "" :
    case " " :
        return ;
    default:
      return string;
  }
}

function createSupplierMutationVariablesFromFormValues(values) {
  return {
          supplierInput: makeInputObjectInputCreateSupplier(values.companyName, stripEmptyString(values.intraCommunityVat), stripEmptyString(values.siretNumber), stripEmptyString(values.phoneNumber), stripEmptyString(values.mobileNumber), stripEmptyString(values.email), stripEmptyString(values.note), stripEmptyString(values.internalCode), values.shopId, undefined),
          contactsInput: values.contactLastName !== "" ? [makeInputObjectInputCreateSupplierContact(values.contactLastName, stripEmptyString(values.contactFirstName), stripEmptyString(values.contactPosition), stripEmptyString(values.contactEmail), stripEmptyString(values.contactPhoneNumber), stripEmptyString(values.contactMobileNumber), true, values.contactCivility, undefined)] : undefined,
          locationsInput: values.locationLabel !== "" ? [makeInputObjectInputCreateSupplierLocation(values.locationLabel, stripEmptyString(values.locationRecipient), stripEmptyString(values.locationAddress), stripEmptyString(values.locationPostalCode), stripEmptyString(values.locationCity), stripEmptyString(values.locationCountry), [
                    "DELIVERY",
                    "BILLING"
                  ], undefined)] : undefined
        };
}

function SupplierNewPage$SupplierNewFormShopFieldset(Props) {
  var shops = Auth$Pos.useShops(undefined);
  var shopsItems = Belt_Array.map(shops, (function (shop) {
          return {
                  key: shop.id,
                  value: shop.id,
                  label: shop.name
                };
        }));
  var shopsSections = [{
      items: shopsItems
    }];
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Shop"),
              description: Intl$Pos.t("Define the shop for which the supplier will be created."),
              children: React.createElement(SupplierNewForm.Select.make, {
                    field: /* ShopId */0,
                    label: Intl$Pos.t("Shop"),
                    placeholder: Intl$Pos.t("Select a shop"),
                    sections: shopsSections
                  })
            });
}

var SupplierNewFormShopFieldset = {
  make: SupplierNewPage$SupplierNewFormShopFieldset
};

function SupplierNewPage$SupplierNewFormInformationFieldset(Props) {
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("General information"),
              description: Intl$Pos.t("Complete the essential information of your new supplier."),
              children: null
            }, React.createElement(SupplierNewForm.InputText.make, {
                  field: /* CompanyName */1,
                  label: Intl$Pos.t("Company name"),
                  placeholder: Intl$Pos.t("Company name")
                }), React.createElement(SupplierNewForm.InputText.make, {
                  field: /* Email */2,
                  label: Intl$Pos.t("Email"),
                  placeholder: Intl$Pos.t("contact@company.com")
                }), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(SupplierNewForm.InputPhone.make, {
                      label: Intl$Pos.t("Home phone"),
                      field: /* PhoneNumber */3,
                      placeholder: "02 11 22 33 44"
                    }), React.createElement(SupplierNewForm.InputPhone.make, {
                      label: Intl$Pos.t("Mobile phone"),
                      field: /* MobileNumber */4,
                      placeholder: "06 11 22 33 44"
                    })), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(SupplierNewForm.InputText.make, {
                      field: /* IntraCommunityVat */5,
                      label: Intl$Pos.t("TVA number"),
                      placeholder: "FR 40 123456789"
                    }), React.createElement(SupplierNewForm.InputText.make, {
                      field: /* SiretNumber */6,
                      label: Intl$Pos.t("Siret"),
                      placeholder: "123 456 789"
                    })), React.createElement(SupplierNewForm.InputText.make, {
                  field: /* InternalCode */7,
                  label: Intl$Pos.t("Internal code"),
                  placeholder: Intl$Pos.t("Internal code")
                }));
}

var SupplierNewFormInformationFieldset = {
  make: SupplierNewPage$SupplierNewFormInformationFieldset
};

function SupplierNewPage$SupplierNewFormLocationFieldset(Props) {
  var recipientFieldFilledRef = React.useRef(false);
  var dispatch = Curry._1(SupplierNewForm.useFormDispatch, undefined);
  var match = Curry._1(SupplierNewForm.useFormState, undefined);
  var values = match.values;
  var onChangeLocationAddress = function (value) {
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* LocationAddress */18,
          _1: (function (param) {
              return value;
            })
        });
  };
  var onRequestLocationAutoComplete = function (address) {
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* LocationAddress */18,
          _1: (function (param) {
              return address.name;
            })
        });
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* LocationPostalCode */19,
          _1: (function (param) {
              return address.postcode;
            })
        });
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* LocationCity */20,
          _1: (function (param) {
              return address.city;
            })
        });
    Curry._1(dispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* LocationCountry */21,
          _1: (function (param) {
              return address.country;
            })
        });
  };
  var defaultlocationRecipientFieldPlaceholder = Intl$Pos.t("Company name");
  var locationRecipientFieldPlaceholder = !recipientFieldFilledRef.current && values.companyName !== "" ? values.companyName : defaultlocationRecipientFieldPlaceholder;
  var onFocusLocationRecipientField = function (param) {
    if (!recipientFieldFilledRef.current && locationRecipientFieldPlaceholder !== defaultlocationRecipientFieldPlaceholder) {
      Curry._1(dispatch, {
            TAG: /* FieldValueChanged */2,
            _0: /* LocationRecipient */17,
            _1: (function (param) {
                return values.companyName;
              })
          });
      recipientFieldFilledRef.current = true;
      return ;
    }
    
  };
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Company address"),
              description: Intl$Pos.t("Indicate the supplier's address. It will appear on commercial documents."),
              children: null
            }, React.createElement(SupplierNewForm.InputText.make, {
                  field: /* LocationLabel */16,
                  label: Intl$Pos.t("Title"),
                  placeholder: Intl$Pos.t("Invoicing"),
                  hideRequired: true
                }), React.createElement(SupplierNewForm.InputText.make, {
                  field: /* LocationRecipient */17,
                  label: Intl$Pos.t("Recipient"),
                  placeholder: locationRecipientFieldPlaceholder,
                  hideRequired: true,
                  onFocus: onFocusLocationRecipientField
                }), React.createElement(SupplierAddressComboBoxField$Pos.make, {
                  addressName: values.locationAddress,
                  onInputChange: onChangeLocationAddress,
                  onRequestAutoComplete: onRequestLocationAutoComplete
                }), React.createElement(SupplierNewForm.InputText.make, {
                  field: /* LocationPostalCode */19,
                  label: Intl$Pos.t("Postal code"),
                  placeholder: Intl$Pos.t("Postal code")
                }), React.createElement(SupplierNewForm.InputText.make, {
                  field: /* LocationCity */20,
                  label: Intl$Pos.t("City"),
                  placeholder: Intl$Pos.t("City")
                }), React.createElement(SupplierNewForm.InputText.make, {
                  field: /* LocationCountry */21,
                  label: Intl$Pos.t("Country"),
                  placeholder: Intl$Pos.t("Country")
                }));
}

var SupplierNewFormLocationFieldset = {
  make: SupplierNewPage$SupplierNewFormLocationFieldset
};

function SupplierNewPage$SupplierNewFormContactFieldset(Props) {
  var civilityFieldItems = Belt_Array.map(SupplierCivility$Pos.options, (function (civility) {
          return {
                  key: SupplierCivility$Pos.toLongLabel(civility),
                  value: civility,
                  label: SupplierCivility$Pos.toLongLabel(civility)
                };
        }));
  var civilityFieldSections = [{
      items: civilityFieldItems
    }];
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Company contact"),
              description: Intl$Pos.t("Specify the coordinates of your point of contact within the company to communicate effectively."),
              children: null
            }, React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(SupplierNewForm.InputText.make, {
                      field: /* ContactLastName */10,
                      label: Intl$Pos.t("Last name"),
                      placeholder: "Dubois",
                      hideRequired: true
                    }), React.createElement(SupplierNewForm.InputText.make, {
                      field: /* ContactFirstName */9,
                      label: Intl$Pos.t("First name"),
                      placeholder: "Jean"
                    })), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(SupplierNewForm.Select.make, {
                      field: /* ContactCivility */11,
                      label: Intl$Pos.t("Civility"),
                      sections: civilityFieldSections
                    }), React.createElement(SupplierNewForm.InputText.make, {
                      field: /* ContactPosition */12,
                      label: Intl$Pos.t("Employment"),
                      placeholder: "Commercial"
                    })), React.createElement(SupplierNewForm.InputText.make, {
                  field: /* ContactEmail */13,
                  label: Intl$Pos.t("Email"),
                  placeholder: Intl$Pos.t("mikehasford@company.com")
                }), React.createElement(Group$Pos.make, {
                  children: null
                }, React.createElement(SupplierNewForm.InputPhone.make, {
                      label: Intl$Pos.t("Home phone"),
                      field: /* ContactPhoneNumber */14,
                      placeholder: "02 11 22 33 44"
                    }), React.createElement(SupplierNewForm.InputPhone.make, {
                      label: Intl$Pos.t("Mobile phone"),
                      field: /* ContactMobileNumber */15,
                      placeholder: "06 11 22 33 44"
                    })));
}

var SupplierNewFormContactFieldset = {
  make: SupplierNewPage$SupplierNewFormContactFieldset
};

function SupplierNewPage$SupplierNewFormNoteFieldset(Props) {
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Note"),
              description: Intl$Pos.t("Add a note."),
              children: React.createElement(SupplierNewForm.InputTextArea.make, {
                    field: /* Note */8,
                    label: Intl$Pos.t("Note")
                  })
            });
}

var SupplierNewFormNoteFieldset = {
  make: SupplierNewPage$SupplierNewFormNoteFieldset
};

function SupplierNewPage$SupplierNewFormDiscardButton(Props) {
  var match = Navigation$Pos.useGoBack(undefined);
  var onGoBack = match[1];
  if (match[0]) {
    return React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Discard"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(onGoBack, undefined);
                    }), undefined, undefined, undefined));
  } else {
    return React.createElement(SupplierNewForm.CancelButton.make, {
                text: Intl$Pos.t("Discard")
              });
  }
}

var SupplierNewFormDiscardButton = {
  make: SupplierNewPage$SupplierNewFormDiscardButton
};

function SupplierNewPage(Props) {
  var supplierShowRoute = Props.supplierShowRoute;
  var navigate = Navigation$Pos.useNavigate(undefined);
  var scope = Auth$Pos.useScope(undefined);
  var match;
  if (scope.TAG === /* Organisation */0) {
    var shop = scope.activeShop;
    match = shop !== undefined ? shop : scope.shops[0];
  } else {
    match = scope._0;
  }
  var match$1 = React.useState(function () {
        
      });
  var setNotificationError = match$1[1];
  var notificationError = match$1[0];
  var onSubmitFailure = function (error) {
    Curry._1(setNotificationError, (function (param) {
            return error;
          }));
  };
  var onSubmitSuccess = function (success) {
    if (success !== undefined) {
      return Curry._4(navigate, Curry._1(supplierShowRoute, success), true, undefined, undefined);
    } else {
      return Curry._1(setNotificationError, (function (param) {
                    return Request$Pos.serverErrorMessage;
                  }));
    }
  };
  var match$2 = Curry._1(SupplierNewForm.useFormPropState, {
        initialValues: supplierNewFormInitialValues(match.id),
        schema: supplierNewFormSchema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var match$3 = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var createSupplierMutation = match$3[0];
  var onSubmit = function (param, values) {
    return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(createSupplierMutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, createSupplierMutationVariablesFromFormValues(values))), undefined, (function (param) {
                  return param.createSupplier.id;
                }));
  };
  var actionsBar = React.createElement(ResourceDetailsPage$Pos.ActionsBar.make, {
        items: [
          React.createElement(SupplierNewPage$SupplierNewFormDiscardButton, {}),
          React.createElement(SupplierNewForm.SubmitButton.make, {
                variation: "success",
                size: "medium",
                onSubmit: onSubmit,
                text: Intl$Pos.t("Save")
              })
        ]
      });
  var notificationBanner;
  if (notificationError !== undefined) {
    var onRequestClose = function (param) {
      Curry._1(setNotificationError, (function (param) {
              
            }));
    };
    notificationBanner = React.createElement(ResourceDetailsPage$Pos.NotificationBanner.make, {
          value: {
            TAG: /* Error */1,
            _0: notificationError
          },
          onRequestClose: onRequestClose
        });
  } else {
    notificationBanner = null;
  }
  var tmp;
  tmp = scope.TAG === /* Organisation */0 ? React.createElement(SupplierNewPage$SupplierNewFormShopFieldset, {}) : null;
  return React.createElement(SupplierNewForm.Core.Provider.make, {
              children: React.createElement(ResourceDetailsPage$Pos.make, {
                    title: Intl$Pos.t("New supplier"),
                    actionsBar: actionsBar,
                    notificationBanner: notificationBanner,
                    children: null
                  }, tmp, React.createElement(SupplierNewPage$SupplierNewFormInformationFieldset, {}), React.createElement(SupplierNewPage$SupplierNewFormLocationFieldset, {}), React.createElement(SupplierNewPage$SupplierNewFormContactFieldset, {}), React.createElement(SupplierNewPage$SupplierNewFormNoteFieldset, {})),
              propState: [
                match$2[0],
                match$2[1]
              ]
            });
}

var make = SupplierNewPage;

export {
  CreateSupplierMutation ,
  SupplierNewFormLenses ,
  supplierNewFormInitialValues ,
  SupplierNewForm ,
  supplierNewFormSchema ,
  stripEmptyString ,
  createSupplierMutationVariablesFromFormValues ,
  SupplierNewFormShopFieldset ,
  SupplierNewFormInformationFieldset ,
  SupplierNewFormLocationFieldset ,
  SupplierNewFormContactFieldset ,
  SupplierNewFormNoteFieldset ,
  SupplierNewFormDiscardButton ,
  make ,
}
/* query Not a pure module */
