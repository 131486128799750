// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as CatalogProduct$Pos from "../../modules/Catalog/CatalogProduct.bs.js";
import * as CatalogProductEditFormPageActions$Pos from "../../modules/Catalog/CatalogProductEditFormPageActions.bs.js";
import * as CatalogProductEditFormInformationFieldsetPanel$Pos from "../../modules/Catalog/CatalogProductEditFormInformationFieldsetPanel.bs.js";
import * as CatalogProductEditFormOrganisationFieldsetPanel$Pos from "../../modules/Catalog/CatalogProductEditFormOrganisationFieldsetPanel.bs.js";

function CatalogProductEditPage(Props) {
  var shopName = Props.shopName;
  var editionMode = Props.editionMode;
  var productKind = Props.productKind;
  var contextData = Props.contextData;
  var notifier = Notifier$Pos.use(undefined, undefined);
  var renderActions = function (param) {
    return React.createElement(CatalogProductEditFormPageActions$Pos.make, {
                editionMode: editionMode
              });
  };
  var formattedTitle;
  var exit = 0;
  if (editionMode && contextData !== undefined) {
    formattedTitle = Intl$Pos.t("Editing") + " " + contextData.name;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var productLabel = CatalogProduct$Pos.Kind.toLabel(undefined, false, productKind);
    formattedTitle = Intl$Pos.template(Intl$Pos.t("Step {{step}} | Create {{fulfill}}"), {
          step: "1",
          fulfill: Intl$Pos.t("a new " + productLabel)
        }, undefined);
  }
  return React.createElement(Page$Pos.make, {
              children: null,
              title: formattedTitle,
              subtitle: shopName,
              renderActions: renderActions
            }, React.createElement(Notifier$Pos.Banner.make, {
                  notifier: notifier
                }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                              children: null,
                              space: "xlarge"
                            }, React.createElement(CatalogProductEditFormInformationFieldsetPanel$Pos.make, {}), editionMode ? null : React.createElement(CatalogProductEditFormOrganisationFieldsetPanel$Pos.make, {}))), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var make = React.memo(CatalogProductEditPage);

var Config;

export {
  Config ,
  make ,
}
/* make Not a pure module */
