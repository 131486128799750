// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as StackFields$Pos from "../../resources/layout-and-structure/StackFields.bs.js";
import * as CatalogVariant$Pos from "./CatalogVariant.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as CatalogVariantEditForm$Pos from "./CatalogVariantEditForm.bs.js";

function CatalogVariantEditFormStockFieldsetPanel(Props) {
  var match = Curry._1(CatalogVariantEditForm$Pos.useFormState, undefined).values;
  var bulk = match.bulk;
  var capacityUnit = match.capacityUnit;
  var tmp = {
    field: /* InitialStockQuantity */15,
    label: Intl$Pos.t("Initial stock"),
    minValue: 0,
    precision: bulk ? CatalogVariant$Pos.defaultBulkCapacityPrecision : 0
  };
  var tmp$1 = capacityUnit !== undefined && bulk ? ({
        TAG: /* Custom */1,
        _0: capacityUnit
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.appender = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(FieldsetLayoutPanel$Pos.make, {
              title: Intl$Pos.t("Stock"),
              description: Intl$Pos.t("Indicate the initial stock quantity of the reference or leave the stock at zero and create an order for the reception of the product."),
              children: null
            }, React.createElement(CatalogVariantEditForm$Pos.InputNumber.make, tmp), React.createElement(CatalogVariantEditForm$Pos.InputTextArea.make, {
                  field: /* InitialStockComment */16,
                  label: Intl$Pos.t("Comment")
                }), bulk ? null : React.createElement(StackFields$Pos.make, {
                    children: null
                  }, React.createElement(CatalogVariantEditForm$Pos.InputNumber.make, {
                        field: /* MaxStockThreshold */17,
                        label: Intl$Pos.t("Maximum stock"),
                        hideRequired: true,
                        minValue: 0,
                        precision: 0
                      }), React.createElement(CatalogVariantEditForm$Pos.InputNumber.make, {
                        field: /* MinStockThreshold */18,
                        label: Intl$Pos.t("Minimum stock"),
                        hideRequired: true,
                        minValue: 0,
                        precision: 0
                      }), React.createElement(CatalogVariantEditForm$Pos.InputNumber.make, {
                        field: /* StockOrderTriggerThreshold */19,
                        label: Intl$Pos.t("Stock triggering the order"),
                        hideRequired: true,
                        minValue: 0,
                        precision: 0
                      })));
}

var make = React.memo(CatalogVariantEditFormStockFieldsetPanel);

export {
  make ,
}
/* make Not a pure module */
