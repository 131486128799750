// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../typography/Title.bs.js";
import * as ReactDom from "react-dom";
import * as Button$Pos from "../actions/Button.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Column$Pos from "../layout-and-structure/Column.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../layout-and-structure/Columns.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as IconButton$Pos from "../actions/IconButton.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";

var styles = StyleSheet$Pos.create({
      footerSecondary: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor50, Caml_option.some(Style$Pos.shadowOffset(0, 2)), 0.2, 10, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function footerViewStyleFromParams(variation) {
  if (variation === "primary") {
    return Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return styles.footerSecondary;
  }
}

function Modal$ModalFooter(Props) {
  var variation = Props.variation;
  var renderStartTextOpt = Props.renderStartText;
  var criticalButtonText = Props.criticalButtonText;
  var criticalButtonCallback = Props.criticalButtonCallback;
  var abortButtonText = Props.abortButtonText;
  var allowCommitOpt = Props.allowCommit;
  var commitButtonVariation = Props.commitButtonVariation;
  var commitButtonText = Props.commitButtonText;
  var commitButtonCallback = Props.commitButtonCallback;
  var onRequestCloseOpt = Props.onRequestClose;
  var renderStartText = renderStartTextOpt !== undefined ? renderStartTextOpt : (function (param) {
        return null;
      });
  var allowCommit = allowCommitOpt !== undefined ? allowCommitOpt : true;
  var onRequestClose = onRequestCloseOpt !== undefined ? onRequestCloseOpt : (function (param) {
        
      });
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(variation === "primary" ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                        children: null,
                                        space: "normal",
                                        align: "end"
                                      }, React.createElement(Button$Pos.make, Button$Pos.makeProps(commitButtonText !== undefined && commitButtonCallback !== undefined ? Intl$Pos.t("Cancel") : Intl$Pos.t("Close"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                  Curry._1(onRequestClose, undefined);
                                                }), undefined, undefined, undefined)), commitButtonText !== undefined && commitButtonCallback !== undefined ? React.createElement(Button$Pos.make, Button$Pos.makeProps(commitButtonText, undefined, commitButtonVariation, undefined, undefined, undefined, !allowCommit, undefined, undefined, undefined, (function (param) {
                                                    Curry._1(onRequestClose, undefined);
                                                    Curry._1(commitButtonCallback, undefined);
                                                  }), undefined, undefined, undefined)) : null)), "large", undefined, "xsmall", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                        children: null,
                                        align: "spaceBetween"
                                      }, Curry._1(renderStartText, undefined), React.createElement(Inline$Pos.make, {
                                            children: null,
                                            space: "small"
                                          }, criticalButtonText !== undefined && criticalButtonCallback !== undefined ? React.createElement(Inline$Pos.make, {
                                                  children: React.createElement(Button$Pos.make, Button$Pos.makeProps(criticalButtonText, undefined, "danger", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                              Curry._1(onRequestClose, undefined);
                                                              Curry._1(criticalButtonCallback, undefined);
                                                            }), undefined, undefined, undefined))
                                                }) : null, abortButtonText !== undefined ? React.createElement(Button$Pos.make, Button$Pos.makeProps(abortButtonText, undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                        Curry._1(onRequestClose, undefined);
                                                      }), undefined, undefined, undefined)) : null, commitButtonText !== undefined && commitButtonCallback !== undefined ? React.createElement(Button$Pos.make, Button$Pos.makeProps(commitButtonText, undefined, commitButtonVariation, undefined, undefined, undefined, !allowCommit, undefined, undefined, undefined, (function (param) {
                                                        Curry._1(onRequestClose, undefined);
                                                        Curry._1(commitButtonCallback, undefined);
                                                      }), undefined, undefined, undefined)) : null))), "large", "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), Caml_option.some(footerViewStyleFromParams(variation)), undefined, undefined, undefined));
}

var make = React.memo(Modal$ModalFooter);

var styles$1 = StyleSheet$Pos.create({
      header: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor15, undefined, undefined, undefined, undefined, 2, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      wrapper: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", Caml_option.some(Style$Pos.dp(0)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(0)), undefined, Caml_option.some(Style$Pos.dp(0)), undefined, 100, undefined),
            Style$Pos.unsafeCss({
                  position: "fixed",
                  backgroundColor: "transparent"
                })
          ]),
      backdrop: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0, undefined),
            Style$Pos.unsafeCss({
                  width: "100vw",
                  height: "100vh",
                  cursor: "auto",
                  "background-color": "rgb(7, 6, 30, 0.8)",
                  "backdrop-filter": "blur(2.5px) saturate(0)"
                })
          ]),
      inner: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor00, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor100, Caml_option.some(Style$Pos.shadowOffset(0.0, 0.0)), 0.1, 20, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(90)), Caml_option.some(Style$Pos.dp(720)), Caml_option.some(Style$Pos.dp(225)), Caml_option.some(Style$Pos.dp(580)), "hidden", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "absolute", undefined, undefined, undefined, undefined, 1, undefined),
      body: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      wrapperViewOpened: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 90, undefined),
            Style$Pos.unsafeCss({
                  visibility: "visible",
                  transitionDelay: "0s",
                  transitionDuration: ".1s",
                  transitionProperty: "all",
                  transitionTimingFunction: "ease-out"
                })
          ]),
      wrapperViewClosed: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, -1, undefined),
            Style$Pos.unsafeCss({
                  visibility: "hidden",
                  transitionDelay: "0s",
                  transitionDuration: ".1s",
                  transitionProperty: "all",
                  transitionTimingFunction: "ease-out"
                })
          ]),
      backdropViewOpened: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0.75, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  visibility: "visible",
                  transitionDelay: "0s",
                  transitionDuration: ".1s",
                  transitionProperty: "all",
                  transitionTimingFunction: "ease-out"
                })
          ]),
      backdropViewClosed: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  visibility: "hidden",
                  transitionDelay: "0s",
                  transitionDuration: ".1s",
                  transitionProperty: "all",
                  transitionTimingFunction: "ease-out"
                })
          ]),
      innerViewOpened: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 1, [
                  {
                    translateY: 0
                  },
                  {
                    scale: 1
                  }
                ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  visibility: "visible",
                  transitionDelay: "0s",
                  transitionDuration: ".3s",
                  transitionProperty: "all",
                  transitionTimingFunction: "cubic-bezier(0.190, 1.000, 0.220, 1.000)"
                })
          ]),
      innerViewClosed: Style$Pos.merge([
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 0, [
                  {
                    translateY: -5
                  },
                  {
                    scale: 0.95
                  }
                ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            Style$Pos.unsafeCss({
                  visibility: "hidden",
                  transitionDelay: "0s",
                  transitionDuration: ".1s",
                  transitionProperty: "all",
                  transitionTimingFunction: "linear"
                })
          ])
    });

function wrapperViewStyleFromParams(opened) {
  return Style$Pos.arrayStyle([opened ? styles$1.wrapperViewOpened : styles$1.wrapperViewClosed]);
}

function backdropViewStyleFromParams(opened) {
  return Style$Pos.arrayStyle([opened ? styles$1.backdropViewOpened : styles$1.backdropViewClosed]);
}

function innerViewStyleFromParams(opened) {
  return Style$Pos.arrayStyle([opened ? styles$1.innerViewOpened : styles$1.innerViewClosed]);
}

function bodyViewStyleFromParams(backgroundColor) {
  return Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, backgroundColor, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function Modal(Props) {
  var children = Props.children;
  var opened = Props.opened;
  var title = Props.title;
  var variationOpt = Props.variation;
  var backgroundColorOpt = Props.backgroundColor;
  var hideFooterOpt = Props.hideFooter;
  var renderStartTextOpt = Props.renderStartText;
  var criticalButtonText = Props.criticalButtonText;
  var criticalButtonCallback = Props.criticalButtonCallback;
  var abortButtonText = Props.abortButtonText;
  var allowCommitOpt = Props.allowCommit;
  var commitButtonVariationOpt = Props.commitButtonVariation;
  var commitButtonText = Props.commitButtonText;
  var commitButtonCallback = Props.commitButtonCallback;
  var onRequestClose = Props.onRequestClose;
  var variation = variationOpt !== undefined ? variationOpt : "primary";
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : Colors$Pos.neutralColor05;
  var hideFooter = hideFooterOpt !== undefined ? hideFooterOpt : false;
  var renderStartText = renderStartTextOpt !== undefined ? renderStartTextOpt : (function (param) {
        return null;
      });
  var allowCommit = allowCommitOpt !== undefined ? allowCommitOpt : true;
  var commitButtonVariation = commitButtonVariationOpt !== undefined ? commitButtonVariationOpt : "success";
  React.useEffect((function () {
          document.body.className = opened ? "disabled-scroll" : "enabled-scroll";
          var handler = function (evt) {
            var ctrlKeyPressed = evt.ctrlKey;
            var match = evt.key;
            switch (match) {
              case "Enter" :
                  if (ctrlKeyPressed) {
                    Belt_Option.forEach(commitButtonCallback, (function (commit) {
                            Curry._1(commit, undefined);
                          }));
                    return Belt_Option.forEach(onRequestClose, (function (close) {
                                  Curry._1(close, undefined);
                                }));
                  } else {
                    return ;
                  }
              case "Escape" :
                  return Belt_Option.forEach(onRequestClose, (function (fn) {
                                Curry._1(fn, undefined);
                              }));
              default:
                return ;
            }
          };
          var domDocument = document;
          if (opened) {
            domDocument.addEventListener("keydown", handler);
          } else {
            domDocument.removeEventListener("keydown", handler);
          }
          return (function (param) {
                    domDocument.removeEventListener("keydown", handler);
                  });
        }), [
        opened,
        commitButtonCallback
      ]);
  var portal = document.getElementById("portals-modal");
  if (portal == null) {
    return null;
  }
  var tmp;
  if (hideFooter) {
    tmp = null;
  } else {
    var tmp$1 = {
      variation: variation,
      renderStartText: renderStartText,
      criticalButtonText: criticalButtonText,
      criticalButtonCallback: criticalButtonCallback,
      abortButtonText: abortButtonText,
      allowCommit: allowCommit,
      commitButtonVariation: commitButtonVariation,
      commitButtonText: commitButtonText,
      commitButtonCallback: commitButtonCallback
    };
    if (onRequestClose !== undefined) {
      tmp$1.onRequestClose = Caml_option.valFromOption(onRequestClose);
    }
    tmp = React.createElement(make, tmp$1);
  }
  return ReactDom.createPortal(React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(Style$Pos.arrayStyle([
                              styles$1.wrapper,
                              wrapperViewStyleFromParams(opened)
                            ])), undefined, undefined, undefined), React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(View$Pos.make, View$Pos.makeProps(undefined, Caml_option.some(Style$Pos.arrayStyle([
                                          styles$1.backdrop,
                                          backdropViewStyleFromParams(opened)
                                        ])), undefined, undefined, undefined)), undefined, undefined, undefined, undefined, undefined, (function (param) {
                            Belt_Option.forEach(onRequestClose, (function (fn) {
                                    Curry._1(fn, undefined);
                                  }));
                          }), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(Style$Pos.arrayStyle([
                                  styles$1.inner,
                                  innerViewStyleFromParams(opened)
                                ])), undefined, undefined, undefined), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Columns$Pos.make, {
                                                  children: null,
                                                  align: "center"
                                                }, React.createElement(Column$Pos.make, {
                                                      children: React.createElement(Title$Pos.make, {
                                                            children: title,
                                                            level: 3,
                                                            weight: "medium"
                                                          }),
                                                      width: "fluid"
                                                    }), React.createElement(Column$Pos.make, {
                                                      children: onRequestClose !== undefined ? React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps("close_bold", undefined, undefined, Colors$Pos.neutralColor30, Colors$Pos.neutralColor50, undefined, undefined, (function (param) {
                                                                    Curry._1(onRequestClose, undefined);
                                                                  }), undefined, undefined, undefined)) : null,
                                                      width: "content"
                                                    }))), "large", "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), Caml_option.some(styles$1.header), undefined, undefined, undefined)), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(Style$Pos.arrayStyle([
                                      styles$1.body,
                                      bodyViewStyleFromParams(backgroundColor)
                                    ])), undefined, undefined, undefined), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(children), undefined, undefined, undefined, undefined, undefined, undefined, true, undefined, undefined, undefined, undefined)), tmp))), portal);
}

var make$1 = React.memo(Modal);

export {
  make$1 as make,
}
/* styles Not a pure module */
