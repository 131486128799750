// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as Scaffold$Pos from "../../bundles/Scaffold/Scaffold.bs.js";
import * as TextLink$Pos from "../../resources/navigation/TextLink.bs.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as SearchBar$Pos from "../../resources/selection-and-input/SearchBar.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as ButtonLink$Pos from "../../resources/actions/ButtonLink.bs.js";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as Illustration$Pos from "../../resources/images-and-icons/Illustration.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as PromotionStatusBadge$Pos from "../../modules/Promotion/PromotionStatusBadge.bs.js";
import * as PromotionTableMoreActionsMenu$Pos from "../../modules/Promotion/PromotionTableMoreActionsMenu.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query PromotionListPageQuery($first: Int, $last: Int, $before: String, $after: String, $search: String, $filterBy: InputPromotionCampaignsQueryFilter)  {\npromotionCampaignsDistinctOnCku(filterBy: $filterBy, search: $search, first: $first, last: $last, before: $before, after: $after)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncku  \nname  \nstartDate  \nendDate  \ndiscounts  {\n__typename  \ntotalCount  \n}\n\nformattedShopsNames  \nprice  {\n__typename  \nname  \n}\n\nformattedStatus  \n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.promotionCampaignsDistinctOnCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          promotionCampaignsDistinctOnCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.discounts;
                    var value$3 = value$1.price;
                    var value$4 = value$1.formattedStatus;
                    var tmp;
                    switch (value$4) {
                      case "ARCHIVED" :
                          tmp = "ARCHIVED";
                          break;
                      case "DRAFT" :
                          tmp = "DRAFT";
                          break;
                      case "EXPIRED" :
                          tmp = "EXPIRED";
                          break;
                      case "NOT_PROGRAMMED" :
                          tmp = "NOT_PROGRAMMED";
                          break;
                      case "ONGOING" :
                          tmp = "ONGOING";
                          break;
                      case "PROGRAMMED" :
                          tmp = "PROGRAMMED";
                          break;
                      case "STOPPED" :
                          tmp = "STOPPED";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              cku: Scalar$Pos.CKU.parse(value$1.cku),
                              name: value$1.name,
                              startDate: Scalar$Pos.Datetime.parse(value$1.startDate),
                              endDate: Scalar$Pos.Datetime.parse(value$1.endDate),
                              discounts: {
                                __typename: value$2.__typename,
                                totalCount: value$2.totalCount
                              },
                              formattedShopsNames: value$1.formattedShopsNames,
                              price: {
                                __typename: value$3.__typename,
                                name: value$3.name
                              },
                              formattedStatus: tmp
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.promotionCampaignsDistinctOnCku;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedStatus;
          var formattedStatus = value$2 === "STOPPED" ? "STOPPED" : (
              value$2 === "ARCHIVED" ? "ARCHIVED" : (
                  value$2 === "DRAFT" ? "DRAFT" : (
                      value$2 === "NOT_PROGRAMMED" ? "NOT_PROGRAMMED" : (
                          value$2 === "ONGOING" ? "ONGOING" : (
                              value$2 === "EXPIRED" ? "EXPIRED" : "PROGRAMMED"
                            )
                        )
                    )
                )
            );
          var value$3 = value$1.price;
          var value$4 = value$3.name;
          var value$5 = value$3.__typename;
          var price = {
            __typename: value$5,
            name: value$4
          };
          var value$6 = value$1.formattedShopsNames;
          var value$7 = value$1.discounts;
          var value$8 = value$7.totalCount;
          var value$9 = value$7.__typename;
          var discounts = {
            __typename: value$9,
            totalCount: value$8
          };
          var value$10 = value$1.endDate;
          var value$11 = Scalar$Pos.Datetime.serialize(value$10);
          var value$12 = value$1.startDate;
          var value$13 = Scalar$Pos.Datetime.serialize(value$12);
          var value$14 = value$1.name;
          var value$15 = value$1.cku;
          var value$16 = Scalar$Pos.CKU.serialize(value$15);
          var value$17 = value$1.id;
          var value$18 = value$1.__typename;
          var node = {
            __typename: value$18,
            id: value$17,
            cku: value$16,
            name: value$14,
            startDate: value$13,
            endDate: value$11,
            discounts: discounts,
            formattedShopsNames: value$6,
            price: price,
            formattedStatus: formattedStatus
          };
          var value$19 = value.__typename;
          return {
                  __typename: value$19,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var promotionCampaignsDistinctOnCku = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          promotionCampaignsDistinctOnCku: promotionCampaignsDistinctOnCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputPromotionCampaignsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          archived: a$1 !== undefined ? (
              a$1 === "ONLY" ? "ONLY" : (
                  a$1 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.last;
  var a$2 = inp.before;
  var a$3 = inp.after;
  var a$4 = inp.search;
  var a$5 = inp.filterBy;
  return {
          first: a !== undefined ? a : undefined,
          last: a$1 !== undefined ? a$1 : undefined,
          before: a$2 !== undefined ? a$2 : undefined,
          after: a$3 !== undefined ? a$3 : undefined,
          search: a$4 !== undefined ? a$4 : undefined,
          filterBy: a$5 !== undefined ? serializeInputObjectInputPromotionCampaignsQueryFilter(a$5) : undefined
        };
}

function makeVariables(first, last, before, after, search, filterBy, param) {
  return {
          first: first,
          last: last,
          before: before,
          after: after,
          search: search,
          filterBy: filterBy
        };
}

function makeInputObjectInputPromotionCampaignsQueryFilter(shopIds, archived, param) {
  return {
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          first: undefined,
          last: undefined,
          before: undefined,
          after: undefined,
          search: undefined,
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputPromotionCampaignsQueryFilter: serializeInputObjectInputPromotionCampaignsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputPromotionCampaignsQueryFilter: makeInputObjectInputPromotionCampaignsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputPromotionCampaignsQueryFilter: serializeInputObjectInputPromotionCampaignsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputPromotionCampaignsQueryFilter: makeInputObjectInputPromotionCampaignsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function encoder(param) {
  return Belt_Option.map(param.shop, (function (shop) {
                return shop.id;
              }));
}

function decoder(shops, shopId) {
  return {
          TAG: /* Ok */0,
          _0: {
            shop: Belt_Array.getBy(shops, (function (shop) {
                    return shop.id === shopId;
                  }))
          }
        };
}

function useJsonCodec(param) {
  var shops = Auth$Pos.useShops(undefined);
  return JsonCodec$Pos.object1(encoder, (function (param) {
                return decoder(shops, param);
              }), JsonCodec$Pos.optional(JsonCodec$Pos.field("shopId", JsonCodec$Pos.string)));
}

var Filters = {
  encoder: encoder,
  decoder: decoder,
  useJsonCodec: useJsonCodec
};

var Row = {};

function makeQueryVariables(_defaultQueryVariables, connectionArguments, search, filterBy, param) {
  return {
          first: connectionArguments.first,
          last: connectionArguments.last,
          before: connectionArguments.before,
          after: connectionArguments.after,
          search: search,
          filterBy: filterBy
        };
}

function makeQueryVariablesFilterBy(param) {
  var shop = param.shop;
  return {
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined,
          archived: "EXCLUDED"
        };
}

function totalCountFromQueryData(param) {
  return param.promotionCampaignsDistinctOnCku.totalCount;
}

function cursorsFromQueryData(param) {
  var promotionCampaignsDistinctOnCku = param.promotionCampaignsDistinctOnCku;
  return [
          promotionCampaignsDistinctOnCku.pageInfo.startCursor,
          promotionCampaignsDistinctOnCku.pageInfo.endCursor
        ];
}

function rowsFromQueryDataAndState(param, param$1) {
  return Belt_Array.map(param.promotionCampaignsDistinctOnCku.edges, (function (edge) {
                return {
                        cku: edge.node.cku,
                        id: edge.node.id,
                        name: edge.node.name,
                        startDate: edge.node.startDate,
                        endDate: edge.node.endDate,
                        discountsCount: edge.node.discounts.totalCount,
                        priceName: edge.node.price.name,
                        formattedShopsNames: edge.node.formattedShopsNames,
                        formattedStatus: edge.node.formattedStatus
                      };
              }));
}

function keyExtractor(param) {
  return param.id;
}

var Scaffolded = Scaffold$Pos.Make({
      useFiltersJsonCodec: useJsonCodec,
      QueryInner: Query_inner,
      useQuery: use,
      makeQueryVariables: makeQueryVariables,
      makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
      cursorsFromQueryData: cursorsFromQueryData,
      totalCountFromQueryData: totalCountFromQueryData,
      rowsFromQueryDataAndState: rowsFromQueryDataAndState,
      keyExtractor: keyExtractor
    });

var scaffoldedColumns = [
  {
    name: Intl$Pos.t("Name"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 200
      },
      width: {
        NAME: "pct",
        VAL: 20
      },
      sticky: true
    },
    render: (function (param) {
        return React.createElement(TextLink$Pos.make, {
                    text: param.name,
                    to: {
                      TAG: /* Route */0,
                      _0: LegacyRouter$Pos.routeToPathname(/* Promotion */3) + "/" + param.cku
                    }
                  });
      })
  },
  {
    name: Intl$Pos.t("Start date"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.dateTimeFormat(undefined, "short", param.startDate)
                  });
      })
  },
  {
    name: Intl$Pos.t("End date"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.dateTimeFormat(undefined, "short", param.endDate)
                  });
      })
  },
  {
    name: Intl$Pos.t("Discounted products"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 60
      },
      width: {
        NAME: "px",
        VAL: 170
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: String(param.discountsCount)
                  });
      })
  },
  {
    name: Intl$Pos.t("Price list"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 100
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.priceName
                  });
      })
  },
  {
    name: Intl$Pos.t("Active shops"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 100
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.formattedShopsNames
                  });
      })
  },
  {
    name: Intl$Pos.t("Status"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 90
      },
      width: {
        NAME: "fr",
        VAL: 1
      }
    },
    render: (function (param) {
        return React.createElement(PromotionStatusBadge$Pos.make, {
                    status: param.formattedStatus
                  });
      })
  },
  {
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "px",
        VAL: 70
      }
    },
    render: (function (param) {
        var notifier = Notifier$Pos.use(undefined, undefined);
        return React.createElement(Inline$Pos.make, {
                    children: React.createElement(PromotionTableMoreActionsMenu$Pos.make, {
                          cku: param.cku,
                          id: param.id,
                          name: param.name,
                          startDate: param.startDate,
                          notifier: notifier
                        }),
                    align: "end"
                  });
      })
  }
];

function PromotionListPage(Props) {
  var authorizedCreateRoute = Props.authorizedCreateRoute;
  var notifier = Notifier$Pos.use(undefined, undefined);
  var scope = Auth$Pos.useScope(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var initialFilters = {
    shop: activeShop
  };
  var initialState = Curry._1(Scaffolded.makeInitialState, initialFilters);
  var match = Curry._1(Scaffolded.use, (function (param) {
          return initialState;
        }));
  var dispatch = match[1];
  var state = match[0];
  var defaultQueryVariables = {
    first: undefined,
    last: undefined,
    before: undefined,
    after: undefined,
    search: undefined,
    filterBy: undefined
  };
  var filters;
  if (scope.TAG === /* Organisation */0) {
    var tmp = {
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (param) {
                    return {
                            shop: shop
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp.value = Caml_option.valFromOption(state.filters.shop);
    }
    filters = React.createElement(Auth$Pos.SelectShop.make, tmp);
  } else {
    filters = null;
  }
  var actions;
  actions = authorizedCreateRoute.TAG === /* Ok */0 ? React.createElement(ButtonLink$Pos.make, {
          children: Intl$Pos.t("Create campaign"),
          variation: "primary",
          to: {
            TAG: /* Route */0,
            _0: authorizedCreateRoute._0
          }
        }) : null;
  var emptyState;
  var exit = 0;
  if (state.currentPage !== 1 || state.searchQuery !== undefined || state.filters.shop !== activeShop) {
    exit = 1;
  } else {
    var tmp$1 = {
      illustration: Illustration$Pos.create,
      title: Intl$Pos.t("Welcome to the promotional campaigns space."),
      children: actions
    };
    var tmp$2 = Belt_Result.isOk(authorizedCreateRoute) ? Intl$Pos.t("Start creating your first campaign.") : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.text = tmp$2;
    }
    emptyState = React.createElement(EmptyState$Pos.make, tmp$1);
  }
  if (exit === 1) {
    emptyState = React.createElement(EmptyState$Pos.make, {
          illustration: Illustration$Pos.notFound,
          title: Intl$Pos.t("No result were found."),
          text: Intl$Pos.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  var banner = React.createElement(Notifier$Pos.Banner.make, {
        notifier: notifier
      });
  var tmp$3 = {
    placeholder: Intl$Pos.t("Search a campaign"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$3.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar$Pos.make, tmp$3);
  return React.createElement(Scaffolded.make, {
              title: Intl$Pos.t("Promotional campaigns"),
              state: state,
              dispatch: dispatch,
              columns: scaffoldedColumns,
              filters: filters,
              actions: actions,
              banner: banner,
              searchBar: searchBar,
              emptyState: emptyState,
              defaultQueryVariables: defaultQueryVariables
            });
}

var make = React.memo(PromotionListPage);

export {
  Query ,
  Filters ,
  Row ,
  Scaffolded ,
  scaffoldedColumns ,
  make ,
}
/* query Not a pure module */
