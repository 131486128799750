// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as IconButton$Pos from "../actions/IconButton.bs.js";

function Burger(Props) {
  var onPress = Props.onPress;
  return React.createElement(IconButton$Pos.make, IconButton$Pos.makeProps("burger", undefined, "normal", Colors$Pos.neutralColor70, undefined, undefined, undefined, (function (param) {
                    Curry._1(onPress, undefined);
                  }), undefined, undefined, undefined));
}

var make = React.memo(Burger);

export {
  make ,
}
/* make Not a pure module */
