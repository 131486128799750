// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as QueryString$Pos from "../../primitives/QueryString.bs.js";

var baseRoute = "/settings";

var userRoute = baseRoute + "/user";

var shopsRoute = baseRoute + "/shops";

var labelPrinterRoute = baseRoute + "/label-printer";

var customerBillingAccountBaseRoute = baseRoute + "/customer-billing-account";

var customerBillingAccountEditRoute = customerBillingAccountBaseRoute + "/edit";

var invoicesRoute = customerBillingAccountBaseRoute + "/invoices";

function encoder(param) {
  return [
          param.activeShopId,
          param.corporateName,
          param.shopName,
          param.email,
          param.phone,
          param.vatNumber,
          param.billingAddress,
          param.shippingAddress
        ];
}

function decoder(param) {
  return {
          TAG: /* Ok */0,
          _0: {
            activeShopId: param[0],
            corporateName: param[1],
            shopName: param[2],
            email: param[3],
            phone: param[4],
            vatNumber: param[5],
            billingAddress: param[6],
            shippingAddress: param[7]
          }
        };
}

var address = JsonCodec$Pos.object4((function (param) {
        return [
                param.address,
                param.postalCode,
                param.city,
                param.country
              ];
      }), (function (param) {
        return {
                TAG: /* Ok */0,
                _0: {
                  address: param[0],
                  postalCode: param[1],
                  city: param[2],
                  country: param[3]
                }
              };
      }), JsonCodec$Pos.field("address", JsonCodec$Pos.string), JsonCodec$Pos.field("postalCode", JsonCodec$Pos.string), JsonCodec$Pos.field("city", JsonCodec$Pos.string), JsonCodec$Pos.field("country", JsonCodec$Pos.string));

var value = JsonCodec$Pos.object8(encoder, decoder, JsonCodec$Pos.field("activeShopId", JsonCodec$Pos.string), JsonCodec$Pos.field("corporateName", JsonCodec$Pos.string), JsonCodec$Pos.field("shopName", JsonCodec$Pos.string), JsonCodec$Pos.field("email", JsonCodec$Pos.string), JsonCodec$Pos.field("phone", JsonCodec$Pos.string), JsonCodec$Pos.optional(JsonCodec$Pos.field("vatNumber", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("billingAddress", address)), JsonCodec$Pos.optional(JsonCodec$Pos.field("shippingAddress", address)));

var CustomerBillingAccountEditQueryStringCodecs = {
  encoder: encoder,
  decoder: decoder,
  address: address,
  value: value
};

function decodeEditCustomerBillingAccountQueryString(query) {
  var values = JsonCodec$Pos.decodeWith(QueryString$Pos.parse(query), value);
  if (values.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: values._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

function encodeEditCustomerBillingAccountQueryString(activeShopId, corporateName, shopName, email, phone, billingAddress, shippingAddress, vatNumber) {
  return QueryString$Pos.stringify(JsonCodec$Pos.encodeWith({
                  activeShopId: activeShopId,
                  corporateName: corporateName,
                  shopName: shopName,
                  email: email,
                  phone: phone,
                  vatNumber: vatNumber,
                  billingAddress: billingAddress,
                  shippingAddress: shippingAddress
                }, value));
}

var customerBillingAccountShowRoute = customerBillingAccountBaseRoute;

export {
  baseRoute ,
  userRoute ,
  shopsRoute ,
  labelPrinterRoute ,
  customerBillingAccountBaseRoute ,
  customerBillingAccountShowRoute ,
  customerBillingAccountEditRoute ,
  invoicesRoute ,
  CustomerBillingAccountEditQueryStringCodecs ,
  decodeEditCustomerBillingAccountQueryString ,
  encodeEditCustomerBillingAccountQueryString ,
}
/* address Not a pure module */
