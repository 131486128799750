// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Image$Pos from "../../primitives/Image.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Spaces$Pos from "../../resources/theme/Spaces.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import MaintenancePng from "./maintenance.png";

var imageUri = MaintenancePng;

var styles = StyleSheet$Pos.create({
      container: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(450)), undefined, undefined),
      image: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(186)), undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.xlarge)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-150)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(544)), undefined, undefined),
      action: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.large)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function MaintenancePage(Props) {
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.container), undefined, undefined, undefined), React.createElement(Image$Pos.make, {
                  style: styles.image,
                  source: Image$Pos.fromUriSource(Image$Pos.uriSource(imageUri))
                }), React.createElement(Stack$Pos.make, {
                  children: null,
                  space: "normal"
                }, React.createElement(Title$Pos.make, {
                      children: Intl$Pos.t("The dashboard is currently unavailable due to a maintenance."),
                      align: "center"
                    }), React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.t("Wino will be back soon, thank you for your patience."),
                      align: "center",
                      size: "huge"
                    }), React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Refresh the page"), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        window.location.reload();
                                      }), undefined, undefined, undefined))), Caml_option.some(styles.action), undefined, undefined, undefined))));
}

var make = MaintenancePage;

export {
  imageUri ,
  styles ,
  make ,
}
/* imageUri Not a pure module */
