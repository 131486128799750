// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Svg$Circle(Props) {
  var cx = Props.cx;
  var cy = Props.cy;
  var r = Props.r;
  var fill = Props.fill;
  var tmp = {};
  if (cx !== undefined) {
    tmp.cx = Caml_option.valFromOption(cx);
  }
  if (cy !== undefined) {
    tmp.cy = Caml_option.valFromOption(cy);
  }
  if (fill !== undefined) {
    tmp.fill = Caml_option.valFromOption(fill);
  }
  if (r !== undefined) {
    tmp.r = Caml_option.valFromOption(r);
  }
  return React.createElement("circle", tmp);
}

var Circle = {
  make: Svg$Circle
};

function Svg$Path(Props) {
  var d = Props.d;
  var fill = Props.fill;
  var transform = Props.transform;
  var fillRule = Props.fillRule;
  var filter = Props.filter;
  var stroke = Props.stroke;
  var strokeWidth = Props.strokeWidth;
  var strokeDasharray = Props.strokeDasharray;
  var tmp = {};
  if (d !== undefined) {
    tmp.d = Caml_option.valFromOption(d);
  }
  if (fill !== undefined) {
    tmp.fill = Caml_option.valFromOption(fill);
  }
  if (fillRule !== undefined) {
    tmp.fillRule = Caml_option.valFromOption(fillRule);
  }
  if (filter !== undefined) {
    tmp.filter = Caml_option.valFromOption(filter);
  }
  if (stroke !== undefined) {
    tmp.stroke = Caml_option.valFromOption(stroke);
  }
  if (strokeDasharray !== undefined) {
    tmp.strokeDasharray = Caml_option.valFromOption(strokeDasharray);
  }
  if (strokeWidth !== undefined) {
    tmp.strokeWidth = Caml_option.valFromOption(strokeWidth);
  }
  if (transform !== undefined) {
    tmp.transform = Caml_option.valFromOption(transform);
  }
  return React.createElement("path", tmp);
}

var Path = {
  make: Svg$Path
};

function Svg(Props) {
  var children = Props.children;
  var role = Props.role;
  var width = Props.width;
  var height = Props.height;
  var viewBox = Props.viewBox;
  var fill = Props.fill;
  var stroke = Props.stroke;
  var tmp = {};
  if (role !== undefined) {
    tmp.role = Caml_option.valFromOption(role);
  }
  if (height !== undefined) {
    tmp.height = Caml_option.valFromOption(height);
  }
  if (width !== undefined) {
    tmp.width = Caml_option.valFromOption(width);
  }
  if (fill !== undefined) {
    tmp.fill = Caml_option.valFromOption(fill);
  }
  if (stroke !== undefined) {
    tmp.stroke = Caml_option.valFromOption(stroke);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return React.createElement("svg", tmp, children);
}

var make = Svg;

export {
  Circle ,
  Path ,
  make ,
}
/* react Not a pure module */
