// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Auth__Hooks$Pos from "./Auth__Hooks.bs.js";

function Auth__Elements$SelectShop(Props) {
  var value = Props.value;
  var disabledIdsOpt = Props.disabledIds;
  var variation = Props.variation;
  var onChange = Props.onChange;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var dispatch = Auth__Hooks$Pos.useDispatch(undefined);
  var shops = Auth__Hooks$Pos.useShops(undefined);
  var activeShop = Auth__Hooks$Pos.useActiveShop(undefined);
  var onChange$1 = function (nextShopId) {
    var nextShop = Belt_Option.flatMap(nextShopId, (function (shopId) {
            return Belt_Array.getBy(shops, (function (shop) {
                          return shop.id === shopId;
                        }));
          }));
    Belt_Option.forEach(onChange, (function (fn) {
            Curry._1(fn, nextShop);
          }));
    Curry._1(dispatch, {
          TAG: /* ActiveShopSet */2,
          _0: nextShop
        });
  };
  var shopItems = Belt_Array.map(shops, (function (shop) {
          return {
                  key: shop.id,
                  value: shop.id,
                  label: shop.name,
                  disabled: Belt_Array.some(disabledIds, (function (id) {
                          return id === shop.id;
                        }))
                };
        }));
  var sections = [
    {
      items: [{
          key: "default",
          label: Intl$Pos.template(Intl$Pos.t("All{{feminine}}"), undefined, undefined),
          sticky: true
        }]
    },
    {
      items: shopItems,
      title: Intl$Pos.t("Shops")
    }
  ];
  var value$1 = Belt_Option.map(Belt_Option.orElse(value, activeShop), (function (shop) {
          return shop.id;
        }));
  var tmp = {
    label: Intl$Pos.t("Shop"),
    sections: sections,
    value: value$1,
    onChange: onChange$1
  };
  if (variation !== undefined) {
    tmp.variation = Caml_option.valFromOption(variation);
  }
  return React.createElement(Select$Pos.make, tmp);
}

var SelectShop = {
  make: Auth__Elements$SelectShop
};

function Auth__Elements$SelectSingleShop(Props) {
  var value = Props.value;
  var disabledIdsOpt = Props.disabledIds;
  var variation = Props.variation;
  var onChange = Props.onChange;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var dispatch = Auth__Hooks$Pos.useDispatch(undefined);
  var shops = Auth__Hooks$Pos.useShops(undefined);
  var onChange$1 = function (nextActiveShopId) {
    var nextActiveShop = Belt_Array.getBy(shops, (function (shop) {
            return shop.id === nextActiveShopId;
          }));
    if (nextActiveShop !== undefined) {
      Belt_Option.forEach(onChange, (function (fn) {
              Curry._1(fn, nextActiveShop);
            }));
    }
    Curry._1(dispatch, {
          TAG: /* ActiveShopSet */2,
          _0: nextActiveShop
        });
  };
  var value$1 = value.id;
  var shopItems = Belt_Array.map(shops, (function (shop) {
          return {
                  key: shop.id,
                  value: shop.id,
                  label: shop.name,
                  disabled: Belt_Array.some(disabledIds, (function (id) {
                          return id === shop.id;
                        }))
                };
        }));
  var sections = [{
      items: shopItems,
      title: Intl$Pos.t("Shops")
    }];
  var tmp = {
    label: Intl$Pos.t("Shop"),
    sections: sections,
    value: value$1,
    onChange: onChange$1
  };
  if (variation !== undefined) {
    tmp.variation = Caml_option.valFromOption(variation);
  }
  return React.createElement(Select$Pos.make, tmp);
}

var SelectSingleShop = {
  make: Auth__Elements$SelectSingleShop
};

var useDispatch = Auth__Hooks$Pos.useDispatch;

var useScope = Auth__Hooks$Pos.useScope;

var useShops = Auth__Hooks$Pos.useShops;

var useActiveShop = Auth__Hooks$Pos.useActiveShop;

export {
  useDispatch ,
  useScope ,
  useShops ,
  useActiveShop ,
  SelectShop ,
  SelectSingleShop ,
}
/* react Not a pure module */
