// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation SupplierArchiveModalMutation($id: ID!)  {\narchiveSupplier(id: $id)  {\n__typename  \nid  \narchivedAt  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.archiveSupplier;
  var value$2 = value$1.archivedAt;
  return {
          archiveSupplier: {
            __typename: value$1.__typename,
            id: value$1.id,
            archivedAt: !(value$2 == null) ? Caml_option.some(Scalar$Pos.Datetime.parse(value$2)) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.archiveSupplier;
  var value$2 = value$1.archivedAt;
  var archivedAt = value$2 !== undefined ? Scalar$Pos.Datetime.serialize(Caml_option.valFromOption(value$2)) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var archiveSupplier = {
    __typename: value$4,
    id: value$3,
    archivedAt: archivedAt
  };
  return {
          archiveSupplier: archiveSupplier
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var ArchiveSupplierMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ArchiveSupplierMutation_useWithVariables = include.useWithVariables;

var ArchiveSupplierMutation = {
  ArchiveSupplierMutation_inner: ArchiveSupplierMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use,
  useWithVariables: ArchiveSupplierMutation_useWithVariables
};

var text = "Once the supplier is archived, it will only be visible in the supplier list by applying the \"Archive\" filter.";

function SupplierArchiveModal(Props) {
  var id = Props.id;
  var opened = Props.opened;
  var onRequestClose = Props.onRequestClose;
  var supplierBaseRoute = Props.supplierBaseRoute;
  var navigate = Navigation$Pos.useNavigate(undefined);
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var archiveSupplier = match[0];
  var commit = function (param) {
    Future.tapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(archiveSupplier, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                  id: id
                })), (function (param) {
            Curry._4(navigate, supplierBaseRoute, undefined, undefined, undefined);
          }));
  };
  return React.createElement(Modal$Pos.make, {
              children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                children: Intl$Pos.t(text),
                                variation: "normal"
                              })), "xlarge", "xxlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
              opened: opened,
              title: Intl$Pos.t("Confirm the archiving of this supplier"),
              abortButtonText: Intl$Pos.t("Cancel"),
              commitButtonText: Intl$Pos.t("Confirm"),
              commitButtonCallback: commit,
              onRequestClose: onRequestClose
            });
}

var make = React.memo(SupplierArchiveModal);

export {
  ArchiveSupplierMutation ,
  text ,
  make ,
}
/* query Not a pure module */
