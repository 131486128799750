/* TypeScript file generated from Root.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as RootBS__Es6Import from './Root.bs';
const RootBS: any = RootBS__Es6Import;

// tslint:disable-next-line:interface-over-type-literal
export type Props = {};

export const make: React.ComponentType<{}> = RootBS.make;
