// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Button$Pos from "./Button.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Tooltip$Pos from "../overlays/Tooltip.bs.js";
import * as AsyncData$Pos from "../../primitives/AsyncData.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as SpinnerModal$Pos from "../feedback-indicators/SpinnerModal.bs.js";
import * as TriggerDownload$Pos from "../../primitives/TriggerDownload.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";

function RequestOpenStorageUrlButton$RequestButton(Props) {
  var text = Props.text;
  var textError = Props.textError;
  var operableRequest = Props.operableRequest;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return /* NotAsked */0;
      });
  var setRequestResult = match[1];
  var requestResult = match[0];
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(onChange, requestResult);
        }), [requestResult]);
  var onPress = React.useCallback((function (param) {
          if (operableRequest.TAG !== /* Ok */0) {
            return ;
          }
          if (typeof requestResult === "number") {
            if (requestResult !== /* NotAsked */0) {
              return ;
            }
            
          } else if (requestResult.TAG === /* Reloading */0) {
            return ;
          }
          Curry._1(setRequestResult, (function (param) {
                  return /* Loading */1;
                }));
          Future.get(Curry._1(operableRequest._0, undefined), (function (result) {
                  Curry._1(setRequestResult, (function (param) {
                          return {
                                  TAG: /* Done */1,
                                  _0: result
                                };
                        }));
                }));
        }), [operableRequest]);
  var text$1;
  text$1 = typeof requestResult === "number" || !(requestResult.TAG === /* Done */1 && !(requestResult._0.TAG === /* Ok */0 || textError === undefined)) ? text : textError;
  var tmp;
  tmp = typeof requestResult === "number" || !(requestResult.TAG === /* Done */1 && requestResult._0.TAG !== /* Ok */0) ? "neutral" : "danger";
  var tmp$1;
  tmp$1 = operableRequest.TAG === /* Ok */0 ? Intl$Pos.t("Cannot run request") : operableRequest._0;
  return React.createElement(Tooltip$Pos.make, {
              children: React.createElement(Button$Pos.make, Button$Pos.makeProps(text$1, "normal", tmp, undefined, undefined, undefined, AsyncData$Pos.isBusy(requestResult) || Belt_Result.isError(operableRequest), undefined, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, undefined, undefined)),
              placement: "start",
              content: {
                TAG: /* Text */0,
                _0: tmp$1
              },
              disabled: Belt_Result.isOk(operableRequest),
              delay: 75
            });
}

function failureErrorToString(error, exportName) {
  if (typeof error === "number") {
    if (error !== 0) {
      return Intl$Pos.template(Intl$Pos.t("An issue occurred while generating the URL for the {{export}} file."), {
                  export: Intl$Pos.t(exportName)
                }, undefined);
    } else {
      return Intl$Pos.t("An issue when downloading the file occurred with this navigator.");
    }
  } else {
    return Intl$Pos.template(Intl$Pos.t("An issue occurred when attempting to download the {{export}} file."), {
                export: Intl$Pos.t(exportName)
              }, undefined) + " " + Request$Pos.serverErrorMessage;
  }
}

function decodeAndMakeUrl(json) {
  return Belt_Option.map(Belt_Option.orElse(Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "url"), Json$Pos.flatDecodeDictFieldString(Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "file", Json$Pos.decodeDict), "url")), (function (prim) {
                return new URL(prim);
              }));
}

function RequestOpenStorageUrlButton(Props) {
  var text = Props.text;
  var textErrorOpt = Props.textError;
  var operableRequest = Props.operableRequest;
  var onFailure = Props.onFailure;
  var textError = textErrorOpt !== undefined ? textErrorOpt : Intl$Pos.t("Download failed");
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var onChange = React.useCallback((function (asyncResult) {
          Curry._1(setLoading, (function (param) {
                  return AsyncResult$Pos.isBusy(asyncResult);
                }));
          if (typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1) {
            var json = asyncResult._0;
            if (json.TAG === /* Ok */0) {
              var fileUrl = decodeAndMakeUrl(json._0);
              if (fileUrl !== undefined) {
                Future.tapError(TriggerDownload$Pos.fromUrl(undefined, Caml_option.valFromOption(fileUrl)), (function (param) {
                        Curry._1(onFailure, /* LinkingOpenUrlError */0);
                      }));
              } else {
                Curry._1(onFailure, /* MissingFileUrl */1);
              }
            } else {
              Curry._1(onFailure, /* RequestError */{
                    _0: json._0
                  });
            }
          }
          
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(SpinnerModal$Pos.make, {
                  title: text,
                  opened: match[0]
                }), React.createElement(RequestOpenStorageUrlButton$RequestButton, {
                  text: text,
                  textError: textError,
                  operableRequest: operableRequest,
                  onChange: onChange
                }));
}

var make = React.memo(RequestOpenStorageUrlButton);

export {
  failureErrorToString ,
  make ,
}
/* make Not a pure module */
