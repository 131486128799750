// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as OrderStatus$Pos from "./OrderStatus.bs.js";
import * as OrderEditForm$Pos from "./OrderEditForm.bs.js";
import * as OrderMutations$Pos from "./OrderMutations.bs.js";

function OrderPageActions(Props) {
  var id = Props.id;
  var statuses = Props.statuses;
  var edition = Props.edition;
  var onRequestCancelEdition = Props.onRequestCancelEdition;
  var match = Navigation$Pos.useGoBack(undefined);
  var onGoBack = match[1];
  var canGoBack = match[0];
  var action = OrderStatus$Pos.toAction(statuses);
  var createOrder = OrderMutations$Pos.useCreate(undefined);
  var updateOrder = OrderMutations$Pos.useUpdate(id);
  var moveOrder = OrderMutations$Pos.useMove(id, action);
  var unarchive = OrderMutations$Pos.useUnarchive(id);
  var match$1 = Belt_Array.getExn(statuses, 0);
  if (edition) {
    return React.createElement(Inline$Pos.make, {
                children: null,
                space: "small"
              }, React.createElement(OrderEditForm$Pos.ResetButton.make, {
                    variation: "neutral",
                    size: "medium",
                    onPress: (function (param) {
                        if (id !== undefined || !canGoBack) {
                          return Curry._1(onRequestCancelEdition, undefined);
                        } else {
                          return Curry._1(onGoBack, undefined);
                        }
                      }),
                    text: Intl$Pos.t("Discard")
                  }), React.createElement(OrderEditForm$Pos.SubmitButton.make, {
                    variation: "success",
                    size: "medium",
                    onSubmit: Belt_Option.isNone(id) ? createOrder : updateOrder,
                    text: Intl$Pos.t(Belt_Option.isNone(id) ? "Create draft" : "Save")
                  }));
  } else if (match$1 === "ARCHIVED") {
    return React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Unarchive"), "medium", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(unarchive, undefined);
                    }), undefined, undefined, undefined));
  } else if (match$1 === "DRAFT") {
    return React.createElement(OrderEditForm$Pos.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: Intl$Pos.t("Finalize")
              });
  } else if (match$1 === "FINALIZED") {
    return React.createElement(OrderEditForm$Pos.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: Intl$Pos.t("Define as accepted")
              });
  } else if (match$1 === "ACCEPTED") {
    return React.createElement(OrderEditForm$Pos.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: Intl$Pos.t("Start reception")
              });
  } else if (match$1 === "RECEIVING") {
    return React.createElement(OrderEditForm$Pos.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: Intl$Pos.t("Finish reception")
              });
  } else if (match$1 === "RECEIVED" && !OrderStatus$Pos.has(statuses, "PAID")) {
    return React.createElement(OrderEditForm$Pos.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: Intl$Pos.t("Define as paid")
              });
  } else {
    return null;
  }
}

var make = React.memo(OrderPageActions);

export {
  make ,
}
/* make Not a pure module */
