// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query products($filterBy: InputProductsQueryFilter)  {\nproducts(filterBy: $filterBy)  {\n__typename  \ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nvariants(first: 50, filterBy: {archived: INCLUDED})  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \ncku  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.products;
  var value$2 = value$1.edges;
  return {
          products: {
            __typename: value$1.__typename,
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.variants;
                    var value$3 = value$2.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              variants: {
                                __typename: value$2.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  cku: Scalar$Pos.CKU.parse(value$1.cku)
                                                }
                                              };
                                      }), value$3)
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.products;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variants;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.cku;
                  var value$3 = Scalar$Pos.CKU.serialize(value$2);
                  var value$4 = value$1.__typename;
                  var node = {
                    __typename: value$4,
                    cku: value$3
                  };
                  var value$5 = value.__typename;
                  return {
                          __typename: value$5,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variants = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.__typename;
          var node = {
            __typename: value$5,
            variants: variants
          };
          var value$6 = value.__typename;
          return {
                  __typename: value$6,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.__typename;
  var products = {
    __typename: value$4,
    totalCount: value$3,
    edges: edges
  };
  return {
          products: products
        };
}

function serializeInputObjectProductColorEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a === "ORANGE" ? "ORANGE" : (
              a === "RED" ? "RED" : (
                  a === "AMBER" ? "AMBER" : (
                      a === "DARK" ? "DARK" : (
                          a === "BLACK" ? "BLACK" : (
                              a === "BLOND" ? "BLOND" : (
                                  a === "ROSE" ? "ROSE" : "WHITE"
                                )
                            )
                        )
                    )
                )
            )
        };
}

function serializeInputObjectProductKindEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a === "SPIRITUOUS" ? "SPIRITUOUS" : (
              a === "BEER" ? "BEER" : (
                  a === "WINE" ? "WINE" : "SIMPLE"
                )
            )
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputProductsQueryFilter(inp) {
  var a = inp.name;
  var a$1 = inp.kind;
  var a$2 = inp.producer;
  var a$3 = inp.color;
  var a$4 = inp.variantCkus;
  var a$5 = inp.shopIds;
  var a$6 = inp.archived;
  return {
          name: a !== undefined ? ({
                _equals: a._equals
              }) : undefined,
          kind: a$1 !== undefined ? serializeInputObjectProductKindEqualsFilter(a$1) : undefined,
          producer: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          color: a$3 !== undefined ? serializeInputObjectProductColorEqualsFilter(a$3) : undefined,
          variantCkus: a$4 !== undefined ? serializeInputObjectInFilter(a$4) : undefined,
          shopIds: a$5 !== undefined ? serializeInputObjectInFilter(a$5) : undefined,
          archived: a$6 !== undefined ? (
              a$6 === "ONLY" ? "ONLY" : (
                  a$6 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputProductsQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputProductsQueryFilter(name, kind, producer, color, variantCkus, shopIds, archived, param) {
  return {
          name: name,
          kind: kind,
          producer: producer,
          color: color,
          variantCkus: variantCkus,
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectProductKindEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectProductColorEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var ProductsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductsQueryFilter: serializeInputObjectInputProductsQueryFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectProductKindEqualsFilter: serializeInputObjectProductKindEqualsFilter,
  serializeInputObjectProductColorEqualsFilter: serializeInputObjectProductColorEqualsFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductsQueryFilter: makeInputObjectInputProductsQueryFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectProductKindEqualsFilter: makeInputObjectProductKindEqualsFilter,
  makeInputObjectProductColorEqualsFilter: makeInputObjectProductColorEqualsFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var useLazy = include.useLazy;

var ProductsQuery_refetchQueryDescription = include.refetchQueryDescription;

var ProductsQuery_use = include.use;

var ProductsQuery_useLazyWithVariables = include.useLazyWithVariables;

var ProductsQuery = {
  ProductsQuery_inner: ProductsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductsQueryFilter: serializeInputObjectInputProductsQueryFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectProductKindEqualsFilter: serializeInputObjectProductKindEqualsFilter,
  serializeInputObjectProductColorEqualsFilter: serializeInputObjectProductColorEqualsFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductsQueryFilter: makeInputObjectInputProductsQueryFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectProductKindEqualsFilter: makeInputObjectProductKindEqualsFilter,
  makeInputObjectProductColorEqualsFilter: makeInputObjectProductColorEqualsFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: ProductsQuery_refetchQueryDescription,
  use: ProductsQuery_use,
  useLazy: useLazy,
  useLazyWithVariables: ProductsQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query productsByVariantCku($cku: CKU!)  {\nproductsByVariantCku(cku: $cku, filterBy: {archived: INCLUDED})  {\n__typename  \ntotalCount  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.productsByVariantCku;
  return {
          productsByVariantCku: {
            __typename: value$1.__typename,
            totalCount: value$1.totalCount
          }
        };
}

function serialize$1(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.__typename;
  var productsByVariantCku = {
    __typename: value$3,
    totalCount: value$2
  };
  return {
          productsByVariantCku: productsByVariantCku
        };
}

function serializeVariables$1(inp) {
  return {
          cku: inp.cku
        };
}

function makeVariables$1(cku, param) {
  return {
          cku: cku
        };
}

var ProductsByVariantCku_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var ProductsByVariantCku_refetchQueryDescription = include$1.refetchQueryDescription;

var ProductsByVariantCku_use = include$1.use;

var ProductsByVariantCku_useLazy = include$1.useLazy;

var ProductsByVariantCku_useLazyWithVariables = include$1.useLazyWithVariables;

var ProductsByVariantCku = {
  ProductsByVariantCku_inner: ProductsByVariantCku_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  refetchQueryDescription: ProductsByVariantCku_refetchQueryDescription,
  use: ProductsByVariantCku_use,
  useLazy: ProductsByVariantCku_useLazy,
  useLazyWithVariables: ProductsByVariantCku_useLazyWithVariables
};

function runFindingRelevantVariantCku(productsByVariantCkuFetch, variantsCku, variantsCkuActiveIndexOpt, relevantVariantCku, onDone, param) {
  var variantsCkuActiveIndex = variantsCkuActiveIndexOpt !== undefined ? variantsCkuActiveIndexOpt : 0;
  var match = Belt_Array.get(variantsCku, variantsCkuActiveIndex);
  if (match !== undefined) {
    return Future.get(FuturePromise.fromPromise(Curry._1(productsByVariantCkuFetch, match)), (function (response) {
                  if (response.TAG !== /* Ok */0) {
                    return Curry._1(onDone, /* Error */1);
                  }
                  var match$1 = response._0;
                  if (match$1.TAG !== /* Ok */0) {
                    return Curry._1(onDone, /* Error */1);
                  }
                  var match$2 = match$1._0;
                  if (match$2.error !== undefined) {
                    return Curry._1(onDone, /* Error */1);
                  }
                  var totalCount = match$2.data.productsByVariantCku.totalCount;
                  var relevantVariantCku$1 = relevantVariantCku !== undefined && relevantVariantCku.productsCount >= totalCount ? relevantVariantCku : ({
                        cku: match,
                        productsCount: totalCount
                      });
                  runFindingRelevantVariantCku(productsByVariantCkuFetch, variantsCku, variantsCkuActiveIndex + 1 | 0, relevantVariantCku$1, onDone, undefined);
                }));
  } else if (relevantVariantCku !== undefined) {
    return Curry._1(onDone, /* Success */{
                _0: relevantVariantCku.cku
              });
  } else {
    return Curry._1(onDone, /* Error */1);
  }
}

function CatalogProductByCkuPage(Props) {
  var cku = Props.cku;
  var redirectToCreateVariantOpt = Props.redirectToCreateVariant;
  var redirectToCreateVariant = redirectToCreateVariantOpt !== undefined ? redirectToCreateVariantOpt : false;
  var shops = Auth$Pos.useShops(undefined);
  var authDispatch = Auth$Pos.useDispatch(undefined);
  var navigate = Navigation$Pos.useNavigate(undefined);
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = Curry.app(useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryResults = match[1];
  var executeQuery = match[0];
  var match$1 = React.useState(function () {
        return /* Loading */0;
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  React.useEffect((function () {
          var match = shops.length;
          if (match !== 0) {
            if (match !== 1) {
              Curry._3(executeQuery, undefined, undefined, {
                    filterBy: makeInputObjectInputProductsQueryFilter(undefined, undefined, undefined, undefined, {
                          _in: [cku]
                        }, undefined, "INCLUDED", undefined)
                  });
            } else {
              Curry._1(setStatus, (function (param) {
                      return /* Success */{
                              _0: cku
                            };
                    }));
            }
          }
          
        }), [shops]);
  var productsByVariantCkuFetch = function (cku) {
    return Curry._6(apolloClient.rescript_query, {
                query: query$1,
                Raw: Raw$1,
                parse: parse$1,
                serialize: serialize$1,
                serializeVariables: serializeVariables$1
              }, undefined, undefined, /* NetworkOnly */2, undefined, {
                cku: Scalar$Pos.CKU.serialize(cku)
              });
  };
  React.useEffect((function () {
          if (queryResults.TAG === /* Executed */0) {
            var match = queryResults._0;
            var match$1 = match.data;
            if (match$1 !== undefined) {
              var match$2 = match$1.products;
              var variantsCku = Belt_Option.map(Belt_Array.get(match$2.edges, 0), (function (param) {
                      return Belt_Array.map(param.node.variants.edges, (function (param) {
                                    return param.node.cku;
                                  }));
                    }));
              if (variantsCku !== undefined) {
                if (match$2.totalCount >= shops.length) {
                  Curry._1(setStatus, (function (param) {
                          return /* Success */{
                                  _0: cku
                                };
                        }));
                } else if (variantsCku.length !== 1) {
                  runFindingRelevantVariantCku(productsByVariantCkuFetch, variantsCku, undefined, undefined, (function (result) {
                          Curry._1(authDispatch, {
                                TAG: /* ActiveShopSet */2,
                                _0: undefined
                              });
                          Curry._1(setStatus, (function (param) {
                                  return result;
                                }));
                        }), undefined);
                } else {
                  var cku$1 = variantsCku[0];
                  Curry._1(setStatus, (function (param) {
                          return /* Success */{
                                  _0: cku$1
                                };
                        }));
                }
              } else {
                Curry._1(setStatus, (function (param) {
                        return /* Error */1;
                      }));
              }
            } else if (match.error !== undefined) {
              Curry._1(setStatus, (function (param) {
                      return /* Error */1;
                    }));
            }
            
          }
          
        }), [queryResults]);
  if (typeof status === "number") {
    if (status !== 0) {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Error */2
                });
    } else {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Loading */0
                });
    }
  }
  var cku$1 = status._0;
  if (redirectToCreateVariant) {
    Curry._4(navigate, LegacyRouter$Pos.routeToPathname(/* Catalog */2) + ("/" + cku$1 + "/create"), true, undefined, undefined);
  } else {
    Curry._4(navigate, LegacyRouter$Pos.routeToPathname(/* Catalog */2) + ("/product/" + cku$1 + ""), true, undefined, undefined);
  }
  return null;
}

var make = React.memo(CatalogProductByCkuPage);

export {
  ProductsQuery ,
  ProductsByVariantCku ,
  runFindingRelevantVariantCku ,
  make ,
}
/* query Not a pure module */
