// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Badge$Pos from "../../resources/feedback-indicators/Badge.bs.js";

function PromotionStatusBadge(Props) {
  var status = Props.status;
  if (status === "STOPPED") {
    return React.createElement(Badge$Pos.make, {
                children: Intl$Pos.t("Stopped"),
                variation: "danger"
              });
  } else if (status === "ARCHIVED") {
    return null;
  } else if (status === "DRAFT") {
    return React.createElement(Badge$Pos.make, {
                children: Intl$Pos.t("Draft"),
                variation: "neutral"
              });
  } else if (status === "NOT_PROGRAMMED") {
    return React.createElement(Badge$Pos.make, {
                children: Intl$Pos.t("Unscheduled"),
                variation: "danger"
              });
  } else if (status === "ONGOING") {
    return React.createElement(Badge$Pos.make, {
                children: Intl$Pos.t("Ongoing"),
                variation: "success"
              });
  } else if (status === "EXPIRED") {
    return React.createElement(Badge$Pos.make, {
                children: Intl$Pos.t("Expired"),
                variation: "warning"
              });
  } else {
    return React.createElement(Badge$Pos.make, {
                children: Intl$Pos.t("Scheduled"),
                variation: "information"
              });
  }
}

var make = React.memo(PromotionStatusBadge);

export {
  make ,
}
/* make Not a pure module */
