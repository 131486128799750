// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Json$Pos from "./Json.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function mapError(result, fn) {
  if (result.TAG === /* Ok */0) {
    return result;
  } else {
    return {
            TAG: /* Error */1,
            _0: Curry._1(fn, result._0)
          };
  }
}

function prependLocation(err, loc) {
  var variant = err.NAME;
  if (variant === "MissingField") {
    var match = err.VAL;
    var location$p = Belt_Array.concat([loc], match[0]);
    return {
            NAME: "MissingField",
            VAL: [
              location$p,
              match[1]
            ]
          };
  }
  if (variant === "SyntaxError") {
    return err;
  }
  if (variant === "UnexpectedJsonType") {
    var match$1 = err.VAL;
    var location$p$1 = Belt_Array.concat([loc], match$1[0]);
    return {
            NAME: "UnexpectedJsonType",
            VAL: [
              location$p$1,
              match$1[1],
              match$1[2]
            ]
          };
  }
  var match$2 = err.VAL;
  var location$p$2 = Belt_Array.concat([loc], match$2[0]);
  return {
          NAME: "UnexpectedJsonValue",
          VAL: [
            location$p$2,
            match$2[1]
          ]
        };
}

function encodeWith(data, codec) {
  return Curry._1(codec.encode, data);
}

function decodeWith(json, codec) {
  return Curry._1(codec.decode, json);
}

function string_decode(json) {
  var x = Json$Pos.decodeString(json);
  if (x !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: x
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "UnexpectedJsonType",
              VAL: [
                [],
                "string",
                json
              ]
            }
          };
  }
}

var string = {
  encode: Json$Pos.encodeString,
  decode: string_decode
};

function float_decode(json) {
  var x = Json$Pos.decodeNumber(json);
  if (x !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: x
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "UnexpectedJsonType",
              VAL: [
                [],
                "number",
                json
              ]
            }
          };
  }
}

var $$float = {
  encode: Json$Pos.encodeNumber,
  decode: float_decode
};

var int_encode = Json$Pos.encodeNumber;

function int_decode(json) {
  return Belt_Result.flatMap(Curry._1(float_decode, json), (function (x) {
                if (x === Math.trunc(x) && x >= -2147483648 && x <= 2147483647) {
                  return {
                          TAG: /* Ok */0,
                          _0: Math.trunc(x)
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            NAME: "UnexpectedJsonValue",
                            VAL: [
                              [],
                              String(x)
                            ]
                          }
                        };
                }
              }));
}

var $$int = {
  encode: int_encode,
  decode: int_decode
};

function bool_decode(json) {
  var x = Json$Pos.decodeBoolean(json);
  if (x !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: x
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "UnexpectedJsonType",
              VAL: [
                [],
                "bool",
                json
              ]
            }
          };
  }
}

var bool = {
  encode: Json$Pos.encodeBoolean,
  decode: bool_decode
};

function nullable(payloadCodec) {
  return {
          encode: (function (maybeValue) {
              if (maybeValue !== undefined) {
                return Curry._1(payloadCodec.encode, Caml_option.valFromOption(maybeValue));
              } else {
                return Json$Pos.encodedNull;
              }
            }),
          decode: (function (json) {
              if (Caml_obj.equal(json, Json$Pos.encodedNull)) {
                return {
                        TAG: /* Ok */0,
                        _0: undefined
                      };
              } else {
                return Belt_Result.map(Curry._1(payloadCodec.decode, json), (function (v) {
                              return Caml_option.some(v);
                            }));
              }
            })
        };
}

function array(elementCodec) {
  return {
          encode: (function (x) {
              return Json$Pos.encodeArray(Belt_Array.map(x, elementCodec.encode));
            }),
          decode: (function (json) {
              var elementJsons = Js_json.classify(json);
              if (typeof elementJsons !== "number" && elementJsons.TAG === /* JSONArray */3) {
                var results = Belt_Array.mapWithIndex(elementJsons._0, (function (i, elemJson) {
                        return mapError(Curry._1(elementCodec.decode, elemJson), (function (__x) {
                                      return prependLocation(__x, {
                                                  TAG: /* Index */1,
                                                  _0: i
                                                });
                                    }));
                      }));
                return Belt_Array.reduce(results, {
                            TAG: /* Ok */0,
                            _0: []
                          }, (function (maybeAcc, res) {
                              return Belt_Result.flatMap(maybeAcc, (function (acc) {
                                            return Belt_Result.map(res, (function (x) {
                                                          return Belt_Array.concat(acc, [x]);
                                                        }));
                                          }));
                            }));
              }
              return {
                      TAG: /* Error */1,
                      _0: {
                        NAME: "UnexpectedJsonType",
                        VAL: [
                          [],
                          "array",
                          json
                        ]
                      }
                    };
            })
        };
}

function asObject(json) {
  var fieldset = Js_json.classify(json);
  if (typeof fieldset !== "number" && fieldset.TAG === /* JSONObject */2) {
    return {
            TAG: /* Ok */0,
            _0: fieldset._0
          };
  }
  return {
          TAG: /* Error */1,
          _0: {
            NAME: "UnexpectedJsonType",
            VAL: [
              [],
              "object",
              json
            ]
          }
        };
}

function makeOptional(param) {
  return {
          path: param.path,
          codec: nullable(param.codec),
          claim: /* Optional */1
        };
}

function path(param) {
  return param.path;
}

function codec(param) {
  return param.codec;
}

function claim(param) {
  return param.claim;
}

function encode(field, val) {
  var key = path(field);
  var json = Curry._1(codec(field).encode, val);
  if (Caml_obj.equal(json, Json$Pos.encodedNull) && claim(field) === /* Optional */1) {
    return [];
  } else {
    return [[
              key._0,
              json
            ]];
  }
}

function decode(field, fieldset) {
  var key = path(field);
  var key$1 = key._0;
  var decodeChild = function (childJson) {
    return mapError(Curry._1(codec(field).decode, childJson), (function (__x) {
                  return prependLocation(__x, {
                              TAG: /* Field */0,
                              _0: key$1
                            });
                }));
  };
  var match = Js_dict.get(fieldset, key$1);
  var match$1 = claim(field);
  if (match !== undefined) {
    return decodeChild(Caml_option.valFromOption(match));
  } else if (match$1) {
    return decodeChild(Json$Pos.encodedNull);
  } else {
    return {
            TAG: /* Error */1,
            _0: {
              NAME: "MissingField",
              VAL: [
                [],
                key$1
              ]
            }
          };
  }
}

function field(key, codec) {
  return {
          path: /* Key */{
            _0: key
          },
          codec: codec,
          claim: /* Required */0
        };
}

function object1(destruct, construct, field1) {
  return {
          encode: (function (value) {
              var val1 = Curry._1(destruct, value);
              var keyVals = [encode(field1, val1)];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG === /* Ok */0) {
                              return Curry._1(construct, val1._0);
                            } else {
                              return val1;
                            }
                          }));
            })
        };
}

function object2(destruct, construct, field1, field2) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0
                                        ]);
                            } else {
                              return val2;
                            }
                          }));
            })
        };
}

function object3(destruct, construct, field1, field2, field3) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0
                                        ]);
                            } else {
                              return val3;
                            }
                          }));
            })
        };
}

function object4(destruct, construct, field1, field2, field3, field4) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0
                                        ]);
                            } else {
                              return val4;
                            }
                          }));
            })
        };
}

function object5(destruct, construct, field1, field2, field3, field4, field5) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0
                                        ]);
                            } else {
                              return val5;
                            }
                          }));
            })
        };
}

function object6(destruct, construct, field1, field2, field3, field4, field5, field6) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0
                                        ]);
                            } else {
                              return val6;
                            }
                          }));
            })
        };
}

function object7(destruct, construct, field1, field2, field3, field4, field5, field6, field7) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0
                                        ]);
                            } else {
                              return val7;
                            }
                          }));
            })
        };
}

function object8(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0
                                        ]);
                            } else {
                              return val8;
                            }
                          }));
            })
        };
}

function object9(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0
                                        ]);
                            } else {
                              return val9;
                            }
                          }));
            })
        };
}

function object10(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0
                                        ]);
                            } else {
                              return val10;
                            }
                          }));
            })
        };
}

function object11(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0
                                        ]);
                            } else {
                              return val11;
                            }
                          }));
            })
        };
}

function object12(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0
                                        ]);
                            } else {
                              return val12;
                            }
                          }));
            })
        };
}

function object13(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0
                                        ]);
                            } else {
                              return val13;
                            }
                          }));
            })
        };
}

function object14(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0
                                        ]);
                            } else {
                              return val14;
                            }
                          }));
            })
        };
}

function object15(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0
                                        ]);
                            } else {
                              return val15;
                            }
                          }));
            })
        };
}

function object16(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0
                                        ]);
                            } else {
                              return val16;
                            }
                          }));
            })
        };
}

function object17(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0
                                        ]);
                            } else {
                              return val17;
                            }
                          }));
            })
        };
}

function object18(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17, field18) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16]),
                encode(field18, match[17])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG !== /* Ok */0) {
                              return val17;
                            }
                            var val18 = decode(field18, fieldset);
                            if (val18.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0,
                                          val18._0
                                        ]);
                            } else {
                              return val18;
                            }
                          }));
            })
        };
}

function object19(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17, field18, field19) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16]),
                encode(field18, match[17]),
                encode(field19, match[18])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG !== /* Ok */0) {
                              return val17;
                            }
                            var val18 = decode(field18, fieldset);
                            if (val18.TAG !== /* Ok */0) {
                              return val18;
                            }
                            var val19 = decode(field19, fieldset);
                            if (val19.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0,
                                          val18._0,
                                          val19._0
                                        ]);
                            } else {
                              return val19;
                            }
                          }));
            })
        };
}

function object20(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17, field18, field19, field20) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16]),
                encode(field18, match[17]),
                encode(field19, match[18]),
                encode(field20, match[19])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG !== /* Ok */0) {
                              return val17;
                            }
                            var val18 = decode(field18, fieldset);
                            if (val18.TAG !== /* Ok */0) {
                              return val18;
                            }
                            var val19 = decode(field19, fieldset);
                            if (val19.TAG !== /* Ok */0) {
                              return val19;
                            }
                            var val20 = decode(field20, fieldset);
                            if (val20.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0,
                                          val18._0,
                                          val19._0,
                                          val20._0
                                        ]);
                            } else {
                              return val20;
                            }
                          }));
            })
        };
}

function object21(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17, field18, field19, field20, field21) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16]),
                encode(field18, match[17]),
                encode(field19, match[18]),
                encode(field20, match[19]),
                encode(field21, match[20])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG !== /* Ok */0) {
                              return val17;
                            }
                            var val18 = decode(field18, fieldset);
                            if (val18.TAG !== /* Ok */0) {
                              return val18;
                            }
                            var val19 = decode(field19, fieldset);
                            if (val19.TAG !== /* Ok */0) {
                              return val19;
                            }
                            var val20 = decode(field20, fieldset);
                            if (val20.TAG !== /* Ok */0) {
                              return val20;
                            }
                            var val21 = decode(field21, fieldset);
                            if (val21.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0,
                                          val18._0,
                                          val19._0,
                                          val20._0,
                                          val21._0
                                        ]);
                            } else {
                              return val21;
                            }
                          }));
            })
        };
}

function object22(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17, field18, field19, field20, field21, field22) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16]),
                encode(field18, match[17]),
                encode(field19, match[18]),
                encode(field20, match[19]),
                encode(field21, match[20]),
                encode(field22, match[21])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG !== /* Ok */0) {
                              return val17;
                            }
                            var val18 = decode(field18, fieldset);
                            if (val18.TAG !== /* Ok */0) {
                              return val18;
                            }
                            var val19 = decode(field19, fieldset);
                            if (val19.TAG !== /* Ok */0) {
                              return val19;
                            }
                            var val20 = decode(field20, fieldset);
                            if (val20.TAG !== /* Ok */0) {
                              return val20;
                            }
                            var val21 = decode(field21, fieldset);
                            if (val21.TAG !== /* Ok */0) {
                              return val21;
                            }
                            var val22 = decode(field22, fieldset);
                            if (val22.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0,
                                          val18._0,
                                          val19._0,
                                          val20._0,
                                          val21._0,
                                          val22._0
                                        ]);
                            } else {
                              return val22;
                            }
                          }));
            })
        };
}

function object23(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17, field18, field19, field20, field21, field22, field23) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16]),
                encode(field18, match[17]),
                encode(field19, match[18]),
                encode(field20, match[19]),
                encode(field21, match[20]),
                encode(field22, match[21]),
                encode(field23, match[22])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG !== /* Ok */0) {
                              return val17;
                            }
                            var val18 = decode(field18, fieldset);
                            if (val18.TAG !== /* Ok */0) {
                              return val18;
                            }
                            var val19 = decode(field19, fieldset);
                            if (val19.TAG !== /* Ok */0) {
                              return val19;
                            }
                            var val20 = decode(field20, fieldset);
                            if (val20.TAG !== /* Ok */0) {
                              return val20;
                            }
                            var val21 = decode(field21, fieldset);
                            if (val21.TAG !== /* Ok */0) {
                              return val21;
                            }
                            var val22 = decode(field22, fieldset);
                            if (val22.TAG !== /* Ok */0) {
                              return val22;
                            }
                            var val23 = decode(field23, fieldset);
                            if (val23.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0,
                                          val18._0,
                                          val19._0,
                                          val20._0,
                                          val21._0,
                                          val22._0,
                                          val23._0
                                        ]);
                            } else {
                              return val23;
                            }
                          }));
            })
        };
}

function object24(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17, field18, field19, field20, field21, field22, field23, field24) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16]),
                encode(field18, match[17]),
                encode(field19, match[18]),
                encode(field20, match[19]),
                encode(field21, match[20]),
                encode(field22, match[21]),
                encode(field23, match[22]),
                encode(field24, match[23])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG !== /* Ok */0) {
                              return val17;
                            }
                            var val18 = decode(field18, fieldset);
                            if (val18.TAG !== /* Ok */0) {
                              return val18;
                            }
                            var val19 = decode(field19, fieldset);
                            if (val19.TAG !== /* Ok */0) {
                              return val19;
                            }
                            var val20 = decode(field20, fieldset);
                            if (val20.TAG !== /* Ok */0) {
                              return val20;
                            }
                            var val21 = decode(field21, fieldset);
                            if (val21.TAG !== /* Ok */0) {
                              return val21;
                            }
                            var val22 = decode(field22, fieldset);
                            if (val22.TAG !== /* Ok */0) {
                              return val22;
                            }
                            var val23 = decode(field23, fieldset);
                            if (val23.TAG !== /* Ok */0) {
                              return val23;
                            }
                            var val24 = decode(field24, fieldset);
                            if (val24.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0,
                                          val18._0,
                                          val19._0,
                                          val20._0,
                                          val21._0,
                                          val22._0,
                                          val23._0,
                                          val24._0
                                        ]);
                            } else {
                              return val24;
                            }
                          }));
            })
        };
}

function object25(destruct, construct, field1, field2, field3, field4, field5, field6, field7, field8, field9, field10, field11, field12, field13, field14, field15, field16, field17, field18, field19, field20, field21, field22, field23, field24, field25) {
  return {
          encode: (function (value) {
              var match = Curry._1(destruct, value);
              var keyVals = [
                encode(field1, match[0]),
                encode(field2, match[1]),
                encode(field3, match[2]),
                encode(field4, match[3]),
                encode(field5, match[4]),
                encode(field6, match[5]),
                encode(field7, match[6]),
                encode(field8, match[7]),
                encode(field9, match[8]),
                encode(field10, match[9]),
                encode(field11, match[10]),
                encode(field12, match[11]),
                encode(field13, match[12]),
                encode(field14, match[13]),
                encode(field15, match[14]),
                encode(field16, match[15]),
                encode(field17, match[16]),
                encode(field18, match[17]),
                encode(field19, match[18]),
                encode(field20, match[19]),
                encode(field21, match[20]),
                encode(field22, match[21]),
                encode(field23, match[22]),
                encode(field24, match[23]),
                encode(field25, match[24])
              ];
              return Json$Pos.encodeDict(Js_dict.fromArray(Belt_Array.concatMany(keyVals)));
            }),
          decode: (function (json) {
              return Belt_Result.flatMap(asObject(json), (function (fieldset) {
                            var val1 = decode(field1, fieldset);
                            if (val1.TAG !== /* Ok */0) {
                              return val1;
                            }
                            var val2 = decode(field2, fieldset);
                            if (val2.TAG !== /* Ok */0) {
                              return val2;
                            }
                            var val3 = decode(field3, fieldset);
                            if (val3.TAG !== /* Ok */0) {
                              return val3;
                            }
                            var val4 = decode(field4, fieldset);
                            if (val4.TAG !== /* Ok */0) {
                              return val4;
                            }
                            var val5 = decode(field5, fieldset);
                            if (val5.TAG !== /* Ok */0) {
                              return val5;
                            }
                            var val6 = decode(field6, fieldset);
                            if (val6.TAG !== /* Ok */0) {
                              return val6;
                            }
                            var val7 = decode(field7, fieldset);
                            if (val7.TAG !== /* Ok */0) {
                              return val7;
                            }
                            var val8 = decode(field8, fieldset);
                            if (val8.TAG !== /* Ok */0) {
                              return val8;
                            }
                            var val9 = decode(field9, fieldset);
                            if (val9.TAG !== /* Ok */0) {
                              return val9;
                            }
                            var val10 = decode(field10, fieldset);
                            if (val10.TAG !== /* Ok */0) {
                              return val10;
                            }
                            var val11 = decode(field11, fieldset);
                            if (val11.TAG !== /* Ok */0) {
                              return val11;
                            }
                            var val12 = decode(field12, fieldset);
                            if (val12.TAG !== /* Ok */0) {
                              return val12;
                            }
                            var val13 = decode(field13, fieldset);
                            if (val13.TAG !== /* Ok */0) {
                              return val13;
                            }
                            var val14 = decode(field14, fieldset);
                            if (val14.TAG !== /* Ok */0) {
                              return val14;
                            }
                            var val15 = decode(field15, fieldset);
                            if (val15.TAG !== /* Ok */0) {
                              return val15;
                            }
                            var val16 = decode(field16, fieldset);
                            if (val16.TAG !== /* Ok */0) {
                              return val16;
                            }
                            var val17 = decode(field17, fieldset);
                            if (val17.TAG !== /* Ok */0) {
                              return val17;
                            }
                            var val18 = decode(field18, fieldset);
                            if (val18.TAG !== /* Ok */0) {
                              return val18;
                            }
                            var val19 = decode(field19, fieldset);
                            if (val19.TAG !== /* Ok */0) {
                              return val19;
                            }
                            var val20 = decode(field20, fieldset);
                            if (val20.TAG !== /* Ok */0) {
                              return val20;
                            }
                            var val21 = decode(field21, fieldset);
                            if (val21.TAG !== /* Ok */0) {
                              return val21;
                            }
                            var val22 = decode(field22, fieldset);
                            if (val22.TAG !== /* Ok */0) {
                              return val22;
                            }
                            var val23 = decode(field23, fieldset);
                            if (val23.TAG !== /* Ok */0) {
                              return val23;
                            }
                            var val24 = decode(field24, fieldset);
                            if (val24.TAG !== /* Ok */0) {
                              return val24;
                            }
                            var val25 = decode(field25, fieldset);
                            if (val25.TAG === /* Ok */0) {
                              return Curry._1(construct, [
                                          val1._0,
                                          val2._0,
                                          val3._0,
                                          val4._0,
                                          val5._0,
                                          val6._0,
                                          val7._0,
                                          val8._0,
                                          val9._0,
                                          val10._0,
                                          val11._0,
                                          val12._0,
                                          val13._0,
                                          val14._0,
                                          val15._0,
                                          val16._0,
                                          val17._0,
                                          val18._0,
                                          val19._0,
                                          val20._0,
                                          val21._0,
                                          val22._0,
                                          val23._0,
                                          val24._0,
                                          val25._0
                                        ]);
                            } else {
                              return val25;
                            }
                          }));
            })
        };
}

var DecodingError = {};

var optional = makeOptional;

export {
  DecodingError ,
  encodeWith ,
  decodeWith ,
  string ,
  $$float ,
  $$int ,
  bool ,
  array ,
  field ,
  optional ,
  object1 ,
  object2 ,
  object3 ,
  object4 ,
  object5 ,
  object6 ,
  object7 ,
  object8 ,
  object9 ,
  object10 ,
  object11 ,
  object12 ,
  object13 ,
  object14 ,
  object15 ,
  object16 ,
  object17 ,
  object18 ,
  object19 ,
  object20 ,
  object21 ,
  object22 ,
  object23 ,
  object24 ,
  object25 ,
}
/* Json-Pos Not a pure module */
