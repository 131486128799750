// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Colors$Pos from "../../resources/theme/Colors.bs.js";

function toLabel(status) {
  switch (status) {
    case /* Active */0 :
        return Intl$Pos.t("On sale");
    case /* Inactive */1 :
        return Intl$Pos.t("Taken out");
    case /* Archived */2 :
        return Intl$Pos.t("Archived");
    case /* Unarchived */3 :
        return Intl$Pos.t("Not archived");
    
  }
}

function toString(status) {
  switch (status) {
    case /* Active */0 :
        return "ACTIVE";
    case /* Inactive */1 :
        return "INACTIVE";
    case /* Archived */2 :
        return "ARCHIVED";
    case /* Unarchived */3 :
        return "UNARCHIVED";
    
  }
}

function fromString(str) {
  switch (str) {
    case "ACTIVE" :
        return {
                TAG: /* Ok */0,
                _0: /* Active */0
              };
    case "ARCHIVED" :
        return {
                TAG: /* Ok */0,
                _0: /* Archived */2
              };
    case "INACTIVE" :
        return {
                TAG: /* Ok */0,
                _0: /* Inactive */1
              };
    case "UNARCHIVED" :
        return {
                TAG: /* Ok */0,
                _0: /* Unarchived */3
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Invalid product status"
            };
  }
}

var Status = {
  toLabel: toLabel,
  toString: toString,
  fromString: fromString
};

function toLabel$1(titleCaseOpt, translateOpt, kind) {
  var titleCase = titleCaseOpt !== undefined ? titleCaseOpt : false;
  var translate = translateOpt !== undefined ? translateOpt : true;
  var label = kind === "SPIRITUOUS" ? "spirituous" : (
      kind === "BEER" ? "beer" : (
          kind === "WINE" ? "wine" : "simple product"
        )
    );
  var label$1 = translate ? Intl$Pos.t(label) : label;
  if (titleCase) {
    return label$1.charAt(0).toUpperCase() + label$1.substr(1);
  } else {
    return label$1;
  }
}

function toString$1(kind) {
  return kind;
}

function fromString$1(str) {
  switch (str) {
    case "BEER" :
        return {
                TAG: /* Ok */0,
                _0: "BEER"
              };
    case "SIMPLE" :
        return {
                TAG: /* Ok */0,
                _0: "SIMPLE"
              };
    case "SPIRITUOUS" :
        return {
                TAG: /* Ok */0,
                _0: "SPIRITUOUS"
              };
    case "WINE" :
        return {
                TAG: /* Ok */0,
                _0: "WINE"
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Invalid kind"
            };
  }
}

var Kind = {
  toLabel: toLabel$1,
  toString: toString$1,
  fromString: fromString$1
};

function toLabel$2(x) {
  if (x === "ORANGE") {
    return Intl$Pos.t("Orange");
  } else if (x === "RED") {
    return Intl$Pos.t("Red");
  } else if (x === "AMBER") {
    return Intl$Pos.t("Amber");
  } else if (x === "DARK") {
    return Intl$Pos.t("Dark");
  } else if (x === "BLACK") {
    return Intl$Pos.t("Black");
  } else if (x === "BLOND") {
    return Intl$Pos.t("Blond");
  } else if (x === "ROSE") {
    return Intl$Pos.t("Rose");
  } else {
    return Intl$Pos.t("White");
  }
}

function toColorSet(value, variation) {
  if (value === "BLOND" || value === "ORANGE") {
    return {
            foregroundColor: Colors$Pos.Product.blondTextColor,
            backgroundColor: Colors$Pos.Product.blondBadgeColor
          };
  } else if (value === "RED") {
    return {
            foregroundColor: Colors$Pos.Product.redTextColor,
            backgroundColor: Colors$Pos.Product.redBadgeColor
          };
  } else if (value === "AMBER") {
    return {
            foregroundColor: Colors$Pos.Product.amberTextColor,
            backgroundColor: Colors$Pos.Product.amberBadgeColor
          };
  } else if (value === "DARK") {
    return {
            foregroundColor: Colors$Pos.Product.darkTextColor,
            backgroundColor: Colors$Pos.Product.darkBadgeColor
          };
  } else if (value === "BLACK") {
    return {
            foregroundColor: Colors$Pos.Product.blackTextColor,
            backgroundColor: Colors$Pos.Product.blackBadgeColor
          };
  } else if (value === "ROSE") {
    return {
            foregroundColor: Colors$Pos.Product.roseTextColor,
            backgroundColor: Colors$Pos.Product.roseBadgeColor
          };
  } else if (variation === "pastille") {
    return {
            foregroundColor: Colors$Pos.Product.whitePastilleColor,
            backgroundColor: Colors$Pos.Product.whitePastilleColor
          };
  } else {
    return {
            foregroundColor: Colors$Pos.Product.whiteTextColor,
            backgroundColor: Colors$Pos.Product.whiteBadgeColor
          };
  }
}

function toString$2(color) {
  return color;
}

function fromString$2(str) {
  switch (str) {
    case "AMBER" :
        return {
                TAG: /* Ok */0,
                _0: "AMBER"
              };
    case "BLACK" :
        return {
                TAG: /* Ok */0,
                _0: "BLACK"
              };
    case "BLOND" :
        return {
                TAG: /* Ok */0,
                _0: "BLOND"
              };
    case "DARK" :
        return {
                TAG: /* Ok */0,
                _0: "DARK"
              };
    case "ORANGE" :
        return {
                TAG: /* Ok */0,
                _0: "ORANGE"
              };
    case "RED" :
        return {
                TAG: /* Ok */0,
                _0: "RED"
              };
    case "ROSE" :
        return {
                TAG: /* Ok */0,
                _0: "ROSE"
              };
    case "WHITE" :
        return {
                TAG: /* Ok */0,
                _0: "WHITE"
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Invalid color"
            };
  }
}

var Color = {
  toLabel: toLabel$2,
  toColorSet: toColorSet,
  toString: toString$2,
  fromString: fromString$2
};

function toLabel$3(x) {
  if (x === "EFFERVESCENT") {
    return Intl$Pos.t("Effervescent");
  } else {
    return Intl$Pos.t("Still");
  }
}

function toString$3(wineType) {
  return wineType;
}

function fromString$3(str) {
  switch (str) {
    case "EFFERVESCENT" :
        return {
                TAG: /* Ok */0,
                _0: "EFFERVESCENT"
              };
    case "STILL" :
        return {
                TAG: /* Ok */0,
                _0: "STILL"
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Invalid wine type"
            };
  }
}

var WineType = {
  toLabel: toLabel$3,
  toString: toString$3,
  fromString: fromString$3
};

function toLabel$4(x) {
  if (x === "SWEET") {
    return Intl$Pos.t("Sweet");
  } else if (x === "SEMIDRY") {
    return Intl$Pos.t("Semi dry");
  } else if (x === "SOFT") {
    return Intl$Pos.t("Soft");
  } else {
    return Intl$Pos.t("Dry");
  }
}

function toString$4(whiteWineType) {
  return whiteWineType;
}

function fromString$4(str) {
  switch (str) {
    case "DRY" :
        return {
                TAG: /* Ok */0,
                _0: "DRY"
              };
    case "SEMIDRY" :
        return {
                TAG: /* Ok */0,
                _0: "SEMIDRY"
              };
    case "SOFT" :
        return {
                TAG: /* Ok */0,
                _0: "SOFT"
              };
    case "SWEET" :
        return {
                TAG: /* Ok */0,
                _0: "SWEET"
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Invalid white wine type"
            };
  }
}

var WhiteWineType = {
  toLabel: toLabel$4,
  toString: toString$4,
  fromString: fromString$4
};

export {
  Status ,
  Kind ,
  Color ,
  WineType ,
  WhiteWineType ,
}
/* Intl-Pos Not a pure module */
