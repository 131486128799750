// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json$Pos from "../../primitives/Json.bs.js";

function encodeHttpContext(jwt) {
  var headers = {};
  headers["Authorization"] = Json$Pos.encodeString("Bearer " + jwt);
  var context = {};
  context["headers"] = Json$Pos.encodeDict(headers);
  return Json$Pos.encodeDict(context);
}

export {
  encodeHttpContext ,
}
/* Json-Pos Not a pure module */
