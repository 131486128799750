// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as SalesListPage$Pos from "./SalesListPage.bs.js";

var baseRoute = "/sales";

function SalesRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  if (subUrlPath) {
    return React.createElement(Navigation$Pos.Redirect.make, {
                route: baseRoute
              });
  } else {
    return React.createElement(SalesListPage$Pos.make, {
                request: SalesListPage$Pos.SalesExportRequest.make
              });
  }
}

var make = SalesRouter;

export {
  baseRoute ,
  make ,
}
/* react Not a pure module */
