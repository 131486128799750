// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

function equal(a1, a2) {
  if (a1.address === a2.address && a1.postalCode === a2.postalCode && a1.city === a2.city) {
    return a1.country === a2.country;
  } else {
    return false;
  }
}

var Address_initialValue = {
  address: "",
  postalCode: "",
  city: "",
  country: ""
};

var Address = {
  initialValue: Address_initialValue,
  equal: equal
};

var values = [
  /* SA */0,
  /* SAS */1,
  /* SARL */2,
  /* EI */3,
  /* EIRL */4,
  /* EURL */5,
  /* MICRO */6,
  /* SNC */7,
  /* SCS */8,
  /* SCA */9
];

function toString(value) {
  switch (value) {
    case /* SA */0 :
        return "SA";
    case /* SAS */1 :
        return "SAS";
    case /* SARL */2 :
        return "SARL";
    case /* EI */3 :
        return "EI";
    case /* EIRL */4 :
        return "EIRL";
    case /* EURL */5 :
        return "EURL";
    case /* MICRO */6 :
        return "MICRO";
    case /* SNC */7 :
        return "SNC";
    case /* SCS */8 :
        return "SCS";
    case /* SCA */9 :
        return "SCA";
    
  }
}

function fromString(value) {
  switch (value) {
    case "EI" :
        return {
                TAG: /* Ok */0,
                _0: /* EI */3
              };
    case "EIRL" :
        return {
                TAG: /* Ok */0,
                _0: /* EIRL */4
              };
    case "EURL" :
        return {
                TAG: /* Ok */0,
                _0: /* EURL */5
              };
    case "MICRO" :
        return {
                TAG: /* Ok */0,
                _0: /* MICRO */6
              };
    case "SA" :
        return {
                TAG: /* Ok */0,
                _0: /* SA */0
              };
    case "SARL" :
        return {
                TAG: /* Ok */0,
                _0: /* SARL */2
              };
    case "SAS" :
        return {
                TAG: /* Ok */0,
                _0: /* SAS */1
              };
    case "SCA" :
        return {
                TAG: /* Ok */0,
                _0: /* SCA */9
              };
    case "SCS" :
        return {
                TAG: /* Ok */0,
                _0: /* SCS */8
              };
    case "SNC" :
        return {
                TAG: /* Ok */0,
                _0: /* SNC */7
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Unknown legal form"
            };
  }
}

function compare(a, b) {
  return b.length - a.length | 0;
}

var CompanyLegalForm = {
  values: values,
  toString: toString,
  fromString: fromString,
  compare: compare
};

function sanitize(value) {
  return value.replace(/\s+/g, "");
}

function stripIban(value) {
  return value.replace(new RegExp("[^A-Za-z0-9]+"), "").toUpperCase();
}

function getIbanComponents(ibanStripped) {
  return Caml_option.null_to_opt(new RegExp("^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$").exec(ibanStripped));
}

function getOptionString(optNullable) {
  if (optNullable !== undefined) {
    return Caml_option.nullable_to_opt(Caml_option.valFromOption(optNullable));
  }
  
}

function numericiseIban(bban, country, checkDigits) {
  return Belt_Array.joinWith(Belt_Array.map((bban + country + checkDigits).split(""), (function (ch) {
                    if (ch.charCodeAt(0) >= "A".charCodeAt(0) && ch.charCodeAt(0) <= "Z".charCodeAt(0)) {
                      return String(ch.charCodeAt(0) - 55);
                    } else {
                      return ch;
                    }
                  })), "", (function (a) {
                return a;
              }));
}

function findAllMatches(regex, numbericed) {
  var _acc = [];
  while(true) {
    var acc = _acc;
    var result = regex.exec(numbericed);
    if (result === null) {
      return acc;
    }
    var match = Belt_Option.getExn(Belt_Array.get(result, 0));
    _acc = Belt_Array.concat(acc, [match]);
    continue ;
  };
}

function computeMod97(matches) {
  return Belt_Array.reduce(matches, "", (function (total, curr) {
                return String(Belt_Option.getWithDefault(Belt_Int.fromString(total + Belt_Option.getWithDefault((curr == null) ? undefined : Caml_option.some(curr), "")), 0) % 97);
              }));
}

function validate(value) {
  var trimmedIban = sanitize(value);
  if (!new RegExp("^([A-Za-z]{2}[ \\-]?[0-9]{2})(?=(?:[ \\-]?[A-Za-z0-9]){9,30}$)((?:[ \\-]?[A-Za-z0-9]{3,5}){2,6})([ \\-]?[A-Za-z0-9]{1,3})?$").test(trimmedIban)) {
    return false;
  }
  var ibanStripped = stripIban(trimmedIban);
  var ibanComponents = getIbanComponents(ibanStripped);
  if (ibanComponents === undefined) {
    return false;
  }
  var match = Caml_option.valFromOption(ibanComponents);
  var country = getOptionString(Belt_Array.get(match, 1));
  var checkDigits = getOptionString(Belt_Array.get(match, 2));
  var bban = getOptionString(Belt_Array.get(match, 3));
  if (country === undefined) {
    return false;
  }
  if (checkDigits === undefined) {
    return false;
  }
  if (bban === undefined) {
    return false;
  }
  var numbericed = numericiseIban(bban, country, checkDigits);
  var regex = new RegExp("\\d{1,7}", "g");
  var matches = findAllMatches(regex, numbericed);
  return computeMod97(matches) === "1";
}

var SepaMandate = {};

var prefixes = Belt_Array.map(values, toString);

function strippedCorporateName(corporateName) {
  return Belt_Array.reduce(Belt_SortArray.stableSortBy(prefixes, compare), corporateName, (function (acc, prefixe) {
                  return acc.replace(prefixe, "");
                })).trim();
}

function validate$1(corporateName) {
  return strippedCorporateName(corporateName).length > 3;
}

var Iban = {
  sanitize: sanitize,
  validate: validate
};

var CorporateName = {
  validate: validate$1
};

export {
  Address ,
  CompanyLegalForm ,
  Iban ,
  SepaMandate ,
  CorporateName ,
}
/* prefixes Not a pure module */
