// Generated by ReScript, PLEASE EDIT WITH CARE


var acceptedCountryCodes = [
  /* FR */0,
  /* BE */3,
  /* LU */4
];

function fromString(value) {
  switch (value) {
    case "boost" :
        return {
                TAG: /* Ok */0,
                _0: /* Boost */2
              };
    case "company" :
        return {
                TAG: /* Ok */0,
                _0: /* Company */1
              };
    case "standard" :
        return {
                TAG: /* Ok */0,
                _0: /* Standard */0
              };
    case "warehouse" :
        return {
                TAG: /* Ok */0,
                _0: /* Warehouse */3
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function toString(value) {
  switch (value) {
    case /* Standard */0 :
        return "Standard";
    case /* Company */1 :
        return "Company";
    case /* Boost */2 :
        return "Boost";
    case /* Warehouse */3 :
        return "Warehouse";
    
  }
}

var CustomerBillingPlanKind = {
  fromString: fromString,
  toString: toString
};

export {
  acceptedCountryCodes ,
  CustomerBillingPlanKind ,
}
/* No side effect */
