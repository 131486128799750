// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as ReactAria$Pos from "../../externals/ReactAria.bs.js";
import * as ReactStately from "react-stately";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";

function contentText(text) {
  return {
          TAG: /* Text */0,
          _0: text
        };
}

var styles = StyleSheet$Pos.create({
      root: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor80, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(350)), undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.normal)), undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(Spaces$Pos.small)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      text: Style$Pos.merge([
            FontFaces$Pos.libreFranklinRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor00, undefined, FontSizes$Pos.tiny, undefined, undefined, undefined, undefined, undefined, Spaces$Pos.medium, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      arrow: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Colors$Pos.neutralColor80, undefined, undefined, undefined, undefined, 0, undefined, undefined, undefined, Colors$Pos.transparent, 5, undefined, Colors$Pos.transparent, 5, undefined, undefined, "solid", Colors$Pos.neutralColor80, undefined, undefined, undefined, undefined, 6, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "absolute", undefined, undefined, undefined, undefined, undefined, undefined)
    });

var make = React.memo(function (Props) {
      var arrowedOpt = Props.arrowed;
      var placement = Props.placement;
      var offset = Props.offset;
      var crossOffsetOpt = Props.crossOffset;
      var content = Props.content;
      var state = Props.state;
      var ariaProps = Props.ariaProps;
      var triggerDomRef = Props.triggerDomRef;
      var arrowed = arrowedOpt !== undefined ? arrowedOpt : true;
      var crossOffset = crossOffsetOpt !== undefined ? crossOffsetOpt : 0;
      var domRef = React.useRef(null);
      var props = ReactAria.mergeProps(ariaProps, {
            isOpen: state.isOpen
          });
      var match = ReactAria.useTooltip(props, state);
      var tmp;
      tmp = content.TAG === /* Text */0 ? (
          arrowed ? Spaces$Pos.small + Belt_Option.getWithDefault(offset, 0) : (
              offset !== undefined ? offset : Spaces$Pos.xxsmall
            )
        ) : (
          offset !== undefined ? offset : 0
        );
      var position = ReactAria.useOverlayPosition({
            targetRef: triggerDomRef,
            overlayRef: domRef,
            isOpen: state.isOpen,
            placement: placement,
            offset: tmp,
            crossOffset: crossOffset
          });
      var match$1 = position.placement;
      var match$2 = position.placement;
      var match$3 = position.arrowProps;
      var tmp$1;
      var exit = 0;
      if (match$2 === "bottom") {
        var left = match$3.style.left;
        if (left !== undefined) {
          tmp$1 = Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(left + crossOffset - 4)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-4)), undefined, undefined, undefined);
        } else {
          exit = 1;
        }
      } else if (match$2 === "right") {
        var top = match$3.style.top;
        if (top !== undefined) {
          tmp$1 = Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-7)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(top + crossOffset - 3)), undefined, undefined, undefined);
        } else {
          exit = 1;
        }
      } else if (match$2 === "top") {
        var left$1 = match$3.style.left;
        if (left$1 !== undefined) {
          tmp$1 = Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-4)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(left$1 + crossOffset - 4)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        } else {
          exit = 1;
        }
      } else {
        var top$1 = match$3.style.top;
        if (top$1 !== undefined) {
          tmp$1 = Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-7)), undefined, Caml_option.some(Style$Pos.dp(top$1 + crossOffset - 3)), undefined, undefined, undefined);
        } else {
          exit = 1;
        }
      }
      if (exit === 1) {
        tmp$1 = Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      }
      var arrowStyle = Style$Pos.arrayStyle([
            styles.arrow,
            Style$Pos.unsafeCss({
                  transform: match$1 === "bottom" ? "rotate(180deg)" : (
                      match$1 === "right" ? "rotate(90deg)" : (
                          match$1 === "top" ? "rotate(0deg)" : "rotate(-90deg)"
                        )
                    )
                }),
            tmp$1
          ]);
      var tmp$2;
      tmp$2 = content.TAG === /* Text */0 ? React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Text$Pos.make, Text$Pos.makeProps(content._0, Caml_option.some(styles.text), undefined, undefined, undefined))), Caml_option.some(styles.root), undefined, undefined, undefined)) : content._0;
      return React.createElement(ReactAria.Overlay, {
                  children: React.createElement(ReactAria$Pos.Spread.make, {
                        props: ReactAria.mergeProps(match.tooltipProps, position.overlayProps),
                        children: React.createElement("span", {
                              ref: domRef
                            }, React.createElement(AnimatedRender$Pos.make, {
                                  children: React.createElement(React.Fragment, undefined, arrowed ? React.createElement(View$Pos.make, View$Pos.makeProps(undefined, Caml_option.some(arrowStyle), undefined, undefined, undefined)) : null, tmp$2),
                                  displayed: state.isOpen,
                                  duration: 50
                                }))
                      })
                });
    });

function Tooltip(Props) {
  var childrenOpt = Props.children;
  var arrowedOpt = Props.arrowed;
  var placementOpt = Props.placement;
  var offset = Props.offset;
  var crossOffset = Props.crossOffset;
  var content = Props.content;
  var disabledOpt = Props.disabled;
  var delayOpt = Props.delay;
  var closeDelayOpt = Props.closeDelay;
  var opened = Props.opened;
  var immediateCloseOpt = Props.immediateClose;
  var triggerAlignOpt = Props.triggerAlign;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var arrowed = arrowedOpt !== undefined ? arrowedOpt : true;
  var placement = placementOpt !== undefined ? placementOpt : "bottom";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var delay = delayOpt !== undefined ? delayOpt : 500;
  var closeDelay = closeDelayOpt !== undefined ? closeDelayOpt : 150;
  var immediateClose = immediateCloseOpt !== undefined ? immediateCloseOpt : false;
  var triggerAlign = triggerAlignOpt !== undefined ? triggerAlignOpt : "auto";
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  var ref = match[0];
  var props_isDisabled = disabled;
  var props_delay = delay;
  var props_closeDelay = closeDelay;
  var props = {
    isDisabled: props_isDisabled,
    isOpen: opened,
    delay: props_delay,
    closeDelay: props_closeDelay
  };
  var state = ReactStately.useTooltipTriggerState(props);
  var match$1 = ReactAria.useTooltipTrigger(props, state, ref);
  React.useEffect((function () {
          if (state.isOpen) {
            Curry._1(state.close, true);
          }
          
        }), [Belt_Option.isSome(opened)]);
  React.useEffect((function () {
          if (!hovered && immediateClose) {
            Curry._1(state.close, true);
          }
          
        }), [hovered]);
  React.useEffect((function () {
          if (disabled && state.isOpen) {
            Curry._1(state.close, true);
          }
          
        }), [
        disabled,
        state.isOpen
      ]);
  var opened$1 = state.isOpen && !disabled;
  var triggerStyle = {
    display: "flex",
    alignSelf: triggerAlign,
    flexDirection: "column"
  };
  var triggerProps = ReactAria.mergeProps(match$1.triggerProps, {
        onPointerDown: null,
        onMouseDown: null
      });
  var tmp;
  if (opened$1) {
    var tmp$1 = {
      arrowed: arrowed,
      placement: placement,
      content: content,
      state: state,
      ariaProps: match$1.tooltipProps,
      triggerDomRef: ref
    };
    if (offset !== undefined) {
      tmp$1.offset = Caml_option.valFromOption(offset);
    }
    if (crossOffset !== undefined) {
      tmp$1.crossOffset = Caml_option.valFromOption(crossOffset);
    }
    tmp = React.createElement(make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ReactAria$Pos.Spread.make, {
                  props: triggerProps,
                  children: React.createElement("div", {
                        ref: ref,
                        style: triggerStyle
                      }, children)
                }), tmp);
}

var make$1 = React.memo(Tooltip);

export {
  contentText ,
  make$1 as make,
}
/* styles Not a pure module */
