// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Spaces$Pos from "../theme/Spaces.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactDOMStyle from "@rescript/react/src/ReactDOMStyle.bs.js";

function makeStyle(spaceX, spaceY) {
  return ReactDOMStyle.unsafeAddProp({
              display: "flex",
              columnGap: "" + String(Spaces$Pos.toFloat(spaceX)) + "px",
              flexWrap: "wrap"
            }, "rowGap", "" + String(Spaces$Pos.toFloat(spaceY)) + "px");
}

function makeItemStyle(flexBasisPct) {
  return {
          flex: "1 1 " + String(flexBasisPct) + "%"
        };
}

function Group(Props) {
  var children = Props.children;
  var spaceXOpt = Props.spaceX;
  var spaceYOpt = Props.spaceY;
  var columns = Props.columns;
  var spaceX = spaceXOpt !== undefined ? spaceXOpt : "large";
  var spaceY = spaceYOpt !== undefined ? spaceYOpt : "large";
  var childrenLength = Belt_Option.getWithDefault(columns, React.Children.toArray(children).length);
  var flexBasisPct = Math.max(Caml_int32.div(100, childrenLength) - childrenLength | 0, childrenLength - 2 | 0);
  return React.createElement("div", {
              style: makeStyle(spaceX, spaceY)
            }, Belt_Array.mapWithIndex(React.Children.toArray(children), (function (idx, child) {
                    return React.createElement("div", {
                                key: String(idx),
                                style: makeItemStyle(flexBasisPct)
                              }, child);
                  })));
}

var make = React.memo(Group);

export {
  make ,
}
/* make Not a pure module */
