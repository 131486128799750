// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as Text$Pos from "../../primitives/Text.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Colors$Pos from "../theme/Colors.bs.js";
import * as Inline$Pos from "../layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontFaces$Pos from "../theme/FontFaces.bs.js";
import * as FontSizes$Pos from "../theme/FontSizes.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as TooltipIcon$Pos from "../overlays/TooltipIcon.bs.js";

var styles = StyleSheet$Pos.create({
      text: Style$Pos.merge([
            FontFaces$Pos.archivoRegularStyle,
            Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "_600", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]),
      lightText: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor20, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      grayText: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.neutralColor100, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      darkText: Style$Pos.style(undefined, undefined, undefined, Colors$Pos.brandColor60, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function inlineSpaceFromParams(size) {
  if (size === "small" || size === "xsmall" || size === "xxsmall" || size === "tiny") {
    return "xsmall";
  } else {
    return "small";
  }
}

function iconSizeFromParams(size) {
  if (size === "small" || size === "xsmall" || size === "xxsmall" || size === "tiny") {
    return 18;
  } else if (size === "normal") {
    return 20;
  } else {
    return 20;
  }
}

function iconColorFromParams(disabled, hovered) {
  if (disabled) {
    return Colors$Pos.neutralColor20;
  } else if (hovered) {
    return Colors$Pos.brandColor60;
  } else {
    return Colors$Pos.neutralColor100;
  }
}

function textStyleFromParams(size, disabled, hovered) {
  return Style$Pos.arrayStyle([
              size === "small" || size === "xsmall" || size === "xxsmall" || size === "tiny" ? FontSizes$Pos.styleFromSize("small") : FontSizes$Pos.styleFromSize("large"),
              disabled ? styles.lightText : (
                  hovered ? styles.darkText : styles.grayText
                )
            ]);
}

function TextIconButton(Props) {
  var children = Props.children;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var icon = Props.icon;
  var textTooltip = Props.textTooltip;
  var onPress = Props.onPress;
  var size = sizeOpt !== undefined ? sizeOpt : "normal";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  var ref = match[0];
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(Inline$Pos.make, {
                      children: null,
                      space: inlineSpaceFromParams(size)
                    }, React.createElement(Icon$Pos.make, {
                          name: icon,
                          fill: iconColorFromParams(disabled, hovered),
                          size: iconSizeFromParams(size)
                        }), React.createElement(Text$Pos.make, Text$Pos.makeProps(children, Caml_option.some(Style$Pos.arrayStyle([
                                      styles.text,
                                      textStyleFromParams(size, disabled, hovered)
                                    ])), undefined, undefined, undefined)), textTooltip !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TooltipIcon$Pos.make, {
                                        children: textTooltip,
                                        variation: "info",
                                        altTriggerRef: ref
                                      })), undefined, undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null), undefined, disabled, undefined, undefined, undefined, onPress, undefined, ref, undefined));
}

var make = TextIconButton;

export {
  make ,
}
/* styles Not a pure module */
