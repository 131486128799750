// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Future from "rescript-future/src/Future.bs.js";
import * as Json$Pos from "./Json.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";

function request(param) {
  return Future.map(FuturePromise.fromPromise(fetch("https://api64.ipify.org/?format=json", {
                          method: "GET"
                        }).then(function (prim) {
                        return prim.json();
                      }).then(function (json) {
                      return Promise.resolve(Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "ip"));
                    })), undefined, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                var ip = result._0;
                if (ip !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: ip
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
              }));
}

export {
  request ,
}
/* Json-Pos Not a pure module */
