// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "./Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Stack$Pos from "./Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../typography/Title.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Spinner$Pos from "../feedback-indicators/Spinner.bs.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as Illustration$Pos from "../images-and-icons/Illustration.bs.js";

var styles = StyleSheet$Pos.create({
      main: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", "center", undefined, undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      mainDefault: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(-1.5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(5)), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.pct(5)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function viewStyleFromParams(compact) {
  return Style$Pos.arrayOptionStyle([compact ? undefined : Caml_option.some(styles.mainDefault)]);
}

function renderSpinner(compact) {
  return React.createElement(Spinner$Pos.make, {
              size: compact ? 26 : 32
            });
}

function statusToText(status) {
  if (typeof status !== "number") {
    return {
            title: status.title,
            text: status.message
          };
  }
  switch (status) {
    case /* Loading */0 :
        return {
                title: Intl$Pos.t("Loading..."),
                text: Intl$Pos.t("Please wait.")
              };
    case /* NoDataAvailable */1 :
        return {
                title: Intl$Pos.t("No data available."),
                text: Intl$Pos.t("Try again with another keyword or filter.")
              };
    case /* Error */2 :
        return {
                title: Intl$Pos.t("Loading issue."),
                text: Intl$Pos.t("Please try refreshing the page.")
              };
    
  }
}

function Placeholder(Props) {
  var status = Props.status;
  var customText = Props.customText;
  var compactOpt = Props.compact;
  var childComponent = Props.childComponent;
  var compact = compactOpt !== undefined ? compactOpt : false;
  var tmp;
  if (typeof status === "number") {
    switch (status) {
      case /* Loading */0 :
          tmp = renderSpinner(compact);
          break;
      case /* NoDataAvailable */1 :
          tmp = React.createElement(Illustration$Pos.make, {
                element: Illustration$Pos.create
              });
          break;
      case /* Error */2 :
          tmp = React.createElement(Illustration$Pos.make, {
                element: Illustration$Pos.error
              });
          break;
      
    }
  } else {
    var element = status.illustration;
    tmp = element !== undefined ? React.createElement(Illustration$Pos.make, {
            element: Caml_option.valFromOption(element)
          }) : React.createElement(Illustration$Pos.make, {
            element: Illustration$Pos.notAsked
          });
  }
  return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(Style$Pos.arrayStyle([
                          styles.main,
                          viewStyleFromParams(compact)
                        ])), undefined, undefined, undefined), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(tmp), undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), compact ? null : React.createElement(Stack$Pos.make, {
                    children: null
                  }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Title$Pos.make, {
                                    children: Intl$Pos.t(statusToText(status).title),
                                    level: 3,
                                    align: "center",
                                    weight: "medium"
                                  })), undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                    children: customText !== undefined ? customText : Intl$Pos.t(statusToText(status).text),
                                    align: "center",
                                    variation: "normal"
                                  })), "xhuge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(typeof status === "number" || childComponent === undefined ? null : React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(Curry._1(childComponent, undefined)), undefined, undefined, undefined, "xxlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined))), "xhuge", "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
}

var make = React.memo(Placeholder);

export {
  make ,
}
/* styles Not a pure module */
