// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as BarControl$Pos from "../../resources/layout-and-structure/BarControl.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Pagination$Pos from "../../resources/navigation/Pagination.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as QueryString$Pos from "../../primitives/QueryString.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as TextIconButton$Pos from "../../resources/actions/TextIconButton.bs.js";

var make = React.memo(function (Props) {
      var onPress = Props.onPress;
      return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, {
                              children: Intl$Pos.t("Reset"),
                              size: "small",
                              icon: "close_light",
                              onPress: (function (param) {
                                  Curry._1(onPress, undefined);
                                })
                            })), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined));
    });

var ResetFiltersButton = {
  make: make
};

function makeTotalPages(totalCount, nodesPerPage) {
  if (nodesPerPage <= 0 || totalCount <= 0) {
    return 1;
  } else if (Caml_int32.mod_(totalCount, nodesPerPage) === 0) {
    return Caml_int32.div(totalCount, nodesPerPage);
  } else {
    return Caml_int32.div(totalCount, nodesPerPage) + 1 | 0;
  }
}

function useQueryStringPersistReducer(codec, reducer, initialState) {
  var navigate = Navigation$Pos.useNavigate(undefined);
  var url = Navigation$Pos.useUrl(undefined);
  var initialState$1 = React.useMemo((function () {
          var urlState = JsonCodec$Pos.decodeWith(QueryString$Pos.parse(url.query), codec);
          var match = url.query;
          if (urlState.TAG === /* Ok */0 && match !== "") {
            return urlState._0;
          } else {
            return initialState;
          }
        }), []);
  var match = React.useReducer(Curry.__2(reducer), initialState$1);
  var state = match[0];
  React.useEffect((function () {
          var query = QueryString$Pos.stringify(JsonCodec$Pos.encodeWith(state, codec));
          var route = url.pathname + "?" + query;
          if (query !== url.query) {
            Curry._4(navigate, route, true, undefined, undefined);
          }
          
        }), [state]);
  return [
          state,
          match[1]
        ];
}

var edgesPerPage = 10;

function Make(funarg) {
  var makeQueryVariables = funarg.makeQueryVariables;
  var makeQueryVariablesFilterBy = funarg.makeQueryVariablesFilterBy;
  var cursorsFromQueryData = funarg.cursorsFromQueryData;
  var totalCountFromQueryData = funarg.totalCountFromQueryData;
  var rowsFromQueryDataAndState = funarg.rowsFromQueryDataAndState;
  var keyExtractor = funarg.keyExtractor;
  var useFiltersJsonCodec = funarg.useFiltersJsonCodec;
  var encoder = function (param) {
    var connectionArguments = param.connectionArguments;
    return [
            param.currentPage,
            param.previousPage,
            param.searchQuery,
            Caml_option.some(param.filters),
            connectionArguments.first,
            connectionArguments.last,
            connectionArguments.after,
            connectionArguments.before
          ];
  };
  var value = function (filtersCodec, initialFilters) {
    return JsonCodec$Pos.object8(encoder, (function (param) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            currentPage: Belt_Option.getWithDefault(param[0], 1),
                            previousPage: Belt_Option.getWithDefault(param[1], -1),
                            searchQuery: param[2],
                            filters: Belt_Option.getWithDefault(param[3], initialFilters),
                            connectionArguments: {
                              first: param[4],
                              last: param[5],
                              after: param[6],
                              before: param[7]
                            }
                          }
                        };
                }), JsonCodec$Pos.optional(JsonCodec$Pos.field("page", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("previousPage", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("search", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("filters", filtersCodec)), JsonCodec$Pos.optional(JsonCodec$Pos.field("first", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("last", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("after", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("before", JsonCodec$Pos.string)));
  };
  var makeInitialState = function (filters) {
    return {
            currentPage: 1,
            previousPage: -1,
            searchQuery: undefined,
            filters: filters,
            connectionArguments: {
              first: 10
            }
          };
  };
  var makeConnectionArguments = function (currentPage, previousPage, totalCount, cursors) {
    if (currentPage === 1) {
      return {
              first: 10
            };
    }
    var startCursor = cursors[0];
    var exit = 0;
    if (startCursor !== undefined) {
      if (currentPage === (previousPage - 1 | 0)) {
        return {
                last: 10,
                before: startCursor
              };
      }
      exit = 2;
    } else {
      exit = 2;
    }
    if (exit === 2) {
      var endCursor = cursors[1];
      if (endCursor !== undefined && currentPage === (previousPage + 1 | 0)) {
        return {
                first: 10,
                after: endCursor
              };
      }
      
    }
    if (currentPage === makeTotalPages(totalCount, 10)) {
      return {
              last: 10
            };
    }
    
  };
  var use = function (initialState) {
    var reducer = function (state, action) {
      switch (action.TAG | 0) {
        case /* Navigated */0 :
            var nextPage = action.nextPage;
            var previousPage = state.currentPage;
            var maybeConnectionArguments = makeConnectionArguments(nextPage, previousPage, action.queryTotalCount, action.queryCursors);
            if (maybeConnectionArguments !== undefined) {
              return {
                      currentPage: nextPage,
                      previousPage: previousPage,
                      searchQuery: state.searchQuery,
                      filters: state.filters,
                      connectionArguments: maybeConnectionArguments
                    };
            } else {
              return state;
            }
        case /* Searched */1 :
            var searchQuery = action._0;
            var tmp = searchQuery === "" ? undefined : searchQuery;
            return {
                    currentPage: 1,
                    previousPage: -1,
                    searchQuery: tmp,
                    filters: state.filters,
                    connectionArguments: {
                      first: 10
                    }
                  };
        case /* FiltersUpdated */2 :
            return {
                    currentPage: 1,
                    previousPage: -1,
                    searchQuery: state.searchQuery,
                    filters: Curry._1(action._0, state.filters),
                    connectionArguments: {
                      first: 10
                    }
                  };
        case /* Reset */3 :
            return action._0;
        
      }
    };
    var codec = value(Curry._1(useFiltersJsonCodec, undefined), Curry._1(initialState, undefined).filters);
    return useQueryStringPersistReducer(codec, reducer, Curry._1(initialState, undefined));
  };
  var makeNextPage = function (state, action, totalPages) {
    var match = state.currentPage;
    if ((match === 0 || match === 1) && (totalPages === 0 || totalPages === 1)) {
      return ;
    }
    switch (action) {
      case /* First */0 :
          if (match >= 1) {
            return 1;
          } else {
            return ;
          }
      case /* Prev */1 :
          if (match > totalPages) {
            return totalPages;
          } else if (match > 1) {
            return match - 1 | 0;
          } else {
            return ;
          }
      case /* Next */2 :
          if (match > totalPages) {
            return totalPages;
          } else if (match >= 1 && match < totalPages) {
            return match + 1 | 0;
          } else {
            return ;
          }
      case /* Last */3 :
          if (match >= 1) {
            return totalPages;
          } else {
            return ;
          }
      
    }
  };
  var Scaffold$Make = function (Props) {
    var title = Props.title;
    var subtitle = Props.subtitle;
    var renderTitleEnd = Props.renderTitleEnd;
    var state = Props.state;
    var dispatch = Props.dispatch;
    var columns = Props.columns;
    var filtersOpt = Props.filters;
    var actionsOpt = Props.actions;
    var bannerOpt = Props.banner;
    var searchBarOpt = Props.searchBar;
    var emptyState = Props.emptyState;
    var defaultQueryVariables = Props.defaultQueryVariables;
    var filters = filtersOpt !== undefined ? Caml_option.valFromOption(filtersOpt) : null;
    var actions = actionsOpt !== undefined ? Caml_option.valFromOption(actionsOpt) : null;
    var banner = bannerOpt !== undefined ? Caml_option.valFromOption(bannerOpt) : null;
    var searchBar = searchBarOpt !== undefined ? Caml_option.valFromOption(searchBarOpt) : null;
    var queryVariables = React.useMemo((function () {
            var filterBy = Curry._1(makeQueryVariablesFilterBy, state.filters);
            return Curry._5(makeQueryVariables, defaultQueryVariables, state.connectionArguments, state.searchQuery, Caml_option.some(filterBy), undefined);
          }), [
          defaultQueryVariables,
          state
        ]);
    var asyncResult = AsyncResult$Pos.mapError(ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(funarg.useQuery, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  /* NetworkOnly */3,
                  /* CacheFirst */1,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  queryVariables
                ])), (function (param) {
            
          }));
    var onRequestPaginate = React.useCallback((function (action) {
            var queryData;
            if (typeof asyncResult === "number") {
              return ;
            }
            if (asyncResult.TAG === /* Reloading */0) {
              var queryData$1 = asyncResult._0;
              if (queryData$1.TAG !== /* Ok */0) {
                return ;
              }
              queryData = queryData$1._0;
            } else {
              var queryData$2 = asyncResult._0;
              if (queryData$2.TAG !== /* Ok */0) {
                return ;
              }
              queryData = queryData$2._0;
            }
            var totalPages = makeTotalPages(Curry._1(totalCountFromQueryData, queryData), 10);
            var maybeNextPage = makeNextPage(state, action, totalPages);
            if (maybeNextPage === undefined) {
              return ;
            }
            var queryTotalCount = Curry._1(totalCountFromQueryData, queryData);
            var queryCursors = Curry._1(cursorsFromQueryData, queryData);
            Curry._1(dispatch, {
                  TAG: /* Navigated */0,
                  nextPage: maybeNextPage,
                  queryTotalCount: queryTotalCount,
                  queryCursors: queryCursors
                });
          }), [
          state.currentPage,
          asyncResult
        ]);
    var pageVariation = subtitle !== undefined ? "standard" : "compact";
    var asyncTableRows = AsyncResult$Pos.mapOk(asyncResult, (function (queryData) {
            return Curry._2(rowsFromQueryDataAndState, queryData, state);
          }));
    var columns$1 = Belt_Array.mapWithIndex(columns, (function (index, column) {
            return {
                    key: Belt_Option.getWithDefault(column.name, "scaffold-key-" + String(index)),
                    name: Belt_Option.getWithDefault(column.name, ""),
                    layout: column.layout,
                    render: (function (row) {
                        return Curry._1(column.render, row.data);
                      })
                  };
          }));
    var tmp = {
      children: null,
      variation: pageVariation,
      title: title
    };
    if (subtitle !== undefined) {
      tmp.subtitle = subtitle;
    }
    if (renderTitleEnd !== undefined) {
      tmp.renderTitleEnd = Caml_option.valFromOption(renderTitleEnd);
    }
    var tmp$1;
    var exit = 0;
    var queryData;
    if (typeof asyncResult === "number") {
      exit = 1;
    } else if (asyncResult.TAG === /* Reloading */0) {
      var queryData$1 = asyncResult._0;
      if (queryData$1.TAG === /* Ok */0) {
        queryData = queryData$1._0;
        exit = 2;
      } else {
        exit = 1;
      }
    } else {
      var queryData$2 = asyncResult._0;
      if (queryData$2.TAG === /* Ok */0) {
        queryData = queryData$2._0;
        exit = 2;
      } else {
        exit = 1;
      }
    }
    switch (exit) {
      case 1 :
          tmp$1 = React.createElement(Pagination$Pos.make, {
                currentPage: 1,
                totalPages: 1,
                onRequestPaginate: onRequestPaginate
              });
          break;
      case 2 :
          tmp$1 = React.createElement(Pagination$Pos.make, {
                currentPage: state.currentPage,
                totalPages: makeTotalPages(Curry._1(totalCountFromQueryData, queryData), 10),
                onRequestPaginate: onRequestPaginate
              });
          break;
      
    }
    return React.createElement(Page$Pos.make, tmp, React.createElement(BarControl$Pos.make, {
                    filters: filters,
                    actions: actions,
                    banner: banner
                  }), React.createElement(TableView$Pos.make, {
                    data: asyncTableRows,
                    columns: columns$1,
                    keyExtractor: keyExtractor,
                    hideReloadingPlaceholder: AsyncResult$Pos.isReloading(asyncTableRows),
                    placeholderEmptyState: emptyState,
                    searchBar: searchBar,
                    onSearchQueryChange: (function (searchQuery) {
                        Curry._1(dispatch, {
                              TAG: /* Searched */1,
                              _0: searchQuery
                            });
                      })
                  }), tmp$1);
  };
  var make = React.memo(Scaffold$Make, (function (oldProps, newProps) {
          if (oldProps.title === newProps.title && oldProps.subtitle === newProps.subtitle && oldProps.state === newProps.state && oldProps.filters === newProps.filters && oldProps.actions === newProps.actions && oldProps.columns === newProps.columns && oldProps.banner === newProps.banner && oldProps.searchBar === newProps.searchBar && oldProps.emptyState === newProps.emptyState) {
            return oldProps.defaultQueryVariables === newProps.defaultQueryVariables;
          } else {
            return false;
          }
        }));
  return {
          makeInitialState: makeInitialState,
          use: use,
          makeNextPage: makeNextPage,
          makeConnectionArguments: makeConnectionArguments,
          makeQueryVariables: makeQueryVariables,
          makeQueryVariablesFilterBy: makeQueryVariablesFilterBy,
          cursorsFromQueryData: cursorsFromQueryData,
          totalCountFromQueryData: totalCountFromQueryData,
          rowsFromQueryDataAndState: rowsFromQueryDataAndState,
          keyExtractor: keyExtractor,
          make: make
        };
}

export {
  ResetFiltersButton ,
  makeTotalPages ,
  edgesPerPage ,
  useQueryStringPersistReducer ,
  Make ,
}
/* make Not a pure module */
