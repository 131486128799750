// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Stack$Pos from "./Stack.bs.js";
import * as Title$Pos from "../typography/Title.bs.js";

var make = React.memo(function (Props) {
      var children = Props.children;
      var title = Props.title;
      return React.createElement(Stack$Pos.make, {
                  children: null,
                  space: "normal"
                }, React.createElement(Title$Pos.make, {
                      children: title.toUpperCase(),
                      level: 5
                    }), React.createElement(Stack$Pos.make, {
                      children: children,
                      space: "xsmall"
                    }));
    });

export {
  make ,
}
/* make Not a pure module */
