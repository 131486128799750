// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";

function use(reducer, initialState) {
  var cleanupEffects = React.useRef([]);
  var match = React.useReducer((function (fullState, action) {
          var state = Curry._2(reducer, fullState.state, action);
          if (typeof state === "number") {
            return fullState;
          }
          switch (state.TAG | 0) {
            case /* Update */0 :
                return {
                        state: state._0,
                        sideEffects: fullState.sideEffects
                      };
            case /* UpdateWithSideEffects */1 :
                return {
                        state: state._0,
                        sideEffects: {
                          contents: Belt_Array.concat(fullState.sideEffects.contents, [state._1])
                        }
                      };
            case /* SideEffects */2 :
                return {
                        state: fullState.state,
                        sideEffects: {
                          contents: Belt_Array.concat(fullState.sideEffects.contents, [state._0])
                        }
                      };
            
          }
        }), {
        state: initialState,
        sideEffects: {
          contents: []
        }
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var sideEffects = match$1.sideEffects;
  var state = match$1.state;
  React.useEffect((function () {
          if (sideEffects.contents.length !== 0) {
            var sideEffectsToRun = Js_array.sliceFrom(0, sideEffects.contents);
            sideEffects.contents = [];
            var cancelFuncs = Belt_Array.keepMap(sideEffectsToRun, (function (func) {
                    return Curry._1(func, {
                                dispatch: dispatch,
                                state: state
                              });
                  }));
            Caml_splice_call.spliceObjApply(cleanupEffects.current, "push", [cancelFuncs]);
          }
          
        }), [sideEffects]);
  React.useEffect((function () {
          return (function (param) {
                    cleanupEffects.current.forEach(function (cb) {
                          Curry._1(cb, undefined);
                        });
                  });
        }), []);
  return [
          state,
          dispatch
        ];
}

function useWithMapState(reducer, getInitialState) {
  var cleanupEffects = React.useRef([]);
  var match = React.useReducer((function (fullState, action) {
          var state = Curry._2(reducer, fullState.state, action);
          if (typeof state === "number") {
            return fullState;
          }
          switch (state.TAG | 0) {
            case /* Update */0 :
                return {
                        state: state._0,
                        sideEffects: fullState.sideEffects
                      };
            case /* UpdateWithSideEffects */1 :
                return {
                        state: state._0,
                        sideEffects: {
                          contents: Belt_Array.concat(fullState.sideEffects.contents, [state._1])
                        }
                      };
            case /* SideEffects */2 :
                return {
                        state: fullState.state,
                        sideEffects: {
                          contents: Belt_Array.concat(fullState.sideEffects.contents, [state._0])
                        }
                      };
            
          }
        }), undefined, (function (param) {
          return {
                  state: Curry._1(getInitialState, undefined),
                  sideEffects: {
                    contents: []
                  }
                };
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  var sideEffects = match$1.sideEffects;
  var state = match$1.state;
  React.useEffect((function () {
          if (sideEffects.contents.length !== 0) {
            var sideEffectsToRun = Js_array.sliceFrom(0, sideEffects.contents);
            sideEffects.contents = [];
            var cancelFuncs = Belt_Array.keepMap(sideEffectsToRun, (function (func) {
                    return Curry._1(func, {
                                dispatch: dispatch,
                                state: state
                              });
                  }));
            Caml_splice_call.spliceObjApply(cleanupEffects.current, "push", [cancelFuncs]);
          }
          
        }), [sideEffects]);
  React.useEffect((function () {
          return (function (param) {
                    cleanupEffects.current.forEach(function (cb) {
                          Curry._1(cb, undefined);
                        });
                  });
        }), []);
  return [
          state,
          dispatch
        ];
}

export {
  use ,
  useWithMapState ,
}
/* react Not a pure module */
